import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findElementIndex',
  standalone: true,
})
export class FindElementIndexPipe implements PipeTransform {
  transform(array: number[] | string[], initialValue: number | string): number {
    return array.findIndex(val => val === initialValue);
  }
}
