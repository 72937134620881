import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { TempMappingApiService } from '../services/temp-mapping-api.service';
import * as tempMappingActions from './temp-mapping.actions';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { selectTemperatureMappings } from './temp-mapping.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';

@Injectable()
export class TempMappingEffects {
  getTemperatureMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tempMappingActions.getTemperatureMappings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.tempMappingApiService.getTemperatureMappings(controllerID).pipe(
          map((view) => tempMappingActions.getTemperatureMappingsSuccess({ view })),
          catchError((error) => of(tempMappingActions.getTemperatureMappingsError({ error }))),
        );
      }),
    ),
  );

  updateTemperatureMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(tempMappingActions.updateTemperatureMappings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectTemperatureMappings),
      ),
      switchMap(([_action, controllerID, tempMappings]) => {
        return this.tempMappingApiService.updateTemperatureMappings(controllerID, tempMappings).pipe(
          switchMap(() => {
            return [
              tempMappingActions.updateTemperatureMappingsSuccess(),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
            ];
          }),
          catchError((error) => of(tempMappingActions.updateTemperatureMappingsError({ error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private tempMappingApiService: TempMappingApiService,
  ) {
  }
}
