<div
  [class.popup_white]="isMobileEditUnitMode"
  [class.rtl]="languageService.isRtl"
  class="popup">
  <h3
    *ngIf="!isMobileEditUnitMode"
    class="popup-title fz-24 mb-20 d-flex justify-between">
    <span class="popup-main-title">
      {{ "Controller.GeneralSettings.CustomUnitsDialog.CustomUnits" | translate }}
    </span>
    <div class="unit-titles d-none-md">
      <span class="mh-10">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Metric" | translate }}
      </span>
      <span class="unit-titles-last mh-10">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.Imperial" | translate }}
      </span>
    </div>
    <img
      (click)="close()"
      [qaTags]="'qa-btn-close'"
      alt="cross"
      class="close-icon pointer"
      src="/assets/icons/common/cross.svg" />
  </h3>

  <div class="popup-body color-secondary fz-20 mb-25">
    <form
      *ngIf="!platformService.isMd; else mobileTemplate"
      [formGroup]="form">
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Temperature" | translate }}
        </span>
        <mat-radio-group
          [qaTags]="'qa-radio-temperature'"
          class="ls-radio-group"
          formControlName="temperature">
          <mat-radio-button
            *ngFor="let el of TemperatureUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [qaTags]="'qa-radio-temperature-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "temperature-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirFlow" | translate }}
        </span>
        <mat-radio-group
          [qaTags]="'qa-radio-air-flow'"
          class="ls-radio-group"
          formControlName="airFlow">
          <mat-radio-button
            *ngFor="let el of AirFlowUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [qaTags]="'qa-radio-air-flow-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "air-flow-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirSpeed" | translate }}
        </span>
        <mat-radio-group
          [qaTags]="'qa-radio-air-speed'"
          class="ls-radio-group"
          formControlName="airSpeed">
          <mat-radio-button
            *ngFor="let el of AirSpeedUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [qaTags]="'qa-radio-air-speed-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "air-speed-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirPressure" | translate }}
        </span>
        <mat-radio-group
          [qaTags]="'qa-radio-air-pressure'"
          class="ls-radio-group"
          formControlName="airPressure">
          <div class="double-radio">
            <mat-radio-button
              [qaTags]="'qa-radio-air-pressure-0'"
              [value]="AirPressureUnitEnum.MmOfWater"
              class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
              {{ AirPressureUnitEnum.MmOfWater | enum : "air-pressure-unit-enum" | translate }}
            </mat-radio-button>
            <mat-radio-button
              [qaTags]="'qa-radio-air-pressure-1'"
              [value]="AirPressureUnitEnum.Pascal"
              class="ls-radio-group__button mh-20 overrided-mat-radio-button overrided-color-dark-blue">
              {{ AirPressureUnitEnum.Pascal | enum : "air-pressure-unit-enum" | translate }}
            </mat-radio-button>
          </div>
          <mat-radio-button
            [qaTags]="'qa-radio-air-pressure-2'"
            [value]="AirPressureUnitEnum.InchesOfWater"
            class="ls-radio-group__button ml-115 ls-radio-group__button-double__radio overrided-mat-radio-button overrided-color-dark-blue">
            {{ AirPressureUnitEnum.InchesOfWater | enum : "air-pressure-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressure" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-water-main-pressure"
          formControlName="waterMainPressure">
          <mat-radio-button
            *ngFor="let el of WaterMainPressureUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [ngClass]="'qa-radio-water-main-pressure-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "water-main-pressure-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressure" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-water-line-pressure"
          formControlName="waterLinePressure">
          <mat-radio-button
            *ngFor="let el of WaterLinePressureUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [ngClass]="'qa-radio-water-line-pressure-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "water-line-pressure-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Weight" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-weight"
          formControlName="weight">
          <div class="double-radio">
            <mat-radio-button
              [value]="WeightUnitEnum.Gram"
              class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue qa-radio-weight-0">
              {{ WeightUnitEnum.Gram | enum : "weight-unit-enum" | translate }}
            </mat-radio-button>
            <mat-radio-button
              [value]="WeightUnitEnum.Kilogram"
              class="ls-radio-group__button mh-90 overrided-mat-radio-button overrided-color-dark-blue qa-radio-weight-1">
              {{ WeightUnitEnum.Kilogram | enum : "weight-unit-enum" | translate }}
            </mat-radio-button>
          </div>

          <mat-radio-button
            [value]="WeightUnitEnum.Pound"
            class="ls-radio-group__button ls-radio-group__button-double__radio overrided-mat-radio-button overrided-color-dark-blue ml-65 qa-radio-weight-2">
            {{ WeightUnitEnum.Pound | enum : "weight-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Volume" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-volume"
          formControlName="volume">
          <mat-radio-button
            *ngFor="let el of VolumeUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [ngClass]="'qa-radio-volume-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "volume-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Length" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-length"
          formControlName="length">
          <mat-radio-button
            *ngFor="let el of LengthUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [ngClass]="'qa-radio-length-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "length-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
      <div class="d-flex flex-row align-center unit-field">
        <span class="unit-field__label">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Illuminance" | translate }}
        </span>
        <mat-radio-group
          class="ls-radio-group qa-radio-illuminance"
          formControlName="illuminance">
          <mat-radio-button
            *ngFor="let el of IlluminanceUnitEnum | enumToArray; let last = last"
            [class.ls-radio-group__button-last]="last"
            [ngClass]="'qa-radio-illuminance-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-dark-blue">
            {{ el | enum : "illuminance-unit-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </form>
  </div>

  <div
    *ngIf="!isMobileEditUnitMode"
    class="popup-footer">
    <div class="d-flex align-center justify-end">
      <ls-button
        (click)="resetToDefault()"
        [qaTags]="'qa-btn-reset-to-default'"
        class="mr-15"
        type="secondary">
        {{ "Controller.GeneralSettings.CustomUnitsDialog.ResetToDefault" | translate }}
      </ls-button>
      <ls-button
        (click)="apply()"
        [qaTags]="'qa-btn-apply'">
        {{ "Buttons.Apply" | translate }}
      </ls-button>
    </div>
  </div>
</div>

<ng-template #mobileTemplate>
  <ng-container *ngIf="!isMobileEditUnitMode; else mobileEditUnitTemplate">
    <div
      (click)="openEditUnitTemplate(TemperatureUnitEnum)"
      class="form-field qa-radio-temperature">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Temperature" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.temperature | enum : "temperature-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([TemperatureUnitEnum, this.form.value.temperature] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([TemperatureUnitEnum, this.form.value.temperature] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(AirFlowUnitEnum)"
      class="form-field mt-10 qa-radio-air-flow">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirFlow" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.airFlow | enum : "air-flow-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([AirFlowUnitEnum, this.form.value.airFlow] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([AirFlowUnitEnum, this.form.value.airFlow] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(AirSpeedUnitEnum)"
      class="form-field mt-10 qa-radio-air-speed">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirSpeed" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.airSpeed | enum : "air-speed-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([AirSpeedUnitEnum, this.form.value.airSpeed] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([AirSpeedUnitEnum, this.form.value.airSpeed] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(AirPressureUnitEnum)"
      class="form-field mt-10 qa-radio-air-pressure">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.AirPressure" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.airPressure | enum : "air-pressure-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([AirPressureUnitEnum, this.form.value.airPressure] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([AirPressureUnitEnum, this.form.value.airPressure] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(WaterMainPressureUnitEnum)"
      class="form-field mt-10 qa-radio-water-main-pressure">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterMainPressure" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.waterMainPressure | enum : "water-main-pressure-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([WaterMainPressureUnitEnum, this.form.value.waterMainPressure] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([WaterMainPressureUnitEnum, this.form.value.waterMainPressure] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(WaterLinePressureUnitEnum)"
      class="form-field mt-10 qa-radio-water-line-pressure">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressure" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.waterLinePressure | enum : "water-line-pressure-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([WaterLinePressureUnitEnum, this.form.value.waterLinePressure] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([WaterLinePressureUnitEnum, this.form.value.waterLinePressure] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(WeightUnitEnum)"
      class="form-field mt-10 qa-radio-weight">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Weight" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.weight | enum : "weight-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([WeightUnitEnum, this.form.value.weight] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([WeightUnitEnum, this.form.value.weight] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(VolumeUnitEnum)"
      class="form-field mt-10 qa-radio-volume">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Volume" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.volume | enum : "volume-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([VolumeUnitEnum, this.form.value.volume] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([VolumeUnitEnum, this.form.value.volume] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(LengthUnitEnum)"
      class="form-field mt-10 qa-radio-length">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Length" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.length | enum : "length-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([LengthUnitEnum, this.form.value.length] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([LengthUnitEnum, this.form.value.length] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>

    <div
      (click)="openEditUnitTemplate(IlluminanceUnitEnum)"
      class="form-field mt-10 qa-radio-illuminance">
      <div class="form-field__value-block">
        <span class="form-field__value-title">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Illuminance" | translate }}
        </span>
        <span class="form-field__value">
          {{ this.form.value.illuminance | enum : "illuminance-unit-enum" | translate }}
        </span>
      </div>
      <div class="form-field__img-container">
        <span
          [class.form-field__additional-info-gray]="
            !([IlluminanceUnitEnum, this.form.value.illuminance] | memoizeFunc : isMetricUnit)
          "
          class="form-field__additional-info">
          {{
            (([IlluminanceUnitEnum, this.form.value.illuminance] | memoizeFunc : isMetricUnit)
              ? "Controller.GeneralSettings.CustomUnitsDialog.Metric"
              : "Controller.GeneralSettings.CustomUnitsDialog.Imperial"
            ) | translate
          }}
        </span>
        <img
          class="form-field__chevron"
          src="/assets/icons/arrows/chevron-right-grey.svg" />
      </div>
    </div>
  </ng-container>
</ng-template>

<ng-template #mobileEditUnitTemplate>
  <span
    (click)="editUnitModeChangeValue()"
    class="edit-unit-mode-title">
    <img src="/assets/icons/arrows/chevron-left-grey.svg" />
    <span class="d-block mh-15">{{ editUnitModeObj.title | translate }}</span>
  </span>
  <div [formGroup]="form">
    <mat-radio-group
      (change)="editUnitModeChangeValue()"
      [formControlName]="editUnitModeObj.controlName"
      class="ls-radio-group d-flex flex-column qa-radio-group">
      <ng-container *ngFor="let el of editUnitModeObj.enumName | enumToArray; let first = first; let last = last">
        <span
          *ngIf="first"
          class="fz-18 mh-12">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Metric" | translate }}
        </span>
        <span
          *ngIf="last"
          class="fz-18 mh-12 mt-20">
          {{ "Controller.GeneralSettings.CustomUnitsDialog.Imperial" | translate }}
        </span>
        <mat-radio-button
          [ngClass]="'qa-radio-group-button-' + el"
          [value]="el"
          class="overrided-mat-radio-button overrided-color-dark-blue overrided-color-fz-18">
          {{ el | enum : editUnitModeObj.enumStr | translate }}
        </mat-radio-button>
      </ng-container>
    </mat-radio-group>
  </div>
</ng-template>
