import { ILightingMappingItem } from '@livestock/lighting-mapping';
import { IElement } from '@livestock/installation/interfaces';

export interface LightingMappingState {
  lightingMappings: ILightingMappingItem[];
  originalLightingMappings: ILightingMappingItem[];
  activeMappingElementID: number;
  sensorElements: IElement[];
  isLoading: boolean;
}
