export enum AutoDateTimeTypeEnum {
  ByNetwork,
  Manually,
}

export namespace AutoDateTimeTypeEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case AutoDateTimeTypeEnum.ByNetwork:
        return 'Controller.DateTimeSettings.ByNetwork';
      case AutoDateTimeTypeEnum.Manually:
        return 'Controller.DateTimeSettings.Manual';
      default:
        return value.toString();
    }
  }
}
