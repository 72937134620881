import { FlockConstants, FlockHelper, FlockState } from '@livestock/flock';
import { Action, createReducer, on } from '@ngrx/store';
import * as flockActions from './flock.actions';
import * as controllerActions
  from '../../../../controllers/src/lib/+state/current-controller/current-controller.actions';
import { WeightUnitEnum } from '@livestock/shared/enums';

export const initialState: FlockState = {
  isLoading: false,
  isDefaultWeightLoading: false,
  defaultWeightItems: [],
  settings: null,
  houseMode: {
    controllerID: null,
    targetTemperatureEmpty: 25,
    targetTemperaturePreheat: 33,
    targetTemperatureCleaning: 25,
    targetTemperatureCatching: 22,
    enableTemperatureAlarmEmpty: false,
    enableTemperatureAlarmPreheat: false,
    enableTemperatureAlarmCleaning: false,
    enableTemperatureAlarmCatching: false,
    ventilationEmpty: { min: 0, max: 7 },
    ventilationPreheat: { min: 0, max: 7 },
    ventilationCatching: { min: 0, max: 7 },
    ventilationCleaning: { min: 0, max: 7 },
    timeChangeNextMode: '00:00',
  },
  birdWeightSettings: null,
  birdWeightView: null,
  isFlockFinished: false,
};

const reducer = createReducer(
  initialState,
  on(
    flockActions.getFlockSettings,
    flockActions.getFlockHouseMode,
    flockActions.getFlockBirdWeightSettings,
    flockActions.getFlockBirdWeight,
    flockActions.updateFlockSettings,
    flockActions.updateFlockHouseMode,
    flockActions.updateFlockBirdWeightSettings,
    flockActions.updateFlockBirdWeight,
    flockActions.finishNewFlock,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    flockActions.getDefaultWeight,
    flockActions.changeDefaultWeight,
    (state) => ({
      ...state,
      isDefaultWeightLoading: true,
    }),
  ),
  on(
    flockActions.getDefaultWeightError,
    flockActions.changeDefaultWeightError,
    (state) => ({
      ...state,
      isDefaultWeightLoading: true,
    }),
  ),
  on(
    flockActions.getFlockSettingsError,
    flockActions.getFlockHouseModeError,
    flockActions.getFlockBirdWeightSettingsError,
    flockActions.getFlockBirdWeightError,
    flockActions.updateFlockSettingsError,
    flockActions.updateFlockHouseModeError,
    flockActions.updateFlockBirdWeightSettingsError,
    flockActions.updateFlockBirdWeightError,
    flockActions.updateFlockSettingsSuccess,
    flockActions.updateFlockHouseModeSuccess,
    flockActions.updateFlockBirdWeightSettingsSuccess,
    flockActions.updateFlockBirdWeightSuccess,
    flockActions.finishNewFlockError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(flockActions.getFlockSettingsSuccess,
    flockActions.setFlockSettings,
    (state, { settings }) => {
      return {
        ...state,
        settings: {
          ...state.settings,
          ...settings,
        },
        isLoading: false,
      };
    }),
  on(flockActions.getFlockHouseModeSuccess,
    flockActions.setFlockHouseMode,
    (state, { houseMode }) => {
      return {
        ...state,
        houseMode: {
          ...state.houseMode,
          ...houseMode,
        },
        isLoading: false,
      };
    }),
  on(flockActions.getFlockBirdWeightSettingsSuccess,
    flockActions.setFlockBirdWeightSettings,
    (state, { birdWeightSettings }) => {
      return {
        ...state,
        birdWeightSettings,
        isLoading: false,
      };
    }),
  on(flockActions.getFlockBirdWeightSuccess,
    (state, { view }) => {
      return {
        ...state,
        birdWeightView: view,
        isLoading: false,
      };
    }),
  on(flockActions.setFlockBirdWeight,
    (state, { birdWeightItems }) => {
      return {
        ...state,
        birdWeightView: {
          ...state.birdWeightView,
          items: birdWeightItems,
        },
        isLoading: false,
      };
    }),
  on(flockActions.getDefaultWeightSuccess,
    (state, { defaultWeightItems }) => {
      return {
        ...state,
        defaultWeightItems,
        isDefaultWeightLoading: false,
      };
    }),
  on(flockActions.changeDefaultWeightSuccess,
    (state, { defaultWeightItems, brand, controllerID }) => {
      return {
        ...state,
        defaultWeightItems,
        birdWeightSettings: {
          ...state.birdWeightSettings,
          defaultWeightBrandID: brand,
        },
        birdWeightView: {
          ...state.birdWeightView,
          items: FlockHelper.prepareBirdWeightFromDefault(controllerID, null, defaultWeightItems),
        },
        isDefaultWeightLoading: false,
      };
    }),
  on(flockActions.prepareNewFlock,
    (state, { controllerID, isDefaultFlock }) => {
      return {
        ...state,
        settings: {
          ...FlockConstants.NewFlockState.settings,
          // Now Metric is default for the new flock.
          // If needed we can get { units, customUnits, weightUnit } from params of this action
          // units: units,
          // customUnits: customUnits,
          flockNumber: isDefaultFlock
            ? 1
            : state.settings.flockNumber + 1,
          controllerID,
        },
        houseMode: {
          ...FlockConstants.NewFlockState.houseMode,
          controllerID,
          // TODO: check correct value
          timeChangeNextMode: '00:00',
        },
        birdWeightSettings: {
          controllerID,
          ...FlockConstants.NewFlockState.birdWeightSettings,
        },
        birdWeightView: {
          ...state.birdWeightView,
          items: FlockHelper.prepareBirdWeightFromDefault(controllerID, WeightUnitEnum.Gram, state.defaultWeightItems),
        },
      };
    }),
  on(flockActions.setFlockSettingsForNewFlock,
    (state, { settings }) => {
      let birdWeightSettings = state.birdWeightSettings;
      let birdWeightItems = state.birdWeightView.items;
      if (settings.units !== state.settings.units ||
        settings.unitsDetails?.weight !== state.settings.unitsDetails?.weight) {
        birdWeightSettings = {
          controllerID: settings.controllerID,
          ...FlockConstants.NewFlockState.birdWeightSettings,
        };
        birdWeightItems = FlockHelper.prepareBirdWeightFromDefault(settings.controllerID, null, state.defaultWeightItems);
      }

      return {
        ...state,
        settings,
        birdWeightSettings,
        birdWeightItems,
        isLoading: false,
      };
    }),
  on(flockActions.finishNewFlockSuccess,
    (state) => {
      return {
        ...state,
        isLoading: false,
        isFlockFinished: true,
      };
    }),
  on(flockActions.clearIsFlockFinished,
    (state) => {
      return {
        ...state,
        isFlockFinished: false,
      };
    }),
  on(flockActions.clearBirdWeightItems,
    (state) => {
      return {
        ...state,
        birdWeightSettings: null,
        birdWeightItems: null,
        separateMaleAndFemale: null,
      };
    }),
  on(flockActions.clearFlockSettings,
    (state) => {
      return {
        ...state,
        settings: null,
      };
    }),
  on(flockActions.clearFlockHouseMode,
    (state) => {
      return {
        ...state,
        houseMode: null,
      };
    }),
  on(controllerActions.clearControllerInfo, () => {
    return initialState;
  }),
);

export function flockReducer(state: FlockState | undefined, action: Action): any {
  return reducer(state, action);
}
