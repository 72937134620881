<div
  [class.keyboard__mobile]="isMobileApp"
  class="keyboard">
  <div
    [class.d-flex]="isMobileApp"
    [class.justify-between]="isMobileApp">
    <h6 class="mb-10">{{ title | translate }}</h6>

    <div class="keyboard-modes d-flex mb-20">
      <div
        (click)="switchKeyboardMode(ElementTypesEnum.VentilationDO)"
        [class.keyboard-mode__active]="keyboardMode === ElementTypesEnum.VentilationDO"
        class="keyboard-mode">
        {{ "Controls.Element.Relay" | translate }}
      </div>

      <div
        (click)="switchKeyboardMode(ElementTypesEnum.VentilationAO)"
        [class.keyboard-mode__active]="keyboardMode === ElementTypesEnum.VentilationAO"
        class="keyboard-mode">
        {{ "Controls.Element.Analog" | translate }}
      </div>
    </div>
  </div>

  <div class="icons gap-6">
    <ng-container *ngIf="keyboardMode === ElementTypesEnum.VentilationDO">
      <div
        *ngFor="let element of elementsDO"
        (click)="selectElement(element)"
        [class.icon-active]="isElementChosen(element?.elementID)"
        class="icon">
        <ls-svg-icon
          [color]="ColorsEnum.BlueDefault"
          [path]="path"
          class="mb-5"></ls-svg-icon>
        <span>{{ element.number }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="keyboardMode === ElementTypesEnum.VentilationAO">
      <div
        *ngFor="let element of elementsAO"
        (click)="selectElement(element)"
        [class.icon-active]="isElementChosen(element?.elementID)"
        class="icon">
        <ls-svg-icon
          [color]="ColorsEnum.BlueDefault"
          [path]="path"
          class="mb-5"></ls-svg-icon>
        <span>{{ element.number }}</span>
      </div>
    </ng-container>
  </div>

  <div class="buttons d-flex justify-between">
    <ls-svg-icon
      [color]="ColorsEnum.DefaultDisabled"
      [hasMargin]="true"
      [path]="isMobileApp ? 'keyboard/123-mobile' : 'keyboard/123'"></ls-svg-icon>
    <ls-svg-icon
      [class.selected]="isMobileApp"
      [hasMargin]="true"
      [path]="isMobileApp ? 'keyboard/backspace-mobile' : 'keyboard/backspace'"
      class="pointer"></ls-svg-icon>
    <ls-svg-icon
      (click)="addElements()"
      [hasMargin]="true"
      [path]="isMobileApp ? 'keyboard/enter-long-mobile' : 'keyboard/enter-long'"
      class="pointer"></ls-svg-icon>
  </div>
</div>
