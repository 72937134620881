<div class="popup d-flex flex-column justify-between add-day">
  <div class="popup-top mb-40">
    <div class="popup-header d-flex align-center justify-between mb-50">
      <h3
        [qaTags]="'qa-lbl-popup-title'"
        class="popup-title fw-500 fz-20">
        {{ "Installation.Card.Ventilation.Brand.SelectModel" | translate }}
      </h3>

      <img
        (click)="close()"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="close-icon pointer"
        src="/assets/icons/common/cross.svg" />
    </div>

    <div class="popup-body d-flex justify-between">
      <div class="box-wrapper">
        <h6 class="color-secondary mb-10">{{ "Installation.Card.Ventilation.Brand.Brand" | translate }}</h6>

        <div class="box">
          <div class="pl-12 pr-12 mb-10">
            <ls-search
              [fieldWithKeyboard]="false"
              [placeholder]="'Installation.Card.Ventilation.Brand.SearchBrand'"
              [style]="'light'"
              [(ngModel)]="brandSearchValue"></ls-search>
          </div>

          <div class="overflow-auto h-100-percent">
            <ng-container
              *ngIf="(brands$ | async | filterCollection : brandSearchValue)?.length > 0; else noBrandSearchResults">
              <div
                *ngFor="let brand of brands$ | async | filterCollection : brandSearchValue; trackBy: trackBy"
                (click)="selectBrand(brand.brandID)"
                [class.box-item__active]="brand.brandID == brandID"
                class="box-item">
                {{ brand.name }}
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div class="box-wrapper">
        <h6 class="color-secondary mb-10">{{ "Installation.Card.Ventilation.Brand.Model" | translate }}</h6>

        <div class="box">
          <div class="pl-12 pr-12 mb-10">
            <ls-search
              [fieldWithKeyboard]="false"
              [placeholder]="'Installation.Card.Ventilation.Brand.SearchModel'"
              [style]="'light'"
              [(ngModel)]="modelSearchValue"></ls-search>
          </div>

          <div class="overflow-auto h-100-percent">
            <ng-container *ngIf="brandID; else noBrandSelected">
              <ng-container
                *ngIf="
                  ((models$ | async)(brandID) | filterCollection : modelSearchValue)?.length > 0;
                  else noModelSearchResults
                ">
                <div
                  *ngFor="
                    let model of (models$ | async)(brandID) | filterCollection : modelSearchValue;
                    trackBy: trackBy
                  "
                  (click)="selectModel(model.modelID)"
                  [class.box-item__active]="model.modelID === modelID"
                  class="box-item">
                  {{ model.name }}
                </div>
              </ng-container>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-end buttons">
      <ls-button
        (click)="close()"
        [qaTags]="'qa-btn-cancel'"
        type="secondary">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>

      <ls-button
        (click)="saveValues()"
        [class.disabled]="!brandID || !modelID"
        class="ml-20"
        type="primary">
        {{ "Buttons.Ok" | translate | uppercase }}
      </ls-button>
    </div>
  </div>
</div>

<ng-template #noBrandSelected>
  <div class="d-flex align-center justify-center flex-column">
    <div class="empty-brand">
      <ls-svg-icon
        [color]="ColorsEnum.Default"
        [fullHeight]="true"
        [hasMargin]="true"
        [path]="'dashboard/ventilation'"
        class="mb-20"></ls-svg-icon>
    </div>
    <h6 class="color-secondary">{{ "Installation.Card.Ventilation.Brand.NoBrandSelected" | translate }}</h6>
  </div>
</ng-template>

<ng-template #noBrandSearchResults>
  <ng-container *ngIf="brandSearchValue; else noItems">
    <ls-no-search-results
      [fzClass]="'fz-16'"
      [imgClass]="'w-32'"
      [mbClass]="'mb-10'"
      [searchResult]="brandSearchValue"></ls-no-search-results>
  </ng-container>
</ng-template>

<ng-template #noModelSearchResults>
  <ng-container *ngIf="modelSearchValue; else noItems">
    <ls-no-search-results
      [fzClass]="'fz-16'"
      [imgClass]="'w-32'"
      [mbClass]="'mb-10'"
      [searchResult]="modelSearchValue"></ls-no-search-results>
  </ng-container>
</ng-template>

<ng-template #noItems>
  <div class="p-12">
    {{ "Installation.Card.Ventilation.Brand.NoItems" | translate }}
  </div>
</ng-template>
