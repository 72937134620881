<div
  [formGroup]="form"
  class="thresholds d-flex flex-column">
  <div class="d-flex flex-row flex-column-md justify-end">
    <div class="thresholds__main-title d-flex flex-row align-center mr-15">
      <ls-svg-icon
        [path]="'common/warning-small'"
        class="mr-5">
      </ls-svg-icon>
      <span>{{ "AirTreatment.HighAlarmThreshold" | translate }}:</span>
    </div>
    <div class="d-flex flex-row justify-between-md mt-5-md">
      <div
        [class.mobile-border]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.Humidity" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.highAlarmThresholdHum }}</span>
          <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
        </div>
        <ls-input-integer
          *ngIf="editMode"
          [class.w-input-70]="platformService.isMobileApp"
          [class.w-input-80]="!platformService.isMobileApp"
          [fieldWithKeyboard]="true"
          [max]="AirTreatmentConstants.MaxPercentage"
          [min]="AirTreatmentConstants.MinPercentage"
          [qaTags]="'qa-txt-high-alarm-threshold-hum'"
          [showTooltipOnHover]="!platformService.isMobileApp"
          class="align-center input-decimal"
          errorField
          formControlName="highAlarmThresholdHum">
          <ng-container suffix>
            <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
          </ng-container>
        </ls-input-integer>
        <div class="d-none-md mr-9 ml-9">|</div>
      </div>
      <div
        [class.mobile-border]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.CO2" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.highAlarmThresholdCO2 }}</span>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div
          *ngIf="editMode"
          class="d-flex flex-row align-center">
          <ls-input-integer
            [class.w-input-70]="platformService.isMobileApp"
            [class.w-input-80]="!platformService.isMobileApp"
            [fieldWithKeyboard]="true"
            [max]="AirTreatmentConstants.MaxPPM"
            [min]="AirTreatmentConstants.MinPPM"
            [qaTags]="'qa-txt-high-alarm-threshold-co2'"
            [showTooltipOnHover]="!platformService.isMobileApp"
            class="align-center"
            errorField
            formControlName="highAlarmThresholdCO2">
          </ls-input-integer>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div class="d-none-md mr-9 ml-9">|</div>
      </div>
      <div
        [class.w-30-percent]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.Ammonia" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.highAlarmThresholdAmmonia }} </span>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div
          *ngIf="editMode"
          class="d-flex flex-row align-center">
          <ls-input-integer
            [class.w-input-70]="platformService.isMobileApp"
            [class.w-input-80]="!platformService.isMobileApp"
            [fieldWithKeyboard]="true"
            [max]="AirTreatmentConstants.MaxPercentage"
            [min]="AirTreatmentConstants.MinPercentage"
            [qaTags]="'qa-txt-high-alarm-threshold-ammonia'"
            [showTooltipOnHover]="!platformService.isMobileApp"
            class="align-center"
            errorField
            formControlName="highAlarmThresholdAmmonia">
          </ls-input-integer>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row flex-column-md justify-end mt-15">
    <div class="thresholds__main-title d-flex flex-row align-center mr-15">
      <span>{{ "AirTreatment.ShutoffDiff" | translate }}:</span>
    </div>
    <div class="d-flex flex-row justify-between-md mt-5-md">
      <div
        [class.mobile-border]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.Humidity" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.shutoffHum }}</span>
          <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
        </div>
        <ls-input-integer
          *ngIf="editMode"
          [class.w-input-70]="platformService.isMobileApp"
          [class.w-input-80]="!platformService.isMobileApp"
          [fieldWithKeyboard]="true"
          [max]="AirTreatmentConstants.MaxPercentage"
          [min]="AirTreatmentConstants.MinPercentage"
          [qaTags]="'qa-txt-shutoff-hum'"
          [showTooltipOnHover]="!platformService.isMobileApp"
          class="align-center input-decimal"
          errorField
          formControlName="shutoffHum">
          <ng-container suffix>
            <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
          </ng-container>
        </ls-input-integer>
        <div class="d-none-md mr-9 ml-9">|</div>
      </div>
      <div
        [class.mobile-border]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.CO2" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.shutoffCO2 }}</span>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div
          *ngIf="editMode"
          class="d-flex flex-row align-center">
          <ls-input-integer
            [class.w-input-70]="platformService.isMobileApp"
            [class.w-input-80]="!platformService.isMobileApp"
            [fieldWithKeyboard]="true"
            [max]="AirTreatmentConstants.MaxPPM"
            [min]="AirTreatmentConstants.MinPPM"
            [qaTags]="'qa-txt-shutoff-co2'"
            [showTooltipOnHover]="!platformService.isMobileApp"
            class="align-center"
            errorField
            formControlName="shutoffCO2">
          </ls-input-integer>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div class="d-none-md mr-9 ml-9">|</div>
      </div>
      <div
        [class.w-30-percent]="!editMode"
        class="d-flex flex-row flex-column-md align-center align-start-md position-relative">
        <span class="thresholds__title">{{ "AirTreatment.Ammonia" | translate }}</span>
        <div
          *ngIf="!editMode"
          class="d-flex flex-row align-center">
          <span class="thresholds__value">{{ form.value?.shutoffAmmonia }} </span>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
        <div
          *ngIf="editMode"
          class="d-flex flex-row align-center">
          <ls-input-integer
            [class.w-input-70]="platformService.isMobileApp"
            [class.w-input-80]="!platformService.isMobileApp"
            [fieldWithKeyboard]="true"
            [max]="AirTreatmentConstants.MaxPercentage"
            [min]="AirTreatmentConstants.MinPercentage"
            [qaTags]="'qa-txt-shutoff-ammonia'"
            [showTooltipOnHover]="!platformService.isMobileApp"
            class="align-center"
            errorField
            formControlName="shutoffAmmonia">
          </ls-input-integer>
          <div class="unit">{{ "Ppm" | translate }}</div>
        </div>
      </div>
    </div>
  </div>
</div>
