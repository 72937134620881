<div
  [class.rtl]="languageService.isRtl"
  class="popup d-flex flex-column justify-between">
  <div class="popup-top">
    <h3
      [qaTags]="'qa-lbl-popup-title'"
      class="popup-title fw-500 fz-20 mb-15 d-flex align-center">
      <span>{{ "Farms.AddDeviceDialog.Congratulations" | translate }}</span>
    </h3>

    <div class="popup-body mb-45">
      <div class="d-flex m-auto w-mc mb-15">
        <ls-svg-icon [path]="'success-tick'"></ls-svg-icon>
      </div>

      <p class="text-center fz-20">
        {{ "Farms.AddDeviceDialog.Device" | translate }}
        <span class="fw-500">{{
          "Farms.AddDeviceDialog.IDWithConnectionNumber" | translate : { connectionNumber: data.connectionNumber }
        }}</span>
        {{ "Farms.AddDeviceDialog.HouseNumber" | translate }}
        <span class="fw-500">{{ data.currentHouseNumber }}</span>
        {{ "Farms.AddDeviceDialog.WasSuccessfullyConnectedTo" | translate }}
        <span class="fw-500">{{ data.farmName }}</span> {{ "Farms.AddDeviceDialog.Farm" | translate }}.
      </p>
    </div>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-center buttons">
      <ls-button
        (click)="close()"
        [qaTags]="'qa-btn-ok'"
        class="button"
        type="secondary">
        {{ "Buttons.OK" | translate }}
      </ls-button>
    </div>
  </div>
</div>
