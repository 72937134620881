export interface CoolingPeriod {
    controllerID: number;
    dayNumber: number;
    periodID?: number;
}

export interface CoolingProgram {
    programID: number;
    periodID: number;
    startTime: number;
    endTime: number;
    onTime: number;
    offTime: number;
    startTemp: number;
    stopTemp: number;
    ventStartLevel: number;
    humStopLevel: number;
    element1Connected: boolean;
    element2Connected: boolean;
    element3Connected: boolean;
}

export interface SingleCoolingProgram {
    hasElement1: boolean;
    hasElement2: boolean;
    hasElement3: boolean;
    items: CoolingProgram[];
}