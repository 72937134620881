import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent,
  StepperComponent,
  SvgIconComponent,
} from '@livestock/ui';
import {
  BirdWeightFormComponent,
  BirdWeightSettingsFormComponent,
  FlockSettingsFormComponent,
  FlockWizardStepsEnum,
  HouseModeFormComponent,
  IFlockBirdWeightSettingsView,
  IFlockBirdWeightView,
  IFlockDefaultWeightView,
  IFlockHouseModeView,
  IFlockSettingsView,
  WeightMethodEnum,
  clearBirdWeightItems,
  clearFlockSettings,
  clearIsFlockFinished,
  finishNewFlock,
  selectDefaultWeightItems,
  selectFlockBirdWeightItems,
  selectFlockBirdWeightSettings,
  selectFlockHouseMode,
  selectFlockSettings,
  selectIsFlockFinished,
  selectIsLoading,
  selectReferenceWeight,
  setFlockBirdWeight,
  setFlockBirdWeightSettings,
  setFlockHouseMode,
  selectTemperatureUnit,
  selectWeightUnit,
  setFlockSettingsForNewFlock,
  VentilationWorkingModeEnum,
  selectReferenceTable,
  getDefaultWeight,
  selectIsDefaultWeightLoading,
} from '@livestock/flock';
import {
  Observable,
  Subscription,
  filter, first,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { DialogsService, LanguageService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { ChickenBrandWeight, StorageItem, TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { wasChangedAndNotNull, wasChanged } from '@livestock/shared/rxjs-operators';
import { selectControllerVentilationMode } from '@livestock/controllers';

@Component({
  selector: 'ls-flock-wizard',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    KeyboardComponent,
    ButtonComponent,
    ButtonWithIconComponent,
    StepperComponent,
    SvgIconComponent,
    BirdWeightFormComponent,
    BirdWeightSettingsFormComponent,
    FlockSettingsFormComponent,
    HouseModeFormComponent,
    LoadingComponent,
  ],
  templateUrl: './flock-wizard.component.html',
  styleUrls: ['./flock-wizard.component.scss'],
})
export class FlockWizardComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  // components variables
  currStep = 1;
  isValid = true;
  isValidWeight = true;
  isFromDashboard = false;
  isRedirectInProgress = false;
  settings: IFlockSettingsView;
  houseMode: IFlockHouseModeView;
  birdWeightSettings: IFlockBirdWeightSettingsView;
  birdWeightItems: IFlockBirdWeightView[];
  originalSettings: IFlockSettingsView;
  originalHouseMode: IFlockHouseModeView;
  originalBirdWeightSettings: IFlockBirdWeightSettingsView;
  originalBirdWeightItems: IFlockBirdWeightView[];
  ventilationWorkingMode: VentilationWorkingModeEnum;
  weightUnit: WeightUnitEnum;

  // subs
  sub$ = new Subscription();
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectTemperatureUnit);
  weightUnit$: Observable<WeightUnitEnum> = this.store.select(selectWeightUnit);
  defaultWeightItems$: Observable<IFlockDefaultWeightView[]> = this.store.select(selectDefaultWeightItems);
  referenceTable$: Observable<ChickenBrandWeight> = this.store.select(selectReferenceTable);
  refWeight$: Observable<{ weight: number, femaleWeight: number, maleWeight: number }>;
  isFlockFinished$: Observable<boolean> = this.store.select(selectIsFlockFinished);
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  isDefaultWeightLoading$: Observable<boolean> = this.store.select(selectIsDefaultWeightLoading);

  // enums
  KeyboardModeEnum = KeyboardModeEnum;
  FlockWizardStepsEnum = FlockWizardStepsEnum;
  WeightMethodEnum = WeightMethodEnum;

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private store: Store,
    private router: Router,
    private dialogsService: DialogsService,
    private activatedRoute: ActivatedRoute,
  ) {
    this.isFromDashboard = this.activatedRoute.snapshot.queryParams['isFromDashboard'];
  }

  ngOnInit(): void {
    this.sub$.add(
      this.isFlockFinished$.pipe(filter(x => x))
        .subscribe(async () => {
          const message = 'Flock.NewFlock.NewFlockConfirmationSuccess';
          const title = 'Flock.NewFlock.NewFlockConfirmation';
          await this.dialogsService.ok(
            message, title,
          );
          this.store.dispatch(clearIsFlockFinished());
          this.redirectToPrevPage();
        }),
    );

    this.sub$.add(
      this.store.select(selectFlockSettings)
        .pipe(
          wasChanged(),
          filter(settings => settings != null && !!settings.controllerID),
        ).subscribe((settings) => {
        if (!this.originalSettings) {
          this.originalSettings = settings;
        }
        this.settings = settings;
      }),
    );

    this.store.select(selectControllerVentilationMode).pipe(
      filter(res => res != null),
      first(),
    ).subscribe(ventilationWorkingMode => {
      this.ventilationWorkingMode = ventilationWorkingMode;
    });

    this.sub$.add(
      this.store.select(selectFlockHouseMode)
        .pipe(
          wasChanged(),
          filter(houseMode => houseMode != null && !!houseMode.controllerID),
        ).subscribe((houseMode) => {
        if (!this.originalHouseMode) {
          this.originalHouseMode = houseMode;
        }
        this.houseMode = houseMode;
      }),
    );

    this.sub$.add(
      this.store.select(selectFlockBirdWeightSettings)
        .pipe(
          wasChanged(),
          filter(birdWeightSettings => birdWeightSettings != null && !!birdWeightSettings.controllerID),
        ).subscribe((birdWeightSettings) => {
        if (!this.originalBirdWeightSettings) {
          this.originalBirdWeightSettings = birdWeightSettings;
        }
        this.birdWeightSettings = birdWeightSettings;
      }),
    );

    this.sub$.add(
      this.store.select(selectFlockBirdWeightItems)
        .pipe(wasChangedAndNotNull())
        .subscribe((birdWeightItems) => {
          if (!this.originalBirdWeightItems) {
            this.originalBirdWeightItems = birdWeightItems;
          }
          this.birdWeightItems = birdWeightItems;
        }),
    );

    this.sub$.add(
      this.weightUnit$.subscribe(weightUnit => {
        this.weightUnit = weightUnit;
      }),
    );
  }

  changedSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockSettingsForNewFlock({ settings: event.formValues }));
    }
  }

  changedHouseMode(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockHouseMode({ houseMode: event.formValues }));
    }
  }

  changedBirdWeightSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockBirdWeightSettings({ birdWeightSettings: event.formValues }));
    }
  }

  changedBirdWeight(event: { formValues: any, isValid: boolean }): void {
    this.isValidWeight = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockBirdWeight({ birdWeightItems: event.formValues }));
    }
  }

  activeDayChanged(day: number): void {
    this.refWeight$ = this.store.select(selectReferenceWeight(day));
  }

  async cancel(): Promise<void> {
    if (!this.isDirtyAnyForm()) {
      this.redirectToPrevPage();
      return;
    }

    const result = await this.dialogsService.canContinueAction();

    if (result) {
      this.redirectToPrevPage();
    }
  }

  redirectToPrevPage(): void {
    this.isRedirectInProgress = true;
    this.store.dispatch(clearFlockSettings());
    const url = this.isFromDashboard
      ? AppRoutes.DASHBOARD
      : `controller/${LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)}/flock-settings`;
    this.router.navigateByUrl(url);
  }

  isDirtyAnyForm(): boolean {
    return JSON.stringify(this.originalSettings) !== JSON.stringify(this.settings) ||
      JSON.stringify(this.originalHouseMode) !== JSON.stringify(this.houseMode) ||
      JSON.stringify(this.originalBirdWeightSettings) !== JSON.stringify(this.birdWeightSettings) ||
      JSON.stringify(this.originalBirdWeightItems) !== JSON.stringify(this.birdWeightItems);
  }

  back(): void {
    this.currStep = this.currStep - 1;
    this.isValid = true;
  }

  next(): void {
    if (!this.isValid) {
      return;
    }
    if (this.currStep === FlockWizardStepsEnum.Settings) {
      this.store.dispatch(getDefaultWeight({ weightUnit: this.weightUnit }));
    }
    this.currStep = this.currStep + 1;
  }

  save(): void {
    if (!this.isValid || !this.isValidWeight) {
      return;
    }

    this.store.dispatch(finishNewFlock());
  }

  canDeactivate(): boolean {
    return this.isRedirectInProgress || !this.isDirtyAnyForm();
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearBirdWeightItems());
    this.sub$.unsubscribe();
  }
}
