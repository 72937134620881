<ls-page-wrapper
  (back)="cancel()"
  [hasArrow]="true"
  [isLoading]="loading$ | async"
  [title]="'Controls.Element.LightProgram'">
  <div class="svg-content header__buttons buttons d-flex align-center">
    <ls-svg-icon
      *ngIf="!editMode"
      (click)="enableEdit()"
      [path]="platformService.isMobileApp ? 'mobile/edit' : 'installation/edit'"
      qaTags="qa-btn-edit"
      class="pointer">
    </ls-svg-icon>
    <ng-container *ngIf="!platformService.isMobileApp || !editMode">
      <ls-svg-icon
        *ngIf="editMode"
        (click)="cancelEdit()"
        [color]="ColorsEnum.BlueDark"
        qaTags="qa-btn-table"
        class="icon pointer"
        path="installation/table">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="goToMappings()"
        [class.pointer-events-none]="editMode"
        [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
        [path]="platformService.isMobileApp ? 'mobile/trapec' : 'installation/trapec'"
        qaTags="qa-btn-graph"
        class="pointer">
      </ls-svg-icon>
      <ls-svg-icon
        [class.pointer-events-none]="editMode"
        [color]="ColorsEnum.DefaultDisabled"
        [path]="platformService.isMobileApp ? 'installation/graph-mobile' : 'installation/graph'"
        class="pointer">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="goToSettings()"
        [class.pointer-events-none]="editMode"
        [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
        [path]="platformService.isMobileApp ? 'mobile/settings' : 'installation/settings'"
        qaTags="qa-btn-settings"
        class="pointer">
      </ls-svg-icon>
    </ng-container>
  </div>

  <div
    [class.padded]="!editMode"
    class="main-content program">
    <ls-add-program-day-mobile-dialog
      *ngIf="editMode && platformService.isMobileApp"
      (isDisabledChange)="setDisabledState($event)"
      (clearBeforeDayAdding)="clearBeforeDayAdding($event)"
      (deleteDay)="deleteDay()"
      (onFocusOut)="addNewDayMobile($event)"
      [currentPeriod]="currentPeriod$ | async"
      [periods]="periods$ | async">
    </ls-add-program-day-mobile-dialog>
    <div
      [class.min-height-0]="!platformService.isMobileApp"
      [class.position-relative]="platformService.isMobileApp"
      class="h-100-percent">
      <div class="program-header color-secondary d-flex align-center justify-between mb-20 mb-10-md">
        <h6 [class.d-none]="editMode && platformService.isMobileApp">
          {{ "Controls.Element.Days" | translate }}
        </h6>
        <p *ngIf="!platformService.isMobileApp; else programsCount">
          {{ "Controls.Element.TotaldailyONtime" | translate }}
          <span class="color-primary fw-500">{{ getTime() }}</span>
        </p>
        <ng-template #programsCount>
          <p>{{ "Controls.Program.Program" | translate }} {{ currentProgramIndex }} / {{ getRows()?.length }}</p>
          <img
            *ngIf="editMode && form?.invalid"
            src="/assets/icons/common/warning-icon.svg" />
        </ng-template>
      </div>

      <div
        [class.gap-unset]="platformService.isMobileApp && editMode"
        class="program-lighting program-body h-100-percent d-flex justify-start justify-between-md h-100-percent gap-unset">
        <div
          [class.d-none]="editMode && platformService.isMobileApp"
          class="program-days">
          <div
            #programDaysScrollBar
            [class.program-days__scrollbar-short]="editMode"
            class="program-days__scrollbar">
            <div
              *ngFor="let period of periods$ | async"
              (click)="setPeriod(period)"
              [class.program-day__active]="period?.periodID === (currentPeriod$ | async)?.periodID"
              [qaTags]="
                period?.periodID === (currentPeriod$ | async)?.periodID ? 'qa-lbl-selected-day' : 'qa-tbl-days-list'
              "
              class="program-day">
              {{ period.dayNumber }}
            </div>
          </div>
        </div>

        <div
          *ngIf="form"
          [class.h-85-percent]="!platformService.isMobileApp"
          [class.overflow-auto]="!platformService.isMobileApp"
          class="content-wrapper__data-container flex-grow-1">
          <ls-lighting-program-table-mobile
            *ngIf="platformService.isMobileApp; else desktopTable"
            (onChange)="onTableChange($event)"
            (setFocusedTimepicker)="setFocusedTimepicker($event)"
            #mobileTable
            [availableElems]="availableElems$ | async"
            [editMode]="editMode"
            [isDisabled]="isDisabled"
            [items]="getRows()"
            [noFilledRows]="areNoFilledRows()">
          </ls-lighting-program-table-mobile>
          <ng-template #desktopTable>
            <div
              [formGroup]="form"
              class="item__container">
              <div class="table w-1-25 w-2-25 w-3-25 w-4-25 w-5-25">
                <div class="table-row table-header h-60">
                  <div class="table-cell blue">
                    {{ "Controls.Element.Program" | translate }}
                  </div>
                  <div class="table-cell blue">
                    {{ "Controls.Element.StartTime" | translate }}
                  </div>
                  <div
                    *ngFor="let elems of availableElems$ | async; let i = index"
                    class="table-cell blue">
                    {{ elems.name }}
                  </div>
                </div>
                <div
                  *ngFor="let lightingForm of getRows(); let i = index; let isLast = last"
                  (click)="selectRow(lightingForm, i)"
                  [class.active]="editMode && selectedProgramId && selectedProgramId === lightingForm.value.programID"
                  [class.last-row]="isLast"
                  [formGroup]="lightingForm"
                  class="table-row padding-top-11">
                  <div class="table-cell no-border-bottom blue">
                    <div
                      *ngIf="!editMode"
                      class="d-flex flex-row align-center">
                      {{ lightingForm.value.name }}
                    </div>
                    <ls-input-text
                      *ngIf="editMode"
                      [centered]="true"
                      [class.hidden]="!editMode && areNoFilledRows()"
                      [fieldWithKeyboard]="true"
                      [maxLength]="ElementConstants.MaxNameLength"
                      [qaTags]="'qa-txt-name'"
                      errorField
                      formControlName="name">
                    </ls-input-text>
                  </div>
                  <div class="table-cell no-border-bottom">
                    <ngx-mat-timepicker
                      (click)="setFocusedTimepicker($event)"
                      #timepicker
                      [class.border-none__no-editing-mode]="!editMode"
                      [class.focused]="isFocused([timepicker, currentTimeUUID])"
                      [class.hidden]="!editMode && areNoFilledRows()"
                      [class.ng-invalid]="lightingForm.hasError(ErrorFieldEnum.OuterOverlap)"
                      [disabled]="lightingForm?.disabled"
                      [enableMeridian]="selectHourFormatForTimePicker$ | async"
                      [showSeconds]="false"
                      [showSpinners]="false"
                      class="program__time-picker w-120"
                      formControlName="startTime">
                    </ngx-mat-timepicker>
                  </div>
                  <div
                    *ngFor="let lightingsElement of getElements(lightingForm)"
                    class="table-cell no-border-bottom">
                    <ng-container *ngIf="lightingsElement.value.elementType === ElementTypesEnum.LightAO">
                      <div
                        *ngIf="!editMode"
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [class.lighting-button--max]="lightingsElement.value.intensity === 100"
                        [class.lighting-button--mid]="
                          lightingsElement.value.intensity < 100 && lightingsElement.value.intensity > 0
                        "
                        [class.lighting-button--off]="lightingsElement.value.intensity === 0"
                        class="lighting-button lighting-button--ao ltr">
                        <ls-svg-icon
                          [color]="lightingsElement.value.intensity > 0 ? ColorsEnum.BlueDefault : ColorsEnum.Color90"
                          path="dashboard/light-filled">
                        </ls-svg-icon>
                        {{ lightingsElement.value.intensity }}%
                      </div>
                      <div
                        *ngIf="editMode"
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [formGroup]="lightingsElement"
                        class="lighting-button lighting-button--ao">
                        <ls-svg-icon
                          [color]="ColorsEnum.Yellow"
                          path="dashboard/light-filled"></ls-svg-icon>
                        <ls-input-integer
                          [fieldWithKeyboard]="true"
                          [max]="LightingProgramConstants.onTimeMax"
                          [min]="LightingProgramConstants.onTimeMin"
                          [noMaxWidth]="true"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="intensity">
                          <ng-container suffix>
                            <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                          </ng-container>
                        </ls-input-integer>
                      </div>
                    </ng-container>
                    <ng-container *ngIf="lightingsElement.value.elementType === ElementTypesEnum.LightDO">
                      <button
                        (click)="
                          editMode && lightingsElement.controls.enabled.setValue(!lightingsElement.value.enabled)
                        "
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [class.lighting-button--bordered]="editMode"
                        [class.lighting-button--off]="!lightingsElement.value.enabled"
                        [class.lighting-button--on]="lightingsElement.value.enabled"
                        class="lighting-button lighting-button__on-of"
                        type="button">
                        <ls-svg-icon
                          [color]="lightingsElement.value.enabled ? ColorsEnum.BlueDefault : ColorsEnum.Color90"
                          path="dashboard/light-filled"></ls-svg-icon>
                      </button>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <div
            *ngIf="!editMode && !platformService.isMobileApp"
            [class.align-center]="platformService.isMobileApp"
            class="program-table__block d-flex align-center">
            <img src="/assets/icons/common/note-icon.svg" />
            <span class="program-table__block__text">
              {{
                "Controls.Program.TheseValuesAreForDaysFromTo"
                  | translate
                    : {
                        from: (currentPeriod$ | async).dayNumber,
                        to: periods$ | async | findNextTo : (currentPeriod$ | async)
                      }
              }}
            </span>
          </div>
        </div>
      </div>
    </div>

    <div
      *ngIf="editMode"
      class="program__footer">
      <div class="program__footer__buttons d-flex align-center">
        <ls-button
          *ngIf="!platformService.isMobileApp"
          (click)="addDay()"
          [type]="'secondary'"
          class="program__footer-button program__footer-button--icon mr-16">
          <ls-svg-icon
            [color]="ColorsEnum.Primary"
            qaTags="qa-btn-add-day"
            path="table/plus">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="!platformService.isMobileApp"
          (click)="deleteDay()"
          [class.disabled]="(periods$ | async).length === 1"
          [type]="'secondary'"
          class="program__footer-button program__footer-button--icon mr-35">
          <ls-svg-icon
            [color]="(periods$ | async).length === 1 ? ColorsEnum.DefaultDisabled : ColorsEnum.Primary"
            qaTags="qa-btn-delete-day"
            path="table/delete">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="getRows() as rows"
          (click)="addEmptyRow()"
          [class.disabled]="rows.length >= 10"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [type]="'secondary'"
          class="program__footer-button mr-10">
          <span *ngIf="!platformService.isMobileApp">{{ "Controls.Element.AddProgram" | translate }}</span>
          <ls-svg-icon
            *ngIf="platformService.isMobileApp"
            [color]="ColorsEnum.Primary"
            qaTags="qa-btn-add-program"
            path="table/plus">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="currentPeriod$ | async as cp"
          (click)="deleteProgram(cp)"
          [class.disabled]="selectedProgramIndex === null"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [type]="'secondary'"
          class="program__footer-button">
          <span *ngIf="!platformService.isMobileApp">{{ "Controls.Program.DeleteProgram" | translate }}</span>
          <ls-svg-icon
            *ngIf="platformService.isMobileApp"
            [color]="selectedProgramIndex === null ? ColorsEnum.DefaultDisabled : ColorsEnum.Primary"
            path="table/delete">
          </ls-svg-icon>
        </ls-button>
      </div>
      <div class="program__footer__buttons d-flex align-center">
        <ls-button
          (click)="cancelEdit()"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [type]="'secondary'"
          qaTags="qa-btn-cancel"
          class="program__footer-button mr-16">
          {{ "Controls.Element.Cancel" | translate }}
        </ls-button>
        <ls-button
          (click)="save()"
          [class.disabled]="isDisabled || form.invalid || (formUnchanged && !areNoFilledRows())"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          qaTags="qa-btn-save"
          class="program__footer-button">
          {{ "Controls.Element.Save" | translate }}
        </ls-button>
      </div>
    </div>
    <div
      *ngIf="!editMode && !(currentProgram$ | async)?.items?.length && !platformService.isMobileApp"
      class="program-empty">
      <ls-svg-icon
        [hasMargin]="true"
        path="controls/no-programs"></ls-svg-icon>
      <p class="color-secondary">
        {{ "Controls.Element.Creation" | translate }}
      </p>
      <ls-button (click)="enableEdit()">
        {{ "Controls.Element.EditProgram" | translate }}
      </ls-button>
    </div>
  </div>
  <ls-keyboard
    *ngIf="editMode && !platformService.isMobileApp"
    [context]="dialogRef ? 'mat-dialog-container' : null"
    [hasTimePicker]="(isAMPM$ | async) && (selectHourFormatForTimePicker$ | async)"
    class="keyboard-content keyboard ml-20" />
</ls-page-wrapper>
