import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable, map } from 'rxjs';

import { IStaticPressure } from '../interfaces/static-pressure.interface';
import { AirPressureUnitEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class StaticPressureService {
  constructor(private http: HttpClient) {}

  getStaticPressure(controllerID: number, temperatureUnit: TemperatureUnitEnum, airPressureUnit: AirPressureUnitEnum): Observable<IStaticPressure> {
    return this.http.get<IStaticPressure>(`${baseUrl}/${controllerID}/static-pressure/definitions`).pipe(
      map((staticPressure) => {
        let parsedStaticPressure: IStaticPressure = staticPressure;

        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          parsedStaticPressure =  this.parseStaticPressureTemperature(staticPressure, TemperatureUnitEnum.Fahrenheit);
        }

        if (airPressureUnit !== AirPressureUnitEnum.Pascal) {
          parsedStaticPressure =  this.parseStaticPressureVentilation(parsedStaticPressure, AirPressureUnitEnum.Pascal, airPressureUnit);
        }

        return parsedStaticPressure;
      }),
    );
  }

  updateStaticPressure(
    controllerID: number,
    view: IStaticPressure,
    temperatureUnit: TemperatureUnitEnum,
    airPressureUnit: AirPressureUnitEnum,
  ): Observable<IStaticPressure> {
    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
      view = this.parseStaticPressureTemperature(view, TemperatureUnitEnum.Celsius);
    }

    if (airPressureUnit !== AirPressureUnitEnum.Pascal) {
      view = this.parseStaticPressureVentilation(view, airPressureUnit, AirPressureUnitEnum.Pascal);
    }

    return this.http.put<IStaticPressure>(`${baseUrl}/${controllerID}/static-pressure/definitions`, view).pipe(
      map((staticPressure) => {
        let parsedStaticPressure: IStaticPressure = staticPressure;

        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          parsedStaticPressure = this.parseStaticPressureTemperature(parsedStaticPressure, TemperatureUnitEnum.Fahrenheit);
        }

        if (airPressureUnit !== AirPressureUnitEnum.Pascal) {
          parsedStaticPressure = this.parseStaticPressureVentilation(parsedStaticPressure, AirPressureUnitEnum.Pascal, airPressureUnit);
        }

        return parsedStaticPressure;
      }),
    );
  }

  parseStaticPressureTemperature(staticPressure: IStaticPressure, temperatureUnit: TemperatureUnitEnum): IStaticPressure {
    const temperatureConvertFunction =
      temperatureUnit === TemperatureUnitEnum.Fahrenheit
        ? ConvertHelper.celsiusToFahrenheit
        : ConvertHelper.fahrenheitToCelsius;
      console.log(`converting temp to ${temperatureUnit}`);
    return {
      ...staticPressure,
      outsideTempColdVentilation: temperatureConvertFunction(staticPressure.outsideTempColdVentilation, 1),
      outsideTempWarmVentilation: temperatureConvertFunction(staticPressure.outsideTempWarmVentilation, 1),
    };
  }

  parseStaticPressureVentilation(staticPressure: IStaticPressure, airPressureUnitFrom: AirPressureUnitEnum, airPressureUnitTo: AirPressureUnitEnum): IStaticPressure {
    const conversionFunction = ConvertHelper.getAirConversionMethod(airPressureUnitFrom, airPressureUnitTo);

    return {
      ...staticPressure,
      bandVentilation: conversionFunction(staticPressure.bandVentilation, 1),
      bandTunnel: conversionFunction(staticPressure.bandTunnel, 1),
      targetPressureColdVentilation: conversionFunction(staticPressure.targetPressureColdVentilation, 1),
      targetPressureWarmVentilation: conversionFunction(staticPressure.targetPressureWarmVentilation, 1),
      pressureAlarmLowVentilation: conversionFunction(staticPressure.pressureAlarmLowVentilation, 1),
      pressureAlarmHightVentilation: conversionFunction(staticPressure.pressureAlarmHightVentilation, 1),
      pressureAlarmLowTunnel: conversionFunction(staticPressure.pressureAlarmLowTunnel, 1),
      pressureAlarmHightTunnel: conversionFunction(staticPressure.pressureAlarmHightVentilation, 1),
    };
  }
}
