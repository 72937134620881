import { IGetHeatingProgramZoneView } from '../interfaces/get-heating-program-zone-view.interface';
import { IHeatingProgramZoneItem } from '../interfaces/heating-program-zone-item.interface';

export interface HeatingProgramZoneState {
  isLoading: boolean;
  view: IGetHeatingProgramZoneView;
  originalZones: IHeatingProgramZoneItem[];
  activeZoneID: number;
  selectedHeaters: number[];
}
