import { Action, createReducer, on } from '@ngrx/store';
import * as currentControllerActions from './current-controller.actions';
import * as upsertControllerActions from '../upsert-controller/upsert-controller.actions';
import { CurrentControllerState } from './current-controller.state';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import {
  clearControllerInfo,
  disconnectController, disconnectControllerError,
  disconnectControllerSuccess, reconnectController, reconnectControllerError, reconnectControllerSuccess,
  setControllerLanguage,
} from './current-controller.actions';
import * as flockActions from '../../../../../flock/src/lib/+state/flock.actions';
import { GlobalConstants } from '@livestock/shared/constants';
import { ControllerLanguageEnum } from '@livestock/controllers/enums';

export const CURRENT_CONTROLLER_FEATURE_KEY = 'currentController';
const initialState: CurrentControllerState = {
  controllerInfo: null,
  activeControllerID: null,
  controllerLanguage: null,
};

const reducer = createReducer(
  initialState,
  on(currentControllerActions.getControllerInfoSuccess, (state, { controllerInfo }) => {
    LocalStorageService.setStorageItem(StorageItem.ActiveControllerID, controllerInfo.controllerID);
    return {
      ...state,
      controllerInfo,
      activeControllerID: controllerInfo?.controllerID,
    };
  }),
  on(currentControllerActions.setActiveControllerID, (state, { controllerID }) => {
    LocalStorageService.setStorageItem(StorageItem.ActiveControllerID, controllerID);
    return {
      ...state,
      activeControllerID: controllerID,
    };
  }),
  on(upsertControllerActions.updateDateTimeSettingsSuccess, (state, { dateTimeSettings }) => {
    return {
      ...state,
      controllerInfo: {
        ...state.controllerInfo,
        manuallyDateTime: dateTimeSettings.manuallyDateTimeMoment,
        hoursFormat: dateTimeSettings.hoursFormat,
        timeZoneID: dateTimeSettings.timeZoneID,
      },
    };
  }),
  on(flockActions.finishNewFlockSuccess, (state, { flockView }) => {
    return {
      ...state,
      controllerInfo: {
        ...state.controllerInfo,
        units: flockView.flockSettings.units,
        unitsDetails: flockView.flockSettings.unitsDetails,
      },
    };
  }),
  on(setControllerLanguage, (state, { controllerLanguage }) => {
    LocalStorageService.setStorageItem(StorageItem.ControllerLang, controllerLanguage);

    // use en.json as default for not supported languages
    return {
      ...state,
      controllerLanguage: GlobalConstants.usedLanguages.includes(controllerLanguage)
        ? controllerLanguage
        : ControllerLanguageEnum.EngUS,
    };
  }),
  on(clearControllerInfo, () => {
    LocalStorageService.removeStorageItem(StorageItem.ActiveControllerID);
    return initialState;
  }),
  on(
    disconnectController,
    reconnectController,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    }),
  on(
    disconnectControllerError,
    reconnectControllerError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    }),
  on(disconnectControllerSuccess, (state) => {
    return {
      ...state,
      isDisconnected: true,
      isLoading: false,
    };
  }),
  on(reconnectControllerSuccess, (state) => {
    return {
      ...state,
      isDisconnected: false,
      isLoading: false,
    };
  }),
  on(flockActions.finishNewFlockSuccess, (state) => {
    return {
      ...state,
      controllerInfo: {
        ...state.controllerInfo,
        isDefaultFlock: false,
      },
    };
  }),
);

export function currentControllerReducer(state: CurrentControllerState | undefined, action: Action): any {
  return reducer(state, action);
}
