import { Action, createReducer, on } from '@ngrx/store';
import * as staticPressureActions from './static-pressure.actions';
import { StaticPressureState } from './static-pressure.state';

export const initialState: StaticPressureState = {
  isLoading: false,
  couldBeOpened: true,
  view: null,
};

const reducer = createReducer(
  initialState,
  on(
    staticPressureActions.getStaticPressure,
    staticPressureActions.updateStaticPressure,
    (state) => {
      return {
        ...state,
        isLoading: true,
        couldBeOpened: null,
      };
    },
  ),
  on(staticPressureActions.getStaticPressureError, (state) => {
    return {
      ...state,
      view: null,
      couldBeOpened: false,
      isLoading: false,
    };
  }),
  on(staticPressureActions.updateStaticPressureError, (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(staticPressureActions.getStaticPressureSuccess,
    staticPressureActions.updateStaticPressureSuccess,
    (state, { view }) => {
      return {
        ...state,
        view,
        isLoading: false,
        couldBeOpened: true,
      };
    }),
);

export function staticPressureReducer(state: StaticPressureState | undefined, action: Action): any {
  return reducer(state, action);
}
