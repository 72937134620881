import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { DialogsService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { combineLatest, delay, filter, firstValueFrom, lastValueFrom, Observable, Subscription } from 'rxjs';
import { AddDeviceDialogComponent } from '../add-device-dialog/add-device-dialog.component';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ButtonWithIconComponent, SvgIconComponent } from '@livestock/ui';
import {
  AddHouseNumberDialogComponent,
  clearIsControllerCreatedOrExecuted,
  createControllerByConnectionNumber,
  selectIsControllerCreatedByConnectionNumberInfo, SuccessAddDeviceDialogComponent,
} from '@livestock/controllers';
import { DialogButtonEnum, StorageItem } from '@livestock/shared/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  ICreateControllerByConnectionNumberView,
} from '../../interfaces/create-controller-by-connection-number-view.interface';
import { IFarm, selectActiveFarm, selectExistingHouseNumbers } from '@livestock/farms';

@Component({
  selector: 'ls-add-device',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonWithIconComponent,
    QaTagsDirective,
    SvgIconComponent,
  ],
  templateUrl: './add-device.component.html',
  styleUrls: ['./add-device.component.scss'],
})
export class AddDeviceComponent implements OnInit, OnDestroy {
  @Input() buttonType: 'primary' | 'secondary' | 'disabled' = 'primary';
  @Input() isLinkView = false;
  @Input() marginAuto = false;
  sub$ = new Subscription();
  activeFarm$: Observable<IFarm> = this.store.select(selectActiveFarm);

  constructor(
    public store: Store,
    public platformService: PlatformService,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      combineLatest([
        this.store.select(selectIsControllerCreatedByConnectionNumberInfo),
        this.store.select(selectExistingHouseNumbers),
      ]).pipe(
        wasChanged(),
        delay(500),
        filter(([info, usedHouseNumbers]: [{
          isControllerCreatedByConnectionNumber: boolean,
          creatingControllerError: string,
        }, number[]]) => {
          return info.isControllerCreatedByConnectionNumber != null && usedHouseNumbers != null;
        }),
      ).subscribe((
        [{ isControllerCreatedByConnectionNumber, creatingControllerError }, usedHouseNumbers],
      ) => {
        const connectionNumber = LocalStorageService.getStorageItem(StorageItem.NewControllerConnectionNumber);
        if (!connectionNumber) return;

        // open popup with house number input if this number already exists
        if (creatingControllerError?.startsWith(GlobalConstants.HouseNumberAlreadyExistsError)) {

          // ThisDeviceHouseNumber432AlreadyExistsOnThisFarm => 432
          const currentHouseNumber = +creatingControllerError
            .split('')
            .filter(symbol => !isNaN(+symbol))
            .join('');

          this.dialog.open(AddHouseNumberDialogComponent, {
            width: '500px',
            panelClass: 'popup-full__mobile',
            disableClose: true,
            data: {
              usedHouseNumbers,
              currentHouseNumber,
              connectionNumber,
            },
          });
          return;
        }

        if (isControllerCreatedByConnectionNumber) {
          const houseNumber = LocalStorageService.getStorageItem(StorageItem.NewControllerHouseNumber);
          this.openAddDeviceSuccessDialog(+connectionNumber, +houseNumber);
        }

        if (creatingControllerError) {
          const message = this.translateService.instant('Farms.AddDeviceDialog.FailedAssignmentMessage', {
            connectionNumber,
          });
          const title = 'Farms.AddDeviceDialog.ConnectionError';
          const iconPath = 'fail-circle';

          this.dialogsService.question(message, title, [DialogButtonEnum.CLOSE], iconPath, false);
          LocalStorageService.removeStorageItems([StorageItem.NewControllerHouseNumber, StorageItem.NewControllerConnectionNumber]);
        }

        this.store.dispatch(clearIsControllerCreatedOrExecuted());
      }),
    );
  }

  async addDevice(): Promise<void> {
    const dialogRef = this.dialog.open(AddDeviceDialogComponent, {
      width: '500px',
      panelClass: 'popup-full__mobile',
      disableClose: true,
    });
    const connectionNumber = await lastValueFrom(dialogRef.afterClosed());

    if (connectionNumber) {
      const connectionInfo: ICreateControllerByConnectionNumberView = {
        connectionNumber: connectionNumber.toString(),
      };

      this.store.dispatch(createControllerByConnectionNumber({ connectionInfo }));
    }
  }

  async openAddDeviceSuccessDialog(connectionNumber: number, currentHouseNumber: number): Promise<void> {
    const farm = await firstValueFrom(this.activeFarm$);

    this.dialog.open(SuccessAddDeviceDialogComponent, {
      width: '500px',
      panelClass: 'mobile-content-width',
      disableClose: true,
      data: {
        connectionNumber,
        currentHouseNumber,
        farmName: farm.name,
      },
    });
    LocalStorageService.removeStorageItems([StorageItem.NewControllerHouseNumber, StorageItem.NewControllerConnectionNumber]);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
