export enum HeaterWorkModeEnum {
    Cycles,
    Continuously,
}

export namespace HeaterWorkModeEnum {
    export function toTranslateKey(value: HeaterWorkModeEnum | string | number): string {
      switch (value) {
        case HeaterWorkModeEnum.Cycles:
          return 'Installation.Card.HeatingDO.HeaterWorkModeEnum.Cycles';
        case HeaterWorkModeEnum.Continuously:
          return 'Installation.Card.HeatingDO.HeaterWorkModeEnum.Continuously';
        default:
          return value.toString();
      }
    }
  }