import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  IFlockBirdWeightSettingsView,
  IFlockHouseModeView,
  IFlockSettingsView,
  IFlockBirdWeightView,
  IFlockDefaultWeightView,
  IGetFlockBirdWeightView,
} from '@livestock/flock';
import { ChickenBrandWeight, WeightUnitEnum } from '@livestock/shared/enums';
import { ControllerUnitEnum } from '@livestock/controllers/enums';
import { IUnitsDetails } from '@livestock/controllers/interfaces';
import { IFlockView } from '../interfaces';

export const getDefaultWeight = createAction(
  '[Flock] Get Default Weight [...]',
  props<{ brand?: ChickenBrandWeight, weightUnit?: WeightUnitEnum }>(),
);

export const getDefaultWeightSuccess = createAction(
  '[Flock] Get Default Weight [SUCCESS]',
  props<{ defaultWeightItems: IFlockDefaultWeightView[], weightUnit: WeightUnitEnum }>(),
);

export const getDefaultWeightError = createAction(
  '[Flock] Get Default Weight [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const changeDefaultWeight = createAction(
  '[Flock] Change Default Weight [...]',
  props<{ brand: ChickenBrandWeight, weightUnit?: WeightUnitEnum }>(),
);

export const changeDefaultWeightSuccess = createAction(
  '[Flock] Change Default Weight [SUCCESS]',
  props<{
    defaultWeightItems: IFlockDefaultWeightView[],
    brand: ChickenBrandWeight,
    controllerID: number,
  }>(),
);

export const changeDefaultWeightError = createAction(
  '[Flock] Change Default Weight [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getFlockSettings = createAction(
  '[Flock] Get Flock Settings [...]',
  props<{ controllerID: number }>(),
);

export const getFlockSettingsSuccess = createAction(
  '[Flock] Get Flock Settings [SUCCESS]',
  props<{ settings: IFlockSettingsView }>(),
);

export const getFlockSettingsError = createAction(
  '[Flock] Get Flock Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setFlockSettings = createAction(
  '[Flock] Set Flock Settings [...]',
  props<{ settings: IFlockSettingsView }>(),
);

export const getFlockHouseMode = createAction(
  '[Flock] Get Flock House Mode [...]',
  props<{ controllerID: number }>(),
);

export const getFlockHouseModeSuccess = createAction(
  '[Flock] Get Flock House Mode [SUCCESS]',
  props<{ houseMode: IFlockHouseModeView }>(),
);

export const getFlockHouseModeError = createAction(
  '[Flock] Get Flock House Mode [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setFlockHouseMode = createAction(
  '[Flock] Set Flock House Mode [...]',
  props<{ houseMode: IFlockHouseModeView }>(),
);

export const getFlockBirdWeightSettings = createAction(
  '[Flock] Get Flock Bird Weight Settings [...]',
  props<{ controllerID: number }>(),
);

export const getFlockBirdWeightSettingsSuccess = createAction(
  '[Flock] Get Flock Bird Weight Settings [SUCCESS]',
  props<{ birdWeightSettings: IFlockBirdWeightSettingsView }>(),
);

export const getFlockBirdWeightSettingsError = createAction(
  '[Flock] Get Flock Bird Weight Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setFlockBirdWeightSettings = createAction(
  '[Flock] Set Flock Bird Weight Settings [...]',
  props<{ birdWeightSettings: IFlockBirdWeightSettingsView }>(),
);

export const getFlockBirdWeight = createAction(
  '[Flock] Get Flock Bird Weight [...]',
  props<{ controllerID: number }>(),
);

export const getFlockBirdWeightSuccess = createAction(
  '[Flock] Get Flock Bird Weight [SUCCESS]',
  props<{ view: IGetFlockBirdWeightView; }>(),
);

export const getFlockBirdWeightError = createAction(
  '[Flock] Get Flock Bird Weight [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setFlockBirdWeight = createAction(
  '[Flock] Set Flock Bird Weight [...]',
  props<{ birdWeightItems: IFlockBirdWeightView[]; }>(),
);

// Update

export const updateFlockSettings = createAction(
  '[Flock] Update Flock Settings [...]',
);

export const updateFlockSettingsSuccess = createAction(
  '[Flock] Update Flock Settings [SUCCESS]',
);

export const updateFlockSettingsError = createAction(
  '[Flock] Update Flock Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateFlockHouseMode = createAction(
  '[Flock] Update Flock House Mode [...]',
);

export const updateFlockHouseModeSuccess = createAction(
  '[Flock] Update Flock House Mode [SUCCESS]',
);

export const updateFlockHouseModeError = createAction(
  '[Flock] Update Flock House Mode [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateFlockBirdWeightSettings = createAction(
  '[Flock] Update Flock Bird Weight Settings [...]',
);

export const updateFlockBirdWeightSettingsSuccess = createAction(
  '[Flock] Update Flock Bird Weight Settings [SUCCESS]',
);

export const updateFlockBirdWeightSettingsError = createAction(
  '[Flock] Update Flock Bird Weight Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateFlockBirdWeight = createAction(
  '[Flock] Update Flock Bird Weight [...]',
  props<{ hideFlashMessage: boolean }>(),
);

export const updateFlockBirdWeightSuccess = createAction(
  '[Flock] Update Flock Bird Weight [SUCCESS]',
);

export const updateFlockBirdWeightError = createAction(
  '[Flock] Update Flock Bird Weight [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const prepareNewFlock = createAction(
  '[Flock] Prepare New Flock [...]',
  props<{
    controllerID: number;
    isDefaultFlock: boolean;
    units: ControllerUnitEnum;
    unitsDetails: IUnitsDetails;
    weightUnit: WeightUnitEnum;
  }>(),
);

export const setFlockSettingsForNewFlock = createAction(
  '[Flock] Set Flock Settings For New Flock[...]',
  props<{ settings: IFlockSettingsView }>(),
);

export const finishNewFlock = createAction(
  '[Flock] Finish New Flock [...]',
);

export const finishNewFlockSuccess = createAction(
  '[Flock] Finish New Flock [SUCCESS]',
  props<{ flockView: IFlockView }>(),
);

export const finishNewFlockError = createAction(
  '[Flock] Finish New Flock [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearIsFlockFinished = createAction(
  '[Flock] Clear Is Flock Finished [...]',
);

export const clearBirdWeightItems = createAction(
  '[Flock] Clear Bird Weight Items [...]',
);

export const clearFlockSettings = createAction(
  '[Flock] Clear Flock Settings [...]',
);

export const clearFlockHouseMode = createAction(
  '[Flock] Clear Flock House Mode [...]',
);

