import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, defaultIfEmpty, filter, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { LightingProgramService } from '../service/lighting-program-api.service';
import * as lightingActions from './lighting-program.actions';
import { setFlashMessage, FlashMessageTypeEnum } from '@livestock/notifications';
import { selectCurrentLightingPeriod } from './lighting-program.selectors';

@Injectable()
export class LightingProgramEffects {
  getLightingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.getLightingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([action, controllerID]) => {
        if (action.periodID) {
          return this.lightingService.getLightingProgram(controllerID, action.periodID).pipe(
            map((item) => lightingActions.getLightingProgramSuccess({ item })),
            catchError((error) => of(lightingActions.getLightingProgramError({ error }))),
          );
        }
        return of(lightingActions.getLightingProgramSuccess({ item: { items: [] } }));
      }),
    ));

  getLightingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.getLightingPeriod),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.lightingService.getLightingPeriod(controllerID).pipe(
          map(({ items, lightAOElements, lightDOElements }) => lightingActions.getLightingPeriodSuccess({
            items,
            controllerID,
            lightAOElements,
            lightDOElements,
          })),
          catchError((error) => of(lightingActions.getLightingPeriodError({ error }))),
        );
      }),
    ));

  addLightingDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.addLightingDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      map(([{ dayNumber }, controllerID]) => lightingActions.addLightingDaySuccess({ dayNumber, controllerID })),
      catchError((error) => of(lightingActions.addLightingDayError({ error }))),
    ),
  );

  deleteLightingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.deleteLightingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, programID }, controllerID]) => {
        return this.lightingService.deleteProgram(controllerID, periodID, programID).pipe(
          map(() => lightingActions.deleteLightingProgramSuccess({ periodID, programID })),
          catchError((error) => of(lightingActions.deleteLightingProgramError({ error }))),
        );
      }),
    ));

  deleteLightingDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.deleteLightingDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, preventSelecting }, controllerID]) => {
        if (periodID) {
          return this.lightingService.deleteLightingDay(controllerID, periodID).pipe(
            switchMap(() => ([lightingActions.deleteLightingDaySuccess({
              periodID,
              preventSelecting,
            }), setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'Controls.Element.YouDeletedThisDay',
              },
            })])),
            catchError((error) => of(lightingActions.deleteLightingProgramError({ error }))),
          );
        }

        return [lightingActions.deleteLightingDaySuccess({
          periodID,
          preventSelecting,
          preventLoading: true,
        }), setFlashMessage({
          flashMessage: {
            flashType: FlashMessageTypeEnum.Success,
            message: 'Controls.Element.YouDeletedThisDay',
          },
        })];
      }),
    ));

  deleteLightingDaySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.deleteLightingDaySuccess),
      withLatestFrom(this.store.select(selectCurrentLightingPeriod)),
      filter(([{ preventLoading }]) => !preventLoading),
      map(([_, period]) => lightingActions.getLightingProgram({ periodID: period?.periodID })),
    ),
  );

  saveLightingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingActions.saveLightingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ period, items, itemsToDelete }, controllerID]) => {
        if (period.periodID) {
          return of({
            period,
            items,
            controllerID,
            itemsToDelete,
          });
        }
        return this.lightingService.addLightingDay(controllerID, period).pipe(map(period => (
          {
            items,
            period,
            controllerID,
            itemsToDelete,
          })));

      }),
      switchMap(({ period, items, controllerID, itemsToDelete }) => {
        return forkJoin(itemsToDelete.map(i => this.lightingService.deleteProgram(controllerID, i.periodID, i.programID))).pipe(
          defaultIfEmpty(null),
          map(() => ({
            items,
            period,
            controllerID,
            itemsToDelete,
          })),
        );
      }),
      switchMap(({ period, items, controllerID }) =>
        forkJoin(items.map(prog => this.lightingService[prog.programID ? 'editLightingProgram' : 'addLightingProgram'](controllerID, period.periodID, {
          ...prog,
          periodID: prog.periodID || period.periodID,
        })))
          .pipe(
            defaultIfEmpty(null),
            switchMap((items) => [lightingActions.saveLightingProgramSuccess({ period, items }), setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.SaveChangesSucceeded',
              },
            })]),
            catchError((err) => ([lightingActions.saveLightingProgramError(err), lightingActions.getLightingProgram({ periodID: items[0].periodID })])),
          )),
    ));

  constructor(
    private actions$: Actions,
    private store: Store,
    private lightingService: LightingProgramService,
  ) {
  }
}
