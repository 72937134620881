import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-air-quality-widget',
  templateUrl: './air-quality-widget.component.html',
  styleUrls: ['./air-quality-widget.component.scss'],
})
export class AirQualityWidgetComponent {
}
