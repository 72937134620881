import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap } from 'rxjs';
import * as loginScannedActions from './login-scanned.actions';
import { TicketControllersService } from 'libs/controllers/src/lib/services/ticket-controller.service';
import { IGetAddControllerTicketView } from '../../../../controllers/src/lib/interfaces/get-add-controller-ticket-view';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';

@Injectable()
export class LoginScannedEffects {

  getAddControllerTicketView$ = createEffect(() =>
    this.actions$.pipe(
      ofType(loginScannedActions.getAddControllerTicketView),
      switchMap((
          {
            ticketId,
            isReconnect,
          },
        ) => this.ticketControllersService.getAddControllerByTicketID(ticketId, isReconnect)
          .pipe(
            map((ticketView: IGetAddControllerTicketView) => loginScannedActions.getAddControllerTicketViewSuccess({ ticketView })),
            catchError((error) => of(loginScannedActions.getAddControllerTicketViewError({ payload: error }))),
          ),
      ),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private ticketControllersService: TicketControllersService,
  ) {
  }
}

