<form
  [formGroup]="form"
  class="d-flex flex-row align-center">
  <ls-input-integer
    [fieldWithKeyboard]="true"
    [min]="0"
    [qaTags]="'qa-txt-test-time'"
    formControlName="time">
  </ls-input-integer>
  <span class="color-tertiary fz-16 ml-10">
    {{ "Installation.Card.HeatingAO.Voltage" | translate }}
  </span>
</form>

<div
  *ngIf="!platformService.isMobileApp"
  class="keyboard-wrapper">
  <ls-keyboard [defaultMode]="KeyboardModeEnum.NumericOnly"> </ls-keyboard>
</div>
