import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { ICreateAddControllerTicketView } from '@livestock/controllers/interfaces';
import { ChickenBrandWeight, LengthUnitEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { IGetFlockDefaultWeightView } from '@livestock/flock';

// general settings
export const createGeneralSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [...]',
);

export const createGeneralSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [SUCCESS]',
);

export const createGeneralSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create General Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// house sizes settings
export const createHouseSizesViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Sizes Via Device [...]',
  props<{ currentUnit: LengthUnitEnum }>(),
);

export const createHouseSizesViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Sizes Via Device [SUCCESS]',
);

export const createHouseSizesViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Sizes Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// date and time settings
export const createDateTimeSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [...]',
  props<{ isFinish: boolean }>(),
);

export const createDateTimeSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [SUCCESS]',
  props<{ isFinish: boolean }>(),
);

export const createDateTimeSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create DateTime Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// network settings
export const createNetworkSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [...]',
);

export const createNetworkSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [SUCCESS]',
);

export const createNetworkSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Network Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// flock settings
export const createFlockSettingsViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Flock Settings Via Device [...]',
);

export const createFlockSettingsViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Flock Settings Via Device [SUCCESS]',
);

export const createFlockSettingsViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create Flock Settings Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const createHouseModesViaDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Modes Via Device [...]',
  props<{ currentTempUnit: TemperatureUnitEnum }>(),
);

export const createHouseModesViaDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Modes Via Device [SUCCESS]',
);

export const createHouseModesViaDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Create House Modes Via Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// quick start status
export const getQuickstartStatus = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [...]',
);

export const getQuickstartStatusSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [SUCCESS]',
);

export const getQuickstartStatusError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Quickstart Status [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setQuickstartStatusStep = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [...]',
  props<{ step: QuickStartStatusEnum }>(),
);

export const setQuickstartStatusStepSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [SUCCESS]',
);

export const setQuickstartStatusStepError = createAction(
  '[UPSERT CONTROLLER DEVICE] Set Quickstart Status Step [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// controller serial number
export const getControllerSerialNumberDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [...]',
);

export const getControllerSerialNumberDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [SUCCESS]',
  props<{ serialNumber: string }>(),
);

export const getControllerSerialNumberDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Controller Serial Number Device [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// ticket info
export const getTicketInfoFromDevice = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket ID from controller [...]',
);

export const getTicketInfoFromDeviceSuccess = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket ID from controller [SUCCESS]',
  props<{ ticketView: ICreateAddControllerTicketView }>(),
);

export const getTicketInfoFromDeviceError = createAction(
  '[UPSERT CONTROLLER DEVICE] Get Ticket ID from controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// network connection
export const setNetworkConnectionInProgress = createAction(
  '[UPSERT CONTROLLER] Set Network Connection In Progress [...]',
  props<{ connectionInProgress: boolean }>(),
);

// get weight reference table
export const getFlockWeightReferenceTable = createAction(
  '[UPSERT CONTROLLER] Get Flock Weight Reference Table [...]',
  props<{ brand: ChickenBrandWeight, deviceLogic?: boolean }>(),
);

export const getFlockWeightReferenceTableSuccess = createAction(
  '[UPSERT CONTROLLER] Get Flock Weight Reference Table [SUCCESS]',
  props<{ defaultWeights: IGetFlockDefaultWeightView }>(),
);

export const getFlockWeightReferenceTableError = createAction(
  '[UPSERT CONTROLLER] Get Flock Weight Reference Table [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
