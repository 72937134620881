<ng-container *ngIf="flashMessage">
  <div
    [class.flash-auth]="router.url | memoizeFunc : activatedRouteService.isAuthPage"
    class="flash ph-20 pv-12 d-flex align-center justify-between">
    <div class="flash-indicator flash-indicator__{{ flashMessage.flashType }}"></div>

    <div class="d-flex align-center">
      <div class="flash-icon flex-shrink-0">
        <ng-container [ngSwitch]="flashMessage.flashType">
          <svg
            *ngSwitchCase="FlashMessageTypeEnum.Error"
            width="24"
            height="23"
            viewBox="0 0 24 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg">
            <path
              d="M23.5013 17.3058L15.0233 1.72102C13.6612 -0.572151 10.3407 -0.575197 8.9767 1.72102L0.499073 17.3058C-0.893396 19.6491 0.792464 22.6164 3.52167 22.6164H20.478C23.205 22.6164 24.8938 19.6515 23.5013 17.3058ZM12 19.8039C11.2248 19.8039 10.5937 19.1728 10.5937 18.3976C10.5937 17.6224 11.2248 16.9914 12 16.9914C12.7752 16.9914 13.4062 17.6224 13.4062 18.3976C13.4062 19.1728 12.7752 19.8039 12 19.8039ZM13.4062 14.1789C13.4062 14.9541 12.7752 15.5851 12 15.5851C11.2248 15.5851 10.5937 14.9541 10.5937 14.1789V7.1476C10.5937 6.37238 11.2248 5.74135 12 5.74135C12.7752 5.74135 13.4062 6.37238 13.4062 7.1476V14.1789Z"
              fill="#DA2700" />
          </svg>
          <img
            *ngSwitchCase="FlashMessageTypeEnum.ErrorMessage"
            alt="error"
            src="/assets/icons/flash/error.svg" />
          <img
            *ngSwitchCase="FlashMessageTypeEnum.Success"
            alt="success"
            src="/assets/icons/flash/success.svg" />
          <img
            *ngSwitchCase="FlashMessageTypeEnum.Note"
            alt="note"
            src="/assets/icons/flash/note.svg" />
          <img
            *ngSwitchCase="FlashMessageTypeEnum.Warning"
            alt="warning"
            src="/assets/icons/flash/warning.svg" />
        </ng-container>
      </div>

      <div
        [ngClass]="{
          'qa-lbl-message-error': [FlashMessageTypeEnum.Error, FlashMessageTypeEnum.ErrorMessage].includes(
            flashMessage.flashType
          ),
          'qa-lbl-message-success': flashMessage.flashType === FlashMessageTypeEnum.Success
        }"
        class="flash-message ml-10 qa-lbl-message">
        <ng-container [ngSwitch]="flashMessage.flashType">
          <h6 *ngSwitchCase="FlashMessageTypeEnum.Error">{{ "FlashMessages.Error" | translate }}</h6>
          <h6 *ngSwitchCase="FlashMessageTypeEnum.ErrorMessage">{{ "FlashMessages.ErrorMessage" | translate }}</h6>
          <h6 *ngSwitchCase="FlashMessageTypeEnum.Success">{{ "FlashMessages.Success" | translate }}</h6>
          <h6 *ngSwitchCase="FlashMessageTypeEnum.Note">{{ "FlashMessages.Note" | translate }}</h6>
          <h6 *ngSwitchCase="FlashMessageTypeEnum.Warning">{{ "FlashMessages.Warning" | translate }}</h6>
        </ng-container>

        <p>
          {{
            flashMessage.doNotTranslate
              ? flashMessage.message
              : (flashMessage.message | translate : flashMessage.additionalInfo)
          }}
        </p>
      </div>
    </div>
  </div>
</ng-container>
