<h6 class="fz-16 color-tertiary mb-5 text-center">
  {{ "Installation.Card.LightAO.SelectVoltage" | translate }}
</h6>
<div class="button-container">
  <span
    *ngFor="let number of numbers"
    (click)="selectNumber(number)"
    [class.button-container__active]="number === selectedNumber">
    {{ number }}
  </span>
</div>
