<section class="page-content">
  <header class="d-flex flex-row justify-between">
    <ls-header-title
      (cancel)="cancel()"
      [title]="'Controller.DateTimeSettings.SettingsDateTime'"></ls-header-title>
    <ls-svg-icon
      (click)="isEditMode = !isEditMode"
      [class.disabled]="isEditMode"
      [color]="platformService.isMobileApp && isEditMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'installation/edit-mobile' : 'installation/edit'"
      [qaTags]="'qa-btn-edit'"
      class="pointer">
    </ls-svg-icon>
  </header>

  <div class="container">
    <ls-controller-date-time-settings-form
      (changed)="changedDateTimeSettings($event)"
      [dateTimeSettings]="dateTimeSettings"
      [isLoading]="isLoading$ | async"
      [isDisabled]="!isEditMode" />
    <div
      *ngIf="isEditMode"
      class="d-flex justify-end mt-30">
      <ls-button
        (click)="cancel()"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-20 ml-20">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="update()"
        [class.disabled]="!isValid || !isDirtyForm"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'">
        {{ "Buttons.Update" | translate }}
      </ls-button>
    </div>
  </div>
</section>
