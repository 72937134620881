import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IElementSettings } from '../interfaces/element/element-settings.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';

export const getElementSettings = createAction(
  '[INSTALLATION ELEMENT] Get Element Settings [...]',
  props<{ elementType: ElementTypesEnum }>(),
);

export const getElementSettingsSuccess = createAction(
  '[INSTALLATION ELEMENT] Get Element Settings [SUCCESS]',
  props<{ elementSettings: IElementSettings }>(),
);

export const getElementSettingsError = createAction(
  '[INSTALLATION ELEMENT] Get Element Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateElementSettings = createAction(
  '[INSTALLATION ELEMENT] Update Element Settings [...]',
  props<{
    view: IElementSettings,
    elementType: ElementTypesEnum
  }>(),
);

export const updateElementSettingsSuccess = createAction(
  '[INSTALLATION ELEMENT] Update Element Settings [SUCCESS]',
  props<{ view: IElementSettings }>(),
);

export const updateElementSettingsError = createAction(
  '[INSTALLATION ELEMENT] Update Element Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearElementSettings = createAction(
  '[INSTALLATION ELEMENT] Clear Element Settings',
);
