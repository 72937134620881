<ls-page-wrapper
  (back)="goToProgramsPage()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'HeatingProgramZone.Title'">
  <div class="svg-content">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit'"
      qaTags="qa-btn-edit-heating-zone"
      class="pointer"></ls-svg-icon>
  </div>
  <div
    *ngIf="!platformService.isMobileApp; else mobileTemplate"
    [class.content-form__edit]="editMode"
    class="main-content content-form h-100-percent w-100-percent">
    <div class="table-wrapper overflow-y-auto mb-20 h-100-percent">
      <div class="table">
        <div class="table-row table-header">
          <div class="table-cell blue">
            <h6>{{ "HeatingProgramZone.Zones" | translate }}</h6>
          </div>

          <div class="table-cell blue table-cell__right-border">
            <h6>{{ "HeatingProgramZone.Heaters" | translate }}</h6>
          </div>

          <div class="table-cell blue">
            <h6>{{ "HeatingProgramZone.Average" | translate }}</h6>
          </div>

          <div class="table-cell blue">
            <h6>{{ "HeatingProgramZone.Sensors" | translate }}</h6>
          </div>
        </div>

        <div
          *ngFor="let zone of zones$ | async; trackBy: trackBy"
          class="table-row">
          <div class="table-cell table-cell__dark-bg d-flex align-center justify-center">
            <h6 *ngIf="zone.zoneID !== (activeZoneID$ | async); else nameInput">
              <ng-container *ngIf="zone.name">{{ zone.name }}</ng-container>
              <ng-container *ngIf="!zone.name">
                <span class="color-danger">{{ "HeatingProgramZone.EnterName" | translate }}</span>
              </ng-container>
            </h6>

            <ng-template #nameInput>
              <input
                (click)="enableKeyboard(zone.zoneID)"
                (input)="changeZoneName($event)"
                [attr.data-zone-id]="'zoneName' + zone.zoneID"
                [class.invalid]="zone.name === ''"
                [placeholder]="'HeatingProgramZone.EnterName' | translate"
                value="{{ zone.name }}"
                class="name-input"
                maxlength="20"
                type="text" />
            </ng-template>
          </div>

          <div
            (click)="chooseZoneMode(HeatersZoneModeEnum.Heaters, zone.zoneID)"
            [class.table-cell__active]="
              (activeZoneID$ | async) === zone.zoneID && heatingZoneMode === HeatersZoneModeEnum.Heaters
            "
            class="table-cell table-cell__right-border d-flex pointer">
            <div
              *ngFor="let heatingElement of zone.heatingElements"
              (click)="addOrRemoveHeaterToMovingArray($event, heatingElement.elementID, zone.zoneID)"
              [class.heater-active]="(selectedHeatersIDs$ | async).includes(heatingElement.elementID)"
              class="heater mr-3">
              <ls-svg-icon
                [color]="ColorsEnum.BlueDefault"
                [fullHeight]="true"
                [path]="heatingElement.elementType === ElementType.HeatingDO ? 'heating/heat-do' : 'heating/heat'">
              </ls-svg-icon>
              <span class="fz-12 color-tertiary">{{ heatingElement.number }}</span>
            </div>
          </div>

          <div class="table-cell d-flex align-center justify-center">
            <mat-checkbox
              (change)="changeIsAverage($event, zone.zoneID)"
              [checked]="zone.isAverage"
              [disabled]="!editMode"
              class="checkbox checkbox-active-color">
            </mat-checkbox>
          </div>

          <div
            (click)="chooseZoneMode(HeatersZoneModeEnum.Sensors, zone.zoneID)"
            [class.table-cell__active]="
              (activeZoneID$ | async) === zone.zoneID && heatingZoneMode === HeatersZoneModeEnum.Sensors
            "
            class="table-cell d-flex pointer">
            <ng-container *ngIf="zone.isAverage; else sensors">
              <div class="sensor sensor-all">
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [fullHeight]="true"
                  [path]="'heating/temp'"></ls-svg-icon>
                <span class="fz-12 color-tertiary">{{ "HeatingProgramZone.All" | translate }}</span>
              </div>
            </ng-container>

            <ng-template #sensors>
              <div
                *ngFor="let sensorElement of zone.sensorElements"
                class="sensor mr-4">
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [fullHeight]="true"
                  [path]="'heating/temp'"></ls-svg-icon>
                <span class="fz-12 color-tertiary">{{ sensorElement.number }}</span>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div
        *ngIf="someZoneNamesAreEmpty$ | async"
        class="heaters-error color-danger mt-10">
        {{ "HeatingProgramZone.PleaseNameAllZonesToEnableSaveButton" | translate }}
      </div>
    </div>

    <div
      *ngIf="editMode"
      class="buttons d-flex align-center justify-between">
      <div class="d-flex align-center">
        <ls-button
          (click)="addZone()"
          [class.disabled]="(zones$ | async).length >= MAX_ZONES"
          class="mr-20 h-button-36"
          type="secondary">
          {{ "HeatingProgramZone.AddZone" | translate }}
        </ls-button>

        <ls-button
          (click)="removeZone()"
          [class.disabled]="!(activeZoneID$ | async) || (activeZone$ | async)?.heatingElements?.length > 0"
          class="mr-20 h-button-36"
          type="secondary">
          {{ "HeatingProgramZone.DeleteZone" | translate }}
        </ls-button>

        <ls-button
          (click)="openMoveHeatersPopup()"
          [class.disabled]="!(activeZoneID$ | async) || (selectedHeatersIDs$ | async).length === 0"
          class="h-button-36"
          type="secondary">
          {{ "HeatingProgramZone.Move" | translate }}
        </ls-button>
      </div>

      <div class="d-flex align-center">
        <ls-button
          (click)="toggleEditMode()"
          [qaTags]="'qa-btn-cancel'"
          class="mr-20 w-120 h-button-36"
          type="secondary">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>

        <ls-button
          (click)="save()"
          [class.disabled]="
            !(this.zones$ | async | memoizeFunc : areAnyChanges : this | async) || (someZoneNamesAreEmpty$ | async)
          "
          [qaTags]="'qa-btn-save'"
          class="w-120 h-button-36"
          type="primary">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </div>
  </div>

  <div
    *ngIf="editMode"
    class="keyboard-content content-keyboard ml-20">
    <ng-container [ngSwitch]="heatingZoneMode">
      <ls-heaters-keyboard *ngSwitchCase="HeatersZoneModeEnum.Heaters"></ls-heaters-keyboard>
      <ls-sensors-keyboard
        *ngSwitchCase="HeatersZoneModeEnum.Sensors"
        (saveSensors)="saveSensors($event)"
        [activeZone]="activeZone$ | async"
        [isDisabled]="(activeZone$ | async).isAverage"
        [sensorElements]="sensorElements"></ls-sensors-keyboard>
      <ng-container *ngSwitchDefault>
        <ls-keyboard *ngIf="!platformService.isMobileApp"></ls-keyboard>
      </ng-container>
    </ng-container>
  </div>
</ls-page-wrapper>

<ng-template #mobileTemplate>
  <ls-heating-program-zone-mobile
    (changeZoneName)="changeZoneName($event)"
    (removeZone)="removeZone()"
    (save)="save()"
    (setSensorMode)="heatingZoneMode = HeatersZoneModeEnum.Sensors"
    (toggleEditMode)="toggleEditMode()"
    [editMode]="editMode"
    [heatingAOElements]="heatingAOElements"
    [heatingDOElements]="heatingDOElements"
    [noChangesMade]="!(this.zones$ | async | memoizeFunc : areAnyChanges : this | async)"
    [sensorElements]="sensorElements"
    [sensorMode]="heatingZoneMode"
    [someZonesAreEmpty]="someZoneNamesAreEmpty$ | async"
    [zones]="zones$ | async"></ls-heating-program-zone-mobile>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
