<ng-container *ngIf="parentFormGroup.get(formGroupName)">
  <div [formGroupName]="formGroupName">
    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-hater-work-mode'">{{ "Installation.Card.HeatingDO.HeaterWorkMode" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <mat-radio-group
          (change)="heaterWorkModeChanged($event)"
          [qaTags]="'qa-radio-hater-work-mode'"
          [class.disabled]="!isEditMode"
          aria-labelledby="ls-radio-hater-work-mode-label"
          class="ls-radio-group"
          formControlName="heaterWorkMode">
          <mat-radio-button
            *ngFor="let el of HeaterWorkModeEnum | enumToArray"
            [qaTags]="'qa-radio-hater-work-mode-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button overrided-color-90-fz-16 mr-85">
            {{ el | enum : "hater-work-mode-enum" | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-max-cycle-time'">{{ "Installation.Card.HeatingDO.MaxCycleTime" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="
            !isEditMode || parentFormGroup.get(formGroupName).value.heaterWorkMode == HeaterWorkModeEnum.Continuously
          "
          [max]="ElementsValidationConstants.heatingSettings.maxCycleTime.max"
          [min]="ElementsValidationConstants.heatingSettings.maxCycleTime.min"
          [qaTags]="'qa-txt-max-cycle-time'"
          errorField
          formControlName="maxCycleTime">
        </ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.HeatingDO.Min" | translate }}</span>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-min-on-cycle-time'">{{
        "Installation.Card.HeatingDO.MinOnCycleTime" | translate
      }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="
            !isEditMode || parentFormGroup.get(formGroupName).value.heaterWorkMode == HeaterWorkModeEnum.Continuously
          "
          [max]="ElementsValidationConstants.heatingSettings.minCycleTime.max"
          [min]="ElementsValidationConstants.heatingSettings.minCycleTime.min"
          [qaTags]="'qa-txt-min-on-cycle-time'"
          errorField
          formControlName="minCycleTime">
        </ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.HeatingDO.Min" | translate }}</span>
      </div>
    </div>

    <div
      *ngIf="parentFormGroup.get(formGroupName).value.isAnyHeatingAOElementExist"
      class="settings-field form-field">
      <label [qaTags]="'qa-lbl-analog-heaters'">{{ "Installation.Card.HeatingDO.AnalogHeaters" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="
            !isEditMode || parentFormGroup.get(formGroupName).value.heaterWorkMode == HeaterWorkModeEnum.Continuously
          "
          [max]="ElementsValidationConstants.heatingSettings.analogHeaters.max"
          [min]="ElementsValidationConstants.heatingSettings.analogHeaters.min"
          [qaTags]="'qa-txt-analog-heaters'"
          errorField
          formControlName="analogHeaters">
        </ls-input-integer>
        <span class="unit ml-10">%</span>
      </div>
    </div>
  </div>
</ng-container>
