import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, defaultIfEmpty, filter, forkJoin, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { SprinklersFoggersProgramService } from '../services/sprinklers-foggers-program-api.service';
import * as sprinklersFoggersActions from './sprinklers-foggers-program.actions';
import { setFlashMessage, FlashMessageTypeEnum } from '@livestock/notifications';
import { selectCurrentSprinklersFoggersProgramPeriod } from './sprinklers-foggers-program.selectors';

@Injectable()
export class SprinklersFoggersProgramEffects {
  getSprinklersFoggersProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.getSprinklersFoggersProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([action, controllerID]) => {
        if (action.periodID) {
          return this.apiService.getSprinklersFoggersProgram(controllerID, action.periodID).pipe(
            map((item) => sprinklersFoggersActions.getSprinklersFoggersProgramSuccess({ item })),
            catchError((error) => of(sprinklersFoggersActions.getSprinklersFoggersProgramError({ error }))),
          );
        } else {
          return of(sprinklersFoggersActions.getSprinklersFoggersProgramSuccess({
            item: {
              hasElement1: true,
              hasElement2: true,
              hasElement3: true,
              items: [],
            },
          }));
        }
      }),
    ));

  getSprinklersFoggersProgramPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.getSprinklersFoggersProgramPeriod),
      switchMap(() => this.store.select(selectActiveControllerID)),
      filter(controllerID => controllerID !== null),
      switchMap((controllerID) => {
        return this.apiService.getSprinklersFoggersProgramPeriod(controllerID).pipe(
          map(({ items }) => sprinklersFoggersActions.getSprinklersFoggersProgramPeriodSuccess({
            items,
            controllerID,
          })),
          catchError((error) => of(sprinklersFoggersActions.getSprinklersFoggersProgramPeriodError({ error }))),
        );
      })),
  );

  addSprinklersFoggersDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.addSprinklersFoggersProgramDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      map(([{ dayNumber }, controllerID]) => sprinklersFoggersActions.addSprinklersFoggersProgramDaySuccess({
        dayNumber,
        controllerID,
      })),
      catchError((error) => of(sprinklersFoggersActions.addSprinklersFoggersProgramDayError({ error }))),
    ));

  deleteSprinklersFoggersProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.deleteSprinklersFoggersProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, programID }, controllerID]) => {
        return this.apiService.deleteSprinklersFoggersProgram(controllerID, periodID, programID).pipe(
          map(() => sprinklersFoggersActions.deleteSprinklersFoggersProgramSuccess({ periodID, programID })),
          catchError((error) => of(sprinklersFoggersActions.deleteSprinklersFoggersProgramError({ error }))),
        );
      })),
  );

  deleteSprinklersFoggersProgramDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.deleteSprinklersFoggersProgramDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, preventSelecting }, controllerID]) => {
        if (periodID) {
          return this.apiService.deleteSprinklersFoggersProgramDay(controllerID, periodID).pipe(
            map(() => sprinklersFoggersActions.deleteSprinklersFoggersProgramDaySuccess({
              periodID,
              preventSelecting,
            })),
            tap(() => {
              this.store.dispatch(
                setFlashMessage({
                  flashMessage: {
                    flashType: FlashMessageTypeEnum.Success,
                    message: 'Controls.Element.YouDeletedThisDay',
                  },
                }),
              );
            }),
            catchError((error) => of(sprinklersFoggersActions.deleteSprinklersFoggersProgramError({ error }))),
          );
        } else {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'Controls.Element.YouDeletedThisDay',
              },
            }),
          );
          return of(sprinklersFoggersActions.deleteSprinklersFoggersProgramDaySuccess({ periodID, preventSelecting }));
        }
      }),
    ));

  deleteSprinklersFoggersDaySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.deleteSprinklersFoggersProgramDaySuccess),
      withLatestFrom(this.store.select(selectCurrentSprinklersFoggersProgramPeriod)),
      filter(([{ preventSelecting }]) => !preventSelecting),
      map(([_, period]) => sprinklersFoggersActions.getSprinklersFoggersProgram({ periodID: period?.periodID })),
    ),
  );

  saveSprinklersFoggersProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(sprinklersFoggersActions.saveSprinklersFoggersProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ period, items, itemsToDelete }, controllerID]) => {
        if (period.periodID) {
          return of({
            period,
            items,
            controllerID,
            itemsToDelete,
          });
        } else {
          return this.apiService.addSprinklersFoggersProgramDay(controllerID, period).pipe(map(period => (
            {
              items,
              period,
              controllerID,
              itemsToDelete,
            })));
        }
      }),
      switchMap(({ period, items, controllerID, itemsToDelete }) => {
        return forkJoin(itemsToDelete.map(i => this.apiService.deleteSprinklersFoggersProgram(controllerID, i.periodID, i.programID))).pipe(
          defaultIfEmpty(null),
          map(() => ({
            items,
            period,
            controllerID,
            itemsToDelete,
          })),
        );
      }),
      switchMap(({ period, items, controllerID }) =>
        forkJoin(items.map(prog => this.apiService[prog.programID ? 'editSprinklersFoggersProgram' : 'addSprinklersFoggersProgram'](controllerID, period.periodID, {
          ...prog,
          periodID: prog.periodID || period.periodID,
        })))
          .pipe(
            defaultIfEmpty(null),
            tap(() => {
              this.store.dispatch(
                setFlashMessage({
                  flashMessage: {
                    flashType: FlashMessageTypeEnum.Success,
                    message: 'FlashMessages.SaveChangesSucceeded',
                  },
                }),
              );
            }),
            map((items) => sprinklersFoggersActions.saveSprinklersFoggersProgramSuccess({ period, items })),
            catchError(() => of(sprinklersFoggersActions.getSprinklersFoggersProgram({ periodID: items[0].periodID }))),
          )),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private apiService: SprinklersFoggersProgramService,
  ) {
  }
}
