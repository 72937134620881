<section class="page-content">
  <div class="header d-flex flex-row justify-between align-center">
    <ls-header-title
      (cancel)="back()"
      [title]="'Flock.BirdWeight.BirdWeight'">
    </ls-header-title>
  </div>

  <div class="graph-wrapper">
    <div class="graph w-100-percent">
      <canvas
        [datasets]="barChartData"
        [labels]="barChartLabels"
        [legend]="barChartLegend"
        [options]="barChartOptions"
        [plugins]="barChartPlugins"
        [type]="'line'"
        class="w-100-percent"
        baseChart>
      </canvas>
    </div>
    <div class="bottom-legend">
      <div class="title">
        <span>{{ "Flock.BirdWeight.Legend" | translate }}</span>
      </div>
      <div class="lines">
        <div class="line line--1">
          <span>{{ "Flock.BirdWeight.StandardWeight" | translate }}</span>
        </div>
        <div class="line line--2">
          <span>{{ (isSeparateMode ? "Flock.BirdWeight.MaleWeight" : "Flock.BirdWeight.OwnCurve") | translate }}</span>
        </div>
        <div
          *ngIf="isSeparateMode"
          class="line line--3">
          <span>{{ "Flock.BirdWeight.FemaleWeight" | translate }}</span>
        </div>
      </div>
    </div>
  </div>
</section>
