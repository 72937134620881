import { InputIntegerComponent, EditDayDialogComponent } from '@livestock/ui';
import { lastValueFrom, Subscription } from 'rxjs';
import { ErrorFieldDirective } from '@livestock/shared/directives';
import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormsModule, ReactiveFormsModule, FormGroup, FormControl, Validators, AbstractControl, ValidationErrors } from '@angular/forms';
import { PlatformService } from '@livestock/shared/services';
import { ISprinklersFoggersProgramPeriod } from '@livestock/sprinklers-foggers-program';
import { RequiredTrimValidator } from 'libs/shared/validators/required-trim.validator';
import { MatDialog } from '@angular/material/dialog';
import { AddDeleteDayEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-add-program-day-mobile-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    InputIntegerComponent,
    ErrorFieldDirective,
  ],
  templateUrl: './add-program-day-mobile-dialog.component.html',
  styleUrls: ['./add-program-day-mobile-dialog.component.scss'],
})
export class AddProgramDayMobileDialogComponent implements OnInit{
  @Input() currentPeriod: ISprinklersFoggersProgramPeriod;
  @Input() periods: ISprinklersFoggersProgramPeriod[];
  @Output() clearBeforeDayAdding = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();
  @Output() deleteDay = new EventEmitter();
  @Output() isDisabledChange = new EventEmitter();

  addNewDay: boolean = false;
  form: FormGroup;
  originalPeriods: ISprinklersFoggersProgramPeriod[];
  isTableDisabled: boolean = false;

  sub$ = new Subscription();

  constructor(
    public platformService: PlatformService,
    private dialog: MatDialog,
    public fb: FormBuilder,
  ) {
  }

  ngOnInit(): void {
    this.originalPeriods = this.periods;

    this.form = this.fb.group({
      dayNumber: new FormControl(this.currentPeriod?.dayNumber, [Validators.max(99), RequiredTrimValidator, this.daysValidator()]),
    });

    this.sub$.add(
      this.form.valueChanges.subscribe(val => {
        const period = this.originalPeriods.find(period => period.dayNumber === val?.dayNumber);
        this.isTableDisabled = (!!period || val?.dayNumber == null) ?? false;
        this.isDisabledChange.emit(this.isTableDisabled);
      }),
    );
  }

  onInputFocusOut(): void {
    this.onFocusOut.emit(this.form.value.dayNumber);
  }

  async openEditDayDialog(): Promise<void> {
    const dialogRef = this.dialog.open(EditDayDialogComponent, {
      width: '90%',
    });

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (!res) return;

    const period = this.periods.find(period => period.dayNumber === this.form.value.dayNumber);

    if (res === AddDeleteDayEnum.DeleteDay) {
      this.deleteDay.emit(period);
    };

    if (res === AddDeleteDayEnum.AddDay) {
      const dayNumberControl = this.form.get('dayNumber');

      this.clearBeforeDayAdding.emit(dayNumberControl);
      this.addNewDay = true;
    };
  }

  daysValidator() {
    return (control: AbstractControl): ValidationErrors | null => {
      return (this.originalPeriods.find(period => period.dayNumber === control?.value))
        ? { duplicatedDay: true }
        : null;
    };
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}