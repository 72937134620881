import { Component, ElementRef, HostListener, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { VirtualKeyboardModesEnum } from './virtual-keyboard-modes.enum';
import { CommonModule } from '@angular/common';
import { VirtualKeyboardConstants } from './virtual-keyboard.constants';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectVirtualKeyboardAMPMDisabled,
  selectVirtualKeyboardElementUUID, selectVirtualKeyboardLabelForRanges,
  selectVirtualKeyboardMode,
  selectVirtualKeyboardRanges, setVirtualKeyboardAMPM,
  VirtualKeyboardButtonsEnum,
} from '@livestock/ui';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { setVirtualKeyboardElementUuid, setVirtualKeyboardSymbol } from './+state/virtual-keyboard.actions';
import { IMinMax } from '@livestock/shared/interfaces';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { IconsEnum } from '@livestock/shared/enums';
import { AmPmEnum } from '../../../../../controllers/src/lib/enums/am-pm.enum';

@Component({
  selector: 'lv-virtual-keyboard',
  templateUrl: 'virtual-keyboard.component.html',
  styleUrls: ['virtual-keyboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
  ],
})
export class VirtualKeyboardComponent implements OnInit, OnDestroy {
  @ViewChild('keyboard') keyboard: ElementRef;

  // subs
  sub$ = new Subscription();
  keyboardMode$: Observable<VirtualKeyboardModesEnum> = this.store.select(selectVirtualKeyboardMode);
  AMPMDisabled$: Observable<boolean> = this.store.select(selectVirtualKeyboardAMPMDisabled);
  labelForRanges$: Observable<string> = this.store.select(selectVirtualKeyboardLabelForRanges);

  //vars
  isActive: boolean;
  ranges: IMinMax;

  // constants
  VirtualKeyboardModesEnum = VirtualKeyboardModesEnum;
  VirtualKeyboardConstants = VirtualKeyboardConstants;
  VirtualKeyboardButtonsEnum = VirtualKeyboardButtonsEnum;
  AmPmEnum = AmPmEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;

  /* to prevent input focusout if clicked on the keyboard component */
  @HostListener('mousedown', ['$event'])
  onClick(): boolean {
    return false;
  }

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectVirtualKeyboardElementUUID).pipe(
        wasChanged(),
      ).subscribe((uuid: string) => {
        this.isActive = !!uuid;
      }),
    );

    this.sub$.add(
      this.store.select(selectVirtualKeyboardRanges).pipe(
        wasChanged(),
      ).subscribe((ranges: IMinMax) => {
        this.ranges = ranges;
      }),
    );
  }

  printSymbol(symbol: number | string): void {
    this.store.dispatch(setVirtualKeyboardSymbol({ symbol }));
  }

  setAmPm(AMPM: AmPmEnum): void {
    this.store.dispatch(setVirtualKeyboardAMPM({ AMPM }));
  }

  hideKeyboard(): void {
    this.store.dispatch(setVirtualKeyboardElementUuid({ elementUuid: null }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
