import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import {
  ButtonComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { SettingsRanges, ITemperatureCurveSettings } from '@livestock/temperature-curve';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { EnumPipe } from '@livestock/shared/pipes';
import { CommonModule } from '@angular/common';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-temperature-curve-settings',
  templateUrl: './temperature-curve-settings.component.html',
  styleUrls: ['./temperature-curve-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    SvgIconComponent,
    ButtonComponent,
    EnumPipe,
  ],
})
export class TemperatureCurveSettingsFormComponent implements OnDestroy, OnInit {
  @Input() editMode: boolean;
  @Input() isLoading: boolean;

  form: FormGroup = new FormGroup({
    curveOffset: new FormControl(0),
    comfortZone: new FormControl(0),
  });
  originalSettings: { curveOffset: number, comfortZone: number };

  @Input() set temperatureUnit(unit: TemperatureUnitEnum) {
    this.temperatureUnitEnum = unit;
    const isCelsius = this.temperatureUnitEnum === TemperatureUnitEnum.Celsius;
    this.MIN_VALUE = isCelsius ? SettingsRanges.MIN_VALUE_C : SettingsRanges.MIN_VALUE_F;
    this.MAX_VALUE = isCelsius ? SettingsRanges.MAX_VALUE_C : SettingsRanges.MAX_VALUE_F;
    this.form.controls['curveOffset'].addValidators([Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)]);
    this.form.controls['comfortZone'].addValidators([Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)]);
  };

  @Input() set temperatureCurveSettings(settings: ITemperatureCurveSettings) {
    if (!settings || JSON.stringify(this.form.value) === JSON.stringify({
      curveOffset: settings?.curveOffset,
      comfortZone: settings?.comfortZone,
    })) return;

    this.form.patchValue({
      curveOffset: settings?.curveOffset,
      comfortZone: settings?.comfortZone,
    });
    this.originalSettings = this.form.value;
  };

  @Input() set isSettingsDirty(isSettingsDirty: boolean) {
    if (!isSettingsDirty && this.form.dirty) {
      this.form.markAsPristine();
      this.form.updateValueAndValidity();
    }
  };

  @Output() changed = new EventEmitter();

  // subs
  sub$: Subscription = new Subscription();

  // component vars
  MIN_VALUE: number;
  MAX_VALUE: number;
  temperatureUnitEnum: TemperatureUnitEnum;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          if (!this.originalSettings) {
            this.originalSettings = formValues;
            return;
          }

          this.changed.emit({
            formValues,
            isValid: this.form.valid,
            isDirty: JSON.stringify(formValues) !== JSON.stringify(this.originalSettings),
          });
        }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
