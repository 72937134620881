<nav
  [class.rtl]="languageService.isRtl"
  class="nav d-flex align-center justify-between">
  <div class="menu-container">
    <ls-svg-icon [path]="IconsEnum.CONTROLLER_ICON" />
    <span class="menu-container__controller-count">6</span>

    <div
      (click)="menuService.toggleDashboardMenu()"
      class="menu-container__menu-circle pointer">
      {{ "ControllerMenu.Menu" | translate }}
    </div>
  </div>

  <div class="alarms-container">
    <div class="alarms-container__alarms mh-8">
      <div class="alarms-container__alarms-count">28</div>
      <ls-svg-icon [path]="IconsEnum.ALARM" />
    </div>
    <div class="alarms-container__days">
      <div class="title">{{ "ControllerMenu.Day" | translate }}</div>
      <div class="value">24</div>
    </div>
    <div class="alarms-container__growing mh-8">
      <div class="title">{{ "ControllerMenu.Growing" | translate }}</div>
      <div class="value">Full House</div>
    </div>
  </div>

  <div class="temp-time-container">
    <div class="temp-time-container__temp">
      <div class="title">{{ "ControllerMenu.Temperature" | translate }}</div>
      <div class="value">24 <span class="sub">/ 25.0°C</span></div>
    </div>
    <div
      (click)="logout()"
      class="temp-time-container__cloud pointer ml-28 mr-28">
      <ls-svg-icon [path]="IconsEnum.CLOUD" />
    </div>
    <div class="temp-time-container__time">
      <div class="title">March 5, 24</div>
      <div class="value">20:36</div>
    </div>
  </div>
</nav>
