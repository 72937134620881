import { createFeatureSelector, createSelector } from '@ngrx/store';

import { HeatingProgramZoneState } from './heating-program-zone.state';

export const HEATING_PROGRAM_ZONE = 'heatingProgramZone';

export const getHeatingProgramZoneState =
  createFeatureSelector<HeatingProgramZoneState>(HEATING_PROGRAM_ZONE);

export const selectIsLoading = createSelector(
  getHeatingProgramZoneState,
  ({ isLoading }) => isLoading,
);

export const selectHeatingProgramZones = createSelector(
  getHeatingProgramZoneState,
  ({ view }) => view?.items,
);

export const selectOriginalZones = createSelector(
  getHeatingProgramZoneState,
  ({ originalZones }) => originalZones,
);

export const selectHeatingDOElements = createSelector(
  getHeatingProgramZoneState,
  ({ view }) => view?.heatingDOElements,
);

export const selectHeatingAOElements = createSelector(
  getHeatingProgramZoneState,
  ({ view }) => view?.heatingAOElements,
);

export const selectSensorElements = createSelector(
  getHeatingProgramZoneState,
  ({ view }) => view?.sensorElements,
);

export const selectActiveZoneID = createSelector(
  getHeatingProgramZoneState,
  ({ activeZoneID }) => activeZoneID,
);

export const selectActiveZoneIndex = createSelector(
  selectHeatingProgramZones,
  selectActiveZoneID,
  (zones, activeZoneID) => zones?.findIndex(zone => zone.zoneID === activeZoneID),
);

export const selectHeatingProgramActiveZone = createSelector(
  selectHeatingProgramZones,
  selectActiveZoneID,
  (zones, activeZoneID) => zones?.find(zone => zone.zoneID === activeZoneID),
);

export const selectIfSomeZoneNamesAreEmpty = createSelector(
  getHeatingProgramZoneState,
  ({ view }) => {
    if (!view) {
      return true;
    }

    return view.items.some(zone => zone.name === '');
  },
);

export const selectSelectedHeatersIDs = createSelector(
  getHeatingProgramZoneState,
  ({ selectedHeaters }) => selectedHeaters,
);
