<div
  class="page-info pb-30 pl-15 fz-24 fz-18-lg d-flex position-relative justify-between pl-0-device pb-18-device pb-12-mobile">
  <div class="page-info__title d-none-md">
    <h4 qaTags="qa-lbl-dashboard-title">Dashboard</h4>
  </div>

  <div class="page-info__day fw-500 position-absolute left-50-percent fz-18-lg">
    <span>Day 24, Minimal</span>
  </div>

  <div class="page-info__nav d-flex justify-between w-32-percent w-auto-md align-center fz-18-lg">
    <span>Full House</span>
    <img
      alt="alarm"
      class="d-none-md"
      src="/assets/icons/arrows/chevron-right-grey.svg" />
  </div>
</div>
