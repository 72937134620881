import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { ColorsEnum, ElementTypesEnum } from '@livestock/shared/enums';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { InputIntegerComponent } from '@livestock/ui';
import { GlobalConstants } from '@livestock/shared/constants';
import { ErrorFieldDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-icon-input-button',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    InputIntegerComponent,
    ErrorFieldDirective,
  ],
  templateUrl: './icon-input-button.component.html',
  styleUrls: ['./icon-input-button.component.scss'],
})
export class IconInputButtonComponent  {
  @Input() path: string;
  @Input() activeIndex: number;
  @Input() items: any;
  @Input() width: number;
  @Input() editMode: boolean = false;
  @Input() accuracy: number = 0;
  @Input() unit: string = GlobalConstants.PercentageSymbol;
  @Input() min: number = 0;
  @Input() max: number = GlobalConstants.SMALLINT_MAX;
  @Input() extraPadding: number = 15;
  @Input() isMobileApp: boolean = false;
  @Output() setActiveIcon = new EventEmitter();
  @Output() change = new EventEmitter();
  @Output() onFocusIn = new EventEmitter();
  @Output() onFocusOut = new EventEmitter();

  ColorsEnum = ColorsEnum;
  GlobalConstants = GlobalConstants;
  ElementTypesEnum = ElementTypesEnum;

  setActiveIndex(index: number, elementType): void {
    if (elementType === ElementTypesEnum.VentilationDO) return;

    this.activeIndex = index;
    this.setActiveIcon.emit(this.activeIndex);
  }

  setPercentageValue(currentItem): void {
    this.change.emit(currentItem);
  }

  onFocusInEvent(): void  {
    this.onFocusIn.emit(true);
  }

  onFocusOutEvent(): void  {
    this.onFocusOut.emit(false);
  }
}
