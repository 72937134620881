<div class="widget-wrapper d-flex">
  <div class="widget-top d-flex flex-column mr-25">
    <h6
      [qaTags]="'qa-lbl-widget-title'"
      class="widget-title">
      Cooling
    </h6>

    <div class="widget-icon">
      <ls-svg-icon
        [color]="ColorsEnum.BlueDefault"
        [hasMargin]="true"
        [qaTags]="'qa-img-widget'"
        path="dashboard/cooling"></ls-svg-icon>
    </div>
  </div>

  <div class="widget-body w-100-percent">
    <div class="widget-columns d-flex mb-7">
      <div
        *ngFor="let column of columns; last as last"
        [class.mr-14-desktop]="!last"
        [qaTags]="'qa-lbl-cooling-unit-num'"
        class="widget-column mr-40">
        {{ column }}
      </div>
    </div>

    <div class="widget-content">
      <div class="widget-content__blocks">
        <div class="widget-content__block widget-content__block-top fz-22-mobile">
          <div
            [qaTags]="'qa-lbl-cooling-cycle-status'"
            class="widget-content__value mr-40 mr-14-desktop mr-12-mobile pt-10-desktop pt-20">
            ON
            <span
              [qaTags]="'qa-lbl-cooling-cycle-time'"
              class="fz-16"
              >23sec</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-cooling-cycle-status'"
            class="widget-content__value mr-40 mr-14-desktop mr-12-mobile pt-10-desktop">
            ON
            <span
              [qaTags]="'qa-lbl-cooling-cycle-time'"
              class="fz-16"
              >23sec</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-cooling-cycle-status'"
            class="widget-content__value widget-content__value-inactive pt-10-desktop">
            OFF
            <span
              [qaTags]="'qa-lbl-cooling-cycle-time'"
              class="fz-16"
              >0sec</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
