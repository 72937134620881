import { Component } from '@angular/core';
import { logOut } from '@livestock/auth';
import { Store } from '@ngrx/store';
import { Router, RouterModule } from '@angular/router';
import { Observable } from 'rxjs';
import { PlatformService, MenuService, SearchMobileService, LanguageService, ActivatedRouteService } from '@livestock/shared/services';
import { CommonModule } from '@angular/common';
import { SearchComponent, SvgIconComponent } from '@livestock/ui';
import { ReactiveFormsModule } from '@angular/forms';
import { ColorsEnum, IconsEnum } from '@livestock/shared/enums';
import { selectCurrentUserInitials } from '@livestock/current-user';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ControllerLanguageEnum, selectCurrentControllerHouseNumber } from '@livestock/controllers';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    SvgIconComponent,
    SearchComponent,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    MemoizeFuncPipe,
  ],
  selector: 'ls-top-menu',
  templateUrl: './top-menu.component.html',
  styleUrls: ['./top-menu.component.scss'],
})
export class TopMenuComponent {
  activeHouseNumber$: Observable<number> = this.store.select(selectCurrentControllerHouseNumber);
  currentUserInitials$: Observable<string> = this.store.select(selectCurrentUserInitials);
  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  IconsEnum = IconsEnum;

  constructor(
    public languageService: LanguageService,
    public menuService: MenuService,
    public platformService: PlatformService,
    public router: Router,
    public activatedRouteService: ActivatedRouteService,
    public searchMobileService: SearchMobileService,
    private store: Store,
  ) {
  }

  closeSearch(): void {
    this.searchMobileService.isSearchOpened$.next(false);
  }

  openSearch(): void {
    this.searchMobileService.isSearchOpened$.next(true);
  }

  logout(): void {
    this.store.dispatch(logOut());
  }
}
