import { Component } from '@angular/core';
import { MainWidgetCommonComponent } from '../main-widget-common.component';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '@livestock/ui';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  selector: 'ls-main-cooling-widget',
  templateUrl: './main-cooling-widget.component.html',
  styleUrls: ['./main-cooling-widget.component.scss'],
})
export class MainCoolingWidgetComponent extends MainWidgetCommonComponent {
}
