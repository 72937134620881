<div
  [class.rtl]="languageService.isRtl"
  class="popup h-100-percent d-flex flex-column justify-between">
  <div class="popup-top mb-40">
    <div class="d-flex align-center justify-between mb-25">
      <h3
        [qaTags]="'qa-lbl-popup-title'"
        class="popup-title fz-20 color-primary d-flex align-center">
        <img
          (click)="goBack()"
          [qaTags]="'qa-btn-return'"
          alt="arrow"
          class="go-back pointer mr-20"
          src="/assets/icons/arrows/chevron-left-black.svg" />

        <span>{{ data.wifiName }}</span>
      </h3>

      <img
        (click)="close()"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="close-icon pointer"
        src="/assets/icons/common/cross.svg" />
    </div>

    <div class="popup-body">
      <div
        [formGroup]="form"
        class="d-flex flex-column w-100-percent">
        <div class="w-100-percent">
          <label class="d-block mb-10 fz-16 color-tertiary mb-10">
            {{ "Controller.WiFiPopup.Password" | translate }}
          </label>

          <div class="position-relative mb-30">
            <ls-input-text
              [fieldWithKeyboard]="true"
              [qaTags]="'qa-txt-password'"
              [placeholder]="'Controller.WiFiPopup.EnterPassword' | translate"
              [inputType]="passwordIsVisible ? 'text' : 'password'"
              formControlName="wifiPassword">
            </ls-input-text>

            <ls-svg-icon
              (click)="passwordIsVisible = !passwordIsVisible"
              [path]="passwordIsVisible ? 'auth/eye-closed-icon' : 'auth/eye-icon'"
              class="pointer eye-icon">
            </ls-svg-icon>
          </div>
        </div>

        <div class="d-flex align-center justify-between w-100-percent mb-20">
          <h4 class="fz-20 color-secondary">
            {{ "Controller.WiFiPopup.AutoConnect" | translate }}
          </h4>

          <ls-toggle
            [size]="'large'"
            formControlName="wifiAutoConnect" />
        </div>

        <div
          *ngIf="showPasswordError"
          class="color-danger fz-16">
          {{ "Controller.WiFiPopup.WrongPasswordPleaseTryAgain" | translate }}
        </div>
      </div>
    </div>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-end buttons">
      <ls-button
        (click)="connect()"
        [qaTags]="'qa-btn-connect'"
        [class.disabled]="!form.valid"
        class="button ml-10"
        type="primary">
        {{ "Buttons.Connect" | translate }}
      </ls-button>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="loading d-flex align-center justify-center">
    <ls-loading-lines />
  </div>
</div>

<ls-keyboard
  *ngIf="!platformService.isMobileApp"
  class="keyboard" />
