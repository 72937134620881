import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SlimButtonComponent } from '@livestock/ui';
import { ButtonTypeEnum } from '@livestock/shared/enums';

@Component({
  selector: 'lv-flock-delete-day',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SlimButtonComponent,
  ],
  templateUrl: './flock-delete-day.component.html',
  styleUrls: ['./flock-delete-day.component.scss'],
})
export class FlockDeleteDayComponent {
  @Input() selectedDaysLength: number;
  @Output() cancel = new EventEmitter();
  @Output() deleteRows = new EventEmitter();

  ButtonTypeEnum = ButtonTypeEnum;

  emitCancel(): void {
    this.cancel.emit();
  }

  emitDeleteRows(): void {
    this.deleteRows.emit();
  }
}
