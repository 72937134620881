import { IconsKeyboardComponent } from './../../../ui/src/lib/controls/icons-keyboard/icons-keyboard.component';
import { Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  FormBuilder,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
  FormArray,
  AbstractControl,
  ValidationErrors,
} from '@angular/forms';
import {
  ButtonComponent,
  DaysManagerMobileComponent,
  HeaderTitleComponent,
  IconInputButtonComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent, PageWrapperComponent,
  SensorsKeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { ColorsEnum, DialogButtonEnum, ElementTypesEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { Observable, Subscription, filter } from 'rxjs';
import * as basicVentilationActions from './+state/basic-ventilation-minimum.actions';
import * as basicVentilationSelectors from './+state/basic-ventilation-minimum.selectors';
import { IBasicVentilationMinimumItem } from './interfaces/basic-ventilation-minimum-item.interface';
import { Router } from '@angular/router';
import { IInlets } from './interfaces/inlets.interface';
import { IFans } from './interfaces/fans.interface';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ErrorFieldDirective } from '@livestock/shared/directives';
import { IElement } from 'libs/installation/src/lib/interfaces/element/element.interface';
import { BasicVentilationConstants } from './constants/basic-ventilation.constants';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import {
  TemperatureCurveChartComponent,
  TemperatureCurveSettingsFormComponent,
  TemperatureCurveTableComponent,
} from '@livestock/temperature-curve';
import { wasChangedAndNotEmpty, wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-basic-ventilation-minimum',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HeaderTitleComponent,
    SvgIconComponent,
    TranslateModule,
    InputIntegerComponent,
    InputDecimalComponent,
    ButtonComponent,
    ErrorFieldDirective,
    MemoizeFuncPipe,
    LoadingComponent,
    DaysManagerMobileComponent,
    SensorsKeyboardComponent,
    KeyboardComponent,
    IconInputButtonComponent,
    IconsKeyboardComponent,
    PageWrapperComponent,
    TemperatureCurveChartComponent,
    TemperatureCurveSettingsFormComponent,
    TemperatureCurveTableComponent,
  ],
  templateUrl: './basic-ventilation-minimum.component.html',
  styleUrls: ['./basic-ventilation-minimum.component.scss'],
})

export class BasicVentilationMinimumComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  /*constants*/
  ColorsEnum = ColorsEnum;
  KeyboardModeEnum = KeyboardModeEnum;
  ElementTypesEnum = ElementTypesEnum;
  BasicVentilationConstants = BasicVentilationConstants;

  /*variables*/
  form: FormGroup;
  editMode: boolean = false;
  selectedIndex: number = 0;
  isEmpty: boolean = false;
  originalItems: IBasicVentilationMinimumItem[] = [];
  activeDay: number;
  days: number[];
  isCellActive: boolean = false;
  elements: IElement[];
  selectedFanIndex: number;
  itemsLength: number;
  canDeactivateValue: boolean = true;
  isIconInputActive: boolean = false;

  /*observables*/
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(basicVentilationSelectors.selectIsLoading);
  ventilationDOElements$: Observable<IElement[]> = this.store.select(basicVentilationSelectors.selecVentilationDOElements).pipe(filter(x => x.length > 0));
  ventilationAOElements$: Observable<IElement[]> = this.store.select(basicVentilationSelectors.selecVentilationAOElements).pipe(filter(x => x.length > 0));

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private formBuilder: FormBuilder,
    private router: Router,
    private dialogsService: DialogsService,
    private menuService: MenuService,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(basicVentilationActions.getBasicVentilationMinimumItems());

    this.sub$.add(
      this.store.select(basicVentilationSelectors.selectCanDeactivate).subscribe((canDeactivateValue) => {
        this.canDeactivateValue = canDeactivateValue;
      }),
    );

    this.sub$.add(
      this.store.select(basicVentilationSelectors.selectBasicVentilationMinimumItems)
        .pipe(wasChangedAndNotEmpty()).subscribe((items) => {
          this.itemsLength = items?.length;

          if (!this.form || this.form?.get('items')?.value?.length !== this.itemsLength || this.isFansChanged(items)) {
            this.initForm(items);
          } else {
            this.updateFormValues(items);
          }

          // mobile
          if (this.platformService.isMobileApp) {
            this.activeDay = items?.[0]?.dayNumber ?? 0;
          }

          this.days = this.form.value.items.map(x => x.dayNumber ?? 0).sort((a, b) => a - b);
          this.isEmpty = this.form.value.items.every(item => item.dayNumber == null);
        },
      ),
    );
  }

  initForm(items: IBasicVentilationMinimumItem[]): void {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array(items?.map(i => this.getItemForm(i))),
    });

    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe(({ items }) => {
          this.store.dispatch(basicVentilationActions.setBasicVentilationItems({ items }));
        }),
    );
  }

  updateFormValues(items: IBasicVentilationMinimumItem[]): void {
    this.form.patchValue({ items });
  }

  isFansChanged(items: IBasicVentilationMinimumItem[]): boolean {
    return this.form && this.form?.get('items')?.value[this.selectedIndex]?.fans?.length !== items?.[this.selectedIndex]?.fans?.length;
  }

  getItemForm(item: IBasicVentilationMinimumItem | null): FormGroup {
    return this.formBuilder.group({
      basicVentilationID: this.formBuilder.control(item?.basicVentilationID),
      dayNumber: this.formBuilder.control(item?.dayNumber, [
        Validators.max(BasicVentilationConstants.maxDayValue),
        Validators.min(BasicVentilationConstants.minDayValue),
        Validators.required,
        this.validateUniqueDay(),
      ]),
      offTime: this.formBuilder.control(item?.offTime, [
        Validators.min(BasicVentilationConstants.minDayValue),
        Validators.required,
      ]),
      onTime: this.formBuilder.control(item?.onTime, [
        Validators.min(BasicVentilationConstants.minDayValue),
        Validators.required,
      ]),
      inlets: item != null ? this.getInletsFormArr(item.inlets) : null,
      fans: item != null ? this.getFansFormArr(item.fans) : null,
    });
  }

  getInletsFormArr(inlets: IInlets[] | IElement[]): FormArray {
    return this.formBuilder.array(inlets?.map(i => this.formBuilder.group({
      basicVentilationID: this.formBuilder.control(i?.basicVentilationID || null),
      elementID: this.formBuilder.control(i.elementID),
      elementType: this.formBuilder.control(i.elementType),
      number: this.formBuilder.control(i.number),
    })));
  }

  getFansFormArr(fans: IFans[] | IElement[]): FormArray {
    return this.formBuilder.array(fans?.map(f => this.formBuilder.group({
      basicVentilationID: this.formBuilder.control(f?.basicVentilationID || null),
      elementID: this.formBuilder.control(f.elementID, [Validators.required]),
      elementType: this.formBuilder.control(f.elementType),
      number: this.formBuilder.control(f.number),
      percentage: this.formBuilder.control(f?.elementType === ElementTypesEnum.VentilationAO ? (f?.percentage ?? 0) : null, [
        Validators.min(BasicVentilationConstants.minPercentage),
        Validators.max(BasicVentilationConstants.maxPercentage),
      ]),
    })));
  }

  enableEdit(): void {
    this.editMode = true;
    if (!this.platformService.isMobileApp) this.selectedIndex = null;
  }

  add(): void {
    this.store.dispatch(basicVentilationActions.addNewItem());
    this.selectedIndex = this.itemsLength - 1;
    this.reset();
  }

  async remove(): Promise<void> {
    if (this.platformService.isMobileApp && this.itemsLength > 0) {
      const title = 'AirTreatment.DeleteDay';
      const message = 'AirTreatment.DeleteDayMessage';
      const result = await this.dialogsService.question(
        message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE], 'flash/warning',
      );

      if (!result || result === DialogButtonEnum.CANCEL) {
        return;
      }

      this.editMode = false;
      this.store.dispatch(basicVentilationActions.removeItem({ selectedIndex: this.selectedIndex }));
      this.store.dispatch(basicVentilationActions.updateBasicVentilationMinimumItems());
      this.selectedIndex = 0;
      return;
    }

    if (this.selectedIndex != null) this.store.dispatch(basicVentilationActions.removeItem({ selectedIndex: this.selectedIndex }));
    this.selectedIndex = this.itemsLength ?? null;
  }

  save(): void {
    if (this.form.valid) {
      this.store.dispatch(basicVentilationActions.updateBasicVentilationMinimumItems());
    }
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (!this.canDeactivateValue) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    return true;
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.editMode = false;
      this.store.dispatch(basicVentilationActions.getBasicVentilationMinimumItems());
      this.reset();

      if (this.platformService.isMobileApp) this.selectedIndex = this.form.get('items')['controls'].findIndex(control => control.value.dayNumber === this.activeDay) ?? 0;
      ;
    }
  }

  redirectToSettings(): void {
    this.router.navigate([`${this.router.url}/settings`]);
  }

  setActiveDay(day: number): void {
    this.activeDay = day;
    this.selectedIndex = this.form.get('items')['controls'].findIndex(control => control.value.dayNumber === day) ?? 0;
  }

  setActiveCell(event: Event, index?: number): void {
    if (!this.editMode) return;
    event.stopPropagation();

    this.isCellActive = true;
    this.selectedIndex = index;
  }

  setVentValues(elements: IElement[]): void {
    this.store.dispatch(basicVentilationActions.setFans({ elements, selectedIndex: this.selectedIndex }));
  }

  onPercentageChange(currentItem): void {
    this.store.dispatch(basicVentilationActions.setPercentage({ currentItem, selectedIndex: this.selectedIndex }));
    this.isIconInputActive = false;
  }

  onFocusOut(isIconInputActive: boolean): void {
    this.isIconInputActive = isIconInputActive;
    this.selectedFanIndex = null;
  }

  onIconClick(activeIndex): void {
    this.selectedFanIndex = activeIndex;
  }

  setSelectedIndex(index: number): void {
    if (!this.editMode) return;
    this.selectedIndex = index;
    this.reset();
  }

  reset(): void {
    this.isCellActive = false;
    this.selectedFanIndex = null;
  }

  // mobile
  @HostListener('document:click', ['$event'])
  onDocumentClick(): void {
    if (this.platformService.isMobileApp) {
      this.isCellActive = false;
      this.selectedFanIndex = null;
    }
  }

  setActiveCellMobile(event: Event): void {
    event.stopPropagation();
    this.isCellActive = !this.isCellActive;
  }

  checkDuplicacy(): void {
    const itemsArr = (this.form.controls['items'] as FormArray);
    itemsArr.controls.forEach((x) => {
      (x as FormGroup).get('dayNumber').updateValueAndValidity();
    });
  }

  canDeactivate(): boolean {
    return this.canDeactivateValue || !this.editMode;
  }

  closeComponent(): void {
  }

  async goBack(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private validateUniqueDay(): any {
    return (control: AbstractControl): ValidationErrors | null => {
      if (control.value != null) {
        const formArray = control.parent
          ? (control.parent.parent as FormArray)
          : null;
        if (formArray) {
          const days = formArray.value.map((x) => x.dayNumber);
          return days.filter(x => x == control.value).length > 1
            ? { duplicatedDay: true }
            : null;
        }
      }
      return null;
    };
  }
}
