import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { Store } from '@ngrx/store';
import { logOut } from '@livestock/auth';
import { PageContentComponent } from '../page-content/page-content.component';
import { CommonModule } from '@angular/common';
import { DialogsService, LocalStorageService, MenuService } from '@livestock/shared/services';
import { DialogButtonEnum, StorageItem } from '@livestock/shared/enums';
import { AppRoutes } from '@livestock/shared/routes';

@Component({
  standalone: true,
  selector: 'ls-dashboard',
  imports: [
    CommonModule,
    RouterModule,
    PageContentComponent,
  ],
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit, OnDestroy {
  constructor(
    private menuService: MenuService,
    private store: Store,
    private router: Router,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    const controllerID = LocalStorageService.getStorageItem(StorageItem.ActiveControllerID);
    const checkNewFlockControllerID = LocalStorageService.getStorageItem(StorageItem.CheckNewFlockControllerID);

    if (controllerID === checkNewFlockControllerID) {
      this.checkIfNeedNewFlock();
      LocalStorageService.removeStorageItem(StorageItem.CheckNewFlockControllerID);
    }
  }

  async checkIfNeedNewFlock(): Promise<void> {
    const message = 'Flock.NewFlock.CreateNewFlockMessage';
    const title = 'Flock.NewFlock.CreateNewFlock';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE],
    );

    if (result === DialogButtonEnum.CONTINUE) {
      this.router.navigateByUrl(AppRoutes.NEW_FLOCK);
    }
  }

  logout(): void {
    this.store.dispatch(logOut());
  }

  ngOnDestroy(): void {
    this.menuService.toggleDashboardMenu(false);
  }
}
