import { Component } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { PlatformService } from '@livestock/shared/services';
import { KeyboardModeEnum } from '@livestock/ui';

@Component({
  selector: 'ls-sprinklers-and-foggers-testing-form',
  templateUrl: './sprinklers-and-foggers-testing-form.component.html',
  styleUrls: ['./sprinklers-and-foggers-testing-form.component.scss'],
})
export class SprinklersAndFoggersTestingFormComponent {

  form: FormGroup;
  KeyboardModeEnum = KeyboardModeEnum;

  constructor(public platformService: PlatformService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      time: new FormControl(''),
    });
  }
}
