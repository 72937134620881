import { Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, Optional, Output, SimpleChanges, ViewChild } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { MatSliderModule } from '@angular/material/slider';
import { TranslateModule } from '@ngx-translate/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { IMinMax } from '@livestock/shared/interfaces';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'ls-range',
  templateUrl: './range.component.html',
  styleUrls: ['./range.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    CommonModule,
    SvgIconComponent,
    MatSliderModule,
    TranslateModule,
    NativeElementInjectorDirective,
    MemoizeFuncPipe,
  ],
})

export class RangeComponent implements ControlValueAccessor, OnChanges {
  @ViewChild('rangeMin') rangeMin: ElementRef;
  @ViewChild('rangeMax') rangeMax: ElementRef;
  @Input() value: IMinMax;
  @Input() min: number = 0;
  @Input() max: number = 10;
  @Input() sliderWidth: number = 169;
  @Output() change = new EventEmitter<IMinMax>();

  TICKS_COUNT = 11;
  TICKS_GAP_PX = 20;
  TICK_MARGIN_PX = -2;

  constructor(@Optional() private control: NgControl) {
    control.valueAccessor = this;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['min']?.currentValue != null && changes['max']?.currentValue != null) {
      this.TICKS_COUNT = this.max - this.min;
      this.TICKS_GAP_PX = this.sliderWidth / this.TICKS_COUNT;
    }
  }

  @HostListener('input', ['$event'])
  onRangeChanged(): void {
    const min = this.rangeMin.nativeElement.value;
    const max = this.rangeMax.nativeElement.value;
    this.valueChange({ min, max });
  }

  tickIsInRange([value, index]: [IMinMax, number]): boolean {
    return index + this.min >= +value.min && index + 1 + this.min <= +value.max;
  }

  valueChange(value: IMinMax): void {
    this.value = value;
    this.onChange(value);
    this.change.emit(value);
  }

  writeValue(value: IMinMax): void {
    if (JSON.stringify(this.value) === JSON.stringify(value)) return;
    this.value = value;
  }

  registerOnChange(
    onChange: (value: IMinMax) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {
  };

  private onChange: (
    value: IMinMax,
  ) => void = () => {
  };
}
