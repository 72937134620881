import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  selector: 'ls-temperature-widget',
  templateUrl: './temperature-widget.component.html',
  styleUrls: ['./temperature-widget.component.scss'],
})
export class TemperatureWidgetComponent {
  ColorsEnum = ColorsEnum;
}
