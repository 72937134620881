import { Validators } from '@angular/forms';

export enum SelectDialogCssClass {
  DEFAULT = 'default',
  MOBILE = 'mobile'
}

export interface ISelectDialogOptions {
  fieldTitle?: string;
  translateFieldTitle?: boolean;
  validators?: Validators[];
  list?: any[];
  enum?: any;
  visibleEnumItems?: any[];
  enumString?: string;
  value?: any;
  additionalInfo?: string;
  bindValue?: string;
  bindLabel?: string;
  bindSubLabel?: string;
  searchable?: boolean;
  dialogStyle?: SelectDialogCssClass;
}
