export enum IconsQuickStartEnum {
  QS_COG = 'new-icons/quick-start/settings',
  QS_CLOUD = 'new-icons/quick-start/cloud',
  QS_CONNECTIONS = 'new-icons/quick-start/connections',
  QS_DATE_TIME = 'new-icons/quick-start/date-time',
  QS_FLOCK = 'new-icons/quick-start/flock-cock',
  QS_HOUSE = 'new-icons/quick-start/house',
  QS_HOUSE_MODE = 'new-icons/quick-start/house-mode',
  QS_LANGUAGE = 'new-icons/quick-start/language',
  QS_NETWORK = 'new-icons/quick-start/network',
  NETWORK_IP = 'new-icons/quick-start/network-ip',
}
