import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LightingMappingState } from './lighting-mapping.state';

export const LIGHTING_MAPPING_KEY = 'lightingMapping';

export const getTempMappingState =
  createFeatureSelector<LightingMappingState>(LIGHTING_MAPPING_KEY);

export const selectIsLoading = createSelector(
  getTempMappingState,
  (({ isLoading }) => isLoading),
);

export const selectLightingMappings = createSelector(
  getTempMappingState,
  (({ lightingMappings }) => lightingMappings),
);

export const selectOriginalLightingMappings = createSelector(
  getTempMappingState,
  (({ originalLightingMappings }) => originalLightingMappings),
);

export const selectLightingMappingsSensorsList = createSelector(
  getTempMappingState,
  (({ sensorElements }) => sensorElements),
);

export const selectActiveMappingElementID = createSelector(
  getTempMappingState,
  (({ activeMappingElementID }) => activeMappingElementID),
);

export const selectActiveMapping = createSelector(
  selectLightingMappings,
  selectActiveMappingElementID,
  ((mappings, activeMappingElementID) => {
    return mappings.find(mapping => mapping.lightElementID === activeMappingElementID);
  }),
);

export const selectIsDirtyForm = createSelector(
  selectLightingMappings,
  selectOriginalLightingMappings,
  ((mappings, originalMappings) => {
    return JSON.stringify(mappings) !== JSON.stringify(originalMappings);
  }),
);
