import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  InputComponent,
  InputTextComponent,
  KeyboardComponent,
  LoadingLinesComponent,
  SvgIconComponent, ToggleComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SleepUtils } from '@livestock/shared/utils';
import { Store } from '@ngrx/store';
import { RequiredTrimValidator } from '@livestock/shared/validators';
import { Subscription } from 'rxjs';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-add-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    SvgIconComponent,
    KeyboardComponent,
    ReactiveFormsModule,
    InputTextComponent,
    LoadingLinesComponent,
    ToggleComponent,
  ],
  templateUrl: './wifi-password-dialog.component.html',
  styleUrls: ['./wifi-password-dialog.component.scss'],
})
export class WifiPasswordDialogComponent implements OnInit, OnDestroy {
  sub$ = new Subscription();
  isLoading: boolean;
  form: FormGroup = new FormGroup(
    {
      wifiPassword: new FormControl<string>('', [RequiredTrimValidator]),
      wifiAutoConnect: new FormControl<boolean>(true),
    },
  );
  password = '12345';
  passwordIsVisible: boolean;
  showPasswordError: boolean;

  constructor(
    public dialogRef: MatDialogRef<WifiPasswordDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private store: Store,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) public data: {
      wifiName: string,
    },
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(
        wasChanged(),
      ).subscribe(() => {
        this.showPasswordError = false;
      }),
    );
  }

  goBack(): void {
    this.dialogRef.close();
  }

  close(): void {
    this.dialog.closeAll();
  }

  async connect(): Promise<void> {
    this.isLoading = true;
    await SleepUtils.sleep(1000);
    this.isLoading = false;

    if (this.form.value.wifiPassword !== this.password) {
      this.showPasswordError = true;
      return;
    }

    this.dialogRef.close({
      ...this.form.getRawValue(),
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
