import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as temperatureCurveActions from './temperature-curve.actions';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { selectActiveControllerID, selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { ITemperatureCurve, ITemperatureCurveSettings, TemperatureCurveService } from '@livestock/temperature-curve';
import { TemperatureUnitEnum } from '@livestock/shared/enums';

@Injectable()
export class TemperatureCurveEffects {
  getTemperatureCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(temperatureCurveActions.getTemperatureCurve),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([_action, controllerID, temperatureUnit]: [any, number, TemperatureUnitEnum]) => {
        return this.temperatureCurveService.getTemperatureCurve(controllerID, temperatureUnit).pipe(
          map((view: ITemperatureCurve) => temperatureCurveActions.getTemperatureCurveSuccess({ view })),
          catchError((error) => of(temperatureCurveActions.getTemperatureCurveError({ payload: error }))),
        );
      }),
    ),
  );

  updateTemperatureCurve$ = createEffect(() =>
    this.actions$.pipe(
      ofType(temperatureCurveActions.updateTemperatureCurve),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
        ),
      switchMap(([{ view }, controllerID, temperatureUnit]: [any, number, TemperatureUnitEnum]) => {
        return this.temperatureCurveService.updateTemperatureCurve(controllerID, view.items, temperatureUnit).pipe(
          switchMap((view: ITemperatureCurve) => {
            return [
              temperatureCurveActions.updateTemperatureCurveSuccess({ view }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
            ];
          }),
          catchError((error) => of(temperatureCurveActions.updateTemperatureCurveError({ payload: error }))),
        );
      }),
    ),
  );

  getTemperatureCurveSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(temperatureCurveActions.getTemperatureCurveSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([_action, controllerID, temperatureUnit]: [any, number, TemperatureUnitEnum]) => {
        return this.temperatureCurveService.getTemperatureCurveSettings(controllerID, temperatureUnit).pipe(
          map((view: ITemperatureCurveSettings) =>
            temperatureCurveActions.getTemperatureCurveSettingsSuccess({ view }),
          ),
          catchError((error) => of(temperatureCurveActions.getTemperatureCurveSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateTemperatureCurveSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(temperatureCurveActions.updateTemperatureCurveSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
      ),
      switchMap(([{ view }, controllerID, temperatureUnit]: [any, number, TemperatureUnitEnum]) => {
        return this.temperatureCurveService.updateTemperatureCurveSettings(controllerID, view, temperatureUnit).pipe(
          switchMap((view: ITemperatureCurveSettings) => {
            return [
              temperatureCurveActions.updateTemperatureCurveSettingsSuccess({ view }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
            ];
          }),
          catchError((error) => of(temperatureCurveActions.updateTemperatureCurveSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private temperatureCurveService: TemperatureCurveService,
  ) {
  }
}
