import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { changeDefaultWeight, FlockConstants, IFlockBirdWeightSettingsView, WeightMethodEnum } from '@livestock/flock';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { InputDecimalComponent, InputIntegerComponent, SelectDirective } from '@livestock/ui';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { Subscription } from 'rxjs';
import { ChickenBrandWeight, WeightUnitEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ls-flock-bird-weight-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    QaTagsDirective,
    ErrorFieldDirective,
    EnumPipe,
    EnumToArrayPipe,
    InputIntegerComponent,
    InputDecimalComponent,
    SelectDirective,
    FormsModule,
  ],
  templateUrl: './bird-weight-settings-form.component.html',
  styleUrls: ['./bird-weight-settings-form.component.scss'],
})
export class BirdWeightSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Input() separateMaleAndFemale: boolean;
  @Input() refWeight: { weight: number, femaleWeight: number, maleWeight: number };

  @Input() set birdWeightSettings(birdWeightSettings: IFlockBirdWeightSettingsView) {
    if (birdWeightSettings) {
      this.updateFormValues(birdWeightSettings);
    }
  }

  @Output() changed = new EventEmitter();
  sub$ = new Subscription();
  minCurveOffset: number = FlockConstants.MinCurveOffsetGram;
  maxCurveOffset: number = FlockConstants.MaxCurveOffsetGram;
  form = new FormGroup(
    {
      controllerID: new FormControl(),
      weightMethod: new FormControl(WeightMethodEnum.Predefined, [Validators.required]),
      curveOffset: new FormControl(0, [
        Validators.required,
        Validators.min(FlockConstants.MinCurveOffsetRaw),
        Validators.max(FlockConstants.MaxCurveOffsetRaw),
      ]),
      defaultWeightBrandID: new FormControl<ChickenBrandWeight>(ChickenBrandWeight.COBB_500),
    },
  );

  WeightMethodEnum = WeightMethodEnum;
  WeightUnitEnum = WeightUnitEnum;
  ChickenBrandWeight = ChickenBrandWeight;

  private _weightUnit: WeightUnitEnum;

  get weightUnit(): WeightUnitEnum {
    return this._weightUnit;
  }

  @Input() set weightUnit(weightUnit: WeightUnitEnum) {
    if (weightUnit != null) {
      this._weightUnit = weightUnit;
      this.minCurveOffset = FlockConstants.getMinCurveOffsetByUnit(weightUnit);
      this.maxCurveOffset = FlockConstants.getMaxCurveOffsetByUnit(weightUnit);
      this.setMinMaxCurveOffsetValidators(this.minCurveOffset, this.maxCurveOffset);
    }
  }

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues,
          isValid: this.form.valid,
        });
      }),
    );
  }

  setMinMaxCurveOffsetValidators(minCurveOffset: number, maxCurveOffset: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;

    this.form.controls.curveOffset.addValidators(Validators.min(minCurveOffset));
    this.form.controls.curveOffset.addValidators(Validators.max(maxCurveOffset));
    this.form.controls.curveOffset.updateValueAndValidity();

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  updateFormValues(birdWeightSettings: IFlockBirdWeightSettingsView): void {
    this.form.patchValue(birdWeightSettings);
  }

  onWeightMethodChange(weightMethod: WeightMethodEnum): void {
    if (weightMethod === WeightMethodEnum.Predefined) {
      this.form.controls.defaultWeightBrandID.setValue(ChickenBrandWeight.COBB_500);
      this.form.controls.weightMethod.updateValueAndValidity();
      this.getDefaultWeights(ChickenBrandWeight.COBB_500);
    }
  }

  getDefaultWeights(brand: ChickenBrandWeight): void {
    this.store.dispatch(changeDefaultWeight({ brand, weightUnit: this._weightUnit }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
