import { Component, EventEmitter, Input, OnInit, Output, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-paging',
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  templateUrl: './paging.component.html',
  styleUrls: ['./paging.component.scss'],
})
export class PagingComponent implements OnInit {
  @Input() active: number = 1;
  @Input() pages: number = 1;
  @Input() range: number = 3;
  @Output() pageChanged = new EventEmitter<number>();
  viewport: number;

  constructor(public languageService: LanguageService) {
  }

  ngOnInit(): void {
    this.viewport = this.viewport >= this.pages ? this.pages : this.range;
  }

  emitChangeActivePage(index: number): void {
    this.pageChanged.emit(index);
    this.active = index;
  }

  previousPage(): void {
    if (this.active > 1) {
      this.emitChangeActivePage(this.active - 1);
    }
  }

  nextPage(): void {
    if (this.active < this.pages) {
      this.emitChangeActivePage(this.active + 1);
    }
  }

  countPage(index: number): number {
    return this.active - (this.viewport >> 1) + index;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['pages']) {
      this.viewport = this.viewport >= this.pages ? this.pages : this.range;
      if (this.active > this.pages) {
        this.emitChangeActivePage(this.pages);
      }
    }
  }
}
