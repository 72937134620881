import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
    IAirTreatmentListView,
    IAirTreatmentSettingsView,
    IAirTreatmentThresholdsView,
    IAirTreatmentView,
} from '@livestock/air-treatment';

export const getAirTreatmentItems = createAction(
    '[AIR TREATMENT] Get Air Treatment Items [...]',
    props<{ controllerID: number }>(),
);

export const getAirTreatmentItemsSuccess = createAction(
    '[AIR TREATMENT] Get Air Treatment Items [SUCCESS]',
    props<{ view: IAirTreatmentListView; }>(),
);

export const getAirTreatmentItemsError = createAction(
    '[AIR TREATMENT] Get Air Treatment Items [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const setAirTreatmentItems = createAction(
    '[AIR TREATMENT] Set Air Treatment Items [...]',
    props<{ items: IAirTreatmentView[]; }>(),
);

export const updateAirTreatmentItems = createAction(
    '[AIR TREATMENT] Update Air Treatment Items [...]',
    props<{ controllerID: number }>(),
);

export const updateAirTreatmentItemsSuccess = createAction(
    '[AIR TREATMENT] Update Air Treatment Items [SUCCESS]',
    props<{ updatedItems: IAirTreatmentView[] }>(),
);

export const updateAirTreatmentItemsError = createAction(
    '[AIR TREATMENT] Update Air Treatment Items [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const getAirTreatmentSettings = createAction(
    '[AIR TREATMENT] Get Air Treatment Settings [...]',
    props<{ controllerID: number }>(),
);

export const getAirTreatmentSettingsSuccess = createAction(
    '[AIR TREATMENT] Get Air Treatment Settings [SUCCESS]',
    props<{ view: IAirTreatmentSettingsView; }>(),
);

export const getAirTreatmentSettingsError = createAction(
    '[AIR TREATMENT] Get Air Treatment Settings [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const setAirTreatmentSettings = createAction(
    '[AIR TREATMENT] Set Air Treatment Settings [...]',
    props<{ view: IAirTreatmentSettingsView; }>(),
);

export const updateAirTreatmentSettings = createAction(
    '[AIR TREATMENT] Update Air Treatment Settings [...]',
    props<{ controllerID: number }>(),
);

export const updateAirTreatmentSettingsSuccess = createAction(
    '[AIR TREATMENT] Update Air Treatment Settings [SUCCESS]',
);

export const updateAirTreatmentSettingsError = createAction(
    '[AIR TREATMENT] Update Air Treatment Settings [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const getAirTreatmentThresholds = createAction(
    '[AIR TREATMENT] Get Air Treatment Thresholds [...]',
    props<{ controllerID: number }>(),
);

export const getAirTreatmentThresholdsSuccess = createAction(
    '[AIR TREATMENT] Get Air Treatment Thresholds [SUCCESS]',
    props<{ view: IAirTreatmentThresholdsView; }>(),
);

export const getAirTreatmentThresholdsError = createAction(
    '[AIR TREATMENT] Get Air Treatment Thresholds [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const setAirTreatmentThresholds = createAction(
    '[AIR TREATMENT] Set Air Treatment Thresholds [...]',
    props<{ view: IAirTreatmentThresholdsView; }>(),
);

export const updateAirTreatmentThresholds = createAction(
    '[AIR TREATMENT] Update Air Treatment Thresholds [...]',
    props<{ controllerID: number }>(),
);

export const updateAirTreatmentThresholdsSuccess = createAction(
    '[AIR TREATMENT] Update Air Treatment Thresholds [SUCCESS]',
);

export const updateAirTreatmentThresholdsError = createAction(
    '[AIR TREATMENT] Update Air Treatment Thresholds [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const deleteAirTreatment = createAction(
    '[AIR TREATMENT] Delete Air Treatment [...]',
    props<{ controllerID: number; day: number }>(),
);

export const deleteAirTreatmentSuccess = createAction(
    '[AIR TREATMENT] Delete Air Treatment [SUCCESS]',
);

export const deleteAirTreatmentError = createAction(
    '[AIR TREATMENT] Delete Air Treatment [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);
