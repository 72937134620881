import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormControl,
  FormArray,
  Validators,
  ValidatorFn,
  AbstractControl,
} from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective, SwiperDirective } from '@livestock/shared/directives';
import {
  ButtonComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SelectDirective,
  SvgIconComponent,
} from '@livestock/ui';
import { Subscription } from 'rxjs';
import { TemperatureUnitEnum, ColorsEnum, DialogButtonEnum, StorageItem } from '@livestock/shared/enums';
import {
  HeatingProgramConstants,
  IHeatingProgramView,
  IHeatingProgramZoneElementView,
  IHeatingProgramZoneView,
} from '@livestock/heating-program';
import {
  PlatformService,
  DialogsService,
  LanguageService,
  SwiperService,
  LocalStorageService,
} from '@livestock/shared/services';
import { EnumPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { NgSelectModule } from '@ng-select/ng-select';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
  selector: 'ls-heating-programs-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SwiperModule,
    NgSelectModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    ButtonComponent,
    SvgIconComponent,
    EnumPipe,
    SelectDirective,
    MemoizeFuncPipe,
    SwiperDirective,
  ],
  templateUrl: './heating-programs-form.component.html',
  styleUrls: ['./heating-programs-form.component.scss'],
})
export class HeatingProgramsFormComponent implements OnDestroy {
  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Input() programZones: IHeatingProgramZoneView[] = [];

  @Input() set heatingPrograms(heatingPrograms: IHeatingProgramView[]) {
    if (heatingPrograms) {
      this.numberOfItems = heatingPrograms?.length;
      if (!this.form) {
        this.initForm(heatingPrograms);
        if (this.platformService.isMobileApp) {
          this.activeIndex = 0;
          this.updateVisibleDots();
        }
      } else {
        this.updateFormValues(heatingPrograms);
      }
    }
  }

  @Output() changed = new EventEmitter();
  @Output() deleteProgram = new EventEmitter<number>();
  @ViewChild('table') table: ElementRef;
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  sub$ = new Subscription();
  // component variables
  form: FormGroup;
  activeIndex: number;
  minTemperature: number;
  maxTemperature: number;
  numberOfItems: number;
  visibleDots: number[] = [];

  // enums
  TemperatureUnitEnum = TemperatureUnitEnum;
  HeatingProgramConstants = HeatingProgramConstants;
  GlobalConstants = GlobalConstants;
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  StorageItem = StorageItem;

  private _temperatureUnit: TemperatureUnitEnum;

  get temperatureUnit(): TemperatureUnitEnum {
    return this._temperatureUnit;
  }

  @Input() set temperatureUnit(temperatureUnit: TemperatureUnitEnum) {
    if (temperatureUnit != null) {
      this._temperatureUnit = temperatureUnit;
      const isCelsius = temperatureUnit === TemperatureUnitEnum.Celsius;
      this.maxTemperature = isCelsius
        ? HeatingProgramConstants.MaxTemperatureCelsius
        : HeatingProgramConstants.MaxTemperatureFahrenheit;
      this.minTemperature = isCelsius
        ? HeatingProgramConstants.MinTemperatureCelsius
        : HeatingProgramConstants.MinTemperatureFahrenheit;
      if (this.form) {
        this.setTemperatureValidators(this.minTemperature, this.maxTemperature);
      }
    }
  }

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    public cdr: ChangeDetectorRef,
    public swiperService: SwiperService,
    private formBuilder: FormBuilder,
    private dialogsService: DialogsService,
    private translateService: TranslateService,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ProgramMenuSlide)) || 0,
      centeredSlides: true,
    };
  }

  initForm(heatingPrograms: IHeatingProgramView[]): void {
    this.form = this.formBuilder.group({
      heatingPrograms: this.formBuilder.array(heatingPrograms.map((item) => {
        return this.getProgramFG(item, this.temperatureUnit);
      })),
    });

    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues: formValues.heatingPrograms,
          isValid: this.form.valid,
        });
      }),
    );
  }

  updateFormValues(heatingPrograms: IHeatingProgramView[]): void {
    this.form.patchValue({
      heatingPrograms,
    });
  }

  addEmptyRow(): void {
    const itemsArr = this.getFormItems();

    itemsArr.push(this.getProgramFG({
        programID: null,
        zoneID: null,
        number: itemsArr.value?.length > 0
          ? Math.max(...itemsArr.value.map(x => x.number)) + 1
          : 1,
        onTempDiff: 0,
        offTempDiff: 0,
        minOutput: 0,
        maxOutput: 0,
      },
      this.temperatureUnit));

    this.numberOfItems += 1;
    // scroll to the bottom
    setTimeout(() => {
      if (this.table) {
        this.table.nativeElement.scrollTop = this.table.nativeElement.scrollHeight;
      } else if (this.swiper) {
        this.swiper.swiperRef.slideTo(itemsArr.value?.length);
        this.updateVisibleDots();
      }
    });
  }

  async removeRow(): Promise<void> {
    if (this.activeIndex == null) {
      return;
    }

    const itemsArr = this.getFormItems();
    const currentProgram = itemsArr.at(this.activeIndex).value;
    if (currentProgram.programID != null) {
      const title = 'HeatingProgram.DeleteProgram';
      const message = this.translateService.instant('HeatingProgram.DeleteProgramMessageWithValue', {
        programNumber: currentProgram.number,
      });
      const result = await this.dialogsService.question(
        message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE], 'warning-orange', false,
      );

      if (!result || result === DialogButtonEnum.CANCEL) {
        return;
      }

      this.deleteProgram.emit(currentProgram.programID);
    }

    itemsArr.removeAt(this.activeIndex);
    this.activeIndex = itemsArr.value?.length > 0 && this.platformService.isMobileApp ? 0 : null;
    this.numberOfItems -= 1;
    this.updateVisibleDots();
  }

  setActiveIndex(index: number): void {
    if (!this.editMode) return;
    this.activeIndex = index;
  }

  setTemperatureValidators(minTemperature: number, maxTemperature: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'onTempDiff',
      'offTempDiff',
    ];

    (this.getFormItems().controls as FormGroup[])
      .forEach((element) => {
        controls.forEach(controlName => {
          element.controls[controlName].addValidators(Validators.min(minTemperature));
          element.controls[controlName].addValidators(Validators.max(maxTemperature));
          element.controls[controlName].updateValueAndValidity();
        });
      });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value.heatingPrograms,
        isValid: this.form.valid,
      });
    }
  }

  getFormItems(): FormArray<any> {
    return (this.form.controls['heatingPrograms'] as FormArray);
  }

  changeActiveSlide(ev): void {
    this.activeIndex = ev[0].realIndex;
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, this.activeIndex);
    this.cdr.detectChanges();
  }

  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }

  updateVisibleDots(): void {
    const visibleCount = 10;

    const start = Math.max(0, this.activeIndex - Math.floor(visibleCount / 2));
    const end = Math.min(this.numberOfItems - 1, start + visibleCount - 1);

    this.visibleDots = Array.from({ length: end - start + 1 }, (_, i) => i + start);
  }

  isZoneAlreadyUsed([selectedZoneID, checkedZoneID, programs]: [number, number, IHeatingProgramView[]]): boolean {
    if (selectedZoneID === checkedZoneID) {
      return false;
    }
    return programs.some((element) => element.zoneID === checkedZoneID);
  }

  getProgramZoneByZoneID([zoneID, programZones]: [number, IHeatingProgramZoneView[]]): IHeatingProgramZoneView {
    return programZones.find(x => x.zoneID === zoneID);
  }

  getHeaterElementsByZoneID([zoneID, programZones]: [number, IHeatingProgramZoneView[]]): IHeatingProgramZoneElementView[] {
    return programZones.find(x => x.zoneID === zoneID)?.heatingElements;
  }

  getSensorElementsByZoneID([zoneID, programZones]: [number, IHeatingProgramZoneView[]]): IHeatingProgramZoneElementView[] {
    return programZones.find(x => x.zoneID === zoneID)?.sensorElements;
  }

  checkOutput(index: number, property: string): void {
    const itemsArr = this.getFormItems();
    itemsArr.controls[index].get(property).updateValueAndValidity();
  }

  private getProgramFG(
    program: IHeatingProgramView,
    temperatureUnit: TemperatureUnitEnum,
  ): FormGroup {
    const allTempValidators = [Validators.required];
    if (temperatureUnit != null) {
      const minTemperature = HeatingProgramConstants.getMinTemperatureByUnit(temperatureUnit);
      const maxTemperature = HeatingProgramConstants.getMaxTemperatureByUnit(temperatureUnit);
      allTempValidators.push(...[
        Validators.min(minTemperature),
        Validators.max(maxTemperature),
      ]);
    }

    return new FormGroup(
      {
        programID: new FormControl<number>(program.programID),
        zoneID: new FormControl<number>(program.zoneID, [Validators.required]),
        number: new FormControl<number>(program.number, [Validators.required]),
        onTempDiff: new FormControl<number>(program.onTempDiff, allTempValidators),
        offTempDiff: new FormControl<number>(program.offTempDiff, allTempValidators),
        minOutput: new FormControl<number>(program.minOutput, [
          Validators.required,
          Validators.min(0),
          Validators.max(HeatingProgramConstants.OuputMax),
          this.minOutputValidator(),
        ]),
        maxOutput: new FormControl<number>(program.maxOutput, [
          Validators.required,
          Validators.min(0),
          Validators.max(HeatingProgramConstants.OuputMax),
          this.maxOutputValidator(),
        ]),
      },
    );
  }

  private maxOutputValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const minOutputControl = control?.parent?.get('minOutput');
      if (!minOutputControl) {
        return null;
      }

      const minOutput = minOutputControl.value;
      const maxOutput = control.value;

      if (maxOutput < minOutput) {
        return {
          min: {
            min: `${minOutput}`,
          },
        };
      }
      return null;
    };
  }

  private minOutputValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const maxOutputControl = control?.parent?.get('maxOutput');
      if (!maxOutputControl) {
        return null;
      }

      const maxOutput = maxOutputControl.value;
      const minOutput = control.value;

      if (minOutput > maxOutput) {
        return {
          max: {
            max: `${maxOutput}`,
          },
        };
      }

      return null;
    };
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
    this.sub$.unsubscribe();
  }
}
