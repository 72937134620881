import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';

@Component({
  selector: 'ls-static-pressure-form',
  templateUrl: './static-pressure-element-form.component.html',
  styleUrls: ['../devices-common.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class StaticPressureComponent extends BasicElementComponent {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.parentFormGroup.addControl(
      this.formGroupName,
      new FormGroup({
        name: this.name,
        enabled: new FormControl<boolean>(true),
      }),
    );
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      enabled: setupData.enabled,
    });
  }
}
