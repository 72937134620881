import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from '../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-stepper',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
  ],
  templateUrl: './stepper.component.html',
  styleUrls: ['./stepper.component.scss'],
})
export class StepperComponent {
  _steps: number[];
  @Input() set steps(steps: number) {
    if (steps) {
      this._steps = [...Array(steps).keys()];
    }
  }

  @Input() labels: string[] = [];
  @Input() currStep = 1;

  constructor(public languageService: LanguageService) {
  }
}
