export interface IStaticPressure {
  elementID: number;
  bandVentilation: number;
  outsideTempColdVentilation: number;
  outsideTempWarmVentilation: number;
  targetPressureColdVentilation: number;
  targetPressureWarmVentilation: number;
  pressureAlarmLowVentilation: number;
  pressureAlarmHightVentilation: number;
  bandTunnel: number;
  targetPressureTunnel: number;
  pressureAlarmLowTunnel: number;
  pressureAlarmHightTunnel: number;
}
