export class ElementsValidationConstants {
  static co2 = {
    offset: {
      min: -500,
      max: 500,
    },
  };

  static heatingDO = {
    kwh: {
      min: 0,
      max: 999.,
    },
  };

  static heatingAO = {
    minVoltage: {
      min: 0,
      max: 10,
    },
    maxVoltage: {
      min: 0,
      max: 10,
    },
    kwh: {
      min: 0,
      max: 999.,
    },
  };

  // depends on temp unit, implement in future for backend
  static indoorTemp = {
    offset: {
      min: -10,
      max: 10,
    },
  };

  // depends on temp unit, implement in future for backend
  static outdoorTemp = {
    offset: {
      min: -10,
      max: 10,
    },
  };

  static indoorHumidity = {
    offset: {
      min: 0,
      max: 100,
    },
  };

  static outdoorHumidity = {
    offset: {
      min: 0,
      max: 100,
    },
  };

  static ventilationAO = {
    fansNumber: {
      min: 1,
      max: 40,
    },
    boostTime: {
      min: 1,
      max: 40,
    },
    minVoltage: {
      min: 1,
      max: 99,
    },
    maxVoltage: {
      min: 1,
      max: 99,
    },
    airFlow: {
      min: 0,
      max: 999,
    },
    minEfficiency: {
      min: 0,
      max: 999,
    },
    maxEfficiency: {
      min: 0,
      max: 999,
    },
  };

  static ventilationDO = {
    fansNumber: {
      min: 1,
      max: 40,
    },
    airFlow: {
      min: 0,
      max: 999,
    },
    efficiency: {
      min: 0,
      max: 999,
    },
  };

  static lightingAO = {
    minVoltage: {
      min: 0,
      max: 10,
      default: 0,
    },
    maxVoltage: {
      min: 0,
      max: 10,
      default: 10,
    },
  };

  //settings
  static heatingSettings = {
    maxCycleTime: {
      min: 0,
      max: 600,
    },
    minCycleTime: {
      min: 0,
      max: 600,
    },
    analogHeaters: {
      min: 0,
      max: 100,
    },
  };

  static coolingSettings = {
    minDay: {
      min: 1,
      max: 999,
    },
    humLevelToRestart: {
      min: 0,
      max: 100,
    },
    timeToWetPad: {
      min: 0,
      max: 999,
    },
  };

  static sprinklersFoggersSettings = {
    minDay: {
      min: 1,
      max: 999,
    },
    humLevelToRestart: {
      min: 0,
      max: 100,
    },
    timeToWetPad: {
      min: 0,
      max: 999,
    },
  };

  static lightingSettings = {
    boost: {
      duration: {
        min: 1,
        max: 3600,
        default: 10,
      },
      intensity: {
        min: 1,
        max: 100,
        default: 70,
      },
    },
    dimming: {
      min: 1,
      max: 60,
    },
    spike: {
      cycle: {
        min: 1,
        max: 60,
      },
      duration: {
        min: 1,
        max: 60,
      },
      increase: {
        min: 1,
        max: 100,
      },
    },
    sunriseSunset: {
      duration: {
        min: 1,
        max: 60,
      },
      minToDimm: {
        min: 1,
        max: 100,
      },
    },
  };

  static staticPressureSettings = {
    windDelay: {
      min: 10,
      max: 999,
    },
    pressureAlarm: {
      min: 0,
      max: 100,
      maxAdvanced: 40,
    },
  };
}
