import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LOGIN_SCANNED_FEATURE_KEY, LoginScannedState } from './login-scanned.reducer';

export const selectLoginScannedState =
  createFeatureSelector<LoginScannedState>(LOGIN_SCANNED_FEATURE_KEY);

export const selectTicketId = createSelector(selectLoginScannedState,
  (state) => state?.ticketId);

export const selectControllerInfo = createSelector(selectLoginScannedState,
  (state) => state?.ticketView);

export const isScannedLoginInProgress = createSelector(selectLoginScannedState,
  (state) => !!state?.ticketId);

export const isTicketReconnect = createSelector(selectLoginScannedState,
  (state) => state?.isReconnect);
