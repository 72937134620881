import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, inject } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { TranslateService } from '@ngx-translate/core';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-co2-sensor-form',
  templateUrl: './co2-sensor-form.component.html',
  styleUrls: ['../devices-common.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class Co2SensorComponent extends BasicElementComponent {
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.parentFormGroup.addControl(
      this.formGroupName,
      new FormGroup({
        name: this.name,
        offset: new FormControl<number>(0, [
          Validators.required,
          Validators.min(ElementsValidationConstants.co2.offset.min),
          Validators.max(ElementsValidationConstants.co2.offset.max),
        ]),
        enabled: new FormControl<boolean>(true),
      }),
    );
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      offset: setupData.offset,
      enabled: setupData.enabled,
    });
  }
}
