<div class="popup">
  <div class="d-flex align-center justify-between mb-35">
    <h6 class="color-primary fz-20">{{ "TemperatureMapping.StageManagement" | translate }}</h6>
    <ls-svg-icon
      (click)="close()"
      [hasMargin]="true"
      [qaTags]="'qa-btn-copy-cancel'"
      class="pointer"
      path="common/cross"></ls-svg-icon>
  </div>

  <div class="popup-body">
    <ul class="fz-20 color-secondary">
      <li
        (click)="addBrooding2Stage()"
        [class.color-disabled]="brooding2IsShown"
        class="mb-30">
        {{ "TemperatureMapping.AddBrooding2Stage" | translate }}
      </li>
      <li
        (click)="removeBrooding2Stage()"
        [class.color-disabled]="!brooding2IsShown">
        {{ "TemperatureMapping.DeleteBrooding2Stage" | translate }}
      </li>
    </ul>
  </div>
</div>
