<div
  *ngIf="parentFormGroup.get(formGroupName) as form"
  [formGroupName]="formGroupName"
  class="device-form">
  <ng-container *ngIf="!platformService.isMobileApp || !(installationHelpersService.isTableView$ | async)">
    <div class="device-form__field mb-20">
      <label qaTags="qa-label-name">{{ "Installation.Card.Cooling.DeviceName" | translate }}</label>

      <div class="d-flex align-center form-field">
        <ls-input-text
          [fieldWithKeyboard]="true"
          [maxLength]="ElementConstants.MaxNameLength"
          [qaTags]="'qa-txt-unit-name'"
          errorField
          formControlName="name"></ls-input-text>
        <!-- copy btn -->
        <ng-content></ng-content>
      </div>
    </div>

    <div class="device-form__field mb-10">
      <label>{{ "Installation.Card.Ventilation.ModelName" | translate }}</label>

      <div
        (click)="openVentilationBrandsPopup()"
        [ngClass]="form.value.isCustomModel ? 'pointer-events-none' : 'pointer'"
        class="d-flex align-center form-field">
        <div
          [class.model-name__disabled]="form.value.isCustomModel"
          class="model-name">
          <ng-container *ngIf="form.value.isCustomModel">
            {{ "Installation.Card.Ventilation.CustomModel" | translate }}
          </ng-container>

          <ng-container *ngIf="!form.value.isCustomModel && !form.value.modelID">
            <span class="color-danger opacity-50">{{
              "Installation.Card.Ventilation.Brand.SelectModel" | translate
            }}</span>
          </ng-container>

          <ng-container *ngIf="!form.value.isCustomModel && form.value.modelID">
            {{ (model$ | async)(form.value.modelID, form.value.brandID)?.name }}
          </ng-container>
        </div>

        <ls-svg-icon
          [color]="form.value.isCustomModel ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
          [hasMargin]="true"
          [path]="'common/plus'"
          class="ml-10"></ls-svg-icon>
      </div>
    </div>

    <div class="device-form__field d-flex align-center mb-20 is-custom-model">
      <mat-checkbox
        (change)="validateVentBrandFields($event.checked, true)"
        class="checkbox checkbox-active-color"
        formControlName="isCustomModel"></mat-checkbox>

      <h6 class="color-tertiary">{{ "Installation.Card.Ventilation.CustomModel" | translate }}</h6>
    </div>

    <div class="device-form__field mb-20">
      <label>{{ "Installation.Card.Ventilation.MultipleFansAreWiredToThisPort" | translate }}</label>
      <ls-toggle
        (change)="validateNumberOfFans($event)"
        [offTitle]="'Buttons.No'"
        [onTitle]="'Buttons.Yes'"
        [qaTags]="'qa-tog-enabled'"
        [size]="'large'"
        formControlName="isMultipleFans" />
    </div>

    <div
      *ngIf="form.value.isMultipleFans"
      class="device-form__field mb-20">
      <label>{{ "Installation.Card.Ventilation.NoOfFans" | translate }}</label>
      <ls-input-integer
        [fieldWithKeyboard]="true"
        [max]="ElementsValidationConstants.ventilationAO.fansNumber.max"
        [min]="ElementsValidationConstants.ventilationAO.fansNumber.min"
        [validateWithInitValue]="true"
        errorField
        formControlName="fansNumber">
      </ls-input-integer>
    </div>

    <div class="device-form__field mb-20">
      <label>{{ "Installation.Card.Cooling.Operation" | translate }}</label>
      <ng-select
        [clearable]="false"
        [searchable]="false"
        selectTitle="{{ 'Installation.Card.Cooling.Operation' | translate }}"
        formControlName="operation">
        <ng-option
          *ngFor="let el of ElementOperationEnum | enumToArray"
          [qaTags]="'qa-ddl-operation-' + el"
          [value]="el">
          <span>{{ el | enum : "element-operation" | translate }}</span>
        </ng-option>
      </ng-select>
    </div>
  </ng-container>

  <ng-container *ngIf="!platformService.isMobileApp || (installationHelpersService.isTableView$ | async)">
    <div
      [class.mb-40]="!platformService.isMobileApp"
      class="table-wrapper table-wrapper__do">
      <div class="table w-1-8 w-2-46 w-3-46 padding-top-5 color-tertiary">
        <div class="table-row border-bottom-none">
          <div class="table-cell blue"></div>
          <div class="table-cell blue">
            {{ "Installation.Card.Ventilation.ForOne" | translate }}
          </div>
          <div class="table-cell blue border-left-bold">
            {{ "Installation.Card.Ventilation.Total" | translate }}
          </div>
        </div>
      </div>

      <div class="table w-1-8 w-2-23 w-3-23 w-4-23 w-5-23 padding-top-5 color-tertiary">
        <div class="table-row">
          <div class="table-cell blue"></div>
          <div class="table-cell blue">
            {{ "Installation.Card.Ventilation.Airflow" | translate }}
            <span class="fz-12 ml-3">({{ "Installation.Card.Ventilation.Cfm" | translate }})</span>
          </div>
          <div class="table-cell blue">
            {{ "Installation.Card.Ventilation.Efficiency" | translate }}
            <span class="fz-12 ml-3">({{ "Installation.Card.Ventilation.CfmWatt" | translate }})</span>
          </div>
          <div class="table-cell blue border-left-bold">
            {{ "Installation.Card.Ventilation.Airflow" | translate }}
            <span class="fz-12 ml-3">({{ "Installation.Card.Ventilation.Cfm" | translate }})</span>
          </div>
          <div class="table-cell blue">
            {{ "Installation.Card.Ventilation.Efficiency" | translate }}
            <span class="fz-12 ml-3">({{ "Installation.Card.Ventilation.CfmWatt" | translate }})</span>
          </div>
        </div>

        <div
          *ngFor="let airFlowStep of airFlowSteps"
          class="table-row">
          <div class="table-cell blue">
            {{ airFlowStep === 5 ? "0.05" : "0." + airFlowStep }}
          </div>
          <ng-container
            [ngTemplateOutletContext]="{
              airFlow: 'airFlow' + airFlowStep,
              efficiency: 'efficiency' + airFlowStep,
              model: (model$ | async)(form.value.modelID, form.value.brandID),
              form: form
            }"
            [ngTemplateOutlet]="form.value.isCustomModel ? customAirFlow : presetAirFlow"></ng-container>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<ng-template
  #presetAirFlow
  let-airFlow="airFlow"
  let-efficiency="efficiency"
  let-form="form"
  let-model="model">
  <div class="table-cell">{{ model?.[airFlow] }}</div>
  <div class="table-cell">{{ model?.[efficiency].toFixed(1) }}</div>
  <ng-container
    [ngTemplateOutletContext]="{ airFlow: airFlow, efficiency: efficiency, form: form, model: model }"
    [ngTemplateOutlet]="totalAirFlow"></ng-container>
</ng-template>

<ng-template
  #customAirFlow
  let-airFlow="airFlow"
  let-efficiency="efficiency"
  let-form="form">
  <ng-container [formGroup]="form.get('values')">
    <div class="table-cell table-cell__full-input">
      <!--min/max are random for now, waiting for validation-->
      <ls-input-integer
        [fieldWithKeyboard]="true"
        [formControlName]="airFlow"
        [max]="ElementsValidationConstants.ventilationDO.airFlow.max"
        [min]="ElementsValidationConstants.ventilationDO.airFlow.min"
        [showTooltipOnHover]="true"
        errorField></ls-input-integer>
    </div>
    <div class="table-cell table-cell__full-input">
      <!--min/max are random for now, waiting for validation-->
      <ls-input-decimal
        [accuracy]="1"
        [fieldWithKeyboard]="true"
        [formControlName]="efficiency"
        [max]="ElementsValidationConstants.ventilationDO.efficiency.max"
        [min]="ElementsValidationConstants.ventilationDO.efficiency.min"
        [showTooltipOnHover]="true"
        errorField></ls-input-decimal>
    </div>
  </ng-container>

  <ng-container
    [ngTemplateOutletContext]="{ airFlow: airFlow, efficiency: efficiency, form: form }"
    [ngTemplateOutlet]="totalAirFlow"></ng-container>
</ng-template>

<ng-template
  #totalAirFlow
  let-airFlow="airFlow"
  let-efficiency="efficiency"
  let-form="form"
  let-model="model">
  <div class="table-cell gray border-left-bold">
    {{ (model ? model[airFlow] : form.value.values?.[airFlow]) * form.value.fansNumber }}
  </div>

  <div class="table-cell gray">
    {{ ((model ? model[efficiency] : form.value.values?.[efficiency]) * form.value.fansNumber).toFixed(1) }}
  </div>
</ng-template>
