<div class="widget-wrapper d-flex">
  <div class="widget-top d-flex flex-column mr-40">
    <h6
      [qaTags]="'qa-lbl-widget-title'"
      class="widget-title">
      Inlet
    </h6>

    <div class="widget-icon pt-10-desktop pt-5-lg d-flex flex-column">
      <img
        [qaTags]="'qa-img-widget qa-img-inlet-windows'"
        alt="inlet"
        class="mb-15 mb-12-desktop mb-4-lg"
        src="/assets/icons/dashboard/inlet-right.svg" />

      <img
        [qaTags]="'qa-img-widget qa-img-inlet-tunnel'"
        alt="inlet"
        class="mb-15 mb-12-desktop mb-4-lg"
        src="/assets/icons/dashboard/inlet-bottom.svg" />

      <img
        [qaTags]="'qa-img-widget qa-img-inlet-atic'"
        alt="inlet"
        src="/assets/icons/dashboard/inlet-top.svg" />
    </div>
  </div>

  <div class="widget-body w-100-percent">
    <div class="widget-columns d-flex mb-7">
      <div
        *ngFor="let column of columns"
        [qaTags]="'qa-lbl-inlet-zone-num qa-lbl-inlet-window qa-lbl-inlet-tunnel qa-lbl-inlet-atic'"
        class="widget-column color-secondary">
        {{ column }}
      </div>
    </div>

    <div class="widget-content">
      <div class="widget-content__blocks fz-22-lg">
        <div class="widget-content__block widget-content__block-top mb-17 mb-10-lg">
          <div class="widget-content__value">11<span>%</span></div>
          <div class="widget-content__value">06<span>%</span></div>
          <div class="widget-content__value">06<span>%</span></div>
        </div>

        <div class="widget-content__block mb-17 mb-10-lg">
          <div class="widget-content__value">0<span>%</span></div>
          <div class="widget-content__value">09<span>%</span></div>
          <div class="widget-content__value">09<span>%</span></div>
        </div>

        <div class="widget-content__block">
          <div class="widget-content__value">0<span>%</span></div>
          <div class="widget-content__value">0<span>%</span></div>
          <div class="widget-content__value">0<span>%</span></div>
        </div>
      </div>
    </div>
  </div>
</div>
