<div
  [class.rtl]="languageService.isRtl"
  class="popup d-flex flex-column justify-between">
  <div class="popup-top">
    <div class="d-flex align-center justify-between mb-30">
      <h3
        [qaTags]="'qa-lbl-popup-title'"
        class="popup-title fz-20 color-primary">
        {{ "Controller.ConnectToCellularPopup.ConnectToCellularNetwork" | translate }}
      </h3>

      <img
        (click)="close()"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="close-icon pointer"
        src="/assets/icons/common/cross.svg" />
    </div>

    <ng-container *ngIf="form">
      <div
        [formGroup]="form"
        class="popup-body mb-30">
        <div class="mb-30 d-flex flex-column w-100-percent">
          <label class="mb-5 fz-16 color-tertiary">
            {{ "Controller.ConnectToCellularPopup.ModemAPN" | translate }}
          </label>
          <ls-input-text
            [fieldWithKeyboard]="true"
            formControlName="modemAPN"
            class="w-100-percent d-block"></ls-input-text>
        </div>

        <div class="mb-30 d-flex flex-column w-100-percent">
          <label class="mb-5 fz-16 color-tertiary">
            {{ "Controller.ConnectToCellularPopup.ModemUser" | translate }}
          </label>
          <ls-input-text
            [fieldWithKeyboard]="true"
            formControlName="modemUser"
            class="w-100-percent d-block"></ls-input-text>
        </div>

        <div class="d-flex flex-column w-100-percent">
          <label class="mb-5 fz-16 color-tertiary">
            {{ "Controller.ConnectToCellularPopup.ModemPassword" | translate }}
          </label>
          <ls-input-text
            [fieldWithKeyboard]="true"
            formControlName="modemPassword"
            class="w-100-percent d-block"></ls-input-text>
        </div>
      </div>
    </ng-container>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-end buttons">
      <ls-button
        (click)="close()"
        [qaTags]="'qa-btn-cancel'"
        class="button"
        type="secondary">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>

      <ls-button
        (click)="connect()"
        [qaTags]="'qa-btn-connect'"
        [class.disabled]="!form.valid"
        class="button ml-10"
        type="primary">
        {{ "Buttons.Connect" | translate }}
      </ls-button>
    </div>
  </div>

  <div
    *ngIf="isLoading"
    class="loading d-flex align-center justify-center">
    <ls-loading-lines />
  </div>
</div>

<ls-keyboard
  *ngIf="!platformService.isMobileApp"
  class="keyboard" />
