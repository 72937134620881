<!--important for translating placeholder; value == null not working here-->
<input
  (blur)="onBlur()"
  (focusout)="onFocusOutEvent()"
  #input
  [disabled]="forceDisabled || isDisabled"
  [enterKeyHint]="enterKeyHint"
  [ngClass]="{
    'no-border': noBorder,
    'no-paddings': noPaddings,
    'no-max-width': noMaxWidth,
    rtl: languageService.isRtl,
    'blue-border': blueBorder
  }"
  [placeholder]="placeholder"
  [readonly]="readonly"
  [style.color]="textColor"
  type="text" />

<span
  #content
  [ngClass]="{ icon__rtl: isRtl, icon: isContent }"
  [style.left.px]="languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
  [style.right.px]="!languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
  class="position-absolute suffix w-mc pointer-events-none">
  <ng-content select="[suffix]"></ng-content>
</span>

<!--for value length calculation-->
<span
  *ngIf="isContent && viewInitiated"
  #valueLengthSpan
  class="position-absolute value-raw">
  {{ inputRef.nativeElement.value || placeholder }}
</span>
