import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { InputIntegerComponent, SvgIconComponent } from '@livestock/ui';
import { IAirTreatmentThresholdsView, AirTreatmentConstants } from '@livestock/air-treatment';
import { Subscription } from 'rxjs';
import { GlobalConstants } from '@livestock/shared/constants';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-air-treatment-thresholds-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    InputIntegerComponent,
    SvgIconComponent,
  ],
  templateUrl: './air-treatment-thresholds-form.component.html',
  styleUrls: ['./air-treatment-thresholds-form.component.scss'],
})
export class AirTreatmentThresholdsFormComponent implements OnDestroy {
  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Output() changed = new EventEmitter();
  @Input() set airTreatmentThresholds(airTreatmentThresholds: IAirTreatmentThresholdsView) {
    if (airTreatmentThresholds) {
      this.updateFormValues(airTreatmentThresholds);
    }
  }
  // subs
  sub$ = new Subscription();
  // vars
  form = new FormGroup(
    {
      controllerID: new FormControl(),
      highAlarmThresholdHum: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPercentage),
        Validators.max(AirTreatmentConstants.MaxPercentage),
      ]),
      highAlarmThresholdCO2: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPPM),
        Validators.max(AirTreatmentConstants.MaxPPM),
      ]),
      highAlarmThresholdAmmonia: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPercentage),
        Validators.max(AirTreatmentConstants.MaxPercentage),
      ]),
      shutoffHum: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPercentage),
        Validators.max(AirTreatmentConstants.MaxPercentage),
      ]),
      shutoffCO2: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPPM),
        Validators.max(AirTreatmentConstants.MaxPPM),
      ]),
      shutoffAmmonia: new FormControl(0, [
        Validators.required,
        Validators.min(AirTreatmentConstants.MinPercentage),
        Validators.max(AirTreatmentConstants.MaxPercentage),
      ]),
    },
  );
  // enums
  AirTreatmentConstants = AirTreatmentConstants;
  GlobalConstants = GlobalConstants;

  constructor(
    public platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues,
          isValid: this.form.valid,
        });
      }),
    );
  }

  updateFormValues(airTreatmentThresholds: IAirTreatmentThresholdsView): void {
    this.form.patchValue(airTreatmentThresholds);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
