import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DialogsService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import { resetToOriginalMappings } from '../+state/lighting-mapping.actions';
import { selectIsDirtyForm } from '../+state/lighting-mapping.selectors';

@Injectable({
  providedIn: 'root',
})
export class LightingMappingHelpersService {
  constructor(
    private dialogsService: DialogsService,
    private store: Store,
  ) {
  }

  async confirmUnsavedChanges(): Promise<boolean> {
    const isDirtyForm = await firstValueFrom(this.store.select(selectIsDirtyForm));

    if (isDirtyForm) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }

      this.store.dispatch(resetToOriginalMappings());
    }

    return true;
  }
}
