export class ElementConstants {
  static MaxNameLength = 30;

  static MinDay = 999;
  static HumLevelToRestart = 100;
  static TimeToWetPad = 999;

  static MaxKwh = 999;

  static TEMPERATURE_UNIT_MAX_C: number = 10;
  static TEMPERATURE_UNIT_MIN_C: number = -10;
  static TEMPERATURE_UNIT_MAX_F: number = 50;
  static TEMPERATURE_UNIT_MIN_F: number = 14;
}
