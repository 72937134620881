<div
  [class.rtl]="languageService.isRtl"
  class="d-flex flex-column justify-between h-100-percent pb-15">
  <div class="position-relative">
    <div class="swiper-navigation swiper-arrows">
      <div
        (click)="goToPrevSlide()"
        [class.disabled]="activeZoneIndex === 0"
        class="swiper-navigation-prev"></div>
      <div
        (click)="goToNextSlide()"
        [class.disabled]="activeZoneIndex === zones?.length - 1"
        class="swiper-navigation-next"></div>
    </div>

    <swiper
      *ngIf="swiperService.swiperIsActive"
      (activeIndexChange)="changeActiveZone($event)"
      #swiper
      [cdr]="cdr"
      [config]="swiperService.config"
      [dir]="languageService.direction"
      [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
      class="swiper overflow-y-auto pb-15"
      swiperDirective>
      <ng-template
        *ngFor="let zone of zones; index as index; trackBy: trackBy"
        swiperSlide>
        <div class="mb-10 zone-title">
          <h6
            *ngIf="!editMode; else nameInput"
            class="fw-500 fz-18 color-secondary">
            {{ zone.name + " " }}{{ index + 1 }}/{{ zones.length }}
          </h6>

          <ng-template #nameInput>
            <input
              (click)="disableSensorMode()"
              (input)="changeZoneNameEmit($event)"
              [class.invalid]="zone.name === ''"
              [placeholder]="'HeatingProgramZone.EnterName' | translate"
              value="{{ zone.name }}"
              class="name-input"
              maxlength="20"
              type="text" />
          </ng-template>
        </div>

        <div class="table">
          <div class="table-row">
            <div class="table-cell">
              <h6>{{ "HeatingProgramZone.Heaters" | translate }}</h6>
            </div>

            <div class="table-cell d-flex flex-wrap table-cell__elements">
              <div
                *ngFor="let heatingElement of zone.heatingElements"
                (click)="addOrRemoveHeaterToMovingArray($event, heatingElement.elementID, zone.zoneID)"
                [class.heater-active]="(selectedHeatersIDs$ | async).includes(heatingElement.elementID)"
                class="heater mr-3 mb-3">
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [fullHeight]="true"
                  [path]="
                    heatingElement.elementType === ElementTypesEnum.HeatingDO ? 'heating/heat-do' : 'heating/heat'
                  ">
                </ls-svg-icon>
                <span class="fz-12 color-tertiary">{{ heatingElement.number }}</span>
              </div>
            </div>
          </div>

          <div class="table-row">
            <div class="table-cell">
              <h6>{{ "HeatingProgramZone.Average" | translate }}</h6>
            </div>

            <div class="table-cell">
              <mat-checkbox
                (change)="changeIsAverage($event)"
                [checked]="zone.isAverage"
                [disabled]="!editMode"
                class="checkbox checkbox-active-color">
              </mat-checkbox>
            </div>
          </div>

          <div class="table-row">
            <div class="table-cell">
              <h6>{{ "HeatingProgramZone.Sensors" | translate }}</h6>
            </div>

            <div
              (click)="enableSensorMode()"
              class="table-cell d-flex table-cell__elements table-cell__sensors">
              <ng-container *ngIf="zone.isAverage; else sensors">
                <div class="sensor mb-4 sensor-all">
                  <ls-svg-icon
                    [color]="ColorsEnum.BlueDefault"
                    [fullHeight]="true"
                    [path]="'heating/temp'"></ls-svg-icon>
                  <span class="fz-12 color-tertiary">{{ "HeatingProgramZone.All" | translate }}</span>
                </div>
              </ng-container>

              <ng-template #sensors>
                <div
                  *ngFor="let sensorElement of zone.sensorElements"
                  class="sensor mr-4 mb-4">
                  <ls-svg-icon
                    [color]="ColorsEnum.BlueDefault"
                    [fullHeight]="true"
                    [path]="'heating/temp'"></ls-svg-icon>
                  <span class="fz-12 color-tertiary">{{ sensorElement.number }}</span>
                </div>
              </ng-template>
            </div>
          </div>
        </div>
      </ng-template>
    </swiper>
  </div>

  <div
    *ngIf="editMode"
    class="buttons h-100-percent d-flex flex-column justify-between">
    <div class="d-flex">
      <ls-svg-icon
        (click)="addZone()"
        [class.color-disabled]="zones.length >= MAX_ZONES"
        [class.disabled]="zones.length >= MAX_ZONES"
        [path]="'mobile/add'"
        class="mr-15 color-green">
      </ls-svg-icon>

      <ls-svg-icon
        (click)="removeZoneEmit()"
        [class.color-disabled]="zones[activeZoneIndex]?.heatingElements?.length > 0"
        [class.disabled]="zones[activeZoneIndex]?.heatingElements?.length > 0"
        [path]="'mobile/delete'"
        class="mr-15 color-green">
      </ls-svg-icon>

      <ls-button
        (click)="openMoveHeatersPopup()"
        [class.disabled]="!(activeZoneID$ | async) || (selectedHeatersIDs$ | async).length === 0"
        class="h-button-36"
        type="secondary">
        {{ "HeatingProgramZone.Move" | translate }}
      </ls-button>
    </div>

    <div class="d-flex align-center justify-center">
      <ls-button
        (click)="toggleEditModeEmit()"
        [qaTags]="'qa-btn-cancel'"
        class="mr-40 w-120 h-button-36 w-button-130"
        type="secondary">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>

      <ls-button
        (click)="emitSave()"
        [class.disabled]="someZonesAreEmpty || noChangesMade"
        [qaTags]="'qa-btn-save'"
        class="w-120 h-button-36 w-button-130"
        type="primary">
        {{ "Buttons.Save" | translate }}
      </ls-button>
    </div>
  </div>
</div>

<ls-sensors-keyboard
  *ngIf="sensorMode"
  (saveSensors)="saveSensors($event)"
  (unsetSensorMode)="unsetSensorMode()"
  [activeZone]="zones[activeZoneIndex]"
  [isDisabled]="zones[activeZoneIndex]?.isAverage"
  [sensorElements]="sensorElements"></ls-sensors-keyboard>
