import { AfterViewInit, Component, ElementRef, ViewChild } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { NgxCaptureService } from 'ngx-capture';
import { GlobalConstants } from '@livestock/shared/constants';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { google } from 'google-maps';
import { Capacitor } from '@capacitor/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    QaTagsDirective,
  ],
  selector: 'ls-google-coordinates',
  templateUrl: './google-coordinates.component.html',
  styleUrls: ['./google-coordinates.component.scss'],
})
export class GoogleCoordinatesComponent implements AfterViewInit {
  @ViewChild('map') mapElement: ElementRef;
  latitude: number;
  longitude: number;
  map: google.maps.Map;
  geocoder: google.maps.Geocoder;
  mapProperties;
  address: string;
  markers: google.maps.Marker[] = [];
  platform: string;

  constructor(
    public dialogRef: MatDialogRef<GoogleCoordinatesComponent>,
    private ngxCaptureService: NgxCaptureService,
  ) {
    this.platform = Capacitor.getPlatform();
  }

  setup(latitude: number, longitude: number): void {
    this.geocoder = new google.maps.Geocoder();
    this.latitude = latitude || GlobalConstants.DefaultLatitude;
    this.longitude = longitude || GlobalConstants.DefaultLongitude;
    this.getFormattedAddress();

    this.mapProperties = {
      center: new google.maps.LatLng(this.latitude, this.longitude),
      zoom: 10,
      mapTypeId: google.maps.MapTypeId.SATELLITE,
    };
  }

  ngAfterViewInit(): void {
    this.map = new google.maps.Map(this.mapElement.nativeElement, this.mapProperties);
    this.initAutocomplete();
    const latLng = new google.maps.LatLng(this.latitude, this.longitude);
    this.getFormattedAddress();

    this.markers.push(
      this.addMarker(latLng, 'Farm', this.map),
    );
  }

  clearMarkers(): void {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = [];
  }

  setNewMarker(): void {
    const latLng = new google.maps.LatLng(this.latitude, this.longitude);
    this.clearMarkers();
    this.markers.push(
      this.addMarker(latLng, 'Farm', this.map),
    );
  }

  initAutocomplete(): void {
    const input = document.getElementById('pac-input') as HTMLInputElement;
    const buttons = document.getElementById('buttons') as HTMLInputElement;
    const searchBox = new google.maps.places.SearchBox(input);
    this.map.controls[google.maps.ControlPosition.TOP_LEFT].push(input);
    this.map.controls[google.maps.ControlPosition.BOTTOM_RIGHT].push(buttons);

    this.map.addListener('bounds_changed', () => {
      this.setCenterLatLng(this.map.getBounds() as any);
      setTimeout(() => {
        this.setNewMarker();
        this.getFormattedAddress();
      });
    });

    searchBox.addListener('places_changed', () => {
      const places = searchBox.getPlaces();

      if (places.length == 0) {
        return;
      }

      this.clearMarkers();
      const bounds = new google.maps.LatLngBounds();
      places.forEach((place) => {
        if (!place.geometry) {
          console.log('Returned place contains no geometry');
          return;
        }

        this.markers.push(
          this.addMarker(place.geometry.location, place.name, this.map),
        );

        if (place.geometry.viewport) {
          // Only geocodes have viewport.
          bounds.union(place.geometry.viewport);
        } else {
          bounds.extend(place.geometry.location);
        }
      });
      this.map.fitBounds(bounds);
      this.getFormattedAddress();
    });
  }

  addMarker(latlng, title, map): google.maps.Marker {
    const marker = new google.maps.Marker({
      position: latlng,
      map: map,
      title: title,
      draggable: true,
    });

    this.latitude = latlng.lat();
    this.longitude = latlng.lng();

    marker.addListener('drag', this.dragEvent.bind(this));
    marker.addListener('dragend', this.dragEndEvent.bind(this));
    return marker;
  }

  dragEvent(event): void {
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
  }

  dragEndEvent(event): void {
    this.latitude = event.latLng.lat();
    this.longitude = event.latLng.lng();
    setTimeout(() => {
      this.getFormattedAddress();
    }, 100);
  }

  setValuesAndClose(): void {
    this.getFormattedAddress();

    this.ngxCaptureService.getImage(this.mapElement.nativeElement, false, {
      width: window.innerWidth <= 575 ? 300 : 370,
      height: window.innerWidth <= 575 ? 300 : 370,
      x: window.innerWidth <= 575 ? 0 : 200,
      y: window.innerWidth <= 575 ? 100 : 50,
    }).subscribe((screenshotData) => {
      this.dialogRef.close({
        latitude: this.latitude,
        longitude: this.longitude,
        address: this.address,
        screenshotData,
      });
    });
  }

  getFormattedAddress(): void {
    const myLatlng = new google.maps.LatLng(this.latitude, this.longitude);
    this.geocoder.geocode({ location: myLatlng },
      (results) => this.address = results?.[0].formatted_address);
  }

  setCenterLatLng(bounds: any): void {
    const [latitudeKey, longitudeKey] = Object.keys(bounds);

    this.latitude = (bounds[latitudeKey].lo + bounds[latitudeKey].hi) / 2;
    this.longitude = (bounds[longitudeKey].lo + bounds[longitudeKey].hi) / 2;
  }
}
