import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  HeaderTitleComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { IDateTimeSettingsView } from '../../interfaces/date-time-settings-view.interface';
import { Observable, filter, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import {
  selectIsLoading,
  selectDateTimeSettings,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import {
  getDateTimeSettings,
  setDateTimeSettings,
  updateDateTimeSettings,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuService, PlatformService } from '@livestock/shared/services';
import {
  ControllerDateTimeSettingsFormComponent,
} from '../../ui/date-time-settings-form/date-time-settings-form.component';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-controller-date-time-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerDateTimeSettingsFormComponent,
    ButtonComponent,
    SvgIconComponent,
    HeaderTitleComponent,
    QaTagsDirective,
  ],
  templateUrl: './date-time-settings.component.html',
  styleUrls: [
    '../general-settings/general-settings.component.scss',
    './date-time-settings.component.scss',
  ],
})
export class ControllerDateTimeSettingsComponent implements OnDestroy {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  dateTimeSettings: IDateTimeSettingsView;
  originalDateTimeSettings: IDateTimeSettingsView;
  isValid = true;
  isDirtyForm = false;
  isEditMode: boolean = false;

  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
  ) {
    this.sub$.add(
      this.store.select(selectDateTimeSettings)
        .pipe(
          wasChanged(),
          filter(settings => settings != null && !!settings.controllerID),
        ).subscribe((dateTimeSettings) => {
        //set original settings only when autoDateTimeType is ready
        if (!this.originalDateTimeSettings && dateTimeSettings.autoDateTimeType != null) {
          this.originalDateTimeSettings = dateTimeSettings;
        }
        this.dateTimeSettings = dateTimeSettings;
      }),
    );

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        const controllerID = +params['controllerID'];
        this.store.dispatch(getDateTimeSettings({ controllerID }));
      }),
    );
  }

  changedDateTimeSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    this.isDirtyForm = JSON.stringify(this.originalDateTimeSettings) !== JSON.stringify(event.formValues);
    if (event.isValid) {
      this.store.dispatch(setDateTimeSettings({ dateTimeSettings: event.formValues }));
    }
  }

  cancel(): void {
    //set original settings in store for cancelling
    this.store.dispatch(setDateTimeSettings({ dateTimeSettings: this.originalDateTimeSettings }));
    this.menuService.toggleDashboardMenu(true);
  }

  update(): void {
    this.originalDateTimeSettings = this.dateTimeSettings;
    this.store.dispatch(updateDateTimeSettings());
    this.menuService.toggleDashboardMenu(true);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}

