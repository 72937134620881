import { CalibrationModeEnum } from '../enums/calibration-mode.enum';

export interface IBasicVentilationSettings {
  controllerID: number,
  isWorkingWithCurve: boolean,
  isAllowingRotation: boolean,
  inRotationFansNumber: number,
  movingToTunnelsFansNumber: number,
  minTimeInTunnel: number,
  calibration: CalibrationModeEnum,
  start: number,
  end: number,
  stepsNumber: number,
}
