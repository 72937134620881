<div
  [class.rtl]="languageService.isRtl"
  class="popup d-flex flex-column justify-between">
  <div class="popup-top mb-40">
    <h3
      [qaTags]="'qa-lbl-popup-title'"
      class="popup-title fz-20 fz-16-md mb-30 d-flex align-center color-secondary fw-500">
      <span>{{ "Farms.AddHouseNumberDialog.AddHouseNumber" | translate }}</span>
    </h3>

    <div class="popup-body">
      <div class="warning-icon mb-20 mx-auto">
        <ls-svg-icon
          [fullHeight]="true"
          [path]="'flash/warning'"></ls-svg-icon>
      </div>

      <div class="fz-20 fz-16-md color-tertiary text-center popup-info mb-40">
        <p
          [innerHTML]="
            'Farms.AddHouseNumberDialog.ThisDeviceHouseNumberAlreadyExistsWithValue'
              | translate : { houseNumber: data.currentHouseNumber }
              | memoizeFunc : getInnerHTML : this
          "></p>
        <p>{{ "Farms.AddHouseNumberDialog.ToCompleteThisTask" | translate }}</p>
        <p>{{ "Farms.AddHouseNumberDialog.AddAHouseNumberForThisDevice" | translate }}</p>

        <p
          *ngIf="data.usedHouseNumbers?.length > 0"
          class="mt-10">
          {{ "Farms.AddHouseNumberDialog.YouCantUseTheseNumbers" | translate }}:
          <span class="fw-700 color-primary">{{ data.usedHouseNumbers.join(", ") }}</span>
        </p>
      </div>

      <div class="form-group house-number-input">
        <label class="d-block mb-8 fz-16 color-tertiary">
          {{ "Farms.AddHouseNumberDialog.HouseNumber" | translate }}
        </label>
        <ls-input
          [qaTags]="'qa-txt-device-box'"
          [(ngModel)]="houseNumber"></ls-input>

        <div class="error-field">
          <span *ngIf="houseNumber && data.usedHouseNumbers.includes(+houseNumber)">
            {{
              "Farms.AddHouseNumberDialog.HouseNumberWasAlreadyTakenWithValue"
                | translate : { houseNumber: houseNumber }
            }}
          </span>

          <span *ngIf="houseNumber && !(houseNumber | memoizeFunc : isInputValid)">
            {{ "Farms.AddHouseNumberDialog.PleaseEnterOnlyNumbers" | translate }}
          </span>

          <span *ngIf="houseNumber && houseNumber > 999">
            {{ "Farms.AddHouseNumberDialog.MaximumNumberIs999" | translate }}
          </span>
        </div>
      </div>
    </div>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-center buttons">
      <ls-button
        (click)="close()"
        [qaTags]="'qa-btn-cancel'"
        class="mr-15"
        type="secondary">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>

      <ls-button
        (click)="addHouseNumber()"
        [class.disabled]="
          !houseNumber ||
          data.usedHouseNumbers.includes(+houseNumber) ||
          houseNumber > 999 ||
          !(houseNumber | memoizeFunc : isInputValid)
        "
        [qaTags]="'qa-btn-add'">
        {{ "Buttons.OK" | translate }}
      </ls-button>
    </div>
  </div>
</div>
