import { LightingElement, LightingPeriod, SingleLightingProgram } from '../interfaces/lighting.interfaces';

export interface LightingProgramState {
  isLoading: boolean;
  periods: LightingPeriod[];
  lightDOElements: LightingElement[],
  lightAOElements: LightingElement[];
  currentProgram: SingleLightingProgram;
  currentPeriod: LightingPeriod;
}
