import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter, first } from 'rxjs';
import { selectElementSettings } from 'libs/installation/src/lib/+state/installation.selectors';
import { HeaterWorkModeEnum } from '../../../../../enums/element/heating/hater-work-mode.enum';
import { MatRadioChange } from '@angular/material/radio';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-heating-settings-form',
  templateUrl: './heating-settings-form.component.html',
  styleUrls: ['./heating-settings-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class HeatingSettingsFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroupName: string;
  @Input() isEditMode: boolean;
  @Input() isResetForm$: Observable<boolean>;
  @Input() isSaveForm$: Observable<boolean>;
  @Output() setIsDirty: EventEmitter<boolean> = new EventEmitter();

  // subs
  sub$: Subscription = new Subscription();

  /* component vars */
  parentFormContainer = inject(ControlContainer);
  originalValues;
  // enums
  HeaterWorkModeEnum = HeaterWorkModeEnum;
  ElementsValidationConstants = ElementsValidationConstants;

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.select(selectElementSettings).pipe(
      filter(res => res != null),
      first(),
    ).subscribe(({ heaterWorkMode, maxCycleTime, minCycleTime, analogHeaters, isAnyHeatingAOElementExist }) => {
      this.originalValues = { heaterWorkMode, maxCycleTime, minCycleTime, analogHeaters, isAnyHeatingAOElementExist };

      this.parentFormGroup.addControl(this.formGroupName, new FormGroup({
        heaterWorkMode: new FormControl<number>(heaterWorkMode, [Validators.required]),
        maxCycleTime: new FormControl<number>(maxCycleTime, [
          Validators.min(ElementsValidationConstants.heatingSettings.maxCycleTime.min),
          Validators.max(ElementsValidationConstants.heatingSettings.maxCycleTime.max),
        ]),
        minCycleTime: new FormControl<number>(minCycleTime, [
          Validators.min(ElementsValidationConstants.heatingSettings.minCycleTime.min),
          Validators.max(ElementsValidationConstants.heatingSettings.minCycleTime.max),
        ]),
        analogHeaters: new FormControl<number>(analogHeaters, [
          Validators.min(ElementsValidationConstants.heatingSettings.analogHeaters.min),
          Validators.max(ElementsValidationConstants.heatingSettings.analogHeaters.max),
        ]),
        isAnyHeatingAOElementExist: new FormControl<boolean>(isAnyHeatingAOElementExist),
      }));
    });

    this.sub$.add(this.isResetForm$.subscribe(val => {
      if (val) {
        const group = this.parentFormGroup.get(this.formGroupName);
        group.patchValue({ ...this.originalValues });
        group.updateValueAndValidity({ emitEvent: false });
      }
    }));

    this.sub$.add(this.isSaveForm$.subscribe(val => {
      if (val) {
        const group = this.parentFormGroup.get(this.formGroupName);
        this.originalValues = group.getRawValue();
        group.patchValue({ ...this.originalValues });
        group.updateValueAndValidity({ emitEvent: false });
      }
    }));

    this.sub$.add(
      this.parentFormGroup.get(this.formGroupName).valueChanges.subscribe((items) => {
        this.setIsDirty.emit(JSON.stringify(items) !== JSON.stringify(this.originalValues));
      }),
    );
  }

  heaterWorkModeChanged(event: MatRadioChange): void {
    if (event.value === HeaterWorkModeEnum.Continuously) {
      const group = this.parentFormGroup.get(this.formGroupName);
      group.patchValue({
        ...this.originalValues,
        heaterWorkMode: event.value,
        maxCycleTime: 0,
        minCycleTime: 0,
        analogHeaters: 0,
      });
      group.updateValueAndValidity({ emitEvent: false });
    }
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    this.sub$.unsubscribe();
  }
}

