import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';

@Component({
  selector: 'ls-lighting-ao-testing-form',
  templateUrl: './lighting-ao-testing-form.component.html',
  styleUrls: ['./lighting-ao-testing-form.component.scss'],
})
export class LightingAOTestingFormComponent implements OnInit {
  form: FormGroup;
  selectedNumber: number;

  numbers: number[] = Array.from({length: 10}, (_, i) => i + 1);
  MIN = 0;
  MAX = 10;

  ngOnInit(): void {
    this.form = new FormGroup({
      voltage: new FormControl<number>(0, [
        Validators.min(this.MIN),
        Validators.max(this.MAX),
      ]),
    });
  }

  selectNumber(number: number): void {
    this.selectedNumber = number;
    this.form.patchValue({ voltage: number }, {emitEvent: false});
  }
}

