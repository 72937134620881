import { AfterViewInit, Component, OnDestroy, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AddProgramDayMobileDialogComponent,
  ButtonComponent,
  ButtonWithIconComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  InputTextComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  PageWrapperComponent,
  SvgIconComponent,
  clearKeyboardValue,
  keyboardUpdateFields,
  selectCurrentKeyboardUUID,
  selectKeyboardValueAndUUID,
  setElementUuid,
} from '@livestock/ui';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as lightingActions from './+state/lighting-program.actions';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, filter, firstValueFrom, lastValueFrom, map, take, tap } from 'rxjs';
import {
  selectLightingPeriods,
  selectLightingProgramIsLoading,
  selectCurrentLightingPeriod,
  selectCurrentLightingProgram,
  selectAllLightingPeriodElements,
} from './+state/lighting-program.selectors';
import { LightingPeriod, LightingProgram } from './interfaces/lighting.interfaces';
import { NgxMatTimepickerComponent, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { saveLightingProgram } from './+state/lighting-program.actions';
import * as utils from '@livestock/shared/utils';
import { ColorsEnum, DialogButtonEnum, ElementTypesEnum, ErrorFieldEnum, KeyboardEnum } from '@livestock/shared/enums';
import { Actions } from '@ngrx/effects';
import * as moment from 'moment';
import { NativeElementInjectorDirective } from 'libs/ui/src/lib/controls/native-element.directive';
import { FindNextToPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { setKeyboardMode } from '../../../ui/src/lib/controls/keyboard/+state/keyboard.actions';
import { AddDayDialogComponent, DaysManagerMobileComponent } from '@livestock/ui';
import { ElementConstants } from 'libs/installation/src/lib/components/devices/constants/element.constants';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { GlobalConstants } from '@livestock/shared/constants';
import { LightingProgramTableComponent } from './lighting-program-table-mobile/lighting-program-table-mob.component';
import { selectHourFormatForTimePicker } from '@livestock/controllers';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import {
  EnableValidatorIfControlTouched,
  EnableValidatorsIfControlTouched,
  StartTimeAfterStartTimeOfPrevProg,
} from '@livestock/shared/validators';
import { LightingProgramConstants } from '@livestock/lighting-program/models';

export interface ElementForm {
  programID: FormControl<number>;
  elementID: FormControl<number>;
  number: FormControl<number>;
  elementType: FormControl<ElementTypesEnum>;
  intensity?: FormControl<number>;
  enabled?: FormControl<boolean>;
}

declare module '@angular-material-components/datetime-picker' {
  // eslint-disable-next-line
  export interface NgxMatTimepickerComponent<D> {
    uuids: string[];
  }
}

@Component({
  selector: 'ls-lighting-program',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    KeyboardComponent,
    SvgIconComponent,
    ButtonWithIconComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NativeElementInjectorDirective,
    MemoizeFuncPipe,
    FindNextToPipe,
    LightingProgramTableComponent,
    AddProgramDayMobileDialogComponent,
    InputTextComponent,
    DaysManagerMobileComponent,
    QaTagsDirective,
    PageWrapperComponent,
    ErrorFieldDirective,
  ],
  templateUrl: './lighting-program.component.html',
  styleUrls: ['./lighting-program.component.scss'],
})
export class LightingProgramComponent implements OnInit, OnDestroy, AfterViewInit, IComponentCanDeactivate {
  @ViewChildren('timepicker') timepickerRef: QueryList<NgxMatTimepickerComponent<any>>;
  @ViewChild('mobileTable') mobileTable;
  @ViewChild('programDaysScrollBar') programDaysScrollBar;

  /*constants*/
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;
  ElementConstants = ElementConstants;
  ElementTypesEnum = ElementTypesEnum;
  GlobalConstants = GlobalConstants;
  ErrorFieldEnum = ErrorFieldEnum;
  LightingProgramConstants = LightingProgramConstants;

  /*variables*/
  editMode = false;
  selectedProgramId = null;
  selectedProgramIndex = null;
  form: FormGroup;
  lastSavedValue: string;
  currentTimeUUID: string;
  itemsToDelete = [];
  allAvailableLightingElements = [];
  dialogRef: MatDialogRef<AddDayDialogComponent, any>;
  selectHourFormatForTimePicker$ = this.store.select(selectHourFormatForTimePicker);
  controllerID: number;

  /*observables*/
  periods$ = this.store.select(selectLightingPeriods);
  currentPeriod$ = this.store.select(selectCurrentLightingPeriod);
  currentProgram$ = this.store.select(selectCurrentLightingProgram);
  loading$ = this.store.select(selectLightingProgramIsLoading);
  availableElems$ = this.store.select(selectAllLightingPeriodElements);

  sub$ = new Subscription();
  isAMPM$ = this.store.select(selectCurrentKeyboardUUID).pipe(
    map(i => this.timepickerRef.reduce((acc, i) => ([...acc, ...(i?.uuids || [])]), []).includes(i)),
    tap(i => {
      if (i) {
        this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
      }
    }),
  );

  // Mobile
  currentProgramIndex: number = 1;
  isDisabled: boolean = false;

  constructor(
    public platformService: PlatformService,
    public dialogService: DialogsService,
    public actions: Actions,
    private menuService: MenuService,
    private store: Store,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
  ) {
  }

  ngAfterViewInit(): void {
    this.updateIds();

    this.sub$.add(this.loading$.pipe(filter(loading => !loading)).subscribe(() =>
      setTimeout(() => this.adjustProgramDaysScroll()),
    ));
  }

  ngOnInit(): void {
    this.activatedRoute.data.pipe(
      map((i) => i['elements'].reduce((acc, i) => ([...acc, ...i.items]), [])),
    ).subscribe(i => this.allAvailableLightingElements = i);

    this.store.dispatch(lightingActions.getLightingPeriod());
    this.store.select(selectLightingPeriods).pipe(filter(periods => periods !== null), take(1)).subscribe(periods => {
      this.setPeriod(periods[0], false);
    });

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
      }),
    );

    this.sub$.add(
      this.store.select(selectCurrentLightingProgram)
        .pipe(filter(i => !!i))
        .subscribe(async (program) => {
          if (this.form) {
            this.form.enable();
          }

          this.form = this.fb.group({
            items: this.fb.array(program.items ? program.items.map(i => this.getLightingFG(i)) : []),
          });

          this.store.dispatch(keyboardUpdateFields());
          await this.addDefaultRow();
          this.lastSavedValue = JSON.stringify(this.form.value);
          if (!this.editMode) {
            this.form.disable();
          }
          this.updateIds();
        }),
    );

    this.sub$.add(
      this.actions.pipe(filter(action => action.type === lightingActions.saveLightingProgramSuccess.type)).subscribe(() => {
        this.lastSavedValue = JSON.stringify(this.form.value);
      }),
    );

    this.initTimePickerFocus();
  }

  goToMappings(): void {
    this.router.navigate([AppRoutes.CONTROLLER, this.controllerID, ControllerRoutes.LightingMapping]);
  }

  goToSettings(): void {
    this.router.navigate([AppRoutes.INSTALLATION, 'elementType', ElementTypesEnum.LightAO, 'settings']);
  }

  setDisabledState(ev: boolean): void {
    this.isDisabled = typeof ev === 'boolean' ? ev : false;
  }

  setFocusedTimepicker(e): void {
    if (this.platformService.isMobileApp) return;

    const uuid = e.target.getAttribute('uuid');
    this.currentTimeUUID = uuid;
    if (!uuid) return;
    this.store.dispatch(setElementUuid({
      elementUuid: uuid,
    }));
  }

  get formUnchanged(): boolean {
    return this.lastSavedValue === JSON.stringify(this.form?.value);
  }

  initTimePickerFocus(): void {
    if (this.platformService.isMobileApp) return;
    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
    this.sub$.add(
      this.store.select(selectKeyboardValueAndUUID)
        .pipe(
          tap(({ elementUuid }) => {
            this.currentTimeUUID = elementUuid;
          }),
          filter(({ symbol, elementUuid }) => symbol != null &&
            this.timepickerRef
              .reduce((acc, i) => ([...acc, ...i.uuids]), [])
              .includes(elementUuid),
          ),
        ).subscribe(({ symbol, elementUuid }) => {
        const focusedControl = document.activeElement as HTMLInputElement;
        if (!focusedControl) {
          return;
        }

        // Change focus on Enter
        if (symbol.toString().includes(KeyboardEnum.Enter)) {
          this.focusControl(elementUuid);
          return;
        }

        //to set value according to vladimir code
        const timepickerControlName = focusedControl.attributes.getNamedItem('formcontrolname')?.value;
        if (!timepickerControlName) {
          return;
        }

        const ref = this.timepickerRef.find(i => i.uuids.includes(elementUuid));

        //AM PM BUTTONS BLYAT GOSPODI ETOT DISIGNER ZHELAET MOEY SMERTI
        if (symbol === KeyboardEnum.AM || symbol === KeyboardEnum.PM) {
          ref.meridian = symbol === KeyboardEnum.PM ? KeyboardEnum.PM : KeyboardEnum.AM;
          return;
        }

        // Put symbol
        const { selectionStart, selectionEnd } = focusedControl;
        const isSingleDeletion = selectionStart === selectionEnd && symbol === '' && selectionStart > 0;
        const isMultipleDeletion = symbol === '' && selectionStart !== selectionEnd;
        const currentValue = ref.form.controls[timepickerControlName].value.toString().split('');
        if (isSingleDeletion) {
          // remove the symbol before the cursor
          currentValue.splice(selectionStart - 1, 1);
        } else {
          // add symbol in the right place or remove multiple symbols
          currentValue.splice(selectionStart, selectionEnd - selectionStart, symbol as string);
        }

        ref.form.controls[timepickerControlName].setValue(currentValue.join('').slice(0, 2));

        this.store.dispatch(clearKeyboardValue());

        // set cursor for prev place for single deletion
        if (isSingleDeletion) {
          focusedControl.selectionStart = selectionStart - 1;
          focusedControl.selectionEnd = selectionStart - 1;
          return;
        }

        // do not move cursor for multiple deletion
        if (isMultipleDeletion) {
          focusedControl.selectionStart = selectionStart;
          focusedControl.selectionEnd = selectionStart;
          return;
        }

        // set cursor on next place after adding new symbol
        focusedControl.selectionStart = selectionStart + 1;
        focusedControl.selectionEnd = selectionStart + 1;
      }),
    );
  }

  getTime(): string {
    const firstStartTime = (this.form?.controls['items'] as FormArray)?.controls[0]?.value?.startTime as moment.Moment;
    if (!firstStartTime) {
      return `No time set`;
    }
    const latestPossible = firstStartTime.clone();
    latestPossible.set('hours', 24);
    latestPossible.set('minutes', 0);
    const hours = latestPossible.diff(firstStartTime, 'hours').toString();
    const minutes = latestPossible.subtract(hours, 'hours').diff(firstStartTime, 'minutes').toString();
    return `${hours.padStart(2, '0')}:${minutes.padStart(2, '0')} hrs`;
  }

  getLightingFG({
                  name,
                  periodID,
                  programID,
                  elements,
                  startTime,
                }: LightingProgram): FormGroup<any> {
    return this.fb.group({
      name: this.fb.control(name, EnableValidatorIfControlTouched(Validators.required)),
      programID: this.fb.control(programID),
      periodID: this.fb.control(periodID),
      startTime: this.fb.control(
        utils.TimeUtils.BackendValueToMoment(startTime || GlobalConstants.TimeDefault),
        EnableValidatorsIfControlTouched([Validators.required])),
      elements: this.fb.array(this.allAvailableLightingElements.map(availableElem => {
        const value = elements?.find(i => i.elementID === availableElem.elementID);

        const formGroup: FormGroup<any> = this.fb.group({
          programID: value?.programID,
          elementID: value?.elementID || availableElem.elementID,
          number: value?.number || availableElem.number,
          elementType: availableElem.type || availableElem.elementType,
        });

        if (availableElem.type === ElementTypesEnum.LightAO || availableElem.elementType === ElementTypesEnum.LightAO) {
          formGroup.addControl('intensity', new FormControl(value?.intensity || 0, EnableValidatorsIfControlTouched([Validators.min(0), Validators.max(100)])));
        } else if (availableElem.type === ElementTypesEnum.LightDO || availableElem.elementType === ElementTypesEnum.LightDO) {
          formGroup.addControl('enabled', this.fb.control(value?.enabled));
        }

        return formGroup;
      })),
    }, { validators: EnableValidatorIfControlTouched(StartTimeAfterStartTimeOfPrevProg) });
  }

  selectRow(row: FormGroup, rowIndex: number): void {
    this.selectedProgramId = row.value.programID;
    this.selectedProgramIndex = rowIndex;

    // Due to the requirement to validate the form only if it was touched ...
    const markRowAsTouched = (emitEvent: boolean): void => {
      row.markAllAsTouched();
      row.markAsDirty();

      Object.values(row.controls).forEach(i => {
        i.markAsDirty();
        i.updateValueAndValidity({ emitEvent });
      });

      row.updateValueAndValidity({ emitEvent });
    };

    if (this.platformService.isMobileApp) {
      row.valueChanges.subscribe(() => {
        this.editMode && markRowAsTouched(false);
      });
    } else {
      markRowAsTouched(true);
    }
  }

  getElements(form: FormGroup): FormGroup<ElementForm>[] {
    return (form.controls?.['elements'] as FormArray)?.controls as FormGroup<ElementForm>[];
  }

  updateIds(): void {
    if (this.platformService.isMobileApp) return;

    setTimeout(() => {
      const inputIds = Array.from(document.querySelectorAll('.program ngx-mat-timepicker')).map(domTimepicker => {
        return Array.from(
          domTimepicker.querySelectorAll('input.mat-mdc-input-element'))
          .map(inputElement => inputElement.getAttribute('uuid'));
      });
      if (!inputIds?.length) return;

      this.timepickerRef.forEach((timeinputObject, index) => {
        timeinputObject.uuids = inputIds[index];
      });
    }, 1000);
  };

  async enableEdit(): Promise<void> {
    this.form.enable();
    this.editMode = true;
    await this.addDefaultRow();

    if (this.platformService.isMobileApp) {
      this.selectedProgramIndex == null && (this.selectedProgramIndex = 0);
      this.selectRow((this.form.controls['items'] as FormArray).at(this.selectedProgramIndex) as FormGroup, 0);
    }
    this.updateIds();
  }

  async addDefaultRow(): Promise<void> {
    const items = (this.form.controls['items'] as FormArray);
    if (!items.length) {
      await this.addEmptyRow();

      if (this.platformService.isMobileApp) {
        // update empty program validation
        const row = items.controls[0] as FormGroup;
        this.selectRow(row, 0);
      }
    }
  }

  async getEmptyRow(): Promise<FormGroup<any>> {
    const currentPeriod = await firstValueFrom(this.store.select(selectCurrentLightingPeriod));
    const fg = this.getLightingFG({ periodID: currentPeriod?.periodID } as any);
    return fg;
  }

  async addEmptyRow(): Promise<void> {
    const items = (this.form.controls['items'] as FormArray);

    const item = await this.getEmptyRow();
    items.push(item);
    this.store.dispatch(keyboardUpdateFields());
    this.ngAfterViewInit();

    if (this.platformService.isMobileApp) {
      this.currentProgramIndex = items?.length;
      if (items?.length && this.mobileTable) this.mobileTable.swiper.swiperRef.activeIndex = items.length;
    }
  }

  getRows(): FormGroup[] {
    return (this.form?.controls['items'] as FormArray)?.controls as FormGroup[];
  }

  cancel(): void {
    this.menuService.toggleDashboardMenu(true);
  }

  async setPeriod(newPeriod: LightingPeriod, checkCurrentPeriod: boolean = true): Promise<void> {
    const currentPeriod = checkCurrentPeriod
      ? await firstValueFrom(this.currentPeriod$)
      : null;
    const isCurrentPeriodMock = currentPeriod && !currentPeriod.periodID;
    if (isCurrentPeriodMock || (this.form?.value.items.length && !this.formUnchanged)) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    if (isCurrentPeriodMock) {
      this.store.dispatch(lightingActions.deleteLightingDay({ periodID: currentPeriod.periodID }));
    }
    this.store.dispatch(lightingActions.setCurrentLightingPeriod({ currentPeriod: newPeriod || null }));
    this.store.dispatch(lightingActions.getLightingProgram({ periodID: newPeriod?.periodID || null }));
    this.selectedProgramIndex = null;
    this.selectedProgramId = null;
  }

  async addDay(): Promise<void> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    if (!this.formUnchanged || !currentPeriod.periodID) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    ;

    this.dialogRef = this.dialog.open(AddDayDialogComponent, {
      width: '500px',
    });

    this.sub$.add(
      this.periods$.pipe(take(1)).subscribe((i) => {
        const days = i.map(i => i.dayNumber);
        this.dialogRef.componentInstance.setup(days);
      }),
    );

    const res = await lastValueFrom(this.dialogRef.afterClosed());
    this.dialogRef = null;
    if (res) {
      if (!currentPeriod.periodID) {
        this.store.dispatch(lightingActions.deleteLightingDay({
          periodID: currentPeriod.periodID,
          preventSelecting: true,
        }));
      }

      this.store.dispatch(lightingActions.addLightingDay(res));
    }
  }

  async clearBeforeDayAdding(dayNumberControl: FormControl): Promise<void> {
    if (!this.formUnchanged) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    ;

    dayNumberControl.setValue(null);
    this.store.dispatch(lightingActions.clearProgram());
  }

  addNewDayMobile(dayNumber: number): void {
    this.store.dispatch(lightingActions.addLightingDay({ dayNumber }));
  }

  focusControl(property: string): void {
    const control = document.querySelector(`[uuid=${property}]`) as HTMLInputElement;
    control.focus();
    control.setSelectionRange(0, 0);
  }

  async cancelEdit(): Promise<void> {

    const restoreSettings = (): void => {
      this.store.dispatch(lightingActions.filterPeriods({ currentPeriod }));
      const period = !currentPeriod?.periodID ? periods.find(p => !!p?.periodID) : currentPeriod;
      this.store.dispatch(lightingActions.setCurrentLightingPeriod({ currentPeriod: period }));
      this.store.dispatch(lightingActions.getLightingProgram({ periodID: period?.periodID }));
      this.editMode = false;
      this.isDisabled = false;
    };

    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    const periods = await firstValueFrom(this.periods$);
    if (this.formUnchanged && currentPeriod?.periodID) {
      await this.restoreDefault();
      restoreSettings();
    } else {
      const res = await this.dialogService.canContinueAction();
      if (res) {
        await this.restoreDefault();
        restoreSettings();
      }
    }
    this.updateIds();
  }

  async restoreDefault(): Promise<void> {
    this.editMode = false;
    const lastValue = JSON.parse(this.lastSavedValue);
    lastValue.items = lastValue.items.map((program): LightingProgram => ({
      ...program,
      startTime: utils.TimeUtils.MomentToBackendValue(program.startTime),
    }));
    this.form = this.fb.group({
      items: this.fb.array(lastValue.items.map(i => this.getLightingFG(i))),
    });

    const items = (this.form.controls['items'] as FormArray);
    const emptyItemIndex = items.controls.findIndex(i => !i.value.programID);

    if (emptyItemIndex !== -1 && emptyItemIndex !== 0) {
      items.removeAt(emptyItemIndex);
    }

    this.form.disable();

    this.itemsToDelete = [];
  }

  getAllNonEmptyRowsValue(): any {
    return {
      ...this.form.value,
      items: this.form.value.items.filter(i => {
        return i.programID || i.name;
      }),
    };
  };

  async save(): Promise<void> {
    const values = this.getAllNonEmptyRowsValue();

    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    if (this.form.valid) {
      const deleteNumbers = Array.from(new Set(this.itemsToDelete.map(i => i.programIndex + 1))).sort();
      if (deleteNumbers.length) {

        const deleteText = this.translate.instant(deleteNumbers.length > 1 ?
            'Controls.Element.SaveChangesWithDeleteTextMultiple' :
            'Controls.Element.SaveChangesWithDeleteText',
          { programs: deleteNumbers.join(', ') });

        const res = await this.dialogService.question(
          deleteText, 'Controls.Element.SaveChanges', [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
          this.platformService.isMobileApp ? 'warning-orange' : 'flash/warning',
          false,
        );

        if (res === DialogButtonEnum.CANCEL || !res) return;
      }

      this.lastSavedValue = JSON.stringify(values);
      this.store.dispatch(
        saveLightingProgram({
          period: currentPeriod,
          itemsToDelete: this.itemsToDelete,
          items:
            values.items.map((i: LightingProgram) => ({
              ...i,
              startTime: utils.TimeUtils.MomentToBackendValue(i.startTime),
            })),
        }),
      );
      this.itemsToDelete = [];
    }
  }

  areNoFilledRows(): boolean {
    return !this.getAllNonEmptyRowsValue().items.length;
  }

  deleteProgram(currentPeriod: LightingPeriod): void {
    if (this.platformService.isMobileApp) {
      const controls = (this.form.get('items') as FormArray).controls;
      this.selectedProgramId = controls[this.currentProgramIndex - 1]?.value?.programID;
    }

    (this.form.controls['items'] as FormArray).removeAt(this.selectedProgramIndex);

    if (this.selectedProgramId) {

      this.itemsToDelete.push({
        programID: this.selectedProgramId,
        programIndex: this.selectedProgramIndex,
        periodID: currentPeriod.periodID,
      });
    }

    if (!this.platformService.isMobileApp) {
      this.selectedProgramIndex = null;
      this.selectedProgramId = null;
    }
  }

  async deleteDay(): Promise<void> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    const deleteText = this.translate.instant('Controls.Element.DeleteDayText', { dayNumber: currentPeriod.dayNumber });

    const res = await this.dialogService.question(
      deleteText, 'Controls.Element.DeleteDay', [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
      this.platformService.isMobileApp ? 'warning-orange' : 'flash/warning',
      false,
    );

    if (res === DialogButtonEnum.CANCEL || !res) return;
    this.store.dispatch(lightingActions.deleteLightingDay({ periodID: currentPeriod?.periodID }));

    this.sub$.add(
      this.store.select(selectLightingPeriods)
        .pipe(
          filter(periods => !!periods),
        ).subscribe(periods => {
        this.setPeriod(periods[periods?.length - 1], false);
      }),
    );

    if (this.platformService.isMobileApp) this.editMode = false;
  }

  isFocused([timepicker, uuid]: [NgxMatTimepickerComponent<any>, string]): boolean {
    return timepicker?.uuids?.includes(uuid);
  }

  onTableChange(currentProgramIndex: number): void {
    this.currentProgramIndex = currentProgramIndex + 1;
    this.selectRow((this.form.controls['items'] as FormArray).at(currentProgramIndex) as FormGroup, currentProgramIndex);
  }

  private adjustProgramDaysScroll(): void {
    const programDaysScrollBarElement: HTMLElement = this.programDaysScrollBar.nativeElement;
    const programDays: HTMLCollection = programDaysScrollBarElement.children;

    const selectedDay = Array.from(programDays).find(day => {
      return day.classList.contains('program-day__active');
    });

    if (selectedDay) {
      (selectedDay as HTMLElement).scrollIntoView({ behavior: 'instant', block: 'nearest', inline: 'start' });
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.store.dispatch(lightingActions.resetLightingProgram());
  }

  async canDeactivate(): Promise<boolean> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    return this.formUnchanged && currentPeriod?.periodID != null;
  }

  closeComponent(): void {
  }
}
