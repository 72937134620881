import { IEditUnitModeObj } from '../../interfaces';

export enum WaterLinePressureUnitEnum {
  CmOfWater,
  InchesOfWater,
}

export namespace WaterLinePressureUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case WaterLinePressureUnitEnum.CmOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressureEnum.CmOfWater';
      case WaterLinePressureUnitEnum.InchesOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressureEnum.InchesOfWater';
      default:
        return value.toString();
    };
  }

  export function isMetricUnit(value: WaterLinePressureUnitEnum): boolean {
    return value === WaterLinePressureUnitEnum.CmOfWater;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.WaterLinePressure',
      controlName: 'waterLinePressure',
      enumName: WaterLinePressureUnitEnum,
      enumStr: 'water-line-pressure-unit-enum',
    };
  }
}
