<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [isWhiteBackground]="true"
  [title]="temperatureCurveRoute | memoizeFunc : getTitle">
  <div
    *ngIf="!editMode || !platformService.isMobileApp"
    class="svg-content header__buttons d-flex align-center ml-auto-md">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="
        editMode || temperatureCurveRoute === TemperatureCurveRoute.Chart ? ColorsEnum.BlueDark : ColorsEnum.Default
      "
      [path]="
        editMode || temperatureCurveRoute === TemperatureCurveRoute.Chart
          ? 'common/icon-table'
          : 'temperature-curve/edit-button'
      "
      class="icon pointer ml-20 qa-btn-edit-mode">
    </ls-svg-icon>

    <ng-container *ngIf="temperatureCurveRoute === TemperatureCurveRoute.Table">
      <ls-svg-icon
        (click)="openChart()"
        [class.disabled]="editMode"
        [path]="'temperature-curve/chart'"
        class="icon pointer ml-20 qa-btn-open-chart">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="openSettings()"
        [class.disabled]="editMode"
        [path]="'temperature-curve/settings'"
        class="icon pointer ml-20 qa-btn-open-settings">
      </ls-svg-icon>
    </ng-container>
  </div>

  <div class="main-content settings__form d-flex flex-row flex-column-md">
    <!-- table -->
    <ng-container [ngSwitch]="temperatureCurveRoute">
      <ng-container *ngSwitchCase="TemperatureCurveRoute.Table">
        <ls-temperature-curve-table
          *ngIf="editMode || temperatureCurve?.length; else empty"
          (changed)="changedTempCurve($event)"
          [activeControllerID]="activeControllerId"
          [editMode]="editMode"
          [hasUnsaved]="isTemperatureCurveHasUnsaved"
          [isDirty]="isTemperatureCurveDirty"
          [isLoading]="isLoading$ | async"
          [temperatureCurveItems]="originalTemperatureCurve"
          [temperatureUnitType]="temperatureUnit$ | async"
          class="settings__form-table w-100-percent h-100-percent">
          <div
            *ngIf="editMode"
            class="buttons-content">
            <ls-button
              (click)="cancel()"
              [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
              [qaTags]="'qa-btn-cancel'"
              [type]="'secondary'"
              class="mr-28 h-button-36">
              {{ "Buttons.Cancel" | translate }}
            </ls-button>
            <ls-button
              (click)="update()"
              [class.disabled]="!isTemperatureCurveValid || !isTemperatureCurveDirty"
              [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
              [qaTags]="'qa-btn-update'"
              [type]="'primary'"
              class="h-button-36">
              {{ "Buttons.Save" | translate }}
            </ls-button>
          </div>
        </ls-temperature-curve-table>
      </ng-container>

      <!-- settings -->
      <ls-temperature-curve-settings
        *ngSwitchCase="TemperatureCurveRoute.Settings"
        (changed)="changeTemperatureCurveSettings($event)"
        [editMode]="editMode"
        [isLoading]="isLoading$ | async"
        [temperatureCurveSettings]="temperatureCurveSettings"
        [temperatureUnit]="temperatureUnit$ | async"
        class="settings__form-settings w-100-percent h-100-percent">
        <div
          *ngIf="editMode"
          class="buttons-content">
          <ls-button
            (click)="cancelSettings()"
            [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
            [qaTags]="'qa-btn-cancel'"
            [type]="'secondary'"
            class="mr-28 h-button-36">
            {{ "Buttons.Cancel" | translate }}
          </ls-button>

          <ls-button
            (click)="update()"
            [class.disabled]="!isSettingsValid || !isSettingsDirty"
            [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
            [qaTags]="'qa-btn-update'"
            [type]="'primary'"
            class="h-button-36">
            {{ "Buttons.Save" | translate }}
          </ls-button>
        </div>
      </ls-temperature-curve-settings>

      <!-- chart -->
      <ls-temperature-curve-chart
        *ngSwitchCase="TemperatureCurveRoute.Chart"
        [chartData]="[originalTemperatureCurve, temperatureCurveSettings] | memoizeFunc : getChartData"
        [chartOptions]="chartOptions"
        [legend]="chartLegend"
        class="settings__chart-settings w-100-percent h-100-percent"></ls-temperature-curve-chart>
    </ng-container>
  </div>

  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode && temperatureCurveRoute !== TemperatureCurveRoute.Chart"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20 d-none-md" />
</ls-page-wrapper>

<ng-template #empty>
  <div class="empty-state d-flex flex-column justify-center align-center h-100-percent">
    <ls-svg-icon [path]="'temperature-curve/temperature-curve'"> </ls-svg-icon>
    <span class="empty-state-title mt-20">
      {{ "TemperatureCurve.Empty" | translate }}
    </span>
    <ls-button
      (click)="toggleEditMode()"
      [qaTags]="'qa-btn-empty'"
      [type]="'primary'">
      {{ "TemperatureCurve.ClickToConfigure" | translate }}
    </ls-button>
  </div>
</ng-template>
