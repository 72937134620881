<div class="widget-wrapper">
  <h6
    [qaTags]="'qa-lbl-widget-title'"
    class="widget-title">
    Ventilation
  </h6>

  <div class="widget-body d-flex">
    <div class="widget-icon mr-25">
      <ls-svg-icon
        [color]="ColorsEnum.BlueDefault"
        [hasMargin]="true"
        [qaTags]="'qa-img-widget qa-img-vent'"
        path="dashboard/ventilation"></ls-svg-icon>
    </div>

    <div class="widget-content">
      <div class="ventilation-blocks d-flex">
        <div class="ventilation-block ventilation-block__fixed">
          <h3
            [qaTags]="'qa-lbl-vent-type'"
            class="ventilation-title fw-500 fz-40 fz-32-xl fz-22-lg mb-20 mb-30-xl mb-20-lg">
            Minimal
          </h3>
          <div class="ventilation-percent mb-5 mb-0-xl">
            <div
              [qaTags]="'qa-lbl-vent-output-percent'"
              class="fz-40 fz-32-xl fz-26-lg fw-500">
              81<span class="fz-16 fz-14-lg fw-400"
                >%
                <span [qaTags]="'qa-lbl-vent-output-value'">(381,000)</span>
              </span>
            </div>
          </div>
          <div class="ventilation-subtitle color-tertiary fz-18-lg">
            <span [qaTags]="'qa-lbl-vent-output-units'">Output M3/H</span>
          </div>
        </div>

        <div class="ventilation-block">
          <div class="mb-10 mb-12-xl mb-12-lg ventilation-block__top">
            <div class="ventilation-steps d-flex align-center mb-5">
              <div
                *ngFor="let step of steps"
                [ngClass]="{
                  'ventilation-step__active fz-32-xl fz-22-lg ph-15 ph-10-lg': step === 6
                }"
                [qaTags]="'qa-lbl-vent-low-step qa-lbl-vent-high-step qa-lbl-vent-current-step'"
                class="ventilation-step fz-32 fz-20-xl ph-5">
                {{ step }}
              </div>
            </div>
            <div class="widget-content__subtitle">
              <span>Step</span>
            </div>
          </div>

          <div class="mb-10">
            <div class="mb-5 mb-7-xl mb-3-lg fz-32 fz-20-xl fz-22-lg ventilation-right__value">1,22</div>
            <div class="ventilation-subtitle color-tertiary fz-18-lg">
              <span [qaTags]="'qa-lbl-vent-units qa-lbl-vent-kg-units'">M3/H/Kg</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
