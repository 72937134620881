export enum IconsCommonEnum {
  CROSS = 'new-icons/common/cross',
  CROSS_WHITE = 'new-icons/common/cross-white',
  LEFT_ARROW = 'new-icons/common/left-arrow',
  RIGHT_ARROW = 'new-icons/common/right-arrow',
  MINUS_IN_SQUARE = 'new-icons/common/minus-in-square',
  PLUS_IN_SQUARE = 'new-icons/common/plus-in-square',
  CHECKMARK = 'new-icons/common/checkmark',
  INPUT_ICON = 'new-icons/common/input-icon',
  ARROW_DOWN = 'new-icons/common/arrow-down',
  ARROW_DOWN_WHITE = 'new-icons/common/arrow-down-white',
  BACKSPACE = 'new-icons/common/backspace',
  CHECKMARK_SMALL = 'new-icons/common/checkmark-small',
  DELETE = 'new-icons/common/delete',
  MALE = 'new-icons/common/male',
  EXCLAMATION = 'new-icons/common/exclamation',
  CHECKBOX = 'new-icons/common/checkbox',
  CHECKBOX_CHECKED = 'new-icons/common/checkbox-checked',
  EXPAND_IN_CIRCLE = 'new-icons/common/expand-in-circle',
  DATE_ICON = 'new-icons/common/date-icon',
  TIME_ICON = 'new-icons/common/time-icon',
  SMALL_CHECKMARK_BLUE = 'new-icons/common/checkmark-small-blue',
  CROSS_SMALL_BLACK = 'new-icons/common/cross-small-black',
  CONTROLLER_ICON = 'new-icons/common/controller-icon',
  CONTROLLER_ICON_SMALL = 'new-icons/common/controller-icon-small',
  CONTROLLER_ADD = 'new-icons/common/controller-add',
  FARM_SETTINGS = 'new-icons/common/farm-settings',
  FARM_SEARCH = 'new-icons/common/farm-search',
  FARM_SEARCH_WHITE = 'new-icons/common/farm-search-white',
  ALARM = 'new-icons/common/alarm',
  CLOUD = 'new-icons/common/cloud',
  LOGOUT = 'new-icons/common/logout'
}
