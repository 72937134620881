import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SvgIconComponent, ToggleNewComponent, LoadingGalconComponent } from '@livestock/ui';
import { INetworkSettingsView } from '@livestock/controllers/interfaces';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { IconsEnum } from '@livestock/shared/enums';
import { fadeInOut } from '@livestock/shared/animations';

@Component({
  selector: 'ls-network-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
    ToggleNewComponent,
    LoadingGalconComponent,
  ],
  templateUrl: './network-settings-form.component.html',
  styleUrls: ['./network-settings-form.component.scss'],
  animations: [fadeInOut],
})
export class ControllerNetworkSettingsFormComponent implements OnInit {
  @Input() isDisabled: boolean = false;
  @Input() isLoading: boolean = false;

  @Input() set networkSettings(networkSettings: INetworkSettingsView) {
    if (networkSettings) {
      this.updateFormValues(networkSettings);
    }
  }
  @Output() changed = new EventEmitter();

  //vars
  form = new FormGroup(
    {
      controllerID: new FormControl<number>(null),
      modem: new FormControl(0),
      wifi: new FormControl(0),
      lan: new FormControl(0),
      lanIp: new FormControl(''),
      lanMac: new FormControl(''),
      modemAPN: new FormControl(''),
      modemUser: new FormControl(''),
      modemPassword: new FormControl(''),
      wifiName: new FormControl(''),
      wifiPassword: new FormControl(''),
      wifiAutoConnect: new FormControl<boolean>(false),
    },
  );
  menuItem: 'ethernet' | 'wifi' | 'cellular' = null;
  lanConnected = false;
  lanIpAddress: string;
  lanMac: string;
  IconsEnum = IconsEnum;

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.form.valueChanges.subscribe((formValues) => {
      this.changed.emit({
        formValues: {
          ...formValues,
          lan: Number(formValues.lan),
        },
        isValid: this.form.valid,
      });
    });
  }

  updateFormValues(networkSettings: INetworkSettingsView): void {
    this.form.patchValue(networkSettings);
  }

  menuClicked(menuItemIndex: typeof this.menuItem): void {
    this.menuItem = menuItemIndex;
  }
}
