import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  Output,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  FormControl,
  FormArray,
  Validators,
  AbstractControl,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  DaysManagerMobileComponent,
  ButtonComponent,
  InputIntegerComponent,
  LoadingComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { Subscription } from 'rxjs';
import { PlatformService, DialogsService } from '@livestock/shared/services';
import { AirTreatmentConstants, IAirTreatmentView } from '@livestock/air-treatment';
import { DialogButtonEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'ls-air-treatment-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    MemoizeFuncPipe,
    LoadingComponent,
    InputIntegerComponent,
    ButtonComponent,
    SvgIconComponent,
    DaysManagerMobileComponent,
  ],
  templateUrl: './air-treatment-form.component.html',
  styleUrls: ['./air-treatment-form.component.scss'],
})
export class AirTreatmentFormComponent implements OnDestroy {
  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Input() controllerID: number;
  @Output() changed = new EventEmitter();
  @Input() set airTreatmentItems(airTreatmentItems: IAirTreatmentView[]) {
    if (airTreatmentItems) {
      if (!this.form || this.numberOfItems !== airTreatmentItems.length) {
        this.initForm(airTreatmentItems);
        this.activeDay = airTreatmentItems[0].day;
      } else {
        this.updateFormValues(airTreatmentItems);
      }
      this.numberOfItems = airTreatmentItems.length;
      this.days = airTreatmentItems.map(x => x.day).sort((a, b) => a - b);
    }
  }
  @Output() deleteDay = new EventEmitter<number>();
  @ViewChild('table') table: ElementRef;
  // subs
  sub$ = new Subscription();
  // vars
  form: FormGroup;
  activeIndex: number;
  numberOfItems: number;
  days: number[];
  activeDay: number;
  // enums
  AirTreatmentConstants = AirTreatmentConstants;
  GlobalConstants = GlobalConstants;

  constructor(
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
    private dialogsService: DialogsService,
  ) {
  }

  initForm(items: IAirTreatmentView[]): void {
    this.form = this.formBuilder.group({
      items: this.formBuilder.array(items.map((item) => {
        return this.getItemFG(item);
      })),
    });

    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues: formValues.items,
          isValid: this.form.valid,
        });
      }),
    );
  }

  updateFormValues(items: IAirTreatmentView[]): void {
    this.form.patchValue({
      items,
    });
  }

  addEmptyRow(): void {
    const itemsArr = this.getFormItems();
    const day = itemsArr.value?.length > 0
      ? Math.max(...itemsArr.value.map(x => x.day)) + 1
      : 0;

    itemsArr.push(this.getItemFG({
      controllerID: this.controllerID,
      day: day,
      humidity: 0,
      co2: 0,
      ammonia: 0,
    }));
    this.numberOfItems += 1;
    this.setActiveDay(day);
  }

  async removeRow(): Promise<void> {
    if (this.activeIndex == null) {
      return;
    }

    const itemsArr = this.getFormItems();
    const currentItem = itemsArr.at(this.activeIndex).value;

    const title = 'AirTreatment.DeleteDay';
    const message = 'AirTreatment.DeleteDayMessage';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE], 'flash/warning',
    );

    if (!result || result === DialogButtonEnum.CANCEL) {
      return;
    }

    this.deleteDay.emit(currentItem.day);
    itemsArr.removeAt(this.activeIndex);

    if (this.platformService.isMobileApp && itemsArr.value?.length > 0) {
      this.activeIndex = 0;
      this.activeDay = itemsArr.at(0).value.day;
    } else {
      this.activeIndex = null;
    }

    this.numberOfItems -= 1;
    this.checkDuplicacy();
  }

  setActiveIndex(index: number): void {
    this.activeIndex = index;
  }

  getFormItems(): FormArray<any> {
    return (this.form.controls['items'] as FormArray);
  }

  changeDay(day: number): void {
    if (typeof day != 'number') {
      return;
    }
    this.activeDay = day;
    this.checkDuplicacy();
  }

  checkDuplicacy(): void {
    const itemsArr = this.getFormItems();
    itemsArr.controls.forEach((x) => {
      (x as FormGroup).get('day').updateValueAndValidity();
    });
  }

  // Mobile
  setActiveDay(day: number): void {
    this.activeDay = day;
    this.activeIndex = this.getFormItems().controls.findIndex(x => x.value.day == day);
  }

  addDay(): void {
    this.addEmptyRow();
  }

  getActiveFormGroup(activeDay: number): AbstractControl {
    return this.getFormItems().controls.find(x => x.value.day == activeDay);
  }

  private getItemFG(item: IAirTreatmentView): FormGroup {
    return new FormGroup(
      {
        controllerID: new FormControl<number>(item.controllerID),
        day: new FormControl<number>(item.day, [
          Validators.required,
          Validators.min(AirTreatmentConstants.MinDay),
          Validators.max(AirTreatmentConstants.MaxDay),
          this.validateUniqueDay(),
        ]),
        humidity: new FormControl<number>(item.humidity, [
          Validators.required,
          Validators.min(AirTreatmentConstants.MinPercentage),
          Validators.max(AirTreatmentConstants.MaxPercentage),
        ]),
        co2: new FormControl<number>(item.co2, [
          Validators.required,
          Validators.min(AirTreatmentConstants.MinPPM),
          Validators.max(AirTreatmentConstants.MaxPPM),
        ]),
        ammonia: new FormControl<number>(item.ammonia, [
          Validators.required,
          Validators.min(AirTreatmentConstants.MinPercentage),
          Validators.max(AirTreatmentConstants.MaxPercentage),
        ]),
      },
    );
  }

  private validateUniqueDay(): any {
    return (control: AbstractControl) => {
      if (control.value != null) {
        const formArray = control.parent
          ? (control.parent.parent as FormArray)
          : null;
        if (formArray) {
          const days = formArray.value.map((x) => x.day);
          return days.filter(x => x == control.value).length > 1
            ? { duplicatedDay: true }
            : null;
        }
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
