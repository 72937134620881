import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  SensorsKeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ColorsEnum, ElementTypesEnum, StorageItem } from '@livestock/shared/enums';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  LanguageService,
  LocalStorageService,
  PlatformService,
  SwiperService,
} from '@livestock/shared/services';
import {
  addSensorsToMapping,
  changeActiveElementIsAverage,
  getLightingMappings,
  setActiveMapping,
  updateLightingMappings,
} from '../+state/lighting-mapping.actions';
import { ILightingMappingItem } from '../interfaces/lighting-mapping-item.interface';
import {
  selectActiveMapping, selectIsDirtyForm,
  selectIsLoading,
  selectLightingMappings,
  selectLightingMappingsSensorsList,
} from '../+state/lighting-mapping.selectors';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { IElement } from '@livestock/installation/interfaces';
import { LightingMappingHelpersService } from '../services/lighting-mapping-helpers.service';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { ActivatedRoute, Router } from '@angular/router';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { PageWrapperComponent } from '@livestock/ui';
import { SwiperDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-lighting-mapping',
  templateUrl: 'lighting-mapping.component.html',
  styleUrls: ['lighting-mapping.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
    MatCheckboxModule,
    SensorsKeyboardComponent,
    ButtonComponent,
    SwiperModule,
    PageWrapperComponent,
    SwiperDirective,
  ],
})
export class LightingMappingComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;

  //SUBS
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  lightingMappings$: Observable<ILightingMappingItem[]> = this.store.select(selectLightingMappings);
  activeMapping$: Observable<ILightingMappingItem> = this.store.select(selectActiveMapping);
  sensors$: Observable<IElement[]> = this.store.select(selectLightingMappingsSensorsList);
  isDirtyForm$: Observable<boolean> = this.store.select(selectIsDirtyForm);

  //vars
  editMode: boolean;
  controllerID: number;
  activeIndex: number = 0;

  //enums
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  StorageItem = StorageItem;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    public swiperService: SwiperService,
    private store: Store,
    private lightingMappingHelpersService: LightingMappingHelpersService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ProgramMenuSlide)) || 0,
      centeredSlides: true,
      allowTouchMove: false,
    };
  }

  ngOnInit(): void {
    this.store.dispatch(getLightingMappings());

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
      }),
    );
  }

  goBack(): void {
    this.router.navigate([AppRoutes.CONTROLLER, this.controllerID, ControllerRoutes.LightingPrograms]);
  }

  async toggleEditMode(): Promise<void> {
    const isDirtyForm = await firstValueFrom(this.isDirtyForm$);
    if (isDirtyForm) {
      const confirmationResult = await this.lightingMappingHelpersService.confirmUnsavedChanges();
      if (!confirmationResult) {
        return;
      }
    }

    this.store.dispatch(setActiveMapping({ lightElementID: null }));
    this.editMode = !this.editMode;
  }

  selectRow(mapping: ILightingMappingItem): void {
    if (!this.editMode) return;
    this.store.dispatch(setActiveMapping({ lightElementID: mapping.lightElementID }));
  }

  changeIsAverage(event: MatCheckboxChange, lightElementID: number): void {
    this.store.dispatch(changeActiveElementIsAverage({
      isAverage: event.checked,
      lightElementID,
    }));
  }

  saveSensors(sensors: IElement[]): void {
    this.store.dispatch(addSensorsToMapping({ sensors }));
  }

  unsetActiveMapping(): void {
    this.store.dispatch(setActiveMapping({ lightElementID: null }));
  }

  save(): void {
    this.unsetActiveMapping();
    this.store.dispatch(updateLightingMappings());
  }

  moveToNextStage(): void {
    if (!this.editMode) return;
    this.unsetActiveMapping();
  }

  changeActiveSlide(ev): void {
    this.activeIndex = ev[0].realIndex;
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, this.activeIndex);
  }

  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }

  trackBy(index: number): number {
    return index;
  }

  async canDeactivate(): Promise<boolean> {
    return !(await firstValueFrom(this.isDirtyForm$));
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
    this.unsetActiveMapping();
    this.sub$.unsubscribe();
  }
}
