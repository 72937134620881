<section
  [class.mobile-styles]="platformService.isMobileApp"
  [class.rtl]="languageService.isRtl"
  class="page-content">
  <div
    *ngIf="platformService.isMobileApp"
    class="d-flex flex-row justify-between mt-15 mb-15">
    <span class="flock-title">
      <ng-container [ngSwitch]="currStep">
        <ng-container *ngSwitchCase="FlockWizardStepsEnum.Settings">
          {{ "Flock.NewFlock.FlockSettings" | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="FlockWizardStepsEnum.HouseModes">
          {{ "Flock.NewFlock.HouseModes" | translate }}
        </ng-container>
        <ng-container *ngSwitchCase="FlockWizardStepsEnum.BirdWeight">
          {{ "Flock.NewFlock.BirdWeight" | translate }}
        </ng-container>
      </ng-container>
    </span>
    <ls-svg-icon
      (click)="cancel()"
      [path]="'flock/close'"
      class="close-icon pointer qa-btn-close">
    </ls-svg-icon>
  </div>
  <ls-stepper
    [currStep]="currStep"
    [labels]="
      !platformService.isMobileApp
        ? ['Flock.NewFlock.FlockSettings', 'Flock.NewFlock.HouseModes', 'Flock.NewFlock.BirdWeight']
        : []
    "
    [steps]="3"
    class="stepper">
  </ls-stepper>
  <div class="settings d-flex flex-row">
    <ng-container *ngIf="currStep === FlockWizardStepsEnum.Settings">
      <ls-flock-settings-form
        (changed)="changedSettings($event)"
        [isNewFlock]="true"
        [editMode]="true"
        [settings]="settings"
        class="settings__form">
        <div
          *ngTemplateOutlet="actionButtons"
          class="buttons-content"></div>
      </ls-flock-settings-form>
    </ng-container>

    <ng-container *ngIf="currStep === FlockWizardStepsEnum.HouseModes">
      <ls-flock-house-mode-form
        (changed)="changedHouseMode($event)"
        [editMode]="true"
        [houseMode]="houseMode"
        [temperatureUnit]="temperatureUnit$ | async"
        [ventilationWorkingMode]="ventilationWorkingMode"
        class="settings__form">
        <div
          *ngTemplateOutlet="actionButtons"
          class="buttons-content"></div>
      </ls-flock-house-mode-form>
    </ng-container>

    <ng-container *ngIf="currStep === FlockWizardStepsEnum.BirdWeight">
      <div class="settings__form bird-weight d-flex flex-row flex-column-md w-100-percent">
        <ng-container *ngIf="!(isDefaultWeightLoading$ | async); else loading">
          <ls-flock-bird-weight-settings-form
            (changed)="changedBirdWeightSettings($event)"
            [birdWeightSettings]="birdWeightSettings"
            [editMode]="true"
            [isLoading]="isLoading$ | async"
            [refWeight]="refWeight$ | async"
            [separateMaleAndFemale]="settings?.separateMaleAndFemale"
            [weightUnit]="weightUnit$ | async"
            class="settings__form-bird-weight-settings">
            <div
              *ngIf="!platformService.isMobileApp"
              class="buttons-content left-row">
              <ls-button
                (click)="cancel()"
                [qaTags]="'qa-btn-cancel'"
                [type]="'secondary'"
                class="h-button-36">
                {{ "Buttons.Cancel" | translate }}
              </ls-button>
            </div>
          </ls-flock-bird-weight-settings-form>
          <ls-flock-bird-weight-form
            (activeDayChanged)="activeDayChanged($event)"
            (changed)="changedBirdWeight($event)"
            [weightUnit]="weightUnit$ | async"
            [birdWeightItems]="birdWeightItems"
            [defaultWeightItems]="defaultWeightItems$ | async"
            [referenceTable]="referenceTable$ | async"
            [editMode]="true"
            [isLoading]="isLoading$ | async"
            [separateMaleAndFemale]="settings?.separateMaleAndFemale"
            [weightMethod]="birdWeightSettings?.weightMethod"
            class="settings__form-bird-weight">
            <div
              *ngTemplateOutlet="actionButtons"
              class="buttons-content"></div>
          </ls-flock-bird-weight-form>
        </ng-container>
      </div>
    </ng-container>

    <ls-keyboard
      *ngIf="!platformService.isMobileApp"
      [defaultMode]="KeyboardModeEnum.NumericOnly"
      class="keyboard ml-20" />
  </div>
</section>

<ng-template #actionButtons>
  <div class="buttons-content w-100-percent align-center">
    <ls-button
      *ngIf="
        (platformService.isMobileApp && currStep === FlockWizardStepsEnum.Settings) ||
        (!platformService.isMobileApp && currStep !== FlockWizardStepsEnum.BirdWeight)
      "
      (click)="cancel()"
      [class.w-button-130]="platformService.isMobileApp"
      [qaTags]="'qa-btn-cancel'"
      [type]="'secondary'"
      class="mr-20 h-button-36">
      {{ "Buttons.Cancel" | translate }}
    </ls-button>
    <ls-button-with-icon
      *ngIf="currStep > FlockWizardStepsEnum.Settings"
      (click)="back()"
      [buttonType]="'secondary'"
      [class.w-button-130]="platformService.isMobileApp"
      [iconPath]="'button-icons/left-arrow-green'"
      [qaTags]="'qa-btn-back'"
      class="mr-20 h-button-36"
      textContent="Buttons.Back">
    </ls-button-with-icon>
    <ls-button-with-icon
      *ngIf="currStep < FlockWizardStepsEnum.BirdWeight"
      (click)="next()"
      [buttonType]="'primary'"
      [class.disabled]="!isValid"
      [class.w-button-130]="platformService.isMobileApp"
      [iconPath]="'button-icons/right-arrow-white'"
      [location]="'after'"
      [qaTags]="'qa-btn-next'"
      class="h-button-36"
      textContent="Buttons.Next">
    </ls-button-with-icon>
    <ls-button
      *ngIf="currStep === FlockWizardStepsEnum.BirdWeight"
      (click)="save()"
      [class.disabled]="!isValid || !isValidWeight"
      [class.w-button-130]="platformService.isMobileApp"
      [qaTags]="'qa-btn-finish'"
      [type]="'primary'"
      class="h-button-36">
      {{ "Buttons.Finish" | translate }}
    </ls-button>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
