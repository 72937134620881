import { Component, Input, OnDestroy, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  HeaderTitleComponent,
  LoadingComponent,
  SensorsKeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import {
  AddRemoveBrooding2Enum,
  Brooding2DialogComponent,
  changeActiveElementIsAverage,
  setActiveElement,
  TempMappingCommonComponent,
  TempMappingHelpersService,
  TempMappingStagesEnum,
  tempMappingStagesToTitle,
} from '@livestock/temp-mapping';
import { EnumToArrayPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { Store } from '@ngrx/store';
import { DialogsService, LanguageService, LocalStorageService, SwiperService } from '@livestock/shared/services';
import { IElement } from '@livestock/installation/interfaces';
import { ElementTypesEnum, StorageItem } from '@livestock/shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { SwiperDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-temp-mapping-mobile',
  templateUrl: 'temp-mapping-mobile.component.html',
  styleUrls: ['temp-mapping-mobile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderTitleComponent,
    TranslateModule,
    SvgIconComponent,
    MatCheckboxModule,
    SwiperModule,
    MemoizeFuncPipe,
    ButtonComponent,
    SensorsKeyboardComponent,
    LoadingComponent,
    EnumToArrayPipe,
    SwiperDirective,
  ],
})
export class TempMappingMobileComponent extends TempMappingCommonComponent implements OnDestroy {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  tempMappingStagesToTitle = tempMappingStagesToTitle;
  activeElementIndex: number = 0;
  sensorKeyboardIsActive: boolean;
  StorageItem = StorageItem;

  //vars
  private _editMode: boolean;

  get editMode(): boolean {
    return this._editMode;
  }

  @Input() set editMode(editMode: boolean) {
    this._editMode = editMode;
    this.hideSensorKeyboard();
  }

  constructor(
    store: Store,
    translateService: TranslateService,
    dialogsService: DialogsService,
    tempMappingHelpersService: TempMappingHelpersService,
    public languageService: LanguageService,
    public swiperService: SwiperService,
    private dialog: MatDialog,
  ) {
    super(store, translateService, dialogsService, tempMappingHelpersService);
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ProgramMenuSlide)) || 0,
      centeredSlides: true,
      allowTouchMove: false,
    };
  }

  changeActiveElementIndex([{ realIndex }]): void {
    this.activeElementIndex = realIndex;
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, realIndex);

    if (this.editMode) {
      this.hideSensorKeyboard();
      const elementID = this.getCurrentElementID();
      if (!elementID) return;
      this.store.dispatch(setActiveElement({ activeStage: this.activeStage, elementID }));
    }
  }

  override addBrooding2Stage(): void {
    super.addBrooding2Stage();
    setTimeout(() => this.swiper.swiperRef.slideTo(TempMappingStagesEnum.Brooding2, 0));
  }

  override changeActiveStage(activeStage: TempMappingStagesEnum): void {
    super.changeActiveStage(activeStage);
    setTimeout(() => {
      this.activeElementIndex = 0;
      this.swiper.swiperRef.slideTo(0, 0);
    });
  }

  override changeIsAverage(event: MatCheckboxChange, index: number, elementID: number = null): void {
    this.store.dispatch(changeActiveElementIsAverage({
      activeStage: this.activeStage,
      isAverage: event.checked,
      elementID,
    }));
  }

  override async moveElement(): Promise<void> {
    switch (this.activeStage) {
      case TempMappingStagesEnum.Brooding1:
        this.activeZoneID = this.brooding1HeatingZones[this.activeElementIndex]?.zoneID;
        this.activeElementID = this.brooding1ElementMappings[this.activeElementIndex - this.brooding1HeatingZones.length]?.elementID;
        break;
      case TempMappingStagesEnum.Brooding2:
        this.activeZoneID = this.brooding2HeatingZones[this.activeElementIndex]?.zoneID;
        this.activeElementID = this.brooding2ElementMappings[this.activeElementIndex - this.brooding2HeatingZones.length]?.elementID;
        break;
      default:
        return;
    }
    super.moveElement();
  }

  override saveSensors(sensors: IElement[]): void {
    this.activeElementID = this.getCurrentElementID();
    super.saveSensors(sensors);
  }

  getCurrentElementID(): number {
    switch (this.activeStage) {
      case TempMappingStagesEnum.Brooding1:
        return this.brooding1ElementMappings[this.activeElementIndex - this.brooding1HeatingZones.length]?.elementID;
      case TempMappingStagesEnum.Brooding2:
        return this.brooding2ElementMappings[this.activeElementIndex - this.brooding2HeatingZones.length]?.elementID;
      default:
        return null;
    }
  }

  activateSensorKeyboard(): void {
    const elementID = this.getCurrentElementID();

    if (!elementID
      && ![TempMappingStagesEnum.FullHouse, TempMappingStagesEnum.Brooding1, TempMappingStagesEnum.Brooding2].includes(this.activeStage)
    ) return;

    if (this.activeStage === TempMappingStagesEnum.Brooding2 && this.brooding2ElementMappings.length === 0) return;

    this.store.dispatch(setActiveElement({ activeStage: this.activeStage, elementID }));
    this.sensorKeyboardIsActive = true;
  }

  hideSensorKeyboard(): void {
    this.sensorKeyboardIsActive = false;
  }

  getHeatingElementType(): ElementTypesEnum {
    switch (this.activeStage) {
      case TempMappingStagesEnum.Brooding1:
        if (this.brooding1HeatingZones.length === 0) {
          return null;
        }
        return this.brooding1HeatingZones[this.activeElementIndex]?.heatingElements[0]?.elementType;
      case TempMappingStagesEnum.Brooding2:
        if (this.brooding2HeatingZones.length === 0) {
          return null;
        }
        return this.brooding2HeatingZones[this.activeElementIndex]?.heatingElements[0]?.elementType;
      default:
        return null;
    }
  }

  async openBrooding2Popup(): Promise<void> {
    const dialogRef = this.dialog.open(Brooding2DialogComponent, {
      width: '320px',
    });
    dialogRef.componentInstance.setup(this.brooding2IsShown);
    const result = await lastValueFrom(dialogRef.afterClosed());

    switch (result) {
      case AddRemoveBrooding2Enum.Add:
        this.addBrooding2Stage();
        return;
      case AddRemoveBrooding2Enum.Remove:
        this.removeBrooding2Stage();
        return;
      default:
        return;
    }
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
  }
}
