import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetAddControllerTicketView } from '@livestock/controllers';

export const getAddControllerTicketView = createAction(
  '[LOGIN_SCANNED] Get Add Controller Ticket View [...]',
  props<{ ticketId: string; isReconnect?: boolean }>(),
);

export const getAddControllerTicketViewSuccess = createAction(
  '[LOGIN_SCANNED] Get Add Controller Ticket View [SUCCESS]',
  props<{ ticketView: IGetAddControllerTicketView; }>(),
);

export const getAddControllerTicketViewError = createAction(
  '[LOGIN_SCANNED] Get Add Controller Ticket View [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);
