import {
  AfterViewInit,
  Component,
  ElementRef, HostListener,
  Input, OnDestroy, OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { FormsModule, NgControl, NgModel } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import {
  selectCurrentKeyboardUUID,
  selectKeyboardValueAndUUID,
} from '../keyboard/+state/keyboard.selectors';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import {
  clearFormControlInputInfo,
  clearKeyboardValue,
  setElementUuid,
  setKeyboardMode,
} from '../keyboard/+state/keyboard.actions';
import { Store } from '@ngrx/store';
import { GlobalConstants } from '@livestock/shared/constants';
import { PlatformService } from '@livestock/shared/services';
import { SleepUtils } from '@livestock/shared/utils';
import { KeyboardEnum } from '@livestock/shared/enums';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { KeyboardModeEnum } from '@livestock/ui';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-input-text',
  templateUrl: './input-text.component.html',
  styleUrls: [
    '../input-integer/input-integer.component.scss',
    './input-text.component.scss',
  ],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
  ],
  providers: [
    NgModel,
  ],
})
export class InputTextComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @Input() fieldWithKeyboard = false;
  @Input() formControlName: string;
  @Input() placeholder = '';
  @Input() minLength: number = GlobalConstants.MinLength;
  @Input() maxLength: number = GlobalConstants.MaxLength;
  @Input() readonly: boolean;
  @Input() disabled: boolean;
  @Input() centered: boolean;
  @Input() inputType: 'text' | 'password' = 'text';
  sub$ = new Subscription();
  value: string;

  @HostListener('input', ['$event'])
  onInput(e: InputEvent): void {
    const target: HTMLInputElement = e.target as HTMLInputElement;
    this.value = target.value;
    this.updateInputValue();
    this.valueChange();
  }

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    if (this.fieldWithKeyboard && !this.platformService.isMobileApp) {
      this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.Full }));
      this.store.dispatch(setElementUuid({
        elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
      }));
    }
  }

  // hide keyboard if clicked outside input
  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    await SleepUtils.sleep(100);
    const activeFormControl = await firstValueFrom(this.store.select(selectCurrentKeyboardUUID));

    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.store.dispatch(clearFormControlInputInfo());
    }
  }

  constructor(
    private store: Store,
    private platformService: PlatformService,
    private control: NgControl,
    @Optional()
    private model: NgModel,
  ) {
    control.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.fieldWithKeyboard && !this.platformService.isMobileApp) {
      this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.Full }));
      this.sub$.add(
        this.store.select(selectCurrentKeyboardUUID).pipe(
          wasChanged(),
          filter((uuid) => uuid && uuid === this.inputRef?.nativeElement.getAttribute('uuid')),
        ).subscribe(() => {
          setTimeout(() => {
            this.inputRef.nativeElement.focus();
          });
        }),
      );

      this.sub$.add(
        this.store.select(selectKeyboardValueAndUUID)
          .pipe(
            wasChanged(),
            filter(({ symbol, elementUuid }) =>
              symbol != null && !symbol.toString().includes(KeyboardEnum.Enter) && !([KeyboardEnum.AM, KeyboardEnum.PM] as string[]).includes(symbol?.toString()) && elementUuid === this.inputRef?.nativeElement.getAttribute('uuid')),
          ).subscribe(({ symbol }) => {
          const { selectionStart, selectionEnd } = this.inputRef.nativeElement;
          const isSingleDeletion = selectionStart === selectionEnd && symbol === '' && selectionStart > 0;
          const isMultipleDeletion = symbol === '' && selectionStart !== selectionEnd;

          // do nothing if cursor is in the beginning and we press BACKSPACE button
          if (symbol === '' && selectionStart === selectionEnd && selectionStart === 0) {
            this.store.dispatch(clearKeyboardValue());
            return;
          }

          const currentValue = (this.value || '').toString().split('');

          if (isSingleDeletion) {
            // remove the symbol before the cursor
            currentValue.splice(selectionStart - 1, 1);
          } else {
            // add symbol in the right place or remove multiple symbols
            currentValue.splice(selectionStart, selectionEnd - selectionStart, symbol as string);
          }

          this.value = currentValue.join('');
          this.updateInputValue();
          this.valueChange();
          this.store.dispatch(clearKeyboardValue());

          // set cursor for prev place for single deletion
          if (isSingleDeletion) {
            this.inputRef.nativeElement.selectionStart = selectionStart - 1;
            this.inputRef.nativeElement.selectionEnd = selectionStart - 1;
            return;
          }

          // do not move cursor for multiple deletion
          if (isMultipleDeletion) {
            this.inputRef.nativeElement.selectionStart = selectionStart;
            this.inputRef.nativeElement.selectionEnd = selectionStart;
            return;
          }

          // set cursor on next place after adding new symbol
          this.inputRef.nativeElement.selectionStart = selectionStart + 1;
          this.inputRef.nativeElement.selectionEnd = selectionStart + 1;
        }),
      );

    }
  }

  ngAfterViewInit(): void {
    this.updateInputValue();
  }

  updateInputValue(): void {
    if (!this.inputRef || this.value == null) return;
    this.inputRef.nativeElement.value = this.value;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  valueChange(value?): void {
    const newValue = value || this.value;
    this.onChange(newValue);
  }

  writeValue(value: string): void {
    if (this.value === value) return;
    this.value = value;
    this.updateInputValue();
  }

  setValue(event): void {
    this.onChange(event.target.value);
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}
