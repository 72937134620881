<section
  *ngIf="!isLoading && form; else loading"
  [class.rtl]="languageService.isRtl"
  class="d-flex flex-column settings h-100-percent">
  <div
    [formGroup]="form"
    class="h-100-percent">
    <div class="temperature-info d-flex justify-end mx-auto w-100-percent mb-20 mb-40-md">
      <div class="mb-8-md d-flex mr-20">
        <span class="temperature-info__label">{{ "TemperatureCurve.DailyTargetTemp" | translate }}</span>
        &nbsp;
        <span class="fw-700"> 23{{ temperatureUnitEnum | enum : "temperature-unit-short-enum" | translate }} </span>
      </div>
      <div class="d-flex">
        <span class="temperature-info__label">{{ "TemperatureCurve.CurrentTemp" | translate }}</span>
        &nbsp;
        <span class="fw-700"> 25{{ temperatureUnitEnum | enum : "temperature-unit-short-enum" | translate }} </span>
      </div>
    </div>
    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-offset'">{{ "TemperatureCurve.CurveOffset" | translate }}</label>
      <div class="d-flex align-center w-mc">
        <ls-input-decimal
          [accuracy]="1"
          [class.disabled]="!editMode"
          [fieldWithKeyboard]="true"
          [max]="MAX_VALUE"
          [min]="MIN_VALUE"
          [qaTags]="'qa-txt-offset'"
          errorField
          formControlName="curveOffset"></ls-input-decimal>
        <ng-container
          *ngTemplateOutlet="
            unitTemplate;
            context: { mobileApp: platformService.isMobileApp, mobileAppLabel: 'TemperatureCurve.Min' }
          "></ng-container>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-temp-diff'">{{ "TemperatureCurve.TargetTempDiff" | translate }}</label>
      <div class="d-flex align-center w-mc">
        <ls-input-decimal
          [accuracy]="1"
          [class.disabled]="!editMode"
          [fieldWithKeyboard]="true"
          [max]="MAX_VALUE"
          [min]="MIN_VALUE"
          [qaTags]="'qa-txt-temp-diff'"
          errorField
          formControlName="comfortZone"></ls-input-decimal>
        <ng-container *ngTemplateOutlet="unitTemplate"></ng-container>
      </div>
    </div>
  </div>

  <ng-content
    class="d-flex align-end justify-end"
    select=".buttons-content"></ng-content>
</section>

<ng-template #unitTemplate>
  <span class="unit ml-5 color-tertiary">
    {{ temperatureUnitEnum | enum : "temperature-unit-short-enum" | translate }}
  </span>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
