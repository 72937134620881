import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'ls-no-search-results',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './no-search-results.component.html',
  styleUrls: ['./no-search-results.component.scss'],
})
export class NoSearchResultsComponent {
  @Input() searchResult: string;
  @Input() fzClass: string = 'fz-24';
  @Input() mbClass: string = 'mb-35';
  @Input() imgClass: string = '';
}
