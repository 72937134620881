import { Component } from '@angular/core';
import { MainWidgetCommonComponent } from '../main-widget-common.component';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-main-bird-widget',
  templateUrl: './main-bird-widget.component.html',
  styleUrls: ['./main-bird-widget.component.scss'],
})
export class MainBirdWidgetComponent extends MainWidgetCommonComponent {
  isGraphOpened = false;

  openGraph(): void {
    this.isGraphOpened = true;
  }
}
