import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  Component,
  ElementRef, EventEmitter,
  forwardRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit, Output,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { errFade } from '@livestock/shared/animations';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  selectVirtualKeyboardElementUUID,
  selectVirtualKeyboardSymbolAndUUID,
  setVirtualKeyboardElementUuid,
  setVirtualKeyboardMode,
  setVirtualKeyboardSymbol,
  VirtualKeyboardModesEnum,
  VirtualKeyboardButtonsEnum,
} from '@livestock/ui';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'lv-input-date',
  templateUrl: './lv-input-date.component.html',
  styleUrls: ['./lv-input-date.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    TranslateModule,
    NativeElementInjectorDirective,
  ],
  animations: [
    errFade,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => LvInputDateComponent),
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => LvInputDateComponent),
  }],
})
export class LvInputDateComponent implements ControlValueAccessor, Validator, OnInit, AfterViewInit, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('rightButton', { static: false }) rightButton: ElementRef = new ElementRef(null);

  @Input() label: string = '';
  @Input() isImperial: boolean;
  @Input() placeholder: string = GlobalConstants.USA_Date_placeholder;
  @Input() textPosition: 'left' | 'center' | 'right' = 'left';
  @Input() isDisabled: boolean = false;
  @Input() qaTags: string;

  @Output() onFocusOutWithError = new EventEmitter();

  sub$ = new Subscription();
  control: AbstractControl = new FormControl();
  isRightButtonExists: boolean = false;
  maskSymbol = '_';
  mask = GlobalConstants.Input_date_mask;
  keyboardMode = VirtualKeyboardModesEnum.Numeric;

  GlobalConstants = GlobalConstants;
  monthsWith30Days = ['04', '06', '09', '11'];
  isTouched: boolean;

  protected _value: string;

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    this.isTouched = true;

    this.store.dispatch(setVirtualKeyboardElementUuid({
      elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
    }));

    this.store.dispatch(setVirtualKeyboardMode({ mode: this.keyboardMode }));

    if (!this.value) {
      this.inputRef.nativeElement.value = this.mask;
      setTimeout(() => {
        this.inputRef.nativeElement.selectionStart = 0;
        this.inputRef.nativeElement.selectionEnd = 0;
      });
    }
  }

  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    const activeFormControl = await firstValueFrom(this.store.select(selectVirtualKeyboardElementUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.saveValue();
      this.store.dispatch(setVirtualKeyboardElementUuid({
        elementUuid: null,
      }));
    }
  }

  constructor(
    public languageService: LanguageService,
    protected store: Store,
  ) {
  }

  @Input('value')
  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectVirtualKeyboardElementUUID).pipe(
        wasChanged(),
        filter(res => res == null),
      ).subscribe(() => {
        if (!this.isTouched) return;

        this.inputRef?.nativeElement.blur();
        this.saveValue();
      }),
    );

    this.sub$.add(
      this.store.select(selectVirtualKeyboardSymbolAndUUID).pipe(
        wasChanged(),
        filter(({ symbol, elementUuid }) => {
          return symbol != null && elementUuid === this.inputRef?.nativeElement.getAttribute('uuid');
        }),
      ).subscribe(({ symbol }) => {
        if (symbol === VirtualKeyboardButtonsEnum.DEL) {
          this.removeSymbol();
          this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
          return;
        }

        this.printSymbol(symbol as string);
        this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
      }),
    );
  }

  printSymbol(symbol: string): void {
    const { selectionStart, value } = this.inputRef.nativeElement;
    let nextStep: number;
    const valueArray: string[] = value.split('');

    if (![2, 5, 10].includes(selectionStart)) {
      valueArray.splice(selectionStart, 1, symbol as string);
    }

    switch (selectionStart) {
      case 0:
      case 2:
      case 3:
      case 5:
      case 6:
      case 7:
      case 8:
      case 9:
        nextStep = selectionStart + 1;
        break;
      case 1:
      case 4:
        nextStep = selectionStart + 2;
        break;
      default:
        break;
    }

    const updatedValue = valueArray.join('');
    this.inputRef.nativeElement.value = this.transformDate(updatedValue);
    this.inputRef.nativeElement.selectionStart = nextStep || selectionStart;
    this.inputRef.nativeElement.selectionEnd = nextStep || selectionStart;
  }

  removeSymbol(): void {
    const { selectionStart, value } = this.inputRef.nativeElement;
    const valueArray: string[] = value.split('');

    if (![0, 3, 6].includes(selectionStart)) {
      valueArray.splice(selectionStart - 1, 1, this.maskSymbol);
    }

    const nextStep = selectionStart === 0 ? 0 : selectionStart - 1;

    const updatedValue = valueArray.join('');
    this.inputRef.nativeElement.value = this.transformDate(updatedValue);
    this.inputRef.nativeElement.selectionStart = nextStep ?? selectionStart;
    this.inputRef.nativeElement.selectionEnd = nextStep ?? selectionStart;
  }

  transformDate(value: string): string {
    let month = this.isImperial ? value.slice(0, 2) : value.slice(3, 5);
    let day = this.isImperial ? value.slice(3, 5) : value.slice(0, 2);
    let year = value.slice(6);
    const isLeapYear = !!year.includes(this.maskSymbol) || +year % 4 === 0;

    // set 12 if user printed 13 and more, set 01 if user printed 00
    if (!month.includes(this.maskSymbol)) {
      month = +month > 12 ? '12' : month;
      month = month === '00' ? '01' : month;
    }

    // for 31-day months: print 31 if user printed 32 and more
    // for 30-day months: print 30 if user printed 31 and more
    // for February: print 28 or 29 if user printed 28/29 and more (depending on leap year)
    // 00 => 01
    if (!day.includes(this.maskSymbol)) {
      day = +day > 31 ? '31' : day;
      day = day === '00' ? '01' : day;
      day = this.monthsWith30Days.includes(month) && +day > 30
        ? '30'
        : day;

      if (month === '02' && isLeapYear) {
        day = +day > 29 ? '29' : day;
      }

      if (month === '02' && !isLeapYear) {
        day = +day > 28 ? '28' : day;
      }
    }

    // min year is current year, max is 9999
    if (!year.includes(this.maskSymbol)) {
      const currentYear = new Date().getFullYear();
      year = +year < currentYear ? `${currentYear}` : year;
    }

    return this.isImperial
      ? `${month}.${day}.${year}`
      : `${day}.${month}.${year}`;
  }

  saveValue(): void {
    if (!this.inputRef?.nativeElement) return;

    // reset to default if was not filled properly
    const { value } = this.inputRef.nativeElement;
    if (!value.includes(this.maskSymbol)) {
      this._value = value;
      this.propagateChange(value);
      return;
    }

    this.inputRef.nativeElement.value = this.mask;
    this._value = '';
    this.propagateChange('');
    this.onFocusOutWithError.emit();
  }

  ngAfterViewInit(): void {
    this.isRightButtonExists = this.rightButton?.nativeElement?.children?.length > 0;
  }

  writeValue(value: string): void {
    if (!value) {
      setTimeout(() => {
        this.inputRef.nativeElement.value = GlobalConstants.Input_date_mask;
      });
      return;
    }
    this._value = value;
  }

  propagateChange: (value: any) => void = () => {
  };

  _onTouched = (): void => {
  };

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => object): void {
    this._onTouched = fn;
  }

  focusInput(): void {
    this.inputRef.nativeElement.focus();
  }

  validate(): ValidationErrors | null {
    return null;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
