<ls-page-wrapper
  (back)="cancel()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'StaticPressure.Title'">
  <div
    *ngIf="!platformService.isMobileApp || !editMode"
    class="svg-content buttons d-flex align-center mt-10-md">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      class="pointer"></ls-svg-icon>

    <ls-svg-icon
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'installation/graph-mobile' : 'installation/graph'"
      class="pointer"></ls-svg-icon>

    <ls-svg-icon
      (click)="goToSettings()"
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'installation/settings-mobile' : 'installation/settings'"
      class="pointer"></ls-svg-icon>
  </div>

  <ls-static-pressure-form
    (closeEditMode)="toggleEditMode()"
    (isDirtyFormState)="setIsDirtyForm($event)"
    [class.mr-20]="editMode && !platformService.isMobileApp"
    [editMode]="editMode"
    class="main-content">
  </ls-static-pressure-form>

  <ls-keyboard
    *ngIf="editMode && !platformService.isMobileApp"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content ml-20">
  </ls-keyboard>
</ls-page-wrapper>
