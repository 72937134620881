import { createAction, props } from '@ngrx/store';
import { ICredentials } from '../interfaces/credentials.interface';
import { HttpErrorResponse } from '@angular/common/http';
import { QueryParamsHandling } from '@angular/router';

// LOG IN
export const login = createAction(
  '[AUTH] Log in',
  props<{ payload: ICredentials }>(),
);

export const loginSuccess = createAction(
  '[AUTH] Log in [SUCCESS]',
  props<{ token: string }>(),
);

export const loginError = createAction(
  '[AUTH] Log in [ERROR]',
  props<{ payload: string }>(),
  // TO DO - Need to change it on the backend side firstly
  // props<{ payload: HttpErrorResponse; }>(),
);

// LOG OUT
export const logOut = createAction(
  '[AUTH] Log out',
  (paramsHandling?: QueryParamsHandling) => ({ paramsHandling }),
);

export const resetLoginProcess = createAction('[AUTH] Reset login process');

export const clearAllIfUnAuthorised = createAction(
  '[AUTH] Clear All Entities When Unauthorised',
);

//TOKEN
export const refreshToken = createAction('[AUTH] Refresh token');

export const refreshTokenSuccess = createAction(
  '[AUTH] Refresh token [SUCCESS]',
);

export const refreshTokenError = createAction(
  '[AUTH] Refresh token [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
