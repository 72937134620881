import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as airTreatmentActions from './air-treatment.actions';
import * as airTreatmentSelectors from './air-treatment.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import {
    AirTreatmentService,
    IAirTreatmentListView,
    IAirTreatmentSettingsView,
    IAirTreatmentThresholdsView,
    IAirTreatmentView,
} from '@livestock/air-treatment';

@Injectable()
export class AirTreatmentEffects {

    getAirTreatmentItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.getAirTreatmentItems),
            map((action) => action.controllerID),
            switchMap((controllerID: number) => {
                return this.airTreatmentService.getAirTreatments(controllerID).pipe(
                    map((view: IAirTreatmentListView) =>
                        airTreatmentActions.getAirTreatmentItemsSuccess({ view })),
                    catchError((error) => of(airTreatmentActions.getAirTreatmentItemsError({ payload: error }))),
                );
            }),
        ),
    );

    updateAirTreatmentItems$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.updateAirTreatmentItems),
            map((action) => action.controllerID),
            withLatestFrom(
                this.store.select(airTreatmentSelectors.selectAirTreatmentItems),
            ),
            switchMap(([controllerID, items]: [number, IAirTreatmentView[]]) => {
                return this.airTreatmentService.updateAirTreatments(controllerID, items).pipe(
                    switchMap((updatedItems: IAirTreatmentView[]) => {
                        return [
                            airTreatmentActions.updateAirTreatmentItemsSuccess({ updatedItems }),
                            setFlashMessage({
                                flashMessage: {
                                    flashType: FlashMessageTypeEnum.Success,
                                    message: 'FlashMessages.AirTreatmentWasSuccessfullyUpdated',
                                },
                            }),
                        ];
                    }),
                    catchError((error) => of(airTreatmentActions.updateAirTreatmentItemsError({ payload: error }))),
                );
            }),
        ),
    );

    getAirTreatmentSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.getAirTreatmentSettings),
            map((action) => action.controllerID),
            switchMap((controllerID: number) => {
                return this.airTreatmentService.getAirTreatmentSettings(controllerID).pipe(
                    map((view: IAirTreatmentSettingsView) =>
                        airTreatmentActions.getAirTreatmentSettingsSuccess({ view })),
                    catchError((error) => of(airTreatmentActions.getAirTreatmentSettingsError({ payload: error }))),
                );
            }),
        ),
    );

    updateAirTreatmentSettings$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.updateAirTreatmentSettings),
            map((action) => action.controllerID),
            withLatestFrom(
                this.store.select(airTreatmentSelectors.selectAirTreatmentSettings),
            ),
            switchMap(([controllerID, view]: [number, IAirTreatmentSettingsView]) => {
                return this.airTreatmentService.updateAirTreatmentSettings(controllerID, view).pipe(
                    switchMap(() => {
                        return [
                            airTreatmentActions.updateAirTreatmentSettingsSuccess(),
                            setFlashMessage({
                                flashMessage: {
                                    flashType: FlashMessageTypeEnum.Success,
                                    message: 'FlashMessages.AirTreatmentSettingsWereSuccessfullyUpdated',
                                },
                            }),
                        ];
                    }),
                    catchError((error) => of(airTreatmentActions.updateAirTreatmentSettingsError({ payload: error }))),
                );
            }),
        ),
    );

    getAirTreatmentThresholds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.getAirTreatmentThresholds),
            map((action) => action.controllerID),
            switchMap((controllerID: number) => {
                return this.airTreatmentService.getAirTreatmentThresholds(controllerID).pipe(
                    map((view: IAirTreatmentThresholdsView) =>
                        airTreatmentActions.getAirTreatmentThresholdsSuccess({ view })),
                    catchError((error) => of(airTreatmentActions.getAirTreatmentThresholdsError({ payload: error }))),
                );
            }),
        ),
    );

    updateAirTreatmentThresholds$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.updateAirTreatmentThresholds),
            map((action) => action.controllerID),
            withLatestFrom(
                this.store.select(airTreatmentSelectors.selectAirTreatmentThresholds),
            ),
            switchMap(([controllerID, view]: [number, IAirTreatmentThresholdsView]) => {
                return this.airTreatmentService.updateAirTreatmentThresholds(controllerID, view).pipe(
                    map(() => {
                        return airTreatmentActions.updateAirTreatmentThresholdsSuccess();
                    }),
                    catchError((error) => of(airTreatmentActions.updateAirTreatmentThresholdsError({ payload: error }))),
                );
            }),
        ),
    );

    deleteAirTreatment$ = createEffect(() =>
        this.actions$.pipe(
            ofType(airTreatmentActions.deleteAirTreatment),
            switchMap(({ controllerID, day }) => {
                return this.airTreatmentService.deleteAirTreatment(controllerID, day).pipe(
                    map(() =>
                        airTreatmentActions.deleteAirTreatmentSuccess()),
                    catchError((error) => of(airTreatmentActions.deleteAirTreatmentError({ payload: error }))),
                );
            }),
        ),
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private airTreatmentService: AirTreatmentService,
    ) {
    }
}