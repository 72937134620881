import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CURRENT_USER_FEATURE_KEY, CurrentUserState } from './current-user.reducer';

export const getCurrentUserState =
  createFeatureSelector<CurrentUserState>(CURRENT_USER_FEATURE_KEY);

export const selectCurrentUser = createSelector(
  getCurrentUserState,
  (currentUser) => currentUser?.currentUser,
);

export const selectCurrentUserEmail = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.email,
);

export const selectCurrentUserInitials = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser && `${currentUser.firstName?.[0].toUpperCase()}${currentUser.lastName?.[0].toUpperCase()}`,
);

export const selectCurrentUserID = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.userID,
);

export const selectCurrentUserRole = createSelector(
  selectCurrentUser,
  (currentUser) => currentUser?.role,
);
