import { Action, createReducer, on } from '@ngrx/store';
import * as basicVentilationMinimumActions from './basic-ventilation-minimum.actions';
import { BasicVentilationMinimumState, IBasicVentilationMinimumItem, IFans } from '@livestock/basic-ventilation-minimum';

export const initialState: BasicVentilationMinimumState = {
  isLoading: false,
  canDeactivate: true,
  items: null,
  ventilationDOElements: null,
  ventilationAOElements: null,
  // settings
  isLoadingSettings: false,
  settings: null,
};

const initialValue: IBasicVentilationMinimumItem[] = [{
  basicVentilationID: null,
  dayNumber: null,
  onTime: null,
  offTime: null,
  fans: [{
    basicVentilationID: null,
    elementID: null,
    elementType: null,
    number: null,
    percentage: null,
  }],
  inlets: [{
    basicVentilationID: null,
    elementID: null,
    elementType: null,
    number: null,
  }],
}];

const reducer = createReducer(
  initialState,
  on(
    basicVentilationMinimumActions.getBasicVentilationMinimumItems,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    basicVentilationMinimumActions.updateBasicVentilationMinimumItems,
    (state) => {
      const items = [...state.items].map(item => ({
        ...item,
        fans: item.fans.map(fan => ({
          ...fan,
          percentage: fan.percentage,
        })),
        inlets: item.inlets?.length === 1 && item.inlets[0]?.elementID == null
          ? []
          : item.inlets,
      }));
      return {
        ...state,
        items,
        isLoading: true,
      };
    },
  ),
  on(
    basicVentilationMinimumActions.getBasicVentilationMinimumItemsError,
    basicVentilationMinimumActions.updateBasicVentilationMinimumItemsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.getBasicVentilationMinimumItemsSuccess,
    (state, { view }) => {
      return {
        ...state,
        items: view.items?.length ? view.items : initialValue,
        ventilationDOElements: view?.ventilationDOElements,
        ventilationAOElements: view?.ventilationAOElements,
        isLoading: false,
        canDeactivate: true,
      };
    },
  ),
  on(basicVentilationMinimumActions.updateBasicVentilationMinimumItemsSuccess,
    (state) => {
      return {
        ...state,
        isLoading: false,
        canDeactivate: true,
      };
    },
  ),
  on(basicVentilationMinimumActions.setFans,
    (state, { elements, selectedIndex }) => {
      return {
        ...state,
        isLoading: false,
        items: [...state.items].map((item, index) => ({
          ...item,
          fans: selectedIndex === index ? getFans(elements) : item.fans,
        })),
        canDeactivate: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.addNewItem,
    (state) => {
      const items = [...state.items, initialValue[0]];

      return {
        ...state,
        isLoading: false,
        items,
        canDeactivate: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.removeItem,
    (state, { selectedIndex }) => {
      return {
        ...state,
        isLoading: false,
        items: [...state.items]?.length === 1
          ? initialValue
          : [...state.items]?.filter((_, index) => index !== selectedIndex),
        canDeactivate: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.setBasicVentilationItems,
    (state, { items }) => {
      return {
        ...state,
        isLoading: false,
        items: items?.map((item, index) => ({
          ...item,
          fans: [...state.items]?.[index]?.fans,
        })),
        canDeactivate: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.setPercentage,
    (state, { currentItem, selectedIndex }) => {
      return {
        ...state,
        isLoading: false,
        items: [...state.items]?.map((item, index) => ({
          ...item,
          fans: item?.fans?.map(fan => ({
            ...fan,
            percentage: fan.elementID === currentItem.elementID && selectedIndex === index
              ? currentItem.percentage
              : fan.percentage,
          })),
        })),
        canDeactivate: false,
      };
    },
  ),

  // settings
  on(
    basicVentilationMinimumActions.getBasicVentilationSettings,
    basicVentilationMinimumActions.updateBasicVentilationSettings,
    (state) => {
      return {
        ...state,
        isLoadingSettings: true,
      };
    },
  ),
  on(
    basicVentilationMinimumActions.getBasicVentilationSettingsError,
    basicVentilationMinimumActions.updateBasicVentilationSettingsError,
    (state) => {
      return {
        ...state,
        isLoadingSettings: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.getBasicVentilationSettingsSuccess,
    (state, { settings }) => {
      return {
        ...state,
        settings,
        isLoadingSettings: false,
      };
    },
  ),
  on(basicVentilationMinimumActions.updateBasicVentilationSettingsSuccess,
    (state, { settings }) => {
      return {
        ...state,
        settings,
        isLoadingSettings: false,
      };
    },
  ),
);

export function basicVentilationMinimumReducer(state: BasicVentilationMinimumState | undefined, action: Action): any {
  return reducer(state, action);
}

// fans
export function getFans(elements: IFans[]): IFans[] {
  return elements?.length ? elements.map(el => ({
    elementID: el.elementID,
    number: el.number,
    elementType: el.elementType,
    percentage: el?.percentage,
  }))?.sort((a, b) => a.number - b.number) : [];
}
