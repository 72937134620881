import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Renderer2,
  SimpleChanges,
  SkipSelf,
  ViewChild,
  ViewRef,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { errFade } from '@livestock/shared/animations';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    QaTagsDirective,
  ],
  animations: [
    errFade,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => InputComponent),
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => InputComponent),
  }],
})
export class InputComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, AfterViewInit, OnDestroy {

  control: AbstractControl = new FormControl();
  error: ValidationErrors | undefined = undefined;
  isContent = false;
  filteredOptions: number[] = [];
  @Input() formControlName: string = '';
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'number' | 'password' | 'email' = 'text';
  @Input() maxlength: string = '';
  @Input() min: string | null = null;
  @Input() max: string | null = null;
  @Input() step: number = 1;
  @Input() textPosition: 'left' | 'center' | 'right' = 'left';
  @Input() autofocus: boolean = false;
  @Input() pattern: string = '';
  @Input() options: number[] = [];
  @Input() integerOnly: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() qaTags: string;
  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @ViewChild('content', { static: false }) content: ElementRef = new ElementRef(null);

  constructor(
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
    private renderer: Renderer2,
  ) {
  }

  private _value: any;

  get value(): string {
    return this._value;
  }

  @Input('value')
  set value(value: string) {
    this._value = value;
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  @HostListener('keydown.dot', ['$event'])
  @HostListener('keydown.e', ['$event'])
  @HostListener('keydown.-', ['$event'])
  @HostListener('keydown.+', ['$event'])
  onKeyDown(event: KeyboardEvent): void {
    if (this.integerOnly === false) return;
    event.preventDefault();
    event.stopPropagation();
  }

  /* do not paste e, + and - */
  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    if (this.integerOnly === false) return;

    const clipboardData = e.clipboardData.getData('text');
    if (/[.e+-]/.test(clipboardData)) {
      e.preventDefault();
      e.stopPropagation();
    }
  }

  ngAfterViewInit(): void {
    this.isContent = this.content.nativeElement && this.content.nativeElement.children.length > 0;
    this.cdr.detectChanges();
    this.setRequiredClass();
  }

  ngOnInit(): void {
    if (this.controlContainer) {
      if (this.formControlName && this.controlContainer.control) {
        this.control = this.controlContainer.control.get(this.formControlName) as AbstractControl;

        if (this.control) {
          this.isDisabled = this.isDisabled || this.control.disabled;

          this.control.statusChanges.subscribe(() => {
            this.isDisabled = this.control.disabled;
          });
        }
      }
    }

    this.filterOptions(this.value);
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (!changes && changes['value']) {
      this.writeValue(this._value);
    }
  }

  writeValue(value: any): void {
    this._value = value;
  }

  propagateChange: (value: any) => void = () => {
  };

  _onTouched = (): void => {
  };

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => object): void {
    this._onTouched = fn;
  }

  validate(control: AbstractControl): ValidationErrors | null {
    setTimeout(() => {
      if ((control.dirty || control.touched) && control.errors) {
        this.error = control.errors;
      } else {
        this.error = undefined;
      }
      if (!(this.cdr as ViewRef).destroyed) {
        this.cdr.detectChanges();
      }
    }, 0);
    return null;
  }

  onBlur(): void {
    if (this.control) {
      this.validate(this.control);
    }
  }

  onModelChange(e: string): void {
    this.value = e;

    this.validateNumber();
    this.propagateChange(e);
    this.onChange.emit(this.value);
  }

  validateNumber(): void {
    if (this.pattern && !String(this.value).match(new RegExp(this.pattern))) {
      this.error = { error: true };
      return;
    }

    if (this.min != null && this.value < this.min) {
      this.error = { error: true };
      return;
    }

    if (this.max != null && this.value > this.max) {
      this.error = { error: true };
      return;
    }

    this.error = undefined;
  }

  setRequiredClass(): void {
    if (!this.control.validator) return;
    const hasValidatorRequired = this.control.validator({} as AbstractControl)?.['required'];

    if (hasValidatorRequired) {
      const formField = this.content.nativeElement.closest('.form-field') as HTMLElement;
      if (formField) {
        const fieldTitle = formField.querySelector('.field-title');
        this.renderer.addClass(fieldTitle, 'required');
      } else {
        console.error('ERROR: There are no required markup for the required input!');
      }
    }
  }

  private filterOptions(start: string): void {
    start = !start ? '' : start;
    this.filteredOptions = this.options.filter(option => String(option).startsWith(start));
  }
}
