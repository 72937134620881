import { Component } from '@angular/core';

@Component({
  selector: 'ls-page-info',
  templateUrl: './page-info.component.html',
  styleUrls: ['./page-info.component.scss'],
  standalone: true,
})
export class PageInfoComponent {
}
