import { ElementTypesEnum } from '@livestock/shared/enums';

export interface LightingPeriod {
    controllerID: number;
    dayNumber: number;
    periodID?: number;
}

export interface LightingProgram {
    programID: number;
    periodID: number;
    name: string;
    startTime: number;
    elements: ProgramLightingElement[];
}

export interface SingleLightingProgram {
    items: LightingProgram[];
}

export interface ProgramLightingElement {
    programID: number;
    elementID: number;
    number: number;
    elementType: ElementTypesEnum;
    enabled?: boolean;
    intensity?: number;
}

export interface LightingElement {
    elementID: number;
    name: string;
    minVoltage?: number;
    maxVoltage?: number;
    number: number;
    operation?: number;
}

export interface AllLightingElement extends LightingElement {
    type: ElementTypesEnum,
}