import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ColorsEnum } from '@livestock/shared/enums';
import { SvgIconComponent } from '@livestock/ui';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  selector: 'ls-light-widget',
  templateUrl: './light-widget.component.html',
  styleUrls: ['./light-widget.component.scss'],
})
export class LightWidgetComponent {
  columns: number[] = [1, 2, 3];
  ColorsEnum = ColorsEnum;
}
