<div
  *ngIf="!isLoading && form && separateMaleAndFemale != null; else loading"
  [class.rtl]="languageService.isRtl"
  [class.show-mode]="!editMode"
  [formGroup]="form"
  class="bird-weight d-flex flex-column position-relative">
  <div
    *ngIf="showPseudoLoading"
    class="pseudo-loading">
    <ls-loading></ls-loading>
  </div>

  <div class="bird-weight__container">
    <div
      #table
      [class.equal-columns-2]="!separateMaleAndFemale"
      [class.equal-columns-3]="separateMaleAndFemale"
      [class.separate-male-and-female]="separateMaleAndFemale"
      [class.three-rows]="separateMaleAndFemale"
      class="table">
      <div class="table-row table-header h-56">
        <div class="table-cell blue">
          {{ "Flock.BirdWeight.Day" | translate }}
        </div>
        <div
          *ngIf="!separateMaleAndFemale"
          class="table-cell blue">
          {{ "Flock.BirdWeight.Weight" | translate }}
        </div>
        <ng-container *ngIf="separateMaleAndFemale">
          <div class="table-cell blue">
            {{ "Flock.BirdWeight.MaleWeight" | translate }}
          </div>
          <div class="table-cell blue">
            {{ "Flock.BirdWeight.FemaleWeight" | translate }}
          </div>
        </ng-container>
      </div>

      <ng-container formArrayName="birdWeightItems">
        <ng-container
          *ngFor="
            let birdWeightItemForm of form.get('birdWeightItems')['controls'];
            let index = index;
            let isLast = last
          "
          [formGroup]="birdWeightItemForm">
          <div
            (click)="setActiveIndex(index, birdWeightItemForm.value.day)"
            [class.active]="activeIndex == index"
            [class.last-row]="isLast && activeIndex != index"
            [class.disabled]="weightMethod === WeightMethodEnum.Predefined"
            [class.row-height-75]="!birdWeightItemForm.valid"
            class="table-row padding-top-11">
            <div class="table-cell">
              <ls-input-integer
                (change)="checkDuplicacy()"
                [fieldWithKeyboard]="true"
                [max]="FlockConstants.MaxBirdWeightDay"
                [min]="FlockConstants.MinBirdWeightDay"
                [qaTags]="'qa-txt-day'"
                [showTooltipOnHover]="!platformService.isMobileApp"
                class="align-center input-integer"
                errorField
                formControlName="day">
              </ls-input-integer>
            </div>
            <div
              *ngIf="!separateMaleAndFemale"
              class="table-cell">
              <ls-input-integer
                *ngIf="weightUnit === WeightUnitEnum.Gram"
                (change)="checkWeight(index, 'weight')"
                [fieldWithKeyboard]="true"
                [max]="maxWeight"
                [min]="0"
                [qaTags]="'qa-txt-weight'"
                [showTooltipOnHover]="!platformService.isMobileApp"
                [validateWithInitValue]="true"
                class="align-center input-decimal"
                errorField
                formControlName="weight">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-integer>
              <ls-input-decimal
                *ngIf="weightUnit !== WeightUnitEnum.Gram"
                (change)="checkWeight(index, 'weight')"
                [accuracy]="3"
                [fieldWithKeyboard]="true"
                [max]="maxWeight"
                [min]="0"
                [qaTags]="'qa-txt-weight'"
                [showTooltipOnHover]="!platformService.isMobileApp"
                [validateWithInitValue]="true"
                class="align-center input-decimal"
                errorField
                formControlName="weight">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
            <ng-container *ngIf="separateMaleAndFemale">
              <div class="table-cell">
                <ls-input-integer
                  *ngIf="weightUnit === WeightUnitEnum.Gram"
                  (change)="checkWeight(index, 'maleWeight')"
                  [fieldWithKeyboard]="true"
                  [max]="maxWeight"
                  [min]="0"
                  [qaTags]="'qa-txt-female-weight'"
                  [showTooltipOnHover]="!platformService.isMobileApp"
                  class="align-center input-decimal"
                  errorField
                  formControlName="maleWeight">
                  <ng-container
                    *ngTemplateOutlet="unit"
                    suffix>
                  </ng-container>
                </ls-input-integer>
                <ls-input-decimal
                  *ngIf="weightUnit !== WeightUnitEnum.Gram"
                  (change)="checkWeight(index, 'maleWeight')"
                  [accuracy]="3"
                  [fieldWithKeyboard]="true"
                  [max]="maxWeight"
                  [min]="0"
                  [qaTags]="'qa-txt-female-weight'"
                  [showTooltipOnHover]="!platformService.isMobileApp"
                  class="align-center input-decimal"
                  errorField
                  formControlName="maleWeight">
                  <ng-container
                    *ngTemplateOutlet="unit"
                    suffix>
                  </ng-container>
                </ls-input-decimal>
              </div>
              <div class="table-cell">
                <ls-input-integer
                  *ngIf="weightUnit === WeightUnitEnum.Gram"
                  (change)="checkWeight(index, 'femaleWeight')"
                  [fieldWithKeyboard]="true"
                  [max]="maxWeight"
                  [min]="0"
                  [qaTags]="'qa-txt-femaleWeight-weight'"
                  [showTooltipOnHover]="!platformService.isMobileApp"
                  class="align-center input-decimal"
                  errorField
                  formControlName="femaleWeight">
                  <ng-container
                    *ngTemplateOutlet="unit"
                    suffix>
                  </ng-container>
                </ls-input-integer>
                <ls-input-decimal
                  *ngIf="weightUnit !== WeightUnitEnum.Gram"
                  (change)="checkWeight(index, 'femaleWeight')"
                  [accuracy]="3"
                  [fieldWithKeyboard]="true"
                  [max]="maxWeight"
                  [min]="0"
                  [qaTags]="'qa-txt-femaleWeight-weight'"
                  [showTooltipOnHover]="!platformService.isMobileApp"
                  class="align-center input-decimal"
                  errorField
                  formControlName="femaleWeight">
                  <ng-container
                    *ngTemplateOutlet="unit"
                    suffix>
                  </ng-container>
                </ls-input-decimal>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
  <div
    [ngClass]="weightMethod === WeightMethodEnum.Predefined ? 'justify-end' : 'justify-between'"
    class="buttons d-flex flex-row flex-column-md">
    <div
      *ngIf="editMode && weightMethod !== WeightMethodEnum.Predefined"
      class="buttons__add-delete d-flex flex-row">
      <div class="d-flex flex-row">
        <ls-svg-icon
          (click)="removeRow()"
          [class.color-disabled]="activeIndex == null"
          [class.disabled]="activeIndex == null"
          [path]="!platformService.isMobileApp ? 'common/delete' : 'mobile/delete'"
          class="pointer qa-btn-edit-mode color-green">
        </ls-svg-icon>
        <ls-svg-icon
          (click)="addEmptyRow()"
          [class.color-disabled]="form.invalid"
          [class.disabled]="form.invalid"
          [path]="!platformService.isMobileApp ? 'common/add' : 'mobile/add'"
          class="pointer ml-20 qa-btn-edit-mode color-green">
        </ls-svg-icon>
      </div>

      <ls-button
        (click)="orderByDay()"
        [class.disabled]="form.invalid"
        [class.w-button-130]="platformService.isMobileApp"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="ml-20 h-button-36">
        {{ "Buttons.Update" | translate }}
      </ls-button>
    </div>

    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>

<ng-template #unit>
  <span class="unit">
    <ng-container [ngSwitch]="weightUnit">
      <ng-container *ngSwitchCase="WeightUnitEnum.Gram">
        {{ "g" | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WeightUnitEnum.Kilogram">
        {{ "kg" | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WeightUnitEnum.Pound">
        {{ "lb" | translate }}
      </ng-container>
    </ng-container>
  </span>
</ng-template>
