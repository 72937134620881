import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  FlockService,
  IFlockBirdWeightSettingsView,
  IFlockBirdWeightView,
  IFlockHouseModeView,
  IFlockSettingsView,
  IGetFlockBirdWeightView,
  IGetFlockDefaultWeightView,
  WeightMethodEnum,
} from '@livestock/flock';
import * as flockActions from './flock.actions';
import * as flockSelectors from './flock.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { IFlockView } from '../interfaces';
import { selectActiveControllerID, selectTemperatureUnit, selectWeightUnit } from '@livestock/controllers';
import { TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';

@Injectable()

export class FlockEffects {

  getDefaultWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.getDefaultWeight),
      withLatestFrom(
        this.store.select(selectWeightUnit),
      ),
      switchMap(([{ brand, weightUnit: weightUnitFromAction }, weightUnit]) => {
        return this.flockService.getDefaultWeightBrand(brand, weightUnitFromAction ?? weightUnit).pipe(
          map((view: IGetFlockDefaultWeightView) =>
            flockActions.getDefaultWeightSuccess({ defaultWeightItems: view.items, weightUnit })),
          catchError((error) => of(flockActions.getDefaultWeightError({ payload: error }))),
        );
      }),
    ),
  );

  changeDefaultWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.changeDefaultWeight),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(flockSelectors.selectWeightUnit),
        this.store.select(selectWeightUnit),
      ),
      switchMap(([{ brand }, controllerID, weightUnitFromNewFlock, savedWeightUnit]) => {
        return this.flockService.getDefaultWeightBrand(brand, weightUnitFromNewFlock ?? savedWeightUnit).pipe(
          map((view: IGetFlockDefaultWeightView) =>
            flockActions.changeDefaultWeightSuccess({
              defaultWeightItems: view.items,
              brand,
              controllerID,
            })),
          catchError((error) => of(flockActions.changeDefaultWeightError({ payload: error }))),
        );
      }),
    ),
  );

  getFlockSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.getFlockSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.flockService.getSettings(controllerID).pipe(
          map((settings: IFlockSettingsView) =>
            flockActions.getFlockSettingsSuccess({ settings })),
          catchError((error) => of(flockActions.getFlockSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateFlockSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.updateFlockSettings),
      withLatestFrom(
        this.store.select(flockSelectors.selectFlockSettings),
      ),
      switchMap(([_, settings]: [any, IFlockSettingsView]) => {
        const modifiedSettings = {
          ...settings,
          units: null,
          unitsDetails: null,
        };

        return this.flockService.updateSettings(settings.controllerID, modifiedSettings).pipe(
          switchMap(() => {
            return [
              flockActions.updateFlockSettingsSuccess(),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SettingsWereSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(flockActions.updateFlockSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getFlockHouseMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.getFlockHouseMode),
      withLatestFrom(this.store.select(selectTemperatureUnit)),
      switchMap(([{ controllerID }, temperatureUnit]) => {
        return this.flockService.getHouseMode(controllerID, temperatureUnit).pipe(
          map((houseMode: IFlockHouseModeView) =>
            flockActions.getFlockHouseModeSuccess({ houseMode })),
          catchError((error) => of(flockActions.getFlockHouseModeError({ payload: error }))),
        );
      }),
    ),
  );

  updateFlockHouseMode$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.updateFlockHouseMode),
      withLatestFrom(
        this.store.select(flockSelectors.selectFlockHouseMode),
        this.store.select(selectTemperatureUnit),
      ),
      switchMap(([_, houseMode, temperatureUnit]: [any, IFlockHouseModeView, TemperatureUnitEnum]) => {
        return this.flockService.updateHouseMode(houseMode.controllerID, houseMode, temperatureUnit).pipe(
          switchMap(() => {
            return [
              flockActions.updateFlockHouseModeSuccess(),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.HouseModeWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(flockActions.updateFlockHouseModeError({ payload: error }))),
        );
      }),
    ),
  );

  getFlockBirdWeightSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.getFlockBirdWeightSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.flockService.getBirdWeightSettings(controllerID).pipe(
          map((birdWeightSettings: IFlockBirdWeightSettingsView) =>
            flockActions.getFlockBirdWeightSettingsSuccess({ birdWeightSettings })),
          catchError((error) => of(flockActions.getFlockBirdWeightSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateFlockBirdWeightSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.updateFlockBirdWeightSettings),
      withLatestFrom(
        this.store.select(flockSelectors.selectFlockBirdWeightSettings),
      ),
      switchMap(([_, birdWeightSettings]: [any, IFlockBirdWeightSettingsView]) => {
        const parsedBirdWeightSettings = {
          ...birdWeightSettings,
          defaultWeightBrandID:
            birdWeightSettings.weightMethod === WeightMethodEnum.Predefined
              ? birdWeightSettings.defaultWeightBrandID
              : null,
        };

        return this.flockService.updateBirdWeightSettings(birdWeightSettings.controllerID, parsedBirdWeightSettings).pipe(
          switchMap(() => {
            return [
              flockActions.updateFlockBirdWeightSettingsSuccess(),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.BirdWeightSettingsWereSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(flockActions.updateFlockBirdWeightSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getFlockBirdWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.getFlockBirdWeight),
      withLatestFrom(this.store.select(selectWeightUnit)),
      switchMap(([{ controllerID }, weightUnit]) => {
        return this.flockService.getBirdWeight(controllerID, weightUnit).pipe(
          map((view: IGetFlockBirdWeightView) =>
            flockActions.getFlockBirdWeightSuccess({ view })),
          catchError((error) => of(flockActions.getFlockBirdWeightError({ payload: error }))),
        );
      }),
    ),
  );

  updateFlockBirdWeight$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.updateFlockBirdWeight),
      map((action) => action.hideFlashMessage),
      withLatestFrom(
        this.store.select(flockSelectors.selectFlockBirdWeightSettings),
        this.store.select(flockSelectors.selectFlockBirdWeightItems),
        this.store.select(selectWeightUnit),
      ),
      switchMap(([hideFlashMessage, birdWeightSettings, birdWeightItems, weightUnit]: [boolean, IFlockBirdWeightSettingsView, IFlockBirdWeightView[], WeightUnitEnum]) => {
        return this.flockService.updateBirdWeight(birdWeightSettings.controllerID, birdWeightItems, weightUnit).pipe(
          map(() => {
            if (!hideFlashMessage) {
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.BirdWeightsWereSuccessfullyUpdated',
                },
              });
            }
            return flockActions.updateFlockBirdWeightSuccess();
          }),
          catchError((error) => of(flockActions.updateFlockBirdWeightError({ payload: error }))),
        );
      }),
    ),
  );

  finishNewFlock$ = createEffect(() =>
    this.actions$.pipe(
      ofType(flockActions.finishNewFlock),
      withLatestFrom(this.store.select(flockSelectors.selectFlock)),
      switchMap(([_, flockView]: [any, IFlockView]) => {
        let updatedFlockView: IFlockView = { ...flockView };
        if (flockView.flockBirdWeightSettings.weightMethod !== WeightMethodEnum.Predefined) {
          updatedFlockView = {
            ...updatedFlockView,
            flockBirdWeightSettings: {
              ...updatedFlockView.flockBirdWeightSettings,
              defaultWeightBrandID: null,
            },
          };
        }
        return this.flockService.createFlock(flockView.flockSettings?.controllerID, updatedFlockView).pipe(
          map(() => flockActions.finishNewFlockSuccess({ flockView })),
          catchError((error) => of(flockActions.finishNewFlockError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private flockService: FlockService,
  ) {
  }
}
