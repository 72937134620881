import { KeyboardModeEnum } from '../keyboard-mode.enum';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/am-pm.enum';

export interface KeyboardState {
  elementUuid: string;
  symbol: number | string;
  ranges: { min: number | string, max: number | string };
  mode: KeyboardModeEnum;
  isRefreshFieldsForKeyboard: boolean;
  currentIconPath: string;
  hasAMPM: boolean;
  activeAMPM: AmPmEnum;
}
