import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IBasicVentilationMinimumView, IBasicVentilationMinimumItem, IBasicVentilationSettings, IFans } from '@livestock/basic-ventilation-minimum';
import { IElement } from 'libs/installation/src/lib/interfaces/element/element.interface';

export const getBasicVentilationMinimumItems = createAction(
  '[BASIC VENTILATION MINIMUM] Get Basic VentilationMinimum Items [...]',
);

export const getBasicVentilationMinimumItemsSuccess = createAction(
  '[BASIC VENTILATION MINIMUM] Get Basic VentilationMinimum Items [SUCCESS]',
  props<{ view: IBasicVentilationMinimumView }>(),
);

export const getBasicVentilationMinimumItemsError = createAction(
  '[BASIC VENTILATION MINIMUM] Get Basic VentilationMinimum Items [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateBasicVentilationMinimumItems = createAction(
  '[BASIC VENTILATION MINIMUM] Update Basic VentilationMinimum Items [...]',
);

export const updateBasicVentilationMinimumItemsSuccess = createAction(
  '[BASIC VENTILATION MINIMUM] Update Basic VentilationMinimum Items [SUCCESS]',
);

export const updateBasicVentilationMinimumItemsError = createAction(
  '[BASIC VENTILATION MINIMUM] Update Basic VentilationMinimum Items [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// settings
export const getBasicVentilationSettings = createAction(
  '[BASIC VENTILATION SETTINGS] Get Basic Ventilation Settings [...]',
  props<{ controllerID: number }>(),
);

export const getBasicVentilationSettingsSuccess = createAction(
  '[BASIC VENTILATION SETTINGS] Get Basic Ventilation Settings [SUCCESS]',
  props<{ settings: IBasicVentilationSettings }>(),
);

export const getBasicVentilationSettingsError = createAction(
  '[BASIC VENTILATION SETTINGS] Get Basic Ventilation Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateBasicVentilationSettings = createAction(
  '[BASIC VENTILATION SETTINGS] Update Basic Ventilation Settings [...]',
  props<{ controllerID: number, settings: IBasicVentilationSettings }>(),
);

export const updateBasicVentilationSettingsSuccess = createAction(
  '[BASIC VENTILATION SETTINGS] Update Basic Ventilation Settings [SUCCESS]',
  props<{ settings: IBasicVentilationSettings }>(),
);

export const updateBasicVentilationSettingsError = createAction(
  '[BASIC VENTILATION SETTINGS] Update Basic Ventilation Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setFans = createAction(
    '[BASIC VENTILATION MINIMUM] Set Ventilation Minimum Fans [...]',
    props<{ elements: IElement[], selectedIndex: number }>(),
);

export const addNewItem = createAction(
    '[BASIC VENTILATION MINIMUM] Add Ventilation Minimum Item [...]',
);

export const removeItem = createAction(
    '[BASIC VENTILATION MINIMUM] Remove Ventilation Minimum Item [...]',
    props<{ selectedIndex: number }>(),
);

export const setBasicVentilationItems = createAction(
    '[BASIC VENTILATION MINIMUM] Set Basic Ventilation Minimum Items [...]',
    props<{ items: IBasicVentilationMinimumItem[] }>(),
);

export const setPercentage = createAction(
  '[BASIC VENTILATION MINIMUM] Set Basic Ventilation Minimum Percentage [...]',
  props<{ currentItem: IFans, selectedIndex: number }>(),
);