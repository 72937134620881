<section
  *ngIf="!isLoading; else loading"
  class="d-flex flex-column justify-around h-100-percent">
  <div class="chart-wrapper d-flex flex-grow-1">
    <canvas
      *ngIf="chartData && chartOptions"
      #chart
      [qaTags]="'qa-temp-curve-chart'"
      [datasets]="chartData"
      [options]="chartOptions"
      [legend]="false"
      [type]="'line'"
      baseChart>
    </canvas>
  </div>

  <div
    *ngIf="legend?.length"
    [qaTags]="'qa-temp-curve-chart-legend'"
    class="legend d-flex flex-column ml-70 ml-0-mobile">
    <div class="title">{{ "TemperatureCurve.Legend" | translate }}</div>
    <div class="items d-flex flex-row mt-10">
      <div
        *ngFor="let item of legend"
        class="item d-flex flex-row justify-center">
        <span
          [style.background]="item.color"
          class="color"></span>
        <span>{{ item.title }}</span>
      </div>
    </div>
  </div>

  <ng-content
    class="d-flex align-end justify-end"
    select=".buttons-content"></ng-content>
</section>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
