import { Observable, firstValueFrom } from 'rxjs';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { ElementConstants } from '../../constants/element.constants';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';

@Component({
  selector: 'ls-outdoor-temp-form',
  templateUrl: './outdoor-temp-element-form.component.html',
  styleUrls: ['../devices-common.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class OutdoorTempComponent extends BasicElementComponent {
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);
  MAX_TEMP: number;
  MIN_TEMP: number;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.parentFormGroup.addControl(
      this.formGroupName,
      new FormGroup({
        name: this.name,
        offset: new FormControl<number>(0, [Validators.required]),
        enabled: new FormControl<boolean>(true),
      }),
    );

    super.ngOnInit();
    this.applyValidators();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      offset: setupData.offset,
      enabled: setupData.enabled,
    });
  }

  private async applyValidators(): Promise<void> {
    const isCelsius = (await firstValueFrom(this.temperatureUnit$)) === TemperatureUnitEnum.Celsius;
    this.MAX_TEMP = isCelsius ? ElementConstants.TEMPERATURE_UNIT_MAX_C : ElementConstants.TEMPERATURE_UNIT_MAX_F;
    this.MIN_TEMP = isCelsius ? ElementConstants.TEMPERATURE_UNIT_MIN_C : ElementConstants.TEMPERATURE_UNIT_MIN_F;
    this.parentFormGroup
      .get(this.formGroupName + '.offset')
      .addValidators([Validators.min(this.MIN_TEMP), Validators.max(this.MAX_TEMP)]);
  }
}
