<ng-container *ngIf="parentFormGroup.get(formGroupName)">
  <div [formGroupName]="formGroupName">
    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-wind-delay'">{{ "Installation.Card.StaticPressure.WindDelayTime" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.staticPressureSettings.windDelay.max"
          [min]="ElementsValidationConstants.staticPressureSettings.windDelay.min"
          [qaTags]="'qa-txt-wind-delay'"
          errorField
          formControlName="windDelayTime"></ls-input-integer>
        <span class="unit ml-10">{{ "Sec" | translate }}</span>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-pressure-level'">{{ minPressureForAlarmTitle | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="MAX_PRESSURE_ALARM"
          [min]="MIN_PRESSURE_ALARM"
          [qaTags]="'qa-txt-pressure-level'"
          errorField
          formControlName="minPressureForAlarm"></ls-input-integer>
        <span class="unit ml-10">{{ minPressureForAlarmUnit }}</span>
      </div>
    </div>
  </div>
</ng-container>
