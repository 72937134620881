<div class="widget-wrapper">
  <div class="widget-body d-flex h-100-percent">
    <div class="w-60-percent pr-50 pr-27-xl pr-12-lg air-pressure">
      <h6
        [qaTags]="'qa-lbl-widget-title'"
        class="widget-title">
        Air pressure
      </h6>

      <div class="widget-content">
        <div class="d-flex justify-between align-center pl-10 align-start-xl p-0-xl">
          <div class="widget-icon mr-25">
            <img
              [qaTags]="'qa-img-widget qa-img-air-pressure'"
              alt="air pressure"
              src="/assets/icons/dashboard/air-pressure.svg" />
          </div>

          <div class="d-flex align-center w-100-percent">
            <div class="d-flex flex-column mr-70 mr-40-xl mr-12-lg">
              <div
                [qaTags]="'qa-lbl-air-pressure-value'"
                class="widget-content__value air-pressure__current fz-40 mb-5">
                40<span
                  [qaTags]="'qa-lbl-air-pressure-units'"
                  class="fz-16"
                  >PA</span
                >
              </div>
              <span class="widget-content__subtitle">Current</span>
            </div>

            <div class="d-flex flex-column">
              <div
                [qaTags]="'qa-lbl-air-pressure-score'"
                class="widget-content__value air-pressure__set fz-32">
                20<span class="fz-16">PA</span>
              </div>
              <span class="widget-content__subtitle">Set</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="pl-35 pl-30-xl pl-10-lg water-pressure">
      <h6
        [qaTags]="'qa-lbl-widget-title'"
        class="widget-title">
        Water pressure
      </h6>

      <div class="widget-content">
        <div class="d-flex justify-between align-center align-start-xl water-pressure__block">
          <div class="widget-icon mr-25">
            <img
              [qaTags]="'qa-img-widget qa-img-water-pressure'"
              alt="water pressure"
              src="/assets/icons/dashboard/water-pressure.svg" />
          </div>

          <div class="d-flex flex-column">
            <div
              [qaTags]="'qa-lbl-water-pressure-value'"
              class="widget-content__value fz-40 fz-24-xl mb-5 mb-9-xl mb-5-lg">
              12<span
                [qaTags]="'qa-lbl-water-pressure-units'"
                class="fz-16 fz-14-lg"
                >Inc</span
              >
            </div>
            <span class="widget-content__subtitle water-pressure__block-current">Current</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
