<div class="widget-wrapper">
  <h6
    [qaTags]="'qa-lbl-widget-title'"
    class="widget-title">
    Air quality
  </h6>

  <div class="widget-body d-flex">
    <div class="widget-icon mr-25 pl-10">
      <img
        [qaTags]="'qa-img-widget'"
        alt="air"
        src="/assets/icons/dashboard/air.svg" />
    </div>

    <div class="widget-content">
      <div class="d-flex align-center">
        <div class="d-flex flex-column mr-75 mr-32-xl mr-20-lg">
          <div
            [qaTags]="'qa-lbl-air-quality-humidity-value'"
            class="widget-content__value mb-0-xl fz-24-xl fz-40 fw-500">
            67
            <span
              [qaTags]="'qa-lbl-air-quality-humidity-units'"
              class="fz-16 fw-400"
              >%</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-air-quality-humidity-lbl'"
            class="widget-content__subtitle"
            >Humidity</span
          >
        </div>

        <div class="d-flex flex-column mr-75 mr-32-xl mr-20-lg">
          <div
            [qaTags]="'qa-lbl-air-quality-co2-value'"
            class="widget-content__value mb-0-xl co2">
            00<span
              [qaTags]="'qa-lbl-air-quality-co2-units'"
              class="fz-16"
              >PPM</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-air-quality-co2-lbl'"
            class="widget-content__subtitle"
            >CO2</span
          >
        </div>

        <div class="d-flex flex-column">
          <div
            [qaTags]="'qa-lbl-air-quality-ammonia-value'"
            class="widget-content__value mb-0-xl ammonia">
            00<span
              [qaTags]="'qa-lbl-air-quality-ammonia-units'"
              class="fz-16"
              >PPM</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-air-quality-ammonia-lbl'"
            class="widget-content__subtitle"
            >Ammonia</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
