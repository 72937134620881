import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as currentUserActions from './current-user.actions';
import { catchError, map, of, switchMap } from 'rxjs';
import { CurrentUserService } from '../services/current-user.service';

@Injectable()
export class CurrentUserEffects {

  getCurrentUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(currentUserActions.getCurrentUser),
      switchMap(() => {
        return this.currentUserService.getCurrentUser().pipe(
          map((currentUser) => currentUserActions.getCurrentUserSuccess({ currentUser })),
          catchError((error) => of(currentUserActions.getCurrentUserError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private currentUserService: CurrentUserService,
  ) {
  }
}
