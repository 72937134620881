<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-12 mb-20-mobile">
    <label qaTags="qa-label-name">{{ "Installation.Main.DeviceName" | translate }}</label>

    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-unit-name'"
        errorField
        formControlName="name"></ls-input-text>
      <!-- copy btn -->
      <ng-content></ng-content>
    </div>
  </div>

  <div class="device-form__field mb-12 mb-20-mobile">
    <label>{{ "Installation.Card.HeatingAO.Operation" | translate }}</label>
    <ng-select
      [clearable]="false"
      [searchable]="false"
      selectTitle="{{ 'Installation.Card.HeatingAO.Operation' | translate }}"
      formControlName="operation">
      <ng-option
        *ngFor="let el of ElementOperationEnum | enumToArray"
        [qaTags]="'qa-ddl-operation-' + el"
        [value]="el">
        <span>{{ el | enum : "element-operation" | translate }}</span>
      </ng-option>
    </ng-select>
  </div>

  <div class="device-form__field mb-12 mb-20-mobile">
    <label>{{ "Installation.Card.HeatingAO.MinVoltage" | translate }}</label>
    <div class="d-flex flex-row align-center">
      <ls-input-decimal
        [accuracy]="1"
        [fieldWithKeyboard]="true"
        [max]="ElementsValidationConstants.heatingAO.minVoltage.max"
        [min]="ElementsValidationConstants.heatingAO.minVoltage.min"
        [qaTags]="'qa-txt-min-voltage'"
        [validateWithInitValue]="true"
        errorField
        formControlName="minVoltage">
      </ls-input-decimal>
    </div>
  </div>

  <div class="device-form__field mb-12 mb-20-mobile">
    <label>{{ "Installation.Card.HeatingAO.MaxVoltage" | translate }}</label>
    <div class="d-flex flex-row align-center">
      <ls-input-decimal
        [accuracy]="1"
        [fieldWithKeyboard]="true"
        [max]="ElementsValidationConstants.heatingAO.maxVoltage.max"
        [min]="ElementsValidationConstants.heatingAO.maxVoltage.min"
        [qaTags]="'qa-txt-max-voltage'"
        [validateWithInitValue]="true"
        errorField
        formControlName="maxVoltage">
      </ls-input-decimal>
    </div>
  </div>

  <div class="device-form__field mb-20">
    <label>{{ "Installation.Card.HeatingAO.KWh" | translate }}</label>
    <ls-input-decimal
      [accuracy]="1"
      [fieldWithKeyboard]="true"
      [max]="ElementsValidationConstants.heatingAO.kwh.max"
      [min]="ElementsValidationConstants.heatingAO.kwh.min"
      [qaTags]="'qa-txt-kwh'"
      [validateWithInitValue]="true"
      errorField
      formControlName="kwh">
    </ls-input-decimal>
  </div>
</div>
