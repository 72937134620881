import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as basicVentionMinimumActions from './basic-ventilation-minimum.actions';
import * as basicVentionMinimumSelectors from './basic-ventilation-minimum.selectors';
import { IBasicVentilationMinimumView, IBasicVentilationMinimumItem, BasicVentilationMinimumService, IBasicVentilationSettings } from '@livestock/basic-ventilation-minimum';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { selectActiveControllerID } from 'libs/controllers/src/lib/+state/current-controller/current-controller.selectors';

@Injectable()
export class BasicVentilationMinimumEffects {
  getBasicVentilationMinimumItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(basicVentionMinimumActions.getBasicVentilationMinimumItems),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_, controllerID]) => {
        return this.apiService.getBasicVentilationMinimum(controllerID).pipe(
          map((view: IBasicVentilationMinimumView) =>
            basicVentionMinimumActions.getBasicVentilationMinimumItemsSuccess({ view })),
          catchError((error) => of(basicVentionMinimumActions.getBasicVentilationMinimumItemsError({ payload: error }))),
        );
      }),
    ),
  );

  updateBasicVentilationMinimumItems$ = createEffect(() =>
    this.actions$.pipe(
      ofType(basicVentionMinimumActions.updateBasicVentilationMinimumItems),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(basicVentionMinimumSelectors.selectBasicVentilationMinimumItems),
      ),
      switchMap(([_, controllerID, items]: [any, number, IBasicVentilationMinimumItem[]]) => {
        return this.apiService.updateBasicVentilationMinimum(controllerID, items).pipe(
          switchMap((_) => {
            return [
              basicVentionMinimumActions.updateBasicVentilationMinimumItemsSuccess(),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.BasicVentilationMinimumWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(basicVentionMinimumActions.updateBasicVentilationMinimumItemsError({ payload: error }))),
        );
      }),
    ),
  );

  // settings
  getBasicVentilationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(basicVentionMinimumActions.getBasicVentilationSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.apiService.getBasicVentilationSettings(controllerID).pipe(
          map((settings: IBasicVentilationSettings) =>
            basicVentionMinimumActions.getBasicVentilationSettingsSuccess({ settings })),
          catchError((error) => of(basicVentionMinimumActions.getBasicVentilationSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateBasicVentilationSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(basicVentionMinimumActions.updateBasicVentilationSettings),
      switchMap((payload) => {
        return this.apiService.updateBasicVentilationSettings(payload.controllerID, payload.settings).pipe(
          switchMap(_ => {
            return [
              basicVentionMinimumActions.updateBasicVentilationSettingsSuccess({ settings: payload.settings }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.BasicVentilationMinimumWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(basicVentionMinimumActions.updateBasicVentilationSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private apiService: BasicVentilationMinimumService,
  ) {
  }
}
