import { ConnectionType } from './connection-type.enum';

export enum CardType {
  Unknown = 0,
  Analog,
  Relay,
  DigitalInput,
  Scale,
}

export namespace CardType {
  export function toTranslateKey(value: number | string | CardType): string {
    switch (value) {
      case CardType.Analog:
        return 'Installation.Main.Analog';
      case CardType.DigitalInput:
        return 'Installation.Main.DigitalInput';
      case CardType.Relay:
        return 'Installation.Main.Relay';
      case CardType.Scale:
        return 'Installation.Main.Scale';
      default:
        return value.toString();
    }
  }

  export function toTranslateKeyWithValue([cardType, connectionType]: [number | string | CardType, ConnectionType]): string {
    switch (cardType) {
      case CardType.Analog:
        if (connectionType === ConnectionType.AnalogInput) {
          return 'Installation.Main.AnalogInputWithValue';
        }
        return 'Installation.Main.AnalogOutputWithValue';
      case CardType.DigitalInput:
        return 'Installation.Main.DigitalInputWithValue';
      case CardType.Relay:
        return 'Installation.Main.RelayWithValue';
      case CardType.Scale:
        return 'Installation.Main.ScaleWithValue';
      default:
        return cardType.toString();
    }
  }
}
