import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { IBasicVentilationMinimumView } from '../interfaces/basic-ventilation-minimum-view.interface';
import { IBasicVentilationSettings } from '../interfaces/basic-ventilation-settings.interface';
import { IBasicVentilationMinimumItem } from '../interfaces/basic-ventilation-minimum-item.interface';

@Injectable({
  providedIn: 'root',
})
export class BasicVentilationMinimumService {
  constructor(private http: HttpClient) {
  }

  getBasicVentilationMinimum(controllerID: number): Observable<IBasicVentilationMinimumView> {
    return this.http.get<IBasicVentilationMinimumView>(`${environment.apiUrl}/controller/${controllerID}/basic-ventilation`);
  }

  updateBasicVentilationMinimum(controllerID: number, items: IBasicVentilationMinimumItem[]): Observable<IBasicVentilationMinimumItem[]> {
    return this.http.put<IBasicVentilationMinimumItem[]>(`${environment.apiUrl}/controller/${controllerID}/basic-ventilation`, items);
  }

  // settings
  getBasicVentilationSettings(controllerID: number): Observable<IBasicVentilationSettings> {
    return this.http.get<IBasicVentilationSettings>(`${environment.apiUrl}/controller/${controllerID}/basic-ventilation/settings`);
  }

  updateBasicVentilationSettings(controllerID: number, settings: IBasicVentilationSettings): Observable<IBasicVentilationSettings> {
    return this.http.put<IBasicVentilationSettings>(`${environment.apiUrl}/controller/${controllerID}/basic-ventilation/settings`, settings);
  }
}
