import { IEditUnitModeObj } from '../../interfaces/units/edit-unit-mode-obj.interface';

export enum AirFlowUnitEnum {
  CubicMeterPerHour,
  CubicFeetPerHour,
}

export namespace AirFlowUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case AirFlowUnitEnum.CubicMeterPerHour:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirFlowEnum.CubicMeterHour';
      case AirFlowUnitEnum.CubicFeetPerHour:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirFlowEnum.CubicFeetMin';
      default:
        return value.toString();
    };
  }

  export function isMetricUnit(value: AirFlowUnitEnum): boolean {
    return value === AirFlowUnitEnum.CubicMeterPerHour;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.AirFlow',
      controlName: 'airFlow',
      enumName: AirFlowUnitEnum,
      enumStr: 'air-flow-unit-enum',
    };
  }
}
