import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeatersKeyboardModeEnum } from '../../enums/heaters-keyboard-mode.enum';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectHeatingAOElements,
  selectHeatingDOElements,
  selectHeatingProgramActiveZone,
  selectHeatingProgramZones,
} from '../../+state/heating-program-zone.selectors';
import { IElement } from '@livestock/installation/interfaces';
import { IHeatingProgramZoneItem } from '../../interfaces/heating-program-zone-item.interface';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import {
  addHeatingElementToZone,
  goToNextHeatingProgramZone,
} from '../../+state/heating-program-zone.actions';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-heaters-keyboard',
  templateUrl: 'heaters-keyboard.component.html',
  styleUrls: ['./heaters-keyboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
  ],
})
export class HeatersKeyboardComponent implements OnInit, OnDestroy {
  sub$ = new Subscription();
  heatingDOElements$: Observable<IElement[]> = this.store.select(selectHeatingDOElements);
  heatingAOElements$: Observable<IElement[]> = this.store.select(selectHeatingAOElements);
  keyboardMode: HeatersKeyboardModeEnum = HeatersKeyboardModeEnum.Relay;
  HeatersKeyboardModeEnum = HeatersKeyboardModeEnum;
  ColorsEnum = ColorsEnum;
  activeZone: IHeatingProgramZoneItem;
  zones: IHeatingProgramZoneItem[] = [];

  constructor(private store: Store) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectHeatingProgramZones).pipe(wasChangedAndNotNull())
        .subscribe((zones) => {
          this.zones = zones;
        }),
    );

    this.sub$.add(
      this.store.select(selectHeatingProgramActiveZone)
        .pipe(wasChangedAndNotNull())
        .subscribe((activeZone) => {
          this.activeZone = activeZone;

          if (activeZone.heatingElements.length !== 0) {
            this.keyboardMode = activeZone.heatingElements.some(el => el.elementType === ElementTypesEnum.HeatingAO)
              ? HeatersKeyboardModeEnum.Analog
              : HeatersKeyboardModeEnum.Relay;
          }
        }),
    );
  }

  switchKeyboardMode(keyboardMode: HeatersKeyboardModeEnum): void {
    this.keyboardMode = keyboardMode;
  }

  isHeatingElementChosen([elementID, zones]: [number, IHeatingProgramZoneItem[]]): boolean {
    return zones.some(zone => {
      return zone.heatingElements.some(heatingElement => {
        return heatingElement.elementID === elementID;
      });
    });
  }

  selectHeatingElement(heatingElement: IElement): void {
    this.store.dispatch(addHeatingElementToZone({ heatingElement }));
  }

  goToNextZone(): void {
    this.store.dispatch(goToNextHeatingProgramZone());
  }

  shouldOptionBeDisabled([keyboardMode, activeZone]: [HeatersKeyboardModeEnum, IHeatingProgramZoneItem]): boolean {
    if (!activeZone) {
      return true;
    }

    switch (keyboardMode) {
      case HeatersKeyboardModeEnum.Analog:
        return activeZone.heatingElements.some(el => el.elementType === ElementTypesEnum.HeatingDO);
      case HeatersKeyboardModeEnum.Relay:
        return activeZone.heatingElements.some(el => el.elementType === ElementTypesEnum.HeatingAO);
      default:
        return true;
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
