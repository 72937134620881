<ng-container *ngIf="childGroupName; else noElement">
  <div class="device-wrapper d-flex flex-column justify-between overflow-y-auto thin-scrollbar">
    <div
      [ngClass]="{ 'd-flex flex-column': platformService.isMobileApp }"
      class="device-body">
      <div
        *ngIf="!(installationHelpersService.isTableView$ | async)"
        class="device-header d-flex align-center justify-between mb-40 mb-20-md">
        <h4 class="fz-16 fz-18-md color-secondary fw-500">
          <span [qaTags]="'qa-lbl-action-title'">{{
            helper.childElementTitleByType(currentElementType) | translate
          }}</span>
          {{ " " }}
          <span [qaTags]="'qa-lbl-action-count'">{{ (currentConnectionSetupData$ | async)?.number }}</span>
        </h4>

        <div class="device-status d-flex align-center">
          <ls-svg-icon
            [color]="ColorsEnum.DefaultDisabled"
            [hasMargin]="true"
            [path]="currentElementType | memoizeFunc : ElementTypesEnum.getSvgPath"
            [qaTags]="'qa-img-on-of'"></ls-svg-icon>

          <h4
            [qaTags]="'qa-lbl-on-off'"
            class="device-status__text fz-24 fz-20-md color-tertiary ml-5">
            {{ "Installation.Card.Status.Off" | translate }}
          </h4>
        </div>
      </div>
      <!-- element form -->
      <form [formGroup]="parentForm">
        <ng-container [ngSwitch]="currentElementType">
          <!-- cooling -->
          <ls-cooling-element-form
            *ngSwitchCase="ElementTypesEnum.Cooling"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-cooling-element-form>

          <!-- heating-do -->
          <ls-heating-do-form
            *ngSwitchCase="ElementTypesEnum.HeatingDO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-heating-do-form>

          <!-- heating-ao -->
          <ls-heating-ao-form
            *ngSwitchCase="ElementTypesEnum.HeatingAO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-heating-ao-form>

          <!-- sprinklers and foggers -->
          <ls-sprinklers-and-foggers-form
            *ngSwitchCase="ElementTypesEnum.SprinklersAndFoggers"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-sprinklers-and-foggers-form>

          <!-- indoor temperature -->
          <ls-indoor-temp-form
            *ngSwitchCase="ElementTypesEnum.IndoorTemperature"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-indoor-temp-form>

          <!-- outdoor temperature -->
          <ls-outdoor-temp-form
            *ngSwitchCase="ElementTypesEnum.OutdoorTemperature"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
          </ls-outdoor-temp-form>

          <!-- indoor humidity -->
          <ls-indoor-humidity-form
            *ngSwitchCase="ElementTypesEnum.IndoorHumidity"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
          </ls-indoor-humidity-form>

          <!-- outdoor humidity -->
          <ls-outdoor-humidity-form
            *ngSwitchCase="ElementTypesEnum.OutdoorHumidity"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
          </ls-outdoor-humidity-form>

          <!-- co2 sensor -->
          <ls-co2-sensor-form
            *ngSwitchCase="ElementTypesEnum.CO2"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
          </ls-co2-sensor-form>

          <!-- static pressure -->
          <ls-static-pressure-form
            *ngSwitchCase="ElementTypesEnum.StaticPressure"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
          </ls-static-pressure-form>

          <!-- Lighting sensor -->
          <ls-lighting-sensor-form
            *ngSwitchCase="ElementTypesEnum.LightingSensor"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-lighting-sensor-form>

          <!-- Lighting DO -->
          <ls-lighting-do
            *ngSwitchCase="ElementTypesEnum.LightDO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-lighting-do>

          <!-- Lighting AO -->
          <ls-lighting-ao-form
            *ngSwitchCase="ElementTypesEnum.LightAO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-lighting-ao-form>

          <!-- air speed -->
          <ls-air-speed-form
            *ngSwitchCase="ElementTypesEnum.AirSpeed"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-air-speed-form>

          <!-- ventilation DO (relay) -->
          <ls-ventilation-do-form
            *ngSwitchCase="ElementTypesEnum.VentilationDO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-ventilation-do-form>

          <!-- ventilation AO (analog) -->
          <ls-ventilation-ao-form
            *ngSwitchCase="ElementTypesEnum.VentilationAO"
            [currentSetupNumber]="(currentConnectionSetupData$ | async)?.number"
            [formGroupName]="childGroupName">
            <ng-container *ngIf="(otherElementsSetupData$ | async)?.length">
              <ls-copy-button
                (copy)="copySetup($event)"
                [elements]="otherElementsSetupData$ | async"></ls-copy-button>
            </ng-container>
          </ls-ventilation-ao-form>
        </ng-container>
      </form>

      <!--[class.disabled]="currentConnectionSetupIsNew$ | async"-->
      <ls-button
        *ngIf="helper.isElementTestable(currentElementType)"
        (click)="openTestingPopup()"
        [class.disabled]="true"
        [qaTags]="'qa-btn-test'"
        class="device-button mb-25 pt-20-mobile"
        type="secondary">
        {{ "Buttons.Test" | translate }}
      </ls-button>

      <ls-button
        *ngIf="helper.isElementCalibratable(currentElementType)"
        [qaTags]="'qa-btn-calibrate'"
        class="device-button mb-25 pt-20"
        type="secondary">
        {{ "Buttons.Calibrate" | translate }}
      </ls-button>
    </div>

    <div
      [class.pb-20]="platformService.isMobileApp"
      class="device-buttons d-flex align-center justify-between">
      <!--should be shown only in device, for now it is shown everywhere for presentation purposes-->
      <ls-button
        *ngIf="!platformService.isMobileApp"
        (click)="delete()"
        [class.disabled]="currentConnectionSetupIsNew$ | async"
        [qaTags]="'qa-btn-uninstall'"
        class="device-button"
        type="secondary">
        {{ "Buttons.Uninstall" | translate }}
      </ls-button>

      <div
        [ngClass]="{ 'gap-20 justify-center w-100-percent': platformService.isMobileApp }"
        class="d-flex align-center">
        <ls-button
          (click)="unsetCurrentDeviceType()"
          [qaTags]="'qa-btn-cancel'"
          class="device-button mr-20"
          type="secondary">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>

        <ls-button
          (click)="save()"
          [class.disabled]="parentForm?.invalid || (!this.isDirtyForm && !!(currentElementID$ | async))"
          [qaTags]="'qa-btn-save'"
          class="device-button"
          type="primary">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #noElement>
  <div>not implemented yet</div>
</ng-template>
