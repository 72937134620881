export enum TempMappingStagesEnum {
  FullHouse,
  Brooding1,
  Brooding2,
  Generals,
}

export function tempMappingStagesToTitle(stage: TempMappingStagesEnum): string {
  switch (stage) {
    case TempMappingStagesEnum.FullHouse:
      return 'TemperatureMapping.FullHouse';
    case TempMappingStagesEnum.Brooding1:
      return 'TemperatureMapping.Brooding1';
    case TempMappingStagesEnum.Brooding2:
      return 'TemperatureMapping.Brooding2';
    case TempMappingStagesEnum.Generals:
      return 'TemperatureMapping.General';
    default:
      return '';
  }
}
