import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IControllerView } from '../../interfaces/controller-view.interface';
import { IGeneralSettingsView } from '../../interfaces/general-settings-view.interface';
import { IDateTimeSettingsView } from '../../interfaces/date-time-settings-view.interface';
import { INetworkSettingsView } from '../../interfaces/network-settings-view.interface';
import { ICreateControllerView } from '../../interfaces/create-controller-view.interface';
import { ICreateAddControllerTicketView } from '../../interfaces/create-add-controller-ticket-view.interface';
import { IController } from '@livestock/farms';
import { IHouseSizesView } from '../../interfaces/house-sizes-view.interface';
import { IUpdateDateTimeSettingsView } from '../../interfaces/update-date-time-settings-view.interface';
import { IGeneralSettingsViewNoUnits } from '../../interfaces/general-settings-view-no-units.interface';
import {
  ICreateControllerByConnectionNumberView,
} from '../../interfaces/create-controller-by-connection-number-view.interface';
import { IExecuteControllerByTicketView, ILanSettings } from '@livestock/controllers/interfaces';
import { QuickStartStepsEnum } from '../../enums/quick-start-steps.enum';
import { IFlockBirdWeightView, IFlockSettingsView } from '@livestock/flock';

export * from './upsert-controller-device.actions';

export const getGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Get General Settings [...]',
  props<{ controllerID: number }>(),
);

export const getGeneralSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get General Settings [SUCCESS]',
  props<{ generalSettings: IGeneralSettingsView }>(),
);

export const getGeneralSettingsError = createAction(
  '[UPSERT CONTROLLER] Get General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Set General Settings [...]',
  props<{ generalSettings: IGeneralSettingsView }>(),
);

export const getDateTimeSettings = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [...]',
  props<{ controllerID: number }>(),
);

export const getDateTimeSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [SUCCESS]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const getDateTimeSettingsError = createAction(
  '[UPSERT CONTROLLER] Get Date Time Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setDateTimeSettings = createAction(
  '[UPSERT CONTROLLER] Set Date Time Settings [...]',
  props<{ dateTimeSettings: IDateTimeSettingsView }>(),
);

export const getNetworkSettings = createAction(
  '[UPSERT CONTROLLER] Get Network Settings [...]',
  props<{ controllerID: number }>(),
);

export const getNetworkSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get Network Settings [SUCCESS]',
  props<{ networkSettings: INetworkSettingsView }>(),
);

export const getNetworkSettingsError = createAction(
  '[UPSERT CONTROLLER] Get Network Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setNetworkSettings = createAction(
  '[UPSERT CONTROLLER] Set Network Settings [...]',
  props<{ networkSettings: INetworkSettingsView }>(),
);

export const getLanSettings = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [...]',
  props<{ lan: number; }>(),
);

export const getLanSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [SUCCESS]',
  props<ILanSettings>(),
);

export const getLanSettingsError = createAction(
  '[UPSERT CONTROLLER] Get Lan Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateLanSettings = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [...]',
  props<{ lan: number; }>(),
);

export const updateLanSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [SUCCESS]',
  props<ILanSettings>(),
);

export const updateLanSettingsError = createAction(
  '[UPSERT CONTROLLER] Update Lan Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getHouseSizesSettings = createAction(
  '[UPSERT CONTROLLER] Get House Sizes Settings [...]',
  props<{ controllerID: number }>(),
);

export const getHouseSizesSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Get House Sizes Settings [SUCCESS]',
  props<{ houseSizesSettings: IHouseSizesView }>(),
);

export const getHouseSizesSettingsError = createAction(
  '[UPSERT CONTROLLER] Get House Sizes Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setHouseSizesSettings = createAction(
  '[UPSERT CONTROLLER] Set House Sizes Settings [...]',
  props<{ houseSizesSettings: IHouseSizesView }>(),
);

export const setFlockSettings = createAction(
  '[UPSERT CONTROLLER] Set Flock Settings [...]',
  props<{ flockSettings: IFlockSettingsView }>(),
);

export const setFlockWeights = createAction(
  '[UPSERT CONTROLLER] Set Flock Weights [...]',
  props<{ flockWeights: IFlockBirdWeightView[] }>(),
);

// Create, Update, Delete Actions
export const createController = createAction(
  '[UPSERT CONTROLLER] Create Controller [...]',
  props<{ controller: ICreateControllerView }>(),
);

export const createControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Create Controller [SUCCESS]',
  props<{ controllerID: number }>(),
);

export const createControllerError = createAction(
  '[UPSERT CONTROLLER] Create Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateController = createAction(
  '[UPSERT CONTROLLER] Update Controller [...]',
  props<{ requestView: IControllerView }>(),
);

export const updateControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Update Controller [SUCCESS]',
);

export const updateControllerError = createAction(
  '[UPSERT CONTROLLER] Update Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateGeneralSettings = createAction(
  '[UPSERT CONTROLLER] Update General Settings [...]',
);

export const updateGeneralSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update General Settings [SUCCESS]',
  props<{ generalSettings: IGeneralSettingsViewNoUnits }>(),
);

export const updateGeneralSettingsError = createAction(
  '[UPSERT CONTROLLER] Update General Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateDateTimeSettings = createAction(
  '[UPSERT CONTROLLER] Update Date Time Settings [...]',
);

export const updateDateTimeSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update Date Time Settings [SUCCESS]',
  props<{ dateTimeSettings: IUpdateDateTimeSettingsView }>(),
);

export const updateDateTimeSettingsError = createAction(
  '[UPSERT CONTROLLER] Update Date Time Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateNetworkSettings = createAction(
  '[UPSERT CONTROLLER] Update Network Settings [...]',
);

export const updateNetworkSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update Network Settings [SUCCESS]',
);

export const updateNetworkSettingsError = createAction(
  '[UPSERT CONTROLLER] Update Network Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateHouseSizesSettings = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [...]',
);

export const updateHouseSizesSettingsSuccess = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [SUCCESS]',
);

export const updateHouseSizesSettingsError = createAction(
  '[UPSERT CONTROLLER] Update House Sizes Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteController = createAction(
  '[UPSERT CONTROLLER] Delete Controller [...]',
  props<{ controllerID: number }>(),
);

export const deleteControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Delete Controller [SUCCESS]',
);

export const deleteControllerError = createAction(
  '[UPSERT CONTROLLER] Delete Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

// Ticket Controller
export const createTicketController = createAction(
  '[UPSERT CONTROLLER] Create Ticket Controller [...]',
);

export const createTicketControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Create Ticket Controller [SUCCESS]',
  props<{ ticketView: ICreateAddControllerTicketView }>(),
);

export const createTicketControllerError = createAction(
  '[UPSERT CONTROLLER] Create Ticket Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const executeTicketController = createAction(
  '[UPSERT CONTROLLER] Execute Ticket Controller [...]',
  props<{ requestedView: IExecuteControllerByTicketView, ticketID: string }>(),
);

export const executeTicketControllerSuccess = createAction(
  '[UPSERT CONTROLLER] Execute Ticket Controller [SUCCESS]',
);

export const executeTicketControllerError = createAction(
  '[UPSERT CONTROLLER] Execute Ticket Controller [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const checkIfControllerIsAssignedWithFarm = createAction(
  '[UPSERT CONTROLLER] Check If Controller Is Assigned With Farm  [...]',
);

export const setIsControllerAssignedWithFarm = createAction(
  '[UPSERT CONTROLLER] Set Is Controller Assigned With Farm [...]',
  props<{ isControllerAssignedWithFarm: boolean }>(),
);

export const createControllerByConnectionNumber = createAction(
  '[UPSERT CONTROLLER] Create Controller By Connection Number [...]',
  props<{ connectionInfo: ICreateControllerByConnectionNumberView }>(),
);

export const createControllerByConnectionNumberSuccess = createAction(
  '[UPSERT CONTROLLER] Create Controller By Connection Number [SUCCESS]',
  props<{ controller: IController }>(),
);

export const createControllerByConnectionNumberError = createAction(
  '[UPSERT CONTROLLER] Create Controller By Connection Number [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearIsControllerCreatedOrExecuted = createAction(
  '[UPSERT CONTROLLER] Clear Is Controller Created Or Executed [...]',
);

export const clearUpsertControllerState = createAction(
  '[UPSERT CONTROLLER] Clear Upsert Controller State [...]',
);

export const goToNextStep = createAction(
  '[UPSERT CONTROLLER] Go To Next Step [...]',
);

export const goToStep = createAction(
  '[UPSERT CONTROLLER] Go Step [...]',
  props<{ payload: QuickStartStepsEnum }>(),
);

export const goToPrevStep = createAction(
  '[UPSERT CONTROLLER] Go To Prev Step [...]',
);

export const goToDateTimeSettingsStep = createAction(
  '[UPSERT CONTROLLER] Go To Date Time Settings Step [...]',
);
