import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import {
  LightingElement,
  LightingPeriod,
  LightingProgram,
  SingleLightingProgram,
} from '../interfaces/lighting.interfaces';

export const setCurrentLightingProgram = createAction(
  '[LIGHTING PROGRAM] Set lighting program',
  props<{ currentProgram: SingleLightingProgram }>(),
);

export const setCurrentLightingPeriod = createAction(
  '[LIGHTING PROGRAM] Set lighting period',
  props<{ currentPeriod: LightingPeriod }>(),
);

export const getLightingProgram = createAction(
  '[LIGHTING PROGRAM] Get lighting program [...]',
  props<{ periodID: number }>(),
);

export const getLightingProgramSuccess = createAction(
  '[LIGHTING PROGRAM] Get lighting program [SUCCESS]',
  props<{ item: SingleLightingProgram }>(),
);

export const getLightingProgramError = createAction(
  '[LIGHTING PROGRAM] Get lighting program [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const deleteLightingProgram = createAction(
  '[LIGHTING PROGRAM] Delete lighting program [...]',
  props<{ periodID: number, programID: number }>(),
);

export const deleteLightingProgramSuccess = createAction(
  '[LIGHTING PROGRAM] Delete lighting program [SUCCESS]',
  props<{ periodID: number, programID: number }>(),
);

export const deleteLightingProgramError = createAction(
  '[LIGHTING PROGRAM] Delete lighting program [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const getLightingPeriod = createAction(
  '[LIGHTING PROGRAM] Get lighting period [...]',
);

export const getLightingPeriodSuccess = createAction(
  '[LIGHTING PROGRAM] Get lighting period [SUCCESS]',
  props<{
    items: LightingPeriod[],
    controllerID: number,
    lightDOElements: LightingElement[],
    lightAOElements: LightingElement[];
  }>(),
);

export const getLightingPeriodError = createAction(
  '[LIGHTING PROGRAM] Get lighting period [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const addLightingDay = createAction(
  '[LIGHTING PROGRAM] Add lighting day [...]',
  props<{ dayNumber: number }>(),
);

export const deleteLightingDay = createAction(
  '[LIGHTING PROGRAM] Delete lighting day [...]',
  props<{ periodID: number, preventSelecting?: boolean }>(),
);

// mobile --> clear data when add new day
export const clearProgram = createAction(
  '[LIGHTING PROGRAM] Clear program [...]',
);

// mobile --> remove unsaved day from list days when cancel editing
export const filterPeriods = createAction(
  '[LIGHTING PROGRAM] Filter periods [...]',
  props<{ currentPeriod: LightingPeriod }>(),
);

export const deleteLightingDaySuccess = createAction(
  '[LIGHTING PROGRAM] Delete lighting day [SUCCESS]',
  props<{ periodID: number, preventSelecting?: boolean, preventLoading?: boolean }>(),
);

export const addLightingDaySuccess = createAction(
  '[LIGHTING PROGRAM] Add lighting day [SUCCESS]',
  props<{ dayNumber: number, controllerID: number }>(),
);

export const addLightingDayError = createAction(
  '[LIGHTING PROGRAM] Add lighting day [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const saveLightingProgram = createAction(
  '[LIGHTING PROGRAM] Save lighting program [...]',
  props<{
    period: LightingPeriod, items: LightingProgram[], itemsToDelete: {
      programID: number,
      programIndex: number,
      periodID: number
    }[]
  }>());

export const saveLightingProgramSuccess = createAction(
  '[LIGHTING PROGRAM] Save lighting program [SUCCESS]',
  props<{ period: LightingPeriod, items: LightingProgram[] }>(),
);

export const saveLightingProgramError = createAction(
  '[LIGHTING PROGRAM] Save lighting program [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const resetLightingProgram = createAction(
  '[LIGHTING PROGRAM] Reset lighting program [RESET]',
);
