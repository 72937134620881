import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ColorsEnum } from '@livestock/shared/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { Store } from '@ngrx/store';
import {
  clearCurrentElementTypeAndConnectionSetup,
  getControllerCards,
} from '../../+state/installation.actions';
import { selectCards, selectInstallationIsLoading } from '@livestock/installation';
import { Observable, Subscription, combineLatest, distinctUntilChanged, filter, map } from 'rxjs';
import { CardType } from '../../enums/card-type.enum';
import { ConnectionType } from '../../enums/connection-type.enum';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { LanguageService, MenuService, PlatformService } from '@livestock/shared/services';
import { IConnection } from '../../interfaces/connection.interface';
import { ICard } from '../../interfaces/card.interface';
import { TranslateService } from '@ngx-translate/core';
import { FilterCollectionPipe } from '@livestock/shared/pipes';
import { InstallationRoutes } from '../../module/installation-routes';
import { setActiveControllerID } from '@livestock/controllers';
import { GlobalConstants } from '@livestock/shared/constants';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-installation',
  templateUrl: './installation.component.html',
  styleUrls: ['./installation.component.scss'],
})
export class InstallationComponent implements OnInit, OnDestroy {
  // subs
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectInstallationIsLoading);
  cards$ = this.store.select(selectCards);
  analogCards$ = this.cards$.pipe(
    map((cards) => cards?.filter((i) => i.cardType === CardType.Analog).sort((ca, cb) => ca.number - cb.number)),
  );
  relayCards$ = this.cards$.pipe(
    map((cards) => cards
      .filter((i) => i.cardType === CardType.Relay)
      .sort((a, b) => a.number - b.number),
    ),
  );
  scaleCard$ = this.cards$.pipe(
    map((cards) => cards.filter((i) => i.cardType === CardType.Scale)[0]),
  );
  digInpCard$ = this.cards$.pipe(
    map((cards) => cards.filter((i) => i.cardType === CardType.DigitalInput)[0]),
  );

  //constants
  ColorsEnum = ColorsEnum;
  ConnectionType = ConnectionType;
  ElementTypesEnum = ElementTypesEnum;
  GlobalConstants = GlobalConstants;

  //vars
  selectedCard: ICard = null;
  selectedCardTitle: string;
  isSelectedOutput: boolean;

  constructor(
    public platformService: PlatformService,
    public menuService: MenuService,
    public languageService: LanguageService,
    public filterCollection: FilterCollectionPipe,
    private router: Router,
    private store: Store,
    private translateService: TranslateService,
    private activatedRoute: ActivatedRoute,
  ) {
    if (this.platformService.isDeviceApp) {
      this.store.dispatch(setActiveControllerID({ controllerID: GlobalConstants.HardCodedControllerIDForDevice }));
    }
  }

  ngOnInit(): void {
    this.store.dispatch(getControllerCards());

    this.sub$.add(
      combineLatest([
        this.activatedRoute.queryParams.pipe(
          filter((params) => params && 'cardType' in params && 'cardNumber' in params),
          distinctUntilChanged((a, b) => a['cardType'] === b['cardType'] && a['cardNumber'] === b['cardNumber']),
        ),
        this.cards$.pipe(
          filter((cards) => !!cards),
          wasChanged(),
        ),
      ]).subscribe(([params, cards]) => {
        const selectedCardType = parseInt(params['cardType']);
        const selectedCardNumber = parseInt(params['cardNumber']);
        const connectionType = parseInt(params['connectionType']);
        if (selectedCardType != null && selectedCardNumber != null) {
          const card = cards.find((card) => card.number === selectedCardNumber && card.cardType === selectedCardType);
          const isOutput = connectionType === ConnectionType.AnalogOutput;
          this.selectCard(card, isOutput);
        }
      }),
    );
  }

  navigateToCard(cardID: number, cardNumber: number, cardType: CardType, connectionType?: ConnectionType): void {
    this.router.navigate([`${AppRoutes.INSTALLATION}/card/${cardID}/cardType/${cardType}`], {
      queryParams: {
        cardNumber,
        connectionType,
      },
    });
  }

  getFilledConnections(connections: IConnection[]): IConnection[] {
    return connections.filter(i => i.element?.elementType);
  }

  selectCard(card: ICard, isOutput?: boolean): void {
    if (!card) return;

    this.selectedCard = card;
    const analogType = isOutput ? 'Installation.Main.Output' : 'Installation.Main.Input';
    this.isSelectedOutput = isOutput;
    this.selectedCardTitle = `${this.translateService.instant(CardType.toTranslateKey(card.cardType))}
    ${card.cardType === CardType.Analog || card.cardType === CardType.Relay ? card.number : ''}
    ${card.cardType === CardType.Analog ? this.translateService.instant(analogType) : ''} `;
  }

  getConnections(selectedCard: ICard): IConnection[] {
    if (selectedCard.cardType === CardType.Analog) {
      return this.filterCollection.transform(selectedCard.connections,
        this.isSelectedOutput ?
          ConnectionType.AnalogOutput :
          ConnectionType.AnalogInput,
        'connectionType', true);
    }
    return selectedCard.connections;
  }

  openConnection(connection: IConnection): void {
    if (!connection.element || !this.platformService.isMobileApp) {
      return;
    }

    const { connectionID, elementType, elementID } = connection.element;
    this.router.navigate([InstallationRoutes.createActiveElementLink(
      connection.cardID,
      this.selectedCard.cardType,
      elementID,
      elementType,
      connectionID,
    )], {
      queryParams: {
        cardNumber: this.selectedCard.number,
        connectionNumber: connection.number,
        connectionType: connection.connectionType,
      },
    });
  }

  cancel(): void {
    this.menuService.toggleDashboardMenu(true);
  }

  resetSelectedCard(): void {
    this.selectedCard = null;
    this.router.navigate([], {
      queryParams: {
        'cardNumber': null,
      },
      queryParamsHandling: 'merge',
    });
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearCurrentElementTypeAndConnectionSetup({}));
    this.sub$.unsubscribe();
  }
}
