import { Component } from '@angular/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { MainWidgetCommonComponent } from '../main-widget-common.component';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, NgChartsModule, QaTagsDirective],
  selector: 'ls-main-air-quality-widget',
  templateUrl: './main-air-quality-widget.component.html',
  styleUrls: ['./main-air-quality-widget.component.scss'],
})
export class MainAirQualityWidgetComponent extends MainWidgetCommonComponent {
  barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    plugins: {
      legend: {
        align: 'end',
        labels: {
          padding: 10,
          font: {
            size: 10,
          },
        },
      },
    },
  };
  barChartLabels = ['Mon', 'Tue'];
  barChartLegend = true;
  barChartPlugins = [];
  barChartData: ChartDataset[] = [
    {
      data: [45, 37],
      label: 'Humidity',
    },
    {
      data: [12, 37],
      label: 'CO2',
    },
    {
      data: [45, 22],
      label: 'Ammonia',
    },
  ];
}
