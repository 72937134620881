<div
  *ngIf="!isLoading && form; else loading"
  [class.mobile-styles]="platformService.isMobileApp"
  [class.show-mode]="!editMode"
  [formGroup]="form"
  class="item d-flex flex-column">
  <div class="item__container">
    <div
      *ngIf="!platformService.isMobileApp; else mobileView"
      #table
      [class.table-border-bottom]="numberOfItems >= 10"
      class="table w-1-25 w-2-25 w-3-25 w-4-25">
      <div class="table-row table-header h-60">
        <div class="table-cell blue">
          {{ "AirTreatment.Day" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.Humidity" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.CO2" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.Ammonia" | translate }}
        </div>
      </div>

      <ng-container formArrayName="items">
        <ng-container
          *ngFor="let itemForm of form.get('items')['controls']; let index = index; let isLast = last"
          [formGroup]="itemForm">
          <div
            (click)="setActiveIndex(index)"
            [class.active]="activeIndex === index && editMode"
            [class.last-row]="isLast && activeIndex !== index"
            class="table-row h-60 padding-top-11">
            <div
              [class.blue]="platformService.isDeviceApp"
              class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ itemForm.value?.day }}
              </div>
              <div *ngIf="editMode">
                <ls-input-integer
                  (change)="checkDuplicacy()"
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxDay"
                  [min]="AirTreatmentConstants.MinDay"
                  [qaTags]="'qa-txt-day'"
                  [showTooltipOnHover]="true"
                  class="align-center w-input-115"
                  errorField
                  formControlName="day">
                </ls-input-integer>
              </div>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ itemForm.value?.humidity }}
                <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
              </div>
              <ls-input-integer
                *ngIf="editMode"
                [fieldWithKeyboard]="true"
                [max]="AirTreatmentConstants.MaxPercentage"
                [min]="AirTreatmentConstants.MinPercentage"
                [qaTags]="'qa-txt-humidity'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal w-input-115"
                errorField
                formControlName="humidity">
                <ng-container suffix>
                  <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                </ng-container>
              </ls-input-integer>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ itemForm.value?.co2 }}
                <div class="unit">{{ "Ppm" | translate }}</div>
              </div>
              <div
                *ngIf="editMode"
                class="d-flex flex-row align-center">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxPPM"
                  [min]="AirTreatmentConstants.MinPPM"
                  [qaTags]="'qa-txt-co2'"
                  [showTooltipOnHover]="true"
                  class="align-center w-input-115"
                  errorField
                  formControlName="co2">
                </ls-input-integer>
                <div class="unit">{{ "Ppm" | translate }}</div>
              </div>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ itemForm.value?.ammonia }}
                <div class="unit">{{ "Ppm" | translate }}</div>
              </div>
              <div
                *ngIf="editMode"
                class="d-flex flex-row align-center">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxPercentage"
                  [min]="AirTreatmentConstants.MinPercentage"
                  [qaTags]="'qa-txt-ammonia'"
                  [showTooltipOnHover]="true"
                  class="align-center w-input-115"
                  errorField
                  formControlName="ammonia">
                </ls-input-integer>
                <div class="unit">{{ "Ppm" | translate }}</div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #mobileView>
      <div
        *ngIf="activeDay | memoizeFunc : getActiveFormGroup : this as itemForm"
        [formGroup]="itemForm"
        class="mobile-view">
        <span
          *ngIf="!editMode"
          class="days-caption"
          >{{ "AirTreatment.Days" | translate }}</span
        >
        <div
          *ngIf="editMode"
          class="d-flex flex-row justify-between align-end mb-15">
          <div class="d-flex flex-column position-relative">
            <span>{{ "AirTreatment.Day" | translate }}</span>
            <ls-input-integer
              (change)="changeDay($event)"
              [fieldWithKeyboard]="true"
              [max]="AirTreatmentConstants.MaxDay"
              [min]="AirTreatmentConstants.MinDay"
              [qaTags]="'qa-txt-day'"
              class="align-center w-input-80 mt-5"
              errorField
              formControlName="day">
            </ls-input-integer>
          </div>
          <ls-days-manager-mobile
            (addDay)="addDay()"
            (deleteDay)="removeRow()">
          </ls-days-manager-mobile>
        </div>

        <div class="d-flex flex-row">
          <div
            *ngIf="!editMode"
            class="program-days mr-20">
            <div class="program-days__scrollbar">
              <div
                *ngFor="let day of days"
                (click)="setActiveDay(day)"
                [class.program-day__active]="day === activeDay"
                class="program-day">
                {{ day }}
              </div>
            </div>
          </div>

          <div
            [class.w-1-35]="editMode"
            [class.w-1-50]="!editMode"
            [class.w-2-50]="!editMode"
            [class.w-2-65]="editMode"
            class="table">
            <div class="table-row">
              <div class="table-cell blue">{{ "AirTreatment.Humidity" | translate }}</div>
              <div class="table-cell">
                <div
                  *ngIf="!editMode"
                  class="d-flex flex-row align-center">
                  {{ itemForm.value?.humidity }}
                  <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                </div>
                <ls-input-integer
                  *ngIf="editMode"
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxPercentage"
                  [min]="AirTreatmentConstants.MinPercentage"
                  [qaTags]="'qa-txt-humidity'"
                  class="align-center input-decimal w-input-154 position-absolute-forced"
                  errorField
                  formControlName="humidity">
                  <ng-container suffix>
                    <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                  </ng-container>
                </ls-input-integer>
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell blue">{{ "AirTreatment.CO2" | translate }}</div>
              <div class="table-cell">
                <div
                  *ngIf="!editMode"
                  class="d-flex flex-row align-center">
                  {{ itemForm.value?.co2 }}
                  <div class="unit">{{ "Ppm" | translate }}</div>
                </div>
                <ls-input-integer
                  *ngIf="editMode"
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxPPM"
                  [min]="AirTreatmentConstants.MinPPM"
                  [qaTags]="'qa-txt-co2'"
                  class="align-center input-decimal w-input-154 position-absolute-forced"
                  errorField
                  formControlName="co2">
                  <ng-container suffix>
                    <div class="unit">{{ "Ppm" | translate }}</div>
                  </ng-container>
                </ls-input-integer>
              </div>
            </div>
            <div class="table-row">
              <div class="table-cell blue">{{ "AirTreatment.Ammonia" | translate }}</div>
              <div class="table-cell">
                <div
                  *ngIf="!editMode"
                  class="d-flex flex-row align-center">
                  {{ itemForm.value?.ammonia }}
                  <div class="unit">{{ "Ppm" | translate }}</div>
                </div>
                <ls-input-integer
                  *ngIf="editMode"
                  [fieldWithKeyboard]="true"
                  [max]="AirTreatmentConstants.MaxPercentage"
                  [min]="AirTreatmentConstants.MinPercentage"
                  [qaTags]="'qa-txt-ammonia'"
                  class="align-center input-decimal w-input-154 position-absolute-forced"
                  errorField
                  formControlName="ammonia">
                  <ng-container suffix>
                    <div class="unit">{{ "Ppm" | translate }}</div>
                  </ng-container>
                </ls-input-integer>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ng-template>
  </div>

  <div class="buttons-container">
    <div
      *ngIf="editMode"
      class="buttons__add-delete d-flex flex-row">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #buttons>
  <div class="d-flex align-center">
    <ng-container *ngIf="!platformService.isMobileApp && editMode">
      <ls-button
        (click)="addEmptyRow()"
        [class.disabled]="form.invalid || numberOfItems >= AirTreatmentConstants.MaxDay"
        [qaTags]="'qa-btn-add-day'"
        [type]="'secondary'"
        class="page__footer__button mr-20">
        {{ "AirTreatment.AddDay" | translate }}
      </ls-button>
      <ls-button
        (click)="removeRow()"
        [class.disabled]="activeIndex == null"
        [qaTags]="'qa-btn-delete-day'"
        [type]="'secondary'"
        cclass="page__footer__button">
        {{ "AirTreatment.DeleteDay" | translate }}
      </ls-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
