<div
  *ngIf="form"
  class="popup text-left {{ dialogCssClass }}">
  <div [formGroup]="form">
    <h3
      [ngClass]="{ 'justify-between': !platformService.isMobileApp }"
      class="popup-title fz-20 mb-10 ml-12 mr-12 d-flex align-center">
      <ls-svg-icon
        *ngIf="platformService.isMobileApp"
        (click)="close(null)"
        [qaTags]="'qa-btn-close'"
        class="icon pointer mh-10"
        path="arrows/chevron-left-black">
      </ls-svg-icon>
      <span>{{ translateFieldTitle ? (title | translate) : title }}</span>
      <img
        *ngIf="!platformService.isMobileApp"
        (click)="close(null)"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="pointer"
        src="/assets/icons/common/cross.svg" />
    </h3>
    <p
      *ngIf="options?.additionalInfo"
      class="fz-18">
      {{ options.additionalInfo | translate }}
    </p>
    <ls-search
      *ngIf="options?.searchable"
      [ngModelOptions]="{ standalone: true }"
      [qaTags]="'qa-txt-search'"
      [style]="'light'"
      [(ngModel)]="searchValue" />

    <div
      [class.ls-radio-container__calcucated-height]="options?.searchable"
      class="ls-radio-container mt-10">
      <mat-radio-group
        *ngIf="!options?.list; else selectWithoutEnum"
        (change)="changeValue($event)"
        [qaTags]="'qa-radio-group'"
        aria-labelledby="ls-radio-group-label"
        class="ls-radio-group"
        formControlName="field">
        <ng-container *ngFor="let el of options.enum | enumToArray">
          <mat-radio-button
            *ngIf="!options.visibleEnumItems || options.visibleEnumItems.includes(el)"
            [qaTags]="'qa-radio-group-button-' + el"
            [value]="el"
            class="ls-radio-group__button overrided-mat-radio-button">
            {{ el | enum : options.enumString | translate }}
          </mat-radio-button>
        </ng-container>
      </mat-radio-group>

      <!-- For selects without enum -->
      <ng-template #selectWithoutEnum>
        <div
          *ngFor="let el of options.list | filterCollection : searchValue; index as i"
          [class.disabled]="el.disabled"
          class="option d-flex align-center">
          <input
            (change)="changeValue({ value: el[bindValue] })"
            [checked]="options.value === el[bindValue]"
            id="{{ i }}"
            class="mb-3"
            name="tz"
            type="radio" />
          <label for="{{ i }}">
            <span [innerHTML]="el[bindLabel]"></span>
            <span
              *ngIf="!!el[bindSubLabel]"
              class="ls-radio-group__sub-label">
              {{ el[bindSubLabel] }}
            </span>
          </label>
        </div>
      </ng-template>
    </div>
  </div>

  <div class="buttons d-flex align-center justify-center">
    <ng-container *ngFor="let button of buttons">
      <ls-button
        (click)="close(button)"
        [class]="button.class"
        [ngClass]="button.qaClass"
        [type]="button.lsButtonType"
        class="ml-5 mr-5">
        {{ button.text | translate }}
      </ls-button>
    </ng-container>
  </div>
</div>

<ls-keyboard
  *ngIf="options.searchable"
  [class.rtl]="languageService.isRtl"
  [showDecimalDot]="true"
  class="d-none-md date-time-keyboard" />
