<div class="widget-wrapper">
  <h6
    [qaTags]="'qa-lbl-widget-title'"
    class="widget-title">
    Daily consumption (Total / Per bird)
  </h6>

  <div class="widget-body">
    <div class="widget-content d-flex justify-between">
      <div class="d-flex mr-25-md">
        <div class="widget-icon mr-30 pl-10">
          <img
            [qaTags]="'qa-img-widget qa-img-daily-feed'"
            alt="feed"
            src="/assets/icons/dashboard/feed.svg" />
        </div>

        <div class="d-flex flex-column">
          <div
            [qaTags]="'qa-lbl-daily-feed-value'"
            class="widget-content__value">
            2,800<span
              [qaTags]="'qa-lbl-daily-feed-units'"
              class="fz-16"
              >/0.1Lb</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-daily-feed-lbl'"
            class="widget-content__subtitle"
            >Feed</span
          >
        </div>
      </div>

      <div class="d-flex">
        <div class="widget-icon water-icon mr-30">
          <img
            [qaTags]="'qa-img-widget qa-img-water'"
            alt="water"
            src="/assets/icons/dashboard/water.svg" />
        </div>

        <div class="d-flex flex-column">
          <div
            [qaTags]="'qa-lbl-daily-water-value'"
            class="widget-content__value">
            84<span
              [qaTags]="'qa-lbl-daily-water-units'"
              class="fz-16"
              >/0.2 Gal</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-daily-water-lbl'"
            class="widget-content__subtitle"
            >Water</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
