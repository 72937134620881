<div
  *ngIf="form"
  [formGroup]="form"
  [ngClass]="editMode ? 'content-form mr-20 mr-0-md' : 'content-default'"
  class="content w-100-percent">
  <div class="w-100-percent d-flex flex-column-md mb-20-md">
    <!--BasicMidVentilation-->
    <div class="content-table__wrapper flex-grow-1 mb-20-md">
      <h4 class="content-title">{{ "StaticPressure.BasicMidVentilation" | translate }}</h4>

      <div class="content-table">
        <div class="content-table__row">
          <div class="content-table__title">{{ "StaticPressure.Band" | translate }}</div>
          <div class="content-table__value content-table__value-long">
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['bandVentilation'].max"
              [min]="minMaxValues?.['bandVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="bandVentilation">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div *ngIf="!editMode">
              {{ form.value.bandVentilation.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>

        <div class="content-table__row content-table__row-high">
          <div class="content-table__title">{{ "StaticPressure.OutsideTemp" | translate }}</div>

          <div class="content-table__value">
            <h6>{{ "StaticPressure.Cold" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="1"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['outsideTempColdVentilation'].max"
              [min]="minMaxValues?.['outsideTempColdVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="outsideTempColdVentilation">
              <ng-container
                *ngTemplateOutlet="temperatureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.outsideTempColdVentilation.toFixed(temperatureAccuracy) }}
              <span class="ml-3">{{
                TemperatureUnitEnum.toShortTranslateKey(temperatureUnit$ | async) | translate
              }}</span>
            </div>
          </div>

          <div class="content-table__value">
            <h6>{{ "StaticPressure.Warm" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="1"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['outsideTempWarmVentilation'].max"
              [min]="minMaxValues?.['outsideTempWarmVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="outsideTempWarmVentilation">
              <ng-container
                *ngTemplateOutlet="temperatureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.outsideTempWarmVentilation.toFixed(temperatureAccuracy) }}
              <span class="ml-3">{{
                TemperatureUnitEnum.toShortTranslateKey(temperatureUnit$ | async) | translate
              }}</span>
            </div>
          </div>
        </div>

        <div class="content-table__row content-table__row-high">
          <div class="content-table__title">{{ "StaticPressure.TargetPressure" | translate }}</div>
          <div class="content-table__value">
            <h6>{{ "StaticPressure.Cold" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['targetPressureColdVentilation'].max"
              [min]="minMaxValues?.['targetPressureColdVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="targetPressureColdVentilation">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.targetPressureColdVentilation.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>

          <div class="content-table__value">
            <h6>{{ "StaticPressure.Warm" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['targetPressureWarmVentilation'].max"
              [min]="minMaxValues?.['targetPressureWarmVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="targetPressureWarmVentilation">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.targetPressureWarmVentilation.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>

        <div class="content-table__row content-table__row-high">
          <div class="content-table__title">{{ "StaticPressure.PressureAlarm" | translate }}</div>
          <div class="content-table__value">
            <h6>{{ "StaticPressure.Low" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['pressureAlarmLowVentilation'].max"
              [min]="minMaxValues?.['pressureAlarmLowVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="pressureAlarmLowVentilation">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.pressureAlarmLowVentilation.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>

          <div class="content-table__value">
            <h6>{{ "StaticPressure.High" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['pressureAlarmHightVentilation'].max"
              [min]="minMaxValues?.['pressureAlarmHightVentilation'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="pressureAlarmHightVentilation">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.pressureAlarmHightVentilation.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Tunnel-->
    <div class="content-table__wrapper flex-grow-1 ml-20 ml-0-md">
      <h4 class="content-title">{{ "StaticPressure.Tunnel" | translate }}</h4>

      <div class="content-table">
        <div class="content-table__row">
          <div class="content-table__title">{{ "StaticPressure.Band" | translate }}</div>
          <div class="content-table__value content-table__value-long">
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['bandTunnel'].max"
              [min]="minMaxValues?.['bandTunnel'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="bandTunnel">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div *ngIf="!editMode">
              {{ form.value.bandTunnel.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>

        <div class="content-table__row content-table__row-high">
          <div class="content-table__title">{{ "StaticPressure.TargetPressure" | translate }}</div>
          <div class="content-table__value content-table__value-long">
            <h6>{{ "StaticPressure.Cold" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['targetPressureTunnel'].max"
              [min]="minMaxValues?.['targetPressureTunnel'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="targetPressureTunnel">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.targetPressureTunnel.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>

        <div class="content-table__row content-table__row-high">
          <div class="content-table__title">{{ "StaticPressure.PressureAlarm" | translate }}</div>
          <div class="content-table__value">
            <h6>{{ "StaticPressure.Low" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['pressureAlarmLowTunnel'].max"
              [min]="minMaxValues?.['pressureAlarmLowTunnel'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="pressureAlarmLowTunnel">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.pressureAlarmLowTunnel.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>

          <div class="content-table__value">
            <h6>{{ "StaticPressure.High" | translate }}</h6>
            <ls-input-decimal
              *ngIf="editMode"
              [accuracy]="pressureAccuracy"
              [fieldWithKeyboard]="true"
              [max]="minMaxValues?.['pressureAlarmHightTunnel'].max"
              [min]="minMaxValues?.['pressureAlarmHightTunnel'].min"
              [showTooltipOnHover]="true"
              class="input-decimal w-100-percent"
              errorField
              formControlName="pressureAlarmHightTunnel">
              <ng-container
                *ngTemplateOutlet="pressureUnitTemplate"
                suffix>
              </ng-container>
            </ls-input-decimal>

            <div
              *ngIf="!editMode"
              class="ml-5">
              {{ form.value.pressureAlarmHightTunnel.toFixed(pressureAccuracy) }}
              <span class="ml-3">{{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div
    *ngIf="editMode"
    class="content-buttons d-flex justify-end justify-center-md">
    <ls-button
      (click)="resetForm()"
      [qaTags]="'qa-btn-cancel'"
      [class.w-button-130]="platformService.isMobileApp"
      [class.w-button-120]="!platformService.isMobileApp"
      class="mr-28 h-button-36"
      type="secondary">
      {{ "Buttons.Cancel" | translate }}
    </ls-button>

    <ls-button
      (click)="save()"
      [class.disabled]="form?.invalid || !this.isDirtyForm"
      [qaTags]="'qa-btn-save'"
      [class.w-button-130]="platformService.isMobileApp"
      [class.w-button-120]="!platformService.isMobileApp"
      class="h-button-36"
      type="primary">
      {{ "Buttons.Save" | translate }}
    </ls-button>
  </div>
</div>

<ng-template #pressureUnitTemplate>
  <span class="unit">
    {{ AirPressureUnitEnum.toShortTranslateKey(pressureUnit$ | async) | translate }}
  </span>
</ng-template>

<ng-template #temperatureUnitTemplate>
  <span class="unit">
    {{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit$ | async) | translate }}
  </span>
</ng-template>
