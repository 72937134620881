import { Action, createReducer, on } from '@ngrx/store';
import * as heatingProgramActions from './heating-program.actions';
import { HeatingProgramState } from '@livestock/heating-program';

export const initialState: HeatingProgramState = {
    isLoading: false,
    programs: null,
    programZones: null,
    isDirty: false,
    originalPrograms: null,
};

const reducer = createReducer(
    initialState,
    on(
        heatingProgramActions.getHeatingPrograms,
        heatingProgramActions.updateHeatingPrograms,
        heatingProgramActions.deleteHeatingProgram,
        (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    ),
    on(
        heatingProgramActions.getHeatingProgramsError,
        heatingProgramActions.updateHeatingProgramsError,
        heatingProgramActions.deleteHeatingProgramError,
        (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    ),
    on(heatingProgramActions.getHeatingProgramsSuccess,
        (state, { view }) => {
            return {
                ...state,
                programs: view.items,
                originalPrograms: view.items,
                programZones: view.heatingZones,
                isLoading: false,
                isDirty: false,
            };
        }),
    on(heatingProgramActions.setHeatingPrograms,
        (state, { programs }) => {
            return {
                ...state,
                programs,
                isDirty: JSON.stringify(state.originalPrograms) !== JSON.stringify(state.programs),
            };
        }),
    on(heatingProgramActions.updateHeatingProgramsSuccess,
        (state, { updatedPrograms }) => {
            return {
                ...state,
                isLoading: false,
                programs: updatedPrograms,
                originalPrograms: updatedPrograms,
                isDirty: false,
            };
        }),
    on(heatingProgramActions.deleteHeatingProgramSuccess,
        (state, { programID }) => {
            const filteredPrograms = state.programs.filter(x => x.programID !== programID);
            return {
                ...state,
                isLoading: false,
                programs: filteredPrograms,
                originalPrograms: filteredPrograms,
                isDirty: false,
            };
        }),
    on(heatingProgramActions.clearHeatingProgramsState,
        () => {
            return {
                ...initialState,
            };
        }),
);

export function heatingProgramReducer(state: HeatingProgramState | undefined, action: Action): any {
    return reducer(state, action);
}