<span
  *ngIf="overlaySvgIcon != ''"
  [class.mirror-rtl]="mirrorRtl"
  [innerHTML]="overlaySvgIcon"
  [ngClass]="fullHeight ? 'full-height w-100-percent h-100-percent' : ''"
  [style.color]="color"
  class="d-flex align-center overlay-svg-icon {{ overlayIconPosition }}">
</span>
<span
  [class.mirror-rtl]="mirrorRtl"
  [class.mr-5]="hasMargin"
  [innerHTML]="svgIcon"
  [ngClass]="fullHeight ? 'full-height w-100-percent h-100-percent' : ''"
  [style.color]="color"
  class="d-flex align-center">
</span>
