<div
  *ngIf="showLoader"
  @fadeOut
  class="holder">
  <div class="preloader">
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
</div>
