import { TemperatureUnitEnum } from '@livestock/shared/enums';

export class HeatingProgramConstants {
    static MaxCountOfPrograms = 15;

    static MaxCountOfHeatingElements = 15;
    static MaxCountOfSensors = 12;

    static MinTemperatureCelsius = -5;
    static MinTemperatureFahrenheit = 23;
    static MaxTemperatureCelsius = 5;
    static MaxTemperatureFahrenheit = 41;

    static OuputMax = 100;

    static getMaxTemperatureByUnit(temperatureUnit: TemperatureUnitEnum): number {
        switch (temperatureUnit) {
            case TemperatureUnitEnum.Celsius:
                return HeatingProgramConstants.MaxTemperatureCelsius;
            case TemperatureUnitEnum.Fahrenheit:
                return HeatingProgramConstants.MaxTemperatureFahrenheit;
            default:
                return HeatingProgramConstants.MaxTemperatureCelsius;
        }
    }

    static getMinTemperatureByUnit(temperatureUnit: TemperatureUnitEnum): number {
        switch (temperatureUnit) {
            case TemperatureUnitEnum.Celsius:
                return HeatingProgramConstants.MinTemperatureCelsius;
            case TemperatureUnitEnum.Fahrenheit:
                return HeatingProgramConstants.MinTemperatureFahrenheit;
            default:
                return HeatingProgramConstants.MinTemperatureCelsius;
        }
    }
}