<ng-container *ngIf="!(isLoading$ | async); else loading">
  <ls-header-title
    (cancel)="returnToInstallationMain()"
    [additionalTitleValue]="cardNumber"
    [qaTagsForTitle]="'qa-lbl-install-type'"
    [title]="[cardType, connectionType] | memoizeFunc : CardType.toTranslateKeyWithValue"
    class="d-block mb-30 mb-18-device"></ls-header-title>

  <ls-card-items
    [cardID]="cardID"
    [connectionType]="connectionType"
    [elementType]="currentElementType$ | async"
    class="d-block mb-15"></ls-card-items>

  <div
    [class.card-body__no-settings]="!(currentElementType$ | async | memoizeFunc : helper.isElementHasSettings)"
    class="card-body d-flex justify-between">
    <ls-device-types [elementTypes]="elementTypes"></ls-device-types>

    <ls-active-element></ls-active-element>

    <div
      [ngClass]="
        (currentElementType$ | async | memoizeFunc : helper.isElementHasSettings) ? 'justify-between' : 'justify-end'
      "
      class="card-body__left d-flex flex-column align-end">
      <ls-svg-icon
        *ngIf="currentElementType$ | async | memoizeFunc : helper.isElementHasSettings"
        (click)="navigateToSettings()"
        [color]="(currentElementID$ | async) ? ColorsEnum.Default : ColorsEnum.DefaultDisabled"
        [qaTags]="'qa-btn-settings'"
        class="pointer"
        path="installation/settings"></ls-svg-icon>
      <div class="keyboard mt-10">
        <ls-keyboard class="d-block w-100-percent"></ls-keyboard>
      </div>
    </div>
  </div>
</ng-container>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
