import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-button-with-icon',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
  ],
  templateUrl: './button-with-icon.component.html',
  styleUrls: ['./button-with-icon.component.scss'],
})
export class ButtonWithIconComponent {
  @Input() textContent: string;
  @Input() marginAuto: boolean;
  @Input() iconPath: string;
  @Input() selected: boolean;
  @Input() buttonType: 'primary' | 'secondary' | 'disabled' = 'primary';
  @Input() location: 'before' | 'after' = 'before';
  @Input() qaTags: string;
}
