import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IElementTestingData } from '../interfaces/element/element-testing-interface';

export const getElementTestingData = createAction(
  '[INSTALLATION ELEMENT TESTING] Get element testing data [...]',
);

export const getElementTestingDataSuccess = createAction(
  '[INSTALLATION ELEMENT TESTING] Get element testing data [SUCCESS]',
  props<{ testingData: IElementTestingData }>(),
);

export const getElementTestingDataError = createAction(
  '[INSTALLATION ELEMENT TESTING] Get testing data [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateElementTestingData = createAction(
  '[INSTALLATION ELEMENT TESTING] Update element testing data [...]',
  props<{ testingData: IElementTestingData }>(),
);

export const updateElementTestingDataSuccess = createAction(
  '[INSTALLATION ELEMENT TESTING] Update element testing data [SUCCESS]',
  props<{ testingData: IElementTestingData }>(),
);

export const updatetElementTestingDataError = createAction(
  '[INSTALLATION ELEMENT TESTING] Update element testing data [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
