import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { INSTALLATION_FEATURE_KEY } from './installation.reducer';
import { InstallationState } from './installation.state';
import { IConnection } from '../interfaces/connection.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElement } from '../interfaces/element/element.interface';

export const getInstallationState = createFeatureSelector<InstallationState>(
  INSTALLATION_FEATURE_KEY,
);

export const selectInstallationIsLoading = createSelector(
  getInstallationState,
  ({ isLoading }) => isLoading,
);

export const selectElementIsLoading = createSelector(
  getInstallationState,
  ({ isElementLoading }) => isElementLoading,
);

export const selectHasCards = createSelector(
  getInstallationState,
  ({ cards }) => cards?.length > 0,
);

export const selectCards = createSelector(
  getInstallationState,
  ({ cards }) => cards,
);

export const selectCardConnectionsByID = (cardID: number): MemoizedSelector<InstallationState, IConnection[]> => createSelector(
  getInstallationState,
  ({ cards }) => cards?.find(card => card.cardID === cardID)?.connections,
);

export const selectConnectedElementByType = (elementType: ElementTypesEnum): (state: object) => IElement[] =>
  createSelector(getInstallationState, ({ cards }) => {
    const elements = cards.reduce((accumulator: IElement[], card) => {
      const filteredConnections = (card?.connections || []).filter(
        (connection) => connection?.element?.elementType === elementType,
      );
      accumulator.push(...filteredConnections.map((connection) => connection.element));
      return accumulator;
    }, []);

    return elements;
  },
);

export const selectCardElementTypes = createSelector(
  getInstallationState,
  ({ elementTypes }) => elementTypes,
);

export const selectHasCardElementTypes = createSelector(
  getInstallationState,
  ({ elementTypes }) => elementTypes?.length > 0,
);

export const selectCurrentCardID = createSelector(
  getInstallationState,
  ({ currentCardID }) => currentCardID,
);

export const selectCurrentConnectionID = createSelector(
  getInstallationState,
  ({ currentConnectionID }) => currentConnectionID,
);

export const selectCurrentElementType = createSelector(
  getInstallationState,
  ({ currentElementType }) => currentElementType,
);

export const selectCurrentConnectionSetup = createSelector(
  getInstallationState,
  ({ currentConnectionSetup }) => currentConnectionSetup,
);

export const selectCurrentConnectionSetupData = createSelector(
  getInstallationState,
  ({ currentConnectionSetup }) => currentConnectionSetup?.setupData,
);

export const selectCurrentConnectionSetupIsNew = createSelector(
  getInstallationState,
  ({ currentConnectionSetup }) => currentConnectionSetup?.isNew,
);

export const selectOtherElementsSetupData = createSelector(
  getInstallationState,
  ({ currentConnectionSetup }) => currentConnectionSetup?.setupData?.otherSetups,
);

export const selectCurrentConnectionSetupIsDirty = createSelector(
  getInstallationState,
  ({ currentConnectionSetup }) => currentConnectionSetup?.isDirty,
);

export const selectCurrentElementID = createSelector(
  getInstallationState,
  ({ currentElementID }) => currentElementID,
);

export const selectFirstAvailableElementNumber = createSelector(
  selectCardElementTypes,
  selectCurrentElementType,
  (elementTypes, elementTypeID) => {
    return elementTypes?.find(el => el.elementType === elementTypeID)?.availableElementNumbers?.[0];
  },
);

export const selectElementSettings = createSelector(
  getInstallationState,
  ({ elementSettings }) => elementSettings,
);

export const selectElementTestingData = createSelector(
  getInstallationState,
  ({ elementTestindData }) => elementTestindData,
);

export const selectIsTestingDataLoading = createSelector(
  getInstallationState,
  ({ isTestingDataLoading }) => isTestingDataLoading,
);