import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  InputComponent,
  InputIntegerComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import {
  clearIsControllerCreatedOrExecuted,
  createControllerByConnectionNumber, executeTicketController,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ls-add-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    InputIntegerComponent,
    SvgIconComponent,
  ],
  templateUrl: './add-house-number-dialog.component.html',
  styleUrls: ['./add-house-number-dialog.component.scss'],
})
export class AddHouseNumberDialogComponent {
  houseNumber: number;

  constructor(
    public dialogRef: MatDialogRef<AddHouseNumberDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private sanitizer: DomSanitizer,
    private store: Store,
    @Inject(MAT_DIALOG_DATA) public data: {
      currentHouseNumber: number,
      usedHouseNumbers: number[],
      connectionNumber: string,
      ticketID: string,
      farmID: number,
    },
  ) {
  }

  addHouseNumber(): void {
    if (!this.houseNumber || isNaN(this.houseNumber) || this.houseNumber > 999) return;

    this.store.dispatch(clearIsControllerCreatedOrExecuted());

    if (this.data.ticketID) {
      this.store.dispatch(executeTicketController({
        requestedView: {
          farmID: this.data.farmID,
          houseNumber: +this.houseNumber,
        },
        ticketID: this.data.ticketID,
      }));

      this.dialogRef.close();
      return;
    }

    this.store.dispatch(createControllerByConnectionNumber({
      connectionInfo: {
        connectionNumber: this.data.connectionNumber,
        houseNumber: +this.houseNumber,
      },
    }));

    this.dialogRef.close();
  }

  close(): void {
    this.store.dispatch(clearIsControllerCreatedOrExecuted());
    this.dialogRef.close();
  }

  isInputValid(input: number): boolean {
    return /^[0-9]+$/.test(input?.toString());
  }

  getInnerHTML(text: string): SafeHtml {
    const modifiedText = text.split(' ')
      .map(word => {
        if (Number.isInteger(+word)) {
          return `<span class="fw-500 color-primary">${word}</span>`;
        }

        return word;
      }).join(' ');

    return this.sanitizer.bypassSecurityTrustHtml(modifiedText);
  }
}
