import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { loadingSvgPaths } from './loading-galcon.constants';

@Component({
  selector: 'ls-loading-galcon',
  templateUrl: './loading-galcon.component.html',
  styleUrls: ['./loading-galcon.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
})
export class LoadingGalconComponent implements OnInit, OnDestroy {
  @Input() title: string;
  @Input() shouldBeTranslated: boolean;

  svgPaths: string[] = loadingSvgPaths;
  activeIndex = 0;
  loaderAnimation: ReturnType<typeof setInterval>;

  ngOnInit(): void {
    this.loaderAnimation = setInterval(() => {
      if (this.activeIndex === this.svgPaths.length - 1) {
        this.activeIndex = 0;
        return;
      }

      this.activeIndex += 1;
    }, 300);
  }

  ngOnDestroy(): void {
    clearInterval(this.loaderAnimation);
  }
}
