<ul
  [class.rtl]="languageService.isRtl"
  [qaTags]="'qa-lbl-paging-scale'"
  class="paging user-select-none">
  <li class="paging__page">
    <ls-svg-icon
      (click)="previousPage()"
      [hasMargin]="true"
      [path]="'chevron-left'"
      [qaTags]="'qa-lbl-paging-left'"></ls-svg-icon>
  </li>

  <ng-container *ngIf="pages - viewport <= 2">
    <li
      *ngFor="let it of [].constructor(pages); let i = index"
      (click)="emitChangeActivePage(i + 1)"
      [class.paging__page--active]="active === i + 1"
      class="paging__page">
      {{ i + 1 }}
    </li>
  </ng-container>

  <ng-container *ngIf="pages - viewport > 2 && active <= viewport">
    <li
      *ngFor="let it of [].constructor(viewport + 1); let i = index"
      (click)="emitChangeActivePage(i + 1)"
      [class.paging__page--active]="active === i + 1"
      class="paging__page">
      {{ i + 1 }}
    </li>
    <li class="paging__page">...</li>
    <li
      (click)="emitChangeActivePage(pages)"
      [class.paging__page--active]="active === pages"
      class="paging__page">
      {{ pages }}
    </li>
  </ng-container>

  <ng-container *ngIf="pages - viewport > 2 && active > pages - viewport">
    <li
      (click)="emitChangeActivePage(1)"
      [class.paging__page--active]="active === 1"
      class="paging__page">
      1
    </li>
    <li class="paging__page">...</li>
    <li
      *ngFor="let it of [].constructor(viewport + 1); let i = index"
      (click)="emitChangeActivePage(pages - viewport + i)"
      [class.paging__page--active]="active === pages - viewport + i"
      class="paging__page">
      {{ pages - viewport + i }}
    </li>
  </ng-container>

  <ng-container *ngIf="pages - viewport > 2 && active <= pages - viewport && active > viewport">
    <li
      (click)="emitChangeActivePage(1)"
      [class.paging__page--active]="active === 1"
      class="paging__page">
      1
    </li>
    <li class="paging__page">...</li>
    <li
      *ngFor="let it of [].constructor(viewport); let i = index"
      (click)="emitChangeActivePage(countPage(i))"
      [class.paging__page--active]="active === countPage(i)"
      class="paging__page">
      {{ countPage(i) }}
    </li>
    <li class="paging__page">...</li>
    <li
      (click)="emitChangeActivePage(pages)"
      [class.paging__page--active]="active === pages"
      class="paging__page">
      {{ pages }}
    </li>
  </ng-container>

  <li class="paging__page">
    <ls-svg-icon
      (click)="nextPage()"
      [hasMargin]="true"
      [path]="'chevron-right'"
      [qaTags]="'qa-lbl-paging-right'"></ls-svg-icon>
  </li>
</ul>
