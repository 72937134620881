<button
  [qaTags]="qaTags"
  [type]="buttonType"
  class="pv-5 ls-btn {{ paddingClass }}
    ls-btn{{
    type === 'alert'
      ? '_red'
      : type === 'secondary'
      ? '_white'
      : type === 'success'
      ? '_success'
      : type === 'danger'
      ? '_danger'
      : ''
  }}">
  <span class="fz-16-md"><ng-content></ng-content></span>
</button>
