import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable, map } from 'rxjs';
import {
  FlockHelper,
  IFlockBirdWeightSettingsView,
  IFlockBirdWeightView,
  IFlockHouseModeView,
  IFlockSettingsView,
  IGetFlockBirdWeightView,
  IGetFlockDefaultWeightView,
} from '@livestock/flock';
import { IFlockDefaultWeightView, IFlockView } from '../interfaces';
import { ChickenBrandWeight, TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class FlockService {
  constructor(private http: HttpClient) {
  }

  getDefaultWeight(): Observable<IGetFlockDefaultWeightView> {
    return this.http.get<IGetFlockDefaultWeightView>(`${environment.apiUrl}/flock/default-weight`);
  }

  getSettings(controllerID: number): Observable<IFlockSettingsView> {
    return this.http.get<IFlockSettingsView>(`${baseUrl}/${controllerID}/flock/settings`);
  }

  updateSettings(controllerID: number, requestView: IFlockSettingsView): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/flock/settings`, requestView);
  }

  getHouseMode(controllerID: number, temperatureUnit: TemperatureUnitEnum): Observable<IFlockHouseModeView> {
    return this.http.get<IFlockHouseModeView>(`${baseUrl}/${controllerID}/flock/house-mode`).pipe(
      map((flockHouseMode) => {
        return temperatureUnit === TemperatureUnitEnum.Celsius
          ? flockHouseMode
          : this.parseFlockHouseMode(flockHouseMode, TemperatureUnitEnum.Fahrenheit);
      }),
    );
  }

  updateHouseMode(
    controllerID: number,
    requestView: IFlockHouseModeView,
    temperatureUnit: TemperatureUnitEnum,
  ): Observable<void> {
    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
      requestView = this.parseFlockHouseMode(requestView, TemperatureUnitEnum.Celsius);
    }
    return this.http.put<void>(`${baseUrl}/${controllerID}/flock/house-mode`, requestView);
  }

  getBirdWeightSettings(controllerID: number): Observable<IFlockBirdWeightSettingsView> {
    return this.http.get<IFlockBirdWeightSettingsView>(`${baseUrl}/${controllerID}/flock/bird-weight-settings`);
  }

  updateBirdWeightSettings(controllerID: number, requestView: IFlockBirdWeightSettingsView): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/flock/bird-weight-settings`, requestView);
  }

  getBirdWeight(controllerID: number, weightUnit: WeightUnitEnum): Observable<IGetFlockBirdWeightView> {
    return this.http.get<IGetFlockBirdWeightView>(`${baseUrl}/${controllerID}/flock/bird-weight`).pipe(
      map((birdWeight) => {
        return weightUnit === WeightUnitEnum.Gram
          ? birdWeight
          : {
            ...birdWeight,
            items: this.parseWeightItems<IFlockBirdWeightView>(birdWeight.items, WeightUnitEnum.Gram, weightUnit),
          };
      }),
    );
  }

  updateBirdWeight(controllerID: number, items: IFlockBirdWeightView[], weightUnit: WeightUnitEnum): Observable<void> {
    if (weightUnit !== WeightUnitEnum.Gram) {
      items = this.parseWeightItems<IFlockBirdWeightView>(items, weightUnit, WeightUnitEnum.Gram);
    }
    return this.http.put<void>(`${baseUrl}/${controllerID}/flock/bird-weight`, items);
  }

  //latest ones - March 2024
  getFlock(controllerID: number): Observable<IFlockView> {
    return this.http.get<IFlockView>(`${baseUrl}/${controllerID}/flock`);
  }

  createFlock(controllerID: number, view: IFlockView): Observable<IFlockView> {
    const weightUnit: WeightUnitEnum = FlockHelper.getWeightUnit(view.flockSettings);

    if (weightUnit !== WeightUnitEnum.Gram) {
      const conversionMethod = ConvertHelper.getWeightConversionMethod(weightUnit, WeightUnitEnum.Gram);
      view = {
        ...view,
        flockBirdWeights: view.flockBirdWeights.map(weight => {
          return {
            ...weight,
            weight: conversionMethod(weight.weight, 2),
            femaleWeight: conversionMethod(weight.femaleWeight, 2),
            maleWeight: conversionMethod(weight.maleWeight, 2),
          };
        }),
      };
    }
    return this.http.put<IFlockView>(`${baseUrl}/${controllerID}/flock`, view);
  }

  getDefaultWeightBrand(
    brand: ChickenBrandWeight = ChickenBrandWeight.COBB_500,
    weightUnit: WeightUnitEnum,
  ): Observable<IGetFlockDefaultWeightView> {
    return this.http.get<IGetFlockDefaultWeightView>(`${environment.apiUrl}/flock/default-weight/brand/${brand}`).pipe(
      map((brandWeight) => {
        return weightUnit === WeightUnitEnum.Gram
          ? brandWeight
          : {
            items: this.parseWeightItems<IFlockDefaultWeightView>(
              brandWeight.items,
              WeightUnitEnum.Gram,
              weightUnit,
            ),
          };
      }),
    );
  }

  private parseFlockHouseMode(
    flockHouseMode: IFlockHouseModeView,
    convertTempTo: TemperatureUnitEnum,
  ): IFlockHouseModeView {
    const convertFunction =
      convertTempTo === TemperatureUnitEnum.Celsius
        ? ConvertHelper.fahrenheitToCelsius
        : ConvertHelper.celsiusToFahrenheit;
    return {
      ...flockHouseMode,
      targetTemperatureCatching: convertFunction(flockHouseMode.targetTemperatureCatching, 1),
      targetTemperatureCleaning: convertFunction(flockHouseMode.targetTemperatureCleaning, 1),
      targetTemperatureEmpty: convertFunction(flockHouseMode.targetTemperatureEmpty, 1),
      targetTemperaturePreheat: convertFunction(flockHouseMode.targetTemperaturePreheat, 1),
    };
  }

  private parseWeightItems<T extends IFlockBirdWeightView | IFlockDefaultWeightView>(
    items: T[],
    convertFrom: WeightUnitEnum,
    convertTo: WeightUnitEnum,
  ): T[] {
    const conversionFunction = ConvertHelper.getWeightConversionMethod(convertFrom, convertTo);

    return items.map((item) => {
      return (item as IFlockDefaultWeightView)?.weight != null ? {
        ...item,
        common: conversionFunction((item as IFlockDefaultWeightView).weight, 3),
        female: conversionFunction((item as IFlockDefaultWeightView).femaleWeight, 3),
        male: conversionFunction((item as IFlockDefaultWeightView).maleWeight, 3),
      } : {
        ...item,
        weight: conversionFunction((item as IFlockBirdWeightView).weight, 3),
        femaleWeight: conversionFunction((item as IFlockBirdWeightView).femaleWeight, 3),
        maleWeight: conversionFunction((item as IFlockBirdWeightView).maleWeight, 3),
      };
    });
  }
}
