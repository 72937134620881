<section class="map-wraper">
  <div class="map-container {{ platform }}">
    <input
      [placeholder]="'Search Box'"
      [qaTags]="'qa-txt-search'"
      class="search-box"
      id="pac-input"
      type="text" />

    <div
      class="address"
      id="address">
      {{ "Farms.CreateFarmDialog.CurrentAddress" | translate }}: {{ address || "unknown" }}
    </div>

    <div
      class="buttons position-absolute"
      id="buttons">
      <button
        (click)="dialogRef.close()"
        class="home-page__footer-btn home-page__footer-btn--outlined qa-btn-cancel-address qa-btn-cancel">
        {{ "Farms.CreateFarmDialog.Cancel" | translate }}
      </button>
      <button
        (click)="setValuesAndClose()"
        [disabled]="!latitude || !longitude"
        [qaTags]="'qa-btn-set-address'"
        class="home-page__footer-btn home-page__footer-btn--filled">
        {{ "Farms.CreateFarmDialog.SetFarmAddress" | translate }}
      </button>
    </div>

    <div
      #map
      class="map-area"></div>
  </div>
</section>
