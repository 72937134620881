<ng-container *ngIf="!flowIsFinished; else finishedFlowMessage">
  <ng-container *ngIf="currentStep$ | async as currStep">
    <div class="container pt-0">
      <div class="steps justify-evenly d-flex flex-column">
        <ng-container *ngFor="let step of steps">
          <div class="position-relative wizard-btn">
            <lv-button-wizard
              (click)="onStepClick(step.tab)"
              [iconPath]="step.icon"
              [type]="
                currStep === step.tab && step.type !== ButtonWizardTypeEnum.ERROR ? ButtonWizardTypeEnum.ON : step.type
              "
              class="position-relative" />

            <div
              *ngIf="step.tab !== QuickStartStepsEnum.ConnectionsSettings"
              [ngClass]="{
                blue: steps[step.tab]?.couldBeVisited,
                error: steps[step.tab]?.type === ButtonWizardTypeEnum.ERROR
              }"
              class="line"></div>
          </div>
        </ng-container>
      </div>

      <div
        [ngClass]="currStep === QuickStartStepsEnum.FlockSettings ? 'pt-28' : 'pt-56'"
        class="content-container d-flex flex-column pl-48 pr-28 h-100-percent">
        <h3
          [ngClass]="currStep === QuickStartStepsEnum.FlockSettings ? 'mb-32' : 'mb-56'"
          class="fz-24 fw-600">
          {{ currStep | memoizeFunc : getStepTitle.bind(this) | translate }}
        </h3>
        <ng-container *ngIf="currStep === QuickStartStepsEnum.GeneralSettings">
          <ls-controller-general-settings-form
            (changed)="changedGeneralSettings($event)"
            [generalSettings]="generalSettings"
            [isQuickStart]="true" />
        </ng-container>
        <ng-container *ngIf="currStep === QuickStartStepsEnum.HouseSettings">
          <ls-house-sizes-settings-form
            (changed)="changedHouseSizesSettings($event)"
            [houseSizesSettings]="houseSizesSettings"
            [lengthUnit]="lengthUnit$ | async" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.NetworkSettings">
          <ls-network-settings-form
            (changed)="changedNetworkSettings($event)"
            [isLoading]="isLoading$ | async"
            [networkSettings]="networkSettings" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.DateTimeSettings">
          <ls-controller-date-time-settings-form
            (changed)="changedDateTimeSettings($event)"
            [dateTimeSettings]="dateTimeSettings" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.CloudSettings">
          <lv-controller-settings-stub-form />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.FlockSettings">
          <lv-flock-settings-form
            (changedFlockSettings)="changedFlockSettings($event)"
            (changedFlockWeights)="changedFlockWeights($event)"
            [weightUnit]="weightUnit$ | async"
            [defaultWeights]="defaultWeights"
            [deviceLogic]="deviceLogic"
            [flockSettings]="flockSettings"></lv-flock-settings-form>
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.HouseModeSettings">
          <ls-house-modes-form
            (changed)="changedFlockHouseModes($event)"
            [hoursFormat]="dateTimeSettings.hoursFormat"
            [temperatureUnit]="generalSettings | memoizeFunc : getCurrentTemperatureUnit.bind(this)"
            [ventilationWorkingMode]="ventilationWorkingMode$ | async"
            [houseModesSettings]="flockHouseMode" />
        </ng-container>

        <ng-container *ngIf="currStep === QuickStartStepsEnum.ConnectionsSettings">
          <lv-controller-settings-stub-form />
        </ng-container>

        <div
          *ngIf="!(assigningToFarmInProgress$ | async)"
          [class.actions__general-settings]="currStep === QuickStartStepsEnum.GeneralSettings"
          class="d-flex mt-30 mb-28 actions">
          <lv-button
            *ngIf="
              currStep > QuickStartStepsEnum.GeneralSettings &&
              currStep !== QuickStartStepsEnum.ConnectionsSettings &&
              !(isControllerAssignedWithFarm$ | async)
            "
            (click)="back()"
            [iconWizard]="ButtonIconWizardEnum.BACK"
            [class.disabled]="(connectionInProgress$ | async) || (isLoading$ | async)"
            [qaTags]="'qa-btn-back'"
            class="mr-auto w-97">
            {{ "Controller.QuickStart.Back" | translate }}
          </lv-button>

          <!--Temporary for QA needs-->
          <div class="d-flex flex-column ml-auto">
            <label>Device logic ON/OFF</label>
            <ls-toggle
              (change)="toggleDeviceLogic($event)"
              [value]="false"></ls-toggle>
          </div>

          <lv-button
            *ngIf="currStep < QuickStartStepsEnum.ConnectionsSettings"
            (click)="next()"
            [type]="
              steps[currStep - 1].type === ButtonWizardTypeEnum.ERROR ||
              (currStep === QuickStartStepsEnum.DateTimeSettings && !dateTimeSettingsValid) ||
              (isLoading$ | async)
                ? ButtonTypeEnum.DISABLED
                : ButtonTypeEnum.PRIMARY
            "
            [class.disabled]="
              steps[currStep - 1].type === ButtonWizardTypeEnum.ERROR ||
              (currStep === QuickStartStepsEnum.DateTimeSettings && !dateTimeSettingsValid) ||
              (isLoading$ | async)
            "
            [iconWizard]="ButtonIconWizardEnum.NEXT"
            [qaTags]="'qa-btn-next'"
            class="w-110 ml-20">
            {{ "Controller.QuickStart.Next" | translate }}
          </lv-button>

          <lv-button
            *ngIf="currStep === QuickStartStepsEnum.ConnectionsSettings"
            (click)="goToDashboard(true)"
            [type]="anyStepInError() ? ButtonTypeEnum.DISABLED : ButtonTypeEnum.PRIMARY"
            [class.pointer-events-none]="anyStepInError()"
            [qaTags]="'qa-btn-finish'">
            {{ "Controller.QuickStart.Finish" | translate }}
          </lv-button>
        </div>
      </div>
      <div class="footer d-flex justify-center align-center">
        <span class="mr-2">{{ "Controller.QuickStart.PoweredBy" | translate }}</span>
        <img src="/assets/images/small-logo.png" />
        <img src="/assets/images/galcon-logo.png" />
      </div>
    </div>
  </ng-container>
</ng-container>

<ng-template #finishedFlowMessage>
  <div class="finished-flow text-center fz-24">
    <ls-svg-icon
      [hasMargin]="true"
      class="d-block mx-auto mb-10 w-mc"
      path="quick-start/info"></ls-svg-icon>
    <div class="mb-60">
      <p>{{ "Controller.QuickStart.TheSetupIsIncomplete" | translate }}</p>
      <p>{{ "Controller.QuickStart.YouCanSkipThisStepAndCompleteItLater" | translate }}</p>
    </div>

    <div class="d-flex justify-between align-center">
      <ls-button
        (click)="goToDashboard(false)"
        [qaTags]="'qa-btn-go-to-dashboard'"
        type="secondary">
        {{ "Controller.QuickStart.GoToDashboard" | translate }}
      </ls-button>

      <ls-button
        (click)="goToInstallation()"
        [qaTags]="'qa-btn-continue-with-installation-wrapper'"
        type="secondary">
        {{ "Controller.QuickStart.ContinueWithInstallation" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>
