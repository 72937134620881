import { Injectable } from '@angular/core';
import { environment } from '@livestock/shared/environments';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { ICreateAddControllerTicketView } from '../interfaces/create-add-controller-ticket-view.interface';
import {
  ICreateAddControllerTicketRequestView,
} from '../interfaces/create-add-controller-ticket-request-view.interface';
import { IGetAddControllerTicketView } from '../interfaces/get-add-controller-ticket-view';
import { IExecuteControllerByTicketView } from '@livestock/controllers/interfaces';
import { PlatformService } from '@livestock/shared/services';
import { ControllerUnitEnum } from '../enums';
import { LengthUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

const baseUrl = `${environment.apiUrl}/ticket`;

@Injectable({
  providedIn: 'root',
})
export class TicketControllersService {

  constructor(
    private http: HttpClient,
    private platformService: PlatformService,
  ) {
  }

  createAddControllerTicket(requestView: ICreateAddControllerTicketRequestView, isReconnect?: boolean): Observable<ICreateAddControllerTicketView> {

    const shouldConvertLengthUnitsToMetric =
      requestView.units === ControllerUnitEnum.Imperial
        || (requestView.units === ControllerUnitEnum.Custom
          && requestView.unitsDetails.length === LengthUnitEnum.Foot);

    requestView = {
      ...requestView,
      houseSizes: {
        ...requestView.houseSizes,
        houseHeight: shouldConvertLengthUnitsToMetric ? ConvertHelper.feetToMeters(requestView.houseSizes.houseHeight, 3) : requestView.houseSizes.houseHeight,
        houseLength: shouldConvertLengthUnitsToMetric ? ConvertHelper.feetToMeters(requestView.houseSizes.houseLength, 3) : requestView.houseSizes.houseLength,
        houseRoofHeight: shouldConvertLengthUnitsToMetric ? ConvertHelper.feetToMeters(requestView.houseSizes.houseRoofHeight, 3) : requestView.houseSizes.houseRoofHeight,
        houseWidth: shouldConvertLengthUnitsToMetric ? ConvertHelper.feetToMeters(requestView.houseSizes.houseWidth, 3) : requestView.houseSizes.houseWidth,
      },
    };

    return this.http.post<ICreateAddControllerTicketView>(`${baseUrl}/${isReconnect ? 'reconnect' : 'add'}-controller`, { ...requestView, isVirtual: !this.platformService.isDeviceApp });
  }

  getAddControllerByTicketID(ticketId: string | null, isReconnect?: boolean): Observable<IGetAddControllerTicketView> {
    return this.http.get<IGetAddControllerTicketView>(`${baseUrl}/${ticketId}/${isReconnect ? 'reconnect' : 'add'}-controller`);
  }

  executeAddControllerTicket(ticketId: string, requestView: IExecuteControllerByTicketView, isReconnect?: boolean): Observable<void> {
    return this.http.post<void>(`${baseUrl}/${ticketId}/${isReconnect ? 'reconnect' : 'add'}-controller/execute`, requestView);
  }
}
