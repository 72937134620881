<div class="widget-wrapper">
  <div class="widget-top mb-40-mobile">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Cooling</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content">
      <table class="w-100-percent with-partial-border fz-30 td-equal-4 text-center">
        <thead class="color-tertiary">
          <tr>
            <th class="fz-16 fz-18-mobile">Unit</th>
            <th>
              <div class="d-flex fz-24 fz-22-mobile align-center justify-center">
                <span
                  [qaTags]="'qa-lbl-cooling-p-unit-num'"
                  class="mr-10"
                  >1</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-cooling-p-unit'"
                  path="dashboard/cooling"></ls-svg-icon>
              </div>
            </th>
            <th>
              <div class="d-flex fz-24 fz-22-mobile align-center justify-center">
                <span
                  [qaTags]="'qa-lbl-cooling-p-unit-num'"
                  class="mr-10"
                  >2</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-cooling-p-unit'"
                  path="dashboard/cooling"></ls-svg-icon>
              </div>
            </th>
            <th>
              <div class="d-flex fz-24 fz-22-mobile align-center justify-center">
                <span
                  [qaTags]="'qa-lbl-cooling-p-unit-num'"
                  class="mr-10"
                  >3</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.DefaultDisabled"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-cooling-p-unit'"
                  path="dashboard/cooling"></ls-svg-icon>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="line-height-1">
          <tr>
            <td
              [qaTags]="'qa-lbl-cooling-p-temp'"
              class="color-tertiary fz-16 fz-18-mobile">
              Temp
            </td>
            <td class="fz-32 fz-24-xl fz-22-mobile">20<span class="fz-16">°C</span></td>
            <td class="fz-32 fz-24-xl fz-22-mobile">30<span class="fz-16">°C</span></td>
            <td class="color-disabled">-</td>
          </tr>

          <tr>
            <td
              [qaTags]="'qa-lbl-cooling-p-on-temp'"
              class="color-tertiary fz-16 fz-18-mobile">
              On Temp
            </td>
            <td class="fz-32 fz-24-xl fz-22-mobile">21<span class="fz-16">°C</span></td>
            <td class="fz-32 fz-24-xl fz-22-mobile">28<span class="fz-16">°C</span></td>
            <td class="color-disabled">-</td>
          </tr>

          <tr>
            <td
              [qaTags]="'qa-lbl-cooling-p-cycle-status'"
              class="color-tertiary fz-16 fz-18-mobile">
              Cycle
            </td>
            <td>
              <h6 class="fz-32 fz-24-xl fz-22-mobile line-height-07">ON</h6>
              <span
                [qaTags]="'qa-lbl-cooling-p-cycle-time'"
                class="color-tertiary fz-16"
                >23 Sec</span
              >
            </td>
            <td>
              <h6 class="fz-32 fz-24-xl fz-22-mobile line-height-07">ON</h6>
              <span
                [qaTags]="'qa-lbl-cooling-p-cycle-time'"
                class="color-tertiary fz-16"
                >20 Sec</span
              >
            </td>
            <td class="color-disabled">-</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
