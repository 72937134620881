import { ISprinklersFoggersProgramPeriod } from './../interfaces/sprinklers-foggers-program-period.interface';
import { Action, createReducer, on } from '@ngrx/store';
import * as sprinklersFoggersActions from './sprinklers-foggers-program.actions';
import { SprinklersFoggersProgramState } from './sprinklers-foggers-program.state';

export const initialState: SprinklersFoggersProgramState = {
  isLoading: false,
  periods: null,
  currentProgram: null,
  currentPeriod: null,
};

const reducer = createReducer(
  initialState,
  on(
    sprinklersFoggersActions.getSprinklersFoggersProgram,
    sprinklersFoggersActions.getSprinklersFoggersProgramPeriod,
    sprinklersFoggersActions.saveSprinklersFoggersProgram,
    sprinklersFoggersActions.deleteSprinklersFoggersProgram,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    sprinklersFoggersActions.addSprinklersFoggersProgramDayError,
    sprinklersFoggersActions.getSprinklersFoggersProgramError,
    sprinklersFoggersActions.deleteSprinklersFoggersProgramError,
    sprinklersFoggersActions.getSprinklersFoggersProgramPeriodError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    sprinklersFoggersActions.deleteSprinklersFoggersProgramSuccess,
    (state, { periodID }) => {
      if (!periodID) return { ...state };
      return {
        ...state,
        isLoading: false,
        currentProgram: {
          ...state.currentProgram,
          items: state.currentProgram.items.filter(i => i.periodID !== periodID),
        },
      };
    },
  ),
  on(
    sprinklersFoggersActions.getSprinklersFoggersProgramSuccess,
    (state, { item }) => {
      return {
        ...state,
        isLoading: false,
        currentProgram: item,
      };
    },
  ),
  on(
    sprinklersFoggersActions.deleteSprinklersFoggersProgramDay,
    (state, { periodID, preventSelecting }) => {
      const periods = state.periods.filter(per => per.periodID !== periodID);
      return {
        ...state,
        isLoading: false,
        periods,
        currentPeriod: preventSelecting ? null : periods[0],
      };
    },
  ),
  on(
    sprinklersFoggersActions.addSprinklersFoggersProgramDaySuccess,
    (state, { dayNumber, controllerID }) => {
      const hasDay = state.periods.some(period => period.dayNumber === dayNumber);
      if (hasDay) {
        return state;
      }

      const periods: ISprinklersFoggersProgramPeriod[] = [...state.periods || [], { dayNumber, controllerID }];

      return {
        ...state,
        isLoading: false,
        periods: periods.sort((a, b) => a.dayNumber - b.dayNumber),
        currentProgram: { hasElement1: true, hasElement2: true, hasElement3: true, items: [] },
        currentPeriod: {
          dayNumber,
          controllerID,
        },
      };
    },
  ),
  on(
    sprinklersFoggersActions.getSprinklersFoggersProgramPeriodSuccess,
    (state, { items, controllerID }) => {
      return {
        ...state,
        isLoading: false,
        periods: items.length ? items : [{
          controllerID,
          dayNumber: 0,
        }],
      };
    },
  ),
  on(
    sprinklersFoggersActions.saveSprinklersFoggersProgramSuccess,
    (state, { period, items }) => {

      // If we've added first real day which is not 0,
      // remove the first default day 0 which is not saved.
      const skipFirstDefaultDay =
        state.periods.length === 2 &&
        state.periods[0].dayNumber === 0 &&
        state.periods[0]?.periodID == null &&
        state.periods[1]?.periodID == null;

      return {
        ...state,
        isLoading: false,
        periods: [...state.periods].splice(skipFirstDefaultDay ? 1 : 0).map(i => i.periodID ? ({ ...i }) : ({
          ...i,
          periodID: period.periodID,
        })),
        currentPeriod: period,
        currentProgram: {
          ...state.currentProgram,
          items,
        },
      };
    },
  ),
  on(
    sprinklersFoggersActions.clearProgram,
    (state) => {
      return {
        ...state,
        currentProgram: { hasElement1: true, hasElement2: true, hasElement3: true, items: [] },
      };
    },
  ),
  on(
    sprinklersFoggersActions.filterPeriods,
    (state, { currentPeriod }) => {
      return {
        ...state,
        periods: state.periods?.length > 1 ? filterUnusedPeriods(currentPeriod, [...state.periods]) : [...state.periods],
        currentPeriod: currentPeriod ?? state.periods[0],
      };
    },
  ),
  on(
    sprinklersFoggersActions.setCurrentSprinklersFoggersProgram,
    (state, { currentProgram }) => {
      return {
        ...state,
        currentProgram,
      };
    },
  ),
  on(
    sprinklersFoggersActions.setCurrentSprinklersFoggersProgramPeriod,
    (state, { currentPeriod }) => {
      return {
        ...state,
        currentPeriod,
      };
    },
  ),

  on(
    sprinklersFoggersActions.resetSprinklersFoggers,
    () => {
      return { ...initialState };
    },
  ),
);

export function sprinklersFoggersProgramReducer(state: SprinklersFoggersProgramState | undefined, action: Action): SprinklersFoggersProgramState {
  return reducer(state, action);
}

export function filterUnusedPeriods(currentPeriod: ISprinklersFoggersProgramPeriod, periods: ISprinklersFoggersProgramPeriod[], key = 'periodID'): ISprinklersFoggersProgramPeriod[] {
  if (!currentPeriod?.periodID) return periods.filter(period => !!period.periodID);

  // remove duplicate periods with same periodID's and replace with saved period (currentPeriod)
  return [...new Map(periods.map(item => [item[key], item])).values()]
    .map(period => period.periodID === currentPeriod?.periodID ? currentPeriod : period);
}
