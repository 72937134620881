<div class="{{ fzClass }} text-center d-flex flex-column">
  <div class="{{ mbClass }}">
    <img
      class="{{ imgClass }}"
      alt="search"
      src="/assets/icons/common/search.svg" />
  </div>

  No results for “{{ searchResult }}”
</div>
