import { TreatmentByEnum } from '@livestock/air-treatment/models';

export interface IAirTreatmentSettingsView {
    controllerID: number;
    airTreatmentEnabled: boolean;
    treatmentBy: TreatmentByEnum;
    ventilationMaxForTreatment: number;
    ventilationIndoorTempToStop: number;
    heatingMaxForTreatment: number;
    heatingIndoorTempToStop: number;
}
