import { BasicVentilationMinimumState } from '@livestock/basic-ventilation-minimum';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const BASIC_VENTILATION_MINIMUM_KEY = 'basicVentilationMinimum';

export const getBasicVentilationMinimumState = createFeatureSelector<BasicVentilationMinimumState>(BASIC_VENTILATION_MINIMUM_KEY);

export const selectIsLoading = createSelector(
  getBasicVentilationMinimumState,
  ({ isLoading }) => isLoading,
);

export const selectBasicVentilationMinimumItems = createSelector(
  getBasicVentilationMinimumState,
  ({ items }) => items,
);

export const selecVentilationDOElements = createSelector(
  getBasicVentilationMinimumState,
  ({ ventilationDOElements }) => ventilationDOElements,
);

export const selecVentilationAOElements = createSelector(
  getBasicVentilationMinimumState,
  ({ ventilationAOElements }) => ventilationAOElements,
);

export const selectCanDeactivate = createSelector(
  getBasicVentilationMinimumState,
  ({ canDeactivate }) => canDeactivate,
);

// settings
export const selectIsLoadingSettings = createSelector(
  getBasicVentilationMinimumState,
  ({ isLoadingSettings }) => isLoadingSettings,
);

export const selectBasicVentilationSettings = createSelector(
  getBasicVentilationMinimumState,
  ({ settings }) => settings,
);
