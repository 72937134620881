import { Action, createReducer, on } from '@ngrx/store';
import * as installationActions from './installation.actions';
import { InstallationState } from './installation.state';
import { ICard } from '../interfaces/card.interface';
import { IElementType } from '../interfaces/element/element-type.interface';

export const INSTALLATION_FEATURE_KEY = 'installation';

export const initialState: InstallationState = {
  isLoading: false,
  isElementLoading: false,
  cards: [],
  elementTypes: [],
  currentCardID: null,
  currentConnectionID: null,
  currentElementType: null,
  currentElementID: null,
  currentConnectionSetup: null,
  elementSettings: null,
  elementTestindData: null,
  isTestingDataLoading: null,
};

const reducer = createReducer(
  initialState,
  on(
    installationActions.getControllerCards,
    installationActions.getCardElementTypes,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    installationActions.getControllerCardsError,
    installationActions.getCardElementTypesError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    installationActions.getElement,
    installationActions.createElement,
    installationActions.updateElement,
    installationActions.deleteElement,
    installationActions.getElementSettings,
    installationActions.updateElementSettings,
    (state) => {
      return {
        ...state,
        isElementLoading: true,
      };
    },
  ),
  on(
    installationActions.getElementError,
    installationActions.createElementError,
    installationActions.updateElementError,
    installationActions.deleteElementError,
    installationActions.getElementSettingsError,
    installationActions.updateElementSettingsError,
    (state) => {
      return {
        ...state,
        isElementLoading: false,
      };
    },
  ),

  //CARDS AND ELEMENT TYPES
  on(installationActions.getControllerCardsSuccess, (state, { cards }) => {

    const connectionsWithWarning = extractConnectionsWithWarnings(cards);
    const elementTypes = updateElementTypes(state.elementTypes, connectionsWithWarning);

    return {
      ...state,
      cards,
      currentConnectionID: null,
      currentElementType: null,
      currentElementID: null,
      currentConnectionSetup: null,
      isLoading: false,
      elementTypes,
    };
  }),
  on(installationActions.getCardElementTypesSuccess, (state, { elementTypes: elTypes }) => {
    const connectionsWithWarning = extractConnectionsWithWarnings(state.cards);
    const elementTypes = updateElementTypes(elTypes, connectionsWithWarning);

    return {
      ...state,
      elementTypes,
      isLoading: false,
    };
  }),

  // CURRENT CARD AND CONNECTION
  on(installationActions.setCurrentCard, (state, { cardID }) => {
    return {
      ...state,
      currentCardID: cardID,
    };
  }),

  on(installationActions.setCurrentConnection, (state, { connectionID }) => {
    return {
      ...state,
      currentConnectionID: connectionID,
      currentElementType: null,
      currentElementID: null,
      currentConnectionSetup: null,
    };
  }),

  // ELEMENT CRUD
  on(installationActions.getElementSuccess, (state, { setupData, elementType, connectionID }) => {
    return {
      ...state,
      currentConnectionID: connectionID,
      currentElementID: setupData.elementID,
      currentElementType: elementType,
      currentConnectionSetup: {
        setupData,
        isDirty: false,
        isNew: false,
      },
      isElementLoading: false,
    };
  }),
  on(installationActions.createElementSuccess, (state, { setupData }) => {
    const currentElementType = state.currentElementType;
    const elementTypes = [...state.elementTypes];
    const elementTypeIndex = elementTypes.findIndex(el => el.elementType === currentElementType);
    if (elementTypeIndex !== -1) {
      const availableElementNumbers = [...elementTypes[elementTypeIndex].availableElementNumbers]
        .filter(num => num !== state.currentConnectionSetup.setupData.number);

      elementTypes[elementTypeIndex] = {
        ...elementTypes[elementTypeIndex],
        connectedCount: elementTypes[elementTypeIndex].connectedCount + 1,
        availableElementNumbers,
        isWarning: Boolean(setupData?.offset),
      };
    }

    const cards = [...state.cards];
    const cardIndex = cards.findIndex(card => card.cardID === state.currentCardID);
    if (cardIndex !== -1) {
      const connections = [...cards[cardIndex].connections];
      const connectionIndex = connections.findIndex(connection => connection.connectionID === state.currentConnectionID);

      if (connectionIndex !== -1) {
        connections[connectionIndex] = {
          ...connections[connectionIndex],
          element: {
            elementID: setupData.elementID,
            elementType: setupData.elementType ?? currentElementType,
            number: state.currentConnectionSetup.setupData.number,
            connectionID: state.currentConnectionID,
            isWarning: Boolean(setupData?.offset),
          },
        };

        cards[cardIndex] = {
          ...cards[cardIndex],
          connections: [...connections],
        };
      }
    }

    return {
      ...state,
      cards,
      elementTypes,
      currentElementID: setupData.elementID,
      currentConnectionSetup: {
        setupData: {
          ...state.currentConnectionSetup.setupData,
          ...setupData,
        },
        isNew: false,
        isDirty: false,
      },
      isElementLoading: false,
    };
  }),
  on(installationActions.updateElementSuccess, (state, { setupData }) => {

    const cardIndex = state.cards.findIndex(card => card.cardID === state.currentCardID);
    const currentElementTypeIndex = state.elementTypes.findIndex(type => type.elementType === state.currentElementType);
    const cards = [...state.cards];
    const elementTypes = [...state.elementTypes];

    if (cardIndex !== -1) {
      const connectionIndex = state.cards[cardIndex].connections.findIndex(connection => connection.connectionID === state.currentConnectionID);
      const connections = [...cards[cardIndex].connections];
      if (connectionIndex !== -1) {
        connections[connectionIndex] = {
          ...connections[connectionIndex],
          element: {
            ...connections[connectionIndex].element,
            isWarning: Boolean(setupData?.offset),
          },
        };
        cards[cardIndex] = {
          ...cards[cardIndex],
          connections,
        };
      }
    }
    if (currentElementTypeIndex !== -1) {
      elementTypes[currentElementTypeIndex] = {
        ...elementTypes[currentElementTypeIndex],
        isWarning: Boolean(setupData?.offset),
      };
    }

    return {
      ...state,
      currentConnectionSetup: {
        setupData: {
          ...state.currentConnectionSetup.setupData,
          ...setupData,
        },
        isNew: false,
        isDirty: false,
      },
      isElementLoading: false,
      cards,
      elementTypes,
    };
  }),
  on(installationActions.deleteElementSuccess, (state, { numberToDelete }) => {
    const currentElementType = state.currentElementType;
    let elementTypes = [...state.elementTypes];
    const elementTypeIndex = elementTypes.findIndex(el => el.elementType === currentElementType);
    if (elementTypeIndex !== -1) {
      const availableElementNumbers = [...elementTypes[elementTypeIndex].availableElementNumbers, numberToDelete].sort();

      elementTypes[elementTypeIndex] = {
        ...elementTypes[elementTypeIndex],
        connectedCount: elementTypes[elementTypeIndex].connectedCount - 1,
        availableElementNumbers,
      };
    }

    const cards = [...state.cards];
    const cardIndex = cards.findIndex(card => card.cardID === state.currentCardID);
    if (cardIndex !== -1) {
      const connections = [...cards[cardIndex].connections];
      const connectionIndex = connections.findIndex(connection => connection.connectionID === state.currentConnectionID);

      if (connectionIndex !== -1) {
        connections[connectionIndex] = {
          ...connections[connectionIndex],
          element: null,
        };

        cards[cardIndex] = {
          ...cards[cardIndex],
          connections: [...connections],
        };
      }
    }

    const connectionsWithWarning = extractConnectionsWithWarnings(cards);
    elementTypes = updateElementTypes(elementTypes, connectionsWithWarning);

    return {
      ...state,
      elementTypes,
      cards,
      currentConnectionID: null,
      currentElementType: null,
      currentElementID: null,
      currentConnectionSetup: null,
      isElementLoading: false,
    };
  }),
  on(installationActions.setNewConnectionSetupSuccess, (state, { elementType, otherSetups }) => {
    const availableNumbers = state.elementTypes
      .find(elType => elType.elementType === elementType.elementType)
      ?.availableElementNumbers;

    return {
      ...state,
      currentConnectionSetup: {
        setupData: {
          number: availableNumbers?.[0] || 1,
          otherSetups,
        },
        isNew: true,
        isDirty: false,
      },
      currentElementType: elementType.elementType,
    };
  }),
  on(installationActions.setNewConnectionSetupIsDirtyForm, (state, { isDirtyForm }) => {
    return {
      ...state,
      currentConnectionSetup: {
        ...state.currentConnectionSetup,
        isDirty: isDirtyForm,
      },
    };
  }),
  on(installationActions.clearCurrentElementTypeAndConnectionSetup, (state, { isNew }) => {
    // after clicking CANCEL in element setup, navigating to another connection or going back to installation page
    // do not set currentConnectionID to null for building new connections
    return {
      ...state,
      currentConnectionID: isNew ? state.currentConnectionID : null,
      currentElementType: null,
      currentElementID: null,
      currentConnectionSetup: null,
    };
  }),

  // ELEMENT SETTINGS
  on(installationActions.getElementSettingsSuccess, (state, { elementSettings }) => {
    return {
      ...state,
      elementSettings,
      isElementLoading: false,
    };
  }),
  on(installationActions.updateElementSettingsSuccess, (state, payload) => {
    return {
      ...state,
      elementSettings: {
        ...state.elementSettings,
        ...payload.view,
      },
      isElementLoading: false,
    };
  }),
  on(installationActions.clearElementSettings, (state) => {
    return {
      ...state,
      elementSettings: null,
    };
  }),

  on(
    installationActions.getElementTestingData,
    installationActions.updateElementTestingData,
    state => {
      return {
        ...state,
        isTestingDataLoading: true,
      };
    }),

  on(
    installationActions.getElementTestingDataSuccess,
    installationActions.updateElementTestingDataSuccess,
    (state, { testingData: elementTestindData }) => {
      return {
        ...state,
        isTestingDataLoading: false,
        elementTestindData,
      };
    },
  ),

  on(
    installationActions.getElementTestingDataError,
    installationActions.updatetElementTestingDataError,
    state => {
      return {
        ...state,
        isTestingDataLoading: false,
      };
    }),
);

export function installationReducer(
  state: InstallationState | undefined,
  action: Action,
): InstallationState {
  return reducer(state, action);
}

// A function to extract connections with warnings from cards
function extractConnectionsWithWarnings(cards: ICard[]): object {
  // Filter cards that have at least one connection with a truthy 'element'
  const cardsWithValidConnections = cards.filter(card =>
    card.connections.some(connection => connection?.element),
  );
  // Extract and filter connections with a truthy 'isWarning'
  const connectionsWithWarning = cardsWithValidConnections.reduce((result, card) => {
    card.connections.forEach(connection => {
      const { element } = connection;
      if (element && element?.isWarning) {
        // Use 'elementType' as the key and the connection object as the value
        result[element.elementType] = connection;
      }
    });
    return result;
  }, {});
  return connectionsWithWarning;
}

// Common logic for updating 'elementTypes' based on 'connections'
function updateElementTypes(elementTypes: IElementType[], connectionsWithWarning): any {
  return elementTypes.map(type => {
    return {
      ...type,
      isWarning: type?.elementType in connectionsWithWarning,
    };
  });
}
