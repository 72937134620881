<div
  [class.d-none]="!editMode"
  [class.swiper-navigation__rtl]="languageService.isRtl"
  class="swiper-navigation">
  <div
    (click)="prev()"
    [class.disabled]="swiper?.swiperRef.realIndex === 0 || !items?.length"
    class="swiper-navigation-prev"></div>
  <div
    (click)="next()"
    [class.disabled]="swiper?.swiperRef.realIndex === items?.length - 1 || !items?.length"
    class="swiper-navigation-next"></div>
</div>

<div
  [class.rtl]="languageService.isRtl"
  [class.swiper-container__edit-mode]="editMode"
  class="swiper-container">
  <swiper
    *ngIf="swiperService.swiperIsActive"
    (activeIndexChange)="onIndexChange($event)"
    (paginationHide)="(editMode)"
    #swiper
    [class.swiper-wrapper__hide-pagination]="editMode"
    [config]="swiperService.config"
    [dir]="languageService.direction"
    [pagination]="{ clickable: true, dynamicBullets: true, dynamicMainBullets: 2 }"
    [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
    [touchEventsTarget]="'wrapper'"
    [ngClass]="{ 'view-mode': !editMode }"
    class="swiper-wrapper"
    swiperDirective>
    <ng-template
      *ngFor="let currentControl of items"
      swiperSlide>
      <div
        [class.table__rtl]="languageService.isRtl"
        [formGroup]="currentControl"
        class="table w-1-50 w-2-50 h-100-percent">
        <div class="table-row padding-top-11">
          <div class="table-cell blue">
            <div class="table-cell__main-child d-flex align-center justify-center h-100-percent">
              {{ "Controls.Program.Time" | translate }}
            </div>
            <div class="table-cell__child-group h-100-percent">
              <div class="table-cell__child">{{ "Controls.Program.Start" | translate }}</div>
              <div class="table-cell__child">{{ "Controls.Program.End" | translate }}</div>
              <div class="table-cell__child">{{ "Controls.Program.OnTime" | translate }}</div>
              <div class="table-cell__child">{{ "Controls.Program.OffTime" | translate }}</div>
            </div>
          </div>
          <div class="table-cell">
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child w-100-percent">
              <ls-time-picker-dialog-control
                [class.hidden]="!editMode && noFilledRows"
                [editMode]="editMode"
                [enableMeridian]="selectHourFormatForTimePicker$ | async"
                [isDisabled]="isDisabled"
                [isFieldInvalid]="currentControl.hasError(ErrorFieldEnum.OuterOverlap)"
                class="w-100-percent"
                formControlName="startTime">
              </ls-time-picker-dialog-control>
            </div>
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child w-100-percent">
              <ls-time-picker-dialog-control
                [class.hidden]="!editMode && noFilledRows"
                [editMode]="editMode"
                [enableMeridian]="selectHourFormatForTimePicker$ | async"
                [isDisabled]="isDisabled"
                [isFieldInvalid]="currentControl.hasError(ErrorFieldEnum.InnerOverlap)"
                class="w-100-percent"
                formControlName="endTime">
              </ls-time-picker-dialog-control>
            </div>
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child">
              <ls-input-integer
                [class.border-none__no-editing-mode]="!editMode"
                [class.disabled]="isDisabled"
                [class.hidden]="!editMode && noFilledRows"
                [fieldWithKeyboard]="true"
                [max]="CoolingProgramConstants.onTimeMax"
                [min]="CoolingProgramConstants.onTimeMin"
                [noMaxWidth]="true"
                [noPaddings]="true"
                class="align-center content"
                errorField
                formControlName="onTime"
                placeholder="0">
                <ng-container suffix>
                  <div class="unit">{{ "Sec" | translate | lowercase }}</div>
                </ng-container>
              </ls-input-integer>
            </div>
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child">
              <ls-input-integer
                [class.border-none__no-editing-mode]="!editMode"
                [class.disabled]="isDisabled"
                [class.hidden]="!editMode && noFilledRows"
                [fieldWithKeyboard]="true"
                [max]="CoolingProgramConstants.offTimeMax"
                [min]="CoolingProgramConstants.offTimeMin"
                [noMaxWidth]="true"
                [noPaddings]="true"
                class="align-center content"
                errorField
                formControlName="offTime"
                placeholder="0">
                <ng-container suffix>
                  <div class="unit">{{ "Sec" | translate | lowercase }}</div>
                </ng-container>
              </ls-input-integer>
            </div>
          </div>
        </div>
        <div class="table-row padding-top-11">
          <div class="table-cell blue">
            <div class="table-cell__main-child d-flex align-center justify-center h-100-percent">
              {{ "Controls.Program.Tempdiff" | translate }}
            </div>
            <div class="table-cell__child-group h-100-percent">
              <div class="table-cell__child">{{ "Controls.Program.Start" | translate }}</div>
              <div class="table-cell__child">{{ "Controls.Program.Stop" | translate }}</div>
            </div>
          </div>
          <div class="table-cell">
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child">
              <ls-input-decimal
                [accuracy]="1"
                [class.border-none__no-editing-mode]="!editMode"
                [class.disabled]="isDisabled"
                [class.hidden]="!editMode && noFilledRows"
                [fieldWithKeyboard]="true"
                [max]="CoolingProgramConstants.startTempMax"
                [min]="CoolingProgramConstants.startTempMin"
                [noMaxWidth]="true"
                class="align-center content"
                errorField
                formControlName="startTemp"
                placeholder="0.0">
              </ls-input-decimal>
            </div>
            <div
              [class.pointer-events-none]="!editMode"
              class="table-cell__child">
              <ls-input-decimal
                [accuracy]="1"
                [class.border-none__no-editing-mode]="!editMode"
                [class.disabled]="isDisabled"
                [class.hidden]="!editMode && noFilledRows"
                [fieldWithKeyboard]="true"
                [max]="CoolingProgramConstants.stopTempMax"
                [min]="CoolingProgramConstants.stopTempMin"
                [noMaxWidth]="true"
                class="align-center content"
                errorField
                formControlName="stopTemp"
                placeholder="0.0">
              </ls-input-decimal>
            </div>
          </div>
        </div>
        <div class="table-row padding-top-11">
          <div class="table-cell blue">{{ "Controls.Program.VentStartLevel" | translate }}</div>
          <div
            [class.pointer-events-none]="!editMode"
            class="table-cell">
            <ls-input-integer
              [class.border-none__no-editing-mode]="!editMode"
              [class.disabled]="isDisabled"
              [class.hidden]="!editMode && noFilledRows"
              [fieldWithKeyboard]="true"
              [max]="
                isflockModeAdvance
                  ? CoolingProgramConstants.ventStartLevelAdvancedMax
                  : CoolingProgramConstants.ventStartLevelMax
              "
              [min]="
                isflockModeAdvance
                  ? CoolingProgramConstants.ventStartLevelAdvancedMin
                  : CoolingProgramConstants.ventStartLevelMin
              "
              [noMaxWidth]="true"
              [noPaddings]="true"
              class="align-center content"
              errorField
              formControlName="ventStartLevel"
              placeholder="0">
              <ng-container
                *ngIf="!isflockModeAdvance"
                suffix>
                <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
              </ng-container>
            </ls-input-integer>
          </div>
        </div>
        <div class="table-row padding-top-11">
          <div class="table-cell blue">{{ "Controls.Program.HumidityLevelToStop" | translate }}</div>
          <div
            [class.pointer-events-none]="!editMode"
            class="table-cell">
            <ls-input-integer
              [class.border-none__no-editing-mode]="!editMode"
              [class.disabled]="isDisabled"
              [class.hidden]="!editMode && noFilledRows"
              [fieldWithKeyboard]="true"
              [max]="CoolingProgramConstants.humStopLevelMax"
              [min]="humLevelToRestart || CoolingProgramConstants.humStopLevelMin"
              [noMaxWidth]="true"
              [noPaddings]="true"
              class="align-center content"
              errorField
              formControlName="humStopLevel"
              placeholder="00">
              <ng-container suffix>
                <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
              </ng-container>
            </ls-input-integer>
          </div>
        </div>
        <div class="table-row padding-top-11">
          <div class="table-cell blue">{{ "Controls.Program.Device" | translate }}</div>
          <div
            [class.pointer-events-none]="!editMode"
            class="table-cell">
            <ls-program-device-buttons
              [class.disabled]="isDisabled"
              [class.hidden]="!editMode && noFilledRows"
              [controls]="currentControl"
              class="content">
            </ls-program-device-buttons>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
