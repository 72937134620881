import { Component } from '@angular/core';
import { MainWidgetCommonComponent } from '../main-widget-common.component';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-main-inlet-widget',
  templateUrl: './main-inlet-widget.component.html',
  styleUrls: ['./main-inlet-widget.component.scss'],
})
export class MainInletWidgetComponent extends MainWidgetCommonComponent {
}

