<form [formGroup]="form">
  <ls-input-integer
    [min]="0"
    [fieldWithKeyboard]="true"
    [qaTags]="'qa-txt-test-time'"
    formControlName="time"></ls-input-integer>
  <span class="position-absolute color-tertiary fz-16 sec">{{ "Installation.Card.Cooling.Sec" | translate }}</span>
</form>

<div
  *ngIf="!platformService.isMobileApp"
  class="keyboard-wrapper">
  <ls-keyboard [defaultMode]="KeyboardModeEnum.NumericOnly"> </ls-keyboard>
</div>
