import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  ButtonComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  PageWrapperComponent,
  setKeyboardMode,
  SvgIconComponent,
} from '@livestock/ui';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { ColorsEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import {
  ChartColors,
  ChartLegendItem,
  clearTemperatureCurveState,
  getTemperatureCurve,
  getTemperatureCurveSettings,
  ITemperatureCurveItem,
  ITemperatureCurveSettings,
  selectIsLoading,
  selectTemperatureCurveItems,
  selectTemperatureCurveSettings,
  TemperatureCurveChartComponent,
  TemperatureCurveRoute,
  TemperatureCurveSettingsFormComponent,
  TemperatureCurveTableComponent,
  updateTemperatureCurve,
  updateTemperatureCurveSettings,
} from '@livestock/temperature-curve';
import { ChartDataset, ChartOptions, FillTarget, PointStyle } from 'chart.js';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-temperature-curve',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    TemperatureCurveTableComponent,
    TemperatureCurveSettingsFormComponent,
    TemperatureCurveChartComponent,
    MemoizeFuncPipe,
    PageWrapperComponent,
  ],
  templateUrl: './temperature-curve.component.html',
  styleUrls: ['./temperature-curve.component.scss'],
})
export class TemperatureCurveComponent implements OnDestroy, OnInit, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);

  temperatureCurveRoute: string;
  activeControllerId: number;
  editMode: boolean;

  originalTemperatureCurveSettings: ITemperatureCurveSettings;
  temperatureCurveSettings: ITemperatureCurveSettings;
  isSettingsValid: boolean;
  isSettingsDirty: boolean = false;

  originalTemperatureCurve: ITemperatureCurveItem[];
  temperatureCurve: ITemperatureCurveItem[];
  isTemperatureCurveValid: boolean;
  isTemperatureCurveDirty: boolean = false;
  isTemperatureCurveHasUnsaved: boolean = false;

  chartOptions: ChartOptions;
  chartLegend: ChartLegendItem[];

  KeyboardModeEnum = KeyboardModeEnum;
  TemperatureCurveRoute = TemperatureCurveRoute;
  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private router: Router,
    private dialogsService: DialogsService,
    private translate: TranslateService,
  ) {
  }

  getChartLegend(): ChartLegendItem[] {
    return [
      {
        title: this.translate.instant('TemperatureCurve.TargetTemp'),
        color: ChartColors.TargetColor,
      },
      {
        title: this.translate.instant('TemperatureCurve.ComfortZone'),
        color: ChartColors.RangeColor,
      },
      {
        title: this.translate.instant('TemperatureCurve.Alarm'),
        color: ChartColors.AlarmColor,
      },
    ];
  }

  getTitle(temperatureCurveRoute: TemperatureCurveRoute): string {
    switch (temperatureCurveRoute) {
      case TemperatureCurveRoute.Settings:
        return 'TemperatureCurve.TemperatureCurveSettings';
      case TemperatureCurveRoute.Chart:
        return 'TemperatureCurve.TemperatureCurveChart';
      default:
        return 'TemperatureCurve.TemperatureCurve';
    }
  }

  changedTempCurve(event: { formValues: any; isValid: boolean; isDirty: boolean }): void {
    this.temperatureCurve = event.formValues;
    this.isTemperatureCurveValid = event.isValid && event.isDirty;
    this.isTemperatureCurveDirty = JSON.stringify(this.originalTemperatureCurve) !== JSON.stringify(this.temperatureCurve);
    this.isTemperatureCurveHasUnsaved = this.temperatureCurve.length > this.originalTemperatureCurve.length;
  }

  changeTemperatureCurveSettings(event: { formValues: any; isValid: boolean; isDirty: boolean }): void {
    this.isSettingsValid = event.isValid && event.isDirty;
    this.isSettingsDirty = event.isDirty;

    this.temperatureCurveSettings = {
      controllerID: this.activeControllerId,
      ...event.formValues,
    };
  }

  async confirmUnsavedChanges(): Promise<boolean> {
    return await this.dialogsService.canContinueAction();
  }

  async goBack(): Promise<void> {
    if (this.temperatureCurveRoute === TemperatureCurveRoute.Settings && this.isSettingsDirty) {
      const confirmationResult = await this.confirmUnsavedChanges();
      if (!confirmationResult) return;
    }

    if (this.temperatureCurveRoute !== TemperatureCurveRoute.Table) {
      this.temperatureCurveSettings = {
        ...this.originalTemperatureCurveSettings,
      };
      this.isSettingsDirty = false;
      this.isSettingsValid = false;
      this.changeRoute(TemperatureCurveRoute.Table);
      return;
    }

    this.menuService.toggleDashboardMenu(true);
  }

  async cancel(): Promise<void> {
    if (this.isTemperatureCurveDirty) {
      const confirmationResult = await this.confirmUnsavedChanges();
      if (!confirmationResult) return;
    }

    // to reset temp curve table
    this.originalTemperatureCurve = JSON.parse(JSON.stringify(this.originalTemperatureCurve));
    this.temperatureCurve = this.originalTemperatureCurve;
    this.isTemperatureCurveDirty = false;
    this.editMode = false;
  }

  async cancelSettings(): Promise<void> {
    if (this.isSettingsDirty) {
      const confirmationResult = await this.confirmUnsavedChanges();
      if (!confirmationResult) return;
    }

    this.temperatureCurveSettings = {
      ...this.originalTemperatureCurveSettings,
    };
    this.isSettingsDirty = false;
    this.editMode = false;
  }

  update(): void {
    switch (this.temperatureCurveRoute) {
      case TemperatureCurveRoute.Table:
        this.updateTable();
        break;
      case TemperatureCurveRoute.Settings:
        this.updateSettings();
        break;
      default:
        console.error(
          `${TemperatureCurveComponent.name}: ${this.update.name} not implemented for ${this.temperatureCurveRoute}.`,
        );
    }
  }

  private updateSettings(): void {
    if (!this.isSettingsValid) return;
    this.originalTemperatureCurveSettings = this.temperatureCurveSettings;
    this.store.dispatch(updateTemperatureCurveSettings({ view: this.originalTemperatureCurveSettings }));
    this.isSettingsDirty = false;
    this.isSettingsValid = false;
  }

  private changeRoute(viewType: TemperatureCurveRoute): void {
    this.router.navigate([AppRoutes.CONTROLLER, this.activeControllerId, ControllerRoutes.TemperatureCurve, viewType]);
  }

  private async updateTable(): Promise<void> {
    if (!this.isTemperatureCurveValid) {
      return;
    }
    this.originalTemperatureCurve = this.temperatureCurve?.sort((a, b) => a.day - b.day);
    this.store.dispatch(updateTemperatureCurve({ view: { items: this.temperatureCurve } }));
    this.isTemperatureCurveDirty = false;
    this.isTemperatureCurveValid = false;
    this.editMode = false;
    await this.setChartOptions();
  }

  openChart(): void {
    this.changeRoute(TemperatureCurveRoute.Chart);
  }

  openSettings(): void {
    this.changeRoute(TemperatureCurveRoute.Settings);
  }

  toggleEditMode(): void {
    if (this.temperatureCurveRoute === TemperatureCurveRoute.Chart) {
      this.goBack();
    }

    if (this.editMode && this.temperatureCurveRoute === TemperatureCurveRoute.Table) {
      this.cancel();
      return;
    }

    if (this.editMode && this.temperatureCurveRoute === TemperatureCurveRoute.Settings) {
      this.cancelSettings();
      return;
    }

    this.editMode = true;
  }

  getChartData([items, settings]: [ITemperatureCurveItem[], ITemperatureCurveSettings]): ChartDataset[] {
    if (!items || !settings) {
      return [];
    }

    const generateDataset = (
      fill: FillTarget,
      borderColor: string,
      pointBackgroundColor: string,
      data: ChartDataset['data'],
      backgroundColor: string = null,
      pointStyle: PointStyle = false,
    ): ChartDataset => {
      const dataset: ChartDataset = {
        fill,
        borderColor,
        pointBackgroundColor,
        data,
        pointStyle,
      };

      if (backgroundColor !== null) {
        dataset['backgroundColor'] = backgroundColor;
      }

      return dataset;
    };

    const highAlarmData = [];
    const highComfortData = [];
    const targetData = [];
    const lowComfortData = [];
    const lowAlarmData = [];

    items.forEach((item) => {
      targetData.push({ x: item.day, y: item.target });
      highComfortData.push({ x: item.day, y: item.target + settings.comfortZone });
      lowComfortData.push({ x: item.day, y: item.target - settings.comfortZone });
      highAlarmData.push({ x: item.day, y: item.highTemperatureAlarm });
      lowAlarmData.push({ x: item.day, y: item.lowTemperatureAlarm });
    });

    const highAlarm = generateDataset(false, ChartColors.AlarmColor, ChartColors.AlarmColor, highAlarmData);
    const highComfort = generateDataset(
      '+1',
      ChartColors.RangeColor,
      ChartColors.RangeColor,
      highComfortData,
      ChartColors.RangeColor + ChartColors.RangeOpacity,
    );
    const target = generateDataset(false, ChartColors.TargetColor, ChartColors.TargetColor, targetData);
    const lowComfort = generateDataset(false, ChartColors.RangeColor, ChartColors.RangeColor, lowComfortData);
    const lowAlarm = generateDataset(false, ChartColors.AlarmColor, ChartColors.AlarmColor, lowAlarmData);

    return [highAlarm, highComfort, lowComfort, target, lowAlarm];
  }

  async getChartOptions(items: ITemperatureCurveItem[]): Promise<ChartOptions> {
    if (!items) return {};
    const max: number = Math.max(...items.map((item) => item.day));
    const unit = await firstValueFrom(this.temperatureUnit$);

    return {
      maintainAspectRatio: false,
      responsive: true,
      plugins: {
        tooltip: {
          enabled: true,
          callbacks: {
            title: (tooltipItems) => {
              return tooltipItems[0].label + ` ${this.translate.instant('TemperatureCurve.Day')}`;
            },
            label: (tooltipItems) => {
              return `${tooltipItems.formattedValue} ${this.translate.instant(
                unit === TemperatureUnitEnum.Celsius ? 'Celsius' : 'Fahrenheit',
              )}`;
            },
          },
        },
      },
      scales: {
        x: {
          max,
          display: true,
          type: 'linear',
          position: 'bottom',
          title: {
            font: {
              family: 'Roboto',
              size: 16,
            },
            display: true,
            text: `${this.translate.instant('TemperatureCurve.Days')}`,
            padding: {
              top: 15,
            },
          },
          ticks: {
            font: {
              family: 'Roboto',
              size: 16,
            },
          },
        },
        y: {
          display: true,
          title: {
            display: true,
            font: {
              family: 'Roboto',
              size: 16,
            },
            text: `${this.translate.instant('TemperatureCurve.Temperature')}`,
            padding: {
              bottom: 15,
            },
          },
          ticks: {
            font: {
              family: 'Roboto',
              size: 16,
            },
          },
        },
      },
    };
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.activeControllerId = +params['controllerID'];
        this.temperatureCurveRoute = params['viewType'];
        this.editMode = false;
      }),
    );

    this.store.dispatch(getTemperatureCurve());
    this.store.dispatch(getTemperatureCurveSettings());
    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));

    this.sub$.add(
      this.store
        .select(selectTemperatureCurveSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((temperatureCurveSettings) => {
          this.originalTemperatureCurveSettings = temperatureCurveSettings;
          this.temperatureCurveSettings = temperatureCurveSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectTemperatureCurveItems)
        .pipe(wasChangedAndNotNull())
        .subscribe(async (temperatureCurve) => {
          if (!this.originalTemperatureCurve) {
            this.originalTemperatureCurve = temperatureCurve;
          }
          this.temperatureCurve = temperatureCurve;

          await this.setChartOptions();

          if (!this?.chartLegend?.length) {
            this.chartLegend = this.getChartLegend();
          }
        }),
    );
  }

  async setChartOptions(): Promise<void> {
    this.chartOptions = await this.getChartOptions(this.temperatureCurve);
  }

  canDeactivate(): boolean {
    if (this.temperatureCurveRoute === TemperatureCurveRoute.Table) {
      return !this.isTemperatureCurveDirty;
    }

    if (this.temperatureCurveRoute === TemperatureCurveRoute.Settings) {
      return !this.isSettingsDirty;
    }

    return true;
  }

  closeComponent(): void {
    this.store.dispatch(clearTemperatureCurveState());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
