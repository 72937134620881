import { Action, createReducer, on } from '@ngrx/store';
import * as lightingActions from './lighting-program.actions';
import { LightingProgramState } from './lighting-program.state';
import { filterUnusedPeriods } from '@livestock/sprinklers-foggers-program';
import { LightingPeriod } from '../interfaces/lighting.interfaces';

export const LIGHTING_FEATURE_KEY = 'lightingProgram';

export const initialState: LightingProgramState = {
  isLoading: false,
  periods: null,
  currentProgram: null,
  currentPeriod: null,
  lightAOElements: [],
  lightDOElements: [],
};

const reducer = createReducer(
  initialState,
  on(
    lightingActions.getLightingProgram,
    lightingActions.getLightingPeriod,
    lightingActions.saveLightingProgram,
    lightingActions.deleteLightingDay,
    lightingActions.deleteLightingProgram,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),

  on(
    lightingActions.addLightingDayError,
    lightingActions.getLightingProgramError,
    lightingActions.deleteLightingProgramError,
    lightingActions.getLightingPeriodError,
    lightingActions.saveLightingProgramError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),

  on(
    lightingActions.getLightingProgramSuccess,
    (state, { item }) => {
      return {
        ...state,
        isLoading: false,
        currentProgram: item,
      };
    },
  ),
  on(
    lightingActions.deleteLightingProgramSuccess,
    (state, { periodID }) => {
      if (!periodID) return { ...state };
      return {
        ...state,
        isLoading: false,
        currentProgram: {
          ...state.currentProgram,
          items: state.currentProgram.items.filter(i => i.periodID !== periodID),
        },
      };
    },
  ),

  on(
    lightingActions.deleteLightingDaySuccess,
    (state, { periodID, preventSelecting }) => {
      const periods = state.periods.filter(per => per.periodID !== periodID);
      return {
        ...state,
        isLoading: false,
        periods,
        currentPeriod: preventSelecting ? null : periods[0],
      };
    },
  ),

  on(
    lightingActions.addLightingDaySuccess,
    (state, { dayNumber, controllerID }) => {
      const hasDay = state.periods.some(period => period.dayNumber === dayNumber);
      if (hasDay) {
        return state;
      }

      const periods: LightingPeriod[] = [...state.periods || [], { dayNumber, controllerID }];

      return {
        ...state,
        isLoading: false,
        periods: periods.sort((a, b) => a.dayNumber - b.dayNumber),
        currentProgram: { items: [] },
        currentPeriod: {
          dayNumber,
          controllerID,
        },
      };
    },
  ),

  on(
    lightingActions.getLightingPeriodSuccess,
    (state, { items, controllerID, lightAOElements, lightDOElements }) => {
      return {
        ...state,
        isLoading: false,
        periods: items.length ? items : [{
          controllerID,
          dayNumber: 0,
        }],
        lightAOElements,
        lightDOElements,
      };
    },
  ),
  on(
    lightingActions.clearProgram,
    (state) => {
      return {
        ...state,
        currentProgram: {
          lightDOElements: [],
          lightAOElements: [],
          items: [],
        },
      };
    },
  ),
  on(
    lightingActions.filterPeriods,
    (state, { currentPeriod }) => {
      return {
        ...state,
        periods: state.periods?.length > 1 ? filterUnusedPeriods(currentPeriod, [...state.periods]) : [...state.periods],
        currentPeriod: currentPeriod ?? state.periods[0],
      };
    },
  ),
  on(
    lightingActions.saveLightingProgramSuccess,
    (state, { period, items }) => {
      return {
        ...state,
        isLoading: false,
        periods: state.periods.map(i => i.periodID ? ({ ...i }) : ({ ...i, periodID: period.periodID })),
        currentPeriod: period,
        currentProgram: {
          ...state.currentProgram,
          items,
        },
      };
    },
  ),

  on(
    lightingActions.setCurrentLightingProgram,
    (state, { currentProgram }) => {
      return {
        ...state,
        currentProgram,
      };
    },
  ),

  on(
    lightingActions.setCurrentLightingPeriod,
    (state, { currentPeriod }) => {
      return {
        ...state,
        currentPeriod,
      };
    },
  ),

  on(
    lightingActions.resetLightingProgram,
    () => {
      return { ...initialState };
    },
  ),
);

export function lightingReducer(
  state: LightingProgramState | undefined,
  action: Action,
): LightingProgramState {
  return reducer(state, action);
}
