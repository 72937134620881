import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  clearElementSettings,
  getElementSettings,
  updateElementSettings,
} from '../../../+state/element-settings.actions';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { FormGroup } from '@angular/forms';
import { IElementSettings } from '../../../interfaces/element/element-settings.interface';
import { KeyboardModeEnum } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';
import { ElementWrapperHelper } from '../helpers/element-wrapper.helper';
import { Observable, BehaviorSubject, Subscription } from 'rxjs';
import { selectElementIsLoading } from '../../../+state/installation.selectors';
import { HoursFormatTypeEnum, selectCurrentControllerTimeFormat } from '@livestock/controllers';
import { Location } from '@angular/common';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'ls-element-settings-wrapper',
  templateUrl: './element-settings-wrapper.component.html',
  styleUrls: ['./element-settings-wrapper.component.scss'],
})
export class ElementSettingsWrapperComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  sub$ = new Subscription();
  isElementLoading$: Observable<boolean> = this.store.select(selectElementIsLoading);
  isResetFormSubj$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  isSaveFormSubj$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  currentHoursFormat$: Observable<HoursFormatTypeEnum> = this.store.select(selectCurrentControllerTimeFormat);

  /*vars*/
  parentForm: FormGroup = new FormGroup({});
  childGroupName: string;
  isEditMode: boolean = false;
  currentElementType: ElementTypesEnum;
  isTimePickerSelected: boolean = false;
  isDirty: boolean = false;

  /*constants*/
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  helper = ElementWrapperHelper;
  HoursFormatTypeEnum = HoursFormatTypeEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private dialog: DialogsService,
    private location: Location,
    private translateService: TranslateService,
  ) {
  }

  ngOnInit(): void {
    this.currentElementType = +this.activatedRoute.snapshot.params['elementType'];
    // TODO: fix console error "Cannot find control with name: 'staticPressure'"
    this.childGroupName = this.helper.childFormGroupNameByType(this.currentElementType);

    this.store.dispatch(getElementSettings({
      elementType: this.currentElementType,
    }));
  }

  async returnBack(): Promise<void> {
    if (this.isEditMode && this.isDirty) {
      const canGoBack = await this.dialog.canContinueAction();
      if (!canGoBack) {
        return;
      }
    }

    this.location.back();
    this.isDirty = false;
  }

  async toggleEditMode(): Promise<void> {
    if (this.isEditMode && this.isDirty) {
      const canToggle = await this.dialog.canContinueAction();
      if (!canToggle) {
        return;
      }
      this.isResetFormSubj$.next(true);
      this.isDirty = false;
    }

    this.isEditMode = !this.isEditMode;
  }

  save(): void {
    if (this.parentForm.invalid) {
      return;
    }

    const childForm = this.parentForm.get(this.childGroupName) as FormGroup;

    const view: IElementSettings = {
      ...childForm.getRawValue(),
    };

    this.store.dispatch(updateElementSettings({
      view,
      elementType: this.currentElementType,
    }));

    this.isSaveFormSubj$.next(true);
    this.isDirty = false;

    this.isEditMode = !this.isEditMode;
  }

  getSettingsTitle(elementType: ElementTypesEnum): string {
    return `${this.translateService.instant(this.helper.childElementTitleByType(elementType))} ${this.translateService.instant('Settings')}`;
  }

  canDeactivate(): boolean | Promise<boolean> {
    return !this.parentForm.invalid && !this.isDirty;
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearElementSettings());
  }
}
