<div class="main-input-container position-relative">
  <div class="ls-input">
    <div class="input-wrapper">
      <input
        (blur)="onBlur()"
        (ngModelChange)="onModelChange($event)"
        [autofocus]="autofocus"
        [disabled]="isDisabled"
        [max]="max"
        [maxlength]="maxlength"
        [min]="min"
        [ngModel]="value"
        [pattern]="pattern"
        [placeholder]="placeholder || ''"
        [qaTags]="qaTags"
        [step]="step"
        [type]="type"
        class="text-{{ textPosition }}" />
      <span
        #content
        [ngClass]="{ icon__rtl: isRtl, icon: isContent }">
        <ng-content select="[suffix]"></ng-content>
      </span>
      <span
        [ngClass]="isRtl ? 'left-12' : 'right-12'"
        class="position-absolute">
        <ng-content select="[icon]"></ng-content>
      </span>
    </div>
  </div>
  <div class="error">
    <ng-content select="[errItem]"></ng-content>
  </div>
</div>
