<form [formGroup]="form">
  <div class="container">
    <ls-toggle
      [value]="form.value.enabled"
      [qaTags]="'qa-light-do-test-toggle'"
      [size]="'large'"
      [type]="ToggleType.Buttons"
      [disabled]="true"
      formControlName="enabled" />
  </div>
</form>
