<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Lighting</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content ph-10 ph-0-mobile">
      <table class="w-100-percent fz-32 text-center">
        <thead class="color-tertiary">
          <tr class="border-bottom">
            <th class="fz-16 border-right fz-18-mobile">Zone</th>
            <th class="w-120 border-right">
              <div class="d-flex fz-24 align-center justify-center align-end-xl">
                <span
                  [qaTags]="'qa-lbl-light-zone-p-num'"
                  class="mr-15 mr-10-xl line-height-07"
                  >1</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-light-zone-p-img'"
                  path="dashboard/light"></ls-svg-icon>
              </div>
            </th>
            <th class="w-120 border-right">
              <div class="d-flex fz-24 align-center justify-center align-end-xl">
                <span
                  [qaTags]="'qa-lbl-light-zone-p-num'"
                  class="mr-15 mr-10-xl line-height-07"
                  >2</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.BlueDefault"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-light-zone-p-img'"
                  path="dashboard/light"></ls-svg-icon>
              </div>
            </th>
            <th>
              <div class="d-flex fz-24 align-center align-end-xl">
                <span
                  [qaTags]="'qa-lbl-light-zone-p-num'"
                  class="mr-15 mr-10-xl line-height-07"
                  >3</span
                >
                <ls-svg-icon
                  [color]="ColorsEnum.DefaultDisabled"
                  [hasMargin]="true"
                  [qaTags]="'qa-img-light-zone-p-img'"
                  path="dashboard/light"></ls-svg-icon>
              </div>
            </th>
          </tr>
        </thead>

        <tbody class="line-height-1">
          <tr>
            <td class="border-right"></td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl border-right border-bottom">
              75<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >%</span
              >
            </td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl border-right border-bottom">
              00<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >%</span
              >
            </td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl border-bottom">
              00<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >%</span
              >
            </td>
          </tr>

          <tr>
            <td class="border-right"></td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl border-right">
              36<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >LUX</span
              >
            </td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl border-right">
              42<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >LUX</span
              >
            </td>
            <td
              [qaTags]="'qa-lbl-light-zone-p-value'"
              class="fz-32 fz-22-xl">
              00<span
                [qaTags]="'qa-lbl-light-zone-p-units'"
                class="fz-16"
                >LUX</span
              >
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
