<section
  *ngIf="!(isLoading$ | async) && form; else loading"
  class="page-content d-flex">
  <div class="d-flex flex-column w-100-percent">
    <div class="content-wrapper__header-container d-flex justify-between mb-15">
      <ls-header-title
        (cancel)="goBack()"
        [title]="'Controls.Element.BasicVentilationMinimum'">
      </ls-header-title>
      <div class="buttons d-flex align-center">
        <ls-svg-icon
          *ngIf="!editMode"
          (click)="enableEdit()"
          [path]="platformService.isMobileApp ? 'mobile/edit' : 'installation/edit'"
          class="icon pointer">
        </ls-svg-icon>
        <ng-container *ngIf="!platformService.isMobileApp || !editMode">
          <ls-svg-icon
            *ngIf="editMode"
            (click)="cancel()"
            [color]="ColorsEnum.BlueDark"
            class="icon pointer"
            path="installation/table">
          </ls-svg-icon>
          <ls-svg-icon
            [class.pointer-events-none]="editMode"
            [color]="ColorsEnum.DefaultDisabled"
            [path]="platformService.isMobileApp ? 'installation/graph-mobile' : 'installation/graph'"
            class="icon pointer">
          </ls-svg-icon>
          <ls-svg-icon
            (click)="redirectToSettings()"
            [class.pointer-events-none]="editMode"
            [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
            [path]="platformService.isMobileApp ? 'mobile/settings' : 'installation/settings'"
            class="icon pointer">
          </ls-svg-icon>
        </ng-container>
      </div>
    </div>

    <div class="d-flex justify-between h-100-percent min-height-0">
      <div class="content-wrapper">
        <div class="program content-wrapper__data-container">
          <div class="item__container">
            <div
              *ngIf="!platformService.isMobileApp; else mobile"
              class="table">
              <div class="table-row table-header h-60">
                <div class="table-cell blue">
                  {{ "Controls.Element.Day" | translate }}
                </div>
                <div class="table-cell blue">
                  {{ "Controls.Element.OnTime" | translate }}
                </div>
                <div class="table-cell blue">
                  {{ "Controls.Element.OffTime" | translate }}
                </div>
                <div class="table-cell blue">
                  {{ "Controls.Element.Fans" | translate }}
                </div>
                <div class="table-cell blue">
                  {{ "Controls.Element.Inlets" | translate }}
                </div>
                <div class="table-cell blue">
                  {{ "Controls.Element.Capacity" | translate }}
                </div>
              </div>
              <ng-container *ngIf="form">
                <ng-container
                  *ngFor="let item of form.get('items')['controls']; let index = index; let isLast = last"
                  [formGroup]="item">
                  <div
                    (click)="setSelectedIndex(index)"
                    [class.active]="selectedIndex === index && editMode"
                    [class.last-row]="isLast && selectedIndex !== index"
                    class="table-row padding-top-11">
                    <div
                      [class.blue]="platformService.isDeviceApp"
                      class="table-cell no-border-bottom">
                      <div
                        *ngIf="!editMode && item.value.dayNumber != null"
                        class="d-flex flex-row align-center">
                        {{ item.value.dayNumber }}
                      </div>
                      <div *ngIf="editMode">
                        <ls-input-integer
                          *ngIf="editMode"
                          (change)="checkDuplicacy()"
                          [class.border-none__no-editing-mode]="!editMode"
                          [fieldWithKeyboard]="true"
                          [max]="BasicVentilationConstants.maxDayValue"
                          [min]="BasicVentilationConstants.minDayValue"
                          [noMaxWidth]="true"
                          [noPaddings]="true"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="dayNumber">
                        </ls-input-integer>
                      </div>
                    </div>
                    <div class="table-cell no-border-bottom">
                      <div
                        *ngIf="!editMode && item.value.onTime != null"
                        class="d-flex flex-row align-center">
                        {{ item.value.onTime }} {{ "Sec" | translate }}
                      </div>
                      <div *ngIf="editMode">
                        <ls-input-integer
                          [class.border-none__no-editing-mode]="!editMode"
                          [fieldWithKeyboard]="true"
                          [min]="0"
                          [noMaxWidth]="true"
                          [noPaddings]="true"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="onTime">
                        </ls-input-integer>
                      </div>
                    </div>
                    <div class="table-cell no-border-bottom">
                      <div
                        *ngIf="!editMode && item.value.offTime != null"
                        class="d-flex flex-row align-center">
                        {{ item.value.offTime }} {{ "Sec" | translate }}
                      </div>
                      <div *ngIf="editMode">
                        <ls-input-integer
                          [class.border-none__no-editing-mode]="!editMode"
                          [fieldWithKeyboard]="true"
                          [min]="0"
                          [noMaxWidth]="true"
                          [noPaddings]="true"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="offTime">
                        </ls-input-integer>
                      </div>
                    </div>
                    <div
                      (click)="setActiveCell($event, index)"
                      [class.active-cell]="isCellActive && selectedIndex === index && editMode"
                      class="table-cell no-border-bottom justify-start">
                      <div class="d-flex flex-row align-center">
                        <ls-icon-input-button
                          (change)="onPercentageChange($event)"
                          (onFocusIn)="isIconInputActive = $event"
                          (onFocusOut)="onFocusOut($event)"
                          (setActiveIcon)="onIconClick($event)"
                          [activeIndex]="selectedIndex === index ? selectedFanIndex : null"
                          [editMode]="editMode"
                          [items]="item.get('fans')?.controls"
                          [max]="BasicVentilationConstants.maxPercentage"
                          [min]="BasicVentilationConstants.minPercentage"
                          [path]="'ventilation'">
                        </ls-icon-input-button>
                      </div>
                    </div>
                    <div class="table-cell no-border-bottom justify-start">
                      <div class="d-flex flex-row align-center">
                        <ls-icon-input-button
                          [editMode]="editMode"
                          [items]="item.get('inlets')?.controls"
                          [path]="'dashboard/inlet-bottom'"
                          [width]="25">
                        </ls-icon-input-button>
                      </div>
                    </div>
                    <div
                      [class.non-editable]="editMode"
                      class="table-cell no-border-bottom">
                      <div class="d-flex flex-row align-center"></div>
                    </div>
                  </div>
                </ng-container>
              </ng-container>
            </div>
          </div>
          <div
            *ngIf="editMode"
            class="program__footer">
            <div
              *ngIf="!platformService.isMobileApp"
              class="program__footer__buttons d-flex align-center">
              <ls-button
                [type]="'secondary'"
                class="program__footer-button program__footer-button--icon mr-16 h-button-36">
                <ls-svg-icon
                  (click)="add()"
                  [color]="ColorsEnum.Primary"
                  path="table/plus">
                </ls-svg-icon>
              </ls-button>
              <ls-button
                (click)="remove()"
                [class.disabled]="!form.get('items')?.value?.length && selectedIndex == null"
                [type]="'secondary'"
                class="program__footer-button program__footer-button--icon h-button-36">
                <ls-svg-icon
                  [color]="ColorsEnum.Primary"
                  path="table/delete">
                </ls-svg-icon>
              </ls-button>
            </div>
            <div class="program__footer__buttons d-flex align-center">
              <ls-button
                (click)="cancel()"
                [class.h-button-36]="platformService.isMobileApp"
                [class.w-button-130]="platformService.isMobileApp"
                [type]="'secondary'"
                class="program__footer-button mr-16">
                {{ "Controls.Element.Cancel" | translate }}
              </ls-button>
              <ls-button
                (click)="save()"
                [class.disabled]="form?.invalid || canDeactivateValue"
                [class.h-button-36]="platformService.isMobileApp"
                [class.w-button-130]="platformService.isMobileApp"
                class="program__footer-button">
                {{ "Controls.Element.Save" | translate }}
              </ls-button>
            </div>
          </div>
          <div
            *ngIf="!editMode && isEmpty && !platformService.isMobileApp"
            class="empty-state d-flex flex-column justify-center align-center h-80-percent">
            <ls-svg-icon [path]="'heating-program/empty-state'"> </ls-svg-icon>
            <span class="empty-state-title mt-10">
              {{ "Controls.Element.BasicVentilationIsNotConfigured" | translate }}
            </span>
            <ls-button
              (click)="enableEdit()"
              [qaTags]="'qa-btn-empty'"
              [type]="'primary'">
              {{ "Controls.Element.ClickHereToConfigure" | translate }}
            </ls-button>
          </div>
          <ng-template #mobile>
            <div
              *ngIf="form?.get('items')?.['controls']?.[selectedIndex] as item"
              [formGroup]="item"
              class="mobile-view">
              <div
                *ngIf="!editMode"
                class="days-caption">
                {{ "Controls.Element.Days" | translate }}
              </div>
              <div
                *ngIf="editMode"
                class="d-flex flex-row justify-between align-end mb-15">
                <div class="d-flex flex-column position-relative">
                  <span>{{ "Controls.Element.Day" | translate }}</span>
                  <ls-input-integer
                    (change)="checkDuplicacy()"
                    [class.border-none__no-editing-mode]="!editMode"
                    [fieldWithKeyboard]="true"
                    [max]="BasicVentilationConstants.maxDayValue"
                    [min]="BasicVentilationConstants.minDayValue"
                    [noMaxWidth]="true"
                    [noPaddings]="true"
                    class="align-center w-input-80 mt-5"
                    errorField
                    formControlName="dayNumber">
                  </ls-input-integer>
                </div>
                <ls-days-manager-mobile
                  (addDay)="add()"
                  (deleteDay)="remove()">
                </ls-days-manager-mobile>
              </div>

              <div class="d-flex flex-row">
                <div
                  *ngIf="!editMode"
                  class="program-days mr-20">
                  <div class="program-days__scrollbar">
                    <div
                      *ngFor="let day of days"
                      (click)="setActiveDay(day)"
                      [class.program-day__active]="day === activeDay"
                      class="program-day">
                      {{ day }}
                    </div>
                  </div>
                </div>

                <div
                  [class.w-1-35]="editMode"
                  [class.w-1-50]="!editMode"
                  [class.w-2-50]="!editMode"
                  [class.w-2-65]="editMode"
                  class="table">
                  <div class="table-row padding-top-11">
                    <div class="table-cell blue">{{ "Controls.Element.OnTime" | translate }}</div>
                    <div class="table-cell">
                      <div
                        *ngIf="item.value.onTime != null && !editMode"
                        class="d-flex flex-row align-center">
                        {{ item.value.onTime }} {{ "Sec" | translate | lowercase }}
                      </div>
                      <ls-input-integer
                        *ngIf="editMode"
                        [class.border-none__no-editing-mode]="!editMode"
                        [fieldWithKeyboard]="true"
                        [min]="0"
                        [noMaxWidth]="true"
                        [noPaddings]="true"
                        class="align-center"
                        errorField
                        formControlName="onTime">
                      </ls-input-integer>
                    </div>
                  </div>
                  <div class="table-row padding-top-11">
                    <div class="table-cell blue">{{ "Controls.Element.OffTime" | translate }}</div>
                    <div class="table-cell">
                      <div
                        *ngIf="item.value.onTime != null && !editMode"
                        class="d-flex flex-row align-center">
                        {{ item.value.offTime }} {{ "Sec" | translate | lowercase }}
                      </div>
                      <ls-input-integer
                        *ngIf="editMode"
                        [class.border-none__no-editing-mode]="!editMode"
                        [fieldWithKeyboard]="true"
                        [min]="0"
                        [noMaxWidth]="true"
                        [noPaddings]="true"
                        class="align-center"
                        errorField
                        formControlName="offTime">
                      </ls-input-integer>
                    </div>
                  </div>
                  <div class="table-row padding-top-11">
                    <div class="table-cell blue">{{ "Controls.Element.Fans" | translate }}</div>
                    <div
                      (click)="setActiveCellMobile($event)"
                      [class.active-cell]="isCellActive && editMode"
                      class="table-cell justify-start">
                      <ls-icon-input-button
                        (change)="onPercentageChange($event)"
                        (setActiveIcon)="onIconClick($event)"
                        [editMode]="editMode"
                        [extraPadding]="10"
                        [isMobileApp]="platformService.isMobileApp"
                        [items]="item.get('fans')?.controls"
                        [max]="BasicVentilationConstants.maxPercentage"
                        [min]="BasicVentilationConstants.minPercentage"
                        [path]="'ventilation'">
                      </ls-icon-input-button>
                    </div>
                  </div>
                  <div class="table-row padding-top-11">
                    <div class="table-cell blue">{{ "Controls.Element.Inlets" | translate }}</div>
                    <div class="table-cell">
                      <ls-icon-input-button
                        [editMode]="editMode"
                        [items]="item.get('inlets')?.controls"
                        [path]="'dashboard/inlet-bottom'">
                      </ls-icon-input-button>
                    </div>
                  </div>
                  <div class="table-row padding-top-11">
                    <div class="table-cell blue">{{ "Controls.Element.Capacity" | translate }}</div>
                    <div
                      [class.non-editable]="editMode"
                      class="table-cell">
                      <div class="d-flex flex-row align-center"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </div>
      </div>

      <ng-container *ngIf="editMode">
        <ls-icons-keyboard
          *ngIf="isCellActive && selectedIndex != null && !isIconInputActive; else numericKeyboard"
          (change)="setVentValues($event)"
          (click)="$event.stopPropagation()"
          [elementsAO]="ventilationAOElements$ | async"
          [elementsDO]="ventilationDOElements$ | async"
          [elements]="form?.get('items')?.['controls']?.[selectedIndex]?.get('fans')?.value"
          [isMobileApp]="platformService.isMobileApp"
          [path]="'dashboard/ventilation'"
          [selectedIndex]="selectedFanIndex"
          class="keyboard ml-20">
        </ls-icons-keyboard>
        <ng-template #numericKeyboard>
          <ls-keyboard
            *ngIf="!platformService.isMobileApp"
            [defaultMode]="KeyboardModeEnum.NumericOnly"
            class="keyboard ml-20" />
        </ng-template>
      </ng-container>
    </div>
  </div>
</section>

<ng-template #loading>
  <ls-loading [paddingTop]="25"></ls-loading>
</ng-template>
