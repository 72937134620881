<form
  [formGroup]="form"
  autocomplete="off"
  novalidate>
  <h2>{{ "Auth.LoginToYourAccount" | translate }}</h2>
  <div class="mt-18 position-relative">
    <lv-input
      (keydown.enter)="focusPassword()"
      (inputFocus)="email['focused'] = true"
      (inputBlur)="email['focused'] = false"
      #email
      [class.ng-invalid]="validateForm && form.get('login').invalid"
      [label]="'Auth.EmailAddress'"
      [qaTags]="'qa-txt-user'"
      [autocomplete]="'nope'"
      formControlName="login"
      class="mt-18 d-block"
      type="email">
      <ls-svg-icon
        [path]="IconsEnum.LETTER"
        [color]="
          validateForm && form.get('login').invalid
            ? ColorsEnum.MainRed
            : !!email['focused']
            ? ColorsEnum.MainBlue
            : ColorsEnum.MonoDark
        "
        leftIcon>
      </ls-svg-icon>
    </lv-input>
    <span
      *ngIf="validateForm && form.get('login').dirty && form.get('login').invalid"
      [qaTags]="'qa-lbl-email-err-message'"
      class="validation-error">
      {{ "Auth.PleaseEnterAnEmail" | translate }}
    </span>
  </div>

  <div class="mt-18 position-relative">
    <lv-input
      (keydown.enter)="login()"
      (inputFocus)="password['focused'] = true"
      (inputBlur)="password['focused'] = false"
      #password
      [label]="'Auth.Login.Password'"
      [qaTags]="'qa-txt-password'"
      [type]="passwordInputToggle ? 'text' : 'password'"
      [autocomplete]="'new-password'"
      formControlName="password"
      class="d-block">
      <ls-svg-icon
        [path]="IconsEnum.PASSWORD"
        [color]="
          form.get('password').dirty && form.get('password').invalid
            ? ColorsEnum.MainRed
            : !!password['focused']
            ? ColorsEnum.MainBlue
            : ColorsEnum.MonoDark
        "
        leftIcon>
      </ls-svg-icon>
      <ls-svg-icon
        (click)="passwordInputToggle = !passwordInputToggle"
        [path]="passwordInputToggle ? IconsEnum.EYE_CLOSED : IconsEnum.EYE"
        class="pointer"
        rightIcon>
      </ls-svg-icon>
    </lv-input>
    <span
      *ngIf="form.get('password').dirty && form.get('password').invalid"
      [qaTags]="'qa-txt-err-password'"
      class="validation-error">
      {{ "Auth.PleaseEnterYourPassword" | translate }}
    </span>
  </div>
  <div class="forgot-password">
    <span
      (click)="navigateTo('forgot-password')"
      [qaTags]="'qa-lnk-forgot'"
      class="link">
      {{ "Auth.Login.ForgotPasswordQuestion" | translate }}
    </span>
  </div>
  <ls-button
    (click)="login()"
    [class.disabled-with-events]="form.get('login').invalid || form.get('password').invalid"
    [qaTags]="'qa-btn-login'"
    class="login-button h-48"
    type="primary">
    {{ "Auth.Login.Login" | translate }}
  </ls-button>
  <div
    *ngIf="platformService.isNative && isCredentialsStoredWithBiometry && (userCancelledBiometry || wasLoggedIn)"
    (click)="getBiometricAuthentication()"
    class="d-flex justify-center face-recognition mt-28">
    <ls-svg-icon
      [path]="IconsEnum.FACE_RECOOGNITION"
      class="icon ml-10 mr-10 pointer qa-btn-face-recognition">
    </ls-svg-icon>
    <span>{{ "Auth.BiometricalLogin" | translate }}</span>
  </div>
  <div
    [class.biometrical-login]="
      platformService.isNative && isCredentialsStoredWithBiometry && (userCancelledBiometry || wasLoggedIn)
    "
    class="sign-up mt-86">
    <span class="sign-up_question">
      {{ "Auth.Login.DontHaveAnAccountQuestion" | translate }}
    </span>
    <div
      (click)="navigateTo('sign-up')"
      [qaTags]="'qa-lnk-sign-up'"
      class="create-account-button text-center pointer mt-8">
      {{ "Auth.CreateAccount" | translate }}
    </div>
  </div>
</form>
