import { TempMappingStagesEnum } from '@livestock/temp-mapping/models';
import { ITempMappingSensorElement } from './temp-mapping-sensor-element.interface';
import { IElement } from '@livestock/installation/interfaces';

export interface IActiveMappingElement {
  isAverage: boolean;
  sensors: ITempMappingSensorElement[] | IElement[];
  activeStage: TempMappingStagesEnum;
  temperatureMappingID: number;
  elementID?: number;
}
