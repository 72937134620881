<section class="page-content">
  <header class="d-flex flex-row justify-between">
    <ls-header-title
      (cancel)="goBack()"
      [title]="'Controller.NetworkSettings.SettingNetwork'"
      class="d-flex"></ls-header-title>
    <ls-svg-icon
      (click)="isEditMode = !isEditMode"
      [class.disabled]="isEditMode"
      [color]="platformService.isMobileApp && isEditMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'installation/edit-mobile' : 'installation/edit'"
      [qaTags]="'qa-btn-edit'"
      class="pointer">
    </ls-svg-icon>
  </header>

  <div class="container">
    <ls-network-settings-form
      (changed)="changedNetworkSettings($event)"
      [isDisabled]="!isEditMode"
      [networkSettings]="networkSettings" />
    <div
      *ngIf="isEditMode"
      class="d-flex justify-end justify-center-md mt-30">
      <ls-button
        (click)="cancel()"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-20 ml-20 w-130">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="update()"
        [class.disabled]="!isValid || !isDirtyForm"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'"
        class="w-130 mr-20 ml-20">
        {{ "Buttons.Update" | translate }}
      </ls-button>
    </div>
  </div>
</section>
