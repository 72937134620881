export enum IconsAuthEnum {
    EARTH = 'new-icons/auth/earth',
    EARTH_BLACK = 'new-icons/auth/earth-black',
    PASSWORD = 'new-icons/auth/password',
    LETTER = 'new-icons/auth/letter',
    EYE = 'new-icons/auth/eye',
    EYE_CLOSED = 'new-icons/auth/eye-closed',
    GENERATE = 'new-icons/auth/generate',
    USER = 'new-icons/auth/user',
    PHONE = 'new-icons/auth/phone',
    FACE_RECOOGNITION = 'new-icons/auth/face-recognition',
    CLOSE = 'new-icons/auth/close',
    COPY = 'new-icons/auth/copy',
}