import {
  InputIntegerComponent,
  ButtonComponent,
  InputDecimalComponent,
  ButtonWithIconComponent,
  TimePickerDialogControlComponent,
  ProgramDeviceButtonsComponent,
} from '@livestock/ui';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { Component, Input, Output, EventEmitter, ViewChild, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import SwiperCore, { Navigation, Pagination } from 'swiper';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { LanguageService, LocalStorageService, PlatformService, SwiperService } from '@livestock/shared/services';
import { QaTagsDirective, SwiperDirective } from '@livestock/shared/directives';
import { ErrorFieldDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { CoolingProgramConstants } from '@livestock/cooling-program/models';
import { GlobalConstants } from '@livestock/shared/constants';
import { Store } from '@ngrx/store';
import { selectHourFormatForTimePicker } from '@livestock/controllers';
import { ErrorFieldEnum, StorageItem } from '@livestock/shared/enums';

SwiperCore.use([Navigation, Pagination]);

@Component({
  selector: 'ls-program-table-mobile',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputIntegerComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    InputDecimalComponent,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    SwiperModule,
    MemoizeFuncPipe,
    ErrorFieldDirective,
    TimePickerDialogControlComponent,
    ProgramDeviceButtonsComponent,
    SwiperDirective,
  ],
  templateUrl: './program-table-mobile.component.html',
  styleUrls: ['./program-table-mobile.component.scss'],
})
export class ProgramTableMobileComponent implements OnDestroy {
  @ViewChild('swiper') swiper: SwiperComponent;
  @Input() editMode: boolean;
  @Input() humLevelToRestart: number;
  @Input() noFilledRows: boolean;
  @Input() currentTimeUUID: string;
  @Input() path: string;
  @Input() items: FormGroup[];
  @Input() isDisabled: boolean;
  @Input() isflockModeAdvance: boolean = false;
  @Output() onChange = new EventEmitter();

  // observables
  selectHourFormatForTimePicker$ = this.store.select(selectHourFormatForTimePicker);

  // constants
  CoolingProgramConstants = CoolingProgramConstants;
  GlobalConstants = GlobalConstants;
  ErrorFieldEnum = ErrorFieldEnum;
  StorageItem = StorageItem;

  constructor(
    public languageService: LanguageService,
    public swiperService: SwiperService,
    public platformService: PlatformService,
    public fb: FormBuilder,
    private store: Store,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ProgramMenuSlide)) || 0,
      centeredSlides: true,
    };
  }

  onIndexChange([{ realIndex }]): void {
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, realIndex);
    this.onChange.emit(realIndex);
  }

  prev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  next(): void {
    this.swiper.swiperRef.slideNext(100);
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
  }
}
