export class CoolingProgramConstants {
  static onTimeMin = 5;
  static onTimeMax = 999;
  static offTimeMin = 0;
  static offTimeMax = 999;
  static startTempMin = -10;
  static startTempMax = 20;
  static stopTempMin = -10;
  static stopTempMax = 20;
  static ventStartLevelMin = 0;
  static ventStartLevelMax = 100;
  static ventStartLevelAdvancedMin = 0;
  static ventStartLevelAdvancedMax = 40;
  static humStopLevelMin = 0;
  static humStopLevelMax = 100;
}
