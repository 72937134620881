import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { ICurrentUserView } from '../interfaces/current-user-view';

const baseUrl = environment.apiUrl;

@Injectable({
  providedIn: 'root',
})
export class CurrentUserService {
  constructor(private http: HttpClient) {
  }

  getCurrentUser(): Observable<ICurrentUserView> {
    return this.http.get<ICurrentUserView>(`${baseUrl}/user/current`);
  }
}
