import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable, map } from 'rxjs';
import {
  IControllerView,
  IGeneralSettingsView,
  IDateTimeSettingsView,
  INetworkSettingsView,
  ICreateControllerView,
  IHouseSizesView, ICreateAddControllerTicketView,
} from '@livestock/controllers';
import { IUpdateDateTimeSettingsView } from '../interfaces/update-date-time-settings-view.interface';
import { ChickenBrandWeight, ElementTypesEnum, LengthUnitEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { IGeneralSettingsViewNoUnits } from '../interfaces/general-settings-view-no-units.interface';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { ILanSettings, IReconnectController } from '@livestock/controllers/interfaces';
import { PlatformService } from '@livestock/shared/services';
import { ConvertHelper } from '@livestock/shared/utils';
import { IFlockHouseModeView, IGetFlockDefaultWeightView } from '@livestock/flock';
import { IFlockQuickStartView } from '../../../../flock/src/lib/interfaces';

const baseUrl = `${environment.apiUrl}/controller`;
const deviceUrl = (controllerID: number): string => `http://localhost:3000/api/controller/${controllerID}/settings`;
const deviceUrlFlock = (controllerID: number): string => `http://localhost:3000/api/controller/${controllerID}/flock`;
const deviceQuickStartUrl = (step: QuickStartStatusEnum | string): string => `http://localhost:3000/api/controller/quickstart/status/${step}`;
const defaultControllerID = GlobalConstants.HardCodedControllerIDForDevice;

@Injectable({
  providedIn: 'root',
})
export class ControllersService {

  constructor(
    private http: HttpClient,
    private platformService: PlatformService,
  ) {
  }

  getController(controllerID: number): Observable<IControllerView> {
    return this.http.get<IControllerView>(`${baseUrl}/${controllerID}`);
  }

  getGeneralSettings(controllerID: number): Observable<IGeneralSettingsView> {
    return this.http.get<IGeneralSettingsView>(`${baseUrl}/${controllerID}/settings/general`);
  }

  getDateTimeSettings(controllerID: number): Observable<IDateTimeSettingsView> {
    return this.http.get<IDateTimeSettingsView>(`${baseUrl}/${controllerID}/settings/date-time`);
  }

  getNetworkSettings(controllerID: number): Observable<INetworkSettingsView> {
    return this.http.get<INetworkSettingsView>(`${baseUrl}/${controllerID}/settings/network`);
  }

  getHouseSizesSettings(controllerID: number, lengthUnit: LengthUnitEnum): Observable<IHouseSizesView> {
    return this.http.get<IHouseSizesView>(`${baseUrl}/${controllerID}/settings/house-sizes`).pipe(map(
      settings => ({
        ...settings,
        houseHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseHeight, 3) : settings.houseHeight,
        houseLength: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseLength, 3) : settings.houseLength,
        houseRoofHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseRoofHeight, 3) : settings.houseRoofHeight,
        houseWidth: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.metersToFeet(settings.houseWidth, 3) : settings.houseWidth,
      }),
    ));
  }

  createController(controller: ICreateControllerView): Observable<void> {
    return this.http.post<void>(baseUrl, controller);
  }

  updateController(controllerID: number, requestView: IControllerView): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}`, requestView);
  }

  updateGeneralSettings(controllerID: number, requestView: IGeneralSettingsViewNoUnits): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/settings/general`, requestView);
  }

  updateDateTimeSettings(controllerID: number, requestView: IUpdateDateTimeSettingsView): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/settings/date-time`, requestView);
  }

  updateNetworkSettings(controllerID: number, requestView: INetworkSettingsView): Observable<ILanSettings> {
    return this.http.put<ILanSettings>(`${baseUrl}/${controllerID}/settings/network`, requestView);
  }

  getLanSettings(controllerID: number): Observable<ILanSettings> {
    return this.http.get<{ isActive: boolean; ip: string; mac: string; }>(`${baseUrl}/${controllerID}/settings/network/ethernet`).pipe(map(settings => {
      return {
        lan: Number(settings.isActive),
        lanIp: settings.ip,
        lanMac: settings.mac,
      };
    }));
  }

  updateLanSettings(controllerID: number, isActive: boolean): Observable<ILanSettings> {
    return this.http.put<{ isActive: boolean; ip: string; mac: string; }>(`${deviceUrl(controllerID)}/network/ethernet`, {
      isActive,
    }).pipe(map(settings => {
      return {
        lan: Number(settings.isActive),
        lanIp: settings.ip,
        lanMac: settings.mac,
      };
    }));
  }

  updateHouseSizesSettings(controllerID: number, requestView: IHouseSizesView, lengthUnit: LengthUnitEnum): Observable<void> {
    requestView = {
      ...requestView,
      houseHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseHeight, 3) : requestView.houseHeight,
      houseLength: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseLength, 3) : requestView.houseLength,
      houseRoofHeight: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseRoofHeight, 3) : requestView.houseRoofHeight,
      houseWidth: lengthUnit === LengthUnitEnum.Foot ? ConvertHelper.feetToMeters(requestView.houseWidth, 3) : requestView.houseWidth,
    };
    return this.http.put<void>(`${baseUrl}/${controllerID}/settings/house-sizes`, requestView);
  }

  deleteController(controllerID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}`);
  }

  getControllerElements(controllerID: number, elementType: ElementTypesEnum): Observable<{ items: any[] }> {
    return this.http.get<any>(`${baseUrl}/${controllerID}/element-type/${elementType}/elements`);
  }

  //DEVICE
  createGeneralSettingsViaDevice(controllerID: number, requestView: IGeneralSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID || defaultControllerID)}/general`, requestView);
  }

  createHouseSizesViaDevice(controllerID: number, requestView: IHouseSizesView, lengthUnit: LengthUnitEnum): Observable<void> {
    if (lengthUnit === LengthUnitEnum.Foot) {
      requestView = {
        ...requestView,
        houseHeight: ConvertHelper.feetToMeters(requestView.houseHeight, 1),
        houseLength: ConvertHelper.feetToMeters(requestView.houseLength, 1),
        houseRoofHeight: ConvertHelper.feetToMeters(requestView.houseRoofHeight, 1),
        houseWidth: ConvertHelper.feetToMeters(requestView.houseWidth, 1),
      };
    }
    return this.http.put<void>(`${deviceUrl(controllerID || defaultControllerID)}/house-sizes`, requestView);
  }

  createDateTimeSettingsViaDevice(controllerID: number, requestView: IDateTimeSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID)}/date-time`, requestView);
  }

  createNetworkSettingsViaDevice(controllerID: number, requestView: INetworkSettingsView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID)}/network`, requestView);
  }

  createFlockSettingsViaDevice(controllerID: number, requestView: IFlockQuickStartView): Observable<void> {
    return this.http.put<void>(`${deviceUrl(controllerID)}/flock`, requestView);
  }

  createHouseModesViaDevice(controllerID: number, requestView: IFlockHouseModeView, temperatureUnit: TemperatureUnitEnum): Observable<void> {
    requestView = {
      controllerID,
      ...requestView,
      timeChangeNextMode: Number((requestView.timeChangeNextMode as unknown as string).replace(':', '')),
    };

    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
      requestView = {
        ...requestView,
        targetTemperatureCatching: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureCatching, 1),
        targetTemperatureCleaning: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureCleaning, 1),
        targetTemperatureEmpty: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperatureEmpty, 1),
        targetTemperaturePreheat: ConvertHelper.fahrenheitToCelsius(requestView.targetTemperaturePreheat, 1),
      };
    }

    const {
      ventilationCatching,
      ventilationCleaning,
      ventilationEmpty,
      ventilationPreheat,
      ...view
    } = requestView;

    return this.http.put<void>(`${deviceUrlFlock(controllerID)}/house-mode`, {
      ...view,
      minimumVentilationEmpty: Number(ventilationEmpty.min),
      maximumVentilationEmpty: Number(ventilationEmpty.max),
      minimumVentilationPreheat: Number(ventilationPreheat.min),
      maximumVentilationPreheat: Number(ventilationPreheat.max),
      minimumVentilationCleaning: Number(ventilationCleaning.min),
      maximumVentilationCleaning: Number(ventilationCleaning.max),
      minimumVentilationCatching: Number(ventilationCatching.min),
      maximumVentilationCatching: Number(ventilationCatching.max),
    });
  }

  getControllerSerialNumber(controllerID: number): Observable<{ serialNumber: string }> {
    return this.http.get<{ serialNumber: string }>(`${deviceUrl(controllerID)}/serial-number`);
  }

  getControllerTicketID(controllerID: number): Observable<ICreateAddControllerTicketView> {
    return this.http.get<ICreateAddControllerTicketView>(`${deviceUrl(controllerID)}/ticket-id`);
  }

  /*quick start issues*/
  getQuickstartStatus(): Observable<string> {
    return this.http.get<string>(deviceQuickStartUrl(''));
  }

  setQuickstartStatusStep(step: QuickStartStatusEnum): Observable<void> {
    return this.http.put<void>(deviceQuickStartUrl(step), null);
  }

  // disconnect + reconnect
  disconnectController(controllerID: number): Observable<void> {
    return this.http.post<void>(`${baseUrl}/${controllerID}/disconnect`, null);
  }

  reconnectController(controllerID: number, view: IReconnectController): Observable<ICreateAddControllerTicketView> {
    if (this.platformService.isDeviceApp) {
      return this.http.post<ICreateAddControllerTicketView>(`${baseUrl}/${controllerID}/reconnect-controller`, view);
    }

    return this.http.post<ICreateAddControllerTicketView>(`${environment.apiUrl}/ticket/reconnect-controller`, view);
  }

  // get weight reference table for flock
  getFlockWeightReferenceTable(brand: ChickenBrandWeight, controllerID: number): Observable<IGetFlockDefaultWeightView> {
    return this.http.get<IGetFlockDefaultWeightView>(`${deviceUrl(controllerID)}/weight-reference-table/${brand}`);
  }
}
