export enum LightingInputType {
    Volts,
    Ampers,
}

export namespace LightingInputTypeEnum {
    export function toTranslateKey(value: LightingInputType | string | number): string {
      switch (value) {
        case LightingInputType.Volts:
          return 'Installation.Card.Lighting.Volts';
        case LightingInputType.Ampers:
          return 'Installation.Card.Lighting.Ampers';
        default:
          return value.toString();
      }
    }
  }