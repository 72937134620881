import { IVentBrandItem } from '../interfaces/vent-brand-item.interface';
import { IVentModelDOItem } from '../interfaces/vent-model-do-item.interface';
import { IVentModelAOItem } from '../interfaces/vent-model-ao-item.interface';

export interface VentilationBrandsState {
  isLoading: boolean;
  ventilationBrands: IVentBrandItem[];
  ventilationDOModels: IVentModelDOItem[];
  ventilationAOModels: IVentModelAOItem[];
}
