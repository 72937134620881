<div class="ls-radio-container {{ direction }}">
  <mat-radio-group
    (change)="writeValue($event.value)"
    [qaTags]="'qa-radio-group'"
    [value]="value"
    aria-labelledby="ls-radio-group-label">
    <ng-container *ngFor="let el of list">
      <mat-radio-button
        [qaTags]="'qa-radio-group-button-' + el"
        [value]="el.value"
        [ngClass]="{ 'mat-mdc-radio-checked': value === el.value, disable: el?.disabled }"
        class="ls-radio-group__button overrided-mat-radio-button">
        <div
          class="d-flex flex-row gap-8 radio-value {{ size }}"
          [ngClass]="{ 'pv-10': direction === 'vertical' }">
          <ls-svg-icon [path]="value === el.value ? IconsEnum.CHECKBOX_CHECKED : IconsEnum.CHECKBOX" />
          <span class="el-title {{ el?.cssClass }}">
            {{ el.title | translate }}
            <span
              *ngIf="el?.subtitle"
              class="el-subtitle fz-10"
              >{{ el.subtitle | translate }}</span
            >
          </span>
        </div>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>
