import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetLightingMapping } from '../interfaces/get-lighting-mapping.interface';
import { IElement } from '@livestock/installation/interfaces';

export const getLightingMappings = createAction(
  '[LIGHTING MAPPING] Get Lighting Mappings [...]',
);

export const getLightingMappingsSuccess = createAction(
  '[LIGHTING MAPPING] Get Lighting Mappings [SUCCESS]',
  props<{ view: IGetLightingMapping }>(),
);

export const getLightingMappingsError = createAction(
  '[LIGHTING MAPPING] Get Lighting Mappings [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const updateLightingMappings = createAction(
  '[LIGHTING MAPPING] Update Lighting Mappings [...]',
);

export const updateLightingMappingsSuccess = createAction(
  '[LIGHTING MAPPING] Update Lighting Mappings [SUCCESS]',
);

export const updateLightingMappingsError = createAction(
  '[LIGHTING MAPPING] Update Lighting Mappings [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const setActiveMapping = createAction(
  '[LIGHTING MAPPING] Set Active Mapping',
  props<{ lightElementID: number }>(),
);

export const changeActiveElementIsAverage = createAction(
  '[LIGHTING MAPPING] Change Active Element Is Average',
  props<{ isAverage: boolean, lightElementID: number }>(),
);

export const addSensorsToMapping = createAction(
  '[LIGHTING MAPPING] Add Sensors To Mapping',
  props<{ sensors: IElement[] }>(),
);

export const resetToOriginalMappings = createAction(
  '[LIGHTING MAPPING] Reset To Original Mappings',
);
