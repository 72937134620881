import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { KeyboardModeEnum, ToggleType } from '@livestock/ui';

@Component({
  selector: 'ls-lighting-do-testing-form',
  templateUrl: './lighting-do-testing-form.component.html',
  styleUrls: ['./lighting-do-testing-form.component.scss'],
})
export class LightingDOTestingFormComponent implements OnInit {

  form: FormGroup;

  KeyboardModeEnum = KeyboardModeEnum;
  ToggleType = ToggleType;

  ngOnInit(): void {
    this.form = new FormGroup({
      enabled: new FormControl<boolean>(true, [
        Validators.required,
      ]),
    });
  }
}
