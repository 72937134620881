import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import { Subscription } from 'rxjs';
import { SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ActivatedRouteService } from '@livestock/shared/services';
import { FlashMessageTypeEnum } from '../../enums/flash-message-type.enum';
import { IFlashMessage } from '../../interfaces/flash-message.interface';
import { selectFlashMessage } from '../../+store/flash-message.selectors';
import { removeFlashMessage } from '../../+store/flash-message.actions';
import { Router } from '@angular/router';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'ls-flash-message',
  templateUrl: './flash-message.component.html',
  styleUrls: ['./flash-message.component.scss'],
  standalone: true,
  imports: [CommonModule, SvgIconComponent, TranslateModule, MemoizeFuncPipe],
})
export class FlashMessageComponent implements OnInit, OnDestroy {
  sub$ = new Subscription();
  flashMessage: IFlashMessage;
  FlashMessageTypeEnum = FlashMessageTypeEnum;
  timeOut;

  constructor(
    public activatedRouteService: ActivatedRouteService,
    public router: Router,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectFlashMessage).subscribe((flashMessage) => {
        this.flashMessage = null;

        setTimeout(() => {
          this.flashMessage = flashMessage;
        });

        if (this.timeOut) {
          clearTimeout(this.timeOut);
        }

        this.timeOut = setTimeout(() => {
          this.store.dispatch(removeFlashMessage());
        }, 7000);
      }),
    );
  }

  hideFlash(): void {
    this.flashMessage = null;
    this.store.dispatch(removeFlashMessage());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
