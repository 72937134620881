<div
  [class.rtl]="languageService.isRtl"
  class="swiper-navigation">
  <div
    (click)="prev()"
    [class.disabled]="activeIndex === 0 || !items?.length"
    class="swiper-navigation-prev"></div>
  <div
    (click)="next()"
    [class.disabled]="activeIndex === items?.length - 1 || !items?.length"
    class="swiper-navigation-next"></div>
</div>
<div
  [class.swiper-container__edit-mode]="editMode"
  class="swiper-container">
  <swiper
    *ngIf="swiperService.swiperIsActive"
    (activeIndexChange)="onIndexChange($event)"
    (paginationHide)="(editMode)"
    #swiper
    [class.swiper-wrapper__hide-pagination]="editMode"
    [config]="swiperService.config"
    [dir]="languageService.direction"
    [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
    [touchEventsTarget]="'wrapper'"
    class="swiper-wrapper pt-10"
    swiperDirective>
    <ng-template
      *ngFor="let currentControl of items"
      swiperSlide>
      <div
        [class.w-1-35]="editMode"
        [class.w-1-50]="!editMode"
        [class.w-2-50]="!editMode"
        [class.w-2-65]="editMode"
        [formGroup]="currentControl"
        class="table">
        <div class="table-row padding-top-11">
          <div class="table-cell blue">{{ "Controls.Element.Program" | translate }}</div>
          <div class="table-cell">
            <div
              *ngIf="!editMode"
              class="d-flex flex-row align-center">
              {{ currentControl.value.name }}
            </div>
            <ls-input-text
              *ngIf="editMode"
              [centered]="true"
              [class.hidden]="!editMode && noFilledRows"
              [fieldWithKeyboard]="true"
              [maxLength]="ElementConstants.MaxNameLength"
              [qaTags]="'qa-txt-name'"
              errorField
              formControlName="name">
            </ls-input-text>
          </div>
        </div>
        <div class="table-row padding-top-11">
          <div class="table-cell blue">{{ "Controls.Element.StartTime" | translate }}</div>
          <div
            [class.pointer-events-none]="!editMode"
            class="table-cell">
            <ls-time-picker-dialog-control
              [class.hidden]="!editMode && noFilledRows"
              [editMode]="editMode"
              [enableMeridian]="selectHourFormatForTimePicker$ | async"
              [isDisabled]="isDisabled"
              [isFieldInvalid]="currentControl.hasError(ErrorFieldEnum.OuterOverlap)"
              class="w-155 h-36"
              formControlName="startTime">
            </ls-time-picker-dialog-control>
          </div>
        </div>
        <div
          *ngFor="let lightingsElement of getElements(currentControl); let i = index"
          [formGroup]="lightingsElement"
          class="table-row padding-top-11">
          <div class="table-cell blue">{{ availableElems[i].name }}</div>
          <div
            [class.pointer-events-none]="!editMode"
            class="table-cell justify-start">
            <ng-container *ngIf="lightingsElement.value.elementType === ElementTypesEnum.LightAO">
              <div
                *ngIf="!editMode"
                [class.hidden]="!editMode && noFilledRows"
                [class.lighting-button--max]="lightingsElement.value.intensity === 100"
                [class.lighting-button--mid]="
                  lightingsElement.value.intensity < 100 && lightingsElement.value.intensity > 0
                "
                [class.lighting-button--off]="lightingsElement.value.intensity === 0"
                class="lighting-button lighting-button--ao">
                <ls-svg-icon
                  [color]="lightingsElement.value.intensity > 0 ? ColorsEnum.BlueDefault : ColorsEnum.Color90"
                  path="dashboard/light-filled"></ls-svg-icon>
                {{ lightingsElement.value.intensity }}%
              </div>
              <div
                *ngIf="editMode"
                [class.hidden]="!editMode && noFilledRows"
                [formGroup]="lightingsElement"
                class="lighting-button lighting-button--ao">
                <ls-svg-icon
                  [color]="ColorsEnum.Yellow"
                  [hasMargin]="true"
                  path="dashboard/light-filled"></ls-svg-icon>
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [max]="LightingProgramConstants.onTimeMax"
                  [min]="LightingProgramConstants.onTimeMin"
                  [noMaxWidth]="true"
                  class="align-center"
                  class="align-center"
                  errorField
                  formControlName="intensity">
                  <ng-container suffix>
                    <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                  </ng-container>
                </ls-input-integer>
              </div>
            </ng-container>
            <ng-container *ngIf="lightingsElement.value.elementType === ElementTypesEnum.LightDO">
              <button
                (click)="editMode && lightingsElement.controls.enabled.setValue(!lightingsElement.value.enabled)"
                [class.hidden]="!editMode && noFilledRows"
                [class.lighting-button--off]="!lightingsElement.value.enabled"
                [class.lighting-button--on]="lightingsElement.value.enabled"
                class="lighting-button"
                type="button">
                <ls-svg-icon
                  [color]="lightingsElement.value.enabled ? ColorsEnum.BlueDefault : ColorsEnum.Color90"
                  [hasMargin]="true"
                  path="dashboard/light-filled"></ls-svg-icon>
              </button>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-template>
  </swiper>
</div>
