<div class="active-element__wrapper h-100-percent p-20">
  <ng-container *ngIf="!(isElementLoading$ | async); else loading">
    <ng-container *ngIf="currentElementType$ | async as currentElementType">
      <div class="mb-35 d-flex align-center justify-between">
        <div class="active-element__title d-flex align-center">
          <ls-svg-icon
            (click)="back()"
            [color]="ColorsEnum.Black"
            [hasMargin]="true"
            [path]="'chevron-left'"
            class="back-arrow pointer mr-15"></ls-svg-icon>

          <h4
            *ngIf="!(installationHelpersService.isTableView$ | async); else tableTitle"
            class="color-primary fw-500 fz-18">
            <ng-container *ngIf="cardNumber">
              {{ cardType | enum : "card-type" | translate }}
              {{ cardNumber }}
            </ng-container>

            <ng-container *ngIf="connectionNumber">
              {{ "Installation.Main.Port" | translate }}
              {{ connectionNumber }}
            </ng-container>
          </h4>
        </div>

        <div
          *ngIf="!(installationHelpersService.isTableView$ | async)"
          class="d-flex align-center">
          <ls-svg-icon
            *ngIf="currentElementType$ | async | memoizeFunc : helper.isElementHasTable"
            (click)="openTableView()"
            [hasMargin]="true"
            class="pointer"
            path="mobile/table">
          </ls-svg-icon>

          <ls-svg-icon
            *ngIf="currentElementType$ | async | memoizeFunc : helper.isElementHasSettings"
            (click)="openSettings(currentElementType)"
            [qaTags]="'qa-btn-settings'"
            class="pointer"
            path="installation/settings-mobile"></ls-svg-icon>
        </div>
      </div>

      <ls-element-wrapper [currentElementType]="currentElementType"></ls-element-wrapper>
    </ng-container>
  </ng-container>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>

<ng-template #tableTitle>
  <h4 class="color-primary fw-500 fz-18">{{ "Installation.Main.Table" | translate }}</h4>
</ng-template>
