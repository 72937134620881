import { Component } from '@angular/core';

@Component({
  selector: 'ls-loading-lines',
  templateUrl: './loading-lines.component.html',
  styleUrls: ['./loading-lines.component.scss'],
  standalone: true,
})
export class LoadingLinesComponent {
}
