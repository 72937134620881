import { Action, createReducer, on } from '@ngrx/store';
import * as keyboardActions from './keyboard.actions';
import { KeyboardState } from './keyboard.state';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/am-pm.enum';

export const KEYBOARD_KEY = 'keyboard';

export const initialState: KeyboardState = {
  symbol: null,
  elementUuid: null,
  ranges: null,
  mode: null,
  isRefreshFieldsForKeyboard: false,
  currentIconPath: null,
  hasAMPM: false,
  activeAMPM: AmPmEnum.PM,
};
// setElementUuid
const reducer = createReducer(
  initialState,
  on(keyboardActions.setElementUuid, (state, { elementUuid }) => {
      return {
        ...state,
        elementUuid,
      };
    },
  ),
  on(keyboardActions.switchElementUuid, (state, { elementUuid }) => {
      return {
        ...state,
        elementUuid,
      };
    },
  ),
  on(keyboardActions.setKeyboardValue, (state, { symbol }) => {
      return {
        ...state,
        symbol,
      };
    },
  ),
  on(keyboardActions.clearKeyboardValue, (state) => {
      return {
        ...state,
        symbol: null,
      };
    },
  ),
  on(keyboardActions.clearFormControlInputInfo, ({ currentIconPath }) => {
    return {
      ...initialState,
      currentIconPath,
    };
  }),
  on(keyboardActions.removeSymbol, (state) => {
    return {
      ...state,
      symbol: '',
    };
  }),
  on(keyboardActions.setKeyboardRanges, (state, { ranges }) => {
    return {
      ...state,
      ranges,
    };
  }),
  on(keyboardActions.setKeyboardMode, (state, { mode }) => {
    return {
      ...state,
      mode,
    };
  }),
  on(keyboardActions.keyboardUpdateFields, (state) => {
    return {
      ...state,
      isRefreshFieldsForKeyboard: true,
    };
  }),
  on(keyboardActions.keyboardUpdateFieldsSuccess, (state) => {
    return {
      ...state,
      isRefreshFieldsForKeyboard: false,
    };
  }),
  on(keyboardActions.setKeyboardIconPath, (state, { path }) => {
    return {
      ...state,
      currentIconPath: path,
    };
  }),
  on(keyboardActions.setKeyboardAMPMButtons, (state, { hasAMPM }) => {
    return {
      ...state,
      hasAMPM,
    };
  }),
  on(keyboardActions.setKeyboardActiveAMPMButton, (state, { activeAMPM }) => {
    return {
      ...state,
      activeAMPM,
    };
  }),
);

export function keyboardReducer(state: KeyboardState, action: Action): KeyboardState {
  return reducer(state, action);
}
