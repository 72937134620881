import { Action, createReducer, on } from '@ngrx/store';
import * as currentUserActions from './current-user.actions';
import { ICurrentUserView } from '../interfaces/current-user-view';

export const CURRENT_USER_FEATURE_KEY = 'currentUser';

export interface CurrentUserState {
  currentUser: ICurrentUserView | null;
}

export const initialState: CurrentUserState = {
  currentUser: null,
};

const reducer = createReducer(
  initialState,
  on(currentUserActions.getCurrentUserSuccess, (state, { currentUser }) => {
    return {
      ...state,
      currentUser,
    };
  }),
);

export function currentUserReducer(state: CurrentUserState | undefined, action: Action): any {
  return reducer(state, action);
}
