import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonComponent } from '@livestock/ui';
import { MatDialogRef } from '@angular/material/dialog';
import { IUnitsDetails } from '../../interfaces/units-details.interface';
import { GlobalConstants } from '@livestock/shared/constants';
import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum,
  IlluminanceUnitEnum,
  LengthUnitEnum,
  TemperatureUnitEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { EnumPipe, EnumToArrayPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { IEditUnitModeObj } from '@livestock/shared/interfaces';

@Component({
  selector: 'ls-custom-units-dialog',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    TranslateModule,
    ButtonComponent,
    EnumToArrayPipe,
    EnumPipe,
    MemoizeFuncPipe,
    QaTagsDirective,
  ],
  templateUrl: './custom-units-dialog.component.html',
  styleUrls: ['./custom-units-dialog.component.scss'],
})
export class UnitsDetailsDialogComponent {
  form = new FormGroup(
    {
      temperature: new FormControl(GlobalConstants.DefaultUnitsDetails.temperature, [Validators.required]),
      airFlow: new FormControl(GlobalConstants.DefaultUnitsDetails.airFlow, [Validators.required]),
      airSpeed: new FormControl(GlobalConstants.DefaultUnitsDetails.airSpeed, [Validators.required]),
      airPressure: new FormControl(GlobalConstants.DefaultUnitsDetails.airPressure, [Validators.required]),
      waterMainPressure: new FormControl(GlobalConstants.DefaultUnitsDetails.waterMainPressure, [Validators.required]),
      waterLinePressure: new FormControl(GlobalConstants.DefaultUnitsDetails.waterLinePressure, [Validators.required]),
      weight: new FormControl(GlobalConstants.DefaultUnitsDetails.weight, [Validators.required]),
      volume: new FormControl(GlobalConstants.DefaultUnitsDetails.volume, [Validators.required]),
      length: new FormControl(GlobalConstants.DefaultUnitsDetails.length, [Validators.required]),
      illuminance: new FormControl(GlobalConstants.DefaultUnitsDetails.illuminance, [Validators.required]),
    },
  );
  TemperatureUnitEnum = TemperatureUnitEnum;
  AirFlowUnitEnum = AirFlowUnitEnum;
  AirPressureUnitEnum = AirPressureUnitEnum;
  AirSpeedUnitEnum = AirSpeedUnitEnum;
  IlluminanceUnitEnum = IlluminanceUnitEnum;
  LengthUnitEnum = LengthUnitEnum;
  VolumeUnitEnum = VolumeUnitEnum;
  WaterLinePressureUnitEnum = WaterLinePressureUnitEnum;
  WaterMainPressureUnitEnum = WaterMainPressureUnitEnum;
  WeightUnitEnum = WeightUnitEnum;
  // For Mobile Edit Mode;
  isMobileEditUnitMode = false;
  editUnitModeObj: IEditUnitModeObj;

  constructor(
    public dialogRef: MatDialogRef<UnitsDetailsDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
  ) {
  }

  setup(unitsDetails: IUnitsDetails): void {
    this.form.patchValue(unitsDetails);
  }

  close(): void {
    this.dialogRef.close();
  }

  resetToDefault(): void {
    this.form.patchValue(GlobalConstants.DefaultUnitsDetails);
  }

  isMetricUnit([enumName, value]: [any, any]): boolean {
    return enumName.isMetricUnit(value);
  }

  openEditUnitTemplate(enumName: any): void {
    this.editUnitModeObj = enumName.toEditUnitModeObj();
    this.isMobileEditUnitMode = true;
  }

  editUnitModeChangeValue(): void {
    this.isMobileEditUnitMode = false;
  }

  apply(): void {
    this.dialogRef.close(this.form.value);
  }
}
