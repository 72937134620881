import { animate, state, style, transition, trigger } from '@angular/animations';

export const fadeOut = trigger('fadeOut', [
  state('*', style({ opacity: 1 })),
  state('void', style({ opacity: 0 })),
  transition(':leave', [
    style({ opacity: 1 }),
    animate('0.2s ease-in-out', style({ opacity: 0 })),
  ]),
]);
