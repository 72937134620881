import { Component, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  KeyboardComponent,
  ButtonComponent,
  SvgIconComponent,
} from '@livestock/ui';
import {
  HouseModeFormComponent,
  IFlockHouseModeView,
  clearFlockHouseMode,
  getFlockHouseMode,
  selectFlockHouseMode,
  selectIsLoading,
  setFlockHouseMode,
  updateFlockHouseMode,
  VentilationWorkingModeEnum,
} from '@livestock/flock';
import {
  Subscription,
  Observable,
  filter,
} from 'rxjs';
import { KeyboardModeEnum, PageWrapperComponent } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { ColorsEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { selectControllerVentilationMode, selectTemperatureUnit } from '@livestock/controllers';

@Component({
  selector: 'ls-flock-house-mode',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    HouseModeFormComponent,
    PageWrapperComponent,
  ],
  templateUrl: './house-mode.component.html',
  styleUrls: [
    './house-mode.component.scss',
    '../flock-settings/flock-settings.component.scss',
  ],
})
export class FlockHouseModeComponent implements OnDestroy, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  houseMode: IFlockHouseModeView;
  originalHouseMode: IFlockHouseModeView;
  isValid = true;
  isDirty = false;
  editMode: boolean;
  ColorsEnum = ColorsEnum;
  KeyboardModeEnum = KeyboardModeEnum;
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectTemperatureUnit);
  ventilationMode$: Observable<VentilationWorkingModeEnum> = this.store.select(selectControllerVentilationMode);

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private dialogsService: DialogsService,
  ) {
    this.sub$.add(
      this.store.select(selectFlockHouseMode)
        .pipe(
          wasChanged(),
          filter(houseMode => houseMode != null && !!houseMode.controllerID),
        ).subscribe((houseMode) => {
        if (!this.originalHouseMode) {
          this.originalHouseMode = houseMode;
        }
        this.houseMode = houseMode;
        this.isDirty = this.areAnyChanges();
      }),
    );

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        const controllerID = +params['controllerID'];
        this.store.dispatch(getFlockHouseMode({ controllerID }));
      }),
    );
  }

  changedHouseMode(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockHouseMode({ houseMode: event.formValues }));
    }
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) this.editMode = false;
  }

  async goBack(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  update(): void {
    if (!this.isValid) {
      return;
    }

    this.originalHouseMode = this.houseMode;
    this.store.dispatch(updateFlockHouseMode());
    this.isDirty = false;
  }

  toggleEditMode(): void {
    if (this.editMode) {
      this.cancel();
      return;
    }

    this.editMode = !this.editMode;
  }

  areAnyChanges(): boolean {
    return JSON.stringify(this.originalHouseMode) !== JSON.stringify(this.houseMode);
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.store.dispatch(setFlockHouseMode({ houseMode: this.originalHouseMode }));
    return true;
  }

  canDeactivate(): boolean {
    return !this.areAnyChanges();
  }

  closeComponent(): void {
    this.store.dispatch(clearFlockHouseMode());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
