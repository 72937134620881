<div
  [class.rtl]="languageService.isRtl"
  [formGroup]="form"
  class="house w-mc">
  <div class="house-number mb-0 mb-15-md">
    <lv-input-integer-with-label
      [min]="HouseNumberMin"
      [max]="HouseNumberMax"
      [fontWeight]="400"
      [noWrapperPadding]="true"
      [label]="'Controller.QuickStart.HouseNumber'"
      formControlName="houseNumber"></lv-input-integer-with-label>

    <p
      *ngIf="!platformService.isMobileApp"
      class="fz-12">
      {{ "Inputs.AddNumberBetween" | translate }}
      <span class="fw-600">{{ HouseNumberMin }}-{{ HouseNumberMax }}</span>
    </p>
  </div>

  <ng-container *ngIf="!platformService.isMobileApp; else mobileTemplate">
    <div class="house-icons position-relative">
      <ls-svg-icon [path]="IconsEnum.HOUSE_DEVICE"></ls-svg-icon>

      <!--height-roof-->
      <div class="arrows-height-roof position-absolute">
        <ls-svg-icon
          [path]="IconsEnum.ARROW_TOP"
          [color]="ColorsEnum.Black"
          class="arrows-height-roof__top"></ls-svg-icon>

        <div class="position-absolute height-roof-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [inputWidthPx]="inputWidth"
              [qaTags]="'qa-txt-house-height'"
              [class.hide-error]="!form.controls.houseRoofHeight.errors?.['min'] && !form.controls.houseRoofHeight.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseRoofHeight">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                        maxValue: maxWidthHeightValue,
                        hasError: form.controls.houseRoofHeight.errors?.['min'] || form.controls.houseRoofHeight.errors?.['max']
                     }">
                  </ng-container>

                  <ls-svg-icon
                    [color]="ColorsEnum.Black"
                    [path]="IconsEnum.ARROW_BOTTOM_SMALL"
                    class="arrows-height-roof__bottom arrows-height-roof__bottom-validation"></ls-svg-icon>
                </div>

                <div class="hide-on-focus">
                  <ls-svg-icon
                    [path]="IconsEnum.ARROW_BOTTOM"
                    class="arrows-height-roof__bottom"></ls-svg-icon>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseRoofHeight.valid }"></ng-container>
          </div>
        </div>
      </div>

      <!--height-->
      <div class="arrows-height position-absolute">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_TOP_SMALL"
          class="arrows-height__top"></ls-svg-icon>

        <div class="position-absolute height-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [inputWidthPx]="inputWidth"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [qaTags]="'qa-txt-house-height'"
              [class.hide-error]="!form.controls.houseHeight.errors?.['min'] && !form.controls.houseHeight.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseHeight">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                    maxValue: maxWidthHeightValue,
                    hasError: form.controls.houseHeight.errors?.['min'] || form.controls.houseHeight.errors?.['max']
                     }">
                  </ng-container>

                  <ls-svg-icon
                    [color]="ColorsEnum.Black"
                    [path]="IconsEnum.ARROW_BOTTOM_SMALL"
                    class="arrows-height__bottom arrows-height__bottom-validation"></ls-svg-icon>
                </div>

                <div class="hide-on-focus">
                  <ls-svg-icon
                    [path]="IconsEnum.ARROW_BOTTOM"
                    class="arrows-height__bottom"></ls-svg-icon>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseHeight.valid }"></ng-container>
          </div>
        </div>
      </div>

      <!--width-->
      <div class="d-flex align-center mt-15 position-relative">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_LEFT"></ls-svg-icon>

        <div class="width-input d-flex flex-column mh-2">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [inputWidthPx]="inputWidth"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [fieldWithKeyboard]="true"
              [max]="maxWidthHeightValue"
              [min]="minValue"
              [qaTags]="'qa-txt-house-height'"
              [class.hide-error]="!form.controls.houseWidth.errors?.['min'] && !form.controls.houseWidth.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseWidth">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                    maxValue: maxWidthHeightValue,
                    hasError: form.controls.houseWidth.errors?.['min'] || form.controls.houseWidth.errors?.['max']
                     }">
                  </ng-container>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container *ngTemplateOutlet="unit; context: { isValid: form.controls.houseWidth.valid }"></ng-container>
          </div>
        </div>

        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_RIGHT"></ls-svg-icon>
      </div>

      <!--length-->
      <div class="arrows-length position-absolute">
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_RIGHT_TOP"
          class="arrows-length__top"></ls-svg-icon>
        <ls-svg-icon
          [color]="ColorsEnum.Black"
          [path]="IconsEnum.ARROW_LEFT_BOTTOM"
          class="arrows-length__bottom"></ls-svg-icon>

        <div class="position-absolute length-input d-flex flex-column">
          <div class="d-flex align-center justify-start">
            <ls-input-decimal
              [inputWidthPx]="inputWidthForHouseLength"
              [accuracy]="accuracy"
              [blueBorder]="true"
              [max]="maxLengthValue"
              [min]="minValue"
              [qaTags]="'qa-txt-house-height'"
              [class.hide-error]="!form.controls.houseLength.errors?.['min'] && !form.controls.houseLength.errors?.['max']"
              class="mr-3 mr-1"
              formControlName="houseLength">
              <ng-container description>
                <div class="fz-12 fw-600 w-mc show-on-focus">
                  <ng-container
                    [ngTemplateOutlet]="ranges"
                    [ngTemplateOutletContext]="{
                     maxValue: maxLengthValue,
                     hasError: form.controls.houseLength.errors?.['min'] || form.controls.houseLength.errors?.['max']
                     }">
                  </ng-container>
                </div>
              </ng-container>
            </ls-input-decimal>
            <ng-container
              *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
</div>

<div
  [class.bottom-error__active]="!platformService.isMobileApp && (isKeyboardActive$ | async)"
  class="bottom-error d-flex flex-column align-center fw-500 color-red fz-12-md">
  <span *ngIf="form.controls.houseLength.errors?.['gt'] || form.controls.houseWidth.errors?.['lt']">
    {{ "Controller.QuickStart.TheLengthCanNotBeLowerThanTheWidth" | translate }}
  </span>

  <span *ngIf="form.controls.houseRoofHeight.errors?.['gt'] || form.controls.houseHeight.errors?.['lt']">
    {{ "Controller.QuickStart.TheRoofHeightCanNotBeLowerThanTheWallHeight" | translate }}
  </span>
</div>

<ng-template #mobileTemplate>
  <div
    [formGroup]="form"
    class="form">
    <div class="d-flex align-center justify-between mb-15">
      <lv-input-decimal-with-label
        [max]="maxWidthHeightValue"
        [min]="minValue"
        [label]="'Controller.QuickStart.WallHeightWH'"
        class="w-input-100"
        formControlName="houseHeight">
        <ng-container
          *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"
          suffix></ng-container>
      </lv-input-decimal-with-label>

      <lv-input-decimal-with-label
        [max]="maxWidthHeightValue"
        [min]="minValue"
        [label]="'Controller.QuickStart.RoofHeightRH'"
        class="w-input-100"
        formControlName="houseRoofHeight">
        <ng-container
          *ngTemplateOutlet="unit; context: { isValid: form.controls.houseRoofHeight.valid }"
          suffix></ng-container>
      </lv-input-decimal-with-label>
    </div>

    <div class="d-flex align-center justify-between mb-18">
      <lv-input-decimal-with-label
        [max]="maxWidthHeightValue"
        [min]="minValue"
        [label]="'Controller.QuickStart.WidthW'"
        class="w-input-100"
        formControlName="houseWidth">
        <ng-container
          *ngTemplateOutlet="unit; context: { isValid: form.controls.houseWidth.valid }"
          suffix></ng-container>
      </lv-input-decimal-with-label>

      <lv-input-decimal-with-label
        [max]="maxLengthValue"
        [min]="minValue"
        [label]="'Controller.QuickStart.LengthL'"
        class="w-input-100"
        formControlName="houseLength">
        <ng-container
          *ngTemplateOutlet="unit; context: { isValid: form.controls.houseLength.valid }"
          suffix></ng-container>
      </lv-input-decimal-with-label>
    </div>
  </div>

  <img
    src="/assets/icons/new-icons/house/house-mobile.png"
    alt="house" />
</ng-template>

<ng-template
  #unit
  let-isValid="isValid">
  <span
    [class.color-error]="!platformService.isMobileApp && !isValid"
    class="fw-500">
    {{
      (lengthUnit === LengthUnitEnum.Meter ? "Controller.HouseSizesSettings.m" : "Controller.HouseSizesSettings.ft")
        | translate
    }}
  </span>
</ng-template>

<ng-template
  #ranges
  let-hasError="hasError"
  let-maxValue="maxValue">
  <div
    [ngClass]="hasError ? 'color-error' : 'text-mono-dark'"
    class="fz-8-md fz-12 mt-1 fw-600">
    <span class="fw-400">{{ "Inputs.Range" | translate }}</span> {{ minValue.toFixed(accuracy) }} -
    {{ maxValue.toFixed(accuracy) }}
  </div>
</ng-template>
