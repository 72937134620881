import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { InstallationComponent } from '../components/installation/installation.component';
import { CardComponent } from '../components/card/card.component';
import { InstallationWrapperComponent } from './installation-wrapper.component';
import { ActiveElementMobileComponent } from '../components/active-element-mobile/active-element-mobile.component';
import { InstallationRoutes } from './installation-routes';
import {
  ElementSettingsWrapperComponent,
} from '../components/devices/element-settings-wrapper/element-settings-wrapper.component';
import { SettingsGuard } from '../components/devices/guards/light-settings.guard';
import { CanDeactivateGuard } from '../../../../shared/guards/can-deactivate.guard';

const INSTALLATION_ROUTES: Routes = [
  {
    path: '',
    component: InstallationWrapperComponent,
    children: [
      {
        path: '',
        pathMatch: 'full',
        component: InstallationComponent,
      },
      {
        path: InstallationRoutes.CARD,
        component: CardComponent,
        canDeactivate: [CanDeactivateGuard],
      },
      {
        path: InstallationRoutes.SETTINGS,
        component: ElementSettingsWrapperComponent,
        canActivate: [SettingsGuard],
        canDeactivate: [CanDeactivateGuard],
      },

      /*for mobile*/
      {
        path: InstallationRoutes.ACTIVE_ELEMENT,
        component: ActiveElementMobileComponent,
        canDeactivate: [CanDeactivateGuard],
      },
    ],
  },
];

@NgModule({
  imports: [RouterModule.forChild(INSTALLATION_ROUTES)],
})
export class InstallationWrapperRoutingModule {
}
