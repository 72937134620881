import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { LightingElement, LightingPeriod, LightingProgram, SingleLightingProgram } from '../interfaces/lighting.interfaces';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class LightingProgramService {
  constructor(private http: HttpClient) {
  }

  getLightingPeriod(controllerID: number): Observable<{ items: LightingPeriod[], lightDOElements: LightingElement[], lightAOElements: LightingElement[]; }> {
    return this.http.get<{ items: LightingPeriod[], lightDOElements: LightingElement[], lightAOElements: LightingElement[]; }>(`${baseUrl}/${controllerID}/lighting/period`);
  }

  addLightingDay(controllerID: number, body: { dayNumber: number }): Observable<LightingPeriod> {
    return this.http.post<LightingPeriod>(`${baseUrl}/${controllerID}/lighting/period`, body);
  }

  deleteLightingDay(controllerID: number, periodID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/lighting/period/${periodID}`);
  }

  deleteProgram(controllerID: number, periodID: number, programId: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/lighting/period/${periodID}/program/${programId}`);
  }

  getLightingProgram(controllerID: number, periodID: number): Observable<SingleLightingProgram> {
    return this.http.get<SingleLightingProgram>(`${baseUrl}/${controllerID}/lighting/period/${periodID}/program`);
  }

  addLightingProgram(controllerID: number, periodID: number, body: LightingProgram): Observable<LightingProgram> {
    return this.http.post<LightingProgram>(`${baseUrl}/${controllerID}/lighting/period/${periodID}/program`, body);
  }

  editLightingProgram(controllerID: number, periodID: number, body: LightingProgram): Observable<LightingProgram> {
    return this.http.put<LightingProgram>(`${baseUrl}/${controllerID}/lighting/period/${periodID}/program/${body.programID}`, body);
  }
}
