import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum,
  ElementTypesEnum,
  IlluminanceUnitEnum,
  KeyboardEnum,
  LengthUnitEnum,
  TemperatureUnitEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
} from '../enums';
import { environment } from '../environments';
import { ControllerLanguageEnum } from '../../controllers/src/lib/enums';

export class GlobalConstants {
  static DefaultLatitude = 32.109333;
  static DefaultLongitude = 34.855499;
  static GOOGLE_MAPS_API_KEY = 'AIzaSyCHrvv0IzI8bvMS8YAwFeazCdmvi_K1fA0';
  static DateFormat = 'YYYY-MM-DD[T]HH:mm:ss.000';
  static PercentageSymbol = '%';

  static DefaultUnitsDetails = {
    temperature: TemperatureUnitEnum.Celsius,
    airFlow: AirFlowUnitEnum.CubicMeterPerHour,
    airSpeed: AirSpeedUnitEnum.MeterPerSecond,
    airPressure: AirPressureUnitEnum.Pascal,
    waterMainPressure: WaterMainPressureUnitEnum.Bar,
    waterLinePressure: WaterLinePressureUnitEnum.CmOfWater,
    weight: WeightUnitEnum.Kilogram,
    volume: VolumeUnitEnum.Litre,
    length: LengthUnitEnum.Meter,
    illuminance: IlluminanceUnitEnum.Luxs,
  };

  static DefaultUnitsDetailsImperial = {
    temperature: TemperatureUnitEnum.Fahrenheit,
    airFlow: AirFlowUnitEnum.CubicFeetPerHour,
    airSpeed: AirSpeedUnitEnum.FeetPerMinute,
    airPressure: AirPressureUnitEnum.InchesOfWater,
    waterMainPressure: WaterMainPressureUnitEnum.PSI,
    waterLinePressure: WaterLinePressureUnitEnum.InchesOfWater,
    weight: WeightUnitEnum.Pound,
    volume: VolumeUnitEnum.Gallon,
    length: LengthUnitEnum.Foot,
    illuminance: IlluminanceUnitEnum.FootCandle,
  };

  static DefaultUnitsDetailsMetric = {
    temperature: TemperatureUnitEnum.Celsius,
    airFlow: AirFlowUnitEnum.CubicMeterPerHour,
    airSpeed: AirSpeedUnitEnum.MeterPerSecond,
    airPressure: AirPressureUnitEnum.Pascal,
    waterMainPressure: WaterMainPressureUnitEnum.Bar,
    waterLinePressure: WaterLinePressureUnitEnum.CmOfWater,
    weight: WeightUnitEnum.Kilogram,
    volume: VolumeUnitEnum.Litre,
    length: LengthUnitEnum.Meter,
    illuminance: IlluminanceUnitEnum.Luxs,
  };

  static authLoginPage = '/auth/login';
  static authSignUpPage = '/auth/sign-up';
  static getCreateControllerUrl =
    (ticketID: string, connectionNumber: string, isReconnect?: boolean): string => {
      const url = `https://web.ls-${environment.apiUrl.includes('qa') ? 'qa' : 'rnd'}.galconsmart.com`;
      return `${url}/auth/login?ticketId=${ticketID}&connectionNumber=${connectionNumber}${isReconnect ? '&isReconnect=true' : ''}`;
    };

  static EnglishAlphabetKeyboard = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O',
    'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', '', KeyboardEnum.Up, KeyboardEnum.Backspace, KeyboardEnum.Numeric, KeyboardEnum.Space, KeyboardEnum.Enter];
  static NumericKeyboard = [1, 2, 3, '.', 4, 5, 6, '-', 7, 8, 9, KeyboardEnum.Backspace, KeyboardEnum.ABC, 0, KeyboardEnum.EnterLong];
  static ButtonsKeyboard = [KeyboardEnum.Backspace, KeyboardEnum.EnterLong];
  static DECIMAL_MIN = -2147483647; // TODO: check this values
  static DECIMAL_MAX = 2147483647;
  static SMALLINT_MIN = -32767;
  static SMALLINT_MAX = 32767;
  static TIME_MIN = 0;
  static TIME_MAX = 2359;

  /*house settings*/
  static MaxFootLength = 3277.719;
  static MaxFootWidthAndHeight = 324.819;
  static MinFootWidthHeightLength = 3.281;
  static MaxMeterLength = 999.0;
  static MaxMeterWidthAndHeight = 99.0;
  static MinMeterWidthHeightLength = 1.0;

  /*input length*/
  static MinLength = 0;
  static MaxLength = 50;

  static TimeDefault = '0000';
  /*temporary hardcoded values for device*/
  static HardCodedControllerIDForDevice = 999;
  static InfiniteTokenForDevice = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJodHRwOi8vc2NoZW1hcy54bWxzb2FwLm9yZy93cy8yMDA1LzA1L2lkZW50aXR5L2NsYWltcy9zaWQiOiIxMDAwIiwiaHR0cDovL3NjaGVtYXMubWljcm9zb2Z0LmNvbS93cy8yMDA4LzA2L2lkZW50aXR5L2NsYWltcy9yb2xlIjoiMyIsImV4cCI6MTcyNjIxOTUxMiwiaXNzIjoiTGl2ZXN0b2NrX0lzc3VlciIsImF1ZCI6IkxpdmVzdG9ja19BdWRpZW5jZSJ9.3u5pD56zvFa8OS5EBrBa4E_Oqqcx3Yc5ulTnOWnl6v8';

  //icons that should not be mirrored
  static notRtlMirroredIconsPaths = ['success-tick', 'sort', 'sort-desc', 'home/farms/devices/add-device', 'new-icons/common/cross-white', 'new-icons/common/checkmark'];

  // currently used languages
  static usedLanguages: ControllerLanguageEnum[] = [
    ControllerLanguageEnum.EngUS,
    ControllerLanguageEnum.Spanish,
    ControllerLanguageEnum.Portuguese,
  ];

  static relayIndicatorTypes: ElementTypesEnum[] = [
    ElementTypesEnum.LightDO,
    ElementTypesEnum.HeatingDO,
    ElementTypesEnum.VentilationDO,
  ];

  //house number already exists text error
  static HouseNumberAlreadyExistsError = 'ThisDeviceHouseNumber';

  static nonHandlingHttpErrors = [
    'ThisDeviceHouseNumber',
    'NoConnectionToControllerRetry',
  ];

  static Input_date_mask = '__.__.____';
  static Input_time_mask = '__:__';
  static USA_Date_placeholder = 'mm.dd.yyyy';
  static USA_Date_mask = 'M0.d0.0000';
  static EU_Date_placeholder = 'dd.mm.yyyy';
  static EU_Date_mask = 'd0.M0.0000';
  static Time_hhmm_placeholder = 'hh:mm';
  static Time_hhmmAMPM_placeholder = 'hh:mm AM';
  static Time_mask_hhmm = 'Hh:m0';

  static STRONG_PASSWORD_LENGTH = 12;
  static VERIFICATION_CODE_LENGTH = 4;
  static TICKET_EXPIRED_ERROR = 'TicketExpired';
}
