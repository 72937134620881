import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  KeyboardComponent,
  ButtonComponent,
  SvgIconComponent,
  KeyboardModeEnum,
  PageWrapperComponent, LoadingComponent,
} from '@livestock/ui';
import {
  IFlockBirdWeightSettingsView,
  selectIsLoading,
  selectFlockBirdWeightSettings,
  getFlockBirdWeightSettings,
  setFlockBirdWeightSettings,
  updateFlockBirdWeightSettings,
  getFlockBirdWeight,
  IFlockBirdWeightView,
  selectFlockBirdWeightItems,
  setFlockBirdWeight,
  updateFlockBirdWeight,
  BirdWeightFormComponent,
  BirdWeightSettingsFormComponent,
  selectSeparateMaleAndFemale,
  selectReferenceWeight,
  selectDefaultWeightItems,
  IFlockDefaultWeightView,
  clearBirdWeightItems,
  selectReferenceTable,
} from '@livestock/flock';
import {
  Observable,
  Subscription,
  filter,
} from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { ChickenBrandWeight, ColorsEnum, WeightUnitEnum } from '@livestock/shared/enums';
import { ControllerRoutes } from '@livestock/shared/routes';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { selectCurrentControllerWeightUnit } from '@livestock/controllers';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-flock-bird-weight',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    BirdWeightFormComponent,
    BirdWeightSettingsFormComponent,
    PageWrapperComponent,
    LoadingComponent,
  ],
  templateUrl: './bird-weight.component.html',
  styleUrls: [
    './bird-weight.component.scss',
    '../flock-settings/flock-settings.component.scss',
  ],
})
export class BirdWeightComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  weightUnit$: Observable<WeightUnitEnum> = this.store.select(selectCurrentControllerWeightUnit);
  separateMaleAndFemale$: Observable<boolean> = this.store.select(selectSeparateMaleAndFemale);
  defaultWeightItems$: Observable<IFlockDefaultWeightView[]> = this.store.select(selectDefaultWeightItems);
  referenceTable$: Observable<ChickenBrandWeight> = this.store.select(selectReferenceTable);
  refWeight$: Observable<{ weight: number, femaleWeight: number, maleWeight: number }>;
  birdWeightSettings: IFlockBirdWeightSettingsView;
  originalBirdWeightSettings: IFlockBirdWeightSettingsView;
  birdWeightItems: IFlockBirdWeightView[];
  originalBirdWeightItems: IFlockBirdWeightView[];
  isValidSettings = true;
  isValidWeight = true;
  editMode: boolean;
  KeyboardModeEnum = KeyboardModeEnum;
  controllerID: number;
  isDirtyBirdWeightSettings = false;
  isDirtyBirdWeightItems = false;
  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private dialogsService: DialogsService,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectFlockBirdWeightSettings)
        .pipe(
          wasChanged(),
          filter(birdWeightSettings => birdWeightSettings != null && !!birdWeightSettings.controllerID),
        ).subscribe((birdWeightSettings) => {

        if (!this.originalBirdWeightSettings) {
          this.originalBirdWeightSettings = birdWeightSettings;
        }
        this.birdWeightSettings = birdWeightSettings;
        this.isDirtyBirdWeightSettings = (JSON.stringify(this.birdWeightSettings) !== JSON.stringify(this.originalBirdWeightSettings));
      }),
    );

    this.sub$.add(
      this.store.select(selectFlockBirdWeightItems)
        .pipe(
          wasChanged(),
          filter(birdWeightItems => birdWeightItems != null),
        ).subscribe((birdWeightItems) => {
        if (!this.originalBirdWeightItems) {
          this.originalBirdWeightItems = birdWeightItems;
        }
        this.birdWeightItems = birdWeightItems;
        this.isDirtyBirdWeightItems = (JSON.stringify(this.birdWeightItems) !== JSON.stringify(this.originalBirdWeightItems));
      }),
    );

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
        this.store.dispatch(getFlockBirdWeightSettings({ controllerID: this.controllerID }));
        this.store.dispatch(getFlockBirdWeight({ controllerID: this.controllerID }));
      }),
    );
  }

  changedBirdWeightSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValidSettings = event.isValid;

    if (event.isValid && JSON.stringify(this.birdWeightSettings) !== JSON.stringify(event.formValues)) {
      this.store.dispatch(setFlockBirdWeightSettings({ birdWeightSettings: event.formValues }));
    }
  }

  changedBirdWeight(event: { formValues: any, isValid: boolean }): void {
    this.isValidWeight = event.isValid;

    if (JSON.stringify(this.birdWeightItems) !== JSON.stringify(event.formValues)) {
      this.store.dispatch(setFlockBirdWeight({ birdWeightItems: event.formValues }));
    }
  }

  activeDayChanged(day: number): void {
    setTimeout(() => {
      this.refWeight$ = this.store.select(selectReferenceWeight(day));
    });
  }

  areAnyChanges(): boolean {
    return JSON.stringify(this.originalBirdWeightSettings) !== JSON.stringify(this.birdWeightSettings)
      || JSON.stringify(this.originalBirdWeightItems) !== JSON.stringify(this.birdWeightItems);
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.store.dispatch(setFlockBirdWeightSettings({ birdWeightSettings: this.originalBirdWeightSettings }));
    this.store.dispatch(setFlockBirdWeight({ birdWeightItems: this.originalBirdWeightItems }));
    return true;
  }

  async goBack(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.menuService.toggleDashboardMenu(true);
    }
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.originalBirdWeightItems = JSON.parse(JSON.stringify(this.originalBirdWeightItems));
      this.originalBirdWeightSettings = JSON.parse(JSON.stringify(this.originalBirdWeightSettings));
      this.editMode = false;
      this.isDirtyBirdWeightItems = false;
      this.isDirtyBirdWeightSettings = false;
    }
  }

  update(): void {
    if (!this.isValidSettings || !this.isValidWeight) {
      return;
    }

    this.originalBirdWeightSettings = this.birdWeightSettings;
    this.store.dispatch(updateFlockBirdWeightSettings());

    this.originalBirdWeightItems = this.birdWeightItems;
    this.store.dispatch(updateFlockBirdWeight({ hideFlashMessage: true }));
    this.isDirtyBirdWeightSettings = false;
    this.isDirtyBirdWeightItems = false;
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  redirectToGraph(): void {
    this.router.navigate([`/controller/${this.controllerID}/${ControllerRoutes.FlockBirdWeightGraph}`]);
  }

  canDeactivate(): boolean {
    return !this.areAnyChanges();
  }

  closeComponent(): void {
    this.store.dispatch(clearBirdWeightItems());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
