import { ElementTypesEnum } from '@livestock/shared/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { CardType } from '../enums/card-type.enum';

export class InstallationRoutes {
  static CARD = 'card/:cardID/cardType/:cardType';
  static SETTINGS = 'elementType/:elementType/settings';
  static ACTIVE_ELEMENT = 'card/:cardID/cardType/:cardType/elementID/:elementID/elementType/:elementType/connectionID/:connectionID';

  static createCardLink(cardID: number, cardType: CardType): string {
    return `${AppRoutes.INSTALLATION}/card/${cardID}/cardType/${cardType}`;
  }

  static createSettingsLink(elementType: ElementTypesEnum): string {
    return `${AppRoutes.INSTALLATION}/elementType/${elementType}/settings`;
  }

  static createActiveElementLink(cardID: number, cardType: CardType, elementID: number, elementType: ElementTypesEnum, connectionID: number): string {
    return `${AppRoutes.INSTALLATION}/card/${cardID}/cardType/${cardType}/elementID/${elementID}/elementType/${elementType}/connectionID/${connectionID}`;
  }
}
