import { ChangeDetectionStrategy, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import {
  selectCurrentElementType,
  selectElementIsLoading,
} from '@livestock/installation';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-active-element',
  templateUrl: './active-element.component.html',
  styleUrls: ['./active-element.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ActiveElementComponent {
  currentElementType$: Observable<number> = this.store.select(selectCurrentElementType);
  isElementLoading$: Observable<boolean> = this.store.select(selectElementIsLoading);

  constructor(
    public platformService: PlatformService,
    private store: Store,
  ) {}

}
