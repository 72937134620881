<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name">
          Bird management
        </span>
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div
    [class.widget-body__graph]="isGraphOpened"
    class="widget-body d-flex">
    <ng-container *ngIf="!isGraphOpened; else graph">
      <div class="widget-content flex-grow-1 d-flex flex-column justify-between">
        <div class="ph-10 ph-20-xl ph-0-mobile">
          <table class="w-100-percent text-center fz-24">
            <thead>
              <tr class="border-bottom fz-16 color-tertiary">
                <th class="pb-15 w-100"></th>
                <th class="pb-15">Today</th>
                <th class="pb-15">Yesterday</th>
                <th class="pb-15"></th>
              </tr>
            </thead>

            <tbody class="fz-32 fz-24-xl fz-22-mobile">
              <tr class="td-padding-25 border-bottom">
                <td>
                  <img
                    [qaTags]="'qa-img-bird-p-female'"
                    alt="female"
                    class="pointer"
                    src="/assets/icons/dashboard/female.svg" />
                </td>
                <td>
                  <h6
                    [qaTags]="'qa-lbl-bird-p-female-total-value'"
                    class="line-height-1">
                    28,950
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-female-total-lbl'"
                    class="color-tertiary fz-16 fz-18-mobile">
                    Total Female
                  </div>
                </td>
                <td>
                  <h6
                    [qaTags]="'qa-lbl-bird-p-female-weight-value'"
                    class="line-height-1">
                    0.6<span
                      [qaTags]="'qa-lbl-bird-p-female-weight-units'"
                      class="fz-16"
                      >Lb</span
                    >
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-female-weight-lbl'"
                    class="color-tertiary fz-16 fz-18-mobile">
                    Avg weight
                  </div>
                </td>
                <td></td>
              </tr>

              <tr class="td-padding-25 border-bottom">
                <td>
                  <img
                    [qaTags]="'qa-img-bird-p-male'"
                    alt="male"
                    class="pointer"
                    src="/assets/icons/dashboard/male.svg" />
                </td>
                <td>
                  <h6
                    [qaTags]="'qa-lbl-bird-p-male-total-value'"
                    class="line-height-1">
                    874
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-male-total-lbl'"
                    class="color-tertiary fz-16 fz-18-mobile">
                    Total Males
                  </div>
                </td>
                <td>
                  <h6
                    [qaTags]="'qa-lbl-bird-p-male-weight-value'"
                    class="line-height-1">
                    0.8<span
                      [qaTags]="'qa-lbl-bird-p-male-weight-units'"
                      class="fz-16"
                      >Lb</span
                    >
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-male-weight-lbl'"
                    class="color-tertiary fz-16 fz-18-mobile">
                    Avg weight
                  </div>
                </td>
                <td></td>
              </tr>

              <tr class="td-padding-20">
                <td></td>
                <td>
                  <h6
                    [qaTags]="'qa-lbl-bird-p-mortality-value'"
                    class="line-height-1">
                    0.2<span
                      [qaTags]="'qa-lbl-bird-p-mortality-units'"
                      class="fz-16"
                      >%
                    </span>
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-mortality-lbl'"
                    class="color-tertiary fz-16 fz-18-mobile">
                    Mortality
                  </div>
                </td>
                <td class="color-disabled">
                  <h6
                    [qaTags]="'qa-lbl-bird-p-culled-value'"
                    class="line-height-1">
                    00
                  </h6>
                  <div
                    [qaTags]="'qa-lbl-bird-p-culled-lbl'"
                    class="fz-16 fz-18-mobile">
                    Culled
                  </div>
                </td>
                <td></td>
              </tr>
            </tbody>
          </table>
        </div>

        <button
          (click)="openGraph()"
          class="tertiary-btn common-btn graph-btn">
          <img
            alt="graph"
            class="graph-icon mr-10"
            src="/assets/icons/dashboard/graph.svg" />
          <span>Graph</span>
        </button>
      </div>
    </ng-container>

    <ng-template #graph>
      <div class="widget-content">
        <img
          [qaTags]="
            'qa-lbl-panel-title qa-lbl-bird-p-g-y-axis-label qa-lbl-bird-p-g-x-axis-label qa-img-bird-p-g-legend-pop qa-img-bird-p-g-legend qa-btn-bird-p-graph'
          "
          [src]="
            platformService.isMd
              ? '/assets/icons/dashboard/graph-mockup-mobile.png'
              : '/assets/icons/dashboard/graph-mockup.png'
          "
          alt="mockup"
          class="m-auto d-block w-100-percent" />
      </div>
    </ng-template>
  </div>
</div>
