<div
  #keyboard
  [ngClass]="{
    'keyboard-active': isActive
  }"
  class="keyboard">
  <ng-container *ngIf="ranges?.min && ranges?.max">
    <div class="ranges">
      {{ labelForRanges$ | async | translate : { min: ranges.min, max: ranges.max } }}
    </div>
  </ng-container>

  <ng-container *ngIf="keyboardMode$ | async as keyboardMode">
    <ng-container [ngSwitch]="keyboardMode">
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.Numeric"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.Decimal"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.NumericNegative"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.DecimalNegative"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
      <ng-container
        *ngSwitchCase="VirtualKeyboardModesEnum.AMPM"
        [ngTemplateOutletContext]="{ keyboardMode: keyboardMode }"
        [ngTemplateOutlet]="numericKeyboard"></ng-container>
    </ng-container>
  </ng-container>
</div>

<ng-template
  #numericKeyboard
  let-keyboardMode="keyboardMode">
  <div class="keyboard-row mb-6">
    <div
      *ngFor="let integer of VirtualKeyboardConstants.NumericArrayTop"
      (click)="printSymbol(integer)"
      class="button w-60">
      {{ integer }}
    </div>

    <!--print dot-->
    <div
      *ngIf="keyboardMode === VirtualKeyboardModesEnum.Decimal"
      (click)="printSymbol(VirtualKeyboardButtonsEnum.DOT)"
      class="button w-60">
      {{ VirtualKeyboardButtonsEnum.DOT }}
    </div>

    <!--print +/- -->
    <ng-container
      *ngIf="
        keyboardMode === VirtualKeyboardModesEnum.NumericNegative ||
        keyboardMode === VirtualKeyboardModesEnum.DecimalNegative
      ">
      <div
        (click)="printSymbol(VirtualKeyboardButtonsEnum.MINUS)"
        class="button w-60">
        {{ VirtualKeyboardButtonsEnum.MINUS }}
      </div>

      <div
        (click)="printSymbol(VirtualKeyboardButtonsEnum.PLUS)"
        class="button w-60">
        {{ VirtualKeyboardButtonsEnum.PLUS }}
      </div>
    </ng-container>

    <!--print AM/PM-->
    <ng-container *ngIf="keyboardMode === VirtualKeyboardModesEnum.AMPM">
      <div
        (click)="setAmPm(AmPmEnum.AM)"
        [class.button-disabled]="AMPMDisabled$ | async"
        class="button w-60">
        {{ "Buttons.AM" | translate }}
      </div>

      <div
        (click)="setAmPm(AmPmEnum.PM)"
        [class.button-disabled]="AMPMDisabled$ | async"
        class="button w-60">
        {{ "Buttons.PM" | translate }}
      </div>
    </ng-container>

    <div
      (click)="printSymbol(VirtualKeyboardButtonsEnum.DEL)"
      class="button button-grey w-88">
      <ls-svg-icon [path]="IconsEnum.BACKSPACE"></ls-svg-icon>
    </div>
  </div>

  <div class="keyboard-row">
    <div
      *ngFor="let integer of VirtualKeyboardConstants.NumericArrayBottom"
      (click)="printSymbol(integer)"
      class="button w-60">
      {{ integer }}
    </div>

    <!--print dot-->
    <div
      *ngIf="keyboardMode === VirtualKeyboardModesEnum.DecimalNegative"
      (click)="printSymbol(VirtualKeyboardButtonsEnum.DOT)"
      class="button w-60">
      {{ VirtualKeyboardButtonsEnum.DOT }}
    </div>

    <div
      (click)="hideKeyboard()"
      class="button button-save w-88">
      <ls-svg-icon [path]="IconsEnum.CHECKMARK_SMALL"></ls-svg-icon>
    </div>
  </div>
</ng-template>
