import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICurrentUserView } from '../interfaces/current-user-view';

export const getCurrentUser = createAction(
  '[CURRENT USER] Get Current User [...]',
);

export const getCurrentUserSuccess = createAction(
  '[CURRENT USER] Get Current User [SUCCESS]',
  props<{ currentUser: ICurrentUserView; }>(),
);

export const getCurrentUserError = createAction(
  '[CURRENT USER] Get Current User [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);
