import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatRadioChange, MatRadioModule } from '@angular/material/radio';
import { EnumPipe } from '@livestock/shared/pipes';
import { FormsModule } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { ControllerUnitEnum } from '../../enums/controller-unit.enum';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-units-dialog',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    MatRadioModule,
    TranslateModule,
    EnumPipe,
    QaTagsDirective,
  ],
  templateUrl: './units-dialog.component.html',
  styleUrls: ['./units-dialog.component.scss'],
})
export class UnitsDialogComponent {
  selectedValue: ControllerUnitEnum;
  ControllerUnitEnum = ControllerUnitEnum;

  constructor(
    public dialogRef: MatDialogRef<UnitsDialogComponent>,
  ) {
  }

  setup(value: ControllerUnitEnum): void {
    this.selectedValue = value;
  }

  close(value: ControllerUnitEnum): void {
    this.dialogRef.close({
      value,
    });
  }

  changeValue(event: MatRadioChange): void {
    this.dialogRef.close({
      value: event.value,
    });
  }
}
