import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { AddDeleteDayEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-edit-day-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './edit-day-dialog.component.html',
  styleUrls: ['./edit-day-dialog.component.scss'],
})
export class EditDayDialogComponent {

  constructor(
    public dialogRef: MatDialogRef<EditDayDialogComponent>,
  ) {
  }

  addDay(): void {
    this.dialogRef.close(AddDeleteDayEnum.AddDay);
  }

  deleteDay(): void {
    this.dialogRef.close(AddDeleteDayEnum.DeleteDay);
  }

  close(): void {
    this.dialogRef.close();
  }
}