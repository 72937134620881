import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UpsertControllerState } from './upsert-controller.state';
import { UPSERT_CONTROLLER_FEATURE_KEY } from './upsert-controller.reducers';
import {
  ICreateAddControllerTicketRequestView,
} from '../../interfaces/create-add-controller-ticket-request-view.interface';
import { GlobalConstants } from '@livestock/shared/constants';
import { ControllerUnitEnum } from '../../enums/controller-unit.enum';
import { AirPressureUnitEnum, LengthUnitEnum, TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';

export const getUpsertControllerState =
  createFeatureSelector<UpsertControllerState>(UPSERT_CONTROLLER_FEATURE_KEY);

export const selectIsLoading = createSelector(
  getUpsertControllerState,
  ({ isLoading }) => isLoading,
);

export const selectGeneralSettings = createSelector(
  getUpsertControllerState,
  ({ generalSettings }) => generalSettings,
);

export const selectGeneralSettingsWithoutUnits = createSelector(
  getUpsertControllerState,
  ({ generalSettings }) => {
    const generalSettingsWithoutUnits = { ...generalSettings };
    delete generalSettingsWithoutUnits.unitsDetails;
    delete generalSettingsWithoutUnits.units;
    delete generalSettingsWithoutUnits.actualUnits;
    return generalSettingsWithoutUnits;
  },
);

export const selectDateTimeSettings = createSelector(
  getUpsertControllerState,
  ({ dateTimeSettings }) => dateTimeSettings,
);

export const selectNetworkSettings = createSelector(
  getUpsertControllerState,
  ({ networkSettings }) => networkSettings,
);

export const selectHouseSizesSettings = createSelector(
  getUpsertControllerState,
  ({ houseSizesSettings }) => houseSizesSettings,
);

export const selectTicketView = createSelector(
  getUpsertControllerState,
  ({ ticketView }) => ticketView,
);

export const selectIsControllerAssignedWithFarm = createSelector(
  getUpsertControllerState,
  (store) => store?.isControllerAssignedWithFarm,
);

export const selectUpdateDateTimeSettings = createSelector(
  getUpsertControllerState,
  ({ dateTimeSettings }) => {
    return {
      controllerID: dateTimeSettings.controllerID,
      timeZoneID: dateTimeSettings.timeZoneID,
      hoursFormat: dateTimeSettings.hoursFormat,
      manuallyDateTime: dateTimeSettings.manuallyDateTime?.format(
        GlobalConstants.DateFormat,
      ),
      manuallyDateTimeMoment: dateTimeSettings.manuallyDateTime,
    };
  },
);

export const selectIsControllerCreatedByConnectionNumberInfo = createSelector(
  getUpsertControllerState,
  (state) => {
    return {
      isControllerCreatedByConnectionNumber: state?.isControllerCreatedByConnectionNumber,
      creatingControllerError: state?.creatingControllerError,
    };
  },
);

export const selectIsControllerTicketExecuted = createSelector(
  getUpsertControllerState,
  (state) => {
    return {
      isControllerTicketExecuted: state?.isControllerTicketExecuted,
      creatingControllerError: state?.creatingControllerError,
    };
  },
);

export const selectCurrentStep = createSelector(
  getUpsertControllerState,
  ({ currentStep }) => currentStep,
);

export const selectLengthUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return LengthUnitEnum.Meter;
      case ControllerUnitEnum.Imperial:
        return LengthUnitEnum.Foot;
      default:
        return generalSettings?.unitsDetails?.length;
    }
  },
);

export const selectTemperatureUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      default:
        return generalSettings?.unitsDetails?.temperature;
    }
  },
);

export const selectAirPressureUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return AirPressureUnitEnum.Pascal;
      case ControllerUnitEnum.Imperial:
        return AirPressureUnitEnum.InchesOfWater;
      default:
        return generalSettings?.unitsDetails?.airPressure;
    }
  },
);

export const selectWeightUnit = createSelector(
  selectGeneralSettings,
  (generalSettings) => {
    switch (generalSettings?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Kilogram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return generalSettings?.unitsDetails?.weight;
    }
  },
);

export const selectCreateAddControllerTicketRequestView = createSelector(
  selectGeneralSettings,
  selectDateTimeSettings,
  selectNetworkSettings,
  selectHouseSizesSettings,
  (
    generalSettings,
    dateTimeSettings,
    networkSettings,
    houseSizesSettings,
  ): ICreateAddControllerTicketRequestView => {
    return {
      language: generalSettings.language,
      units: generalSettings.units,
      birdType: generalSettings.birdType,
      timeZoneID: dateTimeSettings.timeZoneID,
      manuallyDateTime: dateTimeSettings.manuallyDateTime?.format(
        GlobalConstants.DateFormat,
      ),
      ventilationWorkingMode: generalSettings.ventilationWorkingMode,
      hoursFormat: dateTimeSettings.hoursFormat,
      unitsDetails:
        generalSettings.units === ControllerUnitEnum.Custom
          ? generalSettings.unitsDetails
          : null,
      houseSizes: {
        houseWidth: houseSizesSettings.houseWidth,
        houseLength: houseSizesSettings.houseLength,
        houseHeight: houseSizesSettings.houseHeight,
        houseRoofHeight: houseSizesSettings.houseRoofHeight,
        houseNumber: houseSizesSettings.houseNumber,
      },
      networkSettings: {
        modem: networkSettings?.modem,
        wifi: networkSettings?.wifi,
        lan: networkSettings?.lan,
        modemAPN: 'modemAPN',
        modemUser: 'modemUser',
        modemPassword: 'modemPassword',
        wifiName: 'wifiName',
        wifiPassword: 'wifiPassword',
        wifiAutoConnect: true,
        lanIp: null,
        lanMac: null,
      },
    };
  },
);

export const selectDeviceControllerSerialNumber = createSelector(
  getUpsertControllerState,
  ({ deviceControllerSerialNumber }) => deviceControllerSerialNumber,
);

export const selectAssigningToFarmInProgress = createSelector(
  getUpsertControllerState,
  ({ assigningToFarmInProgress }) => assigningToFarmInProgress,
);

export const selectNetworkConnectionInProgress = createSelector(
  getUpsertControllerState,
  ({ networkConnectionInProgress }) => networkConnectionInProgress,
);

export const selectControllerVentilationMode = createSelector(
  getUpsertControllerState,
  ({ generalSettings }) => generalSettings?.ventilationWorkingMode,
);

export const selectFlockSettings = createSelector(
  getUpsertControllerState,
  ({ flockSettings }) => flockSettings,
);

export const selectDefaultFlockWeights = createSelector(
  getUpsertControllerState,
  ({ defaultFlockWeights }) => defaultFlockWeights?.items,
);

export const selectFlockSettingsAndWeights = createSelector(
  getUpsertControllerState,
  ({ flockWeights, flockSettings, defaultFlockWeights }) => {
    return {
      ...flockSettings,
      flockBirdWeights: flockWeights?.length > 0 ? flockWeights : defaultFlockWeights?.items,
    };
  },
);
