import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { Store } from '@ngrx/store';
import {
  getCardElementTypes,
  getControllerCards,
  selectCardElementTypes,
  selectCurrentConnectionSetupIsDirty,
  selectCurrentElementID,
  selectCurrentElementType,
  selectHasCards,
  selectInstallationIsLoading,
  setCurrentCard,
} from '@livestock/installation';
import { filter, firstValueFrom, Observable, Subscription } from 'rxjs';
import { IElementType } from '../../interfaces/element/element-type.interface';
import { ColorsEnum } from '@livestock/shared/enums';
import { InstallationRoutes } from '../../module/installation-routes';
import { CardType } from '../../enums/card-type.enum';
import { ConnectionType } from '../../enums/connection-type.enum';
import { NumberUtils } from '@livestock/shared/utils';
import { ElementWrapperHelper } from '../devices/helpers/element-wrapper.helper';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { DialogsService } from '@livestock/shared/services';

@Component({
  selector: 'ls-card',
  templateUrl: './card.component.html',
  styleUrls: ['./card.component.scss'],
})
export class CardComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  /*subs*/
  currentElementType$: Observable<number> = this.store.select(selectCurrentElementType);
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectInstallationIsLoading);
  currentElementID$: Observable<number> = this.store.select(selectCurrentElementID);

  /*vars*/
  cardID: number;
  cardType: CardType;
  connectionType: ConnectionType;
  cardNumber: number;
  elementTypes: IElementType[] = [];

  /*constants*/
  ColorsEnum = ColorsEnum;
  CardType = CardType;
  helper = ElementWrapperHelper;

  constructor(
    private router: Router,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    this.cardID = +this.activatedRoute.snapshot.params['cardID'];
    this.cardType = +this.activatedRoute.snapshot.params['cardType'];
    this.cardNumber = +this.activatedRoute.snapshot.queryParams['cardNumber'];
    this.connectionType = NumberUtils.toNumberOrNull(this.activatedRoute.snapshot.queryParams['connectionType']);

    this.sub$.add(
      this.store.select(selectCardElementTypes).pipe(
        filter(res => res != null),
      ).subscribe((elementTypes) => {
        this.elementTypes = this.connectionType
          ? elementTypes.filter(elementType => elementType.connectionType === this.connectionType)
          : elementTypes;
      }),
    );

    this.getCardsIfNeeded();
    this.store.dispatch(setCurrentCard({ cardID: this.cardID }));
    this.store.dispatch(getCardElementTypes({ cardID: this.cardID }));
  }

  async getCardsIfNeeded(): Promise<void> {
    const hasCards = await firstValueFrom(this.store.select(selectHasCards));

    if (!hasCards) {
      this.store.dispatch(getControllerCards());
    }
  }

  async returnToInstallationMain(): Promise<void> {
    this.router.navigate([AppRoutes.INSTALLATION]);
  }

  async isDirtyForm(): Promise<boolean> {
    return await firstValueFrom(this.store.select(selectCurrentConnectionSetupIsDirty));
  }

  async navigateToSettings(): Promise<void> {
    const elementID = await firstValueFrom(this.currentElementID$);
    if (!elementID) {
      return;
    }

    const elementType = await firstValueFrom(this.currentElementType$);

    this.router.navigate([InstallationRoutes.createSettingsLink(elementType)]);
  }

  closeComponent(): void {
  }

  async canDeactivate(): Promise<boolean> {
    const isDirty = await this.isDirtyForm();
    return !isDirty;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
