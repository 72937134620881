import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

import { GlobalConstants } from '@livestock/shared/constants';
import { Position } from '@livestock/ui';
import { IconsEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-svg-icon',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './svg-icon.component.html',
  styleUrls: ['./svg-icon.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SvgIconComponent {
  @Input() fullHeight: boolean;
  @Input() color: string;
  @Input() hasMargin = false;
  @Input() mirrorRtl = true;
  @Input() overlayIconPosition: Position = Position.Regular;

  @Input() set path(path: string | typeof IconsEnum) {
    this.fetchSvg(path as string, 'svgIcon');
  }

  @Input() set overlayPath(overlayPath: string | null) {
    this.fetchSvg(overlayPath, 'overlaySvgIcon');
  }

  svgIcon: SafeHtml;
  overlaySvgIcon: SafeHtml;
  basePath = '/assets/icons';

  constructor(
    private httpClient: HttpClient,
    private sanitizer: DomSanitizer,
    private cdr: ChangeDetectorRef,
  ) {
  }

  private fetchSvg(path: string, targetProperty: string): void {
    if (!path) {
      this[targetProperty] = '';
      return;
    }

    if (GlobalConstants.notRtlMirroredIconsPaths.includes(path)) this.mirrorRtl = false;

    this.httpClient
      .get(`${this.basePath}/${path}.svg`, { responseType: 'text' })
      .subscribe(value => {
        this[targetProperty] = this.sanitizer.bypassSecurityTrustHtml(value);
        this.cdr.detectChanges();
      });
  }
}
