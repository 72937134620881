import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as ventBrandsActions from './ventilation-brands.actions';
import { VentilationBrandsApiService } from '../service/ventilation-brands-api.service';
import { IVentBrands } from '../interfaces/vent-brands.interface';
import { IVentModelsDO } from '../interfaces/vent-models-do.interface';
import { IVentModelsAO } from '../interfaces/vent-models-ao.interface';
import {
  selectVentilationAOModels,
  selectVentilationBrands,
  selectVentilationDOModels,
} from './ventilation-brands.selectors';

@Injectable()

export class VentilationBrandsEffects {

  getVentilationBrands$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ventBrandsActions.getVentilationBrands),
      withLatestFrom(this.store.select(selectVentilationBrands)),
      switchMap(([_, ventBrands]) => {
        if (ventBrands.length > 0) {
          return [ventBrandsActions.getVentilationBrandsSuccess({})];
        }

        return this.ventilationBrandsApiService.getVentilationBrands().pipe(
          map((view: IVentBrands) => {
            return ventBrandsActions.getVentilationBrandsSuccess({ ventilationBrands: view.items });
          }),
          catchError((error) => of(ventBrandsActions.getVentilationBrandsError({ payload: error }))),
        );
      }),
    ),
  );

  getVentilationModels$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ventBrandsActions.getVentilationModels),
      withLatestFrom(
        this.store.select(selectVentilationDOModels),
        this.store.select(selectVentilationAOModels),
      ),
      switchMap(([{ brandID, isDO }, modelsDO, modelsAO]) => {
        const modelsExist = isDO
          ? modelsDO.some(model => model.brandID === brandID)
          : modelsAO.some(model => model.brandID === brandID);

        if (modelsExist) {
          return EMPTY;
        }

        if (isDO) {
          return this.ventilationBrandsApiService.getVentilationDOModels(brandID).pipe(
            map((view: IVentModelsDO) => {
              return ventBrandsActions.getVentilationModelsDOSuccess({ ventilationModels: view.items });
            }),
            catchError((error) => of(ventBrandsActions.getVentilationModelsError({ payload: error }))),
          );
        }

        return this.ventilationBrandsApiService.getVentilationAOModels(brandID).pipe(
          map((view: IVentModelsAO) => {
            return ventBrandsActions.getVentilationModelsAOSuccess({ ventilationModels: view.items });
          }),
          catchError((error) => of(ventBrandsActions.getVentilationModelsError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private ventilationBrandsApiService: VentilationBrandsApiService,
  ) {
  }
}
