import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as lightingMappingActions from '@livestock/lighting-mapping';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { LightingMappingApiService, selectLightingMappings } from '@livestock/lighting-mapping';
import { ILightingMappingItemUpdate } from '../interfaces/lighting-mapping-item-update.interface';

@Injectable()
export class LightingMappingEffects {
  getLightingMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingMappingActions.getLightingMappings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.lightingMappingApiService.getLightingMappings(controllerID).pipe(
          map((view) => lightingMappingActions.getLightingMappingsSuccess({ view })),
          catchError((error) => of(lightingMappingActions.getLightingMappingsError({ error }))),
        );
      }),
    ),
  );

  updateLightingMappings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(lightingMappingActions.updateLightingMappings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectLightingMappings),
      ),
      switchMap(([_action, controllerID, lightingMappings]) => {
        const updatedLightingMappings: ILightingMappingItemUpdate[] = lightingMappings.map(mapping => {
          return {
            lightElementID: mapping.lightElementID,
            average: mapping.average,
            sensorsList: mapping.sensorsList.map(sensor => sensor.elementID),
          };
        });

        return this.lightingMappingApiService.updateLightingMappings(controllerID, updatedLightingMappings).pipe(
          map(() => lightingMappingActions.updateLightingMappingsSuccess()),
          catchError((error) => of(lightingMappingActions.updateLightingMappingsError({ error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private lightingMappingApiService: LightingMappingApiService,
  ) {
  }
}
