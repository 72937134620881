import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { DialogsService, LanguageService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { ISelectDialogOptions } from '@livestock/shared/interfaces';
import {
  DualToggleComponent,
  InputComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent,
  RadioListComponent,
  SvgIconComponent,
  ThemeEnum,
} from '@livestock/ui';
import { IGeneralSettingsView } from '../../interfaces/general-settings-view.interface';
import { ControllerLanguageEnum, ControllerUnitEnum } from '@livestock/controllers/enums';
import { BirdTypeEnum } from '../../enums/bird-type.enum';
import { MatDialog } from '@angular/material/dialog';
import { UnitsDetailsDialogComponent } from '../custom-units-dialog/custom-units-dialog.component';
import { lastValueFrom, Subscription } from 'rxjs';
import { UnitsDialogComponent } from '../units-dialog/units-dialog.component';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Store } from '@ngrx/store';
import { setControllerLanguage } from '../../+state/current-controller/current-controller.actions';
import {
  ColorsEnum,
  IconsEnum,
  StorageItem,
} from '@livestock/shared/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { VentilationWorkingModeEnum } from '@livestock/flock';
import { MatMenuModule } from '@angular/material/menu';
import { MatRadioModule } from '@angular/material/radio';
import { CustomUnitsListComponent } from '../custom-units-list/custom-units-list.component';
import { GlobalConstants } from '@livestock/shared/constants';
import { fadeInOut } from '@livestock/shared/animations';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    InputComponent,
    EnumPipe,
    QaTagsDirective,
    LoadingComponent,
    InputIntegerComponent,
    KeyboardComponent,
    MatMenuModule,
    DualToggleComponent,
    EnumToArrayPipe,
    MatRadioModule,
    SvgIconComponent,
    RadioListComponent,
    CustomUnitsListComponent,
  ],
  selector: 'ls-controller-general-settings-form',
  templateUrl: './general-settings-form.component.html',
  styleUrls: ['./general-settings-form.component.scss'],
  animations: [fadeInOut],
})
export class ControllerGeneralSettingsFormComponent implements OnInit, OnDestroy {

  @Input() isLoading: boolean;
  @Input() isQuickStart: boolean;
  @Input() isDisabled: boolean = false;

  @Input() set generalSettings(generalSettings: IGeneralSettingsView) {
    if (generalSettings) {
      this.updateFormValues(generalSettings);
    }
  }

  @Output() changed = new EventEmitter();
  // subs
  sub$ = new Subscription();
  form: FormGroup = new FormGroup(
    {
      controllerID: new FormControl(),
      language: new FormControl<ControllerLanguageEnum>(
        Number(LocalStorageService.getStorageItem(StorageItem.ControllerLang) || ControllerLanguageEnum.EngUS),
        [Validators.required],
      ),
      units: new FormControl<ControllerUnitEnum>(ControllerUnitEnum.Imperial, [Validators.required]),
      birdType: new FormControl(BirdTypeEnum.Broilers, [Validators.required]),
      unitsDetails: new FormControl(GlobalConstants.DefaultUnitsDetailsImperial),
      ventilationWorkingMode: new FormControl<VentilationWorkingModeEnum>(VentilationWorkingModeEnum.Basic),
    },
  );
  menuItem: 'language' | 'units' | 'birdType' | 'ventilationMode' = null;

  readonly languagesList = [
    { value: ControllerLanguageEnum.EngUS, title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.EngUS) },
    {
      value: ControllerLanguageEnum.Spanish,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Spanish),
    },
    {
      value: ControllerLanguageEnum.Portuguese,
      title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Portuguese),
    },
  ];
  readonly birdTypeList = [
    { value: BirdTypeEnum.Broilers, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.Broilers) },
    { value: BirdTypeEnum.BreedersR, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.BreedersR), disabled: true },
    { value: BirdTypeEnum.BreedersP, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.BreedersP), disabled: true },
    { value: BirdTypeEnum.AllInAll, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.AllInAll), disabled: true },
    { value: BirdTypeEnum.Commercial, title: BirdTypeEnum.toTranslateKey(BirdTypeEnum.Commercial), disabled: true },
  ];
  unitsList = [
    { value: ControllerUnitEnum.Metric, title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Metric) },
    { value: ControllerUnitEnum.Imperial, title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Imperial) },
  ];

  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;
  ControllerLanguageEnum = ControllerLanguageEnum;
  IconsEnum = IconsEnum;
  VentilationWorkingModeEnum = VentilationWorkingModeEnum;
  ControllerUnitEnum = ControllerUnitEnum;
  ThemeEnum = ThemeEnum;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          this.changed.emit({
            formValues,
            isValid: this.form.valid,
          });
        }),
    );
    this.sub$.add(this.form.controls['unitsDetails'].valueChanges.pipe(wasChanged()).subscribe(unitsDetails => this.unitsDetailsChange(unitsDetails)));
    this.sub$.add(this.form.controls['language'].valueChanges.pipe(wasChanged()).subscribe(language => this.languageChanged(language)));

    if (this.form.controls['units'].value === ControllerUnitEnum.Custom && !this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)) {
      this.unitsList.push({
        title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Custom),
        value: ControllerUnitEnum.Custom,
      });
    }

    this.form.controls['units'].valueChanges.pipe(wasChanged()).subscribe(i => {
      if (i === ControllerUnitEnum.Imperial) {
        this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsImperial, { emitEvent: false });
      } else if (i === ControllerUnitEnum.Metric) {
        this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsMetric, { emitEvent: false });
      }
    });
  }

  menuClicked(menuItemIndex: typeof this.menuItem): void {
    this.menuItem = menuItemIndex;
    if (
      this.menuItem !== 'units' &&
      this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)
      && !(this.form.controls['units'].value === ControllerUnitEnum.Custom)
    ) {
      this.unitsList = this.unitsList.filter(({ value }) => value !== ControllerUnitEnum.Custom);
    }
  }

  languageChanged(language: ControllerLanguageEnum): void {
    this.form.controls['language'].setValue(language);
    this.store.dispatch(setControllerLanguage({ controllerLanguage: language }));

    // imperial is default for EN-US
    const units = language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric;
    this.form.controls['units'].setValue(units);

    if (units === ControllerUnitEnum.Imperial) {
      this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsImperial);
    } else if (units === ControllerUnitEnum.Metric) {
      this.form.controls['unitsDetails']?.setValue(GlobalConstants.DefaultUnitsDetailsMetric);
    }
  }

  async openLanguageSelector(): Promise<void> {
    const title = 'Controller.GeneralSettings.LanguageDialog.SelectLanguage';
    const options: ISelectDialogOptions = {
      enum: ControllerLanguageEnum,
      enumString: 'controller-language',
      value: this.form.value.language,
      validators: [Validators.required],
      visibleEnumItems: [
        ControllerLanguageEnum.EngUS,
        ControllerLanguageEnum.Portuguese,
        ControllerLanguageEnum.Spanish,
      ],
    };
    const result = await this.dialogsService.select(
      options, title, [],
    );

    if (result?.value != null) {
      this.form.controls['language'].setValue(result.value);
      this.store.dispatch(setControllerLanguage({ controllerLanguage: result.value }));

      // imperial is default for EN-US
      const units = result.value === ControllerLanguageEnum.EngUS
        ? ControllerUnitEnum.Imperial
        : ControllerUnitEnum.Metric;
      this.form.controls['units'].setValue(units);
    }
  }

  unitsDetailsChange(unitsDetails): void {
    if (JSON.stringify(unitsDetails) === JSON.stringify(GlobalConstants.DefaultUnitsDetailsImperial)) {
      this.form.controls['units'].setValue(ControllerUnitEnum.Imperial, { emitEvent: false });
      this.unitsList = this.unitsList.filter(unit => unit.value !== ControllerUnitEnum.Custom);
    } else if (JSON.stringify(unitsDetails) === JSON.stringify(GlobalConstants.DefaultUnitsDetailsMetric)) {
      this.form.controls['units'].setValue(ControllerUnitEnum.Metric, { emitEvent: false });
      this.unitsList = this.unitsList.filter(unit => unit.value !== ControllerUnitEnum.Custom);
    } else {
      this.form.controls['units'].setValue(ControllerUnitEnum.Custom, { emitEvent: false });
      if (!this.unitsList.some(unit => unit.value === ControllerUnitEnum.Custom)) {
        this.unitsList.push({
          title: ControllerUnitEnum.toTranslateKey(ControllerUnitEnum.Custom),
          value: ControllerUnitEnum.Custom,
        });
      }
    }
  }

  // available only via quick start
  async openUnitsSelector(): Promise<void> {
    // TODO: add !this.platformService.isDeviceApp later
    if (!this.isQuickStart) return;

    const dialogRef = this.dialog.open(UnitsDialogComponent, {
      width: '440px',
      panelClass: 'popup-full__mobile',
      disableClose: true,
    });
    dialogRef.componentInstance.setup(this.form.controls['units'].value);
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (result?.value != null) {
      this.form.controls['units'].setValue(result.value);

      if (result.value !== ControllerUnitEnum.Custom) {
        this.form.controls['unitsDetails'].setValue(null);
      }

      if (result.value === ControllerUnitEnum.Custom) {
        const dialogRef = this.dialog.open(UnitsDetailsDialogComponent, {
          width: '1280px',
          panelClass: 'popup-full__mobile',
          disableClose: true,
          autoFocus: false,
        });
        dialogRef.componentInstance.setup(this.form.controls['unitsDetails'].value);
        const unitsDetailsResult = await lastValueFrom(dialogRef.afterClosed());

        if (unitsDetailsResult) {
          this.form.controls['unitsDetails'].setValue(unitsDetailsResult);
        }
      }
    }
  }

  async openBirdTypeSelector(): Promise<void> {
    const title = 'Controller.GeneralSettings.BirdsTypeDialog.SelectBirdsType';
    const options: ISelectDialogOptions = {
      enum: BirdTypeEnum,
      enumString: 'controller-bird-type',
      value: this.form.value.birdType,
      validators: [Validators.required],
    };
    const result = await this.dialogsService.select(
      options, title, [],
    );

    if (result?.value != null) {
      this.form.controls['birdType'].setValue(result.value);
    }
  }

  async openVentilationModeSelector(): Promise<void> {
    const title = 'Controller.GeneralSettings.VentilationWorkingModeDialog.SelectVentilationMode';
    const options: ISelectDialogOptions = {
      enum: VentilationWorkingModeEnum,
      enumString: 'ventilation-working-mode-enum',
      value: this.form.value.ventilationWorkingMode,
      validators: [Validators.required],
    };
    const result = await this.dialogsService.select(
      options, title, [],
    );

    if (result?.value != null) {
      this.form.controls['ventilationWorkingMode'].setValue(result.value);
    }
  }

  updateFormValues(generalSettings: IGeneralSettingsView): void {
    if (JSON.stringify(generalSettings) !== JSON.stringify(this.form.value)) {
      this.form.patchValue(generalSettings);
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
