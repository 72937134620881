export enum ElementTypesEnum {
  Cooling = 1,
  HeatingDO,
  HeatingAO,
  SprinklersAndFoggers,
  LightDO,
  LightAO,
  LightDI,
  LightingSensor,
  AirSpeed,
  IndoorTemperature,
  OutdoorTemperature,
  IndoorHumidity,
  OutdoorHumidity,
  Ammonia,
  CO2,
  StaticPressure,
  VentilationDO,
  GasMeter,
  PowerMeter,
  AuxiliaryInput,
  CrossAuger,
  FeederLine,
  WaterMeter,
  VentilationAO,
}

const parentTranslationKey = 'Installation.ElementTypes';

export namespace ElementTypesEnum {
  export function getSvgPath(type: ElementTypesEnum): string {
    switch (type) {
      case ElementTypesEnum.Cooling:
        return 'dashboard/cooling';
      case ElementTypesEnum.HeatingDO:
      case ElementTypesEnum.HeatingAO:
        return 'dashboard/heat';
      case ElementTypesEnum.SprinklersAndFoggers:
        return 'dashboard/sprinklers';
      case ElementTypesEnum.LightAO:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.LightDI:
        return 'dashboard/light';
      case ElementTypesEnum.LightingSensor:
        return 'dashboard/light-sensor';
      case ElementTypesEnum.OutdoorTemperature:
        return 'dashboard/temperature/outdoor-temperature';
      case ElementTypesEnum.IndoorTemperature:
        return 'dashboard/indoor-temp';
      case ElementTypesEnum.IndoorHumidity:
        return 'dashboard/indoor-humidity';
      case ElementTypesEnum.OutdoorHumidity:
        return 'dashboard/outdoor-humidity';
      case ElementTypesEnum.VentilationDO:
      case ElementTypesEnum.VentilationAO:
        return 'dashboard/ventilation';
      case ElementTypesEnum.CO2:
        return 'dashboard/co2';
      case ElementTypesEnum.StaticPressure:
        return 'dashboard/static-pressure';
      case ElementTypesEnum.Ammonia:
        return 'dashboard/ammonia';
      case ElementTypesEnum.AirSpeed:
        return 'dashboard/air-speed';

      // When adding new icons, pay attention if Figma has filled version of the icon, it is hard to notice.
      // If not, you may duplicate the icon & append '-filled' to it's name.
      // Overall looks like most element icons have 2 versions - regular and filled.

      // < !--TODO: NO ICONS-- >
      // case ElementTypesEnum.GasMeter:
      // case ElementTypesEnum.PowerMeter:
      // case ElementTypesEnum.AuxiliaryInput:
      // case ElementTypesEnum.CrossAuger:
      // case ElementTypesEnum.FeederLine:
      // case ElementTypesEnum.WaterMeter:
      default:
        return null;
    }
  }

  export function getSvgPathFilled(type: ElementTypesEnum): string {
    return `${getSvgPath(type)}-filled`;
  }

  export function toTranslateKey(value: number | string | ElementTypesEnum): string {
    let translationKey = '';

    switch (value) {
      case ElementTypesEnum.Cooling:
        translationKey = 'Cooling';
        break;
      case ElementTypesEnum.HeatingDO:
        translationKey = 'HeatingDO';
        break;
      case ElementTypesEnum.HeatingAO:
        translationKey = 'HeatingAO';
        break;
      case ElementTypesEnum.SprinklersAndFoggers:
        translationKey = 'SprinklersAndFoggers';
        break;
      case ElementTypesEnum.LightDO:
        translationKey = 'LightDO';
        break;
      case ElementTypesEnum.LightAO:
        translationKey = 'LightAO';
        break;
      case ElementTypesEnum.LightDI:
        translationKey = 'LightDI';
        break;
      case ElementTypesEnum.LightingSensor:
        translationKey = 'LightingSensor';
        break;
      case ElementTypesEnum.AirSpeed:
        translationKey = 'AirSpeed';
        break;
      case ElementTypesEnum.IndoorTemperature:
        translationKey = 'IndoorTemperature';
        break;
      case ElementTypesEnum.OutdoorTemperature:
        translationKey = 'OutdoorTemperature';
        break;
      case ElementTypesEnum.IndoorHumidity:
        translationKey = 'IndoorHumidity';
        break;
      case ElementTypesEnum.OutdoorHumidity:
        translationKey = 'OutdoorHumidity';
        break;
      case ElementTypesEnum.Ammonia:
        translationKey = 'Ammonia';
        break;
      case ElementTypesEnum.CO2:
        translationKey = 'CO2';
        break;
      case ElementTypesEnum.StaticPressure:
        translationKey = 'StaticPressure';
        break;
      case ElementTypesEnum.VentilationDO:
      case ElementTypesEnum.VentilationAO:
        translationKey = 'Ventilation';
        break;
      case ElementTypesEnum.GasMeter:
        translationKey = 'GasMeter';
        break;
      case ElementTypesEnum.PowerMeter:
        translationKey = 'PowerMeter';
        break;
      case ElementTypesEnum.AuxiliaryInput:
        translationKey = 'AuxiliaryInput';
        break;
      case ElementTypesEnum.CrossAuger:
        translationKey = 'CrossAuger';
        break;
      case ElementTypesEnum.FeederLine:
        translationKey = 'FeederLine';
        break;
      case ElementTypesEnum.WaterMeter:
        translationKey = 'WaterMeter';
        break;
      default:
        return value.toString();
    }

    return `${parentTranslationKey}.${translationKey}`;
  }
}
