import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { InstallationApiService } from '../service/installation-api.service';
import * as installationActions from './installation.actions';
import { catchError, forkJoin, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { IGetOrUpdateElement } from '../interfaces/element/get-or-update-element.interface';
import {
  selectCurrentCardID,
  selectCurrentConnectionID,
  selectCurrentElementID,
  selectCurrentElementType,
} from './installation.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { IElementSettings } from '../interfaces/element/element-settings.interface';
import { IGetMultipleSetupsForElementType } from '../interfaces/element/get-multiple-setups-for-element-type.interface';
import { getElementEndpointByType } from '../enums/element/element-endpoint-by-type.enum';
import { IElementTestingData } from '../interfaces/element/element-testing-interface';

@Injectable()
export class InstallationEffects {
  getControllerCards$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getControllerCards),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.installationService.getControllerCards(controllerID).pipe(
          map(({ items }) => installationActions.getControllerCardsSuccess({ cards: items })),
          catchError((error) => of(installationActions.getControllerCardsError({ error }))),
        );
      }),
    ),
  );

  getCardElementTypes$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getCardElementTypes),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ cardID }, controllerID]) => {
        return this.installationService.getCardElementTypes(controllerID, cardID).pipe(
          map(({ items }) => installationActions.getCardElementTypesSuccess({ elementTypes: items })),
          catchError((error) => of(installationActions.getCardElementTypesError({ error }))),
        );
      }),
    ),
  );

  // ELEMENTS CRUD
  getElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getElement),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
      ),
      switchMap(
        ([{ elementID, elementType, connectionID }, controllerID, cardID]) => {
          const elName = getElementEndpointByType(elementType);
          return forkJoin([
            this.installationService.getElement(controllerID, cardID, connectionID, elementID, elName),
            this.installationService.getMultipleSetupsForElementType(controllerID, cardID, connectionID, elName),
          ]).pipe(
            map(([setupDataForElement, { items }]: [IGetOrUpdateElement, IGetMultipleSetupsForElementType]) => {
              const setupData: IGetOrUpdateElement = {
                ...setupDataForElement,
                otherSetups: items.filter(item => item.elementID !== setupDataForElement.elementID),
              };

              return installationActions.getElementSuccess({
                setupData,
                connectionID,
                elementType,
              });
            }),
            catchError((error) => of(installationActions.getElementError({ payload: error }))),
          );
        },
      ),
    ),
  );

  createElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.createElement),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentConnectionID),
      ),
      switchMap(
        ([{ view, elementType }, controllerID, cardID, connectionID]) => {
          const updatedView = {
            ...view,
            connectionID,
          };

          const elName = getElementEndpointByType(elementType);
          return this.installationService.createElement(controllerID, cardID, connectionID, updatedView, elName).pipe(
            switchMap((setupData) => [
              installationActions.createElementSuccess({ setupData }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.ElementWasAddedToConnection',
                },
              }),
            ]),
            catchError((error) => of(installationActions.createElementError({ payload: error }))),
          );
        },
      ),
    ),
  );

  updateElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.updateElement),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentConnectionID),
      ),
      switchMap(
        ([{ elementID, view, elementType }, controllerID, cardID, connectionID]) => {
          const elName = getElementEndpointByType(elementType);

          return this.installationService.updateElement(controllerID, cardID, connectionID, elementID, view, elName).pipe(
            switchMap((setupData: IGetOrUpdateElement) => [
              installationActions.updateElementSuccess({ setupData }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
            ]),
            catchError((error) => of(installationActions.updateElementError({ payload: error }))),
          );
        },
      ),
    ),
  );

  deleteElement$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.deleteElement),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentConnectionID),
        this.store.select(selectCurrentElementID),
      ),
      switchMap(
        ([{ numberToDelete, elementType }, controllerID, cardID, connectionID, elementID]) => {
          const elName = getElementEndpointByType(elementType);
          return this.installationService.deleteElement(controllerID, cardID, connectionID, elementID, elName).pipe(
            switchMap(() => [
              installationActions.deleteElementSuccess({ numberToDelete }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.ElementWasRemovedFromConnection',
                },
              }),
            ]),
            catchError((error) => of(installationActions.deleteElementError({ payload: error }))),
          );
        },
      ),
    ),
  );

  setNewConnectionSetup$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.setNewConnectionSetup),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentConnectionID),
      ),
      switchMap(
        ([{ elementType }, controllerID, cardID, connectionID]) => {
          const elName = getElementEndpointByType(elementType.elementType);
          return this.installationService.getMultipleSetupsForElementType(controllerID, cardID, connectionID, elName).pipe(
            map(({ items: otherSetups }) => {
              return installationActions.setNewConnectionSetupSuccess({
                otherSetups,
                elementType,
              });
            }),
            catchError((error) => of(installationActions.setNewConnectionSetupError({ payload: error }))),
          );
        }),
    ),
  );

  // ELEMENTS SETTINGS
  getElementSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getElementSettings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(
        ([{ elementType }, controllerID]) => {
          return this.installationService.getElementSettings(controllerID, elementType).pipe(
            map((elementSettings: IElementSettings) => installationActions.getElementSettingsSuccess({ elementSettings })),
            catchError((error) => of(installationActions.getElementSettingsError({ payload: error }))),
          );
        }),
    ),
  );

  updateElementSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.updateElementSettings),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(
        ([{ view, elementType }, controllerID]) => {
          return this.installationService.updateElementSettings(controllerID, view, elementType).pipe(
            switchMap(() => [
              installationActions.updateElementSettingsSuccess({ view }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.ElementSettingsWereSuccessfullyUpdated',
                },
              }),
            ]),
            catchError((error) => of(installationActions.updateElementSettingsError({ payload: error }))),
          );
        }),
    ),
  );

  getElementTestingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.getElementTestingData),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentElementType),
        this.store.select(selectCurrentElementID),
        this.store.select(selectCurrentConnectionID),
      ),
      switchMap(([_, activeControllerID, currentCardID, elementType, currentElementID, connectionID]) => {
        const elName = getElementEndpointByType(elementType);

        return this.installationService
          .getElementTestingData(activeControllerID, currentCardID, connectionID, currentElementID, elName)
          .pipe(
            map((testingData: IElementTestingData) =>
              installationActions.getElementTestingDataSuccess({ testingData }),
            ),
            catchError((error) => of(installationActions.getElementTestingDataError({ payload: error }))),
          );
      }),
    ),
  );

  updateElementTestingData$ = createEffect(() =>
    this.actions$.pipe(
      ofType(installationActions.updateElementTestingData),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentCardID),
        this.store.select(selectCurrentElementType),
        this.store.select(selectCurrentElementID),
        this.store.select(selectCurrentConnectionID),
      ),
      switchMap(([{ testingData }, activeControllerID, currentCardID, elementType, currentElementID, connectionID]) => {
        const elName = getElementEndpointByType(elementType);

        return this.installationService
          .updateElementTestingData(activeControllerID, currentCardID, connectionID, currentElementID, elName, testingData)
          .pipe(
            switchMap((testingData: IElementTestingData) => [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.ElementTestingDataSaveSuccess',
                },
              }),
              installationActions.getElementTestingDataSuccess({ testingData }),
            ]),
            catchError((error) => of(installationActions.getElementTestingDataError({ payload: error }))),
          );
      }),
    ),
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private installationService: InstallationApiService,
  ) {
  }
}
