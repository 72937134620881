import { ControllerUnitEnum } from '@livestock/controllers/enums';
import {
  FlockHelper,
  FlockState,
} from '@livestock/flock';
import { TemperatureUnitEnum, WeightUnitEnum } from '@livestock/shared/enums';
import { MemoizedSelector, createFeatureSelector, createSelector } from '@ngrx/store';
import {
  selectCurrentControllerWeightUnit,
} from 'libs/controllers/src/lib/+state/current-controller/current-controller.selectors';

export const FLOCK_FEATURE_KEY = 'flock';

export const getFlockState =
  createFeatureSelector<FlockState>(FLOCK_FEATURE_KEY);

export const selectIsLoading = createSelector(
  getFlockState,
  ({ isLoading }) => isLoading,
);

export const selectIsDefaultWeightLoading = createSelector(
  getFlockState,
  ({ isDefaultWeightLoading }) => isDefaultWeightLoading,
);

export const selectFlock = createSelector(
  getFlockState,
  (state) => {
    return {
      flockSettings: state.settings,
      flockHouseMode: state.houseMode,
      flockBirdWeightSettings: state.birdWeightSettings,
      flockBirdWeights: state.birdWeightView?.items,
    };
  },
);

export const selectDefaultWeightItems = createSelector(
  getFlockState,
  ({ defaultWeightItems }) => defaultWeightItems,
);

export const selectFlockSettings = createSelector(
  getFlockState,
  ({ settings }) => settings,
);

export const selectFlockSettingsForNewFlock = createSelector(
  getFlockState,
  ({ settings }) => {
    return {
      ...settings,
      isNewFlock: true,
    };
  },
);

export const selectFlockHouseMode = createSelector(
  getFlockState,
  ({ houseMode }) => houseMode,
);

export const selectFlockBirdWeightSettings = createSelector(
  getFlockState,
  ({ birdWeightSettings }) => birdWeightSettings,
);

export const selectFlockBirdWeightItems = createSelector(
  getFlockState,
  ({ birdWeightView }) => birdWeightView?.items,
);

export const selectFlockBirdWeightView = createSelector(
  getFlockState,
  ({ birdWeightView }) => birdWeightView,
);

export const selectSeparateMaleAndFemale = createSelector(
  getFlockState,
  ({ birdWeightView }) => birdWeightView?.separateMaleAndFemale,
);

export const selectIsFlockFinished = createSelector(
  getFlockState,
  ({ isFlockFinished }) => isFlockFinished,
);

export const selectFlockCreationYear = createSelector(
  getFlockState,
  //2024-04-01T07:17:20.12 => 2024
  ({ settings }) => settings?.creationDate?.split('-')[0] || new Date().getFullYear(),
);

export const selectTemperatureUnit = createSelector(
  selectFlockSettings,
  (settings) => {
    switch (settings?.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      default:
        return settings?.unitsDetails?.temperature;
    }
  },
);

export const selectWeightUnit = createSelector(
  selectFlockSettings,
  (settings) => {
    switch (settings?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Gram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return settings?.unitsDetails?.weight;
    }
  },
);

export const selectReferenceWeight =
  (day: number): MemoizedSelector<any, any, any> =>
    createSelector(
      selectDefaultWeightItems,
      selectCurrentControllerWeightUnit,
      (defaultWeightItems, weightUnit) => {
        if (day == null) {
          return null;
        }
        const defaultWeight = defaultWeightItems.find(x => x.day == day);
        if (defaultWeight == null) {
          return null;
        }

        return {
          weight: FlockHelper.getWeight(defaultWeight, weightUnit),
          femaleWeight: FlockHelper.getFemaleWeight(defaultWeight, weightUnit),
          maleWeight: FlockHelper.getMaleWeight(defaultWeight, weightUnit),
        };
      },
    );

export const selectReferenceTable = createSelector(
  getFlockState,
  ({ birdWeightSettings }) => birdWeightSettings?.defaultWeightBrandID,
);
