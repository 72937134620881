<ls-page-wrapper
  (back)="backRedirect()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'Controller.VentilationBasicSettings.BasicVentilationSettings'">
  <div
    [class.disabled]="editMode"
    class="svg-content header__buttons d-flex align-center justify-between-md">
    <ls-svg-icon
      (click)="editMode = true"
      class="icon pointer qa-btn-edit-mode small-md"
      path="installation/edit"></ls-svg-icon>
  </div>
  <div class="main-content h-100-percent d-flex flex-column overflow-auto">
    <form
      *ngIf="!(isLoading$ | async)"
      [class.mb-10]="platformService.isMobileApp && editMode"
      [class.rtl]="languageService.isRtl"
      [formGroup]="form"
      class="form">
      <div [class.disabled]="!editMode">
        <div class="block mw-250">
          <span>{{ "Controller.VentilationBasicSettings.MinimumVentilation" | translate }}</span>
          <div class="controls">
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.WorkingWithCurve" | translate }}</span>
              <ls-toggle
                [offTitle]="'Buttons.No'"
                [onTitle]="'Buttons.Yes'"
                [qaTags]="'qa-tog-enabled'"
                [size]="'large'"
                formControlName="isWorkingWithCurve" />
            </div>
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.AllowingRotation" | translate }}</span>
              <ls-toggle
                [offTitle]="'Buttons.No'"
                [onTitle]="'Buttons.Yes'"
                [qaTags]="'qa-tog-enabled'"
                [size]="'large'"
                formControlName="isAllowingRotation" />
            </div>
            <div
              *ngIf="form.controls['isAllowingRotation'].value"
              class="control">
              <span>{{ "Controller.VentilationBasicSettings.NumberOfFansThatWorkInRotation" | translate }}</span>
              <ls-input-integer
                [fieldWithKeyboard]="true"
                [max]="BasicVentilationConstants.maxNumberOfFans"
                [min]="BasicVentilationConstants.minNumberOfFans"
                [validateWithInitValue]="true"
                class="w-input-80"
                errorField
                formControlName="inRotationFansNumber">
              </ls-input-integer>
            </div>
          </div>
        </div>
        <div class="block">
          <span>{{ "Controller.VentilationBasicSettings.TunnelVentilation" | translate }}</span>
          <div class="controls">
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.AfterHowManyFansYouMoveToTunnel" | translate }}</span>
              <ls-input-integer
                [fieldWithKeyboard]="true"
                [validateWithInitValue]="true"
                class="w-input-80"
                errorField
                formControlName="movingToTunnelsFansNumber">
              </ls-input-integer>
            </div>
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.MinimumTimeInTunnel" | translate }}</span>
              <div class="postfix">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  class="w-input-80"
                  formControlName="minTimeInTunnel">
                </ls-input-integer>
                <span>{{ "Controller.VentilationBasicSettings.Min" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="block">
          <span>{{ "Controller.VentilationBasicSettings.InletTunnelDoorCalibration" | translate }}</span>
          <div class="controls">
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.EnableCalibration" | translate }}</span>
              <ng-select
                [clearable]="false"
                [formControlName]="'calibration'"
                [searchable]="false">
                <ng-option
                  *ngFor="let el of CalibrationModeEnum | enumToArray"
                  [value]="el">
                  {{ el | enum : "calibration-mode" | translate }}
                </ng-option>
              </ng-select>
            </div>
            <div
              *ngIf="form.controls['calibration']?.value === CalibrationModeEnum.Custom"
              class="group">
              <div class="control">
                <span>{{ "Controller.VentilationBasicSettings.StartAt" | translate }}</span>
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  class="w-input-60"
                  formControlName="start">
                </ls-input-integer>
              </div>
              <div class="control">
                <span>{{ "Controller.VentilationBasicSettings.EndAt" | translate }}</span>
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  class="w-input-60"
                  formControlName="end">
                </ls-input-integer>
              </div>
            </div>
            <div class="control">
              <span>{{ "Controller.VentilationBasicSettings.NumberOfSteps" | translate }}</span>
              <ls-input-integer
                [fieldWithKeyboard]="true"
                class="w-input-80"
                formControlName="stepsNumber">
              </ls-input-integer>
            </div>
          </div>
        </div>
      </div>
    </form>
    <div
      *ngIf="editMode"
      class="buttons-content">
      <ls-button
        (click)="cancel()"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-20 w-130">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="save()"
        [class.disabled]="!form.dirty"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'"
        class="w-130">
        {{ "Buttons.Save" | translate }}
      </ls-button>
    </div>
  </div>

  <ls-keyboard
    *ngIf="editMode && !platformService.isMobileApp"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20 mt-auto" />
</ls-page-wrapper>
