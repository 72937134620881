<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-20">
    <label qaTags="qa-label-name">{{ "Installation.Main.DeviceName" | translate }}</label>

    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-unit-name'"
        errorField
        formControlName="name"></ls-input-text>
      <!-- copy btn -->
      <ng-content></ng-content>
    </div>
  </div>

  <div class="device-form__field mb-20">
    <label>{{ "Installation.Card.LightAO.Min" | translate }}</label>
    <div class="d-flex flex-row align-center">
      <div>
        <ls-input-decimal
          [accuracy]="1"
          [fieldWithKeyboard]="true"
          [max]="ElementsValidationConstants.lightingAO.minVoltage.max"
          [min]="ElementsValidationConstants.lightingAO.minVoltage.min"
          [qaTags]="'qa-txt-min-voltage'"
          [validateWithInitValue]="true"
          errorField
          formControlName="minVoltage">
        </ls-input-decimal>
      </div>
      <span class="unit ml-5">{{ "Installation.Card.LightAO.Voltage" | translate }}</span>
    </div>
  </div>

  <div class="device-form__field mb-20">
    <label>{{ "Installation.Card.LightAO.Max" | translate }}</label>
    <div class="d-flex flex-row align-center">
      <div>
        <ls-input-decimal
          [accuracy]="1"
          [fieldWithKeyboard]="true"
          [max]="ElementsValidationConstants.lightingAO.maxVoltage.max"
          [min]="ElementsValidationConstants.lightingAO.maxVoltage.min"
          [qaTags]="'qa-txt-max-voltage'"
          [validateWithInitValue]="true"
          errorField
          formControlName="maxVoltage">
        </ls-input-decimal>
      </div>
      <span class="unit ml-5">{{ "Installation.Card.LightAO.Voltage" | translate }}</span>
    </div>
  </div>
</div>
