<form
  *ngIf="!isLoading; else loading"
  [class.rtl]="languageService.isRtl"
  [formGroup]="form"
  autocomplete="off"
  novalidate>
  <div></div>
</form>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
