import { Injectable } from '@angular/core';
import { firstValueFrom } from 'rxjs';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import {
  resetTemperatureMappings, selectBrooding2IsEmpty,
  selectIsDirtyForm,
} from '@livestock/temp-mapping';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class TempMappingHelpersService {
  constructor(
    private dialogsService: DialogsService,
    private store: Store,
    private platformService: PlatformService,
  ) {
  }

  async confirmUnsavedChanges(): Promise<boolean> {
    const isDirtyForm = await firstValueFrom(this.store.select(selectIsDirtyForm));
    const brooding2IsEmpty = await firstValueFrom(this.store.select(selectBrooding2IsEmpty)) && !this.platformService.isMobileApp;

    if (isDirtyForm || brooding2IsEmpty) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }

      this.store.dispatch(resetTemperatureMappings());
    }

    return true;
  }
}
