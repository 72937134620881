export enum QuickStartStepsEnum {
  GeneralSettings = 1,
  HouseSettings = 2,
  NetworkSettings = 3,
  DateTimeSettings = 4,
  CloudSettings = 5,
  FlockSettings = 6,
  HouseModeSettings = 7,
  ConnectionsSettings = 8,
}
