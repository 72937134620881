export const AppRoutes = {
  AUTH: 'auth',
  LOGIN: 'login',
  CREATE_FARM: 'create-farm',
  CONTROLLER: 'controller',
  DASHBOARD: 'dashboard',
  FARM: 'farm',
  FARMS: 'farms',
  FARMS_MANAGER: 'farms-manager',
  QUICK_START: 'quick-start',
  SCANNED_LOGIN: 'scanned-login',
  SIDE_MENU: 'side-menu',
  USERS: 'users',
  VALIDATE: 'validate',
  INSTALLATION: 'installation',
  NEW_FLOCK: 'new-flock',
  FORGOT_PASSWORD: 'forgot-password',
  LANDING: 'landing',
};
