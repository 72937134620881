import { IFlockDefaultWeightView, IGetFlockBirdWeightView } from '@livestock/flock';
import {
  IFlockBirdWeightSettingsView,
  IFlockHouseModeView,
  IFlockSettingsView,
} from '@livestock/flock';
import { IFlockView } from '../interfaces';

export interface FlockState {
  isLoading: boolean;
  defaultWeightItems: IFlockDefaultWeightView[];
  isDefaultWeightLoading: boolean;
  settings: IFlockSettingsView;
  houseMode: IFlockHouseModeView;
  birdWeightSettings: IFlockBirdWeightSettingsView;
  birdWeightView: IGetFlockBirdWeightView;
  isFlockFinished: boolean;

  flock?: IFlockView;
}
