import { CommonModule } from '@angular/common';
import { Component, OnInit, OnDestroy } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import {
  EmailValidator,
  PasswordValidator,
  RequiredTrimValidator,
} from '@livestock/shared/validators';
import { Router, RouterModule, ActivatedRoute } from '@angular/router';
import {
  ButtonComponent,
  InputComponent,
  SvgIconComponent,
  VerificationCodeComponent,
} from '@livestock/ui';
import { SecurityTicketService } from '../../services/security-ticket.service';
import { AuthService } from '../../services/auth.service';
import { take, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  FlashMessageTypeEnum,
  setFlashMessage,
} from '@livestock/notifications';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { IconsEnum, ColorsEnum } from '@livestock/shared/enums';
import { LVInputComponent } from '@livestock/ui';
import { GlobalConstants } from '@livestock/shared/constants';
import { PlatformService } from '@livestock/shared/services';
import { PasswordGeneratorUtils } from '@livestock/shared/utils';
import { ClipboardModule, Clipboard } from '@angular/cdk/clipboard';
import { AppRoutes } from '@livestock/shared/routes';

@Component({
  selector: 'ls-forgot-password',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ClipboardModule,
    InputComponent,
    ButtonComponent,
    RouterModule,
    TranslateModule,
    QaTagsDirective,
    SvgIconComponent,
    LVInputComponent,
    VerificationCodeComponent,
  ],
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss'],
})
export class ForgotPasswordComponent implements OnInit, OnDestroy {
  readonly sub$ = new Subscription();
  currStep = 1;
  isLoading = false;
  isDisabledResendCode = false;
  disableResendCodeTimeMs = 30 * 1000;
  validateForm = false;
  form = new FormGroup(
    {
      email: new FormControl('', [RequiredTrimValidator, EmailValidator]),
      verificationCode: new FormControl('', [RequiredTrimValidator]),
      password: new FormControl('', [
        Validators.required,
        Validators.minLength(8),
        PasswordValidator,
      ]),
      ticketID: new FormControl('', [Validators.required]),
    },
  );

  IconsEnum = IconsEnum;
  GlobalConstants = GlobalConstants;
  ColorsEnum = ColorsEnum;
  AppRoutes = AppRoutes;

  constructor(
    public platformService: PlatformService,
    private securityTicketService: SecurityTicketService,
    private authService: AuthService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private clipboard: Clipboard,
  ) {

  }
  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.subscribe(() => {
        this.validateForm = false;
      }),
    );
  }

  navigateTo(route: string): void {
    this.router.navigate([`auth/${route}`]);
  }

  onSendVerificationCode(): void {
    if (!this.form.get('email')?.value || this.isLoading) {
      this.validateForm = true;
      return;
    }

    if (this.form.get('email')?.invalid) {
      this.form.get('email')?.markAsTouched();
      this.form.get('email')?.markAsDirty();
      this.form.get('email')?.updateValueAndValidity();
      this.validateForm = true;
      return;
    }

    this.isLoading = true;
    this.securityTicketService
      .forgotPassword({
        email: this.form.value.email ?? '',
      })
      .subscribe({
        next: (res) => {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.VerificationCodeSentNow',
              },
            }),
          );
          this.form.get('ticketID')?.setValue(res.ticketID);
          this.isLoading = false;
          this.currStep += 1;
          this.setStepToTheRoute(this.currStep);
        },
        error: () => (this.isLoading = false),
      });
  }

  setStepToTheRoute(currStep: number): void {
    this.router.navigate([], {
      relativeTo: this.activatedRoute,
      queryParams: {
        step: currStep,
      },
      queryParamsHandling: 'merge',
      skipLocationChange: true,
    });
  }

  onSendNewCode(): void {
    if (this.form.get('email')?.invalid) return;
    this.isLoading = true;
    this.isDisabledResendCode = true;
    this.securityTicketService
      .forgotPassword({
        email: this.form.value.email ?? '',
      })
      .subscribe({
        next: (res) => {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.VerificationCodeSentNow',
              },
            }),
          );
          this.form.get('ticketID')?.setValue(res.ticketID);
          this.isLoading = false;
          setTimeout(() => {
            this.isDisabledResendCode = false;
          }, this.disableResendCodeTimeMs);
        },
        error: () => {
          this.isLoading = false;
          this.isDisabledResendCode = false;
        },
      });
  }

  onVerificationCode(): void {
    if (!this.form.get('verificationCode').value) {
      return;
    }

    this.isLoading = true;
    this.securityTicketService
      .verifyForgotPassword({
        ticketID: this.form.value.ticketID || '',
        code: this.form.value.verificationCode.trim() || '',
      })
      .subscribe({
        next: () => {
          this.isLoading = false;
          this.currStep += 1;
          this.setStepToTheRoute(this.currStep);
        },
        error: (error) => {
          this.isLoading = false;
          this.form.controls.verificationCode.setValue('');
          if (error?.error?.trim() == GlobalConstants.TICKET_EXPIRED_ERROR) {
            this.form.controls.verificationCode.setErrors({ expired: true });
          } else {
            this.form.controls.verificationCode.setErrors({ wrong: true });
          }

          this.form.valueChanges.pipe(take(1)).subscribe(() => {
            this.form.controls.verificationCode.setErrors(null);
          });
        },
      });
  }

  onSave(): void {
    if (this.form.invalid) return;
    const { password, ticketID } = this.form.value;
    if (password == null || ticketID == null) return;
    this.authService.resetPassword({ password, ticketID }).subscribe(() => {
      this.isLoading = false;
      this.store.dispatch(
        setFlashMessage({
          flashMessage: {
            flashType: FlashMessageTypeEnum.Success,
            message: 'FlashMessages.CongratulationsYourPasswordHasBeenUpdated',
          },
        }),
      );
      this.router.navigate(['/']);
    });
  }

  generatePassword(event: Event): void {
    event.stopPropagation();
    const password = PasswordGeneratorUtils.generatePassword(GlobalConstants.STRONG_PASSWORD_LENGTH);
    this.form.controls.password.setValue(password);
    this.form.controls.password.markAsDirty();
    this.form.controls.password.updateValueAndValidity();
  }

  copyPassword(event: Event): void {
    event.stopPropagation();
    this.clipboard.copy(this.form.controls.password.value);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
