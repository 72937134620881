import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  selector: 'ls-ventilation-widget',
  templateUrl: './ventilation-widget.component.html',
  styleUrls: ['./ventilation-widget.component.scss'],
})
export class VentilationWidgetComponent {
  steps: number[] = [2, 6, 10];
  ColorsEnum = ColorsEnum;
}
