import { NgxMatTimepickerComponent } from '@angular-material-components/datetime-picker';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  SimpleChanges,
  ViewChildren,
  inject,
} from '@angular/core';
import { Observable, Subscription, filter, first, tap } from 'rxjs';
import { Store } from '@ngrx/store';

import { IElementSettings } from 'libs/installation/src/lib/interfaces/element/element-settings.interface';
import { greaterThanValidator, lessThanValidator } from '@livestock/shared/validators';
import { KeyboardEnum } from '@livestock/shared/enums';
import {
  selectElementSettings,
} from 'libs/installation/src/lib/+state/installation.selectors';
import { GlobalConstants } from '@livestock/shared/constants';
import { PlatformService } from '@livestock/shared/services';
import {
  KeyboardModeEnum,
  clearKeyboardValue,
  selectKeyboardValueAndUUID,
  setElementUuid,
  setKeyboardMode,
} from '@livestock/ui';
import { HoursFormatTypeEnum } from '@livestock/controllers';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-lighting-settings-form',
  template: `
    <ng-container *ngIf="platformService.isMobileApp; else desktop">
      <ls-lighting-settings-form-mobile [formGroupName]="formGroupName"
                                        [hoursFormat]="hoursFormat"
                                        [isEditMode]="isEditMode"
                                        [isResetForm$]="isResetForm$"
                                        [isSaveForm$]="isSaveForm$"
                                        (isTimePickerSelected)="isTimePickerSelected.emit($event)"></ls-lighting-settings-form-mobile>
    </ng-container>
    <ng-template #desktop>
      <ls-lighting-settings-form-desktop [formGroupName]="formGroupName"
                                         [hoursFormat]="hoursFormat"
                                         [isEditMode]="isEditMode"
                                         [isResetForm$]="isResetForm$"
                                         [isSaveForm$]="isSaveForm$"
                                         (isTimePickerSelected)="isTimePickerSelected.emit($event)"></ls-lighting-settings-form-desktop>
    </ng-template>
  `,
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class LightingSettingsFormBaseComponent implements OnInit, OnDestroy, AfterViewInit, OnChanges {
  @ViewChildren('timepicker') timepickerRef: QueryList<NgxMatTimepickerComponent<any>>;

  @Input({ required: true }) formGroupName: string;
  @Input() isEditMode: boolean;
  @Input() isResetForm$: Observable<boolean>;
  @Input() isSaveForm$: Observable<boolean>;
  @Input() hoursFormat: HoursFormatTypeEnum;
  @Output() isTimePickerSelected: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() setIsDirty: EventEmitter<boolean> = new EventEmitter();

  // subs
  sub$: Subscription = new Subscription();

  /* component vars */
  parentFormContainer = inject(ControlContainer);
  originalValues;
  currentTimeUUID: string;
  lightingSensorElements: IElementSettings['lightingSensorElements'];

  // enums
  GlobalConstants = GlobalConstants;
  ElementsValidationConstants = ElementsValidationConstants;
  HoursFormatTypeEnum = HoursFormatTypeEnum;

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  constructor(public platformService: PlatformService, private store: Store) {
  }

  ngOnInit(): void {
    this.store
      .select(selectElementSettings)
      .pipe(
        filter((res) => res != null),
        first(),
      )
      .subscribe(({ lightAOSettings, lightingSensorSettings, lightingSensorElements }) => {
        this.lightingSensorElements = lightingSensorElements;
        const settingsFG: FormGroup = new FormGroup({});

        if (lightingSensorSettings) {
          settingsFG.addControl(
            'lightingSensorSettings',
            this.createLightingSensorSettingsForm(lightingSensorSettings),
          );
        }
        if (lightAOSettings) {
          settingsFG.addControl('lightAOSettings', this.createLightAOSettingsForm(lightAOSettings));
        }
        settingsFG.disable();
        this.parentFormGroup.addControl(this.formGroupName, settingsFG);
        this.originalValues = { ...settingsFG.getRawValue() };
      });

    this.sub$.add(
      this.isResetForm$.subscribe((val) => {
        if (val) {
          const group = this.parentFormGroup.get(this.formGroupName);
          group.patchValue({ ...this.originalValues });
          group.updateValueAndValidity({ emitEvent: false });
        }
      }),
    );

    this.sub$.add(
      this.isSaveForm$.subscribe((val) => {
        if (val) {
          const group = this.parentFormGroup.get(this.formGroupName);
          this.originalValues = group.getRawValue();
          group.patchValue({ ...this.originalValues });
          group.updateValueAndValidity({ emitEvent: false });
        }
      }),
    );

    this.sub$.add(
      this.parentFormGroup.get(this.formGroupName).valueChanges.subscribe((items) => {
        this.setIsDirty.emit(JSON.stringify(items) !== JSON.stringify(this.originalValues));
      }),
    );
  }

  ngAfterViewInit(): void {
    this.initTimePickerFocus();
    this.updateIds();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isEditMode' in changes && changes['isEditMode'].currentValue !== changes['isEditMode'].previousValue) {
      this.parentFormGroup?.get(this.formGroupName)?.[this.isEditMode ? 'enable' : 'disable']({ emitEvent: false });
    }
  }

  setFocusedTimepicker(e): void {
    if (this.platformService.isMobileApp) return;

    const uuid = e.target.getAttribute('uuid');
    this.currentTimeUUID = uuid;

    if (!uuid) return;

    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));

    setTimeout(() => {
      this.isTimePickerSelected.emit(true);
    });

    this.store.dispatch(setElementUuid({ elementUuid: uuid }));
  }

  isFocused([timepicker, uuid]: [NgxMatTimepickerComponent<any>, string]): boolean {
    return timepicker?.uuids?.includes(uuid);
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    this.sub$.unsubscribe();
    this.isTimePickerSelected.emit(false);
  }

  private createLightingSensorSettingsForm(settings: IElementSettings['lightingSensorSettings']): FormGroup {
    return new FormGroup({
      lightIntensityAlarmEnabled: new FormControl<boolean>(settings.lightIntensityAlarmEnabled, [Validators.required]),
      minimumLightIntensityForAlarm: new FormControl<number>(settings.minimumLightIntensityForAlarm, [
        Validators.required,
      ]),
      compensationLightingEnabled: new FormControl<boolean>(settings.compensationLightingEnabled, [
        Validators.required,
      ]),
      compensationLightingSensorElementID: new FormControl<number>(settings.compensationLightingSensorElementID, [
        Validators.required,
      ]),
    });
  }

  private createLightAOSettingsForm(settings: IElementSettings['lightAOSettings']): FormGroup {
    return new FormGroup({
      boostEnabled: new FormControl<boolean>(settings.boostEnabled, [Validators.required]),
      boostDurationSec: new FormControl<number>(settings.boostDurationSec, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.boost.duration.min),
        Validators.max(ElementsValidationConstants.lightingSettings.boost.duration.max),
      ]),
      boostIntensity: new FormControl<number>(settings.boostIntensity, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.boost.intensity.min),
        Validators.max(ElementsValidationConstants.lightingSettings.boost.intensity.max),
      ]),
      dimmingTimeMin: new FormControl<number>(settings.dimmingTimeMin, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.dimming.min),
        Validators.max(ElementsValidationConstants.lightingSettings.dimming.max),
      ]),
      dimmingStartTime: new FormControl(
        settings.dimmingStartTime,
        [Validators.required],
      ),
      spikeCycleMin: new FormControl<number>(settings.spikeCycleMin, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.spike.cycle.min),
        Validators.max(ElementsValidationConstants.lightingSettings.spike.cycle.max),
        greaterThanValidator('spikeDurationMin'),
      ]),
      spikeDurationMin: new FormControl<number>(settings.spikeDurationMin, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.spike.duration.min),
        Validators.max(ElementsValidationConstants.lightingSettings.spike.duration.max),
        lessThanValidator('spikeCycleMin'),
      ]),
      spikeIncreaseAmount: new FormControl<number>(settings.spikeIncreaseAmount, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.spike.increase.min),
        Validators.max(ElementsValidationConstants.lightingSettings.spike.increase.max),
      ]),
      spikeEnabled: new FormControl<boolean>(settings.spikeEnabled, [Validators.required]),
      sunriseSunsetMinToDimm: new FormControl<number>(settings.sunriseSunsetMinToDimm, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.sunriseSunset.minToDimm.min),
        Validators.max(ElementsValidationConstants.lightingSettings.sunriseSunset.minToDimm.max),
      ]),
      sunriseSunsetTimeDurationMin: new FormControl<number>(settings.sunriseSunsetTimeDurationMin, [
        Validators.required,
        Validators.min(ElementsValidationConstants.lightingSettings.sunriseSunset.duration.min),
        Validators.max(ElementsValidationConstants.lightingSettings.sunriseSunset.duration.max),
      ]),
    });
  }

  private initTimePickerFocus(): void {
    if (this.platformService.isMobileApp) return;

    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));

    this.sub$.add(
      this.store
        .select(selectKeyboardValueAndUUID)
        .pipe(
          tap(({ elementUuid }) => {
            this.currentTimeUUID = elementUuid;
          }),
          filter(({ symbol, elementUuid }) => {
            const isTimePickerUUID = symbol != null && this.checkIfTimePickerUUID(elementUuid);
            this.isTimePickerSelected.emit(isTimePickerUUID);
            return isTimePickerUUID;
          }),
        )
        .subscribe(({ symbol, elementUuid }) => {
          const focusedControl = document.activeElement as HTMLInputElement;

          if (!focusedControl) {
            this.isTimePickerSelected.emit(false);
            return;
          }

          this.isTimePickerSelected.emit(true);

          if (symbol.toString().includes(KeyboardEnum.Enter)) {
            this.focusControl(elementUuid);
            return;
          }

          const timepickerControlName = focusedControl.getAttribute('formcontrolname');
          if (!timepickerControlName) return;

          const ref = this.timepickerRef.find((i) => i.uuids.includes(elementUuid));

          if (symbol === KeyboardEnum.AM || symbol === KeyboardEnum.PM) {
            ref.meridian = symbol === KeyboardEnum.PM ? KeyboardEnum.PM : KeyboardEnum.AM;
            return;
          }

          const { selectionStart, selectionEnd } = focusedControl;
          const currentValue = ref.form.controls[timepickerControlName].value.toString().split('');
          const isSingleDeletion = selectionStart === selectionEnd && symbol === '' && selectionStart > 0;
          const isMultipleDeletion = symbol === '' && selectionStart !== selectionEnd;

          if (isSingleDeletion) {
            currentValue.splice(selectionStart - 1, 1);
          } else {
            currentValue.splice(selectionStart, selectionEnd - selectionStart, symbol as string);
          }

          let parsedValue = currentValue.join('').slice(0, 2);

          if (this.hoursFormat === HoursFormatTypeEnum.AmPm && timepickerControlName === 'hour') {
            parsedValue = parsedValue % 12;
          }

          ref.form.controls[timepickerControlName].setValue(parsedValue);
          this.store.dispatch(clearKeyboardValue());

          if (isSingleDeletion) {
            focusedControl.selectionStart = selectionStart - 1;
            focusedControl.selectionEnd = selectionStart - 1;
          } else if (isMultipleDeletion) {
            focusedControl.selectionStart = selectionStart;
            focusedControl.selectionEnd = selectionStart;
          } else {
            focusedControl.selectionStart = selectionStart + 1;
            focusedControl.selectionEnd = selectionStart + 1;
          }
        }),
    );
  }

  private checkIfTimePickerUUID(elementUuid: string): boolean {
    return this.timepickerRef.reduce((acc, i) => [...acc, ...i.uuids], []).includes(elementUuid);
  }

  private focusControl(property: string): void {
    const control = document.querySelector(`[uuid=${property}]`) as HTMLInputElement;
    control.focus();
    control.setSelectionRange(0, 0);
  }

  private updateIds(): void {
    if (this.platformService.isMobileApp) return;

    setTimeout(() => {
      const inputIds = Array.from(document.querySelectorAll('ngx-mat-timepicker')).map((domTimepicker) =>
        Array.from(domTimepicker.querySelectorAll('input.mat-mdc-input-element')).map((inputElement) =>
          inputElement.getAttribute('uuid'),
        ),
      );

      if (!inputIds?.length) return;

      this.timepickerRef.forEach((timeinputObject, index) => {
        timeinputObject.uuids = inputIds[index];
      });
    }, 1000);
  }
}
