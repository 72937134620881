import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Store } from '@ngrx/store';
import {
  CreateFarmComponent,
  createFarmSuccess,
  getFarmById,
  getFarms,
  IFarm,
  selectActiveFarm,
  selectFarms,
} from '@livestock/farms';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  LoadingComponent,
  StepperComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { selectControllerInfo, selectTicketId } from '@livestock/login-scanned';
import { Router } from '@angular/router';
import { filter, first, map, switchMap, tap } from 'rxjs/operators';
import { ColorsEnum, ViewModeEnum } from '@livestock/shared/enums';
import { combineLatest, Observable, Subscription } from 'rxjs';
import { Actions, ofType } from '@ngrx/effects';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutes } from '@livestock/shared/routes';
import {
  AddHouseNumberDialogComponent,
  clearIsControllerCreatedOrExecuted,
  executeTicketController,
  selectIsControllerTicketExecuted, selectIsLoading,
} from '@livestock/controllers';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { GlobalConstants } from '@livestock/shared/constants';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'ls-scanned-login',
  standalone: true,
  imports: [
    CommonModule,
    StepperComponent,
    ButtonComponent,
    SvgIconComponent,
    ButtonWithIconComponent,
    CreateFarmComponent,
    TranslateModule,
    LoadingComponent,
  ],
  templateUrl: './scanned-login.component.html',
  styleUrls: ['./scanned-login.component.scss'],
})
export class ScannedLoginComponent implements OnInit, OnDestroy {
  //subs
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  farms$ = this.store.select(selectFarms);
  user$ = this.http.get(`${environment.apiUrl}/user/current`).subscribe(i => this.user = i);
  controller$ = combineLatest([
    this.store.select(selectTicketId),
    this.store.select(selectControllerInfo),
  ]).pipe(
    tap(([tickedId]) => {
      this.tickedId = tickedId;
      if (!tickedId) {
        this.router.navigate([AppRoutes.FARMS]);
      }
    }),
    map(([_, controllerInfo]) => controllerInfo),
  );

  //vars
  user: any;
  currStep = 1;
  selected: IFarm | null = null;
  mode: 'select' | 'create' = 'select';
  tickedId: string | null = null;

  //enums
  ColorsEnum = ColorsEnum;
  ViewModeEnum = ViewModeEnum;

  constructor(
    public router: Router,
    public actions: Actions,
    private store: Store,
    private http: HttpClient,
    private dialog: MatDialog,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getFarms());

    this.actions
      .pipe(
        ofType(createFarmSuccess),
        first(),
        switchMap(() => this.store.select(selectActiveFarm)),
        filter(Boolean),
      ).subscribe(i => this.assign(i));

    this.sub$.add(
      this.store.select(selectIsControllerTicketExecuted).pipe(
        wasChanged(),
      ).subscribe(({ isControllerTicketExecuted, creatingControllerError }) => {
        if (creatingControllerError?.includes(GlobalConstants.HouseNumberAlreadyExistsError)) {
          this.openChooseHouseNumberPopup(creatingControllerError);
          return;
        }

        if (isControllerTicketExecuted) {
          this.store.dispatch(clearIsControllerCreatedOrExecuted());
          this.currStep = 3;
        }
      }),
    );
  }

  openChooseHouseNumberPopup(creatingControllerError: string): void {
    const currentHouseNumber = +creatingControllerError
      .split('')
      .filter(symbol => !isNaN(+symbol))
      .join('');

    const usedHouseNumbers = this.selected?.existingHouseNumbers || [];

    this.dialog.open(AddHouseNumberDialogComponent, {
      width: '500px',
      panelClass: 'popup-full__mobile',
      disableClose: true,
      data: {
        usedHouseNumbers,
        currentHouseNumber,
        ticketID: this.tickedId,
        farmID: this.selected?.farmID,
      },
    });
  }

  assign(selected: IFarm): void {
    if (!selected) return;

    this.store.dispatch(getFarmById({ farmID: selected?.farmID }));
    this.store.dispatch(executeTicketController({
      requestedView: {
        farmID: selected.farmID,
      },
      ticketID: this.tickedId,
    }));
  }

  selectFarmFromList(farm: IFarm): void {
    this.selected = farm;
    this.store.dispatch(getFarmById({ farmID: farm?.farmID }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
