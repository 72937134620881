<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'Flock.BirdWeight.BirdWeight'">
  <div
    *ngIf="!editMode || !platformService.isMobileApp"
    class="svg-content header__buttons d-flex align-center">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      class="icon pointer ml-20 qa-btn-edit-mode">
    </ls-svg-icon>
    <ls-svg-icon
      (click)="redirectToGraph()"
      [class.disabled]="editMode"
      [color]="ColorsEnum.Default"
      [path]="'temperature-curve/chart'"
      class="icon pointer ml-20 qa-btn-edit-mode">
    </ls-svg-icon>
  </div>
  <div class="main-content settings__form d-flex flex-row flex-column-md position-relative">
    <div
      *ngIf="!platformService.isMobileApp"
      class="pseudo-loading">
      <ls-loading></ls-loading>
    </div>

    <ls-flock-bird-weight-settings-form
      (changed)="changedBirdWeightSettings($event)"
      [birdWeightSettings]="originalBirdWeightSettings"
      [editMode]="editMode"
      [isLoading]="isLoading$ | async"
      [refWeight]="refWeight$ | async"
      [separateMaleAndFemale]="separateMaleAndFemale$ | async"
      [weightUnit]="weightUnit$ | async"
      class="settings__form-bird-weight-settings">
    </ls-flock-bird-weight-settings-form>

    <ls-flock-bird-weight-form
      (activeDayChanged)="activeDayChanged($event)"
      (changed)="changedBirdWeight($event)"
      [birdWeightItems]="birdWeightItems"
      [defaultWeightItems]="defaultWeightItems$ | async"
      [editMode]="editMode"
      [referenceTable]="referenceTable$ | async"
      [isLoading]="isLoading$ | async"
      [separateMaleAndFemale]="separateMaleAndFemale$ | async"
      [weightMethod]="birdWeightSettings?.weightMethod"
      [weightUnit]="weightUnit$ | async"
      class="settings__form-bird-weight">
      <div
        *ngIf="editMode"
        class="buttons-content">
        <ls-button
          (click)="cancel()"
          [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
          [qaTags]="'qa-btn-cancel'"
          [type]="'secondary'"
          class="mr-28 h-button-36">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>
        <ls-button
          (click)="update()"
          [class.disabled]="
            !isValidSettings || !isValidWeight || (!isDirtyBirdWeightSettings && !isDirtyBirdWeightItems)
          "
          [class.h-button-36]="platformService.isMobileApp"
          [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
          [qaTags]="'qa-btn-update'"
          [type]="'primary'">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </ls-flock-bird-weight-form>
  </div>

  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20 d-none-md" />
</ls-page-wrapper>
