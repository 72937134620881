<div
  [class.rtl]="languageService.isRtl"
  class="popup d-flex flex-column justify-between">
  <div class="popup-top">
    <div class="d-flex align-center justify-between mb-15">
      <h3
        [qaTags]="'qa-lbl-popup-title'"
        class="popup-title fz-20 color-primary">
        {{ "Controller.WiFiPopup.Wifi" | translate }}
      </h3>

      <img
        (click)="close()"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="close-icon pointer"
        src="/assets/icons/common/cross.svg" />
    </div>

    <div class="popup-body">
      <div class="d-flex flex-column">
        <label class="mb-15 fz-16 color-tertiary">
          {{ "Controller.WiFiPopup.AvailableNetworks" | translate }}
        </label>

        <ul class="wifi-list overflow-auto">
          <li
            *ngFor="let item of wifiList; index as index; last as last"
            (click)="chooseWifi(item)"
            [class.mb-25]="!last"
            class="wifi-list__item d-flex align-center pointer">
            <ls-svg-icon [path]="'quick-start/network/' + (index % 2 === 0 ? 'wifi' : 'wifi-secure')"></ls-svg-icon>
            <span class="ml-10 color-secondary">{{ item }}</span>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div>
