<div class="widget-wrapper">
  <h6
    [qaTags]="'qa-lbl-widget-title'"
    class="widget-title">
    Heat by zone
  </h6>

  <div class="widget-body d-flex">
    <div class="widget-icon mr-25">
      <ls-svg-icon
        [color]="ColorsEnum.BlueDefault"
        [hasMargin]="true"
        [qaTags]="'qa-img-widget qa-img-heat'"
        path="dashboard/heat"></ls-svg-icon>
    </div>

    <div class="widget-content">
      <div class="heat-zones d-flex justify-between justify-start-desktop flex-wrap text-center">
        <div
          *ngFor="let zone of heatZones"
          [class.heat-zone__active]="zone === 1 || zone === 2 || zone === 5"
          [qaTags]="
            zone === 1 || zone === 2 || zone === 5
              ? 'qa-bgc-heat-zone-cell qa-lbl-heat-zone-num'
              : 'qa-lbl-heat-zone-num'
          "
          class="heat-zone mb-10 fz-24 fw-500 pv-3 pv-5-device pv-1-mobile mr-3-desktop mr-8-mobile mb-5-xl mb-4-mobile fz-16-xl line-height-1 pointer mr-30">
          {{ zone }}
        </div>
      </div>
    </div>
  </div>
</div>
