import { createFeatureSelector, createSelector } from '@ngrx/store';
import { KEYBOARD_KEY } from './keyboard.reducer';
import { KeyboardState } from './keyboard.state';

export const getKeyboardState =
  createFeatureSelector<KeyboardState>(KEYBOARD_KEY);

export const selectKeyboardValue = createSelector(
  getKeyboardState,
  (({ symbol }) => symbol),
);

export const selectCurrentKeyboardUUID = createSelector(
  getKeyboardState,
  ((state) => state?.elementUuid),
);

export const selectKeyboardValueAndUUID = createSelector(
  getKeyboardState,
  (({ elementUuid, symbol }) => ({
    elementUuid,
    symbol,
  })),
);

export const selectKeyboardRanges = createSelector(
  getKeyboardState,
  (({ ranges }) => ranges),
);

export const selectKeyboardMode = createSelector(
  getKeyboardState,
  (({ mode }) => mode),
);

export const isRefreshFieldsForKeyboard = createSelector(
  getKeyboardState,
  (({ isRefreshFieldsForKeyboard }) => isRefreshFieldsForKeyboard),
);

export const getKeyboardIconPath = createSelector(
  getKeyboardState,
  (({ currentIconPath }) => currentIconPath),
);

export const selectHasAmPmButtons = createSelector(
  getKeyboardState,
  (({ hasAMPM }) => hasAMPM),
);

export const selectActiveAmPmButton = createSelector(
  getKeyboardState,
  (({ activeAMPM }) => activeAMPM),
);
