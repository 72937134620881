export enum ColorsEnum {
  Primary = '#00A9A2',
  Black = '#051324',
  White = '#fff',
  PrimaryDisabled = '#047772',
  Default = '#616172',
  DefaultDisabled = '#D9D9D9',
  GrayDisabled = '#AAB0B8',
  ColorD0 = '#D0D0DD80',
  Color90 = '#9090a0',
  BlueDefault = '#0EAAD9',
  BlueDark = '#2C73A6',
  Yellow = '#FFDF3A',
  YellowLight = '#ffec89',

  // new colors
  MonoGrey = '#A4A4A4',
  MonoDark = '#090804',
  MainBlue = '#00B0F0',
  MainDarkRed = '#A8191A',
  MonoLight = '#F9F9F9',
  MainRed = '#D41F21',
}
