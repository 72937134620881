import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ButtonComponent } from '@livestock/ui';

@Component({
  selector: 'ls-scan-qr-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ZXingScannerModule,
    ButtonComponent,
  ],
  templateUrl: './scan-qr-dialog.component.html',
  styleUrls: ['./scan-qr-dialog.component.scss'],
})
export class ScanQrDialogComponent {
  constructor(
    public dialogRef: MatDialogRef<ScanQrDialogComponent>,
  ) {
  }

  scan(data: any): void {
    if (!data) {
      return;
    }

    const { search } = new URL(data.text);
    const params = new URLSearchParams(search);

    if (!params.get('connectionNumber')) {
      return;
    }

    const connectionNumber = params.get('connectionNumber');
    this.closePopup(connectionNumber);
  }

  closePopup(connectionNumber?: string): void {
    this.dialogRef.close({ connectionNumber });
  }
}
