import { Injectable, NgZone } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@livestock/shared/environments';

@Injectable({
  providedIn: 'root',
})
export class AppDisplayClientSseService {
  eventSource: EventSource | null;
  sseEvents$ = new BehaviorSubject(null);

  constructor(private readonly _zone: NgZone) {
    this.initSseConnection(`${environment.apiUrl}/sse`);
  }

  initSseConnection(url: string): void {
    this.eventSource = new EventSource(url);
    this.eventSource.onopen = () => {
      console.log('SSE connection open', url);
    };
    this.eventSource.onmessage = (event: MessageEvent) => {
      this._zone.run(() => {
        this.sseEvents$.next(event.data);
      });
    };
    this.eventSource.onerror = error => {
      this._zone.run(() => {
        console.error('SSE Error', error);
      });
    };
  }
}
