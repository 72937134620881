import { Action, createReducer, on } from '@ngrx/store';
import {
  ControllerLanguageEnum,
  ControllerUnitEnum,
  HoursFormatTypeEnum,
  QuickStartStepsEnum,
} from '@livestock/controllers/enums';
import * as upsertControllerActions from './upsert-controller.actions';
import { UpsertControllerState } from './upsert-controller.state';
import { BirdTypeEnum } from '../../enums/bird-type.enum';
import { GlobalConstants } from '@livestock/shared/constants';
import * as moment from 'moment';
import { IHouseSizesView } from '../../interfaces/house-sizes-view.interface';
import { ConvertHelper } from '@livestock/shared/utils';
import { ChickenBrandWeight, LengthUnitEnum, StorageItem } from '@livestock/shared/enums';
import { LocalStorageService } from '@livestock/shared/services';
import { StagingEnum, VentilationWorkingModeEnum } from '@livestock/flock';
import { reconnectControllerSuccess } from '../current-controller/current-controller.actions';

export const UPSERT_CONTROLLER_FEATURE_KEY = 'upsertController';

const language = +LocalStorageService.getStorageItem(StorageItem.ControllerLang) || ControllerLanguageEnum.EngUS;
const minLengthForHouse = language === ControllerLanguageEnum.EngUS
  ? GlobalConstants.MinFootWidthHeightLength
  : GlobalConstants.MinMeterWidthHeightLength;

export const initialState: UpsertControllerState = {
  isLoading: false,
  controllerInfo: null,
  generalSettings: {
    language,
    units: language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric,
    actualUnits: language === ControllerLanguageEnum.EngUS
      ? ControllerUnitEnum.Imperial
      : ControllerUnitEnum.Metric,
    birdType: BirdTypeEnum.Broilers,
    unitsDetails: language === ControllerLanguageEnum.EngUS ? GlobalConstants.DefaultUnitsDetailsImperial : GlobalConstants.DefaultUnitsDetailsMetric,
    ventilationWorkingMode: VentilationWorkingModeEnum.Basic,
  },
  dateTimeSettings: {
    timeZoneID: null,
    hoursFormat: language === ControllerLanguageEnum.EngUS
      ? HoursFormatTypeEnum.AmPm
      : HoursFormatTypeEnum.HoursFormat24,
  },
  networkSettings: {
    wifi: 0,
    wifiName: '1',
    wifiPassword: '1',
    wifiAutoConnect: false,
    lan: 0,
    lanIp: '',
    lanMac: '',
    modem: 0,
    modemAPN: '',
    modemPassword: '',
    modemUser: '',
  },
  houseSizesSettings: {
    houseWidth: minLengthForHouse,
    houseLength: minLengthForHouse,
    houseHeight: minLengthForHouse,
    houseRoofHeight: minLengthForHouse,
    houseNumber: 0,
  },
  flockSettings: {
    growthDay: null,
    flockNumber: 1,
    separateMaleAndFemale: false,
    initialNumberOfBirds: 0,
    femaleInitialNumberOfBirds: 0,
    maleInitialNumberOfBirds: 0,
    staging: StagingEnum.FullHouse,
    curveOffset: 0,
    emptyHouse: true,
    defaultWeightBrandID: ChickenBrandWeight.COBB_500,
  },
  flockWeights: [],
  defaultFlockWeights: null,
  isControllerAssignedWithFarm: false,
  currentStep: QuickStartStepsEnum.GeneralSettings,
};

const reducer = createReducer(
  initialState,
  on(
    upsertControllerActions.getGeneralSettings,
    upsertControllerActions.getDateTimeSettings,
    upsertControllerActions.getNetworkSettings,
    upsertControllerActions.getHouseSizesSettings,
    upsertControllerActions.createController,
    upsertControllerActions.updateController,
    upsertControllerActions.updateGeneralSettings,
    upsertControllerActions.updateDateTimeSettings,
    upsertControllerActions.updateNetworkSettings,
    upsertControllerActions.updateHouseSizesSettings,
    upsertControllerActions.deleteController,
    upsertControllerActions.getTicketInfoFromDevice,
    upsertControllerActions.getLanSettings,
    upsertControllerActions.updateLanSettings,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    upsertControllerActions.getGeneralSettingsError,
    upsertControllerActions.getDateTimeSettingsError,
    upsertControllerActions.getNetworkSettingsError,
    upsertControllerActions.createControllerError,
    upsertControllerActions.updateControllerError,
    upsertControllerActions.updateGeneralSettingsError,
    upsertControllerActions.updateDateTimeSettingsError,
    upsertControllerActions.updateNetworkSettingsError,
    upsertControllerActions.updateHouseSizesSettingsError,
    upsertControllerActions.deleteControllerError,
    upsertControllerActions.updateControllerSuccess,
    upsertControllerActions.updateGeneralSettingsSuccess,
    upsertControllerActions.updateDateTimeSettingsSuccess,
    upsertControllerActions.updateNetworkSettingsSuccess,
    upsertControllerActions.updateHouseSizesSettingsSuccess,
    upsertControllerActions.getLanSettingsError,
    upsertControllerActions.updateLanSettingsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(upsertControllerActions.setGeneralSettings, (state, { generalSettings }) => {
    let houseSizesSettings: IHouseSizesView = { ...state.houseSizesSettings };
    const customUnit = generalSettings?.unitsDetails?.length;
    let actualUnits: ControllerUnitEnum = state.generalSettings.actualUnits;
    if (generalSettings.units !== state.generalSettings.units) {
      // If units are different from the state, update actualUnits based on the new units
      actualUnits =
        generalSettings.units !== ControllerUnitEnum.Custom ? generalSettings.units :
          generalSettings.unitsDetails.length === LengthUnitEnum.Foot ? ControllerUnitEnum.Imperial : ControllerUnitEnum.Metric;

      // Update houseSizesSettings if actualUnits has changed
      if (actualUnits !== state.generalSettings.actualUnits) {
        houseSizesSettings = convertHouseSize(state.houseSizesSettings, actualUnits);
      }
    } else if (
      // Handle custom units change, (treat customUnit as a number because 2 enums are different but theyre values overlap - [0,1])
      generalSettings.units === ControllerUnitEnum.Custom
      && [LengthUnitEnum.Meter, LengthUnitEnum.Foot].includes(customUnit)
      && (customUnit as number) !== actualUnits
    ) {
      actualUnits = customUnit === LengthUnitEnum.Meter ? ControllerUnitEnum.Metric : ControllerUnitEnum.Imperial;
      houseSizesSettings = convertHouseSize(state.houseSizesSettings, actualUnits);
    }

    return {
      ...state,
      generalSettings: {
        ...generalSettings,
        actualUnits,
      },
      houseSizesSettings,
      dateTimeSettings: {
        ...state.dateTimeSettings,
        hoursFormat: generalSettings.language === ControllerLanguageEnum.EngUS
          ? HoursFormatTypeEnum.AmPm
          : HoursFormatTypeEnum.HoursFormat24,
      },
      isLoading: false,
    };
  }),
  on(upsertControllerActions.getGeneralSettingsSuccess, (state, { generalSettings }) => {
    return {
      ...state,
      generalSettings: {
        ...generalSettings,
      },
      isLoading: false,
    };
  }),
  on(
    upsertControllerActions.getDateTimeSettingsSuccess,
    upsertControllerActions.setDateTimeSettings,
    (state, { dateTimeSettings }) => {
      return {
        ...state,
        dateTimeSettings: {
          ...dateTimeSettings,
          manuallyDateTime:
            typeof dateTimeSettings?.manuallyDateTime === 'string' || dateTimeSettings?.manuallyDateTime instanceof String
              ? moment(dateTimeSettings.manuallyDateTime, GlobalConstants.DateFormat)
              : dateTimeSettings.manuallyDateTime,
        },
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.getNetworkSettingsSuccess,
    upsertControllerActions.setNetworkSettings,
    (state, { networkSettings }) => {
      return {
        ...state,
        networkSettings,
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.getLanSettingsSuccess,
    upsertControllerActions.updateLanSettingsSuccess,
    (state, { lan, lanIp, lanMac }) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          lan,
          lanIp,
          lanMac,
        },
        isLoading: false,
      };
    },
  ),
  on(
    upsertControllerActions.getLanSettingsError,
    upsertControllerActions.updateLanSettingsError,
    (state) => {
      return {
        ...state,
        networkSettings: {
          ...state.networkSettings,
          lan: 0,
          lanIp: null,
          lanMac: null,
        },
      };
    },
  ),
  on(
    upsertControllerActions.getHouseSizesSettingsSuccess,
    upsertControllerActions.setHouseSizesSettings,
    (state, { houseSizesSettings }) => {
      return {
        ...state,
        houseSizesSettings,
        isLoading: false,
      };
    },
  ),
  on(upsertControllerActions.setFlockSettings, (state, { flockSettings }) => {
      return {
        ...state,
        flockSettings,
      };
    },
  ),
  on(upsertControllerActions.createTicketControllerSuccess, (state, { ticketView }) => {
    return {
      ...state,
      ticketView,
      currentStep: state.currentStep + 1,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.setIsControllerAssignedWithFarm, (state, { isControllerAssignedWithFarm }) => {
    return {
      ...state,
      isControllerAssignedWithFarm,
    };
  }),
  on(upsertControllerActions.createControllerByConnectionNumber, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(upsertControllerActions.createControllerByConnectionNumberSuccess, (state) => {
    return {
      ...state,
      isControllerCreatedByConnectionNumber: true,
      creatingControllerError: null,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.createControllerByConnectionNumberError, (state, { payload }) => {
    return {
      ...state,
      isControllerCreatedByConnectionNumber: false,
      creatingControllerError: payload.error,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.executeTicketController, (state) => {
    return {
      ...state,
      isControllerTicketExecuted: false,
      creatingControllerError: null,
      isLoading: true,
    };
  }),
  on(upsertControllerActions.executeTicketControllerSuccess, (state) => {
    return {
      ...state,
      isControllerTicketExecuted: true,
      creatingControllerError: null,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.executeTicketControllerError, (state, { payload }) => {
    return {
      ...state,
      isControllerTicketExecuted: false,
      creatingControllerError: payload.error,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.clearIsControllerCreatedOrExecuted, (state) => {
    return {
      ...state,
      isControllerCreatedByConnectionNumber: null,
      isControllerTicketExecuted: null,
      creatingControllerError: null,
    };
  }),
  on(upsertControllerActions.clearUpsertControllerState, (state) => {
    return {
      ...state,
      ...initialState,
    };
  }),
  on(upsertControllerActions.goToNextStep, (state) => {
    if (state.currentStep === QuickStartStepsEnum.ConnectionsSettings) {
      return state;
    }

    return {
      ...state,
      currentStep: state.currentStep + 1,
    };
  }),
  on(upsertControllerActions.goToStep, (state, { payload }) => {
    return {
      ...state,
      currentStep: payload ?? state.currentStep,
    };
  }),
  on(upsertControllerActions.goToPrevStep, (state) => {
    if (state.currentStep === QuickStartStepsEnum.GeneralSettings) {
      return state;
    }

    return {
      ...state,
      currentStep: state.currentStep - 1,
    };
  }),
  on(
    upsertControllerActions.createGeneralSettingsViaDeviceSuccess,
    upsertControllerActions.createHouseSizesViaDeviceSuccess,
    upsertControllerActions.createDateTimeSettingsViaDeviceSuccess,
    upsertControllerActions.createNetworkSettingsViaDeviceSuccess,
    (state, _props) => {
      // TODO: Fix correct steps
      // let nextStep: QuickStartStepsEnum = null;
      // nextStep = state.currentStep === QuickStartStepsEnum.ConnectionsSettings ? state.currentStep : state.currentStep + 1;

      return {
        ...state,
        // currentStep: props?.['isFinish'] ? state.currentStep : nextStep,
      };
    },
  ),
  on(upsertControllerActions.goToDateTimeSettingsStep, (state) => {
    return {
      ...state,
      currentStep: QuickStartStepsEnum.DateTimeSettings,
    };
  }),
  on(upsertControllerActions.getControllerSerialNumberDeviceSuccess, (state, { serialNumber }) => {
    return {
      ...state,
      deviceControllerSerialNumber: serialNumber,
    };
  }),
  on(upsertControllerActions.setNetworkConnectionInProgress, (state, { connectionInProgress }) => {
    return {
      ...state,
      networkConnectionInProgress: connectionInProgress,
    };
  }),
  on(upsertControllerActions.getTicketInfoFromDeviceSuccess, (state, { ticketView }) => {
    return {
      ...state,
      ticketView,
      currentStep: state.currentStep + 1,
      isLoading: false,
    };
  }),
  on(reconnectControllerSuccess, (state, { ticketView }) => {
    return {
      ...state,
      ticketView,
    };
  }),
  on(upsertControllerActions.getFlockWeightReferenceTableSuccess, (state, { defaultWeights }) => {
    return {
      ...state,
      defaultFlockWeights: defaultWeights,
    };
  }),
  on(upsertControllerActions.setFlockWeights, (state, { flockWeights }) => {
    return {
      ...state,
      flockWeights,
    };
  }),
);

export function upsertControllerReducer(state: UpsertControllerState | undefined, action: Action): any {
  return reducer(state, action);
}

const convertHouseSize = (houseSizes: IHouseSizesView, unitType: ControllerUnitEnum): IHouseSizesView => {
  const methodName: string = unitType === ControllerUnitEnum.Metric
    ? ConvertHelper.feetToMeters.name
    : ConvertHelper.metersToFeet.name;

  const accuracy = unitType === ControllerUnitEnum.Metric
    ? 1
    : 3;

  return {
    ...houseSizes,
    houseHeight: ConvertHelper[methodName](houseSizes.houseHeight, accuracy),
    houseRoofHeight: ConvertHelper[methodName](houseSizes.houseRoofHeight, accuracy),
    houseLength: ConvertHelper[methodName](houseSizes.houseLength, accuracy),
    houseWidth: ConvertHelper[methodName](houseSizes.houseWidth, accuracy),
  };
};
