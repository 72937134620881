import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TempMappingState } from './temp-mapping.state';
import { TempMappingStagesEnum } from '../enums/temp-mapping-stages.enum';

export const TEMP_MAPPING_KEY = 'tempMapping';

export const getTempMappingState =
  createFeatureSelector<TempMappingState>(TEMP_MAPPING_KEY);

export const selectIsLoading = createSelector(
  getTempMappingState,
  (({ isLoading }) => isLoading),
);

export const selectTemperatureMappings = createSelector(
  getTempMappingState,
  (({ temperatureMappings }) => temperatureMappings),
);

export const selectFullHouse = createSelector(
  selectTemperatureMappings,
  (temperatureMappings => temperatureMappings.find(mapping => mapping.type === TempMappingStagesEnum.FullHouse)),
);

export const selectTempMappingsHeatingZones = createSelector(
  getTempMappingState,
  (({ heatingZones }) => heatingZones),
);

export const selectTempMappingsSensorElements = createSelector(
  getTempMappingState,
  (({ sensorElements }) => sensorElements),
);

// Brooding #1
export const selectBrooding1 = createSelector(
  selectTemperatureMappings,
  (temperatureMappings => temperatureMappings.filter(mapping => mapping.type === TempMappingStagesEnum.Brooding1)),
);

export const selectBrooding1HeatingZones = createSelector(
  selectBrooding1,
  selectTempMappingsHeatingZones,
  ((temperatureMappings, heatingZones) => {
    const zoneIDs = temperatureMappings.map(mapping => mapping.zoneID);
    return heatingZones.filter(zone => zoneIDs.includes(zone.zoneID));
  }),
);

export const selectBrooding1ElementMappings = createSelector(
  selectBrooding1,
  (temperatureMappings => temperatureMappings.filter(el => !!el.elementID)),
);

export const selectBrooding1HasMappings = createSelector(
  selectBrooding1,
  ((temperatureMappings) => temperatureMappings?.length > 0),
);

// Brooding #2
export const selectBrooding2 = createSelector(
  selectTemperatureMappings,
  (temperatureMappings => temperatureMappings.filter(mapping => mapping.type === TempMappingStagesEnum.Brooding2)),
);

export const selectBrooding2HeatingZones = createSelector(
  selectBrooding2,
  selectTempMappingsHeatingZones,
  ((temperatureMappings, heatingZones) => {
    const zoneIDs = temperatureMappings.map(mapping => mapping.zoneID);
    return heatingZones.filter(zone => zoneIDs.includes(zone.zoneID));
  }),
);

export const selectBrooding2ElementMappings = createSelector(
  selectBrooding2,
  (temperatureMappings => temperatureMappings.filter(el => !!el.elementID)),
);

export const selectBrooding2IsShown = createSelector(
  getTempMappingState,
  (({ brooding2IsShown }) => brooding2IsShown),
);

export const selectBrooding2IsEmpty = createSelector(
  selectBrooding2IsShown,
  selectBrooding2,
  ((brooding2IsShown, brooding2Mappings) => brooding2IsShown && brooding2Mappings.length === 0),
);

export const selectBrooding2HasMappings = createSelector(
  selectBrooding2,
  ((temperatureMappings) => temperatureMappings?.length > 0),
);

// ACTIVE ELEMENT
export const selectTempMappingsActiveElement = createSelector(
  getTempMappingState,
  (({ activeElement }) => activeElement),
);

export const selectIsDirtyForm = createSelector(
  getTempMappingState,
  (({ temperatureMappings, originalTemperatureMappings }) => {
    return JSON.stringify(temperatureMappings) !== JSON.stringify(originalTemperatureMappings);
  }),
);
