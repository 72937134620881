<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'AirTreatment.Settings.AirTreatmentSettings'">
  <div class="svg-content header__buttons d-flex align-center justify-between-md">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'mobile/edit' : 'flock/edit-button'"
      class="icon pointer qa-btn-edit-mode">
    </ls-svg-icon>
  </div>

  <ls-air-treatment-settings-form
    (changed)="changeAirTreatmentSettings($event)"
    [airTreatmentSettings]="airTreatmentSettings"
    [editMode]="editMode"
    [isLoading]="isLoading$ | async"
    [temperatureUnitType]="temperatureUnit$ | async"
    class="main-content">
    <div
      *ngIf="editMode"
      class="buttons-content">
      <ls-button
        (click)="cancelSettings()"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-28 h-button-36">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="updateSettings()"
        [class.disabled]="!isValid || !isDirty"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'"
        class="h-button-36">
        {{ "Buttons.Save" | translate }}
      </ls-button>
    </div>
  </ls-air-treatment-settings-form>
  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20" />
</ls-page-wrapper>
