import { ICard } from '../interfaces/card.interface';
import { IElementType } from '../interfaces/element/element-type.interface';
import { IConnectionSetup } from '../interfaces/connection-setup.interface';
import { IElementSettings } from '../interfaces/element/element-settings.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElementTestingData } from '../interfaces/element/element-testing-interface';

export interface InstallationState {
  isLoading: boolean;
  isElementLoading: boolean;
  cards: ICard[];
  elementTypes: IElementType[];
  currentCardID: number;
  currentConnectionID: number;
  currentElementType: ElementTypesEnum;
  currentElementID: number;
  currentConnectionSetup: IConnectionSetup;
  elementSettings: IElementSettings;
  elementTestindData?: IElementTestingData;
  isTestingDataLoading?: boolean;
}
