import { ControllerLanguageEnum } from '../enums/controller-language.enum';
import { ControllerUnitEnum } from '../enums/controller-unit.enum';
import { BirdTypeEnum } from '../enums/bird-type.enum';
import { HoursFormatTypeEnum } from '../enums/hours-format-type.enum';

export interface ICreateControllerView {
  sn: string;
  connectedByUserID: number;
  farmID: number;
  language: ControllerLanguageEnum;
  units: ControllerUnitEnum;
  houseNumber: number;
  birdType: BirdTypeEnum;
  timeZone: string;
  manuallyDateTime?: string;
  hoursFormat: HoursFormatTypeEnum;
  modem: number;
  wifi: number;
  lan: number;
}
