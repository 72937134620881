import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { IVentBrands } from '../interfaces/vent-brands.interface';
import { IVentModelsAO } from '../interfaces/vent-models-ao.interface';
import { IVentModelsDO } from '../interfaces/vent-models-do.interface';

@Injectable({
  providedIn: 'root',
})
export class VentilationBrandsApiService {
  constructor(private http: HttpClient) {
  }

  getVentilationBrands(): Observable<IVentBrands> {
    return this.http.get<IVentBrands>(`${environment.apiUrl}/brands`);
  }

  getVentilationDOModels(brandID: number): Observable<IVentModelsDO> {
    return this.http.get<IVentModelsDO>(`${environment.apiUrl}/brands/${brandID}/models/ventilation-do`);
  }

  getVentilationAOModels(brandID: number): Observable<IVentModelsAO> {
    return this.http.get<IVentModelsAO>(`${environment.apiUrl}/brands/${brandID}/models/ventilation-ao`);
  }
}
