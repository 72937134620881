<div class="widget-wrapper d-flex">
  <div class="widget-top d-flex flex-column mr-30">
    <h6
      [qaTags]="'qa-lbl-widget-title'"
      class="widget-title">
      Light
    </h6>

    <div class="widget-icon">
      <ls-svg-icon
        [color]="ColorsEnum.BlueDefault"
        [hasMargin]="true"
        [qaTags]="'qa-img-widget'"
        path="dashboard/light"></ls-svg-icon>
    </div>
  </div>

  <div class="widget-body w-100-percent">
    <div class="widget-columns d-flex mb-7">
      <div
        *ngFor="let column of columns"
        [qaTags]="'qa-lbl-light-zone-num'"
        class="widget-column mr-85 mr-58-xl mr-40-lg">
        {{ column }}
      </div>
    </div>

    <div class="widget-content">
      <div class="widget-content__blocks">
        <div class="widget-content__block widget-content__block-top">
          <div
            [qaTags]="'qa-lbl-light-zone-value'"
            class="widget-content__value mr-85 mr-58-xl mr-40-lg">
            00<span class="qa-lbl-light-zone-units">%</span>
          </div>
          <div
            [qaTags]="'qa-lbl-light-zone-value'"
            class="widget-content__value mr-85 mr-58-xl mr-40-lg">
            00<span class="qa-lbl-light-zone-units">%</span>
          </div>
          <div
            [qaTags]="'qa-lbl-light-zone-value'"
            class="widget-content__value">
            75<span class="qa-lbl-light-zone-units">%</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
