import { APP_INITIALIZER, ApplicationConfig } from '@angular/core';
import {
  provideRouter,
  withEnabledBlockingInitialNavigation,
} from '@angular/router';
import { appRoutes } from './app.routes';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { importProvidersFrom } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MAT_MOMENT_DATE_FORMATS,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { MissingTranslationHandler, TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { MissingTranslationService, HttpTranslateLoaderFactory, PlatformService } from '@livestock/shared/services';
import { provideState, provideStore } from '@ngrx/store';
import { provideEffects } from '@ngrx/effects';
import { provideStoreDevtools } from '@ngrx/store-devtools';
import { provideAnimations } from '@angular/platform-browser/animations';
import {
  UpsertControllerEffects,
  upsertControllerReducer,
  UPSERT_CONTROLLER_FEATURE_KEY, currentControllerReducer, CURRENT_CONTROLLER_FEATURE_KEY, CurrentControllerEffects,
} from '@livestock/controllers';
import { KEYBOARD_KEY, keyboardReducer, VIRTUAL_KEYBOARD_KEY, virtualKeyboardReducer } from '@livestock/ui';
import {
  INSTALLATION_FEATURE_KEY,
  installationReducer,
  InstallationEffects,
} from '@livestock/installation';
import { COOLING_FEATURE_KEY, CoolingProgramEffects, coolingReducer } from '@livestock/cooling-program';
import { LIGHTING_FEATURE_KEY, LightingProgramEffects, lightingReducer } from '@livestock/lighting-program';
import { AuthHeaderInterceptor } from '@livestock/auth';
import { HttpErrorsInterceptor } from '@livestock/shared/interceptors';
import {
  SPRINKLERS_FOGGERS_FEATURE_KEY,
  SprinklersFoggersProgramEffects,
  sprinklersFoggersProgramReducer,
} from '@livestock/sprinklers-foggers-program';
import { FLOCK_FEATURE_KEY, FlockEffects, flockReducer } from '@livestock/flock';
import { FLASH_MESSAGE_FEATURE_KEY, flashMessageReducer } from '@livestock/notifications';
import { STATIC_PRESSURE_KEY, StaticPressureEffects, staticPressureReducer } from '@livestock/static-pressure';
import {
  HEATING_PROGRAM_ZONE,
  HeatingProgramZoneEffects,
  heatingProgramZoneReducer,
} from '@livestock/heating-program-zone';
import { TEMP_MAPPING_KEY, TempMappingEffects, tempMappingReducer } from '@livestock/temp-mapping';
import { LIGHTING_MAPPING_KEY, LightingMappingEffects, lightingMappingReducer } from '@livestock/lighting-mapping';
import {
  VENTILATION_BRANDS_KEY,
  VentilationBrandsEffects,
  ventilationBrandsReducer,
} from '@livestock/ventilation-brands';
import {
  BASIC_VENTILATION_MINIMUM_KEY,
  BasicVentilationMinimumEffects,
  basicVentilationMinimumReducer,
} from '@livestock/basic-ventilation-minimum';
import { TEMPERATURE_CURVE_KEY, TemperatureCurveEffects, temperatureCurveReducer } from '@livestock/temperature-curve';
import { HEATING_PROGRAMS_FEATURE_KEY, HeatingProgramEffects, heatingProgramReducer } from '@livestock/heating-program';
import { AIR_TREATMENT_FEATURE_KEY, AirTreatmentEffects, airTreatmentReducer } from '@livestock/air-treatment';

function setAppValues(service: PlatformService) {
  return () => {
    const isMobileApp = false;
    const isDeviceApp = true;
    const isDesktopApp = false;
    return service.setAppValues(isMobileApp, isDeviceApp, isDesktopApp);
  };
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideEffects(),
    provideStore(),
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation()),

    provideState(UPSERT_CONTROLLER_FEATURE_KEY, upsertControllerReducer),
    provideState(CURRENT_CONTROLLER_FEATURE_KEY, currentControllerReducer),
    provideState(INSTALLATION_FEATURE_KEY, installationReducer),
    provideState(VIRTUAL_KEYBOARD_KEY, virtualKeyboardReducer),

    //remove old keyboard in future
    provideState(KEYBOARD_KEY, keyboardReducer),
    provideState(COOLING_FEATURE_KEY, coolingReducer),
    provideState(SPRINKLERS_FOGGERS_FEATURE_KEY, sprinklersFoggersProgramReducer),
    provideState(FLOCK_FEATURE_KEY, flockReducer),
    provideState(LIGHTING_FEATURE_KEY, lightingReducer),
    provideState(FLASH_MESSAGE_FEATURE_KEY, flashMessageReducer),
    provideState(STATIC_PRESSURE_KEY, staticPressureReducer),
    provideState(HEATING_PROGRAM_ZONE, heatingProgramZoneReducer),
    provideState(HEATING_PROGRAMS_FEATURE_KEY, heatingProgramReducer),
    provideState(TEMP_MAPPING_KEY, tempMappingReducer),
    provideState(TEMPERATURE_CURVE_KEY, temperatureCurveReducer),
    provideState(LIGHTING_MAPPING_KEY, lightingMappingReducer),
    provideState(VENTILATION_BRANDS_KEY, ventilationBrandsReducer),
    provideState(BASIC_VENTILATION_MINIMUM_KEY, basicVentilationMinimumReducer),
    provideState(AIR_TREATMENT_FEATURE_KEY, airTreatmentReducer),

    provideEffects(UpsertControllerEffects),
    provideEffects(CurrentControllerEffects),
    provideEffects(InstallationEffects),
    provideEffects(CoolingProgramEffects),
    provideEffects(LightingProgramEffects),
    provideEffects(SprinklersFoggersProgramEffects),
    provideEffects(FlockEffects),
    provideEffects(StaticPressureEffects),
    provideEffects(HeatingProgramZoneEffects),
    provideEffects(HeatingProgramEffects),
    provideEffects(TempMappingEffects),
    provideEffects(TemperatureCurveEffects),
    provideEffects(LightingMappingEffects),
    provideEffects(VentilationBrandsEffects),
    provideEffects(BasicVentilationMinimumEffects),
    provideEffects(AirTreatmentEffects),

    provideStoreDevtools(),
    provideAnimations(),
    {
      provide: APP_INITIALIZER,
      useFactory: setAppValues,
      deps: [PlatformService],
      multi: true,
    },
    {
      provide: DateAdapter,
      useClass: MomentDateAdapter,
      deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
    },
    {
      provide: MAT_DATE_FORMATS,
      useValue: MAT_MOMENT_DATE_FORMATS,
    },

    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthHeaderInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpErrorsInterceptor,
      multi: true,
    },

    importProvidersFrom(
      HttpClientModule,
      MatDialogModule,
      TranslateModule.forRoot({
        loader: {
          provide: TranslateLoader,
          useFactory: HttpTranslateLoaderFactory,
          deps: [HttpClient],
        },
        missingTranslationHandler: {
          provide: MissingTranslationHandler,
          useClass: MissingTranslationService,
        },
        useDefaultLang: false,
      }),
    ),
  ],
};
