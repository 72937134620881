<div
  *ngIf="!isLoading; else loading"
  [class.rtl]="languageService.isRtl"
  [class.show-mode]="!editMode"
  [formGroup]="form"
  class="house-mode d-flex flex-column">
  <div class="house-mode__container">
    <div
      *ngIf="!platformService.isMobileApp; else mobileTemplate"
      class="table w-1-20 w-2-20 w-3-20 w-4-20 w-5-20">
      <div class="table-row table-header h-60">
        <div class="table-cell blue">{{ "Flock.HouseMode.Mode" | translate }}</div>
        <div class="table-cell blue">{{ "Flock.HouseMode.Empty" | translate }}</div>
        <div class="table-cell blue">{{ "Flock.HouseMode.Preheat" | translate }}</div>
        <div class="table-cell blue">{{ "Flock.HouseMode.Cleaning" | translate }}</div>
        <div class="table-cell blue">{{ "Flock.HouseMode.Catching" | translate }}</div>
      </div>
      <!-- The Second Row -->
      <div class="table-row">
        <div class="table-cell blue">{{ "Flock.HouseMode.TargetTemperature" | translate }}</div>
        <div class="table-cell">
          <ls-input-decimal
            [accuracy]="1"
            [fieldWithKeyboard]="true"
            [max]="maxTargetTemperature"
            [min]="FlockConstants.MinTargetTemperature"
            [qaTags]="'qa-txt-target-temperature-empty'"
            class="align-center"
            errorField
            formControlName="targetTemperatureEmpty">
            <ng-container
              *ngTemplateOutlet="unit"
              suffix>
            </ng-container>
          </ls-input-decimal>
        </div>
        <div class="table-cell">
          <ls-input-decimal
            [accuracy]="1"
            [fieldWithKeyboard]="true"
            [max]="maxTargetTemperature"
            [min]="FlockConstants.MinTargetTemperature"
            [qaTags]="'qa-txt-target-temperature-preheat'"
            class="align-center"
            errorField
            formControlName="targetTemperaturePreheat">
            <ng-container
              *ngTemplateOutlet="unit"
              suffix>
            </ng-container>
          </ls-input-decimal>
        </div>
        <div class="table-cell">
          <ls-input-decimal
            [accuracy]="1"
            [fieldWithKeyboard]="true"
            [max]="maxTargetTemperature"
            [min]="FlockConstants.MinTargetTemperature"
            [qaTags]="'qa-txt-target-temperature-cleaning'"
            class="align-center"
            errorField
            formControlName="targetTemperatureCleaning">
            <ng-container
              *ngTemplateOutlet="unit"
              suffix>
            </ng-container>
          </ls-input-decimal>
        </div>
        <div class="table-cell">
          <ls-input-decimal
            [accuracy]="1"
            [fieldWithKeyboard]="true"
            [max]="maxTargetTemperature"
            [min]="FlockConstants.MinTargetTemperature"
            [qaTags]="'qa-txt-target-temperature-catching'"
            class="align-center"
            errorField
            formControlName="targetTemperatureCatching">
            <ng-container
              *ngTemplateOutlet="unit"
              suffix>
            </ng-container>
          </ls-input-decimal>
        </div>
      </div>

      <!-- The Third Row -->
      <div class="table-row">
        <div class="table-cell blue">{{ "Flock.HouseMode.EnableTA" | translate }}</div>
        <div class="table-cell">
          <ls-toggle
            [qaTags]="'qa-chk-enable-temperature-alarm-empty'"
            [size]="'large'"
            formControlName="enableTemperatureAlarmEmpty" />
        </div>
        <div class="table-cell">
          <ls-toggle
            [qaTags]="'qa-chk-enable-temperature-alarm-preheat'"
            [size]="'large'"
            formControlName="enableTemperatureAlarmPreheat" />
        </div>
        <div class="table-cell">
          <ls-toggle
            [qaTags]="'qa-chk-enable-temperature-alarm-cleaning'"
            [size]="'large'"
            formControlName="enableTemperatureAlarmCleaning" />
        </div>
        <div class="table-cell">
          <ls-toggle
            [qaTags]="'qa-chk-enable-temperature-alarm-catching'"
            [size]="'large'"
            formControlName="enableTemperatureAlarmCatching" />
        </div>
      </div>

      <!-- The Fourth Row -->
      <div class="table-row">
        <div class="table-cell blue">{{ "Flock.HouseMode.MaximumVentilation" | translate }}</div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinEmpty')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-maximum-ventilation-empty'"
            class="align-center"
            errorField
            formControlName="maximumVentilationEmpty">
          </ls-input-integer>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinPreheat')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-maximum-ventilation-preheat'"
            class="align-center"
            errorField
            formControlName="maximumVentilationPreheat">
          </ls-input-integer>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinCleaning')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-maximum-ventilation-cleaning'"
            class="align-center"
            errorField
            formControlName="maximumVentilationCleaning">
          </ls-input-integer>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinCatching')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-maximum-ventilation-catching'"
            class="align-center"
            errorField
            formControlName="maximumVentilationCatching">
          </ls-input-integer>
        </div>
      </div>

      <!-- The Fifth Row -->
      <div class="table-row">
        <div class="table-cell blue">{{ "Flock.HouseMode.MinimumVentilation" | translate }}</div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinEmpty')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [moreThanTwoErrorsPossible]="true"
            [min]="0"
            [qaTags]="'qa-txt-minimum-ventilation-empty'"
            class="align-center"
            errorField
            formControlName="minimumVentilationEmpty">
          </ls-input-integer>
          <div
            *ngIf="form.hasError('maxMustBeGreaterThanMinEmpty')"
            class="error-field">
            {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
          </div>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinPreheat')"
            [fieldWithKeyboard]="true"
            [moreThanTwoErrorsPossible]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-minimum-ventilation-preheat'"
            class="align-center"
            errorField
            formControlName="minimumVentilationPreheat">
          </ls-input-integer>
          <div
            *ngIf="form.hasError('maxMustBeGreaterThanMinPreheat')"
            class="error-field">
            {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
          </div>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinCleaning')"
            [fieldWithKeyboard]="true"
            [max]="maxVentilation"
            [moreThanTwoErrorsPossible]="true"
            [min]="0"
            [qaTags]="'qa-txt-minimum-ventilation-cleaning'"
            class="align-center"
            errorField
            formControlName="minimumVentilationCleaning">
          </ls-input-integer>
          <div
            *ngIf="form.hasError('maxMustBeGreaterThanMinCleaning')"
            class="error-field">
            {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
          </div>
        </div>
        <div class="table-cell">
          <ls-input-integer
            [class.invalid]="form.hasError('maxMustBeGreaterThanMinCatching')"
            [fieldWithKeyboard]="true"
            [moreThanTwoErrorsPossible]="true"
            [max]="maxVentilation"
            [min]="0"
            [qaTags]="'qa-txt-minimum-ventilation-catching'"
            class="align-center"
            errorField
            formControlName="minimumVentilationCatching">
          </ls-input-integer>
          <div
            *ngIf="form.hasError('maxMustBeGreaterThanMinCatching')"
            class="error-field">
            {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
          </div>
        </div>
      </div>
    </div>
    <ng-template #mobileTemplate>
      <div class="position-relative">
        <div
          *ngIf="swiperService.swiperIsActive"
          class="swiper-navigation d-flex flex-row">
          <ls-svg-icon
            (click)="slidePrev()"
            [class.disabled]="activeSlide === 0"
            [path]="'arrows/swipper-arrow-left'"
            class="icon pointer mr-10 qa-btn-arrow-left">
          </ls-svg-icon>
          <ls-svg-icon
            (click)="slideNext()"
            [class.disabled]="activeSlide === 3"
            [path]="'arrows/swipper-arrow-right'"
            class="icon pointer ml-10 qa-btn-arrow-left">
          </ls-svg-icon>
        </div>
        <swiper
          *ngIf="swiperService.swiperIsActive"
          (activeIndexChange)="changeActiveSlide($event)"
          #swiper
          [cdr]="cdr"
          [config]="swiperService.config"
          [dir]="languageService.direction"
          [formGroup]="form"
          [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
          class="swiper overflow-y-auto pb-15"
          swiperDirective>
          <ng-template swiperSlide>
            <div class="slide d-flex flex-column w-100-percent w-100-percent">
              <div class="swiper-title">
                {{ "Flock.HouseMode.Mode" | translate }} - {{ "Flock.HouseMode.Empty" | translate }}
              </div>
              <div class="table w-1-50 w-2-50">
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.TargetTemperature" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-decimal
                      [accuracy]="1"
                      [max]="maxTargetTemperature"
                      [min]="FlockConstants.MinTargetTemperature"
                      [qaTags]="'qa-txt-target-temperature-empty'"
                      class="align-center"
                      errorField
                      formControlName="targetTemperatureEmpty">
                      <ng-container
                        *ngTemplateOutlet="unit"
                        suffix>
                      </ng-container>
                    </ls-input-decimal>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">
                    {{ "Flock.HouseMode.EnableTA" | translate }}
                  </div>
                  <div class="table-cell">
                    <ls-toggle
                      [qaTags]="'qa-chk-enable-temperature-alarm-empty'"
                      [size]="'large'"
                      formControlName="enableTemperatureAlarmEmpty" />
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MaximumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinEmpty')"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-maximum-ventilation-empty'"
                      class="align-center"
                      errorField
                      formControlName="maximumVentilationEmpty">
                    </ls-input-integer>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MinimumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinEmpty')"
                      [enterKeyHint]="'done'"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-minimum-ventilation-empty'"
                      class="align-center"
                      errorField
                      formControlName="minimumVentilationEmpty">
                    </ls-input-integer>
                    <div
                      *ngIf="form.hasError('maxMustBeGreaterThanMinEmpty')"
                      class="error-field">
                      {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="slide d-flex flex-column w-100-percent w-100-percent">
              <div class="swiper-title">
                {{ "Flock.HouseMode.Mode" | translate }} - {{ "Flock.HouseMode.Preheat" | translate }}
              </div>
              <div class="table w-1-50 w-2-50">
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.TargetTemperature" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-decimal
                      [accuracy]="1"
                      [max]="maxTargetTemperature"
                      [min]="FlockConstants.MinTargetTemperature"
                      [qaTags]="'qa-txt-target-temperature-preheat'"
                      class="align-center"
                      errorField
                      formControlName="targetTemperaturePreheat">
                      <ng-container
                        *ngTemplateOutlet="unit"
                        suffix>
                      </ng-container>
                    </ls-input-decimal>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">
                    {{ "Flock.HouseMode.EnableTA" | translate }}
                  </div>
                  <div class="table-cell">
                    <ls-toggle
                      [qaTags]="'qa-chk-enable-temperature-alarm-preheat'"
                      [size]="'large'"
                      formControlName="enableTemperatureAlarmPreheat" />
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MaximumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinPreheat')"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-maximum-ventilation-preheat'"
                      class="align-center"
                      errorField
                      formControlName="maximumVentilationPreheat">
                    </ls-input-integer>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MinimumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinPreheat')"
                      [enterKeyHint]="'done'"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-minimum-ventilation-preheat'"
                      class="align-center"
                      errorField
                      formControlName="minimumVentilationPreheat">
                    </ls-input-integer>
                    <div
                      *ngIf="form.hasError('maxMustBeGreaterThanMinPreheat')"
                      class="error-field">
                      {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="slide d-flex flex-column w-100-percent w-100-percent">
              <div class="swiper-title">
                {{ "Flock.HouseMode.Mode" | translate }} - {{ "Flock.HouseMode.Cleaning" | translate }}
              </div>
              <div class="table w-1-50 w-2-50">
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.TargetTemperature" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-decimal
                      [accuracy]="1"
                      [max]="maxTargetTemperature"
                      [min]="FlockConstants.MinTargetTemperature"
                      [qaTags]="'qa-txt-target-temperature-cleaning'"
                      class="align-center"
                      errorField
                      formControlName="targetTemperatureCleaning">
                      <ng-container
                        *ngTemplateOutlet="unit"
                        suffix>
                      </ng-container>
                    </ls-input-decimal>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">
                    {{ "Flock.HouseMode.EnableTA" | translate }}
                  </div>
                  <div class="table-cell">
                    <ls-toggle
                      [qaTags]="'qa-chk-enable-temperature-alarm-cleaning'"
                      [size]="'large'"
                      formControlName="enableTemperatureAlarmCleaning" />
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MaximumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinCleaning')"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-maximum-ventilation-cleaning'"
                      class="align-center"
                      errorField
                      formControlName="maximumVentilationCleaning">
                    </ls-input-integer>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MinimumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinCleaning')"
                      [enterKeyHint]="'done'"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-minimum-ventilation-cleaning'"
                      class="align-center"
                      errorField
                      formControlName="minimumVentilationCleaning">
                    </ls-input-integer>
                    <div
                      *ngIf="form.hasError('maxMustBeGreaterThanMinCleaning')"
                      class="error-field">
                      {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
          <ng-template swiperSlide>
            <div class="slide d-flex flex-column w-100-percent w-100-percent">
              <div class="swiper-title">
                {{ "Flock.HouseMode.Mode" | translate }} - {{ "Flock.HouseMode.Catching" | translate }}
              </div>
              <div class="table w-1-50 w-2-50">
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.TargetTemperature" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-decimal
                      [accuracy]="1"
                      [max]="maxTargetTemperature"
                      [min]="FlockConstants.MinTargetTemperature"
                      [qaTags]="'qa-txt-target-temperature-catching'"
                      class="align-center"
                      errorField
                      formControlName="targetTemperatureCatching">
                      <ng-container
                        *ngTemplateOutlet="unit"
                        suffix>
                      </ng-container>
                    </ls-input-decimal>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">
                    {{ "Flock.HouseMode.EnableTA" | translate }}
                  </div>
                  <div class="table-cell">
                    <ls-toggle
                      [qaTags]="'qa-chk-enable-temperature-alarm-catching'"
                      [size]="'large'"
                      formControlName="enableTemperatureAlarmCatching" />
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MaximumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinCatching')"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-maximum-ventilation-catching'"
                      class="align-center"
                      errorField
                      formControlName="maximumVentilationCatching">
                    </ls-input-integer>
                  </div>
                </div>
                <div class="table-row">
                  <div class="table-cell blue">{{ "Flock.HouseMode.MinimumVentilation" | translate }}</div>
                  <div class="table-cell">
                    <ls-input-integer
                      [class.invalid]="form.hasError('maxMustBeGreaterThanMinCatching')"
                      [enterKeyHint]="'done'"
                      [max]="maxVentilation"
                      [min]="0"
                      [qaTags]="'qa-txt-minimum-ventilation-catching'"
                      class="align-center"
                      errorField
                      formControlName="minimumVentilationCatching">
                    </ls-input-integer>
                    <div
                      *ngIf="form.hasError('maxMustBeGreaterThanMinCatching')"
                      class="error-field">
                      {{ "FormErrors.MinValueShouldBeLessThanMax" | translate }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
        <div class="d-flex align-center justify-center mb-15">
          <div
            *ngFor="let dot of [].constructor(4); index as index"
            [class.dot-active]="index === activeSlide"
            class="dot mh-5"></div>
        </div>
      </div>
    </ng-template>
  </div>
  <div class="buttons">
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>

<ng-template #unit>
  <span class="unit">
    <ng-container [ngSwitch]="temperatureUnit">
      <ng-container *ngSwitchCase="TemperatureUnitEnum.Celsius">
        {{ "Celsius" | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="TemperatureUnitEnum.Fahrenheit">
        {{ "Fahrenheit" | translate }}
      </ng-container>
    </ng-container>
  </span>
</ng-template>
