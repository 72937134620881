export enum FarmUserRolesEnum {
  Admin,
  Operator,
  Viewer,
  Owner,
}

export namespace FarmUserRolesEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case FarmUserRolesEnum.Admin:
        return 'Farms.Users.FarmUserRolesEnum.Admin';
      case FarmUserRolesEnum.Operator:
        return 'Farms.Users.FarmUserRolesEnum.Operator';
      case FarmUserRolesEnum.Viewer:
        return 'Farms.Users.FarmUserRolesEnum.Viewer';
      case FarmUserRolesEnum.Owner:
        return 'Farms.Users.FarmUserRolesEnum.Owner';
      default:
        return value.toString();
    }
  }

  export function couldEditUserHierarchy(currentUserRole: FarmUserRolesEnum, editingUserRole: FarmUserRolesEnum): boolean {
    switch (currentUserRole) {
      //owner can edit all users
      case FarmUserRolesEnum.Owner:
        return true;
      //viewer can't edit anybody but himself
      case FarmUserRolesEnum.Viewer:
        return false;
      //operator can edit viewers
      case FarmUserRolesEnum.Operator:
        return editingUserRole === FarmUserRolesEnum.Viewer;
      //admin can edit viewers and operators
      case FarmUserRolesEnum.Admin:
        return editingUserRole === FarmUserRolesEnum.Viewer || editingUserRole === FarmUserRolesEnum.Operator;
      default:
        return false;
    }
  }

  export function getUserRolesForInvite(userRole: FarmUserRolesEnum): FarmUserRolesEnum[] {
    switch (userRole) {
      //operator can invite viewers
      case FarmUserRolesEnum.Operator:
        return [FarmUserRolesEnum.Viewer];
      //admin can invite operators and viewers
      case FarmUserRolesEnum.Admin:
        return [FarmUserRolesEnum.Viewer, FarmUserRolesEnum.Operator];
      //owner can invite anybody
      case FarmUserRolesEnum.Owner:
        return [FarmUserRolesEnum.Viewer, FarmUserRolesEnum.Operator, FarmUserRolesEnum.Admin];
      //viewer can't invite anybody
      default:
        return [];
    }
  }
}
