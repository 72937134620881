import { CommonModule } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Host,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  SimpleChanges,
  SkipSelf,
  ViewChild,
} from '@angular/core';
import {
  AbstractControl,
  ControlContainer,
  ControlValueAccessor,
  FormControl,
  FormsModule,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
} from '@angular/forms';
import { errFade } from '@livestock/shared/animations';
import { QaTagsDirective } from '@livestock/shared/directives';
import { LanguageService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

import { NativeElementInjectorDirective } from '../native-element.directive';

@Component({
  selector: 'lv-input',
  templateUrl: './lv-input.component.html',
  styleUrls: ['./lv-input.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    NgxMaskDirective,
    NgxMaskPipe,
    TranslateModule,
    NativeElementInjectorDirective,
  ],
  animations: [
    errFade,
  ],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => LVInputComponent),
  }, {
    provide: NG_VALIDATORS,
    multi: true,
    useExisting: forwardRef(() => LVInputComponent),
  },
    provideNgxMask(),
  ],
})
export class LVInputComponent implements ControlValueAccessor, Validator, OnInit, OnChanges, AfterViewInit, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('rightButton', { static: false }) rightButton: ElementRef = new ElementRef(null);
  @ViewChild('rightIcon', { static: false }) rightIcon: ElementRef = new ElementRef(null);

  @Input() formControlName: string = '';
  @Input() label: string = '';
  @Input() labelFieldType: string;
  @Input() placeholder: string = '';
  @Input() type: 'text' | 'password' | 'email' = 'text';
  @Input() autocomplete: string;
  @Input() maxlength: string = '';
  @Input() textPosition: 'left' | 'center' | 'right' = 'left';
  @Input() autofocus: boolean = false;
  @Input() pattern: string = '';
  @Input() options: number[] = [];
  @Input() isDisabled: boolean = false;
  @Input() qaTags: string;

  @Output() onChange: EventEmitter<any> = new EventEmitter();
  @Output() inputFocus: EventEmitter<any> = new EventEmitter();
  @Output() inputBlur: EventEmitter<any> = new EventEmitter();

  control: AbstractControl = new FormControl();
  filteredOptions: number[] = [];
  isRightButtonExist = false;
  isRightIconExist = false;

  private _value: any;

  constructor(
    public languageService: LanguageService,
    private cdr: ChangeDetectorRef,
    @Optional() @Host() @SkipSelf()
    private controlContainer: ControlContainer,
  ) {
  }

  @Input('value')
  set value(value: string) {
    this._value = value;
  }

  get value(): string {
    return this._value;
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.['value']) {
      this.writeValue(this._value);
    }
  }

  ngOnInit(): void {
    if (this.formControlName && this.controlContainer?.control) {
      this.control = this.controlContainer.control.get(this.formControlName) as AbstractControl;

      if (this.control) {
        this.isDisabled = this.isDisabled || this.control.disabled;

        this.control.statusChanges.subscribe(() => {
          this.isDisabled = this.control.disabled;
        });
      }
    }

    this.filterOptions(this.value);
  }

  ngAfterViewInit(): void {
    this.isRightButtonExist = this.rightButton?.nativeElement?.children?.length > 0;
    this.isRightIconExist = this.rightIcon?.nativeElement?.children?.length > 0;
    this.cdr.detectChanges();
  }

  writeValue(value: any): void {
    this._value = value;
  }

  propagateChange: (value: any) => void = () => {
  };

  _onTouched = (): void => {
  };

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => object): void {
    this._onTouched = fn;
  }

  focusInput(): void {
    this.inputRef.nativeElement.focus();
  }

  validate(): ValidationErrors | null {
    return null;
  }

  onModelChange(e: string): void {
    this.value = e;

    this.propagateChange(e);
    this.onChange.emit(this.value);
  }

  ngOnDestroy(): void {
    this.cdr.detach();
  }

  private filterOptions(start: string): void {
    this.filteredOptions = this.options.filter(option => String(option).startsWith(start || ''));
  }
}
