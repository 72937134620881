import {
  Directive,
  ElementRef,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Renderer2,
  SimpleChanges,
} from '@angular/core';
import { AbstractControl, FormControl, NgControl, ValidationErrors } from '@angular/forms';
import { Subscription, startWith, lastValueFrom } from 'rxjs';
import { ErrorFieldEnum } from '../enums';
import { Patterns } from '../constants';
import { TranslateService } from '@ngx-translate/core';
import { PlatformService } from '../services';

@Directive({
  selector: '[errorField]',
  standalone: true,
})
export class ErrorFieldDirective implements OnInit, OnDestroy, OnChanges {
  @Input() showErrorByFocus: boolean = false;
  @Input() errTooltipControl: FormControl | AbstractControl;
  @Input() forceDisabled = false;
  @Input() validateWithInitValue = false;
  @Input() forcedErrorKey: string;
  @Input() moreThanTwoErrorsPossible = false;
  @Input() showTooltipOnHover = !!this.platformService.isMobileApp;

  private tooltip: HTMLElement;
  private errors: ValidationErrors;
  private errorMsg: string;
  private errorMsgValue: string | number;
  private sub$: Subscription = new Subscription();

  @HostListener('focusin', ['$event'])
  focusin(): void {
    if (this.platformService.isDesktopApp || this.errorMsg == null) return;
    this.showTooltip(this.errorMsg, this.errorMsgValue);
  }

  @HostListener('focusout', ['$event'])
  focusout(): void {
    if (this.platformService.isDesktopApp || !this.showTooltipOnHover) return;
    this.hideTooltip();
  }

  @HostListener('mouseover', ['$event'])
  mouseover(): void {
    if (!this.platformService.isDesktopApp || !this.errorMsg) return;
    this.showTooltip(this.errorMsg, this.errorMsgValue);
  }

  @HostListener('mouseout', ['$event'])
  mouseout(): void {
    if (!this.platformService.isDesktopApp || !this.showTooltipOnHover) return;
    this.hideTooltip();
  }

  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private ngControl: NgControl,
    private translateService: TranslateService,
    private platformService: PlatformService,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['errTooltipControl']?.currentValue != null &&
      changes['errTooltipControl'].currentValue !== changes['errTooltipControl'].previousValue
    ) {
      this.ngOnDestroy();
      this.sub$ = new Subscription();
      this.onValueChanges();
      this.validate(this.errTooltipControl);
    }
  }

  ngOnInit(): void {
    this.onValueChanges();
  }

  onValueChanges(): void {
    const control = this.errTooltipControl ?? this.ngControl.control;

    if (!this.validateWithInitValue) {
      this.sub$.add(control.valueChanges.subscribe(() => {
        this.validate(control);
      }));
      return;
    }

    this.sub$.add(control.valueChanges.pipe(
      startWith(control.getRawValue),
    ).subscribe(() => {
      this.validate(control);
    }));
  }

  validate(control: AbstractControl): void {
    const { errors } = control;

    if (JSON.stringify(this.errors) === JSON.stringify(errors)) {
      return;
    }

    this.errors = errors;

    if (errors != null) {
      const errorKey = this.forcedErrorKey ? Object.keys(errors).find(i => i === this.forcedErrorKey) : Object.keys(errors)[0];

      this.forceDisabled = this.forcedErrorKey && !errorKey;

      switch (errorKey) {
        case ErrorFieldEnum.Required:
          this.errorMsg = 'FormErrors.ThisFieldIsRequired';
          break;
        case ErrorFieldEnum.Pattern:
          this.setPatternErrorMessage(errors[errorKey].requiredPattern);
          break;
        case ErrorFieldEnum.InvalidPhoneNumber:
          this.errorMsg = 'FormErrors.FormatInvalidUsePlusAndNumbers';
          break;
        case ErrorFieldEnum.MinLength:
          this.errorMsgValue = errors['minlength']['requiredLength'];
          this.errorMsg = 'FormErrors.MinLength';
          break;
        case ErrorFieldEnum.MaxLength:
          this.errorMsgValue = errors['maxlength']['requiredLength'];
          this.errorMsg = 'FormErrors.MaxLength';
          break;
        case ErrorFieldEnum.Min:
          this.errorMsgValue = errors['min']['min'];
          this.errorMsg = 'FormErrors.MinValue';
          break;
        case ErrorFieldEnum.DaysDupl:
          this.errorMsg = 'FormErrors.DaysDupl';
          break;
        case ErrorFieldEnum.Max:
          this.errorMsgValue = errors['max']['max'];
          this.errorMsg = 'FormErrors.MaxValue';
          break;
        case ErrorFieldEnum.DuplicatedDay:
          this.errorMsg = 'FormErrors.DuplicatedDay';
          break;
        case ErrorFieldEnum.InvalidWeight:
          this.errorMsgValue = errors['invalidWeight']['message'];
          this.errorMsg = 'FormErrors.InvalidWeight';
          break;
        case ErrorFieldEnum.WeightShouldBeMoreThan:
          this.errorMsgValue = errors['weightShouldBeMoreThan']['message'];
          this.errorMsg = 'FormErrors.WeightShouldBeMoreThan';
          break;
        case ErrorFieldEnum.InnerOverlap:
          this.errorMsg = 'FormErrors.EndTime';
          break;
        case ErrorFieldEnum.OuterOverlap:
          this.errorMsg = 'FormErrors.StartTime';
          break;
        case ErrorFieldEnum.GreaterThan:
          this.errorMsgValue = errors['gt']['min'];
          this.errorMsg = 'FormErrors.MinValue';
          break;
        case ErrorFieldEnum.LessThan:
          this.errorMsgValue = errors['lt']['max'];
          this.errorMsg = 'FormErrors.MaxValue';
          break;
        case ErrorFieldEnum.Email:
          this.errorMsg = 'Auth.PleaseEnterAnEmail';
          break;
        default:
          this.errorMsg = 'FormErrors.Error';
          break;
      }

      if (this.showTooltipOnHover) {
        return;
      }

      this.showTooltip(this.errorMsg, this.errorMsgValue);
      return;
    }

    this.errorMsg = null;
    this.errorMsgValue = null;
    this.hideTooltip();
  }

  setPatternErrorMessage(pattern: string): void {
    // ^[....]$ => [....]
    const patternRaw = pattern.slice(1, -1);

    switch (patternRaw) {
      case Patterns.EmailPattern:
        this.errorMsg = 'Auth.PleaseEnterAValidEmailAddress';
        break;
      default:
        break;
    }
  }

  async showTooltip(msg: string, value: string | number | null = null): Promise<void> {
    if (this.forceDisabled) {
      this.hideTooltip();
      return;
    }

    if (this.tooltip != null) {
      this.hideTooltip();
    }

    this.tooltip = this.renderer.createElement('div');

    this.renderer.addClass(this.tooltip, this.showTooltipOnHover ? 'error-tooltip' : 'error-field');

    if (this.showTooltipOnHover) {
      const { clientWidth } = (<HTMLElement>this.elementRef.nativeElement);
      this.renderer.setStyle(this.tooltip, 'width', `${clientWidth < 120 ? 120 : clientWidth}px`);
    }

    const translatedMessage = await lastValueFrom(this.translateService.get(msg, { value }));

    this.renderer.appendChild(this.tooltip, this.renderer.createText(translatedMessage));
    const parent = this.showTooltipOnHover
      ? this.elementRef.nativeElement
      : this.elementRef.nativeElement.parentElement;

    if (this.moreThanTwoErrorsPossible) {
      setTimeout(() => this.setChildNodesPosition(parent, '.error-field', 'unset'));
    }

    this.renderer.appendChild(parent, this.tooltip);
  }

  hideTooltip(): void {
    if (this.tooltip == null) return;
    this.renderer.removeChild(this.elementRef.nativeElement, this.tooltip);
    this.tooltip = null;
    const parent = this.showTooltipOnHover
      ? this.elementRef.nativeElement
      : this.elementRef.nativeElement.parentElement;
    setTimeout(() => this.setChildNodesPosition(parent, '.error-field', 'absolute'));
  }

  setChildNodesPosition(parent: any, childClassName: '.error-field' | '.error-tooltip', position: 'unset' | 'absolute'): void {
    parent.querySelectorAll(childClassName).forEach(child => {
      child.style.position = position;
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.hideTooltip();
  }
}
