import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import {
  AirPressureUnitEnum,
  IconsEnum,
  TemperatureUnitEnum,
} from '@livestock/shared/enums';
import {
  ButtonComponent,
  InputDecimalComponent,
  KeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { ErrorFieldDirective } from '@livestock/shared/directives';
import { TranslateModule } from '@ngx-translate/core';
import { filter, firstValueFrom, Observable, Subscription } from 'rxjs';
import { selectCurrentControllerPressureUnit, selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { Store } from '@ngrx/store';
import { selectStaticPressureView } from '../../+state/static-pressure.selectors';
import { IStaticPressure } from '../../interfaces/static-pressure.interface';
import { updateStaticPressure } from '../../+state/static-pressure.actions';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-static-pressure-form',
  templateUrl: './static-pressure-form.component.html',
  styleUrls: ['./static-pressure-form.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    KeyboardComponent,
    ReactiveFormsModule,
    InputDecimalComponent,
    ErrorFieldDirective,
    TranslateModule,
    ButtonComponent,
  ],
})
export class StaticPressureFormComponent implements OnInit, OnDestroy {
  @Output() closeEditMode = new EventEmitter();
  @Output() isDirtyFormState = new EventEmitter<boolean>();
  //subs
  sub$ = new Subscription();
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);
  pressureUnit$: Observable<AirPressureUnitEnum> = this.store.select(selectCurrentControllerPressureUnit);
  //vars
  isDirtyForm;
  IconsEnum: typeof IconsEnum = IconsEnum;

  _editMode: boolean;

  get editMode(): boolean {
    return this._editMode;
  }

  @Input() set editMode(editMode: boolean) {
    this._editMode = editMode;

    // restore form if cancelled without saving
    if (!editMode && JSON.stringify(this.originalView) !== JSON.stringify(this.form.getRawValue())) {
      this.form.patchValue(this.originalView);
      this.validateForm();
    }
  }

  form = new FormGroup(
    {
      elementID: new FormControl<number>(0),
      bandVentilation: new FormControl<number>(0),
      outsideTempColdVentilation: new FormControl<number>(0),
      outsideTempWarmVentilation: new FormControl<number>(0),
      targetPressureColdVentilation: new FormControl<number>(0),
      targetPressureWarmVentilation: new FormControl<number>(0),
      pressureAlarmLowVentilation: new FormControl<number>(0),
      pressureAlarmHightVentilation: new FormControl<number>(0),
      bandTunnel: new FormControl<number>(0),
      targetPressureTunnel: new FormControl<number>(0),
      pressureAlarmLowTunnel: new FormControl<number>(0),
      pressureAlarmHightTunnel: new FormControl<number>(0),
    },
  );
  pressureAccuracy: number;
  temperatureAccuracy = 1;
  minMaxValues: object;
  originalView: IStaticPressure;
  //constants
  TemperatureUnitEnum = TemperatureUnitEnum;
  AirPressureUnitEnum = AirPressureUnitEnum;
  pressureControls = [
    'bandVentilation',
    'targetPressureColdVentilation',
    'targetPressureWarmVentilation',
    'pressureAlarmLowVentilation',
    'pressureAlarmHightVentilation',
    'bandTunnel',
    'targetPressureTunnel',
    'pressureAlarmLowTunnel',
    'pressureAlarmHightTunnel',
  ];
  temperatureControls = [
    'outsideTempColdVentilation',
    'outsideTempWarmVentilation',
  ];

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectStaticPressureView).pipe(
        filter(res => res != null),
      ).subscribe(view => {
        this.originalView = view;
        this.form.patchValue(view);
        this.validateForm();
      }),
    );

    this.sub$.add(
      this.form.valueChanges.pipe(
        filter(() => !!this.originalView),
        wasChanged(),
      ).subscribe(values => {
        this.isDirtyForm = JSON.stringify(values) !== JSON.stringify(this.originalView);
        this.isDirtyFormState.emit(this.isDirtyForm);
      }),
    );
  }

  async validateForm(): Promise<void> {
    const temperatureUnit = await firstValueFrom(this.temperatureUnit$.pipe(
      filter(res => res != null),
    ));

    const pressureUnit = await firstValueFrom(this.pressureUnit$.pipe(
      filter(res => res != null),
    ));

    this.pressureAccuracy = pressureUnit === AirPressureUnitEnum.Pascal
      ? 0
      : 3;

    switch (temperatureUnit) {
      case TemperatureUnitEnum.Fahrenheit: {
        this.minMaxValues = {
          outsideTempColdVentilation: {
            min: -40,
            max: 59,
          },
          outsideTempWarmVentilation: {
            min: 14,
            max: 194,
          },
        };
        break;
      }
      case TemperatureUnitEnum.Celsius:
      default: {
        this.minMaxValues = {
          outsideTempColdVentilation: {
            min: -40,
            max: 15,
          },
          outsideTempWarmVentilation: {
            min: -10,
            max: 90,
          },
        };
        break;
      }
    }

    switch (pressureUnit) {
      case AirPressureUnitEnum.MmOfWater: {
        this.minMaxValues = {
          ...this.minMaxValues,
          bandVentilation: {
            min: 0,
            max: 2.039,
          },
          targetPressureColdVentilation: {
            min: 2.039,
            max: 10.197,
          },
          targetPressureWarmVentilation: {
            min: 0,
            max: 2.549,
          },
          pressureAlarmLowVentilation: {
            min: 0,
            max: 4.079,
          },
          pressureAlarmHightVentilation: {
            min: 0.306,
            max: 10.197,
          },
          bandTunnel: {
            min: 0,
            max: 2.039,
          },
          targetPressureTunnel: {
            min: 0,
            max: 10.197,
          },
          pressureAlarmLowTunnel: {
            min: 0,
            max: 4.079,
          },
          pressureAlarmHightTunnel: {
            min: 0.510,
            max: 10.197,
          },
        };
        break;
      }
      case AirPressureUnitEnum.InchesOfWater: {
        this.minMaxValues = {
          ...this.minMaxValues,
          bandVentilation: {
            min: 0,
            max: 0.08,
          },
          targetPressureColdVentilation: {
            min: 0.08,
            max: 0.401,
          },
          targetPressureWarmVentilation: {
            min: 0,
            max: 0.1,
          },
          pressureAlarmLowVentilation: {
            min: 0,
            max: 0.161,
          },
          pressureAlarmHightVentilation: {
            min: 0.012,
            max: 0.401,
          },
          bandTunnel: {
            min: 0,
            max: 0.8,
          },
          targetPressureTunnel: {
            min: 0,
            max: 0.401,
          },
          pressureAlarmLowTunnel: {
            min: 0,
            max: 0.161,
          },
          pressureAlarmHightTunnel: {
            min: 0.02,
            max: 0.401,
          },
        };
        break;
      }
      case AirPressureUnitEnum.Pascal:
      default: {
        this.minMaxValues = {
          ...this.minMaxValues,
          bandVentilation: {
            min: 0,
            max: 20,
          },
          targetPressureColdVentilation: {
            min: 20,
            max: 100,
          },
          targetPressureWarmVentilation: {
            min: 0,
            max: 25,
          },
          pressureAlarmLowVentilation: {
            min: 0,
            max: 40,
          },
          pressureAlarmHightVentilation: {
            min: 3,
            max: 100,
          },
          bandTunnel: {
            min: 0,
            max: 20,
          },
          targetPressureTunnel: {
            min: 0,
            max: 100,
          },
          pressureAlarmLowTunnel: {
            min: 0,
            max: 40,
          },
          pressureAlarmHightTunnel: {
            min: 5,
            max: 100,
          },
        };
        break;
      }
    }

    [...this.pressureControls, ...this.temperatureControls].forEach(control => {
      this.form.controls[control].setValidators([
        Validators.min(this.minMaxValues[control].min),
        Validators.max(this.minMaxValues[control].max),
      ]);
    });

    this.form.updateValueAndValidity();
  }

  async resetForm(): Promise<void> {
    if (this.isDirtyForm) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return;
      }
    }

    this.isDirtyForm = false;
    this.form.patchValue(this.originalView);
    this.validateForm();
    this.isDirtyFormState.emit(false);
    this.isDirtyForm = false;
    this.closeEditMode.emit(false);
  }

  save(): void {
    this.originalView = this.form.getRawValue();
    this.isDirtyFormState.emit(false);
    this.isDirtyForm = false;
    this.store.dispatch(updateStaticPressure({ view: this.originalView }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
