import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { catchError, defaultIfEmpty, filter, forkJoin, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { CoolingProgramService } from '../service/cooling-program-api.service';
import * as coolingActions from './cooling-program.actions';
import { setFlashMessage, FlashMessageTypeEnum } from '@livestock/notifications';
import { selectCurrentCoolingPeriod } from './cooling-program.selectors';

@Injectable()
export class CoolingProgramEffects {
  getCoolingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.getCoolingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([action, controllerID]) => {
        if (action.periodID) {
          return this.coolingService.getCoolingProgram(controllerID, action.periodID).pipe(
            map((item) => coolingActions.getCoolingProgramSuccess({ item })),
            catchError((error) => of(coolingActions.getCoolingProgramError({ error }))),
          );
        } else {
          return of(coolingActions.getCoolingProgramSuccess({
            item: {
              hasElement1: true,
              hasElement2: true,
              hasElement3: true,
              items: [],
            },
          }));
        }
      }),
    ));

  getCoolingPeriod$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.getCoolingPeriod),
      switchMap(() => this.store.select(selectActiveControllerID)),
      filter(controllerID => controllerID !== null),
      switchMap((controllerID) => {
        return this.coolingService.getCoolingPeriod(controllerID).pipe(
          map(({ items }) => coolingActions.getCoolingPeriodSuccess({ items, controllerID })),
          catchError((error) => of(coolingActions.getCoolingPeriodError({ error }))),
        );
      }),
    ));

  addCoolingDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.addCoolingDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      map(([{ dayNumber }, controllerID]) => coolingActions.addCoolingDaySuccess({ dayNumber, controllerID })),
      catchError((error) => of(coolingActions.addCoolingDayError({ error }))),
    ),
  );

  deleteCoolingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.deleteCoolingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, programID }, controllerID]) => {
        return this.coolingService.deleteProgram(controllerID, periodID, programID).pipe(
          map(() => coolingActions.deleteCoolingProgramSuccess({ periodID, programID })),
          catchError((error) => of(coolingActions.deleteCoolingProgramError({ error }))),
        );
      }),
    ));

  deleteCoolingDay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.deleteCoolingDay),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ periodID, preventSelecting }, controllerID]) => {
        if (periodID) {
          return this.coolingService.deleteCoolingDay(controllerID, periodID).pipe(
            map(() => coolingActions.deleteCoolingDaySuccess({ periodID, preventSelecting })),
            tap(() => {
              this.store.dispatch(
                setFlashMessage({
                  flashMessage: {
                    flashType: FlashMessageTypeEnum.Success,
                    message: 'Controls.Element.YouDeletedThisDay',
                  },
                }),
              );
            }),
            catchError((error) => of(coolingActions.deleteCoolingProgramError({ error }))),
          );
        } else {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'Controls.Element.YouDeletedThisDay',
              },
            }),
          );
          return of(coolingActions.deleteCoolingDaySuccess({ periodID, preventSelecting, preventLoading: true }));
        }
      }),
    ));

  deleteCoolingDaySuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.deleteCoolingDaySuccess),
      withLatestFrom(this.store.select(selectCurrentCoolingPeriod)),
      filter(([{ preventLoading }]) => !preventLoading),
      map(([_, period]) => coolingActions.getCoolingProgram({ periodID: period?.periodID })),
    ),
  );

  saveCoolingProgram$ = createEffect(() =>
    this.actions$.pipe(
      ofType(coolingActions.saveCoolingProgram),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ period, items, itemsToDelete }, controllerID]) => {
        if (period.periodID) {
          return of({
            period,
            items,
            controllerID,
            itemsToDelete,
          });
        } else {
          return this.coolingService.addCoolingDay(controllerID, period).pipe(map(period => (
            {
              items,
              period,
              controllerID,
              itemsToDelete,
            })));
        }
      }),
      switchMap(({ period, items, controllerID, itemsToDelete }) => {
        return forkJoin(itemsToDelete.map(i => this.coolingService.deleteProgram(controllerID, i.periodID, i.programID))).pipe(
          defaultIfEmpty(null),
          map(() => ({
            items,
            period,
            controllerID,
            itemsToDelete,
          })),
        );
      }),
      switchMap(({ period, items, controllerID }) =>
        forkJoin(items.map(prog => this.coolingService[prog.programID ? 'editCoolingProgram' : 'addCoolingProgram'](controllerID, period.periodID, {
          ...prog,
          periodID: prog.periodID || period.periodID,
        })))
          .pipe(
            defaultIfEmpty(null),
            tap(() => {
              this.store.dispatch(
                setFlashMessage({
                  flashMessage: {
                    flashType: FlashMessageTypeEnum.Success,
                    message: 'FlashMessages.SaveChangesSucceeded',
                  },
                }),
              );
            }),
            map((items) => coolingActions.saveCoolingProgramSuccess({ period, items })),
            catchError(() => of(coolingActions.getCoolingProgram({ periodID: items[0].periodID }))),
          )),
    ));

  constructor(
    private actions$: Actions,
    private store: Store,
    private coolingService: CoolingProgramService,
  ) {
  }
}
