<div class="card-items__wrapper">
  <div class="card-items d-flex align-center justify-between">
    <!--creation of elements should be enabled only on device, but for now it could be done via web too for presentation-->
    <div
      *ngFor="let connection of connections"
      (click)="setActiveConnection(connection)"
      [class.card-item__active]="(currentConnectionID$ | async) === connection.connectionID"
      [qaTags]="'qa-lnk-con-box'"
      class="card-item color-tertiary fz-16 fw-500 d-flex justify-between pointer">
      <div class="card-item__number">
        <span [qaTags]="'qa-lbl-con-num'">{{ connection.number }}</span>
      </div>

      <!--for saved connections-->
      <div
        *ngIf="
          connection.element ||
          (connection.connectionID === (currentConnectionID$ | async) && (currentConnectionSetupIsNew$ | async))
        "
        class="card-item__icon d-flex flex-column justify-between align-center">
        <!-- if a connection doesn't have an element than this element is new and need to use currentSelectedElement (elementType) -->
        <ls-svg-icon
          [color]="ColorsEnum.BlueDefault"
          [fullHeight]="true"
          [mirrorRtl]="false"
          [overlayPath]="connection.element?.isWarning ? 'warning' : null"
          [path]="connection.element?.elementType || elementType | memoizeFunc : ElementTypesEnum.getSvgPathFilled"
          [qaTags]="'qa-img-element-type'"></ls-svg-icon>
        <img
          *ngIf="GlobalConstants.relayIndicatorTypes.includes(connection.element?.elementType || elementType)"
          class="indicator"
          src="assets/icons/relay-indicator.svg" />
        <div class="card-item__icon-count">
          <ng-container
            *ngIf="
              connection.connectionID === (currentConnectionID$ | async) && (currentConnectionSetupIsNew$ | async);
              else infoFromConnection
            ">
            <ng-container *ngIf="currentConnectionSetupData$ | async as currentConnectionSetupData">
              <span [qaTags]="'qa-lbl-element-num'">{{ currentConnectionSetupData.number | number : "2.0" }}</span>
            </ng-container>
          </ng-container>

          <ng-template #infoFromConnection>
            <span [qaTags]="'qa-lbl-element-num'">{{ connection.element.number | number : "2.0" }}</span>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div>
