import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ControllersService } from 'libs/controllers/src/lib/services/controllers.service';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import { DialogsService } from '@livestock/shared/services';

export const HeatingElementsGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
    const controllersService = inject(ControllersService);
    const dialogService = inject(DialogsService);

    const doElementType = route.data['doElementType'];
    const aoElementType = route.data['aoElementType'];
    const controllerID = +route.paramMap.get('controllerID');
    const isHeatingElementsExist = await firstValueFrom(
        forkJoin([
            controllersService
                .getControllerElements(controllerID, doElementType),
            controllersService
                .getControllerElements(controllerID, aoElementType),
        ])
            .pipe(map(([doItems, aoItems]) => !!doItems.items.length || !!aoItems.items.length)),
    );

    if (!isHeatingElementsExist) {
        dialogService.error('Controller.Menu.PleaseCreateElementsBeforeEdit', 'Controller.Menu.NoAvailableElements');
        return false;
    }

    return true;
};
