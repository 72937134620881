import { Action, createReducer, on } from '@ngrx/store';
import { HeatingProgramZoneState } from './heating-program-zone.state';
import * as heatingProgramZoneActions from './heating-program-zone.actions';
import { IHeatingProgramZoneItem } from '../interfaces';
import { IElement } from '@livestock/installation/interfaces';

const emptyZone: IHeatingProgramZoneItem = {
  controllerID: null,
  zoneID: null,
  name: '',
  isAverage: false,
  heatingElements: [],
  sensorElements: [],
  isUsedInProgram: false,
};

export const initialState: HeatingProgramZoneState = {
  isLoading: false,
  activeZoneID: null,
  view: null,
  selectedHeaters: [],
  originalZones: [],
};

const reducer = createReducer(
  initialState,
  on(
    heatingProgramZoneActions.getHeatingProgramZones,
    heatingProgramZoneActions.updateHeatingProgramZones,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    heatingProgramZoneActions.getHeatingProgramZonesError,
    heatingProgramZoneActions.updateHeatingProgramZonesError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(heatingProgramZoneActions.getHeatingProgramZonesSuccess, (state, { view }) => {
      return {
        ...state,
        view,
        originalZones: view.items,
        isLoading: false,
      };
    },
  ),
  on(
    heatingProgramZoneActions.updateHeatingProgramZonesSuccess, (state, { zones }) => {
      const sortedZones = [...zones]
        .sort((a, b) => a.zoneID - b.zoneID);

      return {
        ...state,
        view: {
          ...state.view,
          items: sortedZones,
        },
        originalZones: sortedZones,
        isLoading: false,
      };
    }),
  on(heatingProgramZoneActions.setActiveHeatingProgramZone, (state, { zoneID }) => {
      return {
        ...state,
        activeZoneID: zoneID,
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.addHeatingElementToZone, (state, { heatingElement }) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(zone => zone.zoneID === state.activeZoneID);

      if (activeZoneIndex !== -1) {
        const currentHeatingElementType = zones[activeZoneIndex].heatingElements?.[0]?.elementType;
        const newHeatingElementType = heatingElement.elementType;
        const heatingElements = currentHeatingElementType === newHeatingElementType
          ? [
            ...zones[activeZoneIndex].heatingElements,
            heatingElement,
          ]
          : [heatingElement];

        zones[activeZoneIndex] = {
          ...zones[activeZoneIndex],
          heatingElements,
        };
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
      };
    },
  ),
  on(heatingProgramZoneActions.addSensorsElementsToZone, (state, { sensors }) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(zone => zone.zoneID === state.activeZoneID);

      if (activeZoneIndex !== -1) {
        zones[activeZoneIndex] = {
          ...zones[activeZoneIndex],
          sensorElements: sensors,
        };
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
      };
    },
  ),
  on(heatingProgramZoneActions.goToNextHeatingProgramZone, (state) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(zone => zone.zoneID === state.activeZoneID);

      const newActiveZoneID = (activeZoneIndex >= zones.length - 1 || activeZoneIndex === -1)
        ? zones[0]?.zoneID
        : zones[activeZoneIndex + 1].zoneID;

      return {
        ...state,
        activeZoneID: newActiveZoneID,
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.addHeatingProgramZone, (state) => {
      let zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(zone => zone.zoneID === state.activeZoneID);

      // temporary random zoneID for new zones. Real ones will be sent from backend after successful save.
      // negative for backend purposes
      const zoneID = -getMaxZoneID(zones) - 1;
      const newZone = { ...emptyZone, name: `Zone ${zones.length + 1}`, zoneID };

      if (activeZoneIndex !== -1) {
        zones.splice(activeZoneIndex + 1, 0, newZone);
      } else {
        zones = [...zones, newZone];
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
        activeZoneID: zoneID,
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.removeHeatingProgramZone, (state) => {
      let zones = [...state.view.items];
      const zoneIndex = zones.findIndex(zone => zone.zoneID === state.activeZoneID);

      if (zoneIndex !== -1) {
        zones = zones.filter(zone => zone.zoneID !== state.activeZoneID);
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
        activeZoneID: zones[zoneIndex - 1]?.zoneID,
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.changeZoneName, (state, { name }) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(item => item.zoneID === state.activeZoneID);
      if (activeZoneIndex !== -1) {
        zones[activeZoneIndex] = {
          ...zones[activeZoneIndex],
          name,
        };
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
      };
    },
  ),
  on(heatingProgramZoneActions.changeIsAverage, (state, { isAverage }) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(item => item.zoneID === state.activeZoneID);
      if (activeZoneIndex !== -1) {
        zones[activeZoneIndex] = {
          ...zones[activeZoneIndex],
          isAverage,
          sensorElements: [],
        };
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
      };
    },
  ),
  on(heatingProgramZoneActions.addHeaterToMovingArray, (state, { elementID }) => {
      return {
        ...state,
        selectedHeaters: [...state.selectedHeaters, elementID],
      };
    },
  ),
  on(heatingProgramZoneActions.removeHeaterFromMovingArray, (state, { elementID }) => {
      return {
        ...state,
        selectedHeaters: [...state.selectedHeaters].filter(heaterID => heaterID !== elementID),
      };
    },
  ),
  on(heatingProgramZoneActions.moveHeatersToAnotherZone, (state, { zoneID }) => {
      const zones = [...state.view.items];
      const activeZoneIndex = zones.findIndex(item => item.zoneID === state.activeZoneID);
      if (activeZoneIndex !== -1) {
        zones[activeZoneIndex] = {
          ...zones[activeZoneIndex],
          heatingElements: [...zones[activeZoneIndex].heatingElements].filter(el => !state.selectedHeaters.includes(el.elementID)),
        };

        const zoneToMoveIndex = zones.findIndex(item => item.zoneID === zoneID);
        const heaters: IElement[] = [...state.view.heatingAOElements, ...state.view.heatingDOElements]
          .filter(el => state.selectedHeaters.includes(el.elementID));
        zones[zoneToMoveIndex] = {
          ...zones[zoneToMoveIndex],
          heatingElements: [...zones[zoneToMoveIndex].heatingElements, ...heaters],
        };
      }

      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.setOriginalZones, (state, { zones }) => {
      return {
        ...state,
        view: {
          ...state.view,
          items: zones,
        },
        activeZoneID: null,
        selectedHeaters: [],
      };
    },
  ),
  on(heatingProgramZoneActions.clearHeatingZonesState, () => {
    return {
      ...initialState,
    };
  }),
);

function getMaxZoneID(zones: IHeatingProgramZoneItem[]): number {
  if (zones.length === 0) {
    return 0;
  }

  return Math.max(...zones.map(zone => Math.abs(zone.zoneID)));
}

export function heatingProgramZoneReducer(state: HeatingProgramZoneState | undefined, action: Action): any {
  return reducer(state, action);
}
