import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective, SvgIconComponent],
  selector: 'ls-heat-widget',
  templateUrl: './heat-widget.component.html',
  styleUrls: ['./heat-widget.component.scss'],
})
export class HeatWidgetComponent {
  heatZones: number[] = Array.from({ length: 15 }, (_, index) => index + 1);
  ColorsEnum = ColorsEnum;
}
