<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-20">
    <label qaTags="qa-label-name">{{ "Installation.Main.DeviceName" | translate }}</label>
    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-name'"
        errorField
        formControlName="name"></ls-input-text>
      <!-- copy btn -->
      <ng-content></ng-content>
    </div>
  </div>
  <div class="device-form__field device-form__field-margin">
    <label>{{ "Installation.Main.Operation" | translate }}</label>
    <ng-select
      [clearable]="false"
      [dropdownPosition]="'bottom'"
      [formControlName]="'operation'"
      [qaTags]="'qa-btn-lighting-do-operation'"
      [searchable]="false"
      placeholder="{{ 'Installation.Main.Operation' | translate }}"
      selectTitle="{{ 'Installation.Main.Operation' | translate }}">
      <ng-container>
        <ng-option
          *ngFor="let el of ElementOperationEnum | enumToArray"
          [value]="el">
          {{ el | enum : "element-operation" | translate }}
        </ng-option>
      </ng-container>
    </ng-select>
  </div>
</div>
