<section class="controller-menu">
  <ng-container *ngIf="!platformService.isMd">
    <nav *ngFor="let set of items; trackBy: trackByFn">
      <h2 class="controller-menu__title qa-lbl-section {{ set.qaTag }}">
        {{ set.title }}
      </h2>
      <ul class="controller-menu__block">
        <ng-container *ngTemplateOutlet="list; context: { set: set }"></ng-container>
      </ul>
    </nav>
  </ng-container>

  <ng-container *ngIf="platformService.isMd">
    <p class="fz-18 mb-18">{{ "ControllerMenu.Menu" | translate }}</p>
    <div
      *ngIf="swiperService.swiperIsActive"
      class="pagination">
      <div
        *ngFor="let dot of [].constructor(3); index as index"
        [ngClass]="index === activeSlide ? 'dot-active__menu' : 'dot-menu'"
        class="dot mh-5"></div>
    </div>

    <swiper
      *ngIf="swiperService.swiperIsActive"
      (activeIndexChange)="changeActiveSlide($event)"
      #swiper
      [cdr]="cdr"
      [config]="swiperService.config"
      [dir]="languageService.direction"
      [slideIndexKeyFromStorage]="StorageItem.ControllerMenuSlide"
      class="swiper swiper-controller-menu overflow-y-auto pb-30"
      swiperDirective>
      <ng-template
        *ngFor="let set of items; index as index"
        swiperSlide>
        <div class="slide__list">
          <p class="fz-16 slide__title">
            {{ "Controller.Menu." + set.title | translate }}
          </p>
          <ng-container *ngTemplateOutlet="list; context: { set: set }"></ng-container>
        </div>
      </ng-template>
    </swiper>
  </ng-container>
</section>

<ng-template
  #list
  let-set="set">
  <li
    *ngFor="let icon of set.icons; trackBy: trackByFn"
    (click)="navigateToThePage(icon)"
    [class.controller-menu__item-desktop]="platformService.isDesktopApp"
    [class.hover-background]="!platformService.isDesktopApp && icon.showErrorMessage"
    [ngClass]="icon.disabled ? 'controller-menu__item-with__message' : 'pointer'"
    class="controller-menu__item position-relative">
    <div
      *ngIf="icon.disabled && !icon.isLoadingInfo"
      [class.d-none]="!icon.showErrorMessage"
      [class.d-none__force]="!platformService.isDesktopApp && !icon.showErrorMessage"
      class="position-absolute error-message d-flex align-center justify-between p-10 color-secondary fz-20">
      <img
        alt="error"
        class="mr-10 mr-3-md"
        src="/assets/icons/menu/alarm-round-red.svg" />
      <span>{{ "ControllerMenu.NoDeviceInstalled" | translate }}</span>
    </div>

    <ls-svg-icon
      [color]="icon.color || ColorsEnum.BlueDefault"
      [hasMargin]="true"
      [overlayIconPosition]="Position.TopAside"
      [overlayPath]="icon?.hasWarning ? 'menu/alarm-round-red' : null"
      [path]="'menu/' + icon.path"
      [qaTags]="icon.qaTag" />

    {{ "Controller.Menu." + icon.title | translate }}
  </li>
</ng-template>
