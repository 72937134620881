<section class="position-relative">
  <div
    [class.dashboard-active-widget]="activeWidget != null"
    class="dashboard">
    <div
      [class.d-none]="activeWidget != null"
      class="h-100-percent">
      <div class="d-flex align-center justify-center mb-15">
        <div
          *ngFor="let dot of [].constructor(3); index as index"
          [class.dot-active]="index === activeSlide"
          class="dot mh-5"></div>
      </div>

      <swiper
        *ngIf="swiperService.swiperIsActive"
        (activeIndexChange)="changeActiveSlide($event)"
        [cdr]="cdr"
        [config]="swiperService.config"
        [dir]="languageService.direction"
        [initialSlideIndexDefault]="1"
        [slideIndexKeyFromStorage]="StorageItem.DashboardMenuSlide"
        class="h-100-percent"
        swiperDirective>
        <ng-template swiperSlide>
          <div class="dashboard-left slide d-flex flex-column justify-between h-100-percent w-100-percent">
            <ls-ventilation-widget
              (click)="setActiveWidget(DashboardMainIndicator.VENTILATION)"></ls-ventilation-widget>
            <ls-heat-widget (click)="setActiveWidget(DashboardMainIndicator.HEAT)"></ls-heat-widget>
            <ls-inlet-widget (click)="setActiveWidget(DashboardMainIndicator.INLET)"></ls-inlet-widget>
            <ls-cooling-widget (click)="setActiveWidget(DashboardMainIndicator.COOLING)"></ls-cooling-widget>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="dashboard-middle slide d-flex flex-column justify-between h-100-percent w-100-percent">
            <ls-main-indicator-widget class="main-widget"></ls-main-indicator-widget>
            <ls-temperature-widget
              (click)="setActiveWidget(DashboardMainIndicator.TEMPERATURE)"></ls-temperature-widget>
          </div>
        </ng-template>

        <ng-template swiperSlide>
          <div class="dashboard-right slide d-flex flex-column justify-between h-100-percent w-100-percent">
            <ls-pressure-widget
              (click)="setActiveWidget(DashboardMainIndicator.PRESSURE)"
              class="left-widget"></ls-pressure-widget>
            <ls-air-quality-widget
              (click)="setActiveWidget(DashboardMainIndicator.AIR_QUALITY)"
              class="left-widget"></ls-air-quality-widget>
            <ls-daily-consumption-widget
              (click)="setActiveWidget(DashboardMainIndicator.DAILY_CONSUMPTION)"
              class="left-widget"></ls-daily-consumption-widget>
            <ls-bird-widget (click)="setActiveWidget(DashboardMainIndicator.BIRD)"></ls-bird-widget>
            <ls-light-widget (click)="setActiveWidget(DashboardMainIndicator.LIGHT)"></ls-light-widget>
          </div>
        </ng-template>
      </swiper>
    </div>

    <div
      *ngIf="activeWidget != null"
      class="position-absolute active-widget">
      <ng-container [ngSwitch]="activeWidget">
        <ls-main-ventilation-widget
          *ngSwitchCase="DashboardMainIndicator.VENTILATION"
          (closeWidget)="setActiveWidget(null)"></ls-main-ventilation-widget>
        <ls-main-heat-widget
          *ngSwitchCase="DashboardMainIndicator.HEAT"
          (closeWidget)="setActiveWidget(null)"></ls-main-heat-widget>
        <ls-main-inlet-widget
          *ngSwitchCase="DashboardMainIndicator.INLET"
          (closeWidget)="setActiveWidget(null)"></ls-main-inlet-widget>
        <ls-main-cooling-widget
          *ngSwitchCase="DashboardMainIndicator.COOLING"
          (closeWidget)="setActiveWidget(null)"></ls-main-cooling-widget>

        <ls-main-temperature-widget
          *ngSwitchCase="DashboardMainIndicator.TEMPERATURE"
          (closeWidget)="setActiveWidget(null)"></ls-main-temperature-widget>

        <ls-main-air-quality-widget
          *ngSwitchCase="DashboardMainIndicator.AIR_QUALITY"
          (closeWidget)="setActiveWidget(null)"></ls-main-air-quality-widget>
        <ls-main-pressure-widget
          *ngSwitchCase="DashboardMainIndicator.PRESSURE"
          (closeWidget)="setActiveWidget(null)"></ls-main-pressure-widget>
        <ls-main-daily-consumption-widget
          *ngSwitchCase="DashboardMainIndicator.DAILY_CONSUMPTION"
          (closeWidget)="setActiveWidget(null)"></ls-main-daily-consumption-widget>
        <ls-main-bird-widget
          *ngSwitchCase="DashboardMainIndicator.BIRD"
          (closeWidget)="setActiveWidget(null)"></ls-main-bird-widget>
        <ls-main-light-widget
          *ngSwitchCase="DashboardMainIndicator.LIGHT"
          (closeWidget)="setActiveWidget(null)"></ls-main-light-widget>
      </ng-container>
    </div>
  </div>
</section>
