import { Component, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ElementOperationEnum } from 'libs/installation/src/lib/enums/element/element-operation.enum';
import { TranslateService } from '@ngx-translate/core';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';

@Component({
  selector: 'ls-sprinklers-and-foggers-form',
  templateUrl: './sprinklers-and-foggers.component.html',
  styleUrls: ['../devices-common.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class SprinklersAndFoggersFormComponent extends BasicElementComponent {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        name: this.name,
        operation: new FormControl<ElementOperationEnum>(ElementOperationEnum.NormallyOpen, Validators.required),
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      operation: setupData.operation,
    });
  }
}
