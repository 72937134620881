import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { environment } from '@livestock/shared/environments';

export interface IVersionFile {
  release: string,
  version: string,
  commit: string,
  branch: string,
  buildNumber: string,
}

@Injectable({providedIn: 'root'})
export class BuildInfoService {
  json$: BehaviorSubject<null | IVersionFile> = new BehaviorSubject(null);
  json: null | IVersionFile = null;
  constructor(private readonly httpClient: HttpClient) {
    httpClient.get<IVersionFile>('assets/version.json').subscribe(data => {
      this.json = data;
      this.json$.next(data);
      console.log(`${data.branch} ${data.commit} (${data.release}) v${data.version} ${data.buildNumber}`);
    });
  }

  get environmentPrefix(): string | null {
    if (environment.apiUrl.includes('rnd')) return 'rnd';
    if (environment.apiUrl.includes('qa')) return 'qa';
    return null;
  }
}
