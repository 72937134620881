import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICreateElement } from '../interfaces/element/create-element.interface';
import { IGetOrUpdateElement } from '../interfaces/element/get-or-update-element.interface';
import { IElementType } from '../interfaces/element/element-type.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';

export const getElement = createAction(
  '[INSTALLATION ELEMENT] Get Element [...]',
  props<{ elementID: number; elementType: ElementTypesEnum; connectionID: number }>(),
);

export const getElementSuccess = createAction(
  '[INSTALLATION ELEMENT] Get Element [SUCCESS]',
  props<{ setupData: IGetOrUpdateElement; elementType: number; connectionID: number }>(),
);

export const getElementError = createAction(
  '[INSTALLATION ELEMENT] Get Element [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const createElement = createAction(
  '[INSTALLATION ELEMENT] Create Element Setup [...]',
  props<{ view: ICreateElement, elementType: ElementTypesEnum }>(),
);

export const createElementSuccess = createAction(
  '[INSTALLATION ELEMENT] Create Element [SUCCESS]',
  props<{ setupData: ICreateElement }>(),
);

export const createElementError = createAction(
  '[INSTALLATION ELEMENT] Create Element [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateElement = createAction(
  '[INSTALLATION ELEMENT] Update Element [...]',
  props<{ elementID: number; view: IGetOrUpdateElement, elementType: ElementTypesEnum }>(),
);

export const updateElementSuccess = createAction(
  '[INSTALLATION ELEMENT] Update Element [SUCCESS]',
  props<{ setupData: IGetOrUpdateElement }>(),
);

export const updateElementError = createAction(
  '[INSTALLATION ELEMENT] Update Element [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const deleteElement = createAction(
  '[INSTALLATION ELEMENT] Delete Element [...]',
  props<{ numberToDelete: number, elementType: ElementTypesEnum }>(),
);

export const deleteElementSuccess = createAction(
  '[INSTALLATION ELEMENT] Delete Element [SUCCESS]',
  props<{ numberToDelete: number }>(),
);

export const deleteElementError = createAction(
  '[INSTALLATION ELEMENT] Delete Element [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setNewConnectionSetup = createAction(
  '[INSTALLATION ELEMENT] Set New Connection Setup [...]',
  props<{ elementType: IElementType }>(),
);

export const setNewConnectionSetupSuccess = createAction(
  '[INSTALLATION ELEMENT] Set New Connection Setup [SUCCESS]',
  props<{ elementType: IElementType; otherSetups: IGetOrUpdateElement[] }>(),
);

export const setNewConnectionSetupError = createAction(
  '[INSTALLATION ELEMENT] Set New Connection Setup [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const setNewConnectionSetupIsDirtyForm = createAction(
  '[INSTALLATION ELEMENT] Set New Connection Setup Is Dirty Form',
  props<{ isDirtyForm: boolean }>(),
);

export const clearCurrentElementTypeAndConnectionSetup = createAction(
  '[INSTALLATION ELEMENT] Clear Current Element Type And Connection Setup',
  props<{ isNew?: boolean }>(),
);
