import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent, ButtonWithIconComponent, InputComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { ZXingScannerModule } from '@zxing/ngx-scanner';
import { ScanQrDialogComponent } from '../scan-qr-dialog/scan-qr-dialog.component';
import { lastValueFrom } from 'rxjs';
import { LanguageService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { StorageItem } from '@livestock/shared/enums';

@Component({
  selector: 'ls-add-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    ZXingScannerModule,
    QaTagsDirective,
    MemoizeFuncPipe,
  ],
  templateUrl: './add-device-dialog.component.html',
  styleUrls: ['./add-device-dialog.component.scss'],
})
export class AddDeviceDialogComponent {
  connectionNumber: number;

  constructor(
    public dialogRef: MatDialogRef<AddDeviceDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private dialog: MatDialog,
  ) {
  }

  close(): void {
    this.dialogRef.close(null);
  }

  add(): void {
    if (!this.connectionNumber || !this.isInputValid(this.connectionNumber)) {
      return;
    }

    LocalStorageService.setStorageItem(StorageItem.NewControllerConnectionNumber, this.connectionNumber);
    this.dialogRef.close(this.connectionNumber);
  }

  async openScanDialog(): Promise<void> {
    const dialogRef = this.dialog.open(ScanQrDialogComponent, {
      width: '300px',
      height: '350px',
      disableClose: true,
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (result.connectionNumber) {
      this.connectionNumber = result.connectionNumber;
    }
  }

  isInputValid(input: number): boolean {
    return /^[0-9]+$/.test(input?.toString());
  }
}
