import { AfterViewChecked, Directive } from '@angular/core';

@Directive({
  selector: '[qaTagsDatePicker]',
  standalone: true,
})
export class QaTagsDatepickerDirective implements AfterViewChecked {
  qaTagsAdded: boolean;

  ngAfterViewChecked(): void {
    const calendar = document.querySelector('mat-calendar');

    if (!calendar) {
      this.qaTagsAdded = false;
      return;
    }

    if (calendar && this.qaTagsAdded) return;

    calendar.classList.add('qa-mat-calendar');
    calendar.querySelector('.mat-calendar-period-button').classList.add('qa-btn-month-year');
    calendar.querySelector('.mat-calendar-body-active').classList.add('qa-btn-today');
    calendar.querySelector('.mat-calendar-previous-button').classList.add('qa-btn-prev');
    calendar.querySelector('.mat-calendar-next-button').classList.add('qa-btn-next');
    this.qaTagsAdded = true;
  }

}
