<div class="d-flex align-center">
  <ls-svg-icon
    *ngIf="hasArrow"
    (click)="emitCancel()"
    [color]="arrowColor"
    [fullHeight]="arrowFullHeight"
    [hasMargin]="true"
    [path]="'chevron-left'"
    [qaTags]="qaTagsForArrow"
    class="back-arrow pointer mr-15-md"></ls-svg-icon>

  <h1
    [qaTags]="qaTagsForTitle"
    [class]="titleClass">
    {{ translateNeeded ? (title | translate : { value: additionalTitleValue }) : title }}
  </h1>
</div>
