import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { MatDialog } from '@angular/material/dialog';
import { GoogleCoordinatesComponent } from '../google-coordinates/google-coordinates.component';
import { combineLatest, filter, first, firstValueFrom, lastValueFrom } from 'rxjs';
import { CommonUserRolesEnum, DialogButtonEnum, ViewModeEnum } from '@livestock/shared/enums';
import { DialogsService, LanguageService } from '@livestock/shared/services';
import { CommonModule } from '@angular/common';
import { IFarmInfo } from '../../interfaces/farm-info.interface';
import { createFarm, deleteFarmById, getFarmById, updateFarmById } from '../../+state/farms.actions';
import { IUpdateFarm } from '../../interfaces/update-farm.interface';
import { FormsModule } from '@angular/forms';
import { selectCurrentUserID, selectCurrentUserRole } from '@livestock/current-user';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MatTooltipModule } from '@angular/material/tooltip';
import { selectFirstFarm } from '../../+state/farms.selectors';
import { ButtonComponent } from '@livestock/ui';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { StringUtils } from '@livestock/shared/utils';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    TranslateModule,
    QaTagsDirective,
    MatTooltipModule,
    ButtonComponent,
    MemoizeFuncPipe,
  ],
  selector: 'ls-create-farm',
  templateUrl: './create-farm.component.html',
  styleUrls: ['./create-farm.component.scss'],
})
export class CreateFarmComponent implements OnInit {
  @Output() closeComponent = new EventEmitter();
  @Input() viewMode: ViewModeEnum;
  @Input() markupMode: 'scanned' | 'default' = 'default';
  ViewModeEnum = ViewModeEnum;
  CommonUserRolesEnum = CommonUserRolesEnum;
  farmNameError: boolean;
  currentUserID: number;
  currentUserRole: CommonUserRolesEnum;
  _farm: IFarmInfo;
  _originalFarm: IFarmInfo;

  get farm(): IFarmInfo {
    return this._farm;
  }

  @Input() set farm(farm: IFarmInfo) {
    if (farm) {
      this._farm = farm;
    }
    if (!this._originalFarm) {
      this._originalFarm = { ...farm };
    }
  }

  get cantBeEdited(): boolean {
    return this.viewMode === ViewModeEnum.EditFarm
      && this.currentUserID !== this.farm?.ownerUserID
      && ![CommonUserRolesEnum.Support, CommonUserRolesEnum.God].includes(this.currentUserRole);
  }

  farmDetailsChanged(farm: IFarmInfo): boolean {
    return JSON.stringify(farm) !== JSON.stringify(this._originalFarm);
  }

  constructor(
    private store: Store,
    private dialog: MatDialog,
    private dialogsService: DialogsService,
    public languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    combineLatest([
      this.store.select(selectCurrentUserID),
      this.store.select(selectCurrentUserRole),
    ]).pipe(
      filter(([currentUserID, currentUserRole]) => currentUserID != null && currentUserRole != null),
      first(),
    ).subscribe(([currentUserID, currentUserRole]) => {
      this.currentUserID = currentUserID;
      this.currentUserRole = currentUserRole;
    });
  }

  async openGoogleMaps(): Promise<void> {
    const dialogRef = this.dialog.open(GoogleCoordinatesComponent, {
      width: '800px',
      disableClose: true,
      panelClass: `h-100-percent-mobile`,
    });
    dialogRef.componentInstance.setup(this.farm?.latitude, this.farm?.longitude);
    const result = await lastValueFrom(dialogRef.afterClosed());
    this.farm = result
      ? { ...this.farm, ...result }
      : this.farm;
  }

  setFarmName(name: string): void {
    if (name !== '' && name?.length <= 50) {
      this.farmNameError = false;
    }

    if (StringUtils.areAllWhitespaces(name)) {
      this.farmNameError = true;
    }

    this.farm = {
      ...this.farm,
      name,
    };
  }

  createFarm(): void {
    if (!this.farm.name.trim() || this.farm.name.trim().length > 50) {
      this.farmNameError = true;
      return;
    }

    this.farmNameError = false;
    this.store.dispatch(createFarm({
      farm: {
        ...this.farm,
        name: this.farm.name.trim(),
      },
    }));
    this.close(true);
  }

  updateFarm(): void {
    if (!this.farm.name.trim() || this.farm.name.trim().length > 50) {
      this.farmNameError = true;
      return;
    }

    const farm: IUpdateFarm = {
      ...this.farm,
      name: this.farm.name.trim(),
      screenshotData: this.farm.screenshotData || null,
    };

    this.store.dispatch(updateFarmById({
      farmID: this.farm.farmID,
      farm,
    }));
    this.close(null);
  }

  async deleteFarm(): Promise<void> {
    const message = 'Farms.CreateFarmDialog.AreYouSureYouWantToDeleteFarm';
    const title = 'Farms.CreateFarmDialog.DeleteFarm';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.NO, DialogButtonEnum.YES],
    );

    if (result === DialogButtonEnum.YES) {
      this.store.dispatch(deleteFarmById({
        farmID: this.farm.farmID,
      }));

      const { farmID } = await firstValueFrom(this.store.select(selectFirstFarm));
      this.store.dispatch(getFarmById({ farmID }));

      this.close(true);
    }
  }

  close(isAfterCreateOfDelete: boolean): void {
    this.closeComponent.emit(isAfterCreateOfDelete);
  }
}
