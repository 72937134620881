import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { ISprinklersFoggersProgram } from './../interfaces/sprinklers-foggers-program.interface';
import { ISprinklersFoggersSingleProgram } from './../interfaces/sprinklers-foggers-single-program.interface';
import { ISprinklersFoggersProgramPeriod } from './../interfaces/sprinklers-foggers-program-period.interface';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class SprinklersFoggersProgramService {

  constructor(private http: HttpClient) {
  }

  getSprinklersFoggersProgramPeriod(controllerID: number): Observable<{ items: ISprinklersFoggersProgramPeriod[] }> {
    return this.http.get<{ items: ISprinklersFoggersProgramPeriod[] }>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period`);
  }

  addSprinklersFoggersProgramDay(controllerID: number, body: { dayNumber: number }): Observable<ISprinklersFoggersProgramPeriod> {
    return this.http.post<ISprinklersFoggersProgramPeriod>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period`, body);
  }

  deleteSprinklersFoggersProgramDay(controllerID: number, periodID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period/${periodID}`);
  }

  deleteSprinklersFoggersProgram(controllerID: number, periodID: number, programID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period/${periodID}/program/${programID}`);
  }

  getSprinklersFoggersProgram(controllerID: number, periodID: number): Observable<ISprinklersFoggersSingleProgram> {
    return this.http.get<ISprinklersFoggersSingleProgram>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period/${periodID}/program`);
  }

  addSprinklersFoggersProgram(controllerID: number, periodID: number, body: ISprinklersFoggersProgram): Observable<ISprinklersFoggersProgram> {
    return this.http.post<ISprinklersFoggersProgram>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period/${periodID}/program`, body);
  }

  editSprinklersFoggersProgram(controllerID: number, periodID: number, body: ISprinklersFoggersProgram): Observable<ISprinklersFoggersProgram> {
    return this.http.put<ISprinklersFoggersProgram>(`${baseUrl}/${controllerID}/sprinklers-and-foggers/period/${periodID}/program/${body.programID}`, body);
  }
}