export interface ISprinklersFoggersProgram {
  programID: number;
  periodID: number;
  startTime: number;
  endTime: number;
  onTime: number;
  offTime: number;
  startTemp: number;
  stopTemp: number;
  ventStartLevel: number;
  humStopLevel: number;
  element1Connected: boolean;
  element2Connected: boolean;
  element3Connected: boolean;
}