import { AirTreatmentState } from '@livestock/air-treatment';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const AIR_TREATMENT_FEATURE_KEY = 'airTreatment';

export const getAirTreatmentState =
    createFeatureSelector<AirTreatmentState>(AIR_TREATMENT_FEATURE_KEY);

export const selectIsLoading = createSelector(
    getAirTreatmentState,
    ({ isLoading }) => isLoading,
);

export const selectAirTreatmentItems = createSelector(
    getAirTreatmentState,
    ({ items }) => items,
);

export const selectAirTreatmentSettings = createSelector(
    getAirTreatmentState,
    ({ settings }) => settings,
);

export const selectAirTreatmentThresholds = createSelector(
    getAirTreatmentState,
    ({ thresholds }) => thresholds,
);