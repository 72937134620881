import { Action, createReducer, on } from '@ngrx/store';
import { TempMappingState } from './temp-mapping.state';
import * as tempMappingActions from './temp-mapping.actions';
import { TempMappingStagesEnum } from '../enums/temp-mapping-stages.enum';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IActiveMappingElement } from '../interfaces/active-mapping-element.interface';
import { ITempMappingItem } from '../interfaces/temp-mapping-item.interface';

export const initialState: TempMappingState = {
  temperatureMappings: [],
  originalTemperatureMappings: [],
  brooding2IsShown: false,
  activeElement: null,
  heatingZones: [],
  sensorElements: [
    { elementID: 20, elementType: ElementTypesEnum.IndoorTemperature, number: 1, connectionID: 200 },
    { elementID: 21, elementType: ElementTypesEnum.IndoorTemperature, number: 2, connectionID: 201 },
    { elementID: 22, elementType: ElementTypesEnum.IndoorTemperature, number: 3, connectionID: 202 },
  ],
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(
    tempMappingActions.getTemperatureMappings,
    tempMappingActions.updateTemperatureMappings,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    tempMappingActions.getTemperatureMappingsError,
    tempMappingActions.updateTemperatureMappingsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(tempMappingActions.getTemperatureMappingsSuccess, (state, { view }) => {
      return {
        ...state,
        ...view,
        originalTemperatureMappings: view.temperatureMappings,
        brooding2IsShown: view.temperatureMappings.some(mapping => mapping.type === TempMappingStagesEnum.Brooding2),
        isLoading: false,
      };
    },
  ),
  on(tempMappingActions.updateTemperatureMappingsSuccess, (state) => {
      return {
        ...state,
        originalTemperatureMappings: [...state.temperatureMappings],
        brooding2IsShown: state.temperatureMappings.some(mapping => mapping.type === TempMappingStagesEnum.Brooding2),
        isLoading: false,
      };
    },
  ),
  on(tempMappingActions.setActiveElement, (state, { activeStage, elementID }) => {
      const temperatureMappings = JSON.parse(JSON.stringify(state.temperatureMappings));

      let activeElement: IActiveMappingElement;
      const mappingIndex = temperatureMappings.findIndex(mapping => {
        return mapping.type === activeStage && activeStage === TempMappingStagesEnum.FullHouse
          || mapping.type === activeStage && elementID != null && mapping.elementID === elementID;
      });

      if (mappingIndex !== -1) {
        const { temperatureMappingID, average: isAverage } = temperatureMappings[mappingIndex];
        activeElement = {
          activeStage,
          temperatureMappingID,
          sensors: [...temperatureMappings[mappingIndex].sensors],
          isAverage,
          elementID,
        };
      }

      return {
        ...state,
        temperatureMappings,
        activeElement: activeStage != null ? activeElement : null,
      };
    },
  ),
  on(tempMappingActions.addSensorsToTempMappingElement, (state, { sensors, activeStage, elementID }) => {
      const temperatureMappings: ITempMappingItem[] = JSON.parse(JSON.stringify(state.temperatureMappings));
      let activeElement: IActiveMappingElement;
      const mappingIndex = temperatureMappings.findIndex(mapping => {
        return mapping.type === activeStage && (elementID == null || mapping.elementID === elementID);
      });

      if (mappingIndex !== -1) {
        const { temperatureMappingID, average: isAverage } = temperatureMappings[mappingIndex];
        temperatureMappings[mappingIndex].sensors = sensors;
        activeElement = {
          activeStage,
          temperatureMappingID,
          sensors,
          isAverage,
          elementID,
        };
      }

      return {
        ...state,
        temperatureMappings,
        activeElement,
      };
    },
  ),
  on(tempMappingActions.changeActiveElementIsAverage, (state, { activeStage, isAverage, elementID }) => {
    const temperatureMappings: ITempMappingItem[] = JSON.parse(JSON.stringify(state.temperatureMappings));
    let activeElement: IActiveMappingElement;
    const mappingIndex = temperatureMappings.findIndex(mapping => {
      return mapping.type === activeStage && (elementID == null || mapping.elementID === elementID);
    });

    if (mappingIndex !== -1) {
      temperatureMappings[mappingIndex].sensors = [];
      temperatureMappings[mappingIndex].average = isAverage;
      activeElement = {
        ...state.activeElement,
        isAverage,
        elementID,
        sensors: [],
      };
    }

    return {
      ...state,
      temperatureMappings,
      activeElement,
    };
  }),
  on(tempMappingActions.addBrooding2Stage, (state) => {
    return {
      ...state,
      brooding2IsShown: true,
    };
  }),
  on(tempMappingActions.removeBrooding2Stage, (state) => {
    let temperatureMappings: ITempMappingItem[] = JSON.parse(JSON.stringify(state.temperatureMappings));
    temperatureMappings = temperatureMappings.map(mapping => {
      if (mapping.type !== TempMappingStagesEnum.Brooding2) {
        return mapping;
      }

      return {
        ...mapping,
        type: TempMappingStagesEnum.Brooding1,
      };
    });

    return {
      ...state,
      temperatureMappings,
      brooding2IsShown: false,
    };
  }),
  on(tempMappingActions.moveElement,
    (state, { activeStage, destinationStage, elementID, zoneID }) => {
      const temperatureMappings: ITempMappingItem[] = JSON.parse(JSON.stringify(state.temperatureMappings));
      const activeBroodingIndex = temperatureMappings.findIndex(mapping => {
        return mapping.type === activeStage && ((elementID && mapping.elementID === elementID) || (zoneID && mapping.zoneID === zoneID));
      });

      if (activeBroodingIndex !== -1) {
        temperatureMappings[activeBroodingIndex] = {
          ...temperatureMappings[activeBroodingIndex],
          type: destinationStage,
        };
      }

      return {
        ...state,
        temperatureMappings,
      };
    }),
  on(tempMappingActions.resetTemperatureMappings, (state) => {
    return {
      ...state,
      brooding2IsShown: state.temperatureMappings.some(mapping => mapping.type === TempMappingStagesEnum.Brooding2),
      temperatureMappings: JSON.parse(JSON.stringify(state.originalTemperatureMappings)),
    };
  }),
);

export function tempMappingReducer(state: TempMappingState | undefined, action: Action): any {
  return reducer(state, action);
}
