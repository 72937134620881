import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  InputComponent,
  InputTextComponent,
  KeyboardComponent,
  LoadingLinesComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SleepUtils } from '@livestock/shared/utils';
import { NetworkConnectionStatusEnum } from '@livestock/controllers/enums';
import { Store } from '@ngrx/store';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { RequiredTrimValidator } from '@livestock/shared/validators';

@Component({
  selector: 'ls-connect-to-cellular-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    SvgIconComponent,
    KeyboardComponent,
    ReactiveFormsModule,
    InputTextComponent,
    LoadingLinesComponent,
  ],
  templateUrl: './connect-to-cellular-dialog.component.html',
  styleUrls: ['./connect-to-cellular-dialog.component.scss'],
})
export class ConnectToCellularDialogComponent {
  form: FormGroup = new FormGroup(
    {
      modemAPN: new FormControl('', [RequiredTrimValidator]),
      modemUser: new FormControl('', [RequiredTrimValidator]),
      modemPassword: new FormControl('', [RequiredTrimValidator]),
    },
  );
  isLoading: boolean;

  constructor(
    public dialogRef: MatDialogRef<ConnectToCellularDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private store: Store,
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  // TODO: change this mockup - it is for test/showing purposes only
  async connect(): Promise<void> {
    // trying to connect...
    this.isLoading = true;
    await SleepUtils.sleep(1000);
    this.isLoading = false;

    const connectionResult: NetworkConnectionStatusEnum = this.form.value.modemAPN.length > 3
      ? NetworkConnectionStatusEnum.Success
      : NetworkConnectionStatusEnum.Error;

    if (connectionResult === NetworkConnectionStatusEnum.Success) {
      this.store.dispatch(setFlashMessage({
        flashMessage: {
          message: 'FlashMessages.ConnectedSuccessfully',
          flashType: FlashMessageTypeEnum.Success,
        },
      }));
    }

    this.dialogRef.close({
      ...this.form.getRawValue(),
      modem: connectionResult === NetworkConnectionStatusEnum.Success ? 1 : 0,
      connectionResult,
    });
  }
}
