import { IEditUnitModeObj } from '../../interfaces';

export enum AirPressureUnitEnum {
  MmOfWater,
  Pascal,
  InchesOfWater,
}

export namespace AirPressureUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case AirPressureUnitEnum.MmOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.mmOfWater';
      case AirPressureUnitEnum.Pascal:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.PascalPa';
      case AirPressureUnitEnum.InchesOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.InchesOfWater';
      default:
        return value?.toString();
    }
  }

  export function toShortTranslateKey(value: any): string {
    switch (value) {
      case AirPressureUnitEnum.MmOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.mmOfWater';
      case AirPressureUnitEnum.Pascal:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.PascalPaShort';
      case AirPressureUnitEnum.InchesOfWater:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.InchesOfWater';
      default:
        return value?.toString();
    }
    ;
  }

  export function isMetricUnit(value: AirPressureUnitEnum): boolean {
    return value !== AirPressureUnitEnum.InchesOfWater;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.AirPressure',
      controlName: 'airPressure',
      enumName: AirPressureUnitEnum,
      enumStr: 'air-pressure-unit-enum',
    };
  }
}
