import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Observable, Subscription, filter, first } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { selectElementSettings } from 'libs/installation/src/lib/+state/installation.selectors';
import { selectActiveControllerID, selectControllerVentilationMode } from '@livestock/controllers';
import {
  VentilationWorkingModeEnum,
  getFlockSettings,
  selectFlockSettings,
} from '@livestock/flock';
import { ElementsValidationConstants } from '@livestock/installation/constants';
import { GlobalConstants } from '@livestock/shared/constants';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-static-pressure-settings-form',
  templateUrl: './static-pressure-settings-form.component.html',
  styleUrls: ['./static-pressure-settings-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class StaticPressureSettingsFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroupName: string;
  @Input() isEditMode: boolean;
  @Input() isResetForm$: Observable<boolean>;
  @Input() isSaveForm$: Observable<boolean>;
  @Output() setIsDirty: EventEmitter<boolean> = new EventEmitter();

  // subs
  sub$: Subscription = new Subscription();

  /* component vars */
  parentFormContainer = inject(ControlContainer);
  originalValues;
  ventilationWorkingMode: VentilationWorkingModeEnum;

  minPressureForAlarmUnit: string = GlobalConstants.PercentageSymbol;
  minPressureForAlarmTitle: string = 'Installation.Card.StaticPressure.MinimumPressureForAlarm';

  MIN_PRESSURE_ALARM = ElementsValidationConstants.staticPressureSettings.pressureAlarm.min;
  MAX_PRESSURE_ALARM = ElementsValidationConstants.staticPressureSettings.pressureAlarm.max;
  ElementsValidationConstants = ElementsValidationConstants;

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  constructor(
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.setupControllerSubscription();
    this.setupFlockSettingsSubscription();

    this.store.select(selectControllerVentilationMode).pipe(
      filter(res => res != null),
      first(),
    ).subscribe(ventilationWorkingMode => {
      this.ventilationWorkingMode = ventilationWorkingMode;
    });

    this.sub$.add(
      this.parentFormGroup.get(this.formGroupName)?.valueChanges.subscribe((items) => {
        this.setIsDirty.emit(JSON.stringify(items) !== JSON.stringify(this.originalValues));
      }),
    );
  }

  private setupControllerSubscription(): void {
    this.sub$.add(
      this.store.select(selectActiveControllerID).subscribe((controllerID) => {
        this.store.dispatch(getFlockSettings({ controllerID }));
      }),
    );
  }

  private setupFlockSettingsSubscription(): void {
    this.sub$.add(
      this.store
        .select(selectFlockSettings)
        .pipe(
          wasChanged(),
          filter((settings) => settings != null && !!settings.controllerID),
        )
        .subscribe(() => {
          this.setupElementSettingsSubscription();
          this.setupResetFormSubscription();
          this.setupSaveFormSubscription();
        }),
    );
  }

  private setupElementSettingsSubscription(): void {
    this.sub$.add(
      this.store.select(selectElementSettings)
        .pipe(
          filter((res) => res != null),
          first(),
        ).subscribe(({ windDelayTime, minPressureForAlarm }) => {
        this.originalValues = { windDelayTime, minPressureForAlarm };

        if (this.ventilationWorkingMode === VentilationWorkingModeEnum.Advance) {
          this.MAX_PRESSURE_ALARM = ElementsValidationConstants.staticPressureSettings.pressureAlarm.maxAdvanced;
          this.minPressureForAlarmUnit = '';
          this.minPressureForAlarmTitle = 'Installation.Card.StaticPressure.MinimumPressureLevelForAlarm';
        }

        this.addFormGroupControls(windDelayTime, minPressureForAlarm);
      }),
    );
  }

  private addFormGroupControls(windDelayTime: number, minPressureForAlarm: number): void {
    this.parentFormGroup.addControl(
      this.formGroupName,
      new FormGroup({
        windDelayTime: new FormControl<number>(windDelayTime, [
          Validators.min(ElementsValidationConstants.staticPressureSettings.windDelay.min),
          Validators.max(ElementsValidationConstants.staticPressureSettings.windDelay.max),
        ]),
        minPressureForAlarm: new FormControl<number>(minPressureForAlarm, [
          Validators.min(this.MIN_PRESSURE_ALARM),
          Validators.max(this.MAX_PRESSURE_ALARM),
        ]),
      }),
    );
  }

  private setupResetFormSubscription(): void {
    this.sub$.add(
      this.isResetForm$.pipe(filter(val => val))
        .subscribe(() => {
          this.resetFormGroupValues();
        }),
    );
  }

  private resetFormGroupValues(): void {
    const group = this.parentFormGroup.get(this.formGroupName);
    group.patchValue({ ...this.originalValues });
    group.updateValueAndValidity({ emitEvent: false });
  }

  private setupSaveFormSubscription(): void {
    this.sub$.add(
      this.isSaveForm$.pipe(filter(val => val))
        .subscribe(() => {
          this.saveFormGroupValues();
        }),
    );
  }

  private saveFormGroupValues(): void {
    const group = this.parentFormGroup.get(this.formGroupName);
    this.originalValues = group.getRawValue();
    group.patchValue({ ...this.originalValues });
    group.updateValueAndValidity({ emitEvent: false });
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    this.sub$.unsubscribe();
  }
}
