import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { EDashboardMainIndicator } from '@livestock/dashboard';
import { NgChartsModule } from 'ng2-charts';
import { AirQualityWidgetComponent } from '../widgets/air-quality-widget/air-quality-widget.component';
import { BirdWidgetComponent } from '../widgets/bird-widget/bird-widget.component';
import { CoolingWidgetComponent } from '../widgets/cooling-widget/cooling-widget.component';
import {
  DailyConsumptionWidgetComponent,
} from '../widgets/daily-consumption-widget/daily-consumption-widget.component';
import { HeatWidgetComponent } from '../widgets/heat-widget/heat-widget.component';
import { InletWidgetComponent } from '../widgets/inlet-widget/inlet-widget.component';
import { LightWidgetComponent } from '../widgets/light-widget/light-widget.component';
import {
  MainAirQualityWidgetComponent,
} from '../widgets/main/main-air-quality-widget/main-air-quality-widget.component';
import { MainBirdWidgetComponent } from '../widgets/main/main-bird-widget/main-bird-widget.component';
import { MainCoolingWidgetComponent } from '../widgets/main/main-cooling-widget/main-cooling-widget.component';
import {
  MainDailyConsumptionWidgetComponent,
} from '../widgets/main/main-daily-consumption-widget/main-daily-consumption-widget.component';
import { MainHeatWidgetComponent } from '../widgets/main/main-heat-widget/main-heat-widget.component';
import { MainIndicatorWidgetComponent } from '../widgets/main/main-indicator-widget/main-indicator-widget.component';
import { MainInletWidgetComponent } from '../widgets/main/main-inlet-widget/main-inlet-widget.component';
import { MainLightWidgetComponent } from '../widgets/main/main-light-widget/main-light-widget.component';
import { MainPressureWidgetComponent } from '../widgets/main/main-pressure-widget/main-pressure-widget.component';
import {
  MainTemperatureWidgetComponent,
} from '../widgets/main/main-temperature-widget/main-temperature-widget.component';
import {
  MainVentilationWidgetComponent,
} from '../widgets/main/main-ventilation-widget/main-ventilation-widget.component';
import { MainWidgetCommonComponent } from '../widgets/main/main-widget-common.component';
import { PressureWidgetComponent } from '../widgets/pressure-widget/pressure-widget.component';
import { TemperatureWidgetComponent } from '../widgets/temperature-widget/temperature-widget.component';
import { VentilationWidgetComponent } from '../widgets/ventilation-widget/ventilation-widget.component';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { LanguageService, LocalStorageService, SwiperService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';
import { SwiperDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    NgChartsModule,
    VentilationWidgetComponent,
    HeatWidgetComponent,
    InletWidgetComponent,
    CoolingWidgetComponent,
    LightWidgetComponent,
    BirdWidgetComponent,
    DailyConsumptionWidgetComponent,
    AirQualityWidgetComponent,
    PressureWidgetComponent,
    MainIndicatorWidgetComponent,
    TemperatureWidgetComponent,
    MainLightWidgetComponent,
    MainBirdWidgetComponent,
    MainTemperatureWidgetComponent,
    MainHeatWidgetComponent,
    MainInletWidgetComponent,
    MainWidgetCommonComponent,
    MainPressureWidgetComponent,
    MainDailyConsumptionWidgetComponent,
    MainAirQualityWidgetComponent,
    MainCoolingWidgetComponent,
    MainVentilationWidgetComponent,
    TranslateModule,
    SwiperModule,
    SwiperDirective,
  ],
  selector: 'ls-dashboard-wrapper-mobile',
  templateUrl: './dashboard-wrapper-mobile.component.html',
  styleUrls: ['./dashboard-wrapper-mobile.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DashboardWrapperMobileComponent implements OnDestroy {
  DashboardMainIndicator = EDashboardMainIndicator;
  activeWidget: EDashboardMainIndicator = null;
  activeSlide = Number(LocalStorageService.getStorageItem(StorageItem.DashboardMenuSlide)) ?? 1;
  StorageItem = StorageItem;

  constructor(
    public languageService: LanguageService,
    public cdr: ChangeDetectorRef,
    public swiperService: SwiperService,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.DashboardMenuSlide)) ?? 1,
      centeredSlides: true,
    };
  }

  changeActiveSlide(ev): void {
    this.activeSlide = ev[0].realIndex;
    LocalStorageService.setStorageItem(StorageItem.DashboardMenuSlide, this.activeSlide);
    this.cdr.detectChanges();
  }

  setActiveWidget(activeWidget: EDashboardMainIndicator | null): void {
    this.activeWidget = activeWidget;
    if (activeWidget === null) {
      localStorage.removeItem('hidePageInfo');
      return;
    }

    localStorage.setItem('hidePageInfo', 'true');
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.DashboardMenuSlide);
  }
}
