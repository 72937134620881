import { Pipe, PipeTransform } from '@angular/core';
import { ControllerLanguageEnum } from '../../controllers/src/lib/enums/controller-language.enum';
import { BirdTypeEnum } from '../../controllers/src/lib/enums/bird-type.enum';
import { ControllerUnitEnum } from '../../controllers/src/lib/enums/controller-unit.enum';
import { HoursFormatTypeEnum } from '../../controllers/src/lib/enums/hours-format-type.enum';
import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum, ChickenBrandWeight,
  FarmUserRolesEnum,
  IlluminanceUnitEnum,
  LengthUnitEnum,
  SortingEnum,
  TemperatureUnitEnum,
  UserTableColumnsEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
} from '../enums';
import { AutoDateTimeTypeEnum } from '../../controllers/src/lib/enums/auto-date-time-type.enum';
import { UserStatusesEnum } from '../enums/users/user-statuses.enum';
import { ElementOperationEnum } from '../../installation/src/lib/enums/element/element-operation.enum';
import { CardType } from '../../installation/src/lib/enums/card-type.enum';
import { StagingEnum } from '../../flock/src/lib/enums/staging.enum';
import { HouseModeEnum } from '../../flock/src/lib/enums/house-mode.enum';
import { VentilationWorkingModeEnum } from '../../flock/src/lib/enums/ventilation-working-mode.enum';
import { WeightMethodEnum } from '../../flock/src/lib/enums/weight-method.enum';
import { HeaterWorkModeEnum } from '../../installation/src/lib/enums/element/heating/hater-work-mode.enum';
import { LightingInputTypeEnum } from 'libs/installation/src/lib/enums/element/lighting/lighting-input-type-enum';
import { CalibrationModeEnum } from 'libs/basic-ventilation-minimum/src/lib/enums/calibration-mode.enum';
import { TreatmentByEnum } from '../../air-treatment/src/lib/enums/treatment-by.enum';
import { AmPmEnum } from '../../controllers/src/lib/enums/am-pm.enum';
import { CommonUserRolesEnum } from '../enums/users/common-user-roles.enum';

@Pipe({
  name: 'enum',
  standalone: true,
})
export class EnumPipe implements PipeTransform {
  // tslint:disable-next-line: cyclomatic-complexity
  transform(value: any, enumTitle: string): string {
    if (value == null) {
      return '';
    }

    switch (enumTitle) {
      case 'controller-language':
        return ControllerLanguageEnum.toTranslateKey(value);
      case 'controller-bird-type':
        return BirdTypeEnum.toTranslateKey(value);
      case 'controller-unit':
        return ControllerUnitEnum.toTranslateKey(value);
      case 'controller-hours-format':
        return HoursFormatTypeEnum.toTranslateKey(value);
      case 'auto-date-time-type':
        return AutoDateTimeTypeEnum.toTranslateKey(value);
      case 'temperature-unit-enum':
        return TemperatureUnitEnum.toTranslateKey(value);
      case 'temperature-unit-short-enum':
        return TemperatureUnitEnum.toTranslateKey(value, true);
      case 'air-flow-unit-enum':
        return AirFlowUnitEnum.toTranslateKey(value);
      case 'air-pressure-unit-enum':
        return AirPressureUnitEnum.toTranslateKey(value);
      case 'air-speed-unit-enum':
        return AirSpeedUnitEnum.toTranslateKey(value);
      case 'illuminance-unit-enum':
        return IlluminanceUnitEnum.toTranslateKey(value);
      case 'length-unit-enum':
        return LengthUnitEnum.toTranslateKey(value);
      case 'volume-unit-enum':
        return VolumeUnitEnum.toTranslateKey(value);
      case 'water-line-pressure-unit-enum':
        return WaterLinePressureUnitEnum.toTranslateKey(value);
      case 'water-main-pressure-unit-enum':
        return WaterMainPressureUnitEnum.toTranslateKey(value);
      case 'weight-unit-enum':
        return WeightUnitEnum.toTranslateKey(value);
      case 'farm-user-roles':
        return FarmUserRolesEnum.toTranslateKey(value);
      case 'common-user-roles':
        return CommonUserRolesEnum.toTranslateKey(value);
      case 'user-status':
        return UserStatusesEnum.toTranslateKey(value);
      case 'user-table-columns-enum':
        return UserTableColumnsEnum.toTranslateKey(value);
      case 'sorting-enum':
        return SortingEnum.toTranslateKey(value);
      case 'sorting-enum-az':
        return SortingEnum.toTranslateKeyAZ(value);
      case 'element-operation':
        return ElementOperationEnum.toTranslateKey(value);
      case 'card-type':
        return CardType.toTranslateKey(value);
      case 'staging-enum':
        return StagingEnum.toTranslateKey(value);
      case 'weight-method-enum':
        return WeightMethodEnum.toTranslateKey(value);
      case 'ventilation-working-mode-enum':
        return VentilationWorkingModeEnum.toTranslateKey(value);
      case 'house-mode-enum':
        return HouseModeEnum.toTranslateKey(value);
      case 'hater-work-mode-enum':
        return HeaterWorkModeEnum.toTranslateKey(value);
      case 'lighting-input-type':
        return LightingInputTypeEnum.toTranslateKey(value);
      case 'calibration-mode':
        return CalibrationModeEnum.toTranslateKey(value);
      case 'treatment-enum':
        return TreatmentByEnum.toTranslateKey(value);
      case 'chicken-brand-weight':
        return ChickenBrandWeight.toTranslateKey(value);
      case 'am-pm':
        return AmPmEnum.toTranslateKey(value);
      default:
        return value.toString();
    }
  }
}
