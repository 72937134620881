import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorsEnum, ElementTypesEnum } from '@livestock/shared/enums';
import { PlatformService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { IGetOrUpdateElement } from '../../../interfaces/element/get-or-update-element.interface';
import {
  selectCurrentConnectionSetupData,
  selectCurrentConnectionSetupIsNew,
  selectCurrentElementID,
  selectFirstAvailableElementNumber,
  selectOtherElementsSetupData,
} from '../../../+state/installation.selectors';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { InstallationHelpersService } from '../../../service/installation-helpers.service';
import { clearFormControlInputInfo } from '@livestock/ui';
import { AppRoutes } from '@livestock/shared/routes';
import {
  clearCurrentElementTypeAndConnectionSetup,
  createElement,
  deleteElement,
  getElementTestingData,
  setNewConnectionSetupIsDirtyForm,
  updateElement,
  updateElementTestingData,
} from '@livestock/installation';
import { ICreateElement } from '../../../interfaces/element/create-element.interface';
import { FormGroup } from '@angular/forms';
import { ElementWrapperHelper } from '../helpers/element-wrapper.helper';
import { ElementTestingDialogComponent } from '../element-testing-dialog/element-testing-dialog.component';
import { ElementCustomActionType } from '../../../enums/element/custom-element-actions-enum';
import { ElementCustomActionEvent } from '../../../interfaces/custom-action-event';
import { updateView } from '../../../enums/element/element-endpoint-by-type.enum';
import { ConnectionType } from '../../../enums/connection-type.enum';
import { CardType } from '@livestock/installation/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-element-wrapper',
  templateUrl: './element-wrapper.component.html',
  styleUrls: ['./element-wrapper.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ElementWrapperComponent implements OnInit, OnDestroy {
  private _currentElementType: ElementTypesEnum;

  get currentElementType(): ElementTypesEnum {
    return this._currentElementType;
  }

  @Input() set currentElementType(value: ElementTypesEnum) {
    this._currentElementType = value;
    this.childGroupName = this.helper.childFormGroupNameByType(value);
  }

  /* subs */
  sub$ = new Subscription();
  testingDialogSub$ = new Subscription();
  currentConnectionSetupData$: Observable<IGetOrUpdateElement> = this.store.select(selectCurrentConnectionSetupData);
  otherElementsSetupData$: Observable<IGetOrUpdateElement[]> = this.store.select(selectOtherElementsSetupData);
  currentConnectionSetupIsNew$: Observable<boolean> = this.store.select(selectCurrentConnectionSetupIsNew);
  currentElementID$: Observable<number> = this.store.select(selectCurrentElementID);
  firstAvailableElementNumber$: Observable<number> = this.store.select(selectFirstAvailableElementNumber);

  //vars
  isDirtyForm: boolean = false;
  testingDialog: MatDialogRef<ElementTestingDialogComponent, any>;
  cardNumber: number;
  cardType: CardType;
  connectionType: ConnectionType;
  childGroupName: string;
  parentForm: FormGroup = new FormGroup({});

  // constants
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  helper = ElementWrapperHelper;

  constructor(
    public platformService: PlatformService,
    public installationHelpersService: InstallationHelpersService,
    private store: Store,
    private dialog: MatDialog,
    private router: Router,
    private cdr: ChangeDetectorRef,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.cardType = +this.activatedRoute.snapshot.params['cardType'];
    this.cardNumber = +this.activatedRoute.snapshot.queryParams['cardNumber'];
    this.connectionType = +this.activatedRoute.snapshot.queryParams['connectionType'];
    this.sub$.add(
      this.parentForm.valueChanges.pipe(wasChanged())
        .subscribe(async (setup) => {
          const originalSetupData = await firstValueFrom(this.currentConnectionSetupData$);
          // eslint-disable-next-line
          const { elementID, number, otherSetups, ...originalFormSetupData } = { ...originalSetupData };

          /* do not set is dirty form if element was just created and therefore has connectionID */
          if (!setup[this.childGroupName]) {
            return;
          }

          this.isDirtyForm = JSON.stringify(updateView(this.currentElementType, setup[this.childGroupName]))
            !== JSON.stringify(updateView(this.currentElementType, originalFormSetupData));
          this.store.dispatch(setNewConnectionSetupIsDirtyForm({ isDirtyForm: this.isDirtyForm }));
          this.cdr.detectChanges();
        }),
    );
  }

  copySetup(item: IGetOrUpdateElement): void {
    const updatedItem = { ...item };

    this.parentForm.get(this.childGroupName).patchValue({
      ...updatedItem,
      name: this.parentForm.get(this.childGroupName).value.name,
      number: this.parentForm.get(this.childGroupName).value.number,
    }, { emitEvent: false });
    this.parentForm.get(this.childGroupName).updateValueAndValidity();
  }

  async openTestingPopup(): Promise<void> {

    if (this.helper.elementCustomTestingActionsByType(this.currentElementType)?.length && !(await firstValueFrom(this.currentConnectionSetupIsNew$))) {
      this.store.dispatch(getElementTestingData());
    }

    const component = this.helper.childTestingDialogComponentByType(this.currentElementType);
    this.testingDialog = this.dialog.open(ElementTestingDialogComponent, {
      minWidth: '300px',
      disableClose: true,
      data: {
        component,
        currentElementType: this.currentElementType,
      },
    });
    this.testingDialogSub$ = this.setupTestingDialogSubscriptions(this.testingDialog);
  }

  private setupTestingDialogSubscriptions(dialog: MatDialogRef<ElementTestingDialogComponent>): Subscription {
    const component = dialog.componentInstance;
    const sub = new Subscription();

    sub.add(component.onCustomAction.subscribe(action => this.handleTestingAction(action)));
    sub.add(dialog.afterClosed().subscribe(() => sub.unsubscribe()));

    return sub;
  }

  private handleTestingAction(action: ElementCustomActionEvent): void {
    switch (action.action) {
      case ElementCustomActionType.Cancel:
        this.testingDialog.close();
        break;
      case ElementCustomActionType.SaveTestingData:
        this.store.dispatch(updateElementTestingData({ testingData: action.form.value }));
        break;
      default:
        console.warn(`${ElementWrapperComponent.name}.${this.handleTestingAction.name} is not implemented for action ${action.action}`);
    }
  }

  /* create new or update existing one */
  async save(): Promise<void> {
    if (this.parentForm.invalid) {
      return;
    }

    const elementID = await firstValueFrom(this.currentElementID$);
    const rawView = this.parentForm.get(this.childGroupName).getRawValue();
    if (elementID) {

      const view: IGetOrUpdateElement = {
        ...updateView(this.currentElementType, rawView),
        elementID,
      };

      this.store.dispatch(updateElement({ elementID, view, elementType: this.currentElementType }));
      return;
    }

    const elementNumber = await firstValueFrom(this.firstAvailableElementNumber$);
    const view: ICreateElement = {
      ...updateView(this.currentElementType, rawView),
      number: elementNumber,
      elementType: this.currentElementType,
    };

    this.store.dispatch(createElement({ view, elementType: this.currentElementType }));
  }

  async delete(): Promise<void> {
    const canDeleteElement = await this.installationHelpersService.deleteElementConfirmation();
    if (!canDeleteElement) {
      return;
    }

    const { number: numberToDelete } = await firstValueFrom(this.currentConnectionSetupData$);
    this.store.dispatch(deleteElement({ numberToDelete, elementType: this.currentElementType }));
  }

  async unsetCurrentDeviceType(): Promise<void> {
    const canChangeDevice = await this.installationHelpersService.canLeaveDirtyElementSetup();
    if (!canChangeDevice) {
      return;
    }

    const isNew = await firstValueFrom(this.currentConnectionSetupIsNew$);
    this.store.dispatch(clearCurrentElementTypeAndConnectionSetup({ isNew }));

    if (this.platformService.isMobileApp) {
      this.installationHelpersService.isTableView$.next(false);
      const canLeave = await this.installationHelpersService.canLeaveDirtyElementSetup();
      if (canLeave) {
        this.router.navigate([AppRoutes.INSTALLATION], {
          queryParams: {
            cardNumber: this.cardNumber,
            cardType: this.cardType,
            connectionType: this.connectionType,
          },
        });
      }
    }
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearFormControlInputInfo());
    this.sub$.unsubscribe();
    this.testingDialogSub$?.unsubscribe();
  }
}
