import { HouseModeEnum, StagingEnum } from '@livestock/flock/models';
import { ControllerUnitEnum } from '@livestock/controllers/enums';
import { IUnitsDetails } from '@livestock/controllers/interfaces';
import { ChickenBrandWeight, WeightUnitEnum } from '@livestock/shared/enums';

export interface IFlockSettingsView {
  growthDay: number;
  flockNumber: number;
  separateMaleAndFemale: boolean;
  initialNumberOfBirds: number;
  femaleInitialNumberOfBirds: number;
  maleInitialNumberOfBirds: number;
  staging: StagingEnum;
  defaultWeightBrandID?: ChickenBrandWeight;
  controllerID?: number;

  // new design fields
  unit?: WeightUnitEnum;
  emptyHouse?: boolean;
  curveOffset?: number;

  // TODO: remove in future
  houseMode?: HouseModeEnum;
  units?: ControllerUnitEnum;
  timeNextStage?: number;
  unitsDetails?: IUnitsDetails | null;
  creationDate?: string;
  availableHouseModes?: number[];
  isNewFlock?: boolean;
}
