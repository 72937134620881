import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, forkJoin, map, of, switchMap, tap, withLatestFrom } from 'rxjs';
import * as authActions from './auth.actions';
import { Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { LocalStorageService, MenuService, PlatformService } from '@livestock/shared/services';
import { ILoginResponseModel } from '../interfaces/login-response.interface';
import { LoginData } from '../interfaces/login-request.interface';
import { Store } from '@ngrx/store';
import { selectTicketId } from '@livestock/login-scanned';
import { AppRoutes } from '@livestock/shared/routes';
import { StorageItem } from '@livestock/shared/enums';
import { BiometryService } from '../services/biometry.service';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';

@Injectable()
export class AuthEffects {
  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.login),
      map((action) => action.payload),
      switchMap((credentials) => {
        const loginData: LoginData = { ...credentials };
        return forkJoin([
          this.authService.login(loginData),
          of(loginData),
        ]).pipe(
          switchMap(
            async ([loginResponseModel, credentials]: [ILoginResponseModel, LoginData]) => {

              try {
                if (this.platformService.isNative && !(await this.biometryService.isBiometrySetup())) {
                  if (await this.biometryService.setCredentialsWithBiometry(credentials.login, credentials.password)) {
                    this.store.dispatch(
                      setFlashMessage({
                        flashMessage: {
                          flashType: FlashMessageTypeEnum.Success,
                          message: 'FlashMessages.BiometrySetupSuccess',
                        },
                      }),
                    );
                  }
                }
              } catch (error) {
                console.error(error);
                this.store.dispatch(
                  setFlashMessage({
                    flashMessage: {
                      flashType: FlashMessageTypeEnum.Error,
                      message: 'FlashMessages.BiometrySetupFailure',
                    },
                  }),
                );
              }

              return authActions.loginSuccess({
                token: loginResponseModel.token,
              });
            },
          ),
          catchError((error) => of(authActions.loginError({ payload: error }))),
        );
      }),
    ),
  );

  onLoginSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(authActions.loginSuccess),
      tap((action) => {
        if (!action.token) {
          return;
        }
        LocalStorageService.setStorageItem(StorageItem.Token, action.token);
      }),
      withLatestFrom(this.store.select(selectTicketId)),
      switchMap(([_, ticketId]) => {
        this.router.navigate([ticketId ? AppRoutes.SCANNED_LOGIN : AppRoutes.FARMS]);
        return EMPTY;
      }),
    ), { dispatch: false },
  );

  onLogout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(authActions.logOut),
        switchMap(({ paramsHandling }) => {
          return this.authService.logout().pipe(
            map(() => {
              this.menuService.clear();
              this.authService.goToLogin(paramsHandling);
            }),
            catchError((e) => {
              this.menuService.clear();
              this.authService.goToLogin(paramsHandling);
              console.error(e);
              return EMPTY;
            }),
          );
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private actions$: Actions,
    private store: Store,
    private authService: AuthService,
    private biometryService: BiometryService,
    private router: Router,
    private menuService: MenuService,
    private platformService: PlatformService,
  ) {
  }
}
