<div class="mobile-template d-flex flex-column">
  <mat-accordion
    *ngIf="parentFormGroup.get(formGroupName)"
    [formGroupName]="formGroupName">
    <ng-container
      *ngIf="parentFormGroup.get(formGroupName + '.lightAOSettings')"
      formGroupName="lightAOSettings">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-boost-enabled'">
                {{ "Installation.Card.LightingSettings.Boost" | translate }}
              </label>
              <span class="mt-8">
                {{
                  (parentFormGroup.get(formGroupName)?.value?.lightAOSettings?.boostEnabled ? "On" : "Off") | translate
                }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-boost-enabled'">
                {{ "Installation.Card.LightingSettings.Boost" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-toggle
                  [value]="parentFormGroup.get(formGroupName)?.value?.lightAOSettings?.boostEnabled"
                  [qaTags]="'qa-tog-boost-enabled'"
                  [size]="'large'"
                  formControlName="boostEnabled" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-boost-duration'">
                {{ "Installation.Card.LightingSettings.Duration" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [min]="ElementsValidationConstants.lightingSettings.boost.duration.min"
                  [max]="ElementsValidationConstants.lightingSettings.boost.duration.max"
                  [qaTags]="'qa-boost-duration'"
                  errorField
                  formControlName="boostDurationSec">
                </ls-input-integer>
                <span class="unit ml-10">
                  {{ "Sec" | translate }}
                </span>
              </div>
            </div>
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-boost-intensity'">
                {{ "Installation.Card.LightingSettings.Intensity" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [min]="ElementsValidationConstants.lightingSettings.boost.intensity.min"
                  [max]="ElementsValidationConstants.lightingSettings.boost.intensity.max"
                  [qaTags]="'qa-boost-intensity'"
                  errorField
                  formControlName="boostIntensity">
                </ls-input-integer>
                <span class="unit ml-10">
                  {{ GlobalConstants.PercentageSymbol }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-spike-enabled'">
                {{ "Installation.Card.LightingSettings.Spike" | translate }}
              </label>
              <span class="mt-8">
                {{
                  (parentFormGroup.get(formGroupName)?.value?.lightAOSettings?.spikeEnabled ? "On" : "Off") | translate
                }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-boost-spike-toggle'">
                {{ "Installation.Card.LightingSettings.Spike" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-toggle
                  [value]="parentFormGroup.get(formGroupName)?.value?.lightAOSettings?.spikeEnabled"
                  [qaTags]="'qa-boost-spike-toggle'"
                  [size]="'large'"
                  formControlName="spikeEnabled" />
              </div>
            </div>
          </div>
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-spike-cycle'">
                {{ "Installation.Card.LightingSettings.SpikeCycle" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [min]="ElementsValidationConstants.lightingSettings.spike.cycle.min"
                  [max]="ElementsValidationConstants.lightingSettings.spike.cycle.max"
                  [qaTags]="'qa-spike-cycle'"
                  errorField
                  formControlName="spikeCycleMin">
                </ls-input-integer>
              </div>
            </div>
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-lbl-spike-duration'">
                {{ "Installation.Card.LightingSettings.SpikeDuration" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [min]="ElementsValidationConstants.lightingSettings.spike.duration.min"
                  [max]="ElementsValidationConstants.lightingSettings.spike.duration.max"
                  [qaTags]="'qa-spike-duration'"
                  errorField
                  formControlName="spikeDurationMin">
                </ls-input-integer>
                <span class="unit ml-10">{{ "Min" | translate }}</span>
              </div>
            </div>
            <div class="settings-field form-field mb-20">
              <label [qaTags]="'qa-lbl-spike-increase-amount'">
                {{ "Installation.Card.LightingSettings.SpikeIncreaseAmount" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [min]="ElementsValidationConstants.lightingSettings.spike.increase.min"
                  [max]="ElementsValidationConstants.lightingSettings.spike.increase.max"
                  [qaTags]="'qa-spike-increase-amount'"
                  errorField
                  formControlName="spikeIncreaseAmount">
                </ls-input-integer>
                <span class="unit ml-10">{{ GlobalConstants.PercentageSymbol }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-spike-enabled'">
                {{ "Installation.Card.LightingSettings.Dimming" | translate }}
              </label>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="settings-field form-field mb-20 mr-55">
            <label [qaTags]="'qa-lbl-dimm-time-min'">
              {{ "Installation.Card.LightingSettings.DimTime" | translate }}
            </label>
            <div class="d-flex align-center flex-wrap">
              <ls-input-integer
                [fieldWithKeyboard]="true"
                [min]="ElementsValidationConstants.lightingSettings.dimming.min"
                [max]="ElementsValidationConstants.lightingSettings.dimming.max"
                [qaTags]="'qa-dimm-time-min'"
                errorField
                formControlName="dimmingTimeMin">
              </ls-input-integer>
              <span class="unit ml-10">{{ "Min" | translate }}</span>
            </div>
          </div>
          <div class="settings-field form-field mb-20 mr-55">
            <label [qaTags]="'qa-lbl-start-dimming'">
              {{ "Installation.Card.LightingSettings.StartDimming" | translate }}
            </label>
            <div class="d-flex align-center flex-wrap">
              <ls-time-picker-dialog-control
                [qaTags]="'qa-input-start-dimming'"
                [editMode]="isEditMode"
                formControlName="dimmingStartTime">
              </ls-time-picker-dialog-control>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-sunrise-sunset'">
                {{ "Installation.Card.LightingSettings.SunriseSunset" | translate }}
              </label>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="settings-field form-field mb-20 mr-55">
            <label [qaTags]="'qa-lbl-time-duration'">
              {{ "Installation.Card.LightingSettings.TimeDuration" | translate }}
            </label>
            <div class="d-flex align-center flex-wrap">
              <ls-input-integer
                [fieldWithKeyboard]="true"
                [min]="ElementsValidationConstants.lightingSettings.sunriseSunset.duration.min"
                [max]="ElementsValidationConstants.lightingSettings.sunriseSunset.duration.max"
                [qaTags]="'qa-time-duration'"
                errorField
                formControlName="sunriseSunsetTimeDurationMin">
              </ls-input-integer>
              <span class="unit ml-10">{{ "Min" | translate }}</span>
            </div>
          </div>
          <div class="settings-field form-field mb-20">
            <label [qaTags]="'qa-lbl-light-min-to-dimm'">
              {{ "Installation.Card.LightingSettings.MinToDim" | translate }}
            </label>
            <div class="d-flex align-center flex-wrap">
              <ls-input-integer
                [fieldWithKeyboard]="true"
                [min]="ElementsValidationConstants.lightingSettings.sunriseSunset.minToDimm.min"
                [max]="ElementsValidationConstants.lightingSettings.sunriseSunset.minToDimm.max"
                [qaTags]="'qa-light-min-to-dimm'"
                errorField
                formControlName="sunriseSunsetMinToDimm">
              </ls-input-integer>
              <span class="unit ml-10">{{ GlobalConstants.PercentageSymbol }}</span>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>

    <ng-container
      *ngIf="parentFormGroup.get(formGroupName + '.lightingSensorSettings')"
      formGroupName="lightingSensorSettings">
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-light-intensity-alarm'">
                {{ "Installation.Card.LightingSettings.LightIntentisityAlarm" | translate }}
              </label>
              <span class="mt-8">
                {{
                  parentFormGroup.get(formGroupName).value.lightingSensorSettings.lightIntensityAlarmEnabled
                    ? "On"
                    : ("Off" | translate)
                }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20">
              <label [qaTags]="'qa-lbl-light-intensity-alarm-toggle'">
                {{ "Enabled" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-toggle
                  [value]="parentFormGroup.get(formGroupName).value.lightingSensorSettings.lightIntensityAlarmEnabled"
                  [qaTags]="'qa-light-intensity-alarm-toggle'"
                  [size]="'large'"
                  formControlName="lightIntensityAlarmEnabled" />
              </div>
            </div>
            <div class="settings-field form-field mb-20">
              <label [qaTags]="'qa-lbl-min-light-intensity-for-alarm'">
                {{ "Installation.Card.LightingSettings.MinimuLightIntentisityForAlarm" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [fieldWithKeyboard]="true"
                  [qaTags]="'qa-input-min-light-intensity-for-alarm'"
                  errorField
                  formControlName="minimumLightIntensityForAlarm">
                </ls-input-integer>
                <span class="unit ml-10">{{ "Installation.Card.Lighting.Lux" | translate }}</span>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
      <mat-expansion-panel>
        <mat-expansion-panel-header>
          <mat-panel-title>
            <div class="d-flex flex-column">
              <label [qaTags]="'qa-lbl-boost-enabled'">
                {{ "Installation.Card.LightingSettings.CompensationLighting" | translate }}
              </label>
              <span class="mt-8">
                {{
                  (parentFormGroup.get(formGroupName).value.lightingSensorSettings.compensationLightingEnabled
                    ? "On"
                    : "Off"
                  ) | translate
                }}
              </span>
            </div>
          </mat-panel-title>
        </mat-expansion-panel-header>
        <div class="d-flex flex-row">
          <div class="d-flex flex-column">
            <div class="settings-field form-field mb-20 mr-55">
              <label [qaTags]="'qa-light-intensity-alarm-toggle'">
                {{ "Enabled" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ls-toggle
                  [value]="parentFormGroup.get(formGroupName).value.lightingSensorSettings.compensationLightingEnabled"
                  [qaTags]="'qa-light-intensity-alarm-toggle'"
                  [size]="'large'"
                  formControlName="compensationLightingEnabled" />
              </div>
            </div>
            <div class="settings-field form-field mb-20">
              <label [qaTags]="'qa-lbl-light-sensor'">
                {{ "Installation.Card.LightingSettings.LightingSensor" | translate }}
              </label>
              <div class="d-flex align-center flex-wrap">
                <ng-select
                  [clearable]="false"
                  [searchable]="false"
                  [readonly]="!isEditMode"
                  placeholder="{{ 'Choose' | translate }}"
                  selectTitle="{{ 'Choose' | translate }}"
                  formControlName="compensationLightingSensorElementID"
                  class="w-150 d-none-md">
                  <ng-option
                    *ngFor="let element of lightingSensorElements"
                    [value]="element.elementID">
                    {{ element.name }}
                  </ng-option>
                </ng-select>
              </div>
            </div>
          </div>
        </div>
      </mat-expansion-panel>
    </ng-container>
  </mat-accordion>
</div>
