export enum ElementOperationEnum {
  NormallyOpen,
  NormallyClose,
}

export namespace ElementOperationEnum {
  export function toTranslateKey(value: ElementOperationEnum | string | number): string {
    switch (value) {
      case ElementOperationEnum.NormallyOpen:
        return 'Controls.Element.NormallyOpen';
      case ElementOperationEnum.NormallyClose:
        return 'Controls.Element.NormallyClose';
      default:
        return value.toString();
    }
  }
}
