import { ITempMappingItem } from '../interfaces/temp-mapping-item.interface';
import { IHeatingProgramZoneItem } from '@livestock/heating-program-zone';
import { IElement } from '@livestock/installation/interfaces';
import { IActiveMappingElement } from '../interfaces/active-mapping-element.interface';

export interface TempMappingState {
  temperatureMappings: ITempMappingItem[];
  originalTemperatureMappings: ITempMappingItem[];
  brooding2IsShown: boolean;
  activeElement: IActiveMappingElement;
  heatingZones: IHeatingProgramZoneItem[];
  sensorElements: IElement[];
  isLoading: boolean;
}
