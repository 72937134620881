import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { NativeElementInjectorDirective } from '../../native-element.directive';
import { InputDecimalComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,

    InputDecimalComponent,
    TranslateModule,
  ],
  selector: 'lv-input-decimal-with-label',
  templateUrl: 'lv-input-decimal-with-label.component.html',
  styleUrls: [
    '../input-decimal.component.scss',
    '../../input-integer/input-integer.component.scss',
    'lv-input-decimal-with-label.component.scss',
  ],
})

export class LVInputDecimalWithLabelComponent extends InputDecimalComponent {
  @Input() label = '';
  @Input() inputCssClass: string = '';

  focusInput(): void {
    this.inputRef.nativeElement.focus();
  }
}
