<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Daily consumption</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body mb-40 mb-35-xl mb-70-mobile">
    <div class="widget-content">
      <table class="w-100-percent text-center fz-24">
        <thead>
          <tr class="border-bottom fz-16 fz-18-mobile color-tertiary">
            <th class="pb-10 w-100"></th>
            <th
              [qaTags]="'qa-lbl-daily-p-today-feed-lbl'"
              class="pb-10">
              Today
            </th>
            <th
              [qaTags]="'qa-lbl-daily-p-yday-feed-lbl'"
              class="pb-10 border-right">
              Yesterday
            </th>
            <th class="pb-10">Feeder</th>
          </tr>
        </thead>

        <tbody class="fz-32 fz-24-xl fz-22-mobile">
          <tr class="td-padding-20 border-bottom">
            <td>
              <img
                alt="feed"
                class="pointer"
                src="/assets/icons/dashboard/feed.svg" />
            </td>
            <td>
              <h6
                [qaTags]="'qa-lbl-daily-p-today-feed-value'"
                class="line-height-1">
                0.2<span
                  [qaTags]="'qa-lbl-daily-p-today-feed-units'"
                  class="fz-16"
                  >Lb</span
                >
              </h6>
              <div class="color-tertiary fz-16 fz-18-mobile">Per bird</div>
            </td>
            <td class="border-right">
              <h6
                [qaTags]="'qa-lbl-daily-p-yday-feed-value'"
                class="line-height-1">
                0.1<span
                  [qaTags]="'qa-lbl-daily-p-yday-feed-units'"
                  class="fz-16"
                  >Lb</span
                >
              </h6>
              <div class="color-tertiary fz-16 fz-18-mobile">Per bird</div>
            </td>
            <td>
              <h6 class="line-height-1">17:00</h6>
              <div
                [qaTags]="'qa-lbl-daily-p-today-feed-end-time'"
                class="color-tertiary fz-16 fz-18-mobile">
                End at
              </div>
            </td>
          </tr>

          <tr class="td-padding-20">
            <td>
              <img
                alt="water"
                class="pointer"
                src="/assets/icons/dashboard/water.svg" />
            </td>
            <td>
              <h6
                [qaTags]="'qa-lbl-daily-p-today-water-value'"
                class="line-height-1">
                0.03<span
                  [qaTags]="'qa-lbl-daily-p-today-water-units'"
                  class="fz-16"
                  >Gal</span
                >
              </h6>
              <div
                [qaTags]="'qa-lbl-daily-p-today-water-lbl'"
                class="color-tertiary fz-16 fz-18-mobile">
                Per bird
              </div>
            </td>
            <td class="border-right">
              <h6
                [qaTags]="'qa-lbl-daily-p-yday-water-value'"
                class="line-height-1">
                0.02<span
                  [qaTags]="'qa-lbl-daily-p-yday-water-units'"
                  class="fz-16"
                  >Gal</span
                >
              </h6>
              <div
                [qaTags]="'qa-lbl-daily-p-yday-water-lbl'"
                class="color-tertiary fz-16 fz-18-mobile">
                Per bird
              </div>
            </td>
            <td></td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>

  <div class="widget-top">
    <h6 class="color-tertiary ml-40 ml-0-xl mb-20 mb-10-xl fz-18-mobile">Feed inventory</h6>

    <div class="widget-body">
      <div class="widget-content">
        <table class="w-100-percent text-center fz-24 feed-inventory">
          <thead>
            <tr class="border-bottom fz-16 z-22-mobile color-tertiary">
              <th class="pb-10 w-100"></th>
              <th
                [qaTags]="'qa-lbl-daily-p-sailo-num'"
                class="pb-10">
                1
              </th>
              <th
                [qaTags]="'qa-lbl-daily-p-sailo-num'"
                class="pb-10">
                2
              </th>
              <th
                [qaTags]="'qa-lbl-daily-p-sailo-num'"
                class="pb-10">
                3
              </th>
            </tr>
          </thead>

          <tbody class="fz-32 fz-24-xl fz-22-mobile">
            <tr class="td-padding-20">
              <td>
                <img
                  alt="feed-inventary"
                  class="pointer"
                  src="/assets/icons/dashboard/feed-inventary.svg" />
              </td>
              <td>
                <h6
                  [qaTags]="'qa-lbl-daily-p-sailo-value'"
                  class="line-height-1">
                  2,061<span
                    [qaTags]="'qa-lbl-daily-p-sailo-units'"
                    class="fz-16"
                    >Lb</span
                  >
                </h6>
                <div
                  [qaTags]="'qa-lbl-daily-p-sailo-lbl'"
                  class="color-tertiary fz-16 fz-18-mobile">
                  Quantity
                </div>
              </td>
              <td>
                <h6
                  [qaTags]="'qa-lbl-daily-p-sailo-value'"
                  class="line-height-1">
                  2,006<span
                    [qaTags]="'qa-lbl-daily-p-sailo-units'"
                    class="fz-16"
                    >Lb</span
                  >
                </h6>
                <div
                  [qaTags]="'qa-lbl-daily-p-sailo-lbl'"
                  class="color-tertiary fz-16 fz-18-mobile">
                  Quantity
                </div>
              </td>
              <td>
                <h6
                  [qaTags]="'qa-lbl-daily-p-sailo-value'"
                  class="line-height-1">
                  2,460<span
                    [qaTags]="'qa-lbl-daily-p-sailo-units'"
                    class="fz-16"
                    >Lb</span
                  >
                </h6>
                <div
                  [qaTags]="'qa-lbl-daily-p-sailo-lbl'"
                  class="color-tertiary fz-16 fz-18-mobile">
                  Quantity
                </div>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</div>
