export class AirTreatmentConstants {
    static MinDay = 0;
    static MaxDay = 99;

    static MinPercentage = 0;
    static MaxPercentage = 100;

    static MinPPM = 0;
    static MaxPPM = 5000;

    static MaxForTreatment = 100;
    static MinForTreatment = 1;

    static MaxIndoorTempToStop = 50;
    static MinIndoorTempToStop = 0;

    static MaxIndoorTempToStopF = 122;
    static MinIndoorTempToStopF = 32;

}
