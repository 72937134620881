import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { ITemperatureCurve, ITemperatureCurveSettings } from '@livestock/temperature-curve';

export const getTemperatureCurve = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve [...]',
);

export const getTemperatureCurveSuccess = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve [SUCCESS]',
  props<{ view: ITemperatureCurve }>(),
);

export const getTemperatureCurveError = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateTemperatureCurve = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve [...]',
  props<{ view: ITemperatureCurve }>(),
);

export const updateTemperatureCurveSuccess = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve [SUCCESS]',
  props<{ view: ITemperatureCurve }>(),
);

export const updateTemperatureCurveError = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getTemperatureCurveSettings = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve Settings [...]',
);

export const getTemperatureCurveSettingsSuccess = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve Settings [SUCCESS]',
  props<{ view: ITemperatureCurveSettings }>(),
);

export const getTemperatureCurveSettingsError = createAction(
  '[TEMPERATURE CURVE] Get Temperature Curve Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateTemperatureCurveSettings = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve Settings [...]',
  props<{ view: ITemperatureCurveSettings }>(),
);

export const updateTemperatureCurveSettingsSuccess = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve Settings [SUCCESS]',
  props<{ view: ITemperatureCurveSettings }>(),
);

export const updateTemperatureCurveSettingsError = createAction(
  '[TEMPERATURE CURVE] Update Temperature Curve Settings [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const clearTemperatureCurveState = createAction(
  '[TEMPERATURE CURVE] Clear Temperature Curve State [...]',
);