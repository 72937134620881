<form [formGroup]="form">
  <ls-input-integer
    [fieldWithKeyboard]="true"
    [min]="0"
    [qaTags]="'qa-txt-test-time'"
    formControlName="time">
  </ls-input-integer>
  <span class="position-absolute color-tertiary fz-16 sec">{{ "Installation.Card.HeatingDO.Sec" | translate }}</span>
</form>

<div class="keyboard-wrapper">
  <ls-keyboard [defaultMode]="KeyboardModeEnum.NumericOnly"> </ls-keyboard>
</div>
