<div class="device-types__wrapper h-100-percent pv-20">
  <h4 class="fz-16 color-secondary fw-500 mb-20">{{ "Installation.ElementTypes.SensorType" | translate }}</h4>

  <!--disable if no connection id was chosen
  OR element is loading
  OR for currentElementType:
  (currentElement is NOT new and has some settings OR maxConnectionCount is equal or less than connected count) -->
  <div
    *ngFor="let elementType of elementTypes; last as last"
    (click)="changeActiveDevice(elementType)"
    [ngClass]="{
      'mb-20': !last,
      'device-type__active': elementType.elementType === (currentElementType$ | async),
      'device-type__disabled':
        !(currentConnectionID$ | async) ||
        (isElementLoading$ | async) ||
        (elementType.elementType !== (currentElementType$ | async) &&
          (((currentConnectionSetup$ | async) && !(currentConnectionSetupIsNew$ | async)) ||
            elementType.connectedCount >= elementType.maxConnectionCount)),
      pointer: (currentConnectionID$ | async) && elementType.connectedCount < elementType.maxConnectionCount
    }"
    [qaTags]="'qa-btn-device-type'"
    class="device-type d-flex align-center justify-between color-tertiary fz-16 fw-500">
    <div class="device-type__info d-flex align-center">
      <div class="device-type__icon d-flex align-center justify-center">
        <ls-svg-icon
          [color]="ColorsEnum.BlueDefault"
          [fullHeight]="true"
          [hasMargin]="true"
          [mirrorRtl]="false"
          [overlayPath]="elementType?.isWarning ? 'warning' : null"
          [path]="elementType.elementType | memoizeFunc : ElementTypesEnum.getSvgPath"
          [qaTags]="'qa-img-device-type'"></ls-svg-icon>
      </div>

      <h5 [qaTags]="'qa-lbl-device-type'">{{ "Installation.ElementTypes." + elementType.name | translate }}</h5>
    </div>

    <div
      [qaTags]="'qa-lbl-device-count'"
      class="device-type__count">
      {{
        elementType.elementType === (currentElementType$ | async) && (currentConnectionSetupIsNew$ | async)
          ? elementType.connectedCount + 1
          : elementType.connectedCount
      }}/{{ elementType.maxConnectionCount }}
    </div>
  </div>
</div>
