<div class="d-flex flex-row general-settings">
  <ng-container *ngIf="form">
    <!-- TODO: Remove after the development of mobile settings is finished -->
    <form
      *ngIf="platformService.isMobileApp"
      [formGroup]="form"
      autocomplete="off"
      novalidate>
      <div
        *ngIf="!isLoading; else loading"
        (click)="openLanguageSelector()"
        [qaTags]="'qa-ddl-language'"
        [class.disabled]="isDisabled"
        class="form-field">
        <div class="form-field__value-block">
          <span class="form-field__value-title">
            {{ "Controller.GeneralSettings.Language" | translate }}
          </span>
          <span class="form-field__value">
            {{ this.form.value.language | enum : "controller-language" | translate }}
          </span>
        </div>
        <div class="form-field__img-container">
          <img
            class="form-field__chevron"
            src="/assets/icons/arrows/chevron-right-grey.svg" />
        </div>
      </div>

      <div
        (click)="openUnitsSelector()"
        [class.disabled]="!isQuickStart"
        [qaTags]="'qa-ddl-units'"
        class="form-field mt-20">
        <div class="form-field__value-block">
          <span class="form-field__value-title">
            {{ "Controller.GeneralSettings.Units" | translate }}
          </span>
          <span class="form-field__value">
            {{ this.form.value.units | enum : "controller-unit" | translate }}
          </span>
        </div>
        <div class="form-field__img-container">
          <img
            class="form-field__chevron"
            src="/assets/icons/arrows/chevron-right-grey.svg" />
        </div>
      </div>
      <div
        (click)="openBirdTypeSelector()"
        [class.disabled]="!isQuickStart"
        [qaTags]="'qa-ddl-bird-type'"
        class="form-field mt-20">
        <div class="form-field__value-block">
          <span class="form-field__value-title">
            {{ "Controller.GeneralSettings.TypeOfBird" | translate }}
          </span>
          <span class="form-field__value">
            {{ this.form.value.birdType | enum : "controller-bird-type" | translate }}
          </span>
        </div>
        <div class="form-field__img-container">
          <img
            class="form-field__chevron"
            src="/assets/icons/arrows/chevron-right-grey.svg" />
        </div>
      </div>
    </form>

    <!-- TODO: Check this ngIf when mobile settings are developed -->
    <div
      *ngIf="!platformService.isMobileApp"
      [formGroup]="form"
      class="menu d-flex flex-row gap-20">
      <section class="d-flex flex-column gap-20 titles">
        <div
          (click)="menuClicked('language')"
          [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'language', disabled: isDisabled }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.Language" | translate }}</div>
          <div class="form-field__value d-flex flex-row justify-between">
            <span [ngClass]="{ highlight: menuItem !== 'language' }">{{
              this.form.value.language | enum : "controller-language" | translate
            }}</span>
            <ls-svg-icon
              [color]="menuItem === 'language' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
              [path]="IconsEnum.EXPAND_IN_CIRCLE" />
          </div>
        </div>

        <div
          (click)="menuClicked('units')"
          [ngClass]="{
            'form-field__active connect connect-separator': menuItem === 'units',
            disabled: !isQuickStart || isDisabled
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.Units" | translate }}</div>
          <div class="form-field__value d-flex flex-row justify-between">
            <lv-radio-list
              [formControlName]="'units'"
              [list]="unitsList"
              direction="horizontal"
              size="medium" />
            <ls-svg-icon
              [color]="menuItem === 'units' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
              [path]="IconsEnum.EXPAND_IN_CIRCLE" />
          </div>
        </div>

        <div
          (click)="menuClicked('birdType')"
          [ngClass]="{
            'form-field__active connect connect-separator': menuItem === 'birdType',
            disabled: !isQuickStart || isDisabled
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.TypeOfBird" | translate }}</div>
          <div class="form-field__value d-flex flex-row justify-between">
            <span [ngClass]="{ highlight: menuItem !== 'birdType' }">{{
              this.form.value.birdType | enum : "controller-bird-type" | translate
            }}</span>
            <ls-svg-icon
              [color]="menuItem === 'birdType' ? ColorsEnum.MainBlue : ColorsEnum.MonoGrey"
              [path]="IconsEnum.EXPAND_IN_CIRCLE" />
          </div>
        </div>

        <div
          (click)="menuClicked('ventilationMode')"
          [ngClass]="{
            'form-field__active connect connect-separator': menuItem === 'ventilationMode',
            disabled: !isQuickStart || isDisabled
          }"
          class="form-field justify-unset">
          <div class="form-field__title">{{ "Controller.GeneralSettings.VentilationWorkingMode" | translate }}</div>
          <lv-dual-toggle
            [formControlName]="'ventilationWorkingMode'"
            [firstOption]="{
              title: 'Flock.VentilationWorkingMode.Basic',
              value: VentilationWorkingModeEnum.Basic,
              theme: menuItem !== 'ventilationMode' ? ThemeEnum.Idle : null,
              cssClass:
                form.controls['ventilationWorkingMode'].value === VentilationWorkingModeEnum.Advance &&
                menuItem !== 'ventilationMode'
                  ? 'mono-grey'
                  : ''
            }"
            [secondOption]="{
              title: 'Flock.VentilationWorkingMode.Advanced',
              value: VentilationWorkingModeEnum.Advance,
              theme: menuItem !== 'ventilationMode' ? ThemeEnum.Idle : null,
              cssClass:
                form.controls['ventilationWorkingMode'].value === VentilationWorkingModeEnum.Basic &&
                menuItem !== 'ventilationMode'
                  ? 'mono-grey'
                  : ''
            }"
            size="medium" />
        </div>
      </section>
      <section
        *ngIf="menuItem"
        [ngClass]="{ 'p-15': menuItem !== 'units' }"
        class="d-flex flex-column gap-20 settings {{ menuItem }}">
        <div
          *ngIf="menuItem === 'language'"
          [@fadeInOut]>
          <lv-radio-list
            [formControlName]="'language'"
            [list]="languagesList"
            direction="vertical"
            size="small" />
        </div>
        <div
          *ngIf="menuItem === 'units'"
          [@fadeInOut]>
          <ls-custom-units-list [formControlName]="'unitsDetails'" />
        </div>

        <div
          *ngIf="menuItem === 'birdType'"
          [@fadeInOut]>
          <lv-radio-list
            [formControlName]="'birdType'"
            [list]="birdTypeList"
            direction="vertical"
            size="small" />
        </div>

        <div
          *ngIf="menuItem === 'ventilationMode'"
          [@fadeInOut]
          class="d-flex flex-column gap-28 mono-dark">
          <div>
            <h3 class="fw-600 fz-18 mb-8">
              {{ "Controller.GeneralSettings.VentilationExplanation.Basic" | translate }}
            </h3>
            <p>
              {{ "Controller.GeneralSettings.VentilationExplanation.BasicExplanation" | translate }}
            </p>
          </div>
          <div>
            <h3 class="fw-600 fz-18 mb-8">
              {{ "Controller.GeneralSettings.VentilationExplanation.Advanced" | translate }}
            </h3>
            <p>
              {{ "Controller.GeneralSettings.VentilationExplanation.AdvancedExplanation" | translate }}
            </p>
          </div>
        </div>
      </section>
    </div>
  </ng-container>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
