<div class="device-copy__popup">
  <div class="d-flex align-center justify-between mb-15">
    <h6 class="color-primary fz-20">{{ "Installation.Card.Cooling.ImportFromUnit" | translate }}</h6>
    <ls-svg-icon
      (click)="close()"
      [hasMargin]="true"
      [qaTags]="'qa-btn-copy-cancel'"
      class="pointer"
      path="common/cross"></ls-svg-icon>
  </div>

  <mat-radio-group class="d-flex flex-column negative-margin fz-20 color-secondary qa-rdo-copy-options">
    <ng-container *ngFor="let zone of data.zones">
      <mat-radio-button
        (click)="copyElement(zone)"
        [disabled]="zone.isDisabled"
        [value]="zone.name">
        <span>{{ zone.name }}</span>
      </mat-radio-button>
    </ng-container>
  </mat-radio-group>
</div>
