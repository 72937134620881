export enum KeyboardEnum {
  ABC = 'abc',
  Numeric = '123',
  Up = 'up',
  Backspace = 'backspace',
  Space = 'space',
  Enter = 'enter',
  EnterLong = `${KeyboardEnum.Enter}-long`,
  AM = 'AM',
  PM = 'PM',
}
