import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, EMPTY, lastValueFrom, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { ControllersService } from '../../services/controllers.service';
import * as upsertControllerActions from './current-controller.actions';
import { IControllerView } from '../../interfaces/controller-view.interface';
import {
  selectActiveControllerID,
  selectActiveControllerSerialNumber,
  selectControllerInfo,
} from './current-controller.selectors';
import { Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { LocalStorageService, PlatformService } from '@livestock/shared/services';
import { AlignmentEnum, DialogButtonEnum, StorageItem } from '@livestock/shared/enums';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent } from '@livestock/ui';

@Injectable()
export class CurrentControllerEffects {

  getControllerInfo$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getControllerInfo),
      withLatestFrom(this.store.select(selectControllerInfo)),
      switchMap(([{ controllerID }, controllerView]) => {
        /* don't make redundant requests if we already have info about current controller in store */
        if (controllerView?.controllerID === controllerID) {
          return EMPTY;
        }

        return this.controllersService.getController(controllerID).pipe(
          map((controllerInfo: IControllerView) => upsertControllerActions.getControllerInfoSuccess({ controllerInfo })),
          catchError((error) => {
            const activeFarmID = LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);
            this.router.navigateByUrl(activeFarmID ? `${AppRoutes.FARM}/${activeFarmID}` : AppRoutes.FARMS);
            return of(upsertControllerActions.getControllerInfoError({ payload: error }));
          }),
        );
      }),
    ),
  );

  disconnectController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.disconnectController),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.controllersService.disconnectController(controllerID).pipe(
          map(() => {
            if (!this.platformService.isDeviceApp) {
              this.showControllerWasDisconnectedMessage();
            }

            return upsertControllerActions.disconnectControllerSuccess();
          }),
          catchError((error) => of(upsertControllerActions.disconnectControllerError({ payload: error }))),
        );
      }),
    ),
  );

  reconnectController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.reconnectController),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectActiveControllerSerialNumber),
      ),
      switchMap(([_action, controllerID, sn]) => {
        return this.controllersService.reconnectController(controllerID, { sn }).pipe(
          map((ticketView) => upsertControllerActions.reconnectControllerSuccess({ ticketView })),
          catchError((error) => of(upsertControllerActions.reconnectControllerError({ payload: error }))),
        );
      }),
    ),
  );

  async showControllerWasDisconnectedMessage(): Promise<void> {
    const dialogRef = this.dialog.open(MessageDialogComponent, {
      width: '350px',
      disableClose: true,
    });
    dialogRef.componentInstance.setup(
      'FlashMessages.ControllerWasDisconnected',
      'FlashMessages.ClickOKToNavigateToMainPage',
      [DialogButtonEnum.OK],
      'flash/success',
      true,
      true,
      AlignmentEnum.CENTER,
      AlignmentEnum.CENTER,
      false,
    );

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (res === DialogButtonEnum.OK) {
      const activeFarmID = LocalStorageService.getStorageItem(StorageItem.ActiveFarmID);
      this.router.navigateByUrl(activeFarmID ? `${AppRoutes.FARM}/${activeFarmID}` : AppRoutes.FARMS);
    }
  }

  constructor(
    private store: Store,
    private router: Router,
    private platformService: PlatformService,
    private dialog: MatDialog,
    private actions$: Actions,
    private controllersService: ControllersService,
  ) {
  }
}
