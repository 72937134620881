import { Component } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { SvgIconComponent } from '@livestock/ui';
import { AddRemoveBrooding2Enum } from '../../enums/add-remove-brooding2.enum';

@Component({
  selector: 'ls-brooding2-dialog',
  imports: [
    CommonModule,
    TranslateModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    SvgIconComponent,
  ],
  templateUrl: './brooding2-dialog.component.html',
  styleUrls: ['./brooding2-dialog.component.scss'],
  standalone: true,
})
export class Brooding2DialogComponent {
  brooding2IsShown: boolean;

  constructor(
    public dialogRef: MatDialogRef<Brooding2DialogComponent>,
  ) {
  }

  setup(brooding2IsShown: boolean): void {
    this.brooding2IsShown = brooding2IsShown;
  }

  addBrooding2Stage(): void {
    if (this.brooding2IsShown) return;
    this.dialogRef.close(AddRemoveBrooding2Enum.Add);
  }

  removeBrooding2Stage(): void {
    if (!this.brooding2IsShown) return;
    this.dialogRef.close(AddRemoveBrooding2Enum.Remove);
  }

  close(): void {
    this.dialogRef.close();
  }
}
