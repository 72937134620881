import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ControllersService } from 'libs/controllers/src/lib/services/controllers.service';
import { firstValueFrom, forkJoin, map } from 'rxjs';
import { DialogsService } from '@livestock/shared/services';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';
import { selectActiveControllerID } from '@livestock/controllers';

export const SettingsGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
    const controllersService = inject(ControllersService);
    const dialogService = inject(DialogsService);
    const store = inject(Store);

    const elementType = +route.paramMap.get('elementType') as ElementTypesEnum;

    // extend this condition, if needed for other elements settings
    if (!(elementType === ElementTypesEnum.LightDO)) return true;

    const controllerID = await firstValueFrom(store.select(selectActiveControllerID));
    const isLightingElementsExist = await firstValueFrom(
        forkJoin([
            controllersService
                .getControllerElements(controllerID, ElementTypesEnum.LightingSensor),
            controllersService
                .getControllerElements(controllerID, ElementTypesEnum.LightAO),
        ])
            .pipe(map(([sensorItems, LightAoItems]) => !!sensorItems.items.length || !!LightAoItems.items.length)),
    );

    if (!isLightingElementsExist) {
        dialogService.error('FlashMessages.LightingElementsNotFound', 'Controller.Menu.NoAvailableElements');
        return false;
    }

    return true;
};
