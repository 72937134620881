<div class="widget-wrapper">
  <div class="widget-top">
    <h6
      [qaTags]="'qa-lbl-widget-title'"
      class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Air quality</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body pl-10 pr-30 ph-0-mobile">
    <h6 class="color-tertiary fz-16 fz-18-mobile mb-15 ml-40 ml-0-xl mb-5-xl">Current</h6>

    <table class="w-100-percent text-center fz-16 fz-18-mobile td-padding-10 mb-40 mb-25-xl">
      <thead>
        <tr class="color-secondary border-bottom-default">
          <th></th>
          <th [qaTags]="'qa-lbl-air-quality-p-humidity'">Humidity</th>
          <th [qaTags]="'qa-lbl-air-quality-p-co2'">CO2</th>
          <th [qaTags]="'qa-lbl-air-quality-p-ammonia'">Ammonia</th>
        </tr>
      </thead>

      <tbody>
        <tr>
          <td class="color-secondary border-right-default">Value</td>
          <td [qaTags]="'qa-lbl-air-quality-p-value'">67%</td>
          <td [qaTags]="'qa-lbl-air-quality-p-value'">3,000</td>
          <td [qaTags]="'qa-lbl-air-quality-p-value'">25</td>
        </tr>

        <tr>
          <td class="color-secondary border-right-default">Target</td>
          <td [qaTags]="'qa-lbl-air-quality-p-target'">55%</td>
          <td [qaTags]="'qa-lbl-air-quality-p-target'">2,500</td>
          <td [qaTags]="'qa-lbl-air-quality-p-target'">20</td>
        </tr>

        <tr class="padding-bottom-0">
          <td class="color-secondary border-right-default">Alarm</td>
          <td [qaTags]="'qa-lbl-air-quality-p-alarm'">50%</td>
          <td [qaTags]="'qa-lbl-air-quality-p-alarm'">2,000</td>
          <td [qaTags]="'qa-lbl-air-quality-p-alarm'">32</td>
        </tr>
      </tbody>
    </table>
  </div>

  <div class="widget-body">
    <div class="widget-content ph-10 ph-0-mobile">
      <h6 class="color-tertiary fz-16">Last 2 Days</h6>

      <div class="chart-wrapper w-100-percent">
        <canvas
          [datasets]="barChartData"
          [labels]="barChartLabels"
          [legend]="barChartLegend"
          [options]="barChartOptions"
          [plugins]="barChartPlugins"
          [qaTags]="'qa-lbl-air-quality-p-graph-legend'"
          baseChart
          class="m-auto h-100-percent w-100-percent"></canvas>
      </div>
    </div>
  </div>
</div>
