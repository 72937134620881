import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-main-indicator-widget',
  templateUrl: './main-indicator-widget.component.html',
  styleUrls: ['./main-indicator-widget.component.scss'],
})
export class MainIndicatorWidgetComponent {
}
