import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, HeaderTitleComponent, SvgIconComponent } from '@livestock/ui';
import {
  getNetworkSettings,
  setNetworkSettings,
  updateNetworkSettings,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { INetworkSettingsView } from '../../interfaces/network-settings-view.interface';
import { Observable, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { filter } from 'rxjs/operators';
import { selectIsLoading, selectNetworkSettings } from '../../+state/upsert-controller/upsert-controller.selectors';
import { TranslateModule } from '@ngx-translate/core';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { ControllerNetworkSettingsFormComponent } from '../../ui/network-settings-form/network-settings-form.component';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { ColorsEnum } from '@livestock/shared/enums';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-controller-network-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerNetworkSettingsFormComponent,
    ButtonComponent,
    SvgIconComponent,
    HeaderTitleComponent,
    QaTagsDirective,
  ],
  templateUrl: './network-settings.component.html',
  styleUrls: ['../general-settings/general-settings.component.scss', './network-settings.component.scss'],
})
export class ControllerNetworkSettingsComponent implements OnDestroy, OnInit {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  networkSettings: INetworkSettingsView;
  originalNetworkSettings: INetworkSettingsView;
  isValid = true;
  isDirtyForm = false;
  isEditMode: boolean = false;

  private _controllerID: number;

  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private menuService: MenuService,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.pipe(filter((params) => Boolean(+params['controllerID']))).subscribe((params) => {
        this._controllerID = +params['controllerID'];
        this.store.dispatch(getNetworkSettings({ controllerID: this._controllerID }));
      }),
    );

    this.sub$.add(
      this.store
        .select(selectNetworkSettings)
        .pipe(
          wasChanged(),
          filter((settings) => this._controllerID && settings?.controllerID === this._controllerID),
        )
        .subscribe((networkSettings) => {
          if (!this.originalNetworkSettings && networkSettings.controllerID) {
            this.originalNetworkSettings = networkSettings;
          }

          this.networkSettings = networkSettings;
        }),
    );
  }

  changedNetworkSettings(event: { formValues: any; isValid: boolean }): void {
    this.isValid = event.isValid;
    this.isDirtyForm = JSON.stringify(this.originalNetworkSettings) !== JSON.stringify(event.formValues);
    if (event.isValid) {
      this.store.dispatch(setNetworkSettings({ networkSettings: event.formValues }));
    }
  }

  async goBack(): Promise<void> {
    if (JSON.stringify(this.originalNetworkSettings) !== JSON.stringify(this.networkSettings)) {
      const canContinue = await this.dialogsService.canContinueAction();
      if (!canContinue) return;
    }

    this.store.dispatch(setNetworkSettings({ networkSettings: this.originalNetworkSettings }));
    this.menuService.toggleDashboardMenu(true);
  }

  async cancel(): Promise<void> {
    if (JSON.stringify(this.originalNetworkSettings) !== JSON.stringify(this.networkSettings)) {
      const canContinue = await this.dialogsService.canContinueAction();
      if (!canContinue) return;
    }

    this.isEditMode = false;
    this.store.dispatch(setNetworkSettings({ networkSettings: this.originalNetworkSettings }));
  }

  update(): void {
    this.originalNetworkSettings = this.networkSettings;
    this.store.dispatch(updateNetworkSettings());
    this.isEditMode = false;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
