import { Component, Input, ViewChild } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ChartDataset, ChartOptions } from 'chart.js';
import { CommonModule } from '@angular/common';
import { NgChartsModule } from 'ng2-charts';
import { BaseChartDirective } from 'ng2-charts';

import { LoadingComponent } from '@livestock/ui';
import { QaTagsDirective } from '@livestock/shared/directives';
import { EnumPipe } from '@livestock/shared/pipes';
import { ChartLegendItem } from '@livestock/temperature-curve';

@Component({
  selector: 'ls-temperature-curve-chart',
  templateUrl: './temperature-curve-chart.component.html',
  styleUrls: ['./temperature-curve-chart.component.scss'],
  standalone: true,
  imports: [CommonModule, TranslateModule, QaTagsDirective, LoadingComponent, EnumPipe, NgChartsModule],
})
export class TemperatureCurveChartComponent {
  @ViewChild('chart') chart: BaseChartDirective;

  @Input() isEditMode: boolean;
  @Input() isLoading: boolean;
  @Input() chartData: ChartDataset[] = [];
  @Input() chartOptions: ChartOptions;
  @Input() legend: ChartLegendItem[];
}
