import { NativeElementInjectorDirective } from './../../../ui/src/lib/controls/native-element.directive';
import {
  AfterViewInit,
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { DialogsService, LanguageService, MenuService, PlatformService } from '@livestock/shared/services';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import * as programActions from './+state/sprinklers-foggers-program.actions';
import {
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { Subscription, filter, firstValueFrom, lastValueFrom, map, tap, take } from 'rxjs';
import {
  selectSprinklersFoggersPeriods,
  selectSprinklersFoggersProgramIsLoading,
  selectCurrentSprinklersFoggersProgramPeriod,
  selectCurrentSprinklersFoggersProgram,
} from './+state/sprinklers-foggers-program.selectors';
import { ISprinklersFoggersProgramPeriod } from './interfaces/sprinklers-foggers-program-period.interface';
import { ISprinklersFoggersProgram } from './interfaces/sprinklers-foggers-program.interface';
import { NgxMatTimepickerComponent, NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { saveSprinklersFoggersProgram } from './+state/sprinklers-foggers-program.actions';
import * as utils from '@livestock/shared/utils';
import { ColorsEnum, DialogButtonEnum, ElementTypesEnum, ErrorFieldEnum, KeyboardEnum } from '@livestock/shared/enums';
import { FindNextToPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  SvgIconComponent,
  clearKeyboardValue,
  keyboardUpdateFields,
  selectCurrentKeyboardUUID,
  selectKeyboardValueAndUUID,
  setElementUuid,
  setKeyboardMode,
  AddProgramDayMobileDialogComponent,
  AddDayDialogComponent,
  ProgramTableMobileComponent,
  setKeyboardIconPath,
  ProgramDeviceButtonsComponent,
  PageWrapperComponent,
} from '@livestock/ui';
import {
  AtLeastOneElementSelected,
  EnableValidatorIfControlTouched,
  EnableValidatorsIfControlTouched,
  StartTimeAfterEndTime,
  greaterThanValidator,
  lessThanValidator,
} from '@livestock/shared/validators';
import { Actions } from '@ngrx/effects';
import { ActivatedRoute, Router } from '@angular/router';
import {
  selectActiveControllerID,
  selectControllerVentilationMode,
  selectHourFormatForTimePicker,
} from '@livestock/controllers';
import { GlobalConstants } from '@livestock/shared/constants';
import { AppRoutes } from '@livestock/shared/routes';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { CoolingProgramConstants } from '@livestock/cooling-program/models';
import { VentilationWorkingModeEnum, getFlockSettings } from '@livestock/flock';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { getElementSettings, selectElementSettings } from '@livestock/installation';

declare module '@angular-material-components/datetime-picker' {
  // eslint-disable-next-line
  export interface NgxMatTimepickerComponent<D> {
    uuids: string[];
  }
}

@Component({
  selector: 'ls-sprinklers-foggers-program',
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    KeyboardComponent,
    ButtonWithIconComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    MatDatepickerModule,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    NativeElementInjectorDirective,
    MemoizeFuncPipe,
    FindNextToPipe,
    ProgramTableMobileComponent,
    AddProgramDayMobileDialogComponent,
    ProgramDeviceButtonsComponent,
    PageWrapperComponent,
    ErrorFieldDirective,
    QaTagsDirective,
  ],
  templateUrl: './sprinklers-foggers-program.component.html',
  styleUrls: ['./sprinklers-foggers-program.component.scss'],
})

export class SprinklersFoggersProgramComponent implements OnInit, OnDestroy, AfterViewInit, IComponentCanDeactivate {
  @ViewChildren('timepicker') timepickerRef: QueryList<NgxMatTimepickerComponent<any>>;
  @ViewChild('periodsScrollbar') periodsScrollbar: ElementRef;
  @ViewChild('mobileTable') mobileTable;

  /*observables*/
  periods$ = this.store.select(selectSprinklersFoggersPeriods);
  currentPeriod$ = this.store.select(selectCurrentSprinklersFoggersProgramPeriod);
  currentProgram$ = this.store.select(selectCurrentSprinklersFoggersProgram);
  isLoading$ = this.store.select(selectSprinklersFoggersProgramIsLoading);
  selectHourFormatForTimePicker$ = this.store.select(selectHourFormatForTimePicker);
  sub$ = new Subscription();
  isAMPM$ = this.store.select(selectCurrentKeyboardUUID).pipe(
    map(i => this.timepickerRef.reduce((acc, i) => ([...acc, ...(i.uuids || [])]), []).includes(i)),
    tap(i => {
      if (i) {
        this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
      }
    }),
  );

  /*variables*/
  editMode: boolean = false;
  selectedProgramId: number | null = null;
  selectedProgramIndex: number | null = null;
  form: FormGroup;
  lastSavedValue: string;
  currentTimeUUID: string;
  itemsToDelete = [];
  dialogRef: MatDialogRef<any, any>;
  isflockModeAdvance: boolean;
  humLevelToRestart: number;

  /*constants*/
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;
  GlobalConstants = GlobalConstants;
  CoolingProgramConstants = CoolingProgramConstants;
  ErrorFieldEnum = ErrorFieldEnum;
  periodCellHeight = 50;

  // Mobile
  currentProgramIndex: number = 1;
  isDisabled: boolean = false;

  get formUnchanged(): boolean {
    return this.lastSavedValue === JSON.stringify(this.form?.value);
  }

  constructor(
    public dialogService: DialogsService,
    public platformService: PlatformService,
    public activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private store: Store,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private actions: Actions,
    private translate: TranslateService,
    private router: Router,
    public languageService: LanguageService,
  ) {
  }

  ngAfterViewInit(): void {
    this.updateIds();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
    this.store.dispatch(programActions.resetSprinklersFoggers());
  }

  async ngOnInit(): Promise<void> {
    this.store.dispatch(getElementSettings({
      elementType: ElementTypesEnum.SprinklersAndFoggers,
    }));

    this.sub$.add(
      this.store.select(selectElementSettings).pipe(
        filter((res) => res != null),
      ).subscribe(settings => {
        this.humLevelToRestart = settings.humLevelToRestart;
      }),
    );

    this.store.dispatch(setKeyboardIconPath({ path: 'dashboard/sprinklers' }));

    this.sub$.add(
      this.store.select(selectActiveControllerID).subscribe((controllerID) => {
        this.store.dispatch(getFlockSettings({ controllerID }));
    }));

    this.sub$.add(
      this.store.select(selectControllerVentilationMode).pipe(
        filter(ventilationWorkingMode => ventilationWorkingMode != null),
      ).subscribe(ventilationWorkingMode => {
        this.isflockModeAdvance = ventilationWorkingMode === VentilationWorkingModeEnum.Advance;
      }),
    );

    this.store.dispatch(programActions.getSprinklersFoggersProgramPeriod());

    this.sub$.add(
      this.store.select(selectSprinklersFoggersPeriods)
        .pipe(filter(periods => periods !== null), take(1)).subscribe(periods => {
        this.setPeriod(periods[0], false);
      }),
    );

    this.sub$.add(
      this.store.select(selectCurrentSprinklersFoggersProgram)
        .pipe(filter(i => !!i))
        .subscribe(async (program) => {
          if (this.form) {
            this.form.enable();
          }
          this.form = this.fb.group({
            items: this.fb.array(program.items ? program.items.map(i => this.getSprinklersFoggersProgramFG(i)) : []),
          });
          this.store.dispatch(keyboardUpdateFields());
          await this.addDefaultRow();
          this.lastSavedValue = JSON.stringify(this.form.value);
          if (!this.editMode) {
            this.form.disable();
          }
          this.updateIds();
        }),
    );

    this.sub$.add(
      this.actions.pipe(filter(action => action.type === programActions.saveSprinklersFoggersProgramSuccess.type)).subscribe(() => {
        this.lastSavedValue = JSON.stringify(this.form.value);
      }),
    );

    this.initTimePickerFocus();
  }

  goToSettings(): void {
    this.router.navigate([AppRoutes.INSTALLATION, 'elementType', ElementTypesEnum.SprinklersAndFoggers, 'settings']);
  }

  setFocusedTimepicker(e): void {
    const uuid = e.target.getAttribute('uuid');
    this.currentTimeUUID = uuid;

    if (!uuid) return;

    this.store.dispatch(setElementUuid({
      elementUuid: uuid,
    }));
  }

  initTimePickerFocus(): void {
    if (this.platformService.isMobileApp) return;
    this.sub$.add(
      this.store.select(selectKeyboardValueAndUUID)
        .pipe(
          tap(({ elementUuid }) => {
            this.currentTimeUUID = elementUuid;
          }),
          filter(({ symbol, elementUuid }) => symbol != null &&
            this.timepickerRef
              .reduce((acc, i) => ([...acc, ...i.uuids]), [])
              .includes(elementUuid),
          ),
        ).subscribe(({ symbol, elementUuid }) => {
        const focusedControl = document.activeElement as HTMLInputElement;
        if (!focusedControl) {
          return;
        }

        // Change focus on Enter
        if (symbol.toString().includes(KeyboardEnum.Enter)) {
          this.focusControl(elementUuid);
          return;
        }

        //to set value according to vladimir code
        const timepickerControlName = focusedControl.attributes.getNamedItem('formcontrolname')?.value;
        if (!timepickerControlName) {
          return;
        }

        const ref = this.timepickerRef.find(i => i.uuids.includes(elementUuid));

        //AM PM BUTTONS BLYAT GOSPODI ETOT DISIGNER ZHELAET MOEY SMERTI
        if (symbol === KeyboardEnum.AM || symbol === KeyboardEnum.PM) {
          ref.meridian = symbol === KeyboardEnum.PM ? KeyboardEnum.PM : KeyboardEnum.AM;
          return;
        }

        // Put symbol
        const { selectionStart, selectionEnd } = focusedControl;
        const isSingleDeletion = selectionStart === selectionEnd && symbol === '' && selectionStart > 0;
        const isMultipleDeletion = symbol === '' && selectionStart !== selectionEnd;
        const currentValue = ref.form.controls[timepickerControlName].value.toString().split('');
        if (isSingleDeletion) {
          // remove the symbol before the cursor
          currentValue.splice(selectionStart - 1, 1);
        } else {
          // add symbol in the right place or remove multiple symbols
          currentValue.splice(selectionStart, selectionEnd - selectionStart, symbol as string);
        }

        ref.form.controls[timepickerControlName].setValue(currentValue.join('').slice(0, 2));

        this.store.dispatch(clearKeyboardValue());

        // set cursor for prev place for single deletion
        if (isSingleDeletion) {
          focusedControl.selectionStart = selectionStart - 1;
          focusedControl.selectionEnd = selectionStart - 1;
          return;
        }

        // do not move cursor for multiple deletion
        if (isMultipleDeletion) {
          focusedControl.selectionStart = selectionStart;
          focusedControl.selectionEnd = selectionStart;
          return;
        }

        // set cursor on next place after adding new symbol
        focusedControl.selectionStart = selectionStart + 1;
        focusedControl.selectionEnd = selectionStart + 1;
      }),
    );
  }

  getSprinklersFoggersProgramFG({
                                  periodID,
                                  programID,
                                  startTemp,
                                  startTime,
                                  stopTemp,
                                  element1Connected,
                                  element2Connected,
                                  element3Connected,
                                  endTime,
                                  ventStartLevel,
                                  offTime,
                                  onTime,
                                  humStopLevel,
                                }: ISprinklersFoggersProgram): FormGroup {
    return this.fb.group({
      programID: this.fb.control(programID),
      periodID: this.fb.control(periodID),
      startTime: this.fb.control(
        utils.TimeUtils.BackendValueToMoment(startTime || GlobalConstants.TimeDefault),
        EnableValidatorIfControlTouched(Validators.required)),
      endTime: this.fb.control(
        utils.TimeUtils.BackendValueToMoment(endTime || GlobalConstants.TimeDefault),
        EnableValidatorIfControlTouched(Validators.required)),
      onTime: this.fb.control(onTime,
        EnableValidatorsIfControlTouched([
          Validators.min(CoolingProgramConstants.onTimeMin),
          Validators.max(CoolingProgramConstants.onTimeMax),
          Validators.required,
        ])),
      offTime: this.fb.control(offTime,
        EnableValidatorsIfControlTouched([
          Validators.min(CoolingProgramConstants.offTimeMin),
          Validators.max(CoolingProgramConstants.offTimeMax),
          Validators.required,
        ])),
      startTemp: this.fb.control(startTemp,
        EnableValidatorsIfControlTouched([
          Validators.min(CoolingProgramConstants.startTempMin),
          Validators.max(CoolingProgramConstants.startTempMax),
          greaterThanValidator('stopTemp', 'lt'),
          Validators.required,
        ])),
      stopTemp: this.fb.control(stopTemp,
        EnableValidatorsIfControlTouched([
          Validators.min(CoolingProgramConstants.stopTempMin),
          Validators.max(CoolingProgramConstants.stopTempMax),
          lessThanValidator('startTemp', 'gt'),
          Validators.required,
        ])),
      ventStartLevel: this.fb.control(ventStartLevel,
        EnableValidatorsIfControlTouched(this.isflockModeAdvance ? [
          Validators.min(CoolingProgramConstants.ventStartLevelAdvancedMin),
          Validators.max(CoolingProgramConstants.ventStartLevelAdvancedMax),
          Validators.required,
        ] : [
          Validators.min(CoolingProgramConstants.ventStartLevelMin),
          Validators.max(CoolingProgramConstants.ventStartLevelMax),
          Validators.required,
        ])),
      humStopLevel: this.fb.control(humStopLevel,
        EnableValidatorsIfControlTouched([
          Validators.min(this.humLevelToRestart || CoolingProgramConstants.humStopLevelMin),
          Validators.max(CoolingProgramConstants.humStopLevelMax),
          Validators.required,
        ])),
      element1Connected: this.fb.control(element1Connected || false),
      element2Connected: this.fb.control(element2Connected || false),
      element3Connected: this.fb.control(element3Connected || false),
    }, {
      validators: EnableValidatorsIfControlTouched([
        StartTimeAfterEndTime,
        AtLeastOneElementSelected,
      ]),
    });
  }

  selectRow(row: FormGroup, rowIndex: number): void {
    this.selectedProgramId = row.value.programID;
    this.selectedProgramIndex = rowIndex;

    // Due to the requirement to validate the form only if it was touched ...
    const markRowAsTouched = (emitEvent: boolean): void => {
      row.markAllAsTouched();
      row.markAsDirty();

      Object.values(row.controls).forEach(i => {
        i.markAsDirty();
        i.updateValueAndValidity({ emitEvent });
      });

      row.updateValueAndValidity({ emitEvent });
    };

    if (this.platformService.isMobileApp) {
      row.valueChanges.subscribe(() => {
        this.editMode && markRowAsTouched(false);
      });
    } else {
      markRowAsTouched(true);
    }
  }

  updateIds(): void {
    if (this.platformService.isMobileApp) return;

    setTimeout(() => {
      const inputIds = Array.from(document.querySelectorAll('.program ngx-mat-timepicker')).map(domTimepicker => {
        return Array.from(
          domTimepicker.querySelectorAll('input.mat-mdc-input-element'))
          .map(inputElement => inputElement.getAttribute('uuid'));
      });
      if (!inputIds?.length) return;

      this.timepickerRef.forEach((timeinputObject, index) => {
        timeinputObject.uuids = inputIds[index];
      });
    }, 1000);
  };

  enableEdit(): void {
    this.form.enable();
    this.editMode = true;
    this.addDefaultRow();

    if (this.platformService.isMobileApp) {
      this.selectedProgramIndex == null && (this.selectedProgramIndex = 0);
      this.selectRow((this.form.controls['items'] as FormArray).at(this.selectedProgramIndex) as FormGroup, 0);
    }
    this.updateIds();
  }

  async addDefaultRow(): Promise<void> {
    const items = (this.form.controls['items'] as FormArray);
    if (!items.length) {
      await this.addEmptyRow();

      if (this.platformService.isMobileApp) {
        // update empty program validation
        const row = items.controls[0] as FormGroup;
        this.selectRow(row, 0);
      }
    }
  }

  async getEmptyRow(): Promise<FormGroup<any>> {
    const currentPeriod = await firstValueFrom(this.store.select(selectCurrentSprinklersFoggersProgramPeriod));
    return this.getSprinklersFoggersProgramFG({ periodID: currentPeriod?.periodID } as any);
  }

  async addEmptyRow(): Promise<void> {
    const items = (this.form.controls['items'] as FormArray);

    const emptyRow = await this.getEmptyRow();
    items.push(emptyRow);
    this.store.dispatch(keyboardUpdateFields());
    this.ngAfterViewInit();

    if (this.platformService.isMobileApp) {
      this.currentProgramIndex = items?.length;
      if (items?.length > 1) this.mobileTable.swiper.swiperRef.activeIndex = items.length;
    }
  }

  getRows(): FormGroup[] {
    return (this.form?.controls['items'] as FormArray)?.controls as FormGroup[];
  }

  cancel(): void {
    this.menuService.toggleDashboardMenu(true);
  }

  async setPeriod(newPeriod: ISprinklersFoggersProgramPeriod, checkCurrentPeriod: boolean = true, index?: number): Promise<void> {
    const currentPeriod = checkCurrentPeriod
      ? await firstValueFrom(this.currentPeriod$)
      : null;
    const isCurrentPeriodMock = currentPeriod && !currentPeriod.periodID;
    if (isCurrentPeriodMock || (this.form?.value.items.length && !this.formUnchanged)) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    if (isCurrentPeriodMock) {
      this.store.dispatch(programActions.deleteSprinklersFoggersProgramDay({ periodID: currentPeriod.periodID }));
    }

    this.store.dispatch(programActions.setCurrentSprinklersFoggersProgramPeriod({ currentPeriod: newPeriod || null }));
    this.store.dispatch(programActions.getSprinklersFoggersProgram({ periodID: newPeriod?.periodID || null }));
    this.selectedProgramIndex = null;
    this.selectedProgramId = null;

    //scroll to period
    if (index != null) {
      setTimeout(() => {
        this.periodsScrollbar.nativeElement.scrollTop = this.periodCellHeight * index;
      }, 250);
    }
  }

  async addDay(): Promise<void> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    if (!this.formUnchanged || !currentPeriod.periodID) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    ;

    this.dialogRef = this.dialog.open(AddDayDialogComponent, {
      width: '500px',
    });

    this.sub$.add(
      this.periods$.pipe(take(1)).subscribe((i) => {
          const days = i.map(i => i.dayNumber);
          this.dialogRef.componentInstance.setup(days);
        },
      ),
    );

    const res = await lastValueFrom(this.dialogRef.afterClosed());
    this.dialogRef = null;
    if (!res) return;

    if (!currentPeriod.periodID) {
      this.store.dispatch(programActions.deleteSprinklersFoggersProgramDay({
        periodID: currentPeriod.periodID,
        preventSelecting: true,
      }));
    }

    this.store.dispatch(programActions.addSprinklersFoggersProgramDay(res));
  }

  async clearBeforeDayAdding(dayNumberControl: FormControl): Promise<void> {
    if (!this.formUnchanged) {
      const canLeave = await this.dialogService.canContinueAction();
      if (!canLeave) return;
    }
    ;

    dayNumberControl.setValue(null);
    this.store.dispatch(programActions.clearProgram());
  }

  addNewDayMobile(dayNumber: number): void {
    this.store.dispatch(programActions.addSprinklersFoggersProgramDay({ dayNumber }));
  }

  setDisabledState(ev: boolean): void {
    this.isDisabled = typeof ev === 'boolean' ? ev : false;
  }

  focusControl(property: string): void {
    const control = document.querySelector(`[uuid=${property}]`) as HTMLInputElement;
    control.focus();
    control.setSelectionRange(0, 0);
  }

  async cancelEdit(): Promise<void> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    const periods = await firstValueFrom(this.periods$);

    if (this.formUnchanged && currentPeriod.periodID) {
      this.restoreDefault();
    } else {
      const res = await this.dialogService.canContinueAction();
      if (res) {
        this.restoreDefault();
        this.store.dispatch(programActions.filterPeriods({ currentPeriod }));
        const period = !currentPeriod?.periodID ? periods.find(p => !!p?.periodID) : currentPeriod;
        this.store.dispatch(programActions.setCurrentSprinklersFoggersProgramPeriod({ currentPeriod: period }));
        this.store.dispatch(programActions.getSprinklersFoggersProgram({ periodID: period?.periodID }));
        this.editMode = false;
        this.isDisabled = false;
      }
    }
    this.updateIds();
  }

  restoreDefault(): void {
    this.editMode = false;
    const lastValue = JSON.parse(this.lastSavedValue);
    lastValue.items = lastValue.items.map((program): ISprinklersFoggersProgram => ({
      ...program,
      startTime: utils.TimeUtils.MomentToBackendValue(program.startTime),
      endTime: utils.TimeUtils.MomentToBackendValue(program.endTime),
    }));

    this.form = this.fb.group({
      items: this.fb.array(lastValue.items.map(i => this.getSprinklersFoggersProgramFG(i))),
    });

    const items = (this.form.controls['items'] as FormArray);
    const emptyItemIndex = items.controls.findIndex(i => !i.value.programID);

    if (emptyItemIndex !== -1 && emptyItemIndex !== 0) {
      items.removeAt(emptyItemIndex);
    }

    this.form.disable();

    this.itemsToDelete = [];
  }

  getAllNonEmptyRowsValue(): any {
    return {
      ...this.form.value,
      items: this.form.value.items.filter(i => {
        return i.programID || !AtLeastOneElementSelected({ value: i } as any);
      }),
    };
  };

  async save(): Promise<void> {
    const values = this.getAllNonEmptyRowsValue();

    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    if (this.form.valid) {
      const deleteNumbers = Array.from(new Set(this.itemsToDelete.map(i => i.programIndex + 1))).sort();
      if (deleteNumbers.length) {

        const deleteText = this.translate.instant(deleteNumbers.length > 1 ?
            'Controls.Element.SaveChangesWithDeleteTextMultiple' :
            'Controls.Element.SaveChangesWithDeleteText',
          { programs: deleteNumbers.join(', ') });

        const res = await this.dialogService.question(
          deleteText, 'Controls.Element.SaveChanges', [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
          this.platformService.isMobileApp ? 'warning-orange' : 'flash/warning',
          false,
        );

        if (res === DialogButtonEnum.CANCEL || !res) return;
      }

      this.lastSavedValue = JSON.stringify(values);
      this.store.dispatch(
        saveSprinklersFoggersProgram({
          period: currentPeriod,
          itemsToDelete: this.itemsToDelete,
          items:
            values.items.map((i: ISprinklersFoggersProgram) => ({
              ...i,
              endTime: utils.TimeUtils.MomentToBackendValue(i.endTime),
              startTime: utils.TimeUtils.MomentToBackendValue(i.startTime),
            })),
        }),
      );
      this.itemsToDelete = [];
    }
  }

  areNoFilledRows(): boolean {
    return !this.getAllNonEmptyRowsValue().items.length;
  }

  deleteProgram(currentPeriod: ISprinklersFoggersProgramPeriod): void {
    if (this.platformService.isMobileApp) {
      const controls = (this.form.get('items') as FormArray).controls;
      this.selectedProgramId = controls[this.currentProgramIndex - 1]?.value?.programID;
    }

    (this.form.controls['items'] as FormArray).removeAt(this.selectedProgramIndex);

    if (this.selectedProgramId) {
      this.itemsToDelete.push({
        programID: this.selectedProgramId,
        programIndex: this.selectedProgramIndex,
        periodID: currentPeriod.periodID,
      });
    }

    if (!this.platformService.isMobileApp) {
      this.selectedProgramIndex = null;
      this.selectedProgramId = null;
    }
  }

  async deleteDay(): Promise<void> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    const deleteText = this.translate.instant('Controls.Element.DeleteDayText', { dayNumber: currentPeriod.dayNumber });

    const res = await this.dialogService.question(
      deleteText, 'Controls.Element.DeleteDay', [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
      this.platformService.isMobileApp ? 'warning-orange' : 'flash/warning',
      false,
    );

    if (res === DialogButtonEnum.CANCEL || !res) return;
    this.store.dispatch(programActions.deleteSprinklersFoggersProgramDay({ periodID: currentPeriod?.periodID }));

    this.sub$.add(
      this.store.select(selectSprinklersFoggersPeriods)
        .pipe(
          filter(periods => !!periods),
        ).subscribe(periods => {
        this.setPeriod(periods[periods?.length - 1], false);
      }),
    );

    if (this.platformService.isMobileApp) this.editMode = false;
  }

  isFocused([timepicker, uuid]: [NgxMatTimepickerComponent<any>, string]): boolean {
    return timepicker?.uuids?.includes(uuid);
  }

  onTableChange(currentProgramIndex: number): void {
    this.currentProgramIndex = currentProgramIndex + 1;
    this.selectRow((this.form.controls['items'] as FormArray).at(currentProgramIndex) as FormGroup, currentProgramIndex);
  }

  async canDeactivate(): Promise<boolean> {
    const currentPeriod = await firstValueFrom(this.currentPeriod$);
    return this.formUnchanged && currentPeriod?.periodID != null;
  }

  closeComponent(): void {
  }
}
