import { IconsQuickStartEnum } from './icons-quick-start.enum';
import { IconsCommonEnum } from './icons-common.enum';
import { IconsNetworkEnum } from './icons-network.enum';
import { IconsHouseEnum } from './icons-house.enum';
import { IconsAuthEnum } from './icons-auth.enum';
import { IconsFlockEnum } from './icons-flock.enum';

export const IconsEnum = {
  ...IconsQuickStartEnum,
  ...IconsCommonEnum,
  ...IconsNetworkEnum,
  ...IconsHouseEnum,
  ...IconsAuthEnum,
  ...IconsFlockEnum,
};
