import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import {
  setActiveControllerID,
} from 'libs/controllers/src/lib/+state/current-controller/current-controller.actions';

import { filter, firstValueFrom } from 'rxjs';
import { getStaticPressure, selectCouldBeOpened } from '@livestock/static-pressure';
import { DialogsService } from '@livestock/shared/services';

export const StaticPressureGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
  const store = inject(Store);
  const dialogsService = inject(DialogsService);

  store.dispatch(setActiveControllerID({ controllerID: +route.params['controllerID'] }));
  store.dispatch(getStaticPressure());

  const couldBeOpened = await firstValueFrom(store.select(selectCouldBeOpened).pipe(
    filter(res => res != null),
  ));

  if (couldBeOpened) {
    return true;
  }

  dialogsService.ok('StaticPressure.AddStaticPressureElementInDeviceFirst', 'Warning');
  return false;
};
