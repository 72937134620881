import { Action, createReducer, on } from '@ngrx/store';
import * as airTreatmentActions from './air-treatment.actions';
import { AirTreatmentState } from '@livestock/air-treatment';

export const initialState: AirTreatmentState = {
    isLoading: false,
    items: null,
    settings: null,
    thresholds: null,
};

const reducer = createReducer(
    initialState,
    on(
        airTreatmentActions.getAirTreatmentItems,
        airTreatmentActions.updateAirTreatmentItems,
        (state) => {
            return {
                ...state,
                isLoading: true,
            };
        },
    ),
    on(
        airTreatmentActions.getAirTreatmentItemsError,
        airTreatmentActions.updateAirTreatmentItemsError,
        (state) => {
            return {
                ...state,
                isLoading: false,
            };
        },
    ),
    on(airTreatmentActions.getAirTreatmentItemsSuccess,
        (state, { view }) => {
            return {
                ...state,
                items: view.items,
                isLoading: false,
            };
        }),
    on(airTreatmentActions.setAirTreatmentItems,
        (state, { items }) => {
            return {
                ...state,
                items,
            };
        }),
    on(airTreatmentActions.updateAirTreatmentItemsSuccess,
        (state, { updatedItems }) => {
            return {
                ...state,
                isLoading: false,
                items: updatedItems,
            };
        }),
    on(airTreatmentActions.setAirTreatmentSettings,
      airTreatmentActions.getAirTreatmentSettingsSuccess,
      (state, { view }) => {
        return {
            ...state,
            isLoading: false,
            settings: view,
        };
    }),

    on(airTreatmentActions.getAirTreatmentThresholdsSuccess,
        (state, { view }) => {
            return {
                ...state,
                thresholds: view,
            };
        }),
    on(airTreatmentActions.setAirTreatmentThresholds,
        (state, { view }) => {
            return {
                ...state,
                thresholds: view,
            };
        }),
);

export function airTreatmentReducer(state: AirTreatmentState | undefined, action: Action): any {
    return reducer(state, action);
}
