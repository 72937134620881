import { createFeatureSelector, createSelector } from '@ngrx/store';
import { TemperatureCurveState } from '@livestock/temperature-curve';

export const TEMPERATURE_CURVE_KEY = 'temperaturCurve';

export const getTemperatureCurveState =
  createFeatureSelector<TemperatureCurveState>(TEMPERATURE_CURVE_KEY);

export const selectIsLoading = createSelector(
  getTemperatureCurveState,
  ({ isLoading }) => isLoading,
);

export const selectTemperatureCurveItems = createSelector(
  getTemperatureCurveState,
  ({ view }) => view?.items,
);

export const selectTemperatureCurveSettings = createSelector(
  getTemperatureCurveState,
  ({ settings }) => settings,
);
