import { Component, HostListener } from '@angular/core';
import { PageInfoComponent } from './page-info/page-info.component';
import { CommonModule } from '@angular/common';
import { DashboardWrapperComponent } from '../dashboard-wrapper/dashboard-wrapper.component';
import { DashboardWrapperMobileComponent } from '../m-dashboard-wrapper/dashboard-wrapper-mobile.component';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-page-content',
  templateUrl: './page-content.component.html',
  styleUrls: ['./page-content.component.scss'],
  imports: [CommonModule, DashboardWrapperComponent, PageInfoComponent, DashboardWrapperMobileComponent],
  standalone: true,
})
export class PageContentComponent {
  innerWidth = window.innerWidth;

  constructor(public platformService: PlatformService) {
  }

  get pageInfoIsOpened(): boolean {
    return !localStorage.getItem('hidePageInfo');
  }

  @HostListener('window:resize', ['$event'])
  onResize(event): void {
    this.innerWidth = event.target.innerWidth;
  }

}
