import { Component, Input } from '@angular/core';
import { ColorsEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';
import { IElementType } from '../../interfaces/element/element-type.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { firstValueFrom, Observable } from 'rxjs';
import {
  selectCurrentConnectionID,
  selectCurrentConnectionSetup,
  selectCurrentConnectionSetupIsNew,
  selectCurrentElementType,
  selectElementIsLoading,
  setNewConnectionSetup,
} from '@livestock/installation';
import { InstallationHelpersService } from '../../service/installation-helpers.service';
import { IConnectionSetup } from '../../interfaces/connection-setup.interface';

@Component({
  selector: 'ls-device-types',
  templateUrl: './device-types.component.html',
  styleUrls: ['./device-types.component.scss'],
})
export class DeviceTypesComponent {
  @Input() elementTypes: IElementType[] = [];

  /*subs*/
  currentConnectionID$: Observable<number> = this.store.select(selectCurrentConnectionID);
  currentElementType$: Observable<number> = this.store.select(selectCurrentElementType);
  currentConnectionSetup$: Observable<IConnectionSetup> = this.store.select(selectCurrentConnectionSetup);
  currentConnectionSetupIsNew$: Observable<boolean> = this.store.select(selectCurrentConnectionSetupIsNew);
  isElementLoading$: Observable<boolean> = this.store.select(selectElementIsLoading);

  /*constants*/
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;

  constructor(
    private store: Store,
    private installationHelpersService: InstallationHelpersService,
  ) {
  }

  async changeActiveDevice(elementType: IElementType): Promise<void> {
    const currentElementType = await firstValueFrom(this.currentElementType$);
    if (currentElementType === elementType.elementType) {
      return;
    }

    const canChangeDevice = await this.installationHelpersService.canLeaveDirtyElementSetup();
    if (!canChangeDevice) {
      return;
    }

    this.store.dispatch(setNewConnectionSetup({ elementType }));
  }
}
