import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';

import { IStaticPressure } from '../interfaces/static-pressure.interface';

export const getStaticPressure = createAction(
  '[STATIC PRESSURE] Get Static Pressure [...]',
);

export const getStaticPressureSuccess = createAction(
  '[STATIC PRESSURE] Get Static Pressure [SUCCESS]',
  props<{ view: IStaticPressure }>(),
);

export const getStaticPressureError = createAction(
  '[STATIC PRESSURE] Get Static Pressure [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const updateStaticPressure = createAction(
  '[STATIC PRESSURE] Update Static Pressure [...]',
  props<{ view: IStaticPressure }>(),
);

export const updateStaticPressureSuccess = createAction(
  '[STATIC PRESSURE] Update Static Pressure [SUCCESS]',
  props<{ view: IStaticPressure }>(),
);

export const updateStaticPressureError = createAction(
  '[STATIC PRESSURE] Update Static Pressure [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
