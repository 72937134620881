import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import * as heatingProgramZoneActions from './heating-program-zone.actions';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { selectActiveControllerID } from '@livestock/controllers';
import { HeatingProgramZoneService } from '../services/heating-program-zone.service';
import { selectHeatingProgramZones } from './heating-program-zone.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';

@Injectable()

export class HeatingProgramZoneEffects {

  getHeatingProgramZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heatingProgramZoneActions.getHeatingProgramZones),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([_action, controllerID]) => {
        return this.heatingProgramZoneService.getHeatingProgramZoneView(controllerID).pipe(
          map((view) => heatingProgramZoneActions.getHeatingProgramZonesSuccess({ view })),
          catchError((error) => of(heatingProgramZoneActions.getHeatingProgramZonesError({ error }))),
        );
      }),
    ),
  );

  updateHeatingProgramZone$ = createEffect(() =>
    this.actions$.pipe(
      ofType(heatingProgramZoneActions.updateHeatingProgramZones),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectHeatingProgramZones),
      ),
      switchMap(([_action, controllerID, zones]) => {
        const updatedZones = zones.map(zone => {
          return {
            ...zone,
            controllerID,
          };
        });

        return this.heatingProgramZoneService.updateHeatingProgramZoneView(controllerID, updatedZones).pipe(
          switchMap((zones) => {
            return [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
              heatingProgramZoneActions.updateHeatingProgramZonesSuccess({ zones }),
            ];
          }),
          catchError((error) => of(heatingProgramZoneActions.updateHeatingProgramZonesError({ error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private heatingProgramZoneService: HeatingProgramZoneService,
  ) {
  }
}
