import { Injectable } from '@angular/core';
import { Capacitor } from '@capacitor/core';

@Injectable({
  providedIn: 'root',
})
export class PlatformService {
  private _isMobileApp: boolean;

  get isMobileApp(): boolean {
    return this._isMobileApp;
  }

  private _isDeviceApp: boolean;

  get isDeviceApp(): boolean {
    return this._isDeviceApp;
  }

  private _isDesktopApp: boolean;

  get isDesktopApp(): boolean {
    return this._isDesktopApp;
  }

  get isMd(): boolean {
    return window.innerWidth <= 767;
  }

  get isLaptop(): boolean {
    return window.innerWidth <= 1199;
  }

  get isNative(): boolean {
    return Capacitor?.isNativePlatform();
  }

  setAppValues(
    isMobileApp: boolean,
    isDeviceApp: boolean,
    isDesktopApp: boolean,
  ): void {
    this._isMobileApp = this._isMobileApp == null
      ? isMobileApp
      : this._isMobileApp;
    this._isDeviceApp = this._isDeviceApp == null
      ? isDeviceApp
      : this._isDeviceApp;
    this._isDesktopApp = this._isDesktopApp == null
      ? isDesktopApp
      : this._isDesktopApp;
  }
}
