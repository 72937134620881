import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from '@livestock/shared/environments';
import { IGetHeatingProgramZoneView } from '../interfaces/get-heating-program-zone-view.interface';
import { IHeatingProgramZoneItem } from '../interfaces/heating-program-zone-item.interface';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class HeatingProgramZoneService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getHeatingProgramZoneView(controllerID: number): Observable<IGetHeatingProgramZoneView> {
    return this.http.get<IGetHeatingProgramZoneView>(`${baseUrl}/${controllerID}/heating/program/zone`);
  }

  updateHeatingProgramZoneView(controllerID: number, view: IHeatingProgramZoneItem[]): Observable<IHeatingProgramZoneItem[]> {
    return this.http.put<IHeatingProgramZoneItem[]>(`${baseUrl}/${controllerID}/heating/program/zone`, view);
  }
}
