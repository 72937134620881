import { IElement } from '@livestock/installation/interfaces';

export interface IHeatingProgramZoneItem {
  zoneID: number;
  controllerID: number;
  name: string;
  isAverage: boolean;
  heatingElements: IElement[];
  sensorElements: IElement[];
  isUsedInProgram: boolean;
}
