<ng-container *ngIf="!isControllerAssignedWithFarm; else deviceWasConnected">
  <ng-container [ngTemplateOutlet]="topIconsAndInfo"></ng-container>

  <div class="fz-20 cloud-info">
    <ng-container *ngIf="qrCodeData">
      <span class="cloud-info__scan mt-30">{{ "Controller.CloudSettings.ScanTheQRCodeMessage" | translate }}</span>
      <span class="cloud-info__device-number mt-25 mb-15 color-secondary">
        {{ "Controller.CloudSettings.DeviceConnectionNumber" | translate }}
        {{ connectionNumber }}
      </span>

      <qrcode
        [colorLight]="'#ebeff5'"
        [errorCorrectionLevel]="'M'"
        [margin]="0"
        [qaTags]="'qa-img-QR'"
        [qrdata]="qrCodeData"
        [width]="160" />
    </ng-container>

    <ng-container *ngIf="!qrCodeData">
      {{ "Controller.CloudSettings.ControllerCreationInProgress" | translate }}
    </ng-container>
  </div>
</ng-container>

<ng-template #successAssignment>
  <div class="h-100-percent d-flex flex-column justify-center align-center">
    <div class="d-flex justify-center mb-10">
      <div class="green-round">
        <ls-svg-icon [path]="'large-tick'"></ls-svg-icon>
      </div>
    </div>

    <div class="fz-24 color-secondary d-flex flex-column justify-center text-center">
      <span class="mb-15">{{ "Controller.CloudSettings.Great" | translate }}</span>
      <span
        [innerHtml]="
          'Controller.CloudSettings.DeviceNoHasBeenSetUp' | translate : { sn } | styleTextPart : sn : 'color-primary'
        ">
      </span>

      <div *ngIf="updatedHouseNumber">
        {{ "Controller.CloudSettings.ThisDeviceHouseNumberWasUpdatedTo" | translate }}
        <span class="color-primary fw-700">{{ updatedHouseNumber }}</span>
      </div>

      <span>{{ "Controller.CloudSettings.SelectHowYouWouldLikeToProceed" | translate }}</span>
    </div>

    <div class="d-flex justify-center mt-30">
      <ls-button
        (click)="goToDashboard()"
        [qaTags]="'qa-btn-go-to-dashboard'"
        class="mr-20 ml-20"
        type="secondary">
        {{ "Controller.CloudSettings.GoToDashboard" | translate }}
      </ls-button>
      <ls-button
        (click)="goToInstallation()"
        [qaTags]="'qa-btn-continue-with-installation-wrapper'"
        class="mr-20 ml-20"
        type="secondary">
        {{ "Controller.CloudSettings.ContinueWithInstallation" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>

<ng-template #pairingRequest>
  <div class="h-100-percent d-flex flex-column justify-center align-center">
    <div class="d-flex justify-center mb-50">
      <div class="green-round">
        <ls-svg-icon [path]="'large-tick'"></ls-svg-icon>
      </div>
    </div>

    <div class="fz-24 color-secondary d-flex flex-column justify-center text-center">
      <div>
        {{ "Controller.CloudSettings.The" | translate }}
        <span class="color-primary">{{ farmName }}</span>
        {{ "Controller.CloudSettings.Requested" | translate }}
      </div>
      <span>{{ "Controller.CloudSettings.ToPairThisIronDevice" | translate }}</span>
    </div>

    <div class="d-flex justify-center mt-30">
      <ls-button
        (click)="rejectFarm()"
        [qaTags]="'qa-btn-go-to-dashboard'"
        class="mr-20 ml-20"
        type="secondary">
        {{ "Controller.CloudSettings.Reject" | translate }}
      </ls-button>
      <ls-button
        (click)="acceptFarm()"
        class="mr-20 ml-20"
        type="primary">
        {{ "Controller.CloudSettings.Accept" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>

<ng-template #deviceWasDisconnected>
  <div class="h-100-percent d-flex flex-column justify-center align-center">
    <div class="d-flex justify-center mb-10">
      <div class="green-round">
        <ls-svg-icon [path]="'fail-circle'"></ls-svg-icon>
      </div>
    </div>

    <div class="fz-24 color-secondary d-flex flex-column justify-center align-center mb-80">
      <span class="mb-10">{{ "Controller.CloudSettings.Oops" | translate }}</span>
      <div>
        {{ "Controller.CloudSettings.ThisDeviceDisconnectedFrom" | translate }}
        <span class="color-primary">{{ farmName }}</span>
      </div>
    </div>

    <div class="d-flex justify-center mt-30">
      <ls-button
        (click)="goToDashboard()"
        [qaTags]="'qa-btn-go-to-dashboard'"
        class="mr-20 ml-20"
        type="secondary">
        {{ "Controller.CloudSettings.GoToDashboard" | translate }}
      </ls-button>
      <ls-button
        (click)="goToInstallation()"
        [qaTags]="'qa-btn-continue-with-installation-wrapper'"
        class="mr-20 ml-20"
        type="secondary">
        {{ "Controller.CloudSettings.ContinueWithInstallation" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>

<ng-template #deviceWasConnected>
  <ng-container [ngTemplateOutlet]="topIconsAndInfo"></ng-container>

  <div class="d-flex flex-column justify-center align-center">
    <div class="fz-20 color-secondary connected-by-info mb-35 mx-auto text-center">
      {{ "Controller.CloudSettings.ThisDeviceWasConnectedBy" | translate }}
      <span class="fw-500 color-primary">{{ userEmail }}</span>
      {{ "Controller.CloudSettings.To" | translate }}
      <span class="fw-500 color-primary">{{ farmName }}</span>
    </div>
  </div>
</ng-template>

<ng-template #topIconsAndInfo>
  <ng-container *ngIf="networkSettings$ | async as networkSettings">
    <div class="connection-status d-flex justify-between align-start mx-auto mb-70 fz-20 fz-18-md color-secondary">
      <div class="connection-status__id d-flex flex-column justify-center align-center">
        <div class="connection-status-icon position-relative mb-10">
          <ls-svg-icon [path]="'quick-start/network/device-id'"></ls-svg-icon>
        </div>
        <p class="word-break w-200 text-center">
          {{ "Controller.NetworkSettings.ID" | translate }}
          <span class="fw-500 color-primary">{{ (controllerSerialNumber$ | async) || "0000" }}</span>
        </p>
      </div>

      <div
        class="connection-status__network connection-status__network-connected d-flex flex-column justify-center align-center">
        <div class="connection-status-icon position-relative mb-10">
          <ng-container *ngIf="networkSettings.lan">
            <ls-svg-icon [path]="'quick-start/network/cellular-success'"></ls-svg-icon>
          </ng-container>

          <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
            <ls-svg-icon [path]="'quick-start/network/wifi-success'"></ls-svg-icon>
          </ng-container>

          <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
            <ls-svg-icon [path]="'quick-start/network/cellular-success'"></ls-svg-icon>
          </ng-container>
        </div>

        <ng-container *ngIf="networkSettings.lan">
          <span>{{ networkSettings.lanIp }}</span>
        </ng-container>

        <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
          <span>{{ networkSettings.wifiName }}</span>
        </ng-container>

        <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
          <span>{{ networkSettings.modemAPN }}</span>
        </ng-container>
      </div>

      <div class="connection-status__farm d-flex flex-column justify-center align-center">
        <div class="connection-status-icon position-relative mb-10">
          <ls-svg-icon [path]="'quick-start/network/no-farm'"></ls-svg-icon>
        </div>

        <span class="d-none">{{ farmName }}</span>
        <span class="word-break w-200 text-center">{{ "Controller.NetworkSettings.NoFarm" | translate }}</span>
      </div>
    </div>
  </ng-container>
</ng-template>
