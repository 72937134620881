import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  DualToggleComponent,
  InputComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent,
  LVInputComponent,
  LVInputDecimalWithLabelComponent,
  LVInputIntegerWithLabelComponent,
  LvInputTimeComponent,
  RangeComponent,
  selectVirtualKeyboardAMPM,
  selectVirtualKeyboardIsActive, setVirtualKeyboardAMPM,
  setVirtualKeyboardAMPMDisabled,
  SvgIconComponent,
} from '@livestock/ui';
import { IconsEnum, LengthUnitEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { firstValueFrom, Observable, Subscription } from 'rxjs';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { Store } from '@ngrx/store';
import { IMinMax } from '@livestock/shared/interfaces';
import {
  FlockConstants,
  IFlockHouseModeView,
  RangesMaxMustBeGreaterThanMin,
  VentilationWorkingModeEnum,
} from '@livestock/flock';
import { HoursFormatTypeEnum } from '../../enums/hours-format-type.enum';
import { VirtualKeyboardConstants } from 'libs/ui/src/lib/controls/virtual-keyboard/virtual-keyboard.constants';
import { AmPmEnum } from '../../enums/am-pm.enum';
import { TimeUtils } from '@livestock/shared/utils';

type focusedInput =  'cleaning' | 'catching' | 'empty' | 'preheat';

@Component({
  selector: 'ls-house-modes-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    InputComponent,
    QaTagsDirective,
    LoadingComponent,
    InputDecimalComponent,
    ErrorFieldDirective,
    KeyboardComponent,
    LVInputIntegerWithLabelComponent,
    InputIntegerComponent,
    LVInputComponent,
    LVInputDecimalWithLabelComponent,
    RangeComponent,
    DualToggleComponent,
    LvInputTimeComponent,
  ],
  templateUrl: './house-modes-form.component.html',
  styleUrls: ['./house-modes-form.component.scss'],
})
export class ControllerHouseModesFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;

  @Input() set hoursFormat(hoursFormat: HoursFormatTypeEnum) {
    if (this.platformService.isDeviceApp) {
      const AMPMDisabled = hoursFormat === HoursFormatTypeEnum.HoursFormat24;
      this.store.dispatch(setVirtualKeyboardAMPMDisabled({ AMPMDisabled }));
    }

    this._hoursFormat = hoursFormat;
    this.updateTimeChangeNextMode();
  }

  @Input() set houseModesSettings(houseModesSettings: IFlockHouseModeView) {
    if (!houseModesSettings) return;

    if (this.form) {
      this.updateFormValues(houseModesSettings);
      return;
    }

    this.initForm(houseModesSettings);
    this.updateTimeChangeNextMode();
    this.setMaxTargetTemperatureValidators(this.maxTargetTemperature);
  }

  AMPMOption$: Observable<AmPmEnum> = this.store.select(selectVirtualKeyboardAMPM);
  private _temperatureUnit: TemperatureUnitEnum;
  private _hoursFormat: HoursFormatTypeEnum;

  get temperatureUnit(): TemperatureUnitEnum {
    return this._temperatureUnit;
  }

  get hoursFormat(): HoursFormatTypeEnum {
    return this._hoursFormat;
  }

  @Input() set temperatureUnit(temperatureUnit: TemperatureUnitEnum) {
    if (temperatureUnit != null) {
      this._temperatureUnit = temperatureUnit;
      this.maxTargetTemperature = temperatureUnit === TemperatureUnitEnum.Celsius
        ? FlockConstants.MaxTargetTemperatureCelsius
        : FlockConstants.MaxTargetTemperatureFahrenheit;
      this.setMaxTargetTemperatureValidators(this.maxTargetTemperature);
    }
  }

  @Input() set ventilationWorkingMode(ventilationWorkingMode: VentilationWorkingModeEnum) {
    if (ventilationWorkingMode != null) {
      this.maxVentilation = ventilationWorkingMode === VentilationWorkingModeEnum.Basic
        ? FlockConstants.MaxVentilationBasic
        : FlockConstants.MaxVentilationAdvance;
      // TODO: Check correct ranges when they are defined by PO
      // this.setMaxVentilationValidators(this.maxVentilation);
    }
  }

  @Output() changed = new EventEmitter();

  form: FormGroup;
  sub$ = new Subscription();
  isKeyboardActive$: Observable<boolean> = this.store.select(selectVirtualKeyboardIsActive);
  focusedInput: { [key in focusedInput]: any } = {
    cleaning: null,
    catching: null,
    empty: null,
    preheat: null,
  };

  maxVentilation: number;
  maxTargetTemperature: number;
  DECIMAL_INPUT_ACCURACY: number = 1;
  MIN_VENTILATION: number = 1;

  KeyboardModeEnum = KeyboardModeEnum;
  LengthUnitEnum = LengthUnitEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  GlobalConstants = GlobalConstants;
  HoursFormatTypeEnum = HoursFormatTypeEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;
  VirtualKeyboardConstants = VirtualKeyboardConstants;
  FlockConstants = FlockConstants;

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          this.changed.emit({
            formValues,
            isValid: this.form.valid,
          });
        }),
    );
  }

  initForm(houseModesSettings: IFlockHouseModeView): void {
    this.form = new FormGroup(
      {
        timeChangeNextMode: new FormControl<string>(houseModesSettings.timeChangeNextMode as string, [Validators.required]),
        targetTemperatureEmpty: new FormControl<number>(houseModesSettings.targetTemperatureEmpty, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
        targetTemperaturePreheat: new FormControl<number>(houseModesSettings.targetTemperaturePreheat, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
        targetTemperatureCleaning: new FormControl<number>(houseModesSettings.targetTemperatureCleaning, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
        targetTemperatureCatching: new FormControl<number>(houseModesSettings.targetTemperatureCatching, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
        enableTemperatureAlarmEmpty: new FormControl<boolean>(houseModesSettings.enableTemperatureAlarmEmpty, [Validators.required]),
        enableTemperatureAlarmPreheat: new FormControl<boolean>(houseModesSettings.enableTemperatureAlarmPreheat, [Validators.required]),
        enableTemperatureAlarmCleaning: new FormControl<boolean>(houseModesSettings.enableTemperatureAlarmCleaning, [Validators.required]),
        enableTemperatureAlarmCatching: new FormControl<boolean>(houseModesSettings.enableTemperatureAlarmCatching, [Validators.required]),
        ventilationEmpty: new FormControl<IMinMax>(houseModesSettings.ventilationEmpty, [Validators.required]),
        ventilationPreheat: new FormControl<IMinMax>(houseModesSettings.ventilationPreheat, [Validators.required]),
        ventilationCatching: new FormControl<IMinMax>(houseModesSettings.ventilationCatching, [Validators.required]),
        ventilationCleaning: new FormControl<IMinMax>(houseModesSettings.ventilationCleaning, [Validators.required]),
      }, { validators: [RangesMaxMustBeGreaterThanMin.bind(this)] },
    );
  }

  async updateTimeChangeNextMode(): Promise<void> {
    const timeChangeNextMode = this.form?.controls?.['timeChangeNextMode'];
    const timeValue = timeChangeNextMode?.value;
    const hours = timeValue?.slice(0, 2);

    if (!hours) return;
    const AMPMOptionFromStore = await firstValueFrom(this.AMPMOption$);

    if (this.hoursFormat !== HoursFormatTypeEnum.HoursFormat24) {
      const AMPMOption = hours === '00' || +hours < 12
        ? AmPmEnum.AM
        : AmPmEnum.PM;

      this.store.dispatch(setVirtualKeyboardAMPM({ AMPM: AMPMOptionFromStore ?? AMPMOption }));
      timeChangeNextMode.setValue(TimeUtils.TwentyFourHoursFormatToAMPMTime(timeValue, AMPMOption));
    }

    this.form.updateValueAndValidity();
  }

  inputFocus(field: focusedInput, isFocused: boolean): void {
    this.focusedInput[field] = isFocused;
  }

  setMaxTargetTemperatureValidators(maxTargetTemperature: number): void {
    if (!this.form) return;

    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'targetTemperatureEmpty',
      'targetTemperaturePreheat',
      'targetTemperatureCleaning',
      'targetTemperatureCatching',
    ];

    controls.forEach(controlName => {
      this.form.controls[controlName].addValidators(Validators.max(maxTargetTemperature));
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  setMaxVentilationValidators(maxVentilation: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'maximumVentilationEmpty',
      'maximumVentilationPreheat',
      'maximumVentilationCleaning',
      'maximumVentilationCatching',
      'minimumVentilationEmpty',
      'minimumVentilationPreheat',
      'minimumVentilationCleaning',
      'minimumVentilationCatching',
    ];

    controls.forEach(controlName => {
      this.form.controls[controlName].addValidators(Validators.max(maxVentilation));
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  updateFormValues(houseModesSettings: IFlockHouseModeView): void {
    this.form.patchValue({
      ...houseModesSettings,
      timeChangeNextMode: String(houseModesSettings.timeChangeNextMode),
      ventilationCatching: houseModesSettings.ventilationCatching,
      ventilationCleaning: houseModesSettings.ventilationCleaning,
      ventilationEmpty: houseModesSettings.ventilationEmpty,
      ventilationPreheat: houseModesSettings.ventilationPreheat,
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
