import { createFeatureSelector, createSelector, MemoizedSelector } from '@ngrx/store';
import { FARMS_FEATURE_KEY, FarmsState } from './farms.reducer';
import { FarmUserRolesEnum } from '@livestock/shared/enums';
import { IUser } from '@livestock/shared/interfaces';

// Lookup the 'Farms' feature state managed by NgRx
export const selectFarmsState =
  createFeatureSelector<FarmsState>(FARMS_FEATURE_KEY);

export const selectIsLoading = createSelector(selectFarmsState,
  ({ isLoading }) => isLoading);

export const selectAreUsersLoading = createSelector(selectFarmsState,
  ({ areUsersLoading }) => areUsersLoading);

export const selectFarms = createSelector(selectFarmsState,
  ({ farms }) => farms);

export const selectFirstFarm = createSelector(selectFarms,
  (farms) => farms[0]);

export const selectActiveFarm = createSelector(selectFarmsState,
  (state) => state?.activeFarm);

export const selectActiveFarmName = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.name);

export const selectActiveFarmNameSliced = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.name && (activeFarm?.name.length > 25 ? activeFarm?.name.slice(0, 25) + '...' : activeFarm?.name));

export const selectActiveFarmID = createSelector(selectActiveFarm,
  (activeFarm) => activeFarm?.farmID);

export const selectExistingHouseNumbers = createSelector(
  selectFarms,
  selectActiveFarmID,
  (farms, farmID) => farms.find(farm => farm.farmID === farmID)?.existingHouseNumbers,
);

export const selectFarmUsersById = (farmID: number): MemoizedSelector<FarmsState, IUser[]> => createSelector(selectFarmsState,
  ({ users }) => users.filter(user => user.farmID === farmID));

export const selectFarmUserRoleByUserID = (userID: number | undefined): MemoizedSelector<FarmsState, FarmUserRolesEnum> => createSelector(selectFarmsState,
  ({ users, activeFarm }) => {
    return users.find(user => user.userID === userID && activeFarm?.farmID === user.farmID)?.accessesRole;
  });

export const selectTicketMessage = createSelector(selectFarmsState,
  ({ ticketMessage }) => ticketMessage);

export const selectControllers = createSelector(selectFarmsState,
  ({ controllers }) => {
    return controllers?.map((controller, index) => {
      // Will be use normal data in future
      return {
        ...controller,
        day: 4,
        temp: 28.4,
        humidity: 71,
        score: 5.1,
        name: `House ${controller.houseNumber}`,
        connected: index % 2 == 0,
      };
    });
  });

export const selectControllersExist = createSelector(
  selectControllers,
  (controllers) => controllers.length > 0,
);

export const selectFarmNameForInvitedUser = createSelector(selectFarmsState,
  ({ farmNameForInvitedUser }) => farmNameForInvitedUser);
