import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  ChangeDetectorRef,
  ViewChild,
  OnDestroy,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  FlockConstants,
  IFlockHouseModeView,
  MaxMustBeGreaterThanMin,
  VentilationWorkingModeEnum,
} from '@livestock/flock';
import { FormControl, FormGroup, Validators, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective, SwiperDirective } from '@livestock/shared/directives';
import {
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import { LanguageService, LocalStorageService, PlatformService, SwiperService } from '@livestock/shared/services';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { StorageItem, TemperatureUnitEnum } from '@livestock/shared/enums';
import { Subscription } from 'rxjs';

@Component({
  selector: 'ls-flock-house-mode-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SwiperModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    ToggleComponent,
    SvgIconComponent,
    SwiperDirective,
  ],
  templateUrl: './house-mode-form.component.html',
  styleUrls: ['./house-mode-form.component.scss'],
})
export class HouseModeFormComponent implements OnInit, OnDestroy {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @Input() isLoading: boolean;
  @Input() editMode: boolean;

  @Input() set houseMode(houseMode: IFlockHouseModeView) {
    if (houseMode) {
      this.updateFormValues(houseMode);
    }
  }

  @Input() set ventilationWorkingMode(ventilationWorkingMode: VentilationWorkingModeEnum) {
    if (ventilationWorkingMode != null) {
      this.maxVentilation = ventilationWorkingMode === VentilationWorkingModeEnum.Basic
        ? FlockConstants.MaxVentilationBasic
        : FlockConstants.MaxVentilationAdvance;
      this.setMaxVentilationValidators(this.maxVentilation);
    }
  }

  @Output() changed = new EventEmitter();

  sub$ = new Subscription();
  form = new FormGroup(
    {
      controllerID: new FormControl(),
      targetTemperatureEmpty: new FormControl(0, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
      targetTemperaturePreheat: new FormControl(0, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
      targetTemperatureCleaning: new FormControl(0, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
      targetTemperatureCatching: new FormControl(0, [Validators.required, Validators.min(FlockConstants.MinTargetTemperature)]),
      enableTemperatureAlarmEmpty: new FormControl(false, [Validators.required]),
      enableTemperatureAlarmPreheat: new FormControl(false, [Validators.required]),
      enableTemperatureAlarmCleaning: new FormControl(false, [Validators.required]),
      enableTemperatureAlarmCatching: new FormControl(false, [Validators.required]),
      maximumVentilationEmpty: new FormControl(0, [Validators.required, Validators.min(0)]),
      maximumVentilationPreheat: new FormControl(1, [Validators.required, Validators.min(0)]),
      maximumVentilationCleaning: new FormControl(0, [Validators.required, Validators.min(0)]),
      maximumVentilationCatching: new FormControl(10, [Validators.required, Validators.min(0)]),
      minimumVentilationEmpty: new FormControl(0, [Validators.required, Validators.min(0)]),
      minimumVentilationPreheat: new FormControl(1, [Validators.required, Validators.min(0)]),
      minimumVentilationCleaning: new FormControl(0, [Validators.required, Validators.min(0)]),
      minimumVentilationCatching: new FormControl(3, [Validators.required, Validators.min(0)]),
    }, { validators: [MaxMustBeGreaterThanMin.bind(this)] },
  );

  activeSlide = 0;
  maxTargetTemperature: number;
  maxVentilation: number;

  /*constants*/
  FlockConstants = FlockConstants;
  TemperatureUnitEnum = TemperatureUnitEnum;
  StorageItem = StorageItem;

  private _temperatureUnit: TemperatureUnitEnum;

  get temperatureUnit(): TemperatureUnitEnum {
    return this._temperatureUnit;
  }

  @Input() set temperatureUnit(temperatureUnit: TemperatureUnitEnum) {
    if (temperatureUnit != null) {
      this._temperatureUnit = temperatureUnit;
      this.maxTargetTemperature = temperatureUnit === TemperatureUnitEnum.Celsius
        ? FlockConstants.MaxTargetTemperatureCelsius
        : FlockConstants.MaxTargetTemperatureFahrenheit;
      this.setMaxTargetTemperatureValidators(this.maxTargetTemperature);
    }
  }

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    public cdr: ChangeDetectorRef,
    public swiperService: SwiperService,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: 0,
      centeredSlides: true,
    };
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.subscribe((formValues) => {
        this.changed.emit({
          formValues,
          isValid: this.form.valid,
        });
      }),
    );
  }

  updateFormValues(houseMode: IFlockHouseModeView): void {
    this.form.patchValue(houseMode);
  }

  setMaxTargetTemperatureValidators(maxTargetTemperature: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'targetTemperatureEmpty',
      'targetTemperaturePreheat',
      'targetTemperatureCleaning',
      'targetTemperatureCatching',
    ];

    controls.forEach(controlName => {
      this.form.controls[controlName].addValidators(Validators.max(maxTargetTemperature));
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  setMaxVentilationValidators(maxVentilation: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'maximumVentilationEmpty',
      'maximumVentilationPreheat',
      'maximumVentilationCleaning',
      'maximumVentilationCatching',
      'minimumVentilationEmpty',
      'minimumVentilationPreheat',
      'minimumVentilationCleaning',
      'minimumVentilationCatching',
    ];

    controls.forEach(controlName => {
      this.form.controls[controlName].addValidators(Validators.max(maxVentilation));
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  changeActiveSlide(ev): void {
    this.activeSlide = ev[0].realIndex;
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, this.activeSlide);
    this.cdr.detectChanges();
  }

  slidePrev(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  slideNext(): void {
    this.swiper.swiperRef.slideNext(100);
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
    this.sub$.unsubscribe();
  }
}
