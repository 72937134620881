import { Component, EventEmitter, Input, Output } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { ColorsEnum } from '@livestock/shared/enums';

// TO DO - Need to add common root

@Component({
  selector: 'ls-header-title',
  templateUrl: './header-title.component.html',
  styleUrls: ['./header-title.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    SvgIconComponent,
    TranslateModule,
  ],
})

export class HeaderTitleComponent {
  @Input() arrowColor = ColorsEnum.Default;
  @Input() title: string;
  @Input() titleClass: string = 'ph-30 ml-15 ml-0-md';
  @Input() qaTagsForArrow: string = 'qa-btn-return';
  @Input() qaTagsForTitle: string;
  @Input() additionalTitleValue: string | number;
  @Input() arrowFullHeight = true;
  @Input() hasArrow = true;
  @Input() translateNeeded = true;
  @Output() cancel = new EventEmitter<void>();

  emitCancel(): void {
    this.cancel.emit();
  }
}
