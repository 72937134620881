import { Component } from '@angular/core';
import {
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent,
  PageWrapperComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { StaticPressureFormComponent } from './form/static-pressure-form.component';
import { selectIsLoading } from '../+state/static-pressure.selectors';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { ColorsEnum, ElementTypesEnum } from '@livestock/shared/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { Router } from '@angular/router';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';

@Component({
  selector: 'ls-static-pressure',
  templateUrl: './static-pressure.component.html',
  styleUrls: ['./static-pressure.component.scss'],
  standalone: true,
  imports: [
    SvgIconComponent,
    CommonModule,
    KeyboardComponent,
    TranslateModule,
    StaticPressureComponent,
    StaticPressureFormComponent,
    LoadingComponent,
    PageWrapperComponent,
  ],
})
export class StaticPressureComponent implements IComponentCanDeactivate {
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  editMode: boolean;
  isDirtyForm: boolean;
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private menuService: MenuService,
    private router: Router,
    private dialogsService: DialogsService,
  ) {
  }

  goToSettings(): void {
    this.router.navigate([AppRoutes.INSTALLATION, 'elementType', ElementTypesEnum.StaticPressure, 'settings']);
  }

  async cancel(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  async toggleEditMode(): Promise<void> {
    if (this.editMode && this.isDirtyForm) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return;
      }
    }

    this.editMode = !this.editMode;
  }

  setIsDirtyForm(isDirtyForm: boolean): void {
    this.isDirtyForm = isDirtyForm;
  }

  canDeactivate(): boolean {
    return !this.isDirtyForm;
  }

  closeComponent(): void {
  }
}
