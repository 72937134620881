import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CoolingProgramState } from './cooling-program.state';
import { COOLING_FEATURE_KEY } from './cooling-program.reducer';

export const getCoolingProgramState = createFeatureSelector<CoolingProgramState>(
  COOLING_FEATURE_KEY,
);

export const selectCoolingProgramIsLoading = createSelector(
  getCoolingProgramState,
  ({ isLoading }) => isLoading,
);

export const selectCoolingPeriods = createSelector(
  getCoolingProgramState,
  ({ periods }) => periods,
);

export const selectCurrentCoolingPeriod = createSelector(
  getCoolingProgramState,
  ({ currentPeriod }) => currentPeriod,
);

export const selectCurrentCoolingProgram = createSelector(
  getCoolingProgramState,
  ({ currentProgram }) => currentProgram,
);

