import {
  KeyboardModeEnum,
  clearFormControlInputInfo,
  getKeyboardIconPath,
  selectCurrentKeyboardUUID,
  setElementUuid,
  setKeyboardMode,
} from '@livestock/ui';
import { Component, ElementRef, Input, OnDestroy, OnInit, QueryList, ViewChildren } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ColorsEnum } from '@livestock/shared/enums';
import { ActivatedRoute } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { KeyboardService } from '../../controls/keyboard/services/keyboard.service';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { SleepUtils } from '@livestock/shared/utils';
import { Subscription, firstValueFrom } from 'rxjs';
import { LanguageService } from '@livestock/shared/services';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-program-device-buttons',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
  ],
  templateUrl: './program-device-buttons.component.html',
  styleUrls: ['./program-device-buttons.component.scss'],
})
export class ProgramDeviceButtonsComponent implements OnInit, OnDestroy {
  @ViewChildren('button') buttons: QueryList<ElementRef>;

  private _controls: FormGroup;

  get controls(): FormGroup {
    return this._controls;
  }

  @Input() set controls(controls: FormGroup) {
    this._controls = controls;
  }

  @Input() storeCurrentControl: boolean = true;
  @Input() large: boolean = false;

  //constants
  ColorsEnum = ColorsEnum;

  //observables
  hasElement1$ = this.activatedRoute.data.pipe(map(({ elements }) => this.hasElements(elements[0], 1)));
  hasElement2$ = this.activatedRoute.data.pipe(map(({ elements }) => this.hasElements(elements[0], 2)));
  hasElement3$ = this.activatedRoute.data.pipe(map(({ elements }) => this.hasElements(elements[0], 3)));
  path$ = this.store.select(getKeyboardIconPath).pipe(wasChanged());
  sub$ = new Subscription();

  constructor(
    public activatedRoute: ActivatedRoute,
    public store: Store,
    public keyboardService: KeyboardService,
    public languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectCurrentKeyboardUUID).pipe(
        wasChanged(),
        map(uuid => this.buttons?.find(i => i.nativeElement.getAttribute('uuid') === uuid)),
        filter(element => !!element),
      ).subscribe((element) => {
        element.nativeElement.focus();
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  hasElements = (element, itemNumber): any => !!element?.items?.find(i => i.number === itemNumber);

  storeControl(e: Event): void {
    this.keyboardService.currentControl = this.controls;
    this.store.dispatch(setElementUuid({
      elementUuid: (e.target as HTMLElement).getAttribute('uuid'),
    }));
    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.Buttons }));
  }

  async clearControl(e: Event): Promise<void> {
    this.keyboardService.currentControl = null;
    await SleepUtils.sleep(100);
    const activeFormControl = await firstValueFrom(this.store.select(selectCurrentKeyboardUUID));
    if (activeFormControl === (e.target as HTMLElement).getAttribute('uuid')) {
      this.store.dispatch(clearFormControlInputInfo());
    }
  }
}
