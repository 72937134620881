import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, delay, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { ControllersService } from '../../services/controllers.service';
import * as upsertControllerActions from './upsert-controller.actions';
import * as upsertControllerSelectors from './upsert-controller.selectors';
import { IGeneralSettingsView } from '../../interfaces/general-settings-view.interface';
import { IDateTimeSettingsView } from '../../interfaces/date-time-settings-view.interface';
import { INetworkSettingsView } from '../../interfaces/network-settings-view.interface';
import { TicketControllersService } from '../../services/ticket-controller.service';
import { ICreateAddControllerTicketView } from '../../interfaces/create-add-controller-ticket-view.interface';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import {
  ICreateAddControllerTicketRequestView,
} from '../../interfaces/create-add-controller-ticket-request-view.interface';
import { IUpdateDateTimeSettingsView } from '../../interfaces/update-date-time-settings-view.interface';
import { FarmsService, IController } from '@livestock/farms';
import * as farmsSelectors from '../../../../../farms/src/lib/+state/farms.selectors';
import { IHouseSizesView } from '../../interfaces/house-sizes-view.interface';
import { selectActiveControllerID, selectControllerLanguage } from '../current-controller/current-controller.selectors';
import {
  selectDateTimeSettings,
  selectFlockSettingsAndWeights,
  selectGeneralSettings,
  selectHouseSizesSettings,
  selectNetworkSettings,
} from './upsert-controller.selectors';
import { ChickenBrandWeight, LengthUnitEnum, StorageItem } from '@livestock/shared/enums';
import { LocalStorageService } from '@livestock/shared/services';
import { IGeneralSettingsViewNoUnits } from '../../interfaces/general-settings-view-no-units.interface';
import {
  ICreateControllerByConnectionNumberView,
} from '../../interfaces/create-controller-by-connection-number-view.interface';
import { isTicketReconnect } from '@livestock/login-scanned';
import { selectVirtualKeyboardAMPM } from '@livestock/ui';
import { ControllerLanguageEnum, HoursFormatTypeEnum } from '@livestock/controllers/enums';
import { TimeUtils } from '@livestock/shared/utils';
import { DEFAULT_COBB_500, DEFAULT_ROSS_308, IGetFlockDefaultWeightView, selectFlockHouseMode } from '@livestock/flock';

@Injectable()
export class UpsertControllerEffects {

  getGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getGeneralSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.controllersService.getGeneralSettings(controllerID).pipe(
          map((generalSettings: IGeneralSettingsView) =>
            upsertControllerActions.getGeneralSettingsSuccess({ generalSettings })),
          catchError((error) => of(upsertControllerActions.getGeneralSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateGeneralSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateGeneralSettings),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectGeneralSettingsWithoutUnits),
      ),
      switchMap(([_, generalSettings]: [any, IGeneralSettingsViewNoUnits]) => {
        return this.controllersService.updateGeneralSettings(generalSettings.controllerID, generalSettings).pipe(
          switchMap(() => {
            return [
              upsertControllerActions.updateGeneralSettingsSuccess({ generalSettings }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.GeneralSettingsWereSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(upsertControllerActions.updateGeneralSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getDateTimeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getDateTimeSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.controllersService.getDateTimeSettings(controllerID).pipe(
          map((dateTimeSettings: IDateTimeSettingsView) =>
            upsertControllerActions.getDateTimeSettingsSuccess({ dateTimeSettings })),
          catchError((error) => of(upsertControllerActions.getDateTimeSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateDateTimeSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateDateTimeSettings),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectUpdateDateTimeSettings),
      ),
      switchMap(([_, dateTimeSettings]: [any, IUpdateDateTimeSettingsView]) => {
        return this.controllersService.updateDateTimeSettings(dateTimeSettings.controllerID, dateTimeSettings).pipe(
          switchMap(() => {
            return [
              upsertControllerActions.updateDateTimeSettingsSuccess({ dateTimeSettings }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.DateTimeSettingsWereSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) => of(upsertControllerActions.updateDateTimeSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getNetworkSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getNetworkSettings),
      map((action) => action.controllerID),
      switchMap((controllerID: number) => {
        return this.controllersService.getNetworkSettings(controllerID).pipe(
          map((networkSettings: INetworkSettingsView) =>
            upsertControllerActions.getNetworkSettingsSuccess({ networkSettings })),
          catchError((error) => of(upsertControllerActions.getNetworkSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateNetworkSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateNetworkSettings),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectNetworkSettings),
      ),
      switchMap(([_, networkSettings]: [any, INetworkSettingsView]) => {
        return this.controllersService.updateNetworkSettings(networkSettings.controllerID, networkSettings).pipe(
          switchMap(() => {
            return [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.NetworkSettingsWereSuccessfullyUpdated',
                },
              }),
              upsertControllerActions.updateNetworkSettingsSuccess(),
            ];
          }),
          catchError((error) => of(upsertControllerActions.updateNetworkSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  getHouseSizesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getHouseSizesSettings),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectLengthUnit),
      ),
      switchMap(([action, lengthUnit]) => {
        return this.controllersService.getHouseSizesSettings(action.controllerID, lengthUnit).pipe(
          map((houseSizesSettings: IHouseSizesView) =>
            upsertControllerActions.getHouseSizesSettingsSuccess({ houseSizesSettings })),
          catchError((error) => of(upsertControllerActions.getHouseSizesSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  updateHouseSizesSettings$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateHouseSizesSettings),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectHouseSizesSettings),
        this.store.select(upsertControllerSelectors.selectLengthUnit),
      ),
      switchMap(([_, houseSizesSettings, lengthUnit]: [any, IHouseSizesView, LengthUnitEnum]) => {
        return this.controllersService.updateHouseSizesSettings(houseSizesSettings.controllerID, houseSizesSettings, lengthUnit).pipe(
          switchMap(() => {
            return [
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.HouseSettingsWereSuccessfullyUpdated',
                },
              }),
              upsertControllerActions.updateHouseSizesSettingsSuccess(),
            ];
          }),
          catchError((error) => of(upsertControllerActions.updateHouseSizesSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  createTicketController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createTicketController),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectCreateAddControllerTicketRequestView),
      ),
      switchMap(([_, requestView]: [any, ICreateAddControllerTicketRequestView]) => {
        return this.ticketControllersService.createAddControllerTicket(requestView).pipe(
          map((ticketView: ICreateAddControllerTicketView) => upsertControllerActions.createTicketControllerSuccess({ ticketView })),
          catchError((error) => of(upsertControllerActions.createTicketControllerError({ payload: error }))),
        );
      }),
    ),
  );

  checkIfControllerIsAssignedWithFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.checkIfControllerIsAssignedWithFarm),
      withLatestFrom(
        this.store.select(upsertControllerSelectors.selectTicketView),
      ),
      switchMap(([_, ticketView]: [any, ICreateAddControllerTicketView]) => {
        return this.ticketControllersService.getAddControllerByTicketID(ticketView.ticketID).pipe(
          map(() => {
            return upsertControllerActions.setIsControllerAssignedWithFarm({
              isControllerAssignedWithFarm: false,
            });
          }),
          catchError(() => {
            return of(upsertControllerActions.setIsControllerAssignedWithFarm({
              isControllerAssignedWithFarm: true,
            }));
          }),
        );
      }),
    ),
  );

  createControllerByConnectionNumber$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createControllerByConnectionNumber),
      map(x => x.connectionInfo),
      withLatestFrom(
        this.store.select(farmsSelectors.selectActiveFarmID),
      ),
      switchMap(([connectionInfo, farmID]: [ICreateControllerByConnectionNumberView, number]) => {
        return this.farmsService.createControllerByConnectionNumber(farmID, connectionInfo).pipe(
          map((controller: IController) => {
            LocalStorageService.setStorageItem(StorageItem.CheckNewFlockControllerID, controller.controllerID);
            LocalStorageService.setStorageItem(StorageItem.NewControllerHouseNumber, controller.houseNumber);
            return upsertControllerActions.createControllerByConnectionNumberSuccess({ controller });
          }),
          catchError((error) => of(upsertControllerActions.createControllerByConnectionNumberError({ payload: error }))),
        );
      }),
    ),
  );

  executeTicketController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.executeTicketController),
      withLatestFrom(this.store.select(isTicketReconnect)),
      switchMap(([{ requestedView, ticketID }, isReconnect]) => {
        return this.ticketControllersService.executeAddControllerTicket(ticketID, requestedView, isReconnect).pipe(
          map(() => upsertControllerActions.executeTicketControllerSuccess()),
          catchError((error) => of(upsertControllerActions.executeTicketControllerError({ payload: error }))),
        );
      }),
    ),
  );

  createGeneralSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createGeneralSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectGeneralSettings),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, generalSettings, controllerID]) => {
        return this.controllersService.createGeneralSettingsViaDevice(controllerID, generalSettings).pipe(
          map(() => upsertControllerActions.createGeneralSettingsViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createGeneralSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createHouseSizesViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createHouseSizesViaDevice),
      withLatestFrom(
        this.store.select(selectHouseSizesSettings),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ currentUnit }, houseSizes, controllerID]) => {
        return this.controllersService.createHouseSizesViaDevice(controllerID, houseSizes, currentUnit).pipe(
          map(() => upsertControllerActions.createHouseSizesViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createHouseSizesViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createDateTimeSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createDateTimeSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectDateTimeSettings),
        this.store.select(selectActiveControllerID),
        this.store.select(selectVirtualKeyboardAMPM),
        this.store.select(selectControllerLanguage),
      ),
      switchMap(([action, dateTimeSettings, controllerID, AMPM, language]) => {
        const updatedDateTimeSettings = {
          ...dateTimeSettings,
          date: language === ControllerLanguageEnum.EngUS
            ? TimeUtils.usaDateFormatToUsualDateFormat(dateTimeSettings.date)
            : dateTimeSettings.date,
          time: dateTimeSettings.hoursFormat === HoursFormatTypeEnum.AmPm
            ? TimeUtils.AMPMTimeTo24hFormat(dateTimeSettings.time, AMPM)
            : dateTimeSettings.time,
        };

        return this.controllersService.createDateTimeSettingsViaDevice(controllerID, updatedDateTimeSettings).pipe(
          map(() => upsertControllerActions.createDateTimeSettingsViaDeviceSuccess({ isFinish: action?.isFinish })),
          catchError((error) => of(upsertControllerActions.createDateTimeSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createNetworkSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createNetworkSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectNetworkSettings),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, networkSettings, controllerID]) => {
        return this.controllersService.createNetworkSettingsViaDevice(controllerID, networkSettings).pipe(
          map(() => upsertControllerActions.createNetworkSettingsViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createNetworkSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  getLanSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getLanSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, controllerID]) => {
        return this.controllersService.getLanSettings(controllerID).pipe(
          map((settings) => upsertControllerActions.getLanSettingsSuccess(settings)),
          catchError((error) => of(upsertControllerActions.getLanSettingsError({ payload: error }))),
        );
      }),
    ));

  updateLanSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.updateLanSettings),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ lan: isActive }, controllerID]) => {
        return this.controllersService.updateLanSettings(controllerID, Boolean(isActive)).pipe(
          map((value) => upsertControllerActions.updateLanSettingsSuccess(value)),
          catchError((error) => of(upsertControllerActions.updateLanSettingsError({ payload: error }))),
        );
      }),
    ),
  );

  createFlockSettingsViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createFlockSettingsViaDevice),
      withLatestFrom(
        this.store.select(selectFlockSettingsAndWeights),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([_action, flockView, controllerID]) => {
        return this.controllersService.createFlockSettingsViaDevice(controllerID, flockView).pipe(
          map(() => upsertControllerActions.createFlockSettingsViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createFlockSettingsViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  createHouseModesViaDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.createHouseModesViaDevice),
      withLatestFrom(
        this.store.select(selectFlockHouseMode),
        this.store.select(selectActiveControllerID),
      ),
      switchMap(([{ currentTempUnit }, houseModes, controllerID]) => {
        return this.controllersService.createHouseModesViaDevice(controllerID, houseModes, currentTempUnit).pipe(
          map(() => upsertControllerActions.createHouseModesViaDeviceSuccess()),
          catchError((error) => of(upsertControllerActions.createHouseModesViaDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  getQuickstartStatus$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getQuickstartStatus),
      switchMap(() => {
        return this.controllersService.getQuickstartStatus().pipe(
          map((_status: string) => upsertControllerActions.getQuickstartStatusSuccess()),
          catchError((error) => of(upsertControllerActions.getQuickstartStatusError({ payload: error }))),
        );
      }),
    ),
  );

  setQuickstartStatusStep$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.setQuickstartStatusStep),
      switchMap(({ step }) => {
        return this.controllersService.setQuickstartStatusStep(step).pipe(
          map(() => upsertControllerActions.setQuickstartStatusStepSuccess()),
          catchError((error) => of(upsertControllerActions.setQuickstartStatusStepError({ payload: error }))),
        );
      }),
    ),
  );

  getControllerSerialNumberDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        upsertControllerActions.getControllerSerialNumberDevice,
        upsertControllerActions.createDateTimeSettingsViaDeviceSuccess,
      ),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap((_action, controllerID) => {
        return this.controllersService.getControllerSerialNumber(controllerID).pipe(
          map(({ serialNumber }: { serialNumber: string }) => {
            return upsertControllerActions.getControllerSerialNumberDeviceSuccess({
              serialNumber,
            });
          }),
          catchError((error) => of(upsertControllerActions.getControllerSerialNumberDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  getTicketInfoFromDevice$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getTicketInfoFromDevice),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      //imitate long loading TODO: remove later
      delay(1000),
      switchMap((_action, controllerID) => {
        return this.controllersService.getControllerTicketID(controllerID).pipe(
          map((ticketView: ICreateAddControllerTicketView) => {
            return upsertControllerActions.getTicketInfoFromDeviceSuccess({
              ticketView,
            });
          }),
          catchError((error) => of(upsertControllerActions.getControllerSerialNumberDeviceError({ payload: error }))),
        );
      }),
    ),
  );

  // flock weight reference tables
  getFlockWeightReferenceTable$ = createEffect(() =>
    this.actions$.pipe(
      ofType(upsertControllerActions.getFlockWeightReferenceTable),
      withLatestFrom(this.store.select(selectActiveControllerID)),
      switchMap(([{ brand, deviceLogic }, controllerID]) => {
        if (!deviceLogic) {
          if (brand === ChickenBrandWeight.COBB_500) {
            return of({
              items: DEFAULT_COBB_500,
            }).pipe(
              map((defaultWeights: IGetFlockDefaultWeightView) => {
                return upsertControllerActions.getFlockWeightReferenceTableSuccess({
                  defaultWeights,
                });
              }),
            );
          } else {
            return of({
              items: DEFAULT_ROSS_308,
            }).pipe(
              map((defaultWeights: IGetFlockDefaultWeightView) => {
                return upsertControllerActions.getFlockWeightReferenceTableSuccess({
                  defaultWeights,
                });
              }),
            );
          }
        }
        return this.controllersService.getFlockWeightReferenceTable(brand, controllerID).pipe(
          map((defaultWeights: IGetFlockDefaultWeightView) => {
            return upsertControllerActions.getFlockWeightReferenceTableSuccess({
              defaultWeights,
            });
          }),
          catchError((error) => of(upsertControllerActions.getFlockWeightReferenceTableError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private controllersService: ControllersService,
    private ticketControllersService: TicketControllersService,
    private farmsService: FarmsService,
  ) {
  }
}
