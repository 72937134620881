<div
  [class.rtl]="languageService.isRtl"
  class="popup d-flex flex-column justify-between">
  <div class="popup-top">
    <h3
      [qaTags]="'qa-lbl-popup-title'"
      class="popup-title fw-500 fz-20 fz-16-md mb-40 d-flex align-center color-secondary">
      <span>{{ "Farms.AddDeviceDialog.AddDevice" | translate }}</span>
    </h3>

    <div class="popup-body mb-45">
      <div
        [qaTags]="'qa-lbl-popup-message'"
        class="fz-20 fz-16-md color-secondary mb-40 color-tertiary popup-info text-center">
        {{
          (platformService.isMobileApp
            ? "Farms.AddDeviceDialog.AssignNewDeviceConnectionAndScan"
            : "Farms.AddDeviceDialog.AssignNewDeviceConnection"
          ) | translate
        }}
      </div>
      <div class="form-group mb-30">
        <label
          [qaTags]="'qa-txt-popup-field-prompt'"
          class="d-block mb-8 fz-16 color-secondary text-center color-tertiary">
          {{ "Farms.AddDeviceDialog.DeviceConnectionNumber" | translate }}
        </label>
        <ls-input
          [placeholder]="'000'"
          [textPosition]="'center'"
          [qaTags]="'qa-txt-device-box'"
          [(ngModel)]="connectionNumber"
          class="w-180 d-block m-auto"></ls-input>
        <span
          *ngIf="connectionNumber && !(connectionNumber | memoizeFunc : isInputValid)"
          class="error-field">
          {{ "Farms.AddDeviceDialog.PleaseEnterOnlyNumbers" | translate }}
        </span>
      </div>
    </div>

    <div
      *ngIf="platformService.isMd"
      class="scan">
      <div class="m-auto mt-35 mb-35 w-mc scan-or color-secondary">{{ "Farms.AddDeviceDialog.OR" | translate }}</div>
      <ls-button-with-icon
        (click)="openScanDialog()"
        [iconPath]="'scanned-login/scan'"
        [textContent]="'Farms.AddDeviceDialog.Scan'"
        buttonType="secondary"
        class="w-mc d-block m-auto"></ls-button-with-icon>
    </div>
  </div>

  <div class="popup-footer">
    <div class="d-flex align-center justify-center buttons">
      <ls-button
        (click)="close()"
        [qaTags]="'qa-btn-cancel'"
        [ngClass]="platformService.isMobileApp ? 'w-130' : 'w-120'"
        class="mr-15"
        type="secondary">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>

      <ls-button-with-icon
        (click)="add()"
        [ngClass]="platformService.isMobileApp ? 'w-130' : 'w-120'"
        [textContent]="'Buttons.Next'"
        [location]="'after'"
        [iconPath]="'button-icons/right-arrow-white'"
        [class.disabled]="!(connectionNumber | memoizeFunc : isInputValid)"
        [qaTags]="'qa-btn-next'">
      </ls-button-with-icon>
    </div>
  </div>
</div>
