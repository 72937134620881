import { Action, createReducer, on } from '@ngrx/store';
import * as loginScannedActions from './login-scanned.actions';
import { IGetAddControllerTicketView } from '../../../../controllers/src/lib/interfaces/get-add-controller-ticket-view';

export const LOGIN_SCANNED_FEATURE_KEY = 'loginScanned';

export interface LoginScannedState {
  ticketId: string | null;
  ticketView: IGetAddControllerTicketView | null;
  isReconnect?: boolean;
}

export const initialState: LoginScannedState = {
  ticketId: null,
  ticketView: null,
};

const reducer = createReducer(
  initialState,
  on(loginScannedActions.getAddControllerTicketView,
    (state, { ticketId, isReconnect }) => {
      return {
        ...state,
        ticketId,
        isReconnect,
      };
    },
  ),
  on(
    loginScannedActions.getAddControllerTicketViewSuccess,
    (state, { ticketView }) => {
      return {
        ...state,
        ticketView,
      };
    },
  ),
  on(
    loginScannedActions.getAddControllerTicketViewError,
    (state) => {
      return {
        ...state,
        ticketId: null,
        isReconnect: false,
      };
    },
  ),
);

export function loginScannedReducer(state: LoginScannedState | undefined, action: Action): any {
  return reducer(state, action);
}
