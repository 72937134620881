<div class="widget-wrapper widget-wrapper__padding">
  <h6
    [qaTags]="'qa-lbl-widget-title'"
    class="widget-title">
    Bird management
  </h6>

  <div class="widget-body d-flex">
    <div class="widget-icon mr-25 pl-10">
      <img
        [qaTags]="'qa-img-widget'"
        alt="bird"
        src="/assets/icons/dashboard/bird.svg" />
    </div>

    <div class="widget-content">
      <div class="d-flex justify-between bird-values">
        <div class="d-flex flex-column bird-values__block mb-5-mobile">
          <div
            [qaTags]="'qa-lbl-bird-total-birds-value'"
            class="widget-content__value">
            28,950
          </div>
          <span
            [qaTags]="'qa-lbl-bird-total-birds-lbl'"
            class="widget-content__subtitle"
            >Total birds</span
          >
        </div>

        <div class="d-flex flex-column bird-values__block mb-5-mobile">
          <div
            [qaTags]="'qa-lbl-bird-avg-weight-value'"
            class="widget-content__value">
            1.1<span
              [qaTags]="'qa-lbl-bird-avg-weight-units'"
              class="fz-16"
              >Lb</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-bird-avg-weight-lbl'"
            class="widget-content__subtitle"
            >Avg. weight</span
          >
        </div>

        <div class="d-flex flex-column bird-values__block">
          <div
            [qaTags]="'qa-lbl-bird-mortality-value'"
            class="widget-content__value">
            0.0<span
              [qaTags]="'qa-lbl-bird-mortality-units'"
              class="fz-16"
              >%</span
            >
          </div>
          <span
            [qaTags]="'qa-lbl-bird-mortality-lbl'"
            class="widget-content__subtitle"
            >Mortality</span
          >
        </div>

        <div class="d-flex flex-column bird-values__block">
          <div
            [qaTags]="'qa-lbl-bird-fcr-value'"
            class="widget-content__value">
            0
          </div>
          <span
            [qaTags]="'qa-lbl-bird-fcr-lbl'"
            class="widget-content__subtitle"
            >FCR</span
          >
        </div>
      </div>
    </div>
  </div>
</div>
