<section
  [class.gap-18]="!tableIsOpened"
  class="d-flex w-100-percent">
  <div [formGroup]="settingsForm">
    <!--flock number-->
    <div class="mb-24">
      <lv-input-integer-with-label
        [fullWidth]="true"
        [fontWeight]="500"
        [textAlign]="'center'"
        [min]="FlockSettingsConstants.FlockNumberMin"
        [max]="FlockSettingsConstants.FlockNumberMax"
        [label]="'Flock.FlockNumberHash'"
        class="field"
        formControlName="flockNumber"></lv-input-integer-with-label>
    </div>

    <!--empty house-->
    <div class="d-flex align-start gap-8 mb-24">
      <div class="ls-input position-relative field">
        <div class="input-wrapper">
          <lv-dual-toggle
            (change)="setGrowthDay()"
            [firstOption]="{
              title: 'Yes',
              value: true,
              theme: ThemeEnum.Main,
              toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
            }"
            [secondOption]="{
              title: 'No',
              value: false,
              theme: ThemeEnum.Dark,
              toggleIcon: IconsEnum.CROSS_SMALL_BLACK
            }"
            class="m-auto"
            formControlName="emptyHouse"></lv-dual-toggle>

          <label class="input-label input-label_filled">
            {{ "Flock.EmptyHouse" | translate }}
          </label>
        </div>
      </div>

      <div class="position-relative">
        <lv-input-integer-with-label
          (onFocusIn)="growthDayFocused = true"
          (onFocusOut)="growthDayFocused = false"
          [min]="FlockSettingsConstants.GrowthDayMin"
          [max]="FlockSettingsConstants.GrowthDayMax"
          [fullWidth]="true"
          [textAlign]="'center'"
          [label]="'Flock.GrowthDay'"
          [placeholder]="'--'"
          [class.pointer-events-none]="settingsForm.value.emptyHouse"
          [inputWrapperContentAlign]="'center'"
          class="field validate-untouched w-input-45"
          formControlName="growthDay"></lv-input-integer-with-label>
        <div *ngIf="settingsForm.controls.growthDay.invalid">
          <span class="input-error">
            {{ "Inputs.Range" | translate }} {{ FlockSettingsConstants.GrowthDayMin }} +{{
              FlockSettingsConstants.GrowthDayMax
            }}
          </span>
        </div>

        <ng-container *ngIf="settingsForm.controls.growthDay.value != null">
          <div
            (click)="setGrowthDay(settingsForm.controls.growthDay.value - 1)"
            class="decrement position-absolute">
            <ls-svg-icon
              [path]="IconsEnum.MINUS_IN_SQUARE"
              [color]="incrementDecrementIconsColor"></ls-svg-icon>
          </div>

          <div
            (click)="setGrowthDay(settingsForm.controls.growthDay.value + 1)"
            class="increment position-absolute">
            <ls-svg-icon
              [path]="IconsEnum.PLUS_IN_SQUARE"
              [color]="incrementDecrementIconsColor"></ls-svg-icon>
          </div>
        </ng-container>
      </div>
    </div>

    <!--number of birds-->
    <div
      [ngClass]="{
        'mb-4': maxNumberPerGenderMessageIsShown || maxNumberOfBirdsMessageIsShown,
        'mb-24': !maxNumberPerGenderMessageIsShown && !maxNumberOfBirdsMessageIsShown,
        'gap-8': !settingsForm.controls.separateMaleAndFemale.value,
        'gap-3': settingsForm.controls.separateMaleAndFemale.value
      }"
      class="d-flex align-center justify-between">
      <div
        [ngClass]="settingsForm.controls.separateMaleAndFemale.value === false ? 'field' : 'field-short'"
        class="ls-input position-relative">
        <div class="input-wrapper">
          <lv-dual-toggle
            [optionsTitlePosition]="settingsForm.controls.separateMaleAndFemale.value === false ? 'both' : 'before'"
            [firstOption]="{
              title: 'Yes',
              value: true,
              theme: ThemeEnum.Main,
              toggleIcon: IconsEnum.SMALL_CHECKMARK_BLUE
            }"
            [secondOption]="{
              title: 'No',
              value: false,
              theme: ThemeEnum.Dark,
              toggleIcon: IconsEnum.CROSS_SMALL_BLACK
            }"
            class="m-auto"
            formControlName="separateMaleAndFemale"></lv-dual-toggle>

          <label class="input-label input-label_filled">
            {{ "Flock.Separation" | translate }}
          </label>
        </div>
      </div>

      <ng-container *ngIf="settingsForm.controls.separateMaleAndFemale.value === false; else separateMaleFemale">
        <lv-input-integer-with-label
          (onFocusIn)="showMaxNumberOfBirdsMessage(true)"
          (onFocusOut)="showMaxNumberOfBirdsMessage(false)"
          [fullWidth]="true"
          [noWrapperPadding]="true"
          [min]="FlockSettingsConstants.InitialNumberOfBirdsMin"
          [max]="FlockSettingsConstants.InitialNumberOfBirdsMax"
          [label]="'Flock.NumberOfBirds'"
          class="field validate-untouched"
          formControlName="initialNumberOfBirds"></lv-input-integer-with-label>
      </ng-container>

      <ng-template #separateMaleFemale>
        <lv-input-integer-with-label
          (onFocusIn)="showMaxNumberPerGenderMessage(true)"
          (onFocusOut)="showMaxNumberPerGenderMessage(false)"
          [min]="FlockSettingsConstants.MaleInitialNumberOfBirdsMin"
          [max]="FlockSettingsConstants.MaleInitialNumberOfBirdsMax"
          [fullWidth]="true"
          [fontWeightDependingOnFocus]="true"
          [noPaddings]="true"
          [noWrapperPadding]="true"
          [labelIcon]="IconsEnum.MALE"
          [label]="'Flock.Male'"
          class="field-short position-relative validate-untouched"
          formControlName="maleInitialNumberOfBirds"></lv-input-integer-with-label>

        <lv-input-integer-with-label
          (onFocusIn)="showMaxNumberPerGenderMessage(true)"
          (onFocusOut)="showMaxNumberPerGenderMessage(false)"
          [min]="FlockSettingsConstants.FemaleInitialNumberOfBirdsMin"
          [max]="FlockSettingsConstants.FemaleInitialNumberOfBirdsMax"
          [fullWidth]="true"
          [fontWeightDependingOnFocus]="true"
          [noPaddings]="true"
          [noWrapperPadding]="true"
          [labelIcon]="IconsEnum.FEMALE"
          [label]="'Flock.Female'"
          class="field-short validate-untouched"
          formControlName="femaleInitialNumberOfBirds"></lv-input-integer-with-label>
      </ng-template>
    </div>

    <div
      *ngIf="maxNumberOfBirdsMessageIsShown"
      class="mb-24 fz-12">
      <span>{{ "Flock.MaximumNumberOfBirds" | translate }}</span>
    </div>

    <div
      *ngIf="maxNumberPerGenderMessageIsShown"
      class="mb-24 fz-12">
      <span>{{ "Flock.MaximumNumberOfBirdsPerGender" | translate }}</span>
    </div>

    <!--staging-->
    <div class="d-flex align-center gap-8 mb-24">
      <div class="ls-input position-relative field-full">
        <div class="input-wrapper">
          <lv-dual-toggle
            [firstOption]="{
              title: 'Flock.FullHouse',
              value: StagingEnum.FullHouse
            }"
            [secondOption]="{
              title: 'Flock.Brooding',
              value: StagingEnum.Brooding
            }"
            formControlName="staging"></lv-dual-toggle>

          <label class="input-label input-label_filled">
            {{ "Flock.Staging" | translate }}
          </label>
        </div>
      </div>
    </div>

    <!--reference-->
    <div class="d-flex align-start gap-8 mb-24">
      <div
        [ngClass]="{
          'ls-input__active': tableIsOpened,
          'field-reference': tableIsOpened,
          'field-reference__active': referenceTablePopupIsOpened
        }"
        class="ls-input field position-relative">
        <div class="input-wrapper">
          <label class="input-label input-label_filled">
            {{ "Flock.LoadReferenceTable" | translate }}
          </label>
        </div>

        <div #referenceTable>
          <div
            *ngIf="!referenceTablePopupIsOpened"
            (click)="setReferenceTablePopupIsOpened(true)"
            class="position-absolute w-100-percent d-flex justify-between align-center reference-dropdown">
            <span>{{ settingsForm.value.defaultWeightBrandID | enum : "chicken-brand-weight" | translate }}</span>
            <ls-svg-icon
              [color]="ColorsEnum.MonoDark"
              [path]="IconsEnum.ARROW_SELECT"></ls-svg-icon>
          </div>

          <div
            *ngIf="referenceTablePopupIsOpened"
            class="position-absolute w-100-percent reference-dropdown reference-dropdown__list">
            <div
              (click)="setReferenceTablePopupIsOpened(false)"
              class="d-flex justify-between align-center reference-dropdown__item">
              <span> {{ settingsForm.value.defaultWeightBrandID | enum : "chicken-brand-weight" | translate }}</span>
              <ls-svg-icon
                [color]="ColorsEnum.MainBlue"
                [path]="IconsEnum.ARROW_SELECT"
                class="rotate-180"></ls-svg-icon>
            </div>

            <ng-container *ngFor="let referenceTable of ChickenBrandWeight | enumToArray">
              <div
                *ngIf="referenceTable !== settingsForm.value.defaultWeightBrandID"
                (click)="setReferenceTable(referenceTable)"
                class="d-flex justify-between align-center reference-dropdown__item">
                <span>{{ referenceTable | enum : "chicken-brand-weight" | translate }}</span>
              </div>
            </ng-container>
          </div>
        </div>
      </div>

      <div
        (click)="showWeightTable()"
        [class.curve-offset]="tableIsOpened"
        [class.curve-offset__error]="tableIsOpened && settingsForm.controls.curveOffset.invalid"
        class="position-relative">
        <lv-input-integer-with-label
          (onFocusIn)="showCurveOffsetRange(true)"
          (onFocusOut)="recalculateWeight()"
          [fullWidth]="true"
          [noWrapperPadding]="true"
          [min]="FlockSettingsConstants.CurveOffsetMin"
          [max]="FlockSettingsConstants.CurveOffsetMax"
          [label]="'Flock.AdjustPercent'"
          [class.always-active]="tableIsOpened"
          class="field validate-untouched w-input-45"
          formControlName="curveOffset"></lv-input-integer-with-label>

        <div *ngIf="curveOffsetRangeIsShown || settingsForm.controls.curveOffset.invalid">
          <span [ngClass]="settingsForm.controls.curveOffset.invalid ? 'input-error' : 'input-description'">
            {{ "Inputs.Range" | translate }} {{ FlockSettingsConstants.CurveOffsetMin }} +{{
              FlockSettingsConstants.CurveOffsetMax
            }}
          </span>
        </div>

        <ls-svg-icon
          [path]="IconsEnum.EXPAND_IN_CIRCLE"
          [color]="curveOffsetArrowColor"
          class="curve-offset__arrow"></ls-svg-icon>
      </div>
    </div>

    <!--units-->
    <div class="d-flex align-center gap-8 pointer-events-none">
      <div class="ls-input position-relative field-full">
        <div class="input-wrapper">
          <lv-dual-toggle
            [firstOption]="{
              title: 'Units.Kilogram',
              value: WeightUnitEnum.Kilogram
            }"
            [secondOption]="{
              title: 'Units.Pound',
              value: WeightUnitEnum.Pound
            }"
            [disabled]="true"
            [notFormValue]="weightUnit"></lv-dual-toggle>

          <label class="input-label input-label_filled">
            {{ "Flock.Units" | translate }}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!---->
  <!---->
  <!---->
  <!---->
  <!--weight form-->
  <ng-container *ngIf="weightForm">
    <div *ngIf="tableIsOpened">
      <div
        [class.weight-table__wrapper-not-separated]="!settingsForm?.value.separateMaleAndFemale"
        class="weight-table__wrapper mb-9">
        <!--padding should be added if there are 9+ items in table or just 9 with some of them selected-->
        <div
          [class.weight-table__padding]="
            weightForm?.get('items')['controls']?.length > minRowsForScroll ||
            (weightForm?.get('items')['controls']?.length === minRowsForScroll &&
              (selectedDays.length > 0 || selectedRow != null))
          "
          class="weight-table d-flex flex-column">
          <div class="weight-table__header d-flex fw-600 fz-14">
            <div>
              <span>{{ "Flock.Day" | translate }}</span>
            </div>

            <ng-container *ngIf="settingsForm?.value.separateMaleAndFemale; else standardTableHeader">
              <div class="d-flex align-center">
                <ls-svg-icon [path]="IconsEnum.MALE_BIG"></ls-svg-icon>
                <span class="ml-3">{{ "Flock.MaleWeight" | translate }}</span>
              </div>
              <div>
                <span>{{ "Flock.MaleStandard" | translate }}</span>
              </div>
              <div class="d-flex align-center">
                <ls-svg-icon [path]="IconsEnum.FEMALE_BIG"></ls-svg-icon>
                <span class="ml-3">{{ "Flock.FemaleWeight" | translate }}</span>
              </div>
              <div>
                <span>{{ "Flock.FemaleStandard" | translate }}</span>
              </div>
            </ng-container>

            <ng-template #standardTableHeader>
              <div>
                <span class="ml-3">{{ "Flock.BirdWeight" | translate }}</span>
              </div>
              <div>
                <span>{{ "Flock.BirdStandard" | translate }}</span>
              </div>
            </ng-template>

            <div>
              <ls-svg-icon [path]="IconsEnum.DOUBLE_CHECKMARK"></ls-svg-icon>
            </div>
          </div>

          <div
            #weightTableValues
            [class.weight-table__values-empty]="weightForm?.get('items')['controls']?.length === 0"
            class="weight-table__values flex-grow-1 fw-500">
            <ng-container *ngIf="weightForm?.get('items')['controls']?.length > 0; else emptyTable">
              <ng-container *ngIf="weightForm?.get('items')['controls'] as weightFormControls">
                <div
                  *ngFor="let item of weightFormControls; index as index"
                  (click)="selectRow(index)"
                  [formGroup]="item"
                  [ngClass]="{
                    selected: selectedRow === index || selectedDays.includes(item.value.day),
                    'prev-day-selected':
                      selectedRow === index - 1 || selectedDays.includes(weightFormControls[index - 1]?.value.day)
                  }"
                  class="weight-table__row">
                  <div class="cell cell-day">
                    <span>{{ item.value.day }}</span>
                  </div>

                  <ng-container *ngIf="settingsForm?.value.separateMaleAndFemale; else standardTableValues">
                    <div
                      [class.cell-error]="item.controls.maleWeight.invalid"
                      class="cell">
                      <ls-input-decimal
                        [inputBgColor]="index % 2 === 0 ? ColorsEnum.White : ColorsEnum.MonoLight"
                        [inputWidthPx]="weightInputWidth"
                        [inputTextAlign]="'center'"
                        [noBorder]="true"
                        [accuracy]="3"
                        [min]="FlockSettingsConstants.WeightMin"
                        [max]="
                          weightUnit === WeightUnitEnum.Kilogram
                            ? FlockSettingsConstants.WeightMaxKg
                            : FlockSettingsConstants.WeightMaxPound
                        "
                        [labelForRanges]="VirtualKeyboardConstants.BirdWeightRangeLabel"
                        [showRangesOnKeyboard]="true"
                        [class.active-color]="selectedRow === index || selectedDays.includes(item.value.day)"
                        formControlName="maleWeight"
                        class="weight-table__field validate-untouched"></ls-input-decimal>
                    </div>
                    <div class="cell">
                      <span>{{ defaultWeights[item.value.day]?.maleWeight?.toFixed(3) }}</span>
                    </div>

                    <div
                      [class.cell-error]="item.controls.femaleWeight.invalid"
                      class="cell">
                      <ls-input-decimal
                        [inputBgColor]="index % 2 === 0 ? ColorsEnum.White : ColorsEnum.MonoLight"
                        [inputWidthPx]="weightInputWidth"
                        [inputTextAlign]="'center'"
                        [noBorder]="true"
                        [accuracy]="3"
                        [min]="FlockSettingsConstants.WeightMin"
                        [max]="
                          weightUnit === WeightUnitEnum.Kilogram
                            ? FlockSettingsConstants.WeightMaxKg
                            : FlockSettingsConstants.WeightMaxPound
                        "
                        [labelForRanges]="VirtualKeyboardConstants.BirdWeightRangeLabel"
                        [showRangesOnKeyboard]="true"
                        [class.active-color]="selectedRow === index || selectedDays.includes(item.value.day)"
                        formControlName="femaleWeight"
                        class="weight-table__field validate-untouched"></ls-input-decimal>
                    </div>
                    <div class="cell">
                      <span>{{ defaultWeights[item.value.day]?.femaleWeight?.toFixed(3) }}</span>
                    </div>
                  </ng-container>

                  <ng-template #standardTableValues>
                    <div
                      [class.cell-error]="item.controls.weight.invalid"
                      class="cell">
                      <ls-input-decimal
                        [inputBgColor]="index % 2 === 0 ? ColorsEnum.White : ColorsEnum.MonoLight"
                        [inputWidthPx]="weightInputWidth"
                        [inputTextAlign]="'center'"
                        [noBorder]="true"
                        [accuracy]="3"
                        [min]="FlockSettingsConstants.WeightMin"
                        [max]="
                          weightUnit === WeightUnitEnum.Kilogram
                            ? FlockSettingsConstants.WeightMaxKg
                            : FlockSettingsConstants.WeightMaxPound
                        "
                        [labelForRanges]="VirtualKeyboardConstants.BirdWeightRangeLabel"
                        [showRangesOnKeyboard]="true"
                        [class.active-color]="selectedRow === index || selectedDays.includes(item.value.day)"
                        formControlName="weight"
                        class="weight-table__field validate-untouched"></ls-input-decimal>
                    </div>

                    <div class="cell">
                      <span>{{ defaultWeights[item.value.day]?.weight?.toFixed(3) }}</span>
                    </div>
                  </ng-template>

                  <div class="cell cell-checkbox">
                    <mat-checkbox
                      (change)="checkDay($event, index, item.value.day)"
                      class="checkbox checkbox-active-color position-relative">
                    </mat-checkbox>
                  </div>

                  <!--show add day btn only it is the last day or there is a free day slot after-->
                  <div
                    *ngIf="
                      selectedRow === index &&
                      index <= FlockSettingsConstants.LastDay &&
                      (!weightForm?.get('items')['controls'][index + 1]?.value.day ||
                        weightForm?.get('items')['controls'][index + 1]?.value.day - item.value.day > 1)
                    "
                    (click)="addDay(index)"
                    class="add-day">
                    <ls-svg-icon [path]="IconsEnum.ADD_DAY"></ls-svg-icon>
                  </div>
                </div>
              </ng-container>
            </ng-container>

            <ng-template #emptyTable>
              <div class="d-flex position-relative d-flex h-100-percent">
                <div
                  (click)="addDay(0, true)"
                  class="empty-table__add position-absolute">
                  <ls-svg-icon [path]="IconsEnum.ADD_DAY"></ls-svg-icon>
                </div>

                <div class="empty-table d-flex justify-center flex-column align-center h-100-percent">
                  <h3 class="mb-18 fz-32 fw-600 line-height-115">
                    {{ "Flock.ItLooksLikeAllTheRowsHaveBeenDeleted" | translate }}
                  </h3>

                  <h5 class="mb-18 fz-24 fw-400 line-height-12">
                    {{ "Flock.PleaseLoadTheReferenceTableOrAddLinesByClickingOnPlus" | translate }}
                  </h5>

                  <lv-button
                    (click)="initWeightForm()"
                    [type]="ButtonTypeEnum.PRIMARY"
                    [iconPath]="IconsEnum.RIGHT_ARROW"
                    [iconPosition]="ButtonIconPositionEnum.RIGHT">
                    {{ "Flock.LoadReferenceTable" | translate }}
                  </lv-button>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
      </div>

      <div *ngIf="hasFollowingWeightError">
        <span class="fw-500 weight-error">
          {{ "Flock.TheInformationOnTheFollowingDayCannotBeLowerThanThatOfThePreviousDay" | translate }}
        </span>
      </div>
    </div>
  </ng-container>

  <div
    *ngIf="selectedDays.length > 0"
    (click)="showDeleteDaysPopup(true)"
    class="delete">
    <lv-button [type]="ButtonTypeEnum.ALERT">
      <ls-svg-icon [path]="IconsEnum.DELETE"></ls-svg-icon>
      <span>{{ "Buttons.Delete" | translate }}</span>
    </lv-button>
  </div>

  <div class="delete">
    <lv-flock-delete-day
      *ngIf="deleteDaysPopupIsShowed"
      (cancel)="showDeleteDaysPopup(false)"
      (deleteRows)="deleteDays()"
      [selectedDaysLength]="selectedDays?.length"></lv-flock-delete-day>
  </div>
</section>
