import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { IGetTempMapping } from '../interfaces/get-temp-mapping.interface';
import { ITempMappingItem } from '../interfaces/temp-mapping-item.interface';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class TempMappingApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getTemperatureMappings(controllerID: number): Observable<IGetTempMapping> {
    return this.http.get<IGetTempMapping>(`${baseUrl}/${controllerID}/temperature-mappings`);
  }

  updateTemperatureMappings(controllerID: number, view: ITempMappingItem[]): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/temperature-mappings`, view);
  }
}
