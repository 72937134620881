import { filter, first, Subscription } from 'rxjs';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Component, Input, OnInit, inject, OnDestroy, OnChanges, SimpleChanges } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { ElementConstants } from '../../constants/element.constants';
import { ElementOperationEnum } from 'libs/installation/src/lib/enums/element/element-operation.enum';
import { GlobalConstants } from '@livestock/shared/constants';
import { ElementWrapperHelper } from '../../helpers/element-wrapper.helper';
import { RequiredTrimValidator } from '@livestock/shared/validators';
import { selectCurrentConnectionSetup } from '@livestock/installation';
import { IGetOrUpdateElement } from '@livestock/installation/interfaces';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { keyboardUpdateFields } from '@livestock/ui';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-basic-element',
  template: '',
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class BasicElementComponent implements OnInit, OnDestroy, OnChanges {
  @Input({ required: true }) formGroupName: string;
  @Input() currentSetupNumber: number;

  sub$: Subscription = new Subscription();
  name = new FormControl<string>('');

  ElementConstants = ElementConstants;
  ElementOperationEnum = ElementOperationEnum;
  GlobalConstants = GlobalConstants;
  TemperatureUnitEnum = TemperatureUnitEnum;
  ElementsValidationConstants = ElementsValidationConstants;

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  constructor(
    protected store: Store,
    protected translation: TranslateService,
    protected parentFormContainer: ControlContainer,
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['formGroupName']) {
      this.name = new FormControl<string>(
        `${this.translation.instant(ElementWrapperHelper.childElementTitleByName(this.formGroupName))} ${this.currentSetupNumber}`,
        [Validators.required, Validators.maxLength(ElementConstants.MaxNameLength), RequiredTrimValidator],
      );
    }
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectCurrentConnectionSetup).pipe(
        filter(connectionSetup => connectionSetup && !connectionSetup.isNew),
        first(),
      ).subscribe(({ setupData }) => {
        this.patchValue(setupData);
        this.parentFormGroup.get(this.formGroupName).updateValueAndValidity();
        setTimeout(() => this.store.dispatch(keyboardUpdateFields()));
      }),
    );
  }

  patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
    });
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    this.sub$.unsubscribe();
  }
}
