<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Inlet</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body ph-15 ph-0-xl">
    <div class="widget-content">
      <table class="w-100-percent text-left fz-30">
        <thead class="color-tertiary text-center">
          <tr>
            <th
              [qaTags]="'qa-lbl-inlet-p-zone-num'"
              class="fz-16 fz-18-mobile first-column text-left">
              Zone
            </th>
            <th class="border-bottom border-right fz-24 fz-22-mobile">1</th>
            <th class="border-bottom border-right fz-24 fz-22-mobile">2</th>
            <th class="border-bottom fz-24 fz-22-mobile">3</th>
          </tr>
        </thead>

        <tbody class="text-center">
          <tr>
            <td>
              <div class="inlet-icon">
                <img
                  [qaTags]="'qa-img-inlet-p-windows'"
                  alt="inlet"
                  src="/assets/icons/dashboard/inlet-right.svg" />
              </div>
              <span
                [qaTags]="'qa-lbl-inlet-p-window'"
                class="color-tertiary fz-16 fz-18-mobile"
                >Wall</span
              >
            </td>
            <td class="border-right">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="11"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">11<span class="fz-24">%</span></div>
              </div>
            </td>

            <td class="border-right">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="22"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">22<span class="fz-24">%</span></div>
              </div>
            </td>

            <td>
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="22"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">22<span class="fz-24">%</span></div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="inlet-icon">
                <img
                  [qaTags]="'qa-img-inlet-p-tunnel'"
                  alt="inlet"
                  src="/assets/icons/dashboard/inlet-top.svg" />
              </div>
              <span
                [qaTags]="'qa-lbl-inlet-p-tunnel'"
                class="color-tertiary fz-16 fz-18-mobile"
                >Tunnel</span
              >
            </td>
            <td class="border-right color-disabled">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="0"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">00<span class="fz-24">%</span></div>
              </div>
            </td>
            <td class="border-right">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="9"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">09<span class="fz-24">%</span></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="9"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">09<span class="fz-24">%</span></div>
              </div>
            </td>
          </tr>

          <tr>
            <td>
              <div class="inlet-icon">
                <img
                  [qaTags]="'qa-img-inlet-p-atic'"
                  alt="inlet"
                  src="/assets/icons/dashboard/inlet-bottom.svg" />
              </div>
              <span
                [qaTags]="'qa-lbl-inlet-p-atic'"
                class="color-tertiary fz-16 fz-18-mobile"
                >Attic</span
              >
            </td>
            <td class="border-right color-disabled">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="0"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">00<span class="fz-24">%</span></div>
              </div>
            </td>
            <td class="border-right color-disabled">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="0"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">00<span class="fz-24">%</span></div>
              </div>
            </td>
            <td class="color-disabled">
              <div class="d-flex align-end align-center-mobile justify-center">
                <div class="bar position-relative">
                  <div
                    [style.height.%]="0"
                    class="bar-inner position-absolute"></div>
                </div>
                <div class="inlet-value fz-32 fz-24-xl fz-22-mobile">00<span class="fz-24">%</span></div>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
