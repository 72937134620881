import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ColorsEnum } from '@livestock/shared/enums';
import { IGetOrUpdateElement } from 'libs/installation/src/lib/interfaces/element/get-or-update-element.interface';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { CommonModule } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { CopyDialogComponent } from './copy-dialog/copy-dialog.component';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'ls-copy-button',
  templateUrl: './copy-button.component.html',
  styleUrls: ['./copy-button.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    SvgIconComponent,
    CommonModule,
  ],
})
export class CopyButtonComponent implements OnDestroy {
  @Input() elements: IGetOrUpdateElement[] = [];
  @Output() copy: EventEmitter<IGetOrUpdateElement> = new EventEmitter();
  ColorsEnum = ColorsEnum;

  sub$: Subscription = new Subscription();

  constructor(
    private dialog: MatDialog,
  ) { }

  openPopup(): void {
    const dialog = this.dialog.open(CopyDialogComponent, {
      disableClose: true,
      data: {
        elements: this.elements,
      },
    });

    this.sub$.add(
      dialog.afterClosed().pipe(filter(val => !!val)).subscribe(data => {
        this.copy.emit(data);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
