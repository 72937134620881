import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { ColorsEnum } from '@livestock/shared/enums';
import { DialogsService, LanguageService, PlatformService } from '@livestock/shared/services';
import {
  ButtonComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  PageWrapperComponent,
  SelectDirective,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { NgSelectModule } from '@ng-select/ng-select';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter } from 'rxjs';
import {
  CalibrationModeEnum,
  IBasicVentilationSettings,
  getBasicVentilationSettings,
  updateBasicVentilationSettings,
  selectBasicVentilationSettings,
  selectIsLoadingSettings, BasicVentilationConstants,
} from '@livestock/basic-ventilation-minimum';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';

@Component({
  selector: 'ls-ventilation-minimum-settings',
  templateUrl: './ventilation-minimum-settings.component.html',
  styleUrls: ['./ventilation-minimum-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    InputIntegerComponent,
    KeyboardComponent,
    ToggleComponent,
    QaTagsDirective,
    SelectDirective,
    NgSelectModule,
    EnumPipe,
    EnumToArrayPipe,
    ErrorFieldDirective,
    PageWrapperComponent,
  ],
})
export class VentilationMinimumSettingsComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  editMode: boolean;
  KeyboardModeEnum = KeyboardModeEnum;
  BasicVentilationConstants = BasicVentilationConstants;
  CalibrationModeEnum = CalibrationModeEnum;
  ColorsEnum = ColorsEnum;
  form: FormGroup;
  controllerID: number;
  isLoading$: Observable<boolean>;
  sub$: Subscription = new Subscription();
  settings: IBasicVentilationSettings;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
    private router: Router,
    private store: Store,
    private route: ActivatedRoute,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.route.params.pipe(filter(val => !!val['controllerID'])).subscribe(val => {
        this.controllerID = +val['controllerID'];
        this.store.dispatch(getBasicVentilationSettings({ controllerID: this.controllerID }));
      }),
    );
    this.isLoading$ = this.store.select(selectIsLoadingSettings);
    this.sub$.add(
      this.store.select(selectBasicVentilationSettings).pipe(filter(val => !!val)).subscribe(val => {
        this.initForm(val);
        this.settings = val;
      }),
    );
  }

  initForm(val: IBasicVentilationSettings): void {
    this.form = new FormGroup({
      controllerID: new FormControl(val.controllerID),
      // Minimum ventilation
      isWorkingWithCurve: new FormControl(val.isWorkingWithCurve),
      isAllowingRotation: new FormControl(val.isAllowingRotation),
      inRotationFansNumber: new FormControl(val.inRotationFansNumber, [
        Validators.min(BasicVentilationConstants.minNumberOfFans),
        Validators.max(BasicVentilationConstants.maxNumberOfFans),
      ]),
      // Tunnel ventilation
      movingToTunnelsFansNumber: new FormControl(val.movingToTunnelsFansNumber),
      minTimeInTunnel: new FormControl(val.minTimeInTunnel),
      // Inlet / Tunnel door calibration
      calibration: new FormControl(val.calibration),
      stepsNumber: new FormControl(val.stepsNumber),
      // custom additional
      start: new FormControl(val.start),
      end: new FormControl(val.end),
    });

    this.sub$.add(
      this.form.controls['isAllowingRotation'].valueChanges.subscribe(val => {
        if (!val) {
          this.form.controls['inRotationFansNumber'].reset(); // null
        }
      }),
    );

    this.sub$.add(
      this.form.controls['calibration'].valueChanges.subscribe(val => {
        if (val) {
          this.form.controls['start'].reset(); // null
          this.form.controls['end'].reset(); // null
        }
      }),
    );
  }

  backRedirect(): void {
    const url = this.router.url;
    this.router.navigate([`${url.slice(0, url.lastIndexOf('/'))}`]);
  }

  async cancel(): Promise<void> {
    if (this.haveAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (result) {
        this.editMode = false;
        this.form.patchValue(this.settings, { emitEvent: false });
      }
    } else {
      this.editMode = false;
    }
  }

  save(): void {
    this.editMode = false;
    if (this.form.valid) {
      this.settings = this.form.getRawValue();
      this.store.dispatch(updateBasicVentilationSettings({ controllerID: this.controllerID, settings: this.settings }));
    }
  }

  haveAnyChanges(): boolean {
    return JSON.stringify(this.settings, Object.keys(this.settings).sort()) !== JSON.stringify(this.form.getRawValue(), Object.keys(this.form.getRawValue()).sort());
  }

  canDeactivate(): boolean {
    return !this.haveAnyChanges();
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
