import { createFeatureSelector, createSelector } from '@ngrx/store';
import { LightingProgramState } from './lighting-program.state';
import { LIGHTING_FEATURE_KEY } from './lighting-program.reducer';
import { ElementTypesEnum } from '@livestock/shared/enums';

export const getLightingProgramState = createFeatureSelector<LightingProgramState>(
  LIGHTING_FEATURE_KEY,
);

export const selectLightingProgramIsLoading = createSelector(
  getLightingProgramState,
  ({ isLoading }) => isLoading,
);

export const selectLightingPeriods = createSelector(
  getLightingProgramState,
  ({ periods }) => periods,
);

export const selectCurrentLightingPeriod = createSelector(
  getLightingProgramState,
  ({ currentPeriod }) => currentPeriod,
);

export const selectCurrentLightingProgram = createSelector(
  getLightingProgramState,
  ({ currentProgram }) => currentProgram,
);

export const selectAllLightingPeriodElements = createSelector(
  getLightingProgramState,
  ({ lightAOElements, lightDOElements }) => ([
    ...lightAOElements.map(i => ({ ...i, type: ElementTypesEnum.LightAO })),
    ...lightDOElements.map(i => ({ ...i, type: ElementTypesEnum.LightDO })),
  ].sort((a, b) => a.number - b.number)),
);

