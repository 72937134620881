import { ControlContainer, FormControl, FormGroup } from '@angular/forms';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';

import { TranslateService } from '@ngx-translate/core';
import { LightingInputType } from 'libs/installation/src/lib/enums/element/lighting/lighting-input-type-enum';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';

@Component({
  selector: 'ls-lighting-sensor-form',
  templateUrl: './lighting-sensor-form.component.html',
  styleUrls: ['../devices-common.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class LightingSensorComponent extends BasicElementComponent {
  LightingInputType = LightingInputType;

  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.parentFormGroup.addControl(
      this.formGroupName,
      new FormGroup({
        name: this.name,
        inputType: new FormControl<LightingInputType>(LightingInputType.Volts),
        enabled: new FormControl<boolean>(true),
      }),
    );
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      inputType: setupData.inputType,
      enabled: setupData.enabled,
    });
  }
}
