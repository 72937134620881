<div class="d-flex align-end h-100-percent">
  <div class="content content-form h-100-percent min-height-0 d-flex flex-column mr-20 w-100-percent">
    <ng-container *ngIf="!isLoading; else loading">
      <div class="h-100-percent mb-15 content-info min-height-0 overflow-hidden d-flex flex-column">
        <h6 class="color-secondary mb-15">{{ "TemperatureMapping.Stage" | translate }}</h6>

        <div class="stages-wrapper d-flex justify-between h-100-percent min-height-0">
          <div class="stages d-flex flex-column mr-25">
            <ls-button
              (click)="changeActiveStage(TempMappingStagesEnum.FullHouse)"
              [paddingClass]="'ph-3'"
              [qaTags]="'qa-btn-full-house'"
              [type]="activeStage === TempMappingStagesEnum.FullHouse ? 'primary' : 'secondary'"
              class="mb-10 w-120">
              {{ "TemperatureMapping.FullHouse" | translate }}
            </ls-button>

            <ls-button
              (click)="changeActiveStage(TempMappingStagesEnum.Brooding1)"
              [paddingClass]="'ph-3'"
              [qaTags]="'qa-btn-brooding-1'"
              [type]="activeStage === TempMappingStagesEnum.Brooding1 ? 'primary' : 'secondary'"
              class="mb-10 w-120">
              {{ "TemperatureMapping.Brooding1" | translate }}
            </ls-button>

            <ls-button
              *ngIf="brooding2IsShown"
              (click)="changeActiveStage(TempMappingStagesEnum.Brooding2)"
              [paddingClass]="'ph-3'"
              [qaTags]="'qa-btn-brooding-2'"
              [type]="activeStage === TempMappingStagesEnum.Brooding2 ? 'primary' : 'secondary'"
              class="mb-10 w-120">
              {{ "TemperatureMapping.Brooding2" | translate }}
            </ls-button>

            <ls-button
              (click)="changeActiveStage(TempMappingStagesEnum.Generals)"
              [paddingClass]="'ph-3'"
              [qaTags]="'qa-btn-general'"
              [type]="activeStage === TempMappingStagesEnum.Generals ? 'primary' : 'secondary'"
              class="w-120">
              {{ "TemperatureMapping.General" | translate }}
            </ls-button>
          </div>

          <ng-container [ngSwitch]="activeStage">
            <div class="table w-100-percent w-1-30 w-2-10 w-3-30 w-4-30">
              <div class="table-row table-header padding">
                <div class="table-cell">{{ "TemperatureMapping.Device" | translate }}</div>
                <div class="table-cell">{{ "TemperatureMapping.Average" | translate }}</div>
                <div class="table-cell">{{ "TemperatureMapping.TemperatureSensors" | translate }}</div>
                <div class="table-cell">{{ "TemperatureMapping.Heaters" | translate }}</div>
              </div>

              <!--FULL HOUSE-->
              <ng-container *ngSwitchCase="TempMappingStagesEnum.FullHouse">
                <div
                  *ngIf="fullHouse"
                  [class.selected]="selectedRowIndex === 0"
                  class="table-row">
                  <div class="table-cell gray"></div>
                  <div class="table-cell align-center padding-top-10">
                    <mat-checkbox
                      (change)="changeIsAverage($event, 0)"
                      [checked]="fullHouse.average"
                      [qaTags]="'qa-chk-average'"
                      class="checkbox checkbox-active-color"></mat-checkbox>
                  </div>
                  <div
                    (click)="selectRow()"
                    [class.active-cell]="selectedRowIndex === 0"
                    class="table-cell justify-start align-center padding-top-10 flex-wrap">
                    <ng-container
                      *ngIf="fullHouse.average; else sensors"
                      [ngTemplateOutlet]="averageSensors"></ng-container>

                    <ng-template #sensors>
                      <div
                        *ngFor="let sensor of fullHouse.sensors; index as index; trackBy: trackBy"
                        class="sensor">
                        <ls-svg-icon
                          [color]="ColorsEnum.BlueDefault"
                          [fullHeight]="true"
                          [path]="'heating/temp'"
                          class="mb-5"></ls-svg-icon>
                        <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                      </div>
                    </ng-template>
                  </div>
                  <div class="table-cell gray"></div>
                </div>
              </ng-container>

              <!--Brooding 1-->
              <ng-container
                *ngIf="activeStage === TempMappingStagesEnum.Brooding1"
                [ngTemplateOutlet]="brooding1HasMappings ? brooding1Mappings : emptyRow">
                <ng-template #brooding1Mappings>
                  <ng-container *ngIf="brooding1HeatingZones.length > 0">
                    <div
                      *ngFor="let brooding1heatingZone of brooding1HeatingZones; index as index; trackBy: trackBy"
                      (click)="selectRow(null, brooding1heatingZone.zoneID, index)"
                      [class.selected]="selectedRowIndex === index"
                      class="table-row pointer">
                      <div class="table-cell gray">{{ brooding1heatingZone.name }}</div>
                      <div
                        [class.gray]="!brooding1heatingZone.isAverage"
                        class="table-cell align-center padding-top-10">
                        <mat-checkbox
                          *ngIf="brooding1heatingZone.isAverage"
                          checked
                          class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                      </div>
                      <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                        <ng-container
                          *ngIf="brooding1heatingZone.isAverage; else sensors"
                          [ngTemplateOutlet]="averageSensors">
                        </ng-container>

                        <ng-template #sensors>
                          <div
                            *ngFor="let sensor of brooding1heatingZone.sensorElements; trackBy: trackBy"
                            class="sensor">
                            <ls-svg-icon
                              [color]="ColorsEnum.BlueDefault"
                              [fullHeight]="true"
                              [path]="'heating/temp'"
                              class="mb-5"></ls-svg-icon>
                            <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                          </div>
                        </ng-template>
                      </div>
                      <div class="table-cell justify-start padding-top-10 flex-wrap">
                        <div
                          *ngFor="let heater of brooding1heatingZone.heatingElements; trackBy: trackBy"
                          class="heater">
                          <ls-svg-icon
                            [color]="ColorsEnum.BlueDefault"
                            [fullHeight]="true"
                            [overlayPath]="heater.elementType === ElementTypesEnum.HeatingDO ? 'warning' : null"
                            [path]="'heating/heat'"
                            class="mb-5"></ls-svg-icon>
                          <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="brooding1ElementMappings.length > 0">
                    <div
                      *ngFor="let brooding1ElementMapping of brooding1ElementMappings; index as index; trackBy: trackBy"
                      [class.selected]="selectedRowIndex === index + brooding1HeatingZones.length"
                      class="table-row">
                      <div class="table-cell gray">
                        {{ brooding1ElementMapping.elementName }}
                      </div>
                      <div class="table-cell align-center padding-top-10">
                        <mat-checkbox
                          (change)="
                            changeIsAverage(
                              $event,
                              index + brooding1HeatingZones.length,
                              brooding1ElementMapping.elementID
                            )
                          "
                          [checked]="brooding1ElementMapping.average"
                          class="checkbox checkbox-active-color"></mat-checkbox>
                      </div>

                      <div
                        (click)="
                          selectRow(brooding1ElementMapping.elementID, null, index + brooding1HeatingZones.length)
                        "
                        [class.active-cell]="selectedRowIndex === index + brooding1HeatingZones.length"
                        class="table-cell align-center justify-start padding-top-10 flex-wrap">
                        <ng-container
                          *ngIf="brooding1ElementMapping.average; else elementSensors"
                          [ngTemplateOutlet]="averageSensors">
                        </ng-container>

                        <ng-template #elementSensors>
                          <div
                            *ngFor="let sensor of brooding1ElementMapping.sensors; trackBy: trackBy"
                            class="sensor">
                            <ls-svg-icon
                              [color]="ColorsEnum.BlueDefault"
                              [fullHeight]="true"
                              [path]="'heating/temp'"
                              class="mb-5"></ls-svg-icon>
                            <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                          </div>
                        </ng-template>
                      </div>

                      <div class="table-cell justify-start padding-top-10 gray"></div>
                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>

              <!--Brooding 2-->
              <ng-container
                *ngSwitchCase="TempMappingStagesEnum.Brooding2"
                [ngTemplateOutlet]="brooding2HasMappings ? brooding2Mappings : emptyRow">
                <ng-template #brooding2Mappings>
                  <ng-container *ngIf="brooding2HeatingZones.length > 0">
                    <div
                      *ngFor="let brooding2heatingZone of brooding2HeatingZones; index as index; trackBy: trackBy"
                      (click)="selectRow(null, brooding2heatingZone.zoneID, index)"
                      [class.selected]="selectedRowIndex === index"
                      class="table-row pointer">
                      <div class="table-cell gray">{{ brooding2heatingZone.name }}</div>
                      <div
                        [class.gray]="!brooding2heatingZone.isAverage"
                        class="table-cell align-center padding-top-10">
                        <mat-checkbox
                          *ngIf="brooding2heatingZone.isAverage"
                          checked
                          class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                      </div>
                      <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                        <ng-container
                          *ngIf="brooding2heatingZone.isAverage; else sensors"
                          [ngTemplateOutlet]="averageSensors">
                        </ng-container>

                        <ng-template #sensors>
                          <div
                            *ngFor="let sensor of brooding2heatingZone.sensorElements; trackBy: trackBy"
                            class="sensor">
                            <ls-svg-icon
                              [color]="ColorsEnum.BlueDefault"
                              [fullHeight]="true"
                              [path]="'heating/temp'"
                              class="mb-5"></ls-svg-icon>
                            <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                          </div>
                        </ng-template>
                      </div>
                      <div class="table-cell justify-start padding-top-10 flex-wrap">
                        <div
                          *ngFor="let heater of brooding2heatingZone.heatingElements; trackBy: trackBy"
                          class="heater">
                          <ls-svg-icon
                            [color]="ColorsEnum.BlueDefault"
                            [fullHeight]="true"
                            [overlayPath]="heater.elementType === ElementTypesEnum.HeatingDO ? 'warning' : null"
                            [path]="'heating/heat'"
                            class="mb-5"></ls-svg-icon>
                          <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                        </div>
                      </div>
                    </div>
                  </ng-container>

                  <ng-container *ngIf="brooding2ElementMappings.length > 0">
                    <div
                      *ngFor="let brooding2ElementMapping of brooding2ElementMappings; index as index; trackBy: trackBy"
                      [class.selected]="selectedRowIndex === index + brooding2HeatingZones.length"
                      class="table-row">
                      <div class="table-cell gray">
                        {{ brooding2ElementMapping.elementName }}
                      </div>
                      <div class="table-cell align-center padding-top-10">
                        <mat-checkbox
                          (change)="
                            changeIsAverage(
                              $event,
                              index + brooding2HeatingZones.length,
                              brooding2ElementMapping.elementID
                            )
                          "
                          [checked]="brooding2ElementMapping.average"
                          class="checkbox checkbox-active-color"></mat-checkbox>
                      </div>

                      <div
                        (click)="
                          selectRow(brooding2ElementMapping.elementID, null, index + brooding2HeatingZones.length)
                        "
                        [class.active-cell]="selectedRowIndex === index + brooding2HeatingZones.length"
                        class="table-cell align-center justify-start padding-top-10 flex-wrap">
                        <ng-container
                          *ngIf="brooding2ElementMapping.average; else elementSensors"
                          [ngTemplateOutlet]="averageSensors">
                        </ng-container>

                        <ng-template #elementSensors>
                          <div
                            *ngFor="let sensor of brooding2ElementMapping.sensors; trackBy: trackBy"
                            class="sensor">
                            <ls-svg-icon
                              [color]="ColorsEnum.BlueDefault"
                              [fullHeight]="true"
                              [path]="'heating/temp'"
                              class="mb-5"></ls-svg-icon>
                            <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                          </div>
                        </ng-template>
                      </div>

                      <div class="table-cell justify-start padding-top-10 gray"></div>
                    </div>
                  </ng-container>
                </ng-template>
              </ng-container>

              <!--GENERALS-->
              <ng-container *ngSwitchCase="TempMappingStagesEnum.Generals">
                <div class="table-row">
                  <div class="table-cell gray">
                    {{ "TemperatureMapping.Inlet" | translate }}
                  </div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                </div>
                <div class="table-row">
                  <div class="table-cell gray">
                    {{ "TemperatureMapping.StirFan" | translate }}
                  </div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                </div>
                <div class="table-row">
                  <div class="table-cell gray">
                    {{ "TemperatureMapping.TunnelDoor" | translate }}
                  </div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                  <div class="table-cell gray"></div>
                </div>
              </ng-container>
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>

    <div class="buttons d-flex justify-between align-center">
      <div class="d-flex align-center">
        <ls-svg-icon
          (click)="addBrooding2Stage()"
          [class.disabled]="brooding2IsShown || isLoading"
          [class]="brooding2IsShown || isLoading ? 'color-disabled' : 'color-green'"
          [path]="'common/add'"
          class="mr-20 pointer">
        </ls-svg-icon>

        <ls-svg-icon
          (click)="removeBrooding2Stage()"
          [class.disabled]="activeStage !== TempMappingStagesEnum.Brooding2 || isLoading"
          [class]="activeStage !== TempMappingStagesEnum.Brooding2 || isLoading ? 'color-disabled' : 'color-green'"
          [path]="'common/delete'"
          class="mr-20 pointer">
        </ls-svg-icon>

        <ls-button
          *ngIf="
            brooding2IsShown && [TempMappingStagesEnum.Brooding1, TempMappingStagesEnum.Brooding2].includes(activeStage)
          "
          (click)="moveElement()"
          [class.disabled]="(activeElementID == null && activeZoneID == null) || isLoading"
          type="secondary">
          {{ "Buttons.Move" | translate }}
        </ls-button>
      </div>

      <div class="d-flex align-center">
        <ls-button
          (click)="closeEditModeEmit()"
          [class.disabled]="isLoading"
          [qaTags]="'qa-btn-cancel'"
          class="mr-20 w-120"
          type="secondary">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>

        <ls-button
          (click)="save()"
          [class.disabled]="isLoading || !isDirtyForm"
          [qaTags]="'qa-btn-save'"
          class="w-120"
          type="primary">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </div>
  </div>
  <ls-sensors-keyboard
    (saveSensors)="saveSensors($event)"
    [activeZone]="activeElement$ | async"
    [isDisabled]="!(activeElement$ | async) || isLoading"
    [sensorElements]="sensors$ | async"
    [sensorsKey]="'sensors'"></ls-sensors-keyboard>
</div>

<ng-template #averageSensors>
  <div class="sensor sensor-all">
    <ls-svg-icon
      [color]="ColorsEnum.BlueDefault"
      [fullHeight]="true"
      [path]="'heating/temp'"
      class="mb-5"></ls-svg-icon>
    <span class="fz-12 color-tertiary">{{ "TemperatureMapping.All" | translate }}</span>
  </div>
</ng-template>

<ng-template #emptyRow>
  <div class="table-row">
    <div class="table-cell"></div>
    <div class="table-cell"></div>
    <div class="table-cell"></div>
    <div class="table-cell"></div>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
