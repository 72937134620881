import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PlatformService } from '@livestock/shared/services';
import { greaterThanValidator, lessThanValidator } from '@livestock/shared/validators';
import { KeyboardModeEnum } from '@livestock/ui';
import { Store } from '@ngrx/store';
import {
  selectElementTestingData,
  selectIsTestingDataLoading,
} from 'libs/installation/src/lib/+state/installation.selectors';
import { Subscription, filter } from 'rxjs';

@Component({
  selector: 'ls-lighting-testing-form',
  templateUrl: './lighting-sensor-testing-form.component.html',
  styleUrls: ['./lighting-sensor-testing-form.component.scss'],
})
export class LightingTestingFormComponent implements OnInit {
  isLoading$ = this.store.select(selectIsTestingDataLoading);

  form: FormGroup;
  KeyboardModeEnum = KeyboardModeEnum;

  private sub$ = new Subscription();

  MIN = 0;
  MAX = 6000;

  constructor(private store: Store, public platformService: PlatformService) {}

  ngOnInit(): void {
    this.form = new FormGroup({
      min1V: new FormControl<number>(0, [
        Validators.min(this.MIN),
        Validators.max(this.MAX),
        lessThanValidator('max10V'),
      ]),
      max10V: new FormControl<number>(0, [
        Validators.min(this.MIN),
        Validators.max(this.MAX),
        greaterThanValidator('min1V'),
      ]),
    });

    this.sub$.add(
      this.store
        .select(selectElementTestingData)
        .pipe(filter((testingData) => !!testingData))
        .subscribe((testingData) => {
          this.form.patchValue(testingData, {emitEvent: false});
        }),
    );
  }
}
