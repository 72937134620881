import { Action, createReducer, on } from '@ngrx/store';
import * as virtualKeyboardActions from './virtual-keyboard.actions';
import { VirtualKeyboardState } from './virtual-keyboard.state';
import { VirtualKeyboardModesEnum } from '../virtual-keyboard-modes.enum';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/am-pm.enum';
import { VirtualKeyboardConstants } from '../virtual-keyboard.constants';

const initialState: VirtualKeyboardState = {
  mode: VirtualKeyboardModesEnum.Numeric,
  elementUuid: null,
  symbol: null,
  ranges: null,
  labelForRanges: VirtualKeyboardConstants.DefaultRangeLabel,
  AMPM: AmPmEnum.AM,
  AMPMDisabled: true,
};

const reducer = createReducer(
  initialState,
  on(virtualKeyboardActions.setVirtualKeyboardMode, (state, { mode }) => {
    return {
      ...state,
      mode,
    };
  }),
  on(virtualKeyboardActions.setVirtualKeyboardElementUuid, (state, { elementUuid, ranges, labelForRanges }) => {
      return {
        ...state,
        elementUuid,
        ranges,
        labelForRanges,
        symbol: null,
      };
    },
  ),
  on(virtualKeyboardActions.setVirtualKeyboardSymbol, (state, { symbol }) => {
    return {
      ...state,
      symbol,
    };
  }),
  on(virtualKeyboardActions.setVirtualKeyboardAMPM, (state, { AMPM }) => {
    return {
      ...state,
      AMPM,
    };
  }),
  on(virtualKeyboardActions.setVirtualKeyboardAMPMDisabled, (state, { AMPMDisabled }) => {
    return {
      ...state,
      AMPMDisabled,
    };
  }),
  on(virtualKeyboardActions.clearVirtualKeyboardElementUuid, (state) => {
    return {
      ...state,
      elementUuid: null,
    };
  }),
  on(virtualKeyboardActions.setVirtualKeyboardRanges, (state, { ranges, labelForRanges }) => {
    return {
      ...state,
      ranges,
      labelForRanges,
    };
  }),
);

export function virtualKeyboardReducer(state: VirtualKeyboardState, action: Action): VirtualKeyboardState {
  return reducer(state, action);
}
