import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { Observable, Subscription, filter, first } from 'rxjs';
import { selectElementSettings } from 'libs/installation/src/lib/+state/installation.selectors';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-cooling-settings-form',
  templateUrl: './cooling-settings-form.component.html',
  styleUrls: ['./cooling-settings-form.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
})
export class CoolingSettingsFormComponent implements OnInit, OnDestroy {
  @Input({ required: true }) formGroupName: string;
  @Input() isEditMode: boolean;
  @Input() isResetForm$: Observable<boolean>;
  @Input() isSaveForm$: Observable<boolean>;
  @Output() setIsDirty: EventEmitter<boolean> = new EventEmitter();

  // subs
  sub$: Subscription = new Subscription();

  /* component vars */
  parentFormContainer = inject(ControlContainer);
  originalValues;

  ElementsValidationConstants = ElementsValidationConstants;

  get parentFormGroup(): FormGroup {
    return this.parentFormContainer.control as FormGroup;
  }

  constructor(private store: Store) { }

  ngOnInit(): void {
    this.store.select(selectElementSettings).pipe(
      filter(res => res != null),
      first(),
    ).subscribe(({ minDay, humLevelToRestart, timeToWetPad }) => {
      this.originalValues = { minDay, humLevelToRestart, timeToWetPad };

      this.parentFormGroup.addControl(this.formGroupName, new FormGroup({
        minDay: new FormControl<number>(minDay, [Validators.min(ElementsValidationConstants.coolingSettings.minDay.min), Validators.max(ElementsValidationConstants.coolingSettings.minDay.max)]),
        humLevelToRestart: new FormControl<number>(humLevelToRestart, [Validators.min(ElementsValidationConstants.coolingSettings.humLevelToRestart.min), Validators.max(ElementsValidationConstants.coolingSettings.humLevelToRestart.max)]),
        timeToWetPad: new FormControl<number>(timeToWetPad, [Validators.min(ElementsValidationConstants.coolingSettings.timeToWetPad.min), Validators.max(ElementsValidationConstants.coolingSettings.timeToWetPad.max)]),
      }));
    });

    this.sub$.add(this.isResetForm$.subscribe(val => {
      if (val) {
        const group = this.parentFormGroup.get(this.formGroupName);
        group.patchValue({ ...this.originalValues });
        group.updateValueAndValidity({ emitEvent: false });
      }
    }));

    this.sub$.add(this.isSaveForm$.subscribe(val => {
      if (val) {
        const group = this.parentFormGroup.get(this.formGroupName);
        this.originalValues = group.getRawValue();
        group.patchValue({ ...this.originalValues });
        group.updateValueAndValidity({ emitEvent: false });
      }
    }));

    this.sub$.add(
      this.parentFormGroup.get(this.formGroupName).valueChanges.subscribe((items) => {
        this.setIsDirty.emit(JSON.stringify(items) !== JSON.stringify(this.originalValues));
      }),
    );
  }

  ngOnDestroy(): void {
    this.parentFormGroup.removeControl(this.formGroupName);
    this.sub$.unsubscribe();
  }
}
