import { HeatingProgramState } from '@livestock/heating-program';
import { createFeatureSelector, createSelector } from '@ngrx/store';

export const HEATING_PROGRAMS_FEATURE_KEY = 'heatingPrograms';

export const getHeatingProgramState =
    createFeatureSelector<HeatingProgramState>(HEATING_PROGRAMS_FEATURE_KEY);

export const selectIsLoading = createSelector(
    getHeatingProgramState,
    ({ isLoading }) => isLoading,
);

export const selectHeatingPrograms = createSelector(
    getHeatingProgramState,
    ({ programs }) => programs,
);

export const selectHeatingProgramZones = createSelector(
    getHeatingProgramState,
    ({ programZones }) => programZones,
);

export const selectOriginalHeatingPrograms = createSelector(
    getHeatingProgramState,
    ({ originalPrograms }) => originalPrograms,
);

export const selectIsDirty = createSelector(
    getHeatingProgramState,
    ({ isDirty }) => isDirty,
);