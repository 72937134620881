<div
  *ngIf="!isLoading && form; else loading"
  [class.mobile-styles]="platformService.isMobileApp"
  [class.rtl]="languageService.isRtl"
  [class.show-mode]="!editMode"
  [formGroup]="form"
  class="item">
  <div class="item__container">
    <div
      *ngIf="!platformService.isMobileApp; else mobileView"
      #table
      [class.six-rows]="editMode"
      [class.table-border-bottom]="numberOfItems >= 10"
      [ngClass]="
        !editMode
          ? ['w-1-8', 'w-2-12', 'w-3-12', 'w-4-12', 'w-5-12', 'w-6-12', 'w-7-16', 'w-8-16']
          : ['w-1-10', 'w-2-18', 'w-3-18', 'w-4-18', 'w-5-18', 'w-6-18']
      "
      class="table">
      <div class="table-row table-header h-60">
        <div class="table-cell blue">
          {{ "HeatingProgram.Program" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.Zone" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.OnTempDiff" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.OffTempDiff" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.MaxOutput" | translate }}
        </div>
        <div
          [class.last-column]="editMode"
          class="table-cell blue">
          {{ "HeatingProgram.MinOutput" | translate }}
        </div>
        <ng-container *ngIf="!editMode">
          <div class="table-cell blue">
            {{ "HeatingProgram.Heaters" | translate }}
          </div>
          <div class="table-cell blue">
            {{ "HeatingProgram.Sensors" | translate }}
          </div>
        </ng-container>
      </div>

      <ng-container formArrayName="heatingPrograms">
        <ng-container
          *ngFor="let programForm of form.get('heatingPrograms')['controls']; let index = index; let isLast = last"
          [formGroup]="programForm">
          <div
            (click)="setActiveIndex(index)"
            [class.active]="activeIndex === index && editMode"
            [class.last-row]="isLast && activeIndex !== index"
            class="table-row padding-top-11">
            <div class="table-cell no-border-bottom blue">
              {{ programForm.value.number }}
            </div>
            <div class="table-cell no-border-bottom">
              <ng-container *ngIf="!editMode">
                {{ ([programForm.value.zoneID, programZones] | memoizeFunc : getProgramZoneByZoneID)?.name }}
              </ng-container>
              <ng-select
                *ngIf="editMode"
                [clearable]="false"
                [qaTags]="'qa-ddl-zones'"
                [searchable]="false"
                [showTooltipOnHover]="true"
                selectTitle="{{ 'HeatingProgram.Zone' | translate }}"
                appendTo="body"
                errorField
                formControlName="zoneID">
                <ng-option
                  *ngFor="let zone of programZones"
                  [disabled]="
                    [programForm.value.zoneID, zone.zoneID, form.get('heatingPrograms').value]
                      | memoizeFunc : isZoneAlreadyUsed
                  "
                  [value]="zone.zoneID">
                  {{ zone.name }}
                </ng-option>
              </ng-select>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row">
                {{ programForm.value?.onTempDiff?.toFixed(1) }}
                <ng-container *ngTemplateOutlet="unit"> </ng-container>
              </div>
              <ls-input-decimal
                *ngIf="editMode"
                [accuracy]="1"
                [fieldWithKeyboard]="true"
                [max]="maxTemperature"
                [min]="minTemperature"
                [qaTags]="'qa-txt-on-temp-diff'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="onTempDiff">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row">
                {{ programForm.value?.offTempDiff?.toFixed(1) }}
                <ng-container *ngTemplateOutlet="unit"> </ng-container>
              </div>
              <ls-input-decimal
                *ngIf="editMode"
                [accuracy]="1"
                [fieldWithKeyboard]="true"
                [max]="maxTemperature"
                [min]="minTemperature"
                [qaTags]="'qa-txt-off-temp-diff'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="offTempDiff">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
            <div class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row">
                {{ programForm.value?.maxOutput }}
                <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
              </div>
              <ls-input-integer
                *ngIf="editMode"
                (change)="checkOutput(index, 'minOutput')"
                [fieldWithKeyboard]="true"
                [max]="HeatingProgramConstants.OuputMax"
                [min]="0"
                [qaTags]="'qa-txt-max-output'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="maxOutput">
                <div
                  class="unit"
                  suffix>
                  {{ GlobalConstants.PercentageSymbol }}
                </div>
              </ls-input-integer>
            </div>
            <div
              [class.last-column]="editMode"
              class="table-cell no-border-bottom">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row">
                {{ programForm.value?.minOutput }}
                <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
              </div>
              <ls-input-integer
                *ngIf="editMode"
                (change)="checkOutput(index, 'maxOutput')"
                [fieldWithKeyboard]="true"
                [max]="HeatingProgramConstants.OuputMax"
                [min]="0"
                [qaTags]="'qa-txt-min-output'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="minOutput">
                <div
                  class="unit"
                  suffix>
                  {{ GlobalConstants.PercentageSymbol }}
                </div>
              </ls-input-integer>
            </div>
            <ng-container *ngIf="!editMode">
              <div class="table-cell no-border-bottom padding-top-10">
                <div
                  *ngFor="
                    let heatingElement of [programForm.value.zoneID, programZones]
                      | memoizeFunc : getHeaterElementsByZoneID
                  "
                  class="heater mr-3">
                  <ls-svg-icon
                    [class.heat-do]="heatingElement.elementType === ElementTypesEnum.HeatingDO"
                    [color]="ColorsEnum.BlueDefault"
                    [fullHeight]="true"
                    [path]="
                      heatingElement.elementType === ElementTypesEnum.HeatingDO ? 'heating/heat-do' : 'heating/heat'
                    "
                    class="mb-5">
                  </ls-svg-icon>
                  <span class="fz-12 color-tertiary">{{ heatingElement.number }}</span>
                </div>
              </div>
              <div class="table-cell no-border-bottom padding-top-10">
                <ng-container
                  *ngIf="
                    ([programForm.value.zoneID, programZones] | memoizeFunc : getProgramZoneByZoneID)?.isAverage;
                    else sensors
                  ">
                  <div class="sensor sensor-all">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/temp'"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ "HeatingProgramZone.All" | translate }}</span>
                  </div>
                </ng-container>

                <ng-template #sensors>
                  <div
                    *ngFor="
                      let sensorElement of [programForm.value.zoneID, programZones]
                        | memoizeFunc : getSensorElementsByZoneID
                    "
                    class="sensor mr-4 mb-4">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/temp'"
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ sensorElement.number }}</span>
                  </div>
                </ng-template>
              </div>
            </ng-container>
          </div>
        </ng-container>
      </ng-container>
    </div>
    <ng-template #mobileView>
      <div class="program__slides position-relative">
        <div
          *ngIf="platformService.isMobileApp && editMode"
          class="swiper-navigation d-flex flex-row">
          <ls-svg-icon
            (click)="slidePrev()"
            [class.disabled]="activeIndex === 0"
            [path]="'arrows/swipper-arrow-left'"
            class="icon pointer mr-10 qa-btn-arrow-left">
          </ls-svg-icon>
          <ls-svg-icon
            (click)="slideNext()"
            [class.disabled]="activeIndex === numberOfItems - 1"
            [path]="'arrows/swipper-arrow-right'"
            class="icon pointer ml-10 qa-btn-arrow-left">
          </ls-svg-icon>
        </div>
        <swiper
          *ngIf="swiperService.swiperIsActive"
          (activeIndexChange)="changeActiveSlide($event)"
          #swiper
          [cdr]="cdr"
          [config]="swiperService.config"
          [dir]="languageService.direction"
          [formGroup]="form"
          [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
          class="swiper overflow-y-auto pb-15"
          swiperDirective>
          <ng-template
            *ngFor="let programForm of form.get('heatingPrograms')['controls']; let index = index; let isLast = last"
            [formGroup]="programForm"
            swiperSlide>
            <div
              [class.w-1-40]="editMode"
              [class.w-1-50]="!editMode"
              [class.w-2-50]="!editMode"
              [class.w-2-60]="editMode"
              class="table position-relative">
              <div class="swiper-title">{{ "HeatingProgram.Program" | translate }}: {{ programForm.value.number }}</div>

              <div class="table-row padding-top-11">
                <div class="table-cell blue">{{ "HeatingProgram.Zone" | translate }}</div>
                <div class="table-cell">
                  <ng-container *ngIf="!editMode">
                    {{ ([programForm.value.zoneID, programZones] | memoizeFunc : getProgramZoneByZoneID)?.name }}
                  </ng-container>
                  <ng-select
                    *ngIf="editMode"
                    [clearable]="false"
                    [qaTags]="'qa-ddl-zones'"
                    [searchable]="false"
                    placeholder="{{ 'HeatingProgram.SelectZone' | translate }}"
                    selectTitle="{{ 'HeatingProgram.SelectZone' | translate }}"
                    appendTo="body"
                    formControlName="zoneID">
                    <ng-option
                      *ngFor="let zone of programZones"
                      [disabled]="
                        [programForm.value.zoneID, zone.zoneID, form.get('heatingPrograms').value]
                          | memoizeFunc : isZoneAlreadyUsed
                      "
                      [value]="zone.zoneID">
                      {{ zone.name }}
                    </ng-option>
                  </ng-select>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell blue">{{ "HeatingProgram.OnTempDiff" | translate }}</div>
                <div class="table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ programForm.value?.onTempDiff }}
                    <ng-container *ngTemplateOutlet="unit"> </ng-container>
                  </div>
                  <ls-input-decimal
                    *ngIf="editMode"
                    [accuracy]="1"
                    [fieldWithKeyboard]="true"
                    [max]="maxTemperature"
                    [min]="minTemperature"
                    [qaTags]="'qa-txt-on-temp-diff'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="onTempDiff">
                    <ng-container
                      *ngTemplateOutlet="unit"
                      suffix>
                    </ng-container>
                  </ls-input-decimal>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell blue">{{ "HeatingProgram.OffTempDiff" | translate }}</div>
                <div class="table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ programForm.value?.offTempDiff }}
                    <ng-container *ngTemplateOutlet="unit"> </ng-container>
                  </div>
                  <ls-input-decimal
                    *ngIf="editMode"
                    [accuracy]="1"
                    [fieldWithKeyboard]="true"
                    [max]="maxTemperature"
                    [min]="minTemperature"
                    [qaTags]="'qa-txt-off-temp-diff'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="offTempDiff">
                    <ng-container
                      *ngTemplateOutlet="unit"
                      suffix>
                    </ng-container>
                  </ls-input-decimal>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell blue">{{ "HeatingProgram.MaxOutput" | translate }}</div>
                <div class="table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ programForm.value?.maxOutput }}
                    <div class="unit ml-5">{{ GlobalConstants.PercentageSymbol }}</div>
                  </div>
                  <ls-input-integer
                    *ngIf="editMode"
                    (change)="checkOutput(index, 'minOutput')"
                    [fieldWithKeyboard]="true"
                    [max]="HeatingProgramConstants.OuputMax"
                    [min]="0"
                    [qaTags]="'qa-txt-max-output'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="maxOutput">
                    <div
                      class="unit"
                      suffix>
                      {{ GlobalConstants.PercentageSymbol }}
                    </div>
                  </ls-input-integer>
                </div>
              </div>
              <div class="table-row">
                <div class="table-cell blue">{{ "HeatingProgram.MinOutput" | translate }}</div>
                <div class="table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ programForm.value?.minOutput }}
                    <div class="unit ml-5">{{ GlobalConstants.PercentageSymbol }}</div>
                  </div>
                  <ls-input-integer
                    *ngIf="editMode"
                    (change)="checkOutput(index, 'maxOutput')"
                    [fieldWithKeyboard]="true"
                    [max]="HeatingProgramConstants.OuputMax"
                    [min]="0"
                    [qaTags]="'qa-txt-min-output'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="minOutput">
                    <div
                      class="unit"
                      suffix>
                      {{ GlobalConstants.PercentageSymbol }}
                    </div>
                  </ls-input-integer>
                </div>
              </div>
              <div
                *ngIf="!editMode"
                class="table-row">
                <div class="table-cell blue elements">{{ "HeatingProgram.Heaters" | translate }}</div>
                <div class="table-cell value-row">
                  <div
                    *ngFor="
                      let heatingElement of [programForm.value.zoneID, programZones]
                        | memoizeFunc : getHeaterElementsByZoneID
                    "
                    class="heater mr-3">
                    <ls-svg-icon
                      [class.heat-do]="heatingElement.elementType === ElementTypesEnum.HeatingDO"
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="
                        heatingElement.elementType === ElementTypesEnum.HeatingDO ? 'heating/heat-do' : 'heating/heat'
                      "
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ heatingElement.number }}</span>
                  </div>
                </div>
              </div>
              <div
                *ngIf="!editMode"
                class="table-row">
                <div class="table-cell blue elements">{{ "HeatingProgram.Sensors" | translate }}</div>
                <div class="table-cell value-row">
                  <ng-container
                    *ngIf="
                      ([programForm.value.zoneID, programZones] | memoizeFunc : getProgramZoneByZoneID)?.isAverage;
                      else sensors
                    ">
                    <div class="sensor sensor-all">
                      <ls-svg-icon
                        [color]="ColorsEnum.BlueDefault"
                        [fullHeight]="true"
                        [path]="'heating/temp'"></ls-svg-icon>
                      <span class="fz-12 color-tertiary">{{ "HeatingProgramZone.All" | translate }}</span>
                    </div>
                  </ng-container>

                  <ng-template #sensors>
                    <div
                      *ngFor="
                        let sensorElement of [programForm.value.zoneID, programZones]
                          | memoizeFunc : getSensorElementsByZoneID
                      "
                      class="sensor mr-4 mb-4">
                      <ls-svg-icon
                        [color]="ColorsEnum.BlueDefault"
                        [fullHeight]="true"
                        [path]="'heating/temp'"
                        class="mb-5"></ls-svg-icon>
                      <span class="fz-12 color-tertiary">{{ sensorElement.number }}</span>
                    </div>
                  </ng-template>
                </div>
              </div>
            </div>
          </ng-template>
        </swiper>
      </div>
      <div
        *ngIf="platformService.isMobileApp"
        class="d-flex align-center justify-center mt-15">
        <div *ngFor="let dot of visibleDots; let i = index">
          <div
            [class.dot-active]="visibleDots[i] === activeIndex"
            class="dot mh-5"></div>
        </div>
      </div>
    </ng-template>
  </div>

  <div
    [class.flex-1]="platformService.isMobileApp && editMode"
    class="buttons-container d-flex flex-row justify-between flex-column-md">
    <div
      *ngIf="editMode"
      class="buttons__add-delete d-flex flex-row">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #buttons>
  <div class="d-flex flex-row inner-buttons">
    <ng-container *ngIf="platformService.isMobileApp">
      <ls-svg-icon
        (click)="addEmptyRow()"
        [class.color-disabled]="
          form.invalid ||
          numberOfItems >= HeatingProgramConstants.MaxCountOfPrograms ||
          numberOfItems >= programZones.length
        "
        [class.disabled]="
          form.invalid ||
          numberOfItems >= HeatingProgramConstants.MaxCountOfPrograms ||
          numberOfItems >= programZones.length
        "
        [path]="'mobile/add'"
        class="pointer qa-btn-add-row color-green mr-10-md">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="removeRow()"
        [class.color-disabled]="activeIndex == null"
        [class.disabled]="activeIndex == null"
        [path]="'mobile/delete'"
        class="pointer qa-btn-remove-row color-green">
      </ls-svg-icon>
    </ng-container>
    <ng-container *ngIf="!platformService.isMobileApp">
      <ls-button
        (click)="addEmptyRow()"
        [class.disabled]="
          form.invalid ||
          numberOfItems >= HeatingProgramConstants.MaxCountOfPrograms ||
          numberOfItems >= programZones.length
        "
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'">
        {{ "HeatingProgram.AddProgram" | translate }}
      </ls-button>
      <ls-button
        (click)="removeRow()"
        [class.disabled]="activeIndex == null"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="ml-20">
        {{ "HeatingProgram.DeleteProgram" | translate }}
      </ls-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>

<ng-template #unit>
  <div class="unit">
    {{ temperatureUnit | enum : "temperature-unit-short-enum" | translate }}
  </div>
</ng-template>
