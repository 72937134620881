import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderTitleComponent, LoadingComponent, SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ColorsEnum, ElementTypesEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { TempMappingFormComponent } from './temp-mapping-form/temp-mapping-form.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { firstValueFrom, Observable } from 'rxjs';
import { Store } from '@ngrx/store';
import {
  selectBrooding1ElementMappings,
  selectBrooding1HasMappings,
  selectBrooding1HeatingZones,
  selectBrooding2ElementMappings,
  selectBrooding2HasMappings,
  selectBrooding2HeatingZones,
  selectBrooding2IsShown,
  selectFullHouse,
  selectIsDirtyForm,
  selectIsLoading,
} from '../+state/temp-mapping.selectors';
import { ITempMappingItem } from '../interfaces/temp-mapping-item.interface';
import { IHeatingProgramZoneItem } from '@livestock/heating-program-zone';
import { LanguageService, MenuService, PlatformService } from '@livestock/shared/services';
import { TempMappingHelpersService } from '../services/temp-mapping-helpers.service';
import { getTemperatureMappings, resetTemperatureMappings } from '../+state/temp-mapping.actions';
import { TempMappingMobileComponent } from './temp-mapping-mobile/temp-mapping-mobile.component';
import { selectActiveControllerID, selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { Router } from '@angular/router';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { TemperatureCurveRoute } from '@livestock/temperature-curve';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-temp-mapping',
  templateUrl: 'temp-mapping.component.html',
  styleUrls: ['temp-mapping.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderTitleComponent,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
    TempMappingFormComponent,
    MatCheckboxModule,
    TempMappingMobileComponent,
    LoadingComponent,
    QaTagsDirective,
  ],
})
export class TempMappingComponent implements OnInit, IComponentCanDeactivate {
  //SUBS
  fullHouse$: Observable<ITempMappingItem> = this.store.select(selectFullHouse);
  isDirtyForm$: Observable<boolean> = this.store.select(selectIsDirtyForm);
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);
  //brooding 1
  brooding1HasMappings$: Observable<boolean> = this.store.select(selectBrooding1HasMappings);
  brooding1HeatingZones$: Observable<IHeatingProgramZoneItem[]> = this.store.select(selectBrooding1HeatingZones);
  brooding1ElementMappings$: Observable<ITempMappingItem[]> = this.store.select(selectBrooding1ElementMappings);
  //brooding 2
  brooding2IsShown$: Observable<boolean> = this.store.select(selectBrooding2IsShown);
  brooding2HasMappings$: Observable<boolean> = this.store.select(selectBrooding2HasMappings);
  brooding2HeatingZones$: Observable<IHeatingProgramZoneItem[]> = this.store.select(selectBrooding2HeatingZones);
  brooding2ElementMappings$: Observable<ITempMappingItem[]> = this.store.select(selectBrooding2ElementMappings);

  //vars
  editMode: boolean;

  //enums
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private store: Store,
    private menuService: MenuService,
    private tempMappingHelpersService: TempMappingHelpersService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getTemperatureMappings());
  }

  async toggleEditMode(): Promise<void> {
    if (this.editMode) {
      const isDirtyForm = await firstValueFrom(this.isDirtyForm$);
      if (isDirtyForm) {
        const confirmationResult = await this.tempMappingHelpersService.confirmUnsavedChanges();
        if (!confirmationResult) return;
        this.store.dispatch(resetTemperatureMappings());
      }
    }

    this.editMode = !this.editMode;
  }

  async goToMenu(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  async goToSettings(): Promise<void> {
    const controllerID = await firstValueFrom(this.store.select(selectActiveControllerID));
    this.router.navigate([AppRoutes.CONTROLLER, controllerID, ControllerRoutes.TemperatureCurve, TemperatureCurveRoute.Table]);
  }

  async canDeactivate(): Promise<boolean> {
    return !(await firstValueFrom(this.isDirtyForm$));
  }

  closeComponent(): void {
  }
}
