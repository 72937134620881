import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  ValidatorFn,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';

import {
  ButtonComponent,
  DaysManagerMobileComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { ITemperatureCurveItem, TableControlNames, TableRanges } from '@livestock/temperature-curve';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { EnumPipe } from '@livestock/shared/pipes';
import { DialogsService, PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-temperature-curve-table',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    SvgIconComponent,
    ButtonComponent,
    EnumPipe,
    DaysManagerMobileComponent,
  ],
  templateUrl: './temperature-curve-table.component.html',
  styleUrls: ['./temperature-curve-table.component.scss'],
})
export class TemperatureCurveTableComponent implements OnDestroy, OnChanges {
  @ViewChild('table') table: ElementRef;
  @ViewChild('programDays') programDays: ElementRef;

  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Input() activeControllerID: number;
  @Input() isDirty: boolean;
  @Input() hasUnsaved: boolean;
  @Output() changed = new EventEmitter();

  @Input() set temperatureCurveItems(items: ITemperatureCurveItem[]) {
    this.numberOfItems = items?.length;
    this.tempCurveItems = items || [];
    this.initTempCurveForm(this.tempCurveItems);
  }

  @Input() set temperatureUnitType(unit: TemperatureUnitEnum) {
    this.temperatureUnit = unit;
    const isCelsius = this.temperatureUnit === TemperatureUnitEnum.Celsius;
    this.MIN_VALUE = isCelsius ? TableRanges.MIN_VALUE_C : TableRanges.MIN_VALUE_F;
    this.MAX_VALUE = isCelsius ? TableRanges.MAX_VALUE_C : TableRanges.MAX_VALUE_F;
    if (this.form != null) {
      this.addValidators();
    }
  }

  sub$ = new Subscription();

  tempCurveItems: ITemperatureCurveItem[];
  temperatureUnit: TemperatureUnitEnum;
  form: FormGroup;
  activeIndex: number = 0;
  accuracy: number = 1;
  numberOfItems: number;
  isDirtyForm: boolean;
  MIN_VALUE: number;
  MAX_VALUE: number;
  MIN_DAY = TableRanges.MIN_DAY;
  MAX_DAY = TableRanges.MAX_DAY;
  TARGET_DIFF_CELSIUS = 1;
  TARGET_DIFF_FAHRENHEIT = 33.8;

  constructor(
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
    private dialogService: DialogsService,
  ) {
  }

  private initTempCurveForm(items: ITemperatureCurveItem[]): void {
    this.form = this.formBuilder.group({
      tempCurveItems: this.formBuilder.array(
        items?.map((item) => {
          return this.getTempCurveFG(item);
        }),
      ),
    });

    if (this.activeIndex >= items.length) {
      this.activeIndex = Math.max(items.length - 1, 0);
    }

    this.sub$.add(
      this.form.valueChanges.subscribe((value) => {
        if (JSON.stringify(value.tempCurveItems) !== JSON.stringify(this.tempCurveItems)) {
          this.tempCurveItems = value.tempCurveItems;
          this.form.updateValueAndValidity();
          setTimeout(() => {
            this.changed.emit(this.prepareChangesToEmit());
          });
        }
      }),
    );
  }

  private addValidators(): void {
    const isValidBeforeValidators = this.form.valid;
    const controls = [
      TableControlNames.DAY,
      TableControlNames.TARGET,
      TableControlNames.HIGH_TEMP_ALARM,
      TableControlNames.LOW_TEMP_ALARM,
    ];

    (this.getFormItems().controls as FormGroup[]).forEach((element) => {
      controls.forEach((controlName) => {
        element.controls[controlName].addValidators(this.getValidatorsByControlName(controlName));
        element.controls[controlName].updateValueAndValidity();
      });
    });

    if (isValidBeforeValidators != this.form.valid) {
      this.changed.emit(this.prepareChangesToEmit());
    }
  }

  private getValidatorsByControlName(name: string): ValidatorFn[] {
    let min: number = this.MIN_VALUE;
    let max: number = this.MAX_VALUE;
    const validators: ValidatorFn[] = [Validators.required];
    switch (name) {
      case TableControlNames.DAY:
        validators.push(this.validateUniqDay());
        min = this.MIN_DAY;
        max = this.MAX_DAY;
        break;
      case TableControlNames.TARGET:
        validators.push(this.targetTempValidator());
        break;
      case TableControlNames.HIGH_TEMP_ALARM:
        validators.push(this.highTempAlarmValidator());
        break;
      case TableControlNames.LOW_TEMP_ALARM:
        validators.push(this.lowTempAlarmValidator());
        break;
    }
    validators.unshift(Validators.min(min), Validators.max(max));
    return validators;
  }

  private getTempCurveFG(item: ITemperatureCurveItem): FormGroup<any> {
    const originalState = {
      controllerID: this.activeControllerID,
      day: item.day,
      target: item.target,
      lowTemperatureAlarm: item.lowTemperatureAlarm,
      highTemperatureAlarm: item.highTemperatureAlarm,
    };

    return new FormGroup({
      controllerID: new FormControl<number>(this.activeControllerID),
      day: new FormControl<number>(item.day, this.getValidatorsByControlName(TableControlNames.DAY)),
      target: new FormControl<number>(item.target, this.getValidatorsByControlName(TableControlNames.TARGET)),
      lowTemperatureAlarm: new FormControl<number>(
        item.lowTemperatureAlarm,
        this.getValidatorsByControlName(TableControlNames.LOW_TEMP_ALARM),
      ),
      highTemperatureAlarm: new FormControl<number>(
        item.highTemperatureAlarm,
        this.getValidatorsByControlName(TableControlNames.HIGH_TEMP_ALARM),
      ),
      originalState: new FormControl<any>(originalState),
    });
  }

  targetTempValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const highTempAlarmControl = control?.parent?.get(TableControlNames.HIGH_TEMP_ALARM);
      const lowTempAlarmControl = control?.parent?.get(TableControlNames.LOW_TEMP_ALARM);

      highTempAlarmControl?.updateValueAndValidity();
      lowTempAlarmControl?.updateValueAndValidity();

      return null;
    };
  }

  highTempAlarmValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const targetControl = control?.parent?.get(TableControlNames.TARGET);
      if (!targetControl) {
        return null;
      }

      const target = targetControl.value;
      const highTempAlarm = control.value;
      const diff = this.temperatureUnit === TemperatureUnitEnum.Celsius
        ? this.TARGET_DIFF_CELSIUS
        : this.TARGET_DIFF_FAHRENHEIT;

      if (highTempAlarm < target + diff) {
        return {
          min: {
            min: (target + diff).toFixed(1),
          },
        };
      }

      return null;
    };
  }

  lowTempAlarmValidator(): ValidatorFn {
    return (control: AbstractControl): { [key: string]: any } | null => {
      const targetControl = control?.parent?.get(TableControlNames.TARGET);
      if (!targetControl) {
        return null;
      }

      const target = targetControl.value;
      const lowTempAlarm = control.value;
      const diff = this.temperatureUnit === TemperatureUnitEnum.Celsius
        ? this.TARGET_DIFF_CELSIUS
        : this.TARGET_DIFF_FAHRENHEIT;

      if (lowTempAlarm > target - diff) {
        return {
          max: {
            max: (target - diff).toFixed(1),
          },
        };
      }
      return null;
    };
  }

  async addEmptyRow(): Promise<void> {
    const itemsArr = this.getFormItems();

    if (this.isDirty && this.platformService.isMobileApp) {
      if (!await this.dialogService.canContinueAction()) return;
      const latestItem = itemsArr.controls[itemsArr.controls.length - 1];
      latestItem.patchValue((latestItem as FormGroup).controls['originalState'].value);
      this.changed.emit(this.prepareChangesToEmit());
      if (this.hasUnsaved) return;
    }

    itemsArr.push(
      this.getTempCurveFG({
        day: itemsArr.value?.length > 0 ? Math.max(...itemsArr.value.map((x) => x.day)) + 1 : 0,
        target: 0,
        highTemperatureAlarm: 0,
        lowTemperatureAlarm: 0,
        controllerID: null,
      }),
    );
    this.numberOfItems += 1;

    setTimeout(() => {
      const newItem = itemsArr.controls[itemsArr.controls.length - 1] as FormGroup<any>;
      for (const controlName in newItem.controls) {
        const control = newItem.get(controlName);
        control.markAsDirty();
        control.updateValueAndValidity();
      }
      if (this.table) {
        this.table.nativeElement.scrollTop = this.table.nativeElement.scrollHeight;
      } else {
        this.setActiveIndex(itemsArr.length - 1);
      }
      this.changed.emit(this.prepareChangesToEmit());
    });
  }

  removeRow(): void {
    if (this.activeIndex == null) {
      return;
    }

    const itemsArr = this.getFormItems();
    itemsArr.removeAt(this.activeIndex);
    this.setActiveIndex(this.activeIndex -= 1);
    this.numberOfItems -= 1;
    this.checkDuplicacy();
    this.changed.emit(this.prepareChangesToEmit());
  }

  prepareChangesToEmit(): { formValues: any[], isValid: boolean, isDirty: boolean } {
    return {
      formValues: this.form.value.tempCurveItems.map(value => {
        delete value?.['originalState'];
        return value;
      }),
      isValid: this.form.valid,
      isDirty: this.form.dirty,
    };
  }

  setActiveIndex(index: number): void {
    this.activeIndex = index >= 0 ? index : null;
  }

  getFormItems(): FormArray<any> {
    return this.form.controls['tempCurveItems'] as FormArray;
  }

  checkDuplicacy(): void {
    const itemsArr = this.getFormItems();
    itemsArr.controls.forEach((x) => {
      (x as FormGroup).get(TableControlNames.DAY).updateValueAndValidity();
    });
  }

  validateUniqDay(): ValidatorFn {
    return (control: AbstractControl) => {
      if (control.value != null) {
        const formArray = control.parent ? (control.parent.parent as FormArray) : null;
        if (formArray) {
          const days = formArray.value.map((x) => x.day);
          return days.filter((x) => x == control.value).length > 1 ? { duplicatedDay: true } : null;
        }
      }
      return null;
    };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('isLoading' in changes && changes['isLoading'].currentValue === true) {
      this.setActiveIndex(0);
    }
    if ('editMode' in changes && changes['editMode'].currentValue === false && this.platformService.isMobileApp) {
      setTimeout(() => this.scrollToActiveIndex());
    }
    if ('editMode' in changes && changes['editMode'].currentValue === true && this.tempCurveItems?.length == 0) {
      this.addEmptyRow();
    }
  }

  private scrollToActiveIndex(): void {
    if (!this.programDays) return;
    const containerElement = this.programDays.nativeElement;
    const activeElement = containerElement.querySelector(`.program-day:nth-child(${this.activeIndex + 1})`);
    containerElement.scrollTop = activeElement.offsetTop - activeElement.offsetHeight * 2;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
