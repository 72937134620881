<button
  [class.m-auto]="marginAuto"
  [class.row-reverse]="location === 'after'"
  [class.selected]="selected"
  [qaTags]="qaTags"
  class="with-icon d-flex align-center justify-center pv-7-md pv-4 ph-10 fz-20 fw-500 {{ buttonType }}">
  <ls-svg-icon
    *ngIf="iconPath"
    [hasMargin]="location === 'before'"
    [path]="iconPath"></ls-svg-icon>
  <span class="mr-5 ml-5 fz-16-md">{{ textContent | translate }}</span>
</button>
