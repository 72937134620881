export enum StagingEnum {
    FullHouse = 0,
    Brooding = 1,
}

export namespace StagingEnum {
    export function toTranslateKey(value: any): string {
        switch (value) {
            case StagingEnum.FullHouse:
                return 'Flock.Settings.StagingEnum.FullHouse';
            case StagingEnum.Brooding:
                return 'Flock.Settings.StagingEnum.Brooding';
            default:
                return value.toString();
        };
    }
}