import { Action, createReducer, on } from '@ngrx/store';
import { LightingMappingState } from './lighting-mapping.state';
import * as lightingMappingActions from './lighting-mapping.actions';

export const initialState: LightingMappingState = {
  lightingMappings: [],
  originalLightingMappings: [],
  activeMappingElementID: null,
  sensorElements: [],
  isLoading: false,
};

const reducer = createReducer(
  initialState,
  on(
    lightingMappingActions.getLightingMappings,
    lightingMappingActions.updateLightingMappings,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    lightingMappingActions.getLightingMappingsError,
    lightingMappingActions.updateLightingMappingsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(lightingMappingActions.getLightingMappingsSuccess, (state, { view }) => {
      return {
        ...state,
        originalLightingMappings: view.elementMappings,
        lightingMappings: view.elementMappings,
        sensorElements: view.sensorElements,
        isLoading: false,
      };
    },
  ),
  on(lightingMappingActions.updateLightingMappingsSuccess, (state) => {
      return {
        ...state,
        originalLightingMappings: [...state.lightingMappings],
        isLoading: false,
      };
    },
  ),
  on(lightingMappingActions.setActiveMapping, (state, { lightElementID }) => {
      return {
        ...state,
        activeMappingElementID: lightElementID,
        isLoading: false,
      };
    },
  ),
  on(lightingMappingActions.changeActiveElementIsAverage, (state, { isAverage, lightElementID }) => {
      const lightingMappings = [...state.lightingMappings];
      const activeMappingIndex: number = lightingMappings.findIndex(mapping => mapping.lightElementID === lightElementID);

      if (activeMappingIndex !== -1) {
        lightingMappings[activeMappingIndex] = {
          ...lightingMappings[activeMappingIndex],
          sensorsList: [],
          average: isAverage,
        };
      }

      return {
        ...state,
        lightingMappings,
        isLoading: false,
      };
    },
  ),
  on(lightingMappingActions.addSensorsToMapping, (state, { sensors }) => {
    const lightingMappings = [...state.lightingMappings];
    const activeMappingIndex: number = lightingMappings
      .findIndex(mapping => mapping.lightElementID === state.activeMappingElementID);

    if (activeMappingIndex !== -1) {
      lightingMappings[activeMappingIndex] = {
        ...lightingMappings[activeMappingIndex],
        sensorsList: sensors,
      };
    }

    return {
      ...state,
      lightingMappings,
      isLoading: false,
    };
  }),
  on(lightingMappingActions.resetToOriginalMappings, (state) => {
    return {
      ...state,
      lightingMappings: JSON.parse(JSON.stringify(state.originalLightingMappings)),
      activeMappingElementID: null,
    };
  }),
);

export function lightingMappingReducer(state: LightingMappingState | undefined, action: Action): any {
  return reducer(state, action);
}
