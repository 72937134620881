import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  PageWrapperComponent,
  ButtonComponent,
  KeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { Observable, Subscription } from 'rxjs';
import {
  AirTreatmentFormComponent,
  AirTreatmentThresholdsFormComponent,
  IAirTreatmentThresholdsView,
  IAirTreatmentView,
  deleteAirTreatment,
  getAirTreatmentItems,
  getAirTreatmentThresholds,
  selectAirTreatmentItems,
  selectAirTreatmentThresholds,
  selectIsLoading,
  setAirTreatmentItems,
  setAirTreatmentThresholds,
  updateAirTreatmentItems,
  updateAirTreatmentThresholds,
} from '@livestock/air-treatment';
import { KeyboardModeEnum } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { AppRoutes } from 'libs/shared/routes/app-routes.const';
import { ControllerRoutes } from 'libs/shared/routes/controller-routes.const';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { ColorsEnum } from '@livestock/shared/enums';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-air-treatment',
  standalone: true,
  templateUrl: './air-treatment.component.html',
  styleUrls: ['./air-treatment.component.scss'],
  imports: [
    CommonModule,
    TranslateModule,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    AirTreatmentFormComponent,
    AirTreatmentThresholdsFormComponent,
    PageWrapperComponent,
  ],
})
export class AirTreatmentComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  // variables
  editMode: boolean;
  controllerID: number;
  airTreatmentItems: IAirTreatmentView[];
  originalAirTreatmentItems: IAirTreatmentView[];
  airTreatmentThresholds: IAirTreatmentThresholdsView;
  originalAirTreatmentThresholds: IAirTreatmentThresholdsView;
  isValid = true;
  isValidThresholds = true;
  isDirty = false;

  // enums
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    public platformService: PlatformService,
    private dialogsService: DialogsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
        this.store.dispatch(getAirTreatmentItems({ controllerID: this.controllerID }));
        this.store.dispatch(getAirTreatmentThresholds({ controllerID: this.controllerID }));
      }),
    );

    this.sub$.add(
      this.store.select(selectAirTreatmentItems)
        .pipe(wasChangedAndNotNull())
        .subscribe((airTreatmentItems) => {
          if (!this.originalAirTreatmentItems) {
            this.originalAirTreatmentItems = airTreatmentItems;
          }
          this.airTreatmentItems = airTreatmentItems;
          this.isDirty = this.areAnyChanges();
        }),
    );

    this.sub$.add(
      this.store.select(selectAirTreatmentThresholds)
        .pipe(wasChangedAndNotNull())
        .subscribe((airTreatmentThresholds) => {
          if (!this.originalAirTreatmentThresholds) {
            this.originalAirTreatmentThresholds = airTreatmentThresholds;
          }
          this.airTreatmentThresholds = airTreatmentThresholds;
          this.isDirty = this.areAnyChanges();
        }),
    );
  }

  changedItems(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setAirTreatmentItems({ items: event.formValues }));
    }
  }

  changedThresholds(event: { formValues: any, isValid: boolean }): void {
    this.isValidThresholds = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setAirTreatmentThresholds({ view: event.formValues }));
    }
  }

  update(): void {
    if (!this.isValid || !this.isValidThresholds) {
      return;
    }

    this.originalAirTreatmentItems = this.airTreatmentItems;
    this.originalAirTreatmentThresholds = this.airTreatmentThresholds;
    this.store.dispatch(updateAirTreatmentItems({ controllerID: this.controllerID }));
    this.store.dispatch(updateAirTreatmentThresholds({ controllerID: this.controllerID }));
    this.isDirty = false;
  }

  toggleEditMode(): void {
    if (!this.editMode && !this.airTreatmentItems?.length) {
      this.addDefaultDay();
      return;
    }

    if (this.editMode) {
      this.cancel();
      return;
    }

    this.editMode = !this.editMode;
  }

  deleteDay(day: number): void {
    this.store.dispatch(deleteAirTreatment({ controllerID: this.controllerID, day: day }));
    if (this.platformService.isMobileApp) {
      this.editMode = false;
    }
  }

  areAnyChanges(): boolean {
    return JSON.stringify(this.originalAirTreatmentItems) !== JSON.stringify(this.airTreatmentItems) ||
      JSON.stringify(this.originalAirTreatmentThresholds) !== JSON.stringify(this.airTreatmentThresholds);
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.store.dispatch(setAirTreatmentItems({ items: this.originalAirTreatmentItems }));
    this.store.dispatch(setAirTreatmentThresholds({ view: this.originalAirTreatmentThresholds }));
    return true;
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.editMode = false;
    }
  }

  async goBack(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  addDefaultDay(): void {
    this.store.dispatch(setAirTreatmentItems({
      items: [{
        controllerID: this.controllerID,
        day: 0,
        humidity: 0,
        co2: 0,
        ammonia: 0,
      }],
    }));
    this.editMode = true;
  }

  redirectToSettings(): void {
    this.router.navigate([AppRoutes.CONTROLLER, this.controllerID, ControllerRoutes.AirTreatmentSettings]);
  }

  canDeactivate(): boolean {
    return !this.areAnyChanges();
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
