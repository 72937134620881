<ng-container *ngIf="controller$ | async as controller">
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <main
      *ngIf="user"
      class="scanned-login mx-auto">
      <div [ngSwitch]="currStep">
        <ng-container *ngSwitchCase="1">
          <p class="fz-16 fw-500 text-center mb-16 grey-light">{{ "LoginScanned.Welcome" | translate }}</p>
          <p class="fz-20 fw-300 mb-32">{{ user.email }}</p>
        </ng-container>
        <ng-container *ngSwitchCase="2">
          <div class="d-flex align-center mb-60 h-32">
            <ls-svg-icon
              [hasMargin]="true"
              class="w-22 mr-8"
              path="home/farms/devices/device"></ls-svg-icon>
            <p class="fz-22">Trio Irn-{{ controller.sn }}</p>
          </div>
        </ng-container>
        <ng-container *ngSwitchCase="3">
          <p class="mb-20">{{ "LoginScanned.Congratulations" | translate }}</p>
        </ng-container>
      </div>

      <ls-stepper
        [currStep]="currStep"
        [steps]="3"
        class="mb-40"></ls-stepper>

      <ng-container [ngSwitch]="currStep">
        <ng-container *ngSwitchCase="1">
          <p class="fz-16 mb-30 text-center">{{ "LoginScanned.YouHaveScanned" | translate }}</p>
          <ls-svg-icon
            [color]="ColorsEnum.Default"
            [hasMargin]="true"
            class="mb-30"
            path="home/farms/devices/device"></ls-svg-icon>
          <h2 class="fz-20 fw-500 mb-4 text-center">{{ "LoginScanned.Device" | translate }}</h2>
          <h2 class="fz-16 mb-40 text-center grey-light">ID Irn-{{ controller.sn }}</h2>
          <ls-button
            (click)="currStep = currStep + 1"
            class="w-100-percent d-flex justify-center"
            >{{ "LoginScanned.AssignToFarm" | translate }}
          </ls-button>
        </ng-container>

        <ng-container *ngSwitchCase="2">
          <ng-container
            *ngIf="mode === 'select'"
            [ngTemplateOutlet]="selectFarm"></ng-container>
          <ng-container
            *ngIf="mode === 'create'"
            [ngTemplateOutlet]="createFarm"></ng-container>
        </ng-container>

        <ng-container *ngSwitchCase="3">
          <p class="fz-24 fz-16-md text-center mb-20">
            {{ "LoginScanned.ANewIronDevice" | translate }} (Id {{ controller.sn }})
            <span>{{ "LoginScanned.IsAssignedToYourAccount" | translate }}</span>
          </p>
          <ls-button (click)="router.navigate(['farm/' + selected?.farmID])">
            {{ "LoginScanned.Close" | translate }}
          </ls-button>
        </ng-container>
      </ng-container>
    </main>
  </ng-container>
</ng-container>

<ng-template #selectFarm>
  <p class="fz-16 mb-12 grey width-100-percent">{{ "LoginScanned.SelectFarm" | translate }}</p>
  <ul class="mb-60 items-list">
    <li
      *ngFor="let farm of farms$ | async"
      (click)="selectFarmFromList(farm)"
      [class.selected]="selected?.farmID === farm.farmID"
      class="farm-item fz-16 pointer">
      <ls-svg-icon
        [hasMargin]="true"
        [path]="'scanned-login/farm'" />
      {{ farm.name }}
    </li>
  </ul>
  <ls-button-with-icon
    (click)="mode = 'create'"
    [buttonType]="'secondary'"
    [iconPath]="'plus-primary'"
    class="mb-16 w-100-percent d-flex justify-center"
    textContent="LoginScanned.AddNewFarm"></ls-button-with-icon>
  <ls-button
    (click)="assign(selected!)"
    [class.disabled]="!selected"
    class="w-100-percent d-flex justify-center">
    {{ "LoginScanned.AssignDevice" | translate }}
  </ls-button>
</ng-template>

<ng-template #createFarm>
  <ls-create-farm
    (closeComponent)="mode = 'select'"
    [markupMode]="'scanned'"
    [viewMode]="ViewModeEnum.CreateFarm"></ls-create-farm>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
