import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as staticPressureActions from './static-pressure.actions';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { StaticPressureService } from '../services/static-pressure.service';
import { selectActiveControllerID, selectAirPressureUnit, selectCurrentControllerPressureUnit, selectCurrentControllerTemperatureUnit, selectTemperatureUnit } from '@livestock/controllers';
import { IStaticPressure } from '../interfaces/static-pressure.interface';
import { AirPressureUnitEnum, TemperatureUnitEnum } from '@livestock/shared/enums';

@Injectable()

export class StaticPressureEffects {

  getStaticPressure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticPressureActions.getStaticPressure),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectTemperatureUnit),
        this.store.select(selectAirPressureUnit),
      ),
      switchMap(([_action, controllerID, temperatureUnit, airPressureUnit]: [any, number, TemperatureUnitEnum, AirPressureUnitEnum]) => {
        return this.staticPressureService.getStaticPressure(controllerID, temperatureUnit, airPressureUnit).pipe(
          map((view: IStaticPressure) =>
            staticPressureActions.getStaticPressureSuccess({ view })),
          catchError((error) => of(staticPressureActions.getStaticPressureError({ payload: error }))),
        );
      }),
    ),
  );

  updateStaticPressure$ = createEffect(() =>
    this.actions$.pipe(
      ofType(staticPressureActions.updateStaticPressure),
      withLatestFrom(
        this.store.select(selectActiveControllerID),
        this.store.select(selectCurrentControllerTemperatureUnit),
        this.store.select(selectCurrentControllerPressureUnit),
      ),
      switchMap(([{ view }, controllerID, temperatureUnit, pressureUnit]: [any, number, TemperatureUnitEnum, AirPressureUnitEnum]) => {
        return this.staticPressureService.updateStaticPressure(controllerID, view, temperatureUnit, pressureUnit).pipe(
          switchMap((updatedStaticPressure: IStaticPressure) => {
            return [
              staticPressureActions.updateStaticPressureSuccess({ view: updatedStaticPressure }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.SavedSuccessfully',
                },
              }),
            ];
          }),
          catchError((error) => of(staticPressureActions.updateStaticPressureError({ payload: error }))),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private actions$: Actions,
    private staticPressureService: StaticPressureService,
  ) {
  }
}
