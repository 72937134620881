import { VirtualKeyboardModesEnum } from '../virtual-keyboard-modes.enum';
import { IMinMax } from '@livestock/shared/interfaces';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/am-pm.enum';

export interface VirtualKeyboardState {
  mode: VirtualKeyboardModesEnum;
  elementUuid: string;
  symbol: number | string;
  ranges: IMinMax;
  labelForRanges: string;
  AMPM: AmPmEnum;
  AMPMDisabled: boolean;
}
