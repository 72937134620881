<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Ventilation</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit qa-lnk-vent-p-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content">
      <div
        [qaTags]="
          'qa-lbl-vent-p-type qa-lbl-vent-p-step-num qa-lbl-vent-p-step-arrow qa-lbl-vent-p-min qa-lbl-vent-p-max'
        "
        class="ph-15 ph-5-device ph-0-mobile">
        <img
          alt="mockup"
          class="d-block m-auto w-100-percent"
          src="/assets/icons/dashboard/ventilation/mockup.svg" />
      </div>

      <div
        [qaTags]="'qa-lbl-vent-p-cycle-status'"
        class="fz-16 text-center mb-30 cycle-on">
        Cycle On
        <span
          [qaTags]="'qa-lbl-vent-p-cycle-time'"
          class="color-tertiary">
          82sec
        </span>
      </div>

      <div class="vents d-flex flex-wrap justify-center fz-16">
        <div class="vent">
          <img
            [qaTags]="'qa-img-vent-p-vent-icon'"
            alt="vent"
            src="/assets/icons/dashboard/ventilation/vent.svg" />
          <span [qaTags]="'qa-img-vent-p-vent-num'">1</span>
        </div>
        <div class="vent">
          <img
            [qaTags]="'qa-img-vent-p-vent-icon'"
            alt="vent"
            src="/assets/icons/dashboard/ventilation/vent.svg" />
          <span [qaTags]="'qa-img-vent-p-vent-num'">3</span>
        </div>
        <div class="vent">
          <img
            [qaTags]="'qa-img-vent-p-vent-icon'"
            alt="vent"
            src="/assets/icons/dashboard/ventilation/vent.svg" />
          <span [qaTags]="'qa-img-vent-p-vent-num'">4</span>
        </div>
        <div class="vent">
          <img
            [qaTags]="'qa-img-vent-p-vent-icon'"
            alt="vent"
            src="/assets/icons/dashboard/ventilation/vent.svg" />
          <span [qaTags]="'qa-img-vent-p-vent-num'">5</span>
        </div>
      </div>
    </div>
  </div>
</div>
