import { FormGroup } from '@angular/forms';
import { KeyboardModeEnum } from '@livestock/ui';
import { createAction, props } from '@ngrx/store';
import { AmPmEnum } from '../../../../../../controllers/src/lib/enums/am-pm.enum';

export const keyboardUpdateFields = createAction(
  '[KEYBOARD] Keyboard Update fields',
);

export const keyboardUpdateFieldsSuccess = createAction(
  '[KEYBOARD] Keyboard Update fields [SUCCESS]',
);

export const setKeyboardValue = createAction(
  '[KEYBOARD] Set Keyboard Value',
  props<{ symbol: number | string }>(),
);

export const setButtonsCurrentControl = createAction(
  '[KEYBOARD] Set buttons current control',
  props<{ controls: FormGroup }>(),
);

export const clearKeyboardValue = createAction(
  '[KEYBOARD] Clear Keyboard Value',
);

export const removeSymbol = createAction(
  '[KEYBOARD] Remove Symbol',
);

export const setElementUuid = createAction(
  '[KEYBOARD] Set Form Control Name',
  props<{ elementUuid: string }>(),
);

export const switchElementUuid = createAction(
  '[KEYBOARD] Switch Form Control Name',
  props<{ elementUuid: string }>(),
);

export const clearFormControlInputInfo = createAction(
  '[KEYBOARD] Clear Form Control Input Info',
);

export const setKeyboardRanges = createAction(
  '[KEYBOARD] Set Ranges',
  props<{ ranges: { min: number | string, max: number | string } }>(),
);

export const setKeyboardMode = createAction(
  '[KEYBOARD] Set Keyboard Mode',
  props<{ mode: KeyboardModeEnum }>(),
);

export const setKeyboardIconPath = createAction(
  '[KEYBOARD] Set icon path',
  props<{ path: string }>(),
);

export const setKeyboardAMPMButtons = createAction(
  '[KEYBOARD] Set AM/PM Buttons',
  props<{ hasAMPM: boolean }>(),
);

export const setKeyboardActiveAMPMButton = createAction(
  '[KEYBOARD] Set Active AM or PM Button',
  props<{ activeAMPM: AmPmEnum }>(),
);
