import { Component, inject } from '@angular/core';
import { LightingSettingsFormBaseComponent } from '../lighting-settings-form-base/lighting-settings-form-base.component';
import { ControlContainer } from '@angular/forms';

@Component({
  selector: 'ls-lighting-settings-form-desktop',
  templateUrl: './lighting-settings-form.component.html',
  styleUrls: ['../shared/lighting-settings-form.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useFactory: () => inject(ControlContainer, { skipSelf: true }),
    },
  ],
})
export class LightingSettingsFormComponent extends LightingSettingsFormBaseComponent {

}
