import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  ButtonWizardComponent,
  InputIntegerComponent,
  KeyboardModeEnum,
  LoadingGalconComponent,
  setKeyboardMode,
  SlimButtonComponent,
  StepperComponent,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import { Store } from '@ngrx/store';
import {
  clearUpsertControllerState,
  createDateTimeSettingsViaDevice,
  createFlockSettingsViaDevice,
  createGeneralSettingsViaDevice,
  createHouseModesViaDevice,
  createHouseSizesViaDevice,
  createNetworkSettingsViaDevice,
  goToDateTimeSettingsStep,
  goToNextStep,
  goToStep,
  setDateTimeSettings,
  setFlockSettings,
  setFlockWeights,
  setGeneralSettings,
  setHouseSizesSettings,
  updateLanSettings,
  setQuickstartStatusStep,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { IGeneralSettingsView } from '../../interfaces/general-settings-view.interface';
import { IDateTimeSettingsView } from '../../interfaces/date-time-settings-view.interface';
import { INetworkSettingsView } from '../../interfaces/network-settings-view.interface';
import {
  selectAssigningToFarmInProgress,
  selectControllerVentilationMode,
  selectCurrentStep,
  selectDateTimeSettings,
  selectDefaultFlockWeights,
  selectFlockSettings,
  selectGeneralSettings,
  selectHouseSizesSettings,
  selectIsControllerAssignedWithFarm,
  selectIsLoading,
  selectLengthUnit,
  selectNetworkConnectionInProgress,
  selectNetworkSettings,
  selectTicketView,
  selectWeightUnit,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import { filter, firstValueFrom, Observable, Subscription } from 'rxjs';
import { IHouseSizesView } from '../../interfaces/house-sizes-view.interface';
import {
  ControllerLanguageEnum,
  ControllerUnitEnum,
  QuickStartStatusEnum,
  QuickStartStepsEnum,
} from '@livestock/controllers/enums';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import {
  ControllerDateTimeSettingsFormComponent,
} from '../../ui/date-time-settings-form/date-time-settings-form.component';
import { ControllerSettingsStubFormComponent } from '../../ui/settings-stub-form/settings-stub-form.component';
import {
  ControllerHouseSizesSettingsFormComponent,
} from '../../ui/house-sizes-settings-form/house-sizes-settings-form.component';
import { CloudPageComponent } from '../../ui/cloud-page/cloud-page.component';
import {
  ButtonIconWizardEnum,
  ButtonTypeEnum,
  ButtonWizardTypeEnum,
  DialogButtonEnum,
  IconsEnum,
  LengthUnitEnum,
  StorageItem,
  TemperatureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { TranslateModule } from '@ngx-translate/core';
import { AppRoutes } from '@livestock/shared/routes';
import { Router } from '@angular/router';
import { DialogsService, LocalStorageService, PlatformService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { QuickStartStepType } from '@livestock/controllers/interfaces';
import { FlockSettingsFormComponent } from '../../ui/flock-settings-form/flock-settings-form.component';
import {
  IFlockDefaultWeightView,
  IFlockHouseModeView,
  IFlockSettingsView,
  selectFlockHouseMode,
  setFlockHouseMode,
  VentilationWorkingModeEnum,
} from '@livestock/flock';
import { ConvertHelper } from '@livestock/shared/utils';
import { ControllerNetworkSettingsFormComponent } from '../../ui/network-settings-form/network-settings-form.component';
import { ControllerHouseModesFormComponent } from '../../ui/house-modes-form/house-modes-form.component';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    StepperComponent,
    ButtonComponent,
    ControllerGeneralSettingsFormComponent,
    ControllerDateTimeSettingsFormComponent,
    ControllerSettingsStubFormComponent,
    ControllerHouseSizesSettingsFormComponent,
    ControllerNetworkSettingsFormComponent,
    CloudPageComponent,
    TranslateModule,
    SvgIconComponent,
    ToggleComponent,
    MemoizeFuncPipe,
    InputIntegerComponent,
    ButtonWizardComponent,
    SlimButtonComponent,
    FlockSettingsFormComponent,
    ControllerHouseModesFormComponent,
    LoadingGalconComponent,
  ],
  selector: 'ls-quick-start-container',
  templateUrl: './quick-start-container.component.html',
  styleUrls: ['./quick-start-container.component.scss'],
})
export class QuickStartContainerComponent implements OnInit, OnDestroy {
  // components variables
  generalSettings: IGeneralSettingsView;
  dateTimeSettings: IDateTimeSettingsView;
  networkSettings: INetworkSettingsView;
  houseSizesSettings: IHouseSizesView;
  flockSettings: IFlockSettingsView;
  flockHouseMode: IFlockHouseModeView;
  defaultWeights: IFlockDefaultWeightView[];

  defaultFootHouseSettings: IHouseSizesView = {
    houseWidth: GlobalConstants.MinFootWidthHeightLength,
    houseLength: GlobalConstants.MinFootWidthHeightLength,
    houseHeight: GlobalConstants.MinFootWidthHeightLength,
    houseRoofHeight: GlobalConstants.MinFootWidthHeightLength,
    houseNumber: 0,
  };
  defaultMeterHouseSettings: IHouseSizesView = {
    houseWidth: GlobalConstants.MinMeterWidthHeightLength,
    houseLength: GlobalConstants.MinMeterWidthHeightLength,
    houseHeight: GlobalConstants.MinMeterWidthHeightLength,
    houseRoofHeight: GlobalConstants.MinMeterWidthHeightLength,
    houseNumber: 0,
  };

  flowIsFinished: boolean;
  deviceLogic: boolean;
  steps: QuickStartStepType[] = [
    {
      title: 'Controller.QuickStart.General',
      icon: IconsEnum.QS_COG,
      type: ButtonWizardTypeEnum.ON,
      tab: QuickStartStepsEnum.GeneralSettings,
      couldBeVisited: true,
    },
    {
      title: 'Controller.QuickStart.House',
      icon: IconsEnum.QS_HOUSE,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.HouseSettings,
    },
    {
      title: 'Controller.QuickStart.Network',
      icon: IconsEnum.QS_NETWORK,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.NetworkSettings,
    },
    {
      title: 'Controller.QuickStart.DateTime',
      icon: IconsEnum.QS_DATE_TIME,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.DateTimeSettings,
    },
    {
      title: 'Controller.QuickStart.Cloud',
      icon: IconsEnum.QS_CLOUD,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.CloudSettings,
    },
    {
      title: 'Controller.QuickStart.Flock',
      icon: IconsEnum.QS_FLOCK,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.FlockSettings,
    },
    {
      title: 'Controller.QuickStart.HouseMode',
      icon: IconsEnum.QS_HOUSE_MODE,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.HouseModeSettings,
    },
    {
      title: 'Controller.QuickStart.Connections',
      icon: IconsEnum.QS_CONNECTIONS,
      type: ButtonWizardTypeEnum.OFF,
      tab: QuickStartStepsEnum.ConnectionsSettings,
    },
  ];
  houseSizesValid: boolean = false;
  houseModesValid: boolean = false;
  dateTimeSettingsValid: boolean = false;

  flockSettingsValid: boolean = true;
  flockWeightValid: boolean = true;

  // subs
  sub$ = new Subscription();
  lengthUnit$: Observable<LengthUnitEnum> = this.store.select(selectLengthUnit);
  weightUnit$: Observable<WeightUnitEnum> = this.store.select(selectWeightUnit);
  ventilationWorkingMode$: Observable<VentilationWorkingModeEnum> = this.store.select(selectControllerVentilationMode);
  ticketView$ = this.store.select(selectTicketView);
  isControllerAssignedWithFarm$ = this.store.select(
    selectIsControllerAssignedWithFarm,
  );
  currentStep$: Observable<QuickStartStepsEnum> = this.store.select(selectCurrentStep);
  assigningToFarmInProgress$: Observable<boolean> = this.store.select(selectAssigningToFarmInProgress);
  connectionInProgress$: Observable<boolean> = this.store.select(selectNetworkConnectionInProgress);
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  // enums
  QuickStartStepsEnum = QuickStartStepsEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ButtonWizardTypeEnum = ButtonWizardTypeEnum;
  ButtonIconWizardEnum = ButtonIconWizardEnum;
  ButtonTypeEnum = ButtonTypeEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private router: Router,
    private dialogsService: DialogsService,
  ) {
    LocalStorageService.setStorageItem(StorageItem.ControllerLang, ControllerLanguageEnum.EngUS);
  }

  ngOnInit(): void {
    this.store.dispatch(clearUpsertControllerState());

    this.sub$.add(
      this.store
        .select(selectGeneralSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((generalSettings) => {
          const { _actualUnits, ...settings } = generalSettings;
          this.generalSettings = settings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectDateTimeSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((dateTimeSettings) => {
          this.dateTimeSettings = dateTimeSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectNetworkSettings)
        .pipe(filter(val => val != null))
        .subscribe((networkSettings) => {
          this.networkSettings = networkSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectHouseSizesSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((houseSizesSettings) => {
          this.houseSizesSettings = houseSizesSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectFlockSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((flockSettings) => {
          this.flockSettings = flockSettings;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectDefaultFlockWeights)
        .pipe(wasChangedAndNotNull())
        .subscribe((defaultWeights) => {
          this.defaultWeights = defaultWeights;
        }),
    );

    this.sub$.add(
      this.store
        .select(selectFlockHouseMode)
        .pipe(wasChangedAndNotNull())
        .subscribe((flockHouseMode) => {
          this.flockHouseMode = flockHouseMode;
        }),
    );

    this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
  }

  async onStepClick(tab: QuickStartStepsEnum): Promise<void> {
    const currentStep = await firstValueFrom(this.currentStep$);
    if (currentStep === tab) return;

    await this.recalculateValuesByUnits(currentStep);

    const step = this.steps.find(step => step.tab === tab);

    if (step?.couldBeVisited) {
      const currentStepInfo = this.steps.find(step => step.tab === currentStep);
      currentStepInfo.type = currentStepInfo.type === ButtonWizardTypeEnum.ERROR ? ButtonWizardTypeEnum.ERROR : ButtonWizardTypeEnum.DONE;

      this.goToStep(tab);
    }
  }

  getStepTitle(tab: QuickStartStepsEnum): string {
    const currentStep = this.steps.find(step => step.tab === tab);
    return currentStep?.title;
  }

  goToStep(tab: QuickStartStepsEnum): void {
    const currentStep = this.steps.find(step => step.tab === tab);
    if (currentStep.type !== ButtonWizardTypeEnum.ERROR) currentStep.type = ButtonWizardTypeEnum.DONE;
    this.store.dispatch(goToStep({ payload: tab }));
  }

  changedGeneralSettings(event: { formValues: any; isValid: boolean }): void {
    if (!event.isValid) {
      this.steps.find(step => step.tab === QuickStartStepsEnum.GeneralSettings).type = ButtonWizardTypeEnum.ERROR;
    }
    this.store.dispatch(
      setGeneralSettings({ generalSettings: event.formValues }),
    );
  }

  // TODO: maybe refactor in 0.18.3+, but behaviour is a little bit different here
  async changedDateTimeSettings(event: { formValues: any; isValid: boolean, dateTimeIsDirty: boolean }): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    const dateTimeSettingsStep = this.steps.find(step => step.tab === QuickStartStepsEnum.DateTimeSettings);

    this.dateTimeSettingsValid = event.isValid;

    if (!event.isValid && !event.dateTimeIsDirty) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.ERROR;
    }

    if (event.isValid && currStep === QuickStartStepsEnum.DateTimeSettings) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.ON;
    }

    if (event.isValid && currStep !== QuickStartStepsEnum.DateTimeSettings) {
      dateTimeSettingsStep.type = ButtonWizardTypeEnum.DONE;
    }

    this.store.dispatch(setDateTimeSettings({ dateTimeSettings: event.formValues }));
  }

  changedNetworkSettings(event: { formValues: INetworkSettingsView; isValid: boolean }): void {
    if (event.isValid) {
      if (event.formValues.lan !== this.networkSettings.lan) {
        if (this.platformService.isDeviceApp && this.deviceLogic) {
          this.store.dispatch(updateLanSettings({ lan: event.formValues.lan }));
        }
      }
      // this.store.dispatch(
      //   setNetworkSettings({ networkSettings: event.formValues }),
      // );
    }
  }

  async changedFlockSettings(event: { formValues: any; isValid: boolean }): Promise<void> {
    await this.updateStepStatus(
      QuickStartStepsEnum.FlockSettings,
      'flockSettingsValid',
      event.isValid,
      () => this.flockSettingsValid && this.flockWeightValid,
    );

    this.store.dispatch(setFlockSettings({ flockSettings: event.formValues }));
  }

  async changedFlockWeights(event: { formValues: any; isValid: boolean }): Promise<void> {
    await this.updateStepStatus(
      QuickStartStepsEnum.FlockSettings,
      'flockWeightValid',
      event.isValid,
      () => this.flockSettingsValid && this.flockWeightValid,
    );

    this.store.dispatch(setFlockWeights({ flockWeights: event.formValues }));
  }

  async changedFlockHouseModes(event: { formValues: IFlockHouseModeView; isValid: boolean }): Promise<void> {
    await this.updateStepStatus(
      QuickStartStepsEnum.HouseModeSettings,
      'houseModesValid',
      event.isValid,
      () => this.houseModesValid,
    );
    this.store.dispatch(setFlockHouseMode({ houseMode: event.formValues }));
  }

  async changedHouseSizesSettings(event: {
    formValues: any;
    isValid: boolean;
  }): Promise<void> {
    await this.updateStepStatus(
      QuickStartStepsEnum.HouseSettings,
      'houseSizesValid',
      event.isValid,
      () => this.houseSizesValid,
    );

    this.store.dispatch(
      setHouseSizesSettings({ houseSizesSettings: event.formValues }),
    );
  }

  async updateStepStatus(stepToMatch: QuickStartStepsEnum, validPropName: string, isValid: boolean, validityCondition: () => boolean): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    const step = this.steps.find(step => step.tab === stepToMatch);
    this[validPropName] = isValid;

    if (!this[validPropName]) step.type = ButtonWizardTypeEnum.ERROR;
    if (validityCondition() && currStep === step.tab) {
      step.type = ButtonWizardTypeEnum.ON;
    }
    if (validityCondition() && currStep !== step.tab) {
      step.type = ButtonWizardTypeEnum.DONE;
    }
  }

  handleCurrentStep(currentStep: QuickStartStepsEnum): void {
    switch (currentStep) {
      case QuickStartStepsEnum.GeneralSettings:
        this.store.dispatch(createGeneralSettingsViaDevice());
        break;
      case QuickStartStepsEnum.HouseSettings:
        this.store.dispatch(createHouseSizesViaDevice({ currentUnit: this.getCurrentLengthUnit(this.generalSettings) }));
        break;
      case QuickStartStepsEnum.NetworkSettings:
        //this.store.dispatch(getTicketInfoFromDevice());
        //this.store.dispatch(createNetworkSettingsViaDevice());
        break;
      case QuickStartStepsEnum.DateTimeSettings:
        this.store.dispatch(createDateTimeSettingsViaDevice({ isFinish: false }));
        break;
      case QuickStartStepsEnum.FlockSettings:
        this.store.dispatch(createFlockSettingsViaDevice());
        break;
      case QuickStartStepsEnum.HouseModeSettings:
        this.store.dispatch(createHouseModesViaDevice({ currentTempUnit: this.getCurrentTemperatureUnit(this.generalSettings) }));
        break;
      default:
        break;
    }
  }

  async recalculateValuesByUnits(currStep: QuickStartStepsEnum): Promise<void> {
    if (currStep === QuickStartStepsEnum.HouseSettings && this.getCurrentLengthUnit(this.generalSettings) === LengthUnitEnum.Foot) {
      const houseSize = { ...this.houseSizesSettings };
      houseSize.houseHeight = ConvertHelper.metersToFeet(ConvertHelper.feetToMeters(houseSize.houseHeight, 1), 1);
      houseSize.houseLength = ConvertHelper.metersToFeet(ConvertHelper.feetToMeters(houseSize.houseLength, 1), 1);
      houseSize.houseRoofHeight = ConvertHelper.metersToFeet(ConvertHelper.feetToMeters(houseSize.houseRoofHeight, 1), 1);
      houseSize.houseWidth = ConvertHelper.metersToFeet(ConvertHelper.feetToMeters(houseSize.houseWidth, 1), 1);
      await this.changedHouseSizesSettings({ formValues: houseSize, isValid: this.houseSizesValid });
    }
    if (currStep === QuickStartStepsEnum.HouseModeSettings && this.getCurrentTemperatureUnit(this.generalSettings) === TemperatureUnitEnum.Fahrenheit) {
      const houseModes = { ...this.flockHouseMode };
      houseModes.targetTemperatureCatching = ConvertHelper.celsiusToFahrenheit(ConvertHelper.fahrenheitToCelsius(houseModes.targetTemperatureCatching, 1), 1);
      houseModes.targetTemperatureCleaning = ConvertHelper.celsiusToFahrenheit(ConvertHelper.fahrenheitToCelsius(houseModes.targetTemperatureCleaning, 1), 1);
      houseModes.targetTemperatureEmpty = ConvertHelper.celsiusToFahrenheit(ConvertHelper.fahrenheitToCelsius(houseModes.targetTemperatureEmpty, 1), 1);
      houseModes.targetTemperaturePreheat = ConvertHelper.celsiusToFahrenheit(ConvertHelper.fahrenheitToCelsius(houseModes.targetTemperaturePreheat, 1), 1);
      await this.changedFlockHouseModes({ formValues: houseModes, isValid: this.houseModesValid });
    }
  }

  async back(): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);

    // TODO: Check after rest of settings implemented
    let prevStep: QuickStartStepsEnum = null;
    switch (currStep) {
      case QuickStartStepsEnum.ConnectionsSettings:
        prevStep = QuickStartStepsEnum.HouseModeSettings;
        break;
      case QuickStartStepsEnum.HouseModeSettings:
        prevStep = QuickStartStepsEnum.FlockSettings;
        break;
      case QuickStartStepsEnum.FlockSettings:
        prevStep = QuickStartStepsEnum.CloudSettings;
        break;
      case QuickStartStepsEnum.CloudSettings:
        prevStep = QuickStartStepsEnum.DateTimeSettings;
        break;
      case QuickStartStepsEnum.DateTimeSettings:
        prevStep = QuickStartStepsEnum.NetworkSettings;
        break;
      case QuickStartStepsEnum.NetworkSettings:
        prevStep = QuickStartStepsEnum.HouseSettings;
        break;
      case QuickStartStepsEnum.HouseSettings:
        prevStep = QuickStartStepsEnum.GeneralSettings;
        break;
      default:
        prevStep = currStep;
        break;
    }

    await this.recalculateValuesByUnits(currStep);
    const currentStepIndex = this.steps.findIndex(step => step.tab === currStep);
    if (currentStepIndex > -1 && this.steps[currentStepIndex].type !== ButtonWizardTypeEnum.ERROR) {
      this.steps[currentStepIndex].type = ButtonWizardTypeEnum.DONE;
    }

    this.store.dispatch(goToStep({ payload: prevStep }));
  }

  async next(): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    if (this.platformService.isDeviceApp && this.deviceLogic) {
      this.handleCurrentStep(currStep);
    }

    const currentStepIndex = this.steps.findIndex(step => step.tab === currStep);
    if (currentStepIndex > -1) {
      const nextStep = this.steps[currentStepIndex + 1];

      if (nextStep) nextStep.couldBeVisited = true;
      await this.recalculateValuesByUnits(currStep);

      this.steps[currentStepIndex].type = ButtonWizardTypeEnum.DONE;
    }

    /*if (!this.deviceLogic) {
      currStep === QuickStartStepsEnum.NetworkSettings
        ? this.store.dispatch(createTicketController())
        : this.store.dispatch(goToNextStep());
    }*/
    this.store.dispatch(goToNextStep());
  }

  // if user clicks FINISH on Date&Time Settings step
  async finish(): Promise<void> {
    const currStep = await firstValueFrom(this.currentStep$);
    if (currStep !== QuickStartStepsEnum.DateTimeSettings) return;

    this.store.dispatch(createDateTimeSettingsViaDevice({ isFinish: true }));
    this.store.dispatch(setQuickstartStatusStep({ step: QuickStartStatusEnum.Done }));
  }

  // if user clicks FINISH on Network Settings step
  // TODO: UPD
  async finishOnNetworkSettings(): Promise<void> {
    // return user to step #3 if he didn't choose any network and dateTimeSettings were automatic
    if (!this.dateTimeSettings.autoDateTimeType) {
      const message = 'Controller.QuickStart.NoNetworkConnectionYouNeedToSetDateAndTimeManually';
      const title = 'Controller.QuickStart.NetworkConnectionFailed';
      await this.dialogsService.error(
        message, title, 'flash/cross', [DialogButtonEnum.SET_DATE_AND_TIME], false,
      );
      this.store.dispatch(goToDateTimeSettingsStep());
      return;
    }

    if (this.platformService.isDeviceApp && this.deviceLogic) {
      this.store.dispatch(createNetworkSettingsViaDevice());
    }

    // TODO: set real controllerID when all backend stuff will be ready
    if (this.platformService.isDeviceApp) {
      LocalStorageService.setStorageItem(StorageItem.CheckNewFlockControllerID, GlobalConstants.HardCodedControllerIDForDevice);
    }
    this.store.dispatch(setQuickstartStatusStep({ step: QuickStartStatusEnum.Done }));
    this.flowIsFinished = true;
  }

  goToDashboard(needToSetStatus?: boolean): void {
    this.router.navigateByUrl(AppRoutes.DASHBOARD);
    if (needToSetStatus) {
      this.store.dispatch(setQuickstartStatusStep({ step: QuickStartStatusEnum.Done }));
    }
  }

  goToInstallation(): void {
    this.router.navigateByUrl(AppRoutes.INSTALLATION);
  }

  toggleDeviceLogic(deviceLogic: boolean): void {
    this.deviceLogic = deviceLogic;
  }

  houseSizesWereChanged(lengthUnit: LengthUnitEnum): boolean {
    const houseSizesRaw: IHouseSizesView = JSON.parse(JSON.stringify(this.houseSizesSettings));
    delete houseSizesRaw.controllerID;
    const defaultHouseSettings = lengthUnit === LengthUnitEnum.Foot
      ? this.defaultFootHouseSettings
      : this.defaultMeterHouseSettings;

    return JSON.stringify(houseSizesRaw) !== JSON.stringify(defaultHouseSettings);
  }

  areNetworkSettingsValid(networkSettings: INetworkSettingsView): boolean {
    return !!networkSettings.lan
      || !!networkSettings.wifi && !!networkSettings.wifiName && !!networkSettings.wifiPassword
      || !!networkSettings.modem && !!networkSettings.modemAPN && !!networkSettings.modemUser && !!networkSettings.modemPassword;
  }

  anyStepInError(): boolean {
    return this.steps.some(it => it.type === ButtonWizardTypeEnum.ERROR);
  }

  getCurrentLengthUnit(generalSettings: IGeneralSettingsView): LengthUnitEnum {
    return (
      generalSettings.units === ControllerUnitEnum.Imperial ||
      (generalSettings.units === ControllerUnitEnum.Custom &&
        generalSettings.unitsDetails.length === LengthUnitEnum.Foot)
    ) ? LengthUnitEnum.Foot : LengthUnitEnum.Meter;
  }

  getCurrentTemperatureUnit(generalSettings: IGeneralSettingsView): TemperatureUnitEnum {
    switch (generalSettings.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      case ControllerUnitEnum.Custom:
      default:
        return generalSettings.unitsDetails?.temperature;
    }
  }

  ngOnDestroy(): void {
    LocalStorageService.setStorageItem(StorageItem.ControllerLang, ControllerLanguageEnum.EngUS);
    this.sub$.unsubscribe();
  }
}
