import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  InputComponent,
  InputTextComponent,
  KeyboardComponent,
  LoadingLinesComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { Store } from '@ngrx/store';
import { WifiPasswordDialogComponent } from '../wifi-password-dialog/wifi-password-dialog.component';
import { lastValueFrom } from 'rxjs';
import { IWifiSettings } from '@livestock/controllers/interfaces';

@Component({
  selector: 'ls-wifi-password-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    SvgIconComponent,
    KeyboardComponent,
    ReactiveFormsModule,
    InputTextComponent,
    LoadingLinesComponent,
  ],
  templateUrl: './wifi-list-dialog.component.html',
  styleUrls: ['./wifi-list-dialog.component.scss'],
})
export class WifiListDialogComponent {
  isLoading: boolean;
  wifiList: string[] = Array.from({ length: 15 }).map((item, index) => {
    return `Wifi Mockup ${index + 1}`;
  });

  constructor(
    public dialog: MatDialog,
    public dialogRef: MatDialogRef<WifiListDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    private store: Store,
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  async chooseWifi(wifiName: string): Promise<void> {
    const dialog = this.dialog.open(WifiPasswordDialogComponent, {
      width: '500px',
      height: '344px',
      disableClose: true,
      data: {
        wifiName,
      },
    });

    const res: Partial<IWifiSettings> = await lastValueFrom(dialog.afterClosed());
    if (res) {
      this.dialogRef.close({
        wifi: 0,
        wifiName,
        ...res,
      });
      return;
    }
  }
}
