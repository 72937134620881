import { Component } from '@angular/core';
import { RouterLink } from '@angular/router';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { MenuService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  selector: 'ls-display-top-menu',
  templateUrl: 'display-top-menu.component.html',
  imports: [
    RouterLink,
    CommonModule,
    SvgIconComponent,
    QaTagsDirective,
  ],
  styleUrls: ['display-top-menu.component.scss'],
})
export class DisplayTopMenuComponent {
  constructor(public menuService: MenuService) {
  }

  // TODO: temp solution for qa;
  reset(): void {
    location.reload();
  }

}
