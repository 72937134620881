import { createFeatureSelector, createSelector } from '@ngrx/store';
import { CurrentControllerState } from './current-controller.state';
import { CURRENT_CONTROLLER_FEATURE_KEY } from './current-controller.reducers';
import { LocalStorageService } from '@livestock/shared/services';
import { ControllerUnitEnum } from '@livestock/controllers/enums';
import {
  AirPressureUnitEnum,
  LengthUnitEnum,
  StorageItem,
  TemperatureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { HoursFormatTypeEnum } from '@livestock/controllers/enums';

export const getCurrentControllerState =
  createFeatureSelector<CurrentControllerState>(CURRENT_CONTROLLER_FEATURE_KEY);

export const selectControllerInfo = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo,
);

export const selectHourFormat = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => {
    return controllerInfo?.hoursFormat;
  },
);

export const selectHourFormatForTimePicker = createSelector(
  selectHourFormat,
  (hoursFormat) => {
    return hoursFormat === HoursFormatTypeEnum.AmPm;
  },
);

export const selectCurrentControllerUnits = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => {
    if (!controllerInfo) return null;

    return {
      units: controllerInfo.units,
      unitsDetails: controllerInfo.unitsDetails,
    };
  },
);

export const selectActiveControllerID = createSelector(
  getCurrentControllerState,
  ({ activeControllerID }) => {
    return activeControllerID ||
      (LocalStorageService.getStorageItem(StorageItem.ActiveControllerID)
        ? Number(LocalStorageService.getStorageItem(StorageItem.ActiveControllerID))
        : null);
  },
);

export const selectActiveControllerSerialNumber = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.sn,
);

export const selectActiveControllerFarmName = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.farmName,
);

export const selectCurrentControllerLengthUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return LengthUnitEnum.Meter;
      case ControllerUnitEnum.Imperial:
        return LengthUnitEnum.Foot;
      default:
        return controllerInfo?.unitsDetails?.length;
    }
  },
);

export const selectCurrentControllerTemperatureUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return TemperatureUnitEnum.Celsius;
      case ControllerUnitEnum.Imperial:
        return TemperatureUnitEnum.Fahrenheit;
      default:
        return controllerInfo?.unitsDetails?.temperature;
    }
  },
);

export const selectCurrentControllerWeightUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Gram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return controllerInfo?.unitsDetails?.weight;
    }
  },
);

export const selectCurrentControllerPressureUnit = createSelector(
  selectControllerInfo,
  (controllerInfo) => {
    switch (controllerInfo?.units) {
      case ControllerUnitEnum.Metric:
        return AirPressureUnitEnum.Pascal;
      case ControllerUnitEnum.Imperial:
        return AirPressureUnitEnum.InchesOfWater;
      default:
        return controllerInfo?.unitsDetails?.airPressure;
    }
  },
);

export const selectCurrentControllerTimeFormat = createSelector(
  selectControllerInfo,
  (controllerInfo) => controllerInfo.hoursFormat,
);

export const selectControllerLanguage = createSelector(
  getCurrentControllerState,
  ({ controllerLanguage }) => controllerLanguage,
);

export const selectCurrentControllerHouseNumber = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.houseNumber,
);

export const selectCurrentControllerIsLoading = createSelector(
  getCurrentControllerState,
  ({ isLoading }) => isLoading,
);

export const selectCurrentControllerIsDisconnected = createSelector(
  getCurrentControllerState,
  ({ isDisconnected }) => isDisconnected,
);

export const selectFlockIsDefault = createSelector(
  getCurrentControllerState,
  ({ controllerInfo }) => controllerInfo?.isDefaultFlock,
);
