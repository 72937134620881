import { Component, Input } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import {
  ButtonIconPositionEnum,
  ColorsEnum,
  ButtonTypeEnum,
  ButtonIconWizardEnum,
  IconsEnum,
} from '@livestock/shared/enums';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';

@Component({
  selector: 'lv-button',
  templateUrl: './button-slim.component.html',
  styleUrls: ['./button-slim.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    CommonModule,
    SvgIconComponent,
  ],
})

export class SlimButtonComponent {
  @Input() type: ButtonTypeEnum = ButtonTypeEnum.SECONDARY;
  @Input() iconPath: string;
  @Input() iconPosition: ButtonIconPositionEnum = ButtonIconPositionEnum.LEFT;
  @Input() iconWizard: ButtonIconWizardEnum = ButtonIconWizardEnum.NONE;
  @Input() qaTags: string;

  ColorsEnum = ColorsEnum;
  ButtonIconPositionEnum = ButtonIconPositionEnum;
  ButtonIconWizardEnum = ButtonIconWizardEnum;
  IconsEnum = IconsEnum;
}
