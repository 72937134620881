import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ElementOperationEnum } from 'libs/installation/src/lib/enums/element/element-operation.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { keyboardUpdateFields } from '@livestock/ui';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '../../../../interfaces/element/get-or-update-element.interface';
import {
  getVentilationBrands,
  getVentilationModels,
  IVentModelDOItem,
  selectVentilationDOModelByID,
  VentilationBrandDialogComponent,
} from '@livestock/ventilation-brands';
import { ColorsEnum } from '@livestock/shared/enums';
import { lastValueFrom, Observable } from 'rxjs';
import { PlatformService } from '@livestock/shared/services';
import { InstallationHelpersService } from '../../../../service/installation-helpers.service';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-ventilation-do-form',
  templateUrl: './ventilation-do-form.component.html',
  styleUrls: ['./ventilation-do.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VentilationDoFormComponent extends BasicElementComponent {
  model$: Observable<(modelID: number, brandID: number) => IVentModelDOItem> = this.store.select(selectVentilationDOModelByID);
  airFlowSteps = [5, 10, 15, 20];
  ColorsEnum = ColorsEnum;
  valuesAirFlowValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(ElementsValidationConstants.ventilationDO.airFlow.min),
    Validators.max(ElementsValidationConstants.ventilationDO.airFlow.max),
  ];
  valuesEfficiencyValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(ElementsValidationConstants.ventilationDO.efficiency.min),
    Validators.max(ElementsValidationConstants.ventilationDO.efficiency.max),
  ];
  valuesFormGroup = new FormGroup({
    airFlow5: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow10: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow15: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow20: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    efficiency5: new FormControl<number>(0, [...this.valuesEfficiencyValidators]),
    efficiency10: new FormControl<number>(0, [...this.valuesEfficiencyValidators]),
    efficiency15: new FormControl<number>(0, [...this.valuesEfficiencyValidators]),
    efficiency20: new FormControl<number>(0, [...this.valuesEfficiencyValidators]),
  });

  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
    public dialog: MatDialog,
    public platformService: PlatformService,
    public installationHelpersService: InstallationHelpersService,
    private cdr: ChangeDetectorRef,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.store.dispatch(getVentilationBrands());

    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        name: this.name,
        isMultipleFans: new FormControl<boolean>(false),
        fansNumber: new FormControl<number>(1, [
          Validators.required,
          Validators.min(ElementsValidationConstants.ventilationAO.fansNumber.min),
          Validators.max(ElementsValidationConstants.ventilationAO.fansNumber.max),
        ]),
        operation: new FormControl<ElementOperationEnum>(ElementOperationEnum.NormallyOpen, Validators.required),
        isCustomModel: new FormControl<boolean>(false),
        brandID: new FormControl<number>(null, Validators.required),
        modelID: new FormControl<number>(null, Validators.required),
        values: this.valuesFormGroup,
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      isMultipleFans: setupData.isMultipleFans,
      fansNumber: setupData.fansNumber,
      operation: setupData.operation,
      isCustomModel: setupData.isCustomModel,
      brandID: setupData.brandID,
      modelID: setupData.modelID,
      values: { ...setupData.values || this.parentFormGroup.get(this.formGroupName).value.values },
    });

    if (setupData.brandID) {
      this.store.dispatch(getVentilationModels({ brandID: setupData.brandID, isDO: true }));
    }

    this.validateNumberOfFans(setupData.isMultipleFans, setupData.fansNumber);
    this.validateVentBrandFields(setupData.isCustomModel);
  }

  validateNumberOfFans(isMultipleFans: boolean, numberOfFans?: number): void {
    const control = (this.parentFormGroup.get(this.formGroupName) as FormGroup).controls['fansNumber'];
    control.setValidators([Validators.required, Validators.min(isMultipleFans ? 2 : 1), Validators.max(40)]);
    control.setValue(numberOfFans || (isMultipleFans ? 2 : 1));
    this.parentFormGroup.get(this.formGroupName).updateValueAndValidity();
  }

  async openVentilationBrandsPopup(): Promise<void> {
    const dialogRef = this.dialog.open(VentilationBrandDialogComponent, {
      width: '540px',
      maxWidth: '100%',
      disableClose: true,
    });
    const form = this.parentFormGroup.get(this.formGroupName) as FormGroup;
    const brandIDControl = form.controls['brandID'];
    const modelIDControl = form.controls['modelID'];

    dialogRef.componentInstance.setup(brandIDControl.value, modelIDControl.value);

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (res) {
      brandIDControl.setValue(res.brandID);
      modelIDControl.setValue(res.modelID);
      form.updateValueAndValidity();
      this.cdr.detectChanges();
    }
  }

  validateVentBrandFields(isCustomModel: boolean, unsetValues: boolean = false): void {
    const form = (this.parentFormGroup.get(this.formGroupName) as FormGroup);
    const brandIDControl = form.controls['brandID'];
    const modelIDControl = form.controls['modelID'];
    const valuesControl = form.controls['values'];
    const validator = !isCustomModel ? Validators.required : null;

    brandIDControl.setValidators(validator);
    modelIDControl.setValidators(validator);

    if (unsetValues) {
      brandIDControl.setValue(null);
      modelIDControl.setValue(null);
      valuesControl.patchValue({
        airFlow5: 0,
        airFlow10: 0,
        airFlow15: 0,
        airFlow20: 0,
        efficiency5: 0,
        efficiency10: 0,
        efficiency15: 0,
        efficiency20: 0,
      });
    }

    form.updateValueAndValidity();
    this.store.dispatch(keyboardUpdateFields());
  }
}
