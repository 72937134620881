import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IInvitedUser, IUser } from '@livestock/shared/interfaces';
import { IUserFromInvite } from '../../../../shared/interfaces/users/user-from-invite.interface';
import { IFarmInfo } from '../interfaces/farm-info.interface';
import { ICreateFarm } from '../interfaces/create-farm.interface';
import { IUpdateFarm } from '../interfaces/update-farm.interface';
import { IController } from '../interfaces/controller.interface';
import { IFarm } from '../interfaces/farm.interface';

export const getFarms = createAction(
  '[FARMS] Get farms [...]',
);

export const getFarmsSuccess = createAction(
  '[FARMS] Get farms [SUCCESS]',
  props<{ farms: IFarm[] }>(),
);

export const getFarmsError = createAction(
  '[FARMS] Get farms [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const getFarmById = createAction(
  '[FARMS] Get farm by id [...]',
  props<{ farmID: number; }>(),
);

export const getFarmByIdSuccess = createAction(
  '[FARMS] Get farm by id [SUCCESS]',
  props<{ farm: IFarmInfo; }>(),
);

export const getFarmByIdError = createAction(
  '[FARMS] Get farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const createFarm = createAction(
  '[FARMS] Create farm [...]',
  props<{ farm: ICreateFarm; }>(),
);

export const createFarmSuccess = createAction(
  '[FARMS] Create farm [SUCCESS]',
  props<{ farm: IFarmInfo; }>(),
);

export const createFarmError = createAction(
  '[FARMS] Create farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateFarmById = createAction(
  '[FARMS] Update farm by id [...]',
  props<{ farmID: number; farm: IUpdateFarm; }>(),
);

export const updateFarmByIdSuccess = createAction(
  '[FARMS] Update farm by id [SUCCESS]',
  props<{ farmID: number; farm: IUpdateFarm; }>(),
);

export const updateFarmByIdError = createAction(
  '[FARMS] Update farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const deleteFarmById = createAction(
  '[FARMS] Delete farm by id [...]',
  props<{ farmID: number; }>(),
);

export const deleteFarmByIdSuccess = createAction(
  '[FARMS] Delete farm by id [SUCCESS]',
  props<{ farmID: number; }>(),
);

export const deleteFarmByIdError = createAction(
  '[FARMS] Delete farm by id [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const setActiveFarm = createAction(
  '[FARMS] Set Active Farm [...]',
  props<{ farmID: number; }>(),
);

export const resetActiveFarm = createAction(
  '[FARMS] Reset Active Farm [...]',
);

/*USERS*/
export const getFarmUsers = createAction(
  '[FARMS] Get farm users [...]',
  props<{ farmID: number; }>(),
);

export const getFarmUsersSuccess = createAction(
  '[FARMS] Get farm users [SUCCESS]',
  props<{ farmID: number; users: IUser[] }>(),
);

export const getFarmUsersError = createAction(
  '[FARMS] Get farm users [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const inviteFarmUser = createAction(
  '[FARMS] Invite farm user [...]',
  props<{ invitedUser: IInvitedUser; }>(),
);

export const inviteFarmUserSuccess = createAction(
  '[FARMS] Invite farm user [SUCCESS]',
  props<{ farmID: number; user: IUser; }>(),
);

export const inviteFarmUserError = createAction(
  '[FARMS] Invite farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const resendInviteFarmUser = createAction(
  '[FARMS] Resend Invite farm user [...]',
  props<{ farmID: number; email: string; }>(),
);

export const resendInviteFarmUserSuccess = createAction(
  '[FARMS] Resend Invite farm user [SUCCESS]',
  props<{ farmID: number; email: string; resendDate: string }>(),
);

export const resendInviteFarmUserError = createAction(
  '[FARMS] Resend Invite farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const deleteFarmUser = createAction(
  '[FARMS] Delete farm user [...]',
  props<{ farmID: number; email: string; }>(),
);

export const deleteFarmUserSuccess = createAction(
  '[FARMS] Delete farm user [SUCCESS]',
  props<{ farmID: number; email: string; }>(),
);

export const deleteFarmUserError = createAction(
  '[FARMS] Delete farm user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const updateUser = createAction(
  '[FARMS] Update user [...]',
  props<{ user: IUser }>(),
);

export const updateUserSuccess = createAction(
  '[FARMS] Update user [SUCCESS]',
  props<{ user: IUser }>(),
);

export const updateUserError = createAction(
  '[FARMS] Update user [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

/*HOUSES / CONTROLLERS*/
export const getFarmControllers = createAction(
  '[FARMS] Get farm controllers [...]',
  props<{ farmID: number; }>(),
);

export const getFarmControllersSuccess = createAction(
  '[FARMS] Get farm controllers [SUCCESS]',
  props<{ farmID: number; controllers: IController[]; }>(),
);

export const getFarmControllersError = createAction(
  '[FARMS] Get farm controllers [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

///////////
/*TICKETS*/
export const addNewUserToFarm = createAction(
  '[TICKETS] Add New User To Farm [...]',
  props<{ ticketID: string; }>(),
);

export const addNewUserToFarmSuccess = createAction(
  '[TICKETS] Add New User To Farm [SUCCESS]',
  props<{ farmName: string; }>(),
);

export const addNewUserToFarmError = createAction(
  '[TICKETS] Add New User To Farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const declineExistUserToFarm = createAction(
  '[TICKETS] Decline Exist User To Farm [...]',
  props<{ ticketID: string; }>(),
);

export const declineExistUserToFarmSuccess = createAction(
  '[TICKETS] Decline Exist User To Farm [SUCCESS]',
);

export const declineExistUserToFarmError = createAction(
  '[TICKETS] Decline Exist User To Farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const acceptExistUserToFarm = createAction(
  '[TICKETS] Accept Exist User To Farm [...]',
  props<{ ticketID: string; }>(),
);

export const acceptExistUserToFarmSuccess = createAction(
  '[TICKETS] Accept Exist User To Farm [SUCCESS]',
);

export const acceptExistUserToFarmError = createAction(
  '[TICKETS] Accept Exist User To Farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const executeAddNewUserToFarm = createAction(
  '[TICKETS] Execute Add New Exist User To Farm [...]',
  props<{ userFromInvite: IUserFromInvite; }>(),
);

export const executeAddNewUserToFarmSuccess = createAction(
  '[TICKETS] Execute Add New Exist User To Farm [SUCCESS]',
);

export const executeAddNewUserToFarmError = createAction(
  '[TICKETS] Execute Add New Exist User To Farm [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);

export const removeTicketMessage = createAction(
  '[TICKETS] Remove Ticket Message [...]',
);

export const deleteControllerFromFarm = createAction(
  '[FARMS] Delete controller [...]',
  props<{ controllerID: number }>(),
);

export const deleteControllerFromFarmSuccess = createAction(
  '[FARMS] Delete controller [SUCCESS]',
  props<{ controllerID: number }>(),
);

export const deleteControllerFromFarmError = createAction(
  '[FARMS] Delete controller [ERROR]',
  props<{ payload: HttpErrorResponse; }>(),
);
