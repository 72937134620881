import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent, ButtonWithIconComponent, InputComponent, SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule } from '@angular/forms';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';

@Component({
  selector: 'ls-add-device-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ButtonComponent,
    InputComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    MemoizeFuncPipe,
    SvgIconComponent,
  ],
  templateUrl: './success-add-device-dialog.component.html',
  styleUrls: ['./success-add-device-dialog.component.scss'],
})
export class SuccessAddDeviceDialogComponent {
  connectionNumber: number;

  constructor(
    public dialogRef: MatDialogRef<SuccessAddDeviceDialogComponent>,
    public platformService: PlatformService,
    public languageService: LanguageService,
    @Inject(MAT_DIALOG_DATA) public data: {
      connectionNumber: number,
      currentHouseNumber: number,
      farmName: string,
    },
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

}
