import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetHeatingProgramView, IHeatingProgramView } from '@livestock/heating-program';

export const getHeatingPrograms = createAction(
    '[HEATING PROGRAM] Get Heating Programs [...]',
    props<{ controllerID: number }>(),
);

export const getHeatingProgramsSuccess = createAction(
    '[HEATING PROGRAM] Get Heating Programs [SUCCESS]',
    props<{ view: IGetHeatingProgramView; }>(),
);

export const getHeatingProgramsError = createAction(
    '[HEATING PROGRAM] Get Heating Programs [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const setHeatingPrograms = createAction(
    '[HEATING PROGRAM] Set Heating Programs [...]',
    props<{ programs: IHeatingProgramView[]; }>(),
);

export const updateHeatingPrograms = createAction(
    '[HEATING PROGRAM] Update Heating Programs [...]',
    props<{ controllerID: number }>(),
);

export const updateHeatingProgramsSuccess = createAction(
    '[HEATING PROGRAM] Update Heating Programs [SUCCESS]',
    props<{ updatedPrograms: IHeatingProgramView[] }>(),
);

export const updateHeatingProgramsError = createAction(
    '[HEATING PROGRAM] Update Heating Programs [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const deleteHeatingProgram = createAction(
    '[HEATING PROGRAM] Delete Heating Program [...]',
    props<{ controllerID: number; programID: number }>(),
);

export const deleteHeatingProgramSuccess = createAction(
    '[HEATING PROGRAM] Delete Heating Program [SUCCESS]',
    props<{ programID: number }>(),
);

export const deleteHeatingProgramError = createAction(
    '[HEATING PROGRAM] Delete Heating Program [ERROR]',
    props<{ payload: HttpErrorResponse }>(),
);

export const clearHeatingProgramsState = createAction(
    '[HEATING PROGRAM] Clear Heating Programs State [...]',
);