import { Component, EventEmitter, Input, Output } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';
import { HeaderTitleComponent } from '../header-title/header-title.component';
import { PlatformService } from '@livestock/shared/services';
import { LoadingComponent } from '../../loading/loading.component';

@Component({
  selector: 'ls-page-wrapper',
  templateUrl: './page-wrapper.component.html',
  styleUrls: ['./page-wrapper.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HeaderTitleComponent,
    LoadingComponent,
  ],
})

export class PageWrapperComponent {
  @Input() title: string;
  @Input() hasArrow: boolean;
  @Input() isLoading: boolean;
  @Input() isWhiteBackground: boolean;
  @Output() back = new EventEmitter<void>();

  cancel(): void {
    this.back.emit();
  }

  constructor(
    public platformService: PlatformService,
  ) {
  }
}

