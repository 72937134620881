import { Moment } from 'moment';
import { ControllerUnitEnum, HoursFormatTypeEnum } from '@livestock/controllers/enums';
import { IUnitsDetails } from './units-details.interface';
import { TimeZonesEnum } from '@livestock/shared/constants';

export interface IControllerView {
  controllerID: number;
  sn: string;
  connectedDate: number;
  connectedByUserID: number;
  connectedByUserEmail: string;
  farmID: number;
  farmName: string;
  units: ControllerUnitEnum;
  unitsDetails: IUnitsDetails;
  manuallyDateTime: Moment;
  hoursFormat: HoursFormatTypeEnum;
  houseNumber: number;
  timeZoneID: TimeZonesEnum;
  isDefaultFlock: boolean;
}
