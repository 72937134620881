import { Action, createReducer, on } from '@ngrx/store';
import * as temperatureCurveActions from './temperature-curve.actions';
import { TemperatureCurveState } from './temperature-curve.state';

export const initialState: TemperatureCurveState = {
  isLoading: false,
  view: null,
  originalView: null,
  settings: null,
  originalSettings: null,
};

const reducer = createReducer(
  initialState,
  on(temperatureCurveActions.getTemperatureCurve, temperatureCurveActions.updateTemperatureCurve, (state) => {
    return {
      ...state,
      isLoading: true,
    };
  }),
  on(temperatureCurveActions.getTemperatureCurveError, temperatureCurveActions.updateTemperatureCurveError, (state) => {
    return {
      ...state,
      isLoading: false,
    };
  }),
  on(
    temperatureCurveActions.getTemperatureCurveSuccess,
    temperatureCurveActions.updateTemperatureCurveSuccess,
    (state, { view }) => {
      return {
        ...state,
        view,
        originalView: view,
        isLoading: false,
      };
    },
  ),
  on(
    temperatureCurveActions.getTemperatureCurveSettings,
    temperatureCurveActions.updateTemperatureCurveSettings,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    temperatureCurveActions.getTemperatureCurveSettingsError,
    temperatureCurveActions.updateTemperatureCurveSettingsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    temperatureCurveActions.getTemperatureCurveSettingsSuccess,
    temperatureCurveActions.updateTemperatureCurveSettingsSuccess,
    (state, { view: settings }) => {
      return {
        ...state,
        settings,
        originalSettings: settings,
        isLoading: false,
      };
    },
  ),
  on(temperatureCurveActions.clearTemperatureCurveState, () => {
    return {
      ...initialState,
    };
  }),
);

export function temperatureCurveReducer(state: TemperatureCurveState | undefined, action: Action): any {
  return reducer(state, action);
}
