<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Temperature</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content ph-20 ph-0-xl">
      <section class="d-none-mobile">
        <table class="w-100-percent with-border-radius fz-16 text-center with-light-border td-padding-8 mb-20 mb-10-xl">
          <thead class="cell-secondary color-secondary">
            <tr>
              <th>Temp 1</th>
              <th>Temp 2</th>
              <th>Temp 3</th>
              <th>Temp 4</th>
              <th>Temp 5</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>24<span class="fz-12">°C</span></td>
              <td>23.9<span class="fz-12">°C</span></td>
              <td>23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td>2<span class="fz-12">°C</span></td>
            </tr>
          </tbody>
        </table>

        <table
          class="w-100-percent with-border-radius fz-16 text-center with-light-border td-padding-8 td-equal-5 mb-20 mb-10-xl">
          <thead class="cell-secondary color-secondary">
            <tr>
              <th>Temp 6</th>
              <th>Temp 7</th>
              <th>Temp 8</th>
              <th>Temp 9</th>
              <th>Temp 10</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>30<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
              <td>2<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
            </tr>
          </tbody>
        </table>

        <table
          class="w-100-percent with-border-radius fz-16 text-center with-light-border td-padding-8 td-equal-5 mb-20 mb-10-xl">
          <thead class="cell-secondary color-secondary">
            <tr>
              <th>Temp 11</th>
              <th>Temp 12</th>
              <th>Temp 13</th>
              <th>Temp 14</th>
              <th>Temp 15</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="color-disabled">-</td>
              <td>30<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
            </tr>
          </tbody>
        </table>

        <table
          class="w-100-percent with-border-radius fz-16 text-center with-light-border td-padding-8 td-equal-5 mb-30 mb-10-xl">
          <thead class="cell-secondary color-secondary">
            <tr>
              <th>Temp 16</th>
              <th>Temp 17</th>
              <th>Temp 18</th>
              <th>Temp 19</th>
              <th>Temp 20</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="color-disabled">-</td>
              <td>30<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section class="d-none d-block-mobile table-mobile">
        <table
          class="w-100-percent with-border-radius-6 fz-16 fz-18-mobile text-center with-light-border td-padding-8 mb-20 mb-10-xl">
          <tbody>
            <ng-container *ngFor="let i of [].constructor(10); index as index">
              <tr class="cell-secondary color-secondary">
                <td>Temp {{ index * 2 + 1 }}</td>
                <td>Temp {{ index * 2 + 2 }}</td>
              </tr>

              <tr>
                <td class="fz-22-mobile">24<span class="fz-12">°C</span></td>
                <td class="fz-22-mobile">23.9<span class="fz-12">°C</span></td>
              </tr>
            </ng-container>
          </tbody>
        </table>
      </section>

      <button
        [qaTags]="'qa-btn-details'"
        class="tertiary-btn common-btn mb-25-mobile">
        Details
      </button>
    </div>
  </div>
</div>
