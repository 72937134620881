import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  HeaderTitleComponent,
  LoadingComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { Observable, Subscription, firstValueFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import { IGeneralSettingsView } from '../../interfaces/general-settings-view.interface';
import {
  selectIsLoading,
  selectGeneralSettings,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import { filter } from 'rxjs/operators';
import { ActivatedRoute } from '@angular/router';
import {
  getGeneralSettings,
  setGeneralSettings,
  updateGeneralSettings,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { TranslateModule } from '@ngx-translate/core';
import { MenuService, PlatformService } from '@livestock/shared/services';
import { ControllerGeneralSettingsFormComponent } from '../../ui/general-settings-form/general-settings-form.component';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { ColorsEnum } from '@livestock/shared/enums';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-controller-general-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ControllerGeneralSettingsFormComponent,
    ButtonComponent,
    SvgIconComponent,
    HeaderTitleComponent,
    MemoizeFuncPipe,
    LoadingComponent,
    QaTagsDirective,
  ],
  templateUrl: './general-settings.component.html',
  styleUrls: ['./general-settings.component.scss'],
})
export class ControllerGeneralSettingsComponent implements OnDestroy, OnInit {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);

  generalSettings: IGeneralSettingsView;
  originalGeneralSettings: IGeneralSettingsView;
  isValid = true;
  isDirtyForm = false;
  isEditMode: boolean = false;

  private _controllerID: number;

  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
  ) {
  }

  ngOnInit(): void {

    this.sub$.add(
      this.activatedRoute.params.pipe(
        filter(params => Boolean(+params['controllerID'])),
      ).subscribe((params) => {
        this._controllerID = +params['controllerID'];
        this.store.dispatch(getGeneralSettings({ controllerID: this._controllerID }));
      }),
    );

    this.sub$.add(
      this.store.select(selectGeneralSettings)
        .pipe(
          wasChanged(),
          filter(settings => this._controllerID && settings?.controllerID === this._controllerID),
        ).subscribe((generalSettings) => {
        if (!this.originalGeneralSettings) {
          this.originalGeneralSettings = generalSettings;
        }
        this.generalSettings = generalSettings;
      }),
    );
  }

  changedGeneralSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    this.isDirtyForm = JSON.stringify(this.originalGeneralSettings) !== JSON.stringify(event.formValues);
    if (event.isValid) {
      this.store.dispatch(setGeneralSettings({ generalSettings: event.formValues }));
    }
  }

  cancel(): void {
    this.store.dispatch(setGeneralSettings({ generalSettings: this.originalGeneralSettings }));
    this.menuService.toggleDashboardMenu(true);
  }

  async update(): Promise<void> {
    if (!this.isValid) {
      return;
    }

    this.originalGeneralSettings = this.generalSettings;
    this.store.dispatch(updateGeneralSettings());
    await firstValueFrom(this.isLoading$.pipe(filter(x => x === false)));
    this.menuService.toggleDashboardMenu(true);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
