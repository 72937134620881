import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeyboardModeEnum } from '@livestock/ui';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-ventilation-ao-testing-form',
  templateUrl: './ventilation-ao-testing-form.component.html',
  styleUrls: ['./ventilation-ao-testing-form.component.scss'],
})
export class VentilationAoTestingFormComponent implements OnInit {
  form: FormGroup;
  KeyboardModeEnum = KeyboardModeEnum;

  constructor(public platformService: PlatformService) {
  }

  ngOnInit(): void {
    this.form = new FormGroup({
      time: new FormControl(''),
    });
  }
}
