import {
  CoolingTestingFormComponent,
} from '../elements/cooling-device/cooling-testing-form/cooling-testing-form.component';
import {
  SprinklersAndFoggersTestingFormComponent,
} from '../elements/sprinklers-and-foggers/sprinklers-and-foggers-testing-form/sprinklers-and-foggers-testing-form.component';
import {
  HeatingDOTestingFormComponent,
} from '../elements/heating-do/heating-do-testing-form/heating-do-testing-form.component';
import {
  HeatingAOTestingFormComponent,
} from '../elements/heating-ao/heating-ao-testing-form/heating-ao-testing-form.component';
import {
  LightingTestingFormComponent,
} from '../elements/lighting-sensor/testing-form/lighting-sensor-testing-form.component';
import { ElementCustomActionType } from '../../../enums/element/custom-element-actions-enum';
import { ElementTypesEnum } from '@livestock/shared/enums';
import {
  LightingDOTestingFormComponent,
} from '../elements/lighting-do/testing-form/lighting-do-testing-form.component';
import {
  LightingAOTestingFormComponent,
} from '../elements/lighting-ao/lighting-ao-testing-form/lighting-ao-testing-form.component';

export interface ChildElementDefinition {
  type: ElementTypesEnum;
  name: string;
  title: string;
  hasSettings: boolean;
  isCalibratable: boolean;
  testingDialogComponent?: object;
  testingActionDisabled?: (args: any) => boolean;
  testingActions?: {
    type: string;
    title: string;
    action: ElementCustomActionType;
  }[];
  hasTable?: boolean;
  isFullWidthOnMobile?: boolean;
}

export class ElementWrapperHelper {

  static CHILDREN_FORM_GROUP_NAME: ChildElementDefinition[] = [
    {
      type: ElementTypesEnum.Cooling,
      name: 'cooling',
      title: 'Installation.Card.Cooling.Cooling',
      testingDialogComponent: CoolingTestingFormComponent,
      hasSettings: true,
      isCalibratable: false,
    },
    {
      type: ElementTypesEnum.SprinklersAndFoggers,
      name: 'sprinklersFoggers',
      title: 'Installation.Card.SprinklersAndFoggers.SprinklersAndFoggers',
      testingDialogComponent: SprinklersAndFoggersTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
    },
    {
      type: ElementTypesEnum.IndoorTemperature,
      name: 'indoorTemperature',
      title: 'Installation.Card.IndoorTemperature.IndoorTemperature',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.OutdoorTemperature,
      name: 'outdoorTemperature',
      title: 'Installation.Card.OutdoorTemperature.OutdoorTemperature',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.IndoorHumidity,
      name: 'indoorHumidity',
      title: 'Installation.Card.IndoorHumidity.IndoorHumidity',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.OutdoorHumidity,
      name: 'outdoorHumidity',
      title: 'Installation.Card.OutdoorHumidity.OutdoorHumidity',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.CO2,
      name: 'co2',
      title: 'Installation.Card.CO2.CO2',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.StaticPressure,
      name: 'staticPressure',
      title: 'Installation.Card.StaticPressure.StaticPressure',
      testingDialogComponent: null,
      isCalibratable: true,
      hasSettings: true,
    },
    {
      type: ElementTypesEnum.HeatingDO,
      name: 'heatingDO',
      title: 'Installation.Card.HeatingDO.Heating',
      testingDialogComponent: HeatingDOTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
    },
    {
      type: ElementTypesEnum.HeatingAO,
      name: 'heatingAO',
      title: 'Installation.Card.HeatingAO.Heating',
      testingDialogComponent: HeatingAOTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
    },
    {
      type: ElementTypesEnum.LightingSensor,
      name: 'lighting',
      title: 'Installation.Card.Lighting.LightingSensor',
      testingDialogComponent: LightingTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
      testingActions: [
        {
          type: 'secondary',
          title: 'Buttons.Cancel',
          action: ElementCustomActionType.Cancel,
        },
        {
          type: 'primary',
          title: 'Buttons.Save',
          action: ElementCustomActionType.SaveTestingData,
        },
      ],
      isFullWidthOnMobile: true,
    },
    {
      type: ElementTypesEnum.LightAO,
      name: 'lighting-ao',
      title: 'Installation.Card.LightAO.Lighting',
      testingDialogComponent: LightingAOTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
      isFullWidthOnMobile: true,
    },
    {
      type: ElementTypesEnum.LightDO,
      name: 'lighting-do',
      title: 'Installation.Card.LightDO.LightDO',
      testingDialogComponent: LightingDOTestingFormComponent,
      isCalibratable: false,
      hasSettings: true,
      testingActionDisabled: (formValues) => {
        if (!formValues?.enabled) return true;
        return false;
      },
      isFullWidthOnMobile: true,
    },
    {
      type: ElementTypesEnum.AirSpeed,
      name: 'airSpeed',
      title: 'Installation.Card.AirSpeed.AirSpeed',
      testingDialogComponent: null,
      isCalibratable: false,
      hasSettings: false,
    },
    {
      type: ElementTypesEnum.VentilationDO,
      name: 'ventilation-do',
      title: 'Installation.Card.Ventilation.Fan',
      testingDialogComponent: null,
      // TODO: implement in future, hidden for now
      //testingDialogComponent: VentilationDoTestingFormComponent,
      isCalibratable: false,
      hasSettings: false,
      hasTable: true,
    },
    {
      type: ElementTypesEnum.VentilationAO,
      name: 'ventilation-ao',
      title: 'Installation.Card.Ventilation.Fan',
      testingDialogComponent: null,
      // TODO: implement in future, hidden for now
      //testingDialogComponent: VentilationAoTestingFormComponent,
      isCalibratable: false,
      hasSettings: false,
      hasTable: true,
    },
  ];

  static childFormGroupNameByType(type: ElementTypesEnum): string {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type).name;
  }

  static childTestingDialogComponentByType(type: ElementTypesEnum): any {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type).testingDialogComponent;
  }

  static childElementTitleByType(type: ElementTypesEnum): string {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type).title;
  }

  static childElementTitleByName(name: string): string {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.name === name).title;
  }

  static isElementTestable(type: ElementTypesEnum): boolean {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.testingDialogComponent !== null;
  }

  static isElementCalibratable(type: ElementTypesEnum): boolean {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.isCalibratable;
  }

  static isElementHasSettings(type: ElementTypesEnum): boolean {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.hasSettings;
  }

  static elementCustomTestingActionsByType(type: ElementTypesEnum): any[] {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.testingActions;
  }

  static isElementTestingDisabled([type, formValues]: [ElementTypesEnum, any]): boolean {
    const testFunction = ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.testingActionDisabled;
    return testFunction ? testFunction(formValues) : false;
  }

  static isElementHasTable(type: ElementTypesEnum): boolean {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.hasTable;
  }

  static isElementFullWidthOnMobile(type: ElementTypesEnum): boolean {
    return ElementWrapperHelper.CHILDREN_FORM_GROUP_NAME.find(obj => obj.type === type)?.isFullWidthOnMobile;
  }
}
