<section class="page-content">
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <ls-header-title
      (cancel)="cancel()"
      [title]="'Controller.CloudSettings.SettingCloud'"></ls-header-title>

    <div
      *ngIf="currentController$ | async as currentController"
      class="container">
      <div
        *ngIf="networkSettings$ | async as networkSettings"
        class="d-flex flex-column justify-center">
        <div
          class="connection-status d-flex justify-between align-start mx-auto mb-70 mb-15-md fz-20 fz-18-md color-secondary">
          <div class="connection-status__id d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ls-svg-icon [path]="'quick-start/network/device-id'"></ls-svg-icon>
            </div>
            <p
              *ngIf="!platformService.isMobileApp"
              class="word-break w-200 text-center">
              {{ "Controller.NetworkSettings.ID" | translate }}
              <span class="fw-500 color-primary">{{ currentController.sn }}</span>
            </p>
          </div>

          <div
            [class.connection-status__network-connected]="
              !(isDisconnected$ | async) && (networkSettings.modem || networkSettings.wifi || networkSettings.lan)
            "
            class="connection-status__network d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ng-container *ngIf="!(isDisconnected$ | async)">
                <ng-container *ngIf="networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/cellular-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>

                <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/wifi-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>

                <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/cellular-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>
              </ng-container>

              <ls-svg-icon
                *ngIf="
                  (isDisconnected$ | async) || (!networkSettings.modem && !networkSettings.wifi && !networkSettings.lan)
                "
                [path]="'quick-start/network/no-network'"></ls-svg-icon>
            </div>

            <ng-container *ngIf="!platformService.isMobileApp">
              <ng-container *ngIf="!(isDisconnected$ | async)">
                <ng-container *ngIf="networkSettings.lan">
                  <span>{{ networkSettings.lanIp }}</span>
                </ng-container>

                <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
                  <span>{{ networkSettings.wifiName }}</span>
                </ng-container>

                <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
                  <span>{{ networkSettings.modemAPN }}</span>
                </ng-container>
              </ng-container>

              <ng-container
                *ngIf="
                  (isDisconnected$ | async) || (!networkSettings.modem && !networkSettings.wifi && !networkSettings.lan)
                ">
                <span>{{ "Controller.CloudSettings.NoConnection" | translate }}</span>
              </ng-container>
            </ng-container>
          </div>

          <div class="connection-status__farm d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ls-svg-icon
                [path]="'quick-start/network/' + ((isDisconnected$ | async) ? 'no-farm' : 'farm')"></ls-svg-icon>
            </div>

            <span
              *ngIf="!platformService.isMobileApp"
              class="word-break w-200 text-center">
              {{ currentController.farmName || "test" }}
            </span>
          </div>
        </div>

        <div
          *ngIf="platformService.isMobileApp"
          class="fz-20 mb-20 color-secondary mb-50">
          <p class="mb-5">
            {{ "Controller.NetworkSettings.ID" | translate }}
            <span class="fw-500 color-primary">{{ currentController.sn || "0000" }}</span>
          </p>

          <p class="mb-5">
            {{ "Controller.NetworkSettings.WiFiIP" | translate }}
            <span class="color-primary">{{ wifiIP }}</span>
          </p>

          <p>
            {{ "Controller.NetworkSettings.FarmName" | translate }}
            <span class="color-primary">{{ currentController.farmName || "test" }}</span>
          </p>
        </div>

        <div class="d-flex flex-column justify-center align-center w-350">
          <ng-container *ngIf="isDisconnected$ | async; else disconnectBtn">
            <div class="fz-20 color-secondary connected-by-info mb-35 mx-auto text-center">
              {{ "Controller.CloudSettings.ThisDeviceWasDisconnected" | translate }}
            </div>

            <ls-button
              *ngIf="platformService.isDeviceApp"
              (click)="reconnectController()"
              class="mr-20 ml-20"
              type="secondary">
              {{ "Controller.CloudSettings.Reconnect" | translate }}
            </ls-button>
          </ng-container>

          <ng-template #disconnectBtn>
            <div class="fz-20 color-secondary connected-by-info mb-35 mx-auto text-center">
              {{ "Controller.CloudSettings.ThisDeviceWasConnectedBy" | translate }}
              <span class="fw-500 color-primary">{{ currentController.connectedByUserEmail || "test" }}</span>
              {{ "Controller.CloudSettings.To" | translate }}
              <span class="fw-500 color-primary">{{ currentController.farmName || "test" }}</span>
            </div>

            <ls-button
              (click)="disconnectController()"
              class="mr-20 ml-20"
              type="secondary">
              {{ "Controller.CloudSettings.Disconnect" | translate }}
            </ls-button>
          </ng-template>
        </div>
      </div>

      <div class="d-flex justify-end mt-30">
        <ls-button
          (click)="cancel()"
          [qaTags]="'qa-btn-cancel'"
          [type]="'secondary'">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #loading>
  <ls-loading />
</ng-template>

<ng-template #qr>
  <qrcode
    [colorLight]="'#ebeff5'"
    [errorCorrectionLevel]="'M'"
    [margin]="0"
    [qaTags]="'qa-img-QR'"
    [qrdata]="'123'"
    [width]="160" />
</ng-template>
