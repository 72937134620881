<div class="edit-day-dialog">
  <div class="edit-day-dialog__title d-flex justify-between align-center">
    <h3>
      {{ "Controls.Element.DayManagment" | translate }}
    </h3>

    <img
      (click)="close()"
      class="close-button"
      src="/assets/icons/common/cross.svg" />
  </div>
  <div
    (click)="addDay()"
    class="edit-day-dialog__content">
    <p>{{ "Controls.Element.AddDay" | translate }}</p>
  </div>
  <div
    (click)="deleteDay()"
    class="edit-day-dialog__content">
    <p>{{ "Controls.Element.DeleteCurrentDay" | translate }}</p>
  </div>
</div>
