<div class="popup-wrapper p-20">
  <div class="d-flex align-center justify-between mb-40">
    <h6 class="color-primary fz-20">{{ "Installation.Card.Cooling.Testing" | translate }}</h6>
    <ls-svg-icon
      (click)="closePopup()"
      [hasMargin]="true"
      [qaTags]="'qa-btn-test-cancel'"
      class="pointer"
      path="common/cross"></ls-svg-icon>
  </div>

  <div class="popup-body mx-auto mb-40">
    <h6
      *ngIf="
        ![
          ElementTypesEnum.HeatingAO,
          ElementTypesEnum.LightingSensor,
          ElementTypesEnum.LightDO,
          ElementTypesEnum.LightAO
        ].includes(currentElementType)
      "
      class="fz-16 color-tertiary mb-5">
      {{ "Installation.Card.Cooling.SetTime" | translate }}
    </h6>

    <div
      [class.w-140]="currentElementType === ElementTypesEnum.HeatingAO"
      class="position-relative">
      <ng-container #injectionContainer></ng-container>
    </div>
  </div>

  <ls-button
    *ngIf="!(currentElementType | memoizeFunc : Helper.elementCustomTestingActionsByType)?.length; else customActions"
    (click)="toggleTesting()"
    [class.disabled]="
      injectedComponent?.instance?.form?.invalid || [currentElementType, injectedComponent?.instance?.form?.value]
        | memoizeFunc : Helper.isElementTestingDisabled
    "
    [qaTags]="'qa-btn-test-start-stop'"
    [type]="testHasStarted ? 'danger' : 'success'"
    class="w-120 m-auto">
    {{ (testHasStarted ? "Buttons.Stop" : "Buttons.Start") | translate }}
  </ls-button>

  <ng-template #customActions>
    <div class="custom-actions d-flex flex-row justify-evenly">
      <ls-button
        *ngFor="let action of Helper.elementCustomTestingActionsByType(currentElementType)"
        (click)="handleCustomAction(action)"
        [class.disabled]="
          (['primary', 'success'].includes(action.type) && injectedComponent?.instance?.form?.invalid) ||
          ([currentElementType, injectedComponent?.instance?.form?.value]
            | memoizeFunc : Helper.isElementTestingDisabled)
        "
        [qaTags]="'qa-btn-custom-action'"
        [type]="action.type"
        [type]="action.type"
        class="w-120">
        {{ action.title | translate }}
      </ls-button>
    </div>
  </ng-template>
</div>
