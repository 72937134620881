import { inject } from '@angular/core';
import { IComponentCanDeactivate } from '../interfaces';
import { DialogsService, LocalStorageService } from '../services';
import { StorageItem } from '../enums';
import { PlatformService } from '../services';

export const CanDeactivateGuard = async (component: IComponentCanDeactivate): Promise<boolean> => {
  const dialogService = inject(DialogsService);
  const platformService = inject(PlatformService);
  const canDeactivate = await component?.canDeactivate();
  const authToken = LocalStorageService.getStorageItem(StorageItem.Token);

  // TODO: platformService.isDeviceApp is a temp fix
  if (!canDeactivate && (authToken || platformService.isDeviceApp)) {
    const result = await dialogService.canContinueAction();

    if (result) {
      component?.closeComponent();
      return true;
    }

    return false;
  }

  component?.closeComponent();
  return true;
};
