<div
  *ngIf="!isLoading"
  [class.disabled]="!editMode"
  [formGroup]="form"
  class="settings d-flex flex-column">
  <div class="settings__container">
    <label class="label">{{ "Flock.BirdWeight.WeightMethod" | translate }}</label>
    <ng-select
      (change)="onWeightMethodChange($event)"
      [clearable]="false"
      [qaTags]="'qa-ddl-weight-method'"
      [searchable]="false"
      selectTitle="{{ 'Flock.BirdWeight.WeightMethod' | translate }}"
      appendTo="body"
      formControlName="weightMethod">
      <ng-option
        *ngFor="let el of WeightMethodEnum | enumToArray"
        [value]="el">
        {{ el | enum : "weight-method-enum" | translate }}
      </ng-option>
    </ng-select>

    <ng-container *ngIf="form.value.weightMethod === WeightMethodEnum.Predefined">
      <label class="label mt-20">{{ "Flock.BirdWeight.ReferenceTable" | translate }}</label>
      <ng-select
        (change)="getDefaultWeights($event)"
        [clearable]="false"
        [qaTags]="'qa-ddl-reference-table'"
        [searchable]="false"
        selectTitle="{{ 'Flock.BirdWeight.ReferenceTable' | translate }}"
        formControlName="defaultWeightBrandID"
        appendTo="body">
        <ng-option
          *ngFor="let el of ChickenBrandWeight | enumToArray"
          [value]="el">
          {{ el | enum : "chicken-brand-weight" | translate }}
        </ng-option>
      </ng-select>
    </ng-container>

    <div
      *ngIf="form.value.weightMethod !== WeightMethodEnum.Predefined"
      class="d-flex flex-column flex-row-md justify-between-md">
      <div>
        <label class="label mt-30">{{ "Flock.BirdWeight.RefWeight" | translate }}</label>
        <div class="ref-weight d-flex flex-row align-center">
          <span *ngIf="!separateMaleAndFemale; else separateTemplate">{{ refWeight?.weight ?? " - " }} </span>
          <ng-template #separateTemplate>
            <span>{{ refWeight?.femaleWeight }} - {{ refWeight?.maleWeight }} </span>
          </ng-template>

          <div
            *ngIf="refWeight != null"
            class="ref-weight__unit ml-10">
            <ng-container *ngTemplateOutlet="unit"> </ng-container>
          </div>
        </div>
      </div>

      <div>
        <label class="label mt-30">{{ "Flock.BirdWeight.CurveOffset" | translate }}</label>
        <div class="d-flex flex-row align-center">
          <div class="mr-5 position-relative">
            <ls-input-integer
              *ngIf="weightUnit === WeightUnitEnum.Gram"
              [fieldWithKeyboard]="true"
              [max]="maxCurveOffset"
              [min]="minCurveOffset"
              [qaTags]="'qa-txt-curve-offset'"
              errorField
              formControlName="curveOffset">
            </ls-input-integer>
            <ls-input-decimal
              *ngIf="weightUnit !== WeightUnitEnum.Gram"
              [accuracy]="3"
              [fieldWithKeyboard]="true"
              [max]="maxCurveOffset"
              [min]="minCurveOffset"
              [qaTags]="'qa-txt-curve-offset'"
              errorField
              formControlName="curveOffset">
            </ls-input-decimal>
          </div>
          <ng-container *ngTemplateOutlet="unit"> </ng-container>
        </div>
      </div>
    </div>
  </div>
  <div class="buttons d-flex flex-row justify-start">
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #unit>
  <span class="unit">
    <ng-container [ngSwitch]="weightUnit">
      <ng-container *ngSwitchCase="WeightUnitEnum.Gram">
        {{ "g" | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WeightUnitEnum.Kilogram">
        {{ "kg" | translate }}
      </ng-container>
      <ng-container *ngSwitchCase="WeightUnitEnum.Pound">
        {{ "lb" | translate }}
      </ng-container>
    </ng-container>
  </span>
</ng-template>
