import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IVentBrandItem } from '../interfaces/vent-brand-item.interface';
import { IVentModelDOItem } from '../interfaces/vent-model-do-item.interface';
import { IVentModelAOItem } from '../interfaces/vent-model-ao-item.interface';

export const getVentilationBrands = createAction(
  '[VENTILATION BRANDS] Get Ventilation Brands [...]',
);

export const getVentilationBrandsSuccess = createAction(
  '[VENTILATION BRANDS] Get Ventilation Brands [SUCCESS]',
  props<{ ventilationBrands?: IVentBrandItem[] }>(),
);

export const getVentilationBrandsError = createAction(
  '[VENTILATION BRANDS] Get Ventilation Brands [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);

export const getVentilationModels = createAction(
  '[VENTILATION BRANDS] Get Ventilation Models [...]',
  props<{ brandID: number, isDO: boolean }>(),
);

export const getVentilationModelsDOSuccess = createAction(
  '[VENTILATION BRANDS] Get Ventilation Models DO [SUCCESS]',
  props<{ ventilationModels: IVentModelDOItem[] }>(),
);

export const getVentilationModelsAOSuccess = createAction(
  '[VENTILATION BRANDS] Get Ventilation Models AO [SUCCESS]',
  props<{ ventilationModels: IVentModelAOItem[] }>(),
);

export const getVentilationModelsError = createAction(
  '[VENTILATION BRANDS] Get Ventilation Models [ERROR]',
  props<{ payload: HttpErrorResponse }>(),
);
