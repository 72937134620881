import {
    IAirTreatmentSettingsView,
    IAirTreatmentThresholdsView,
    IAirTreatmentView,
} from '@livestock/air-treatment';

export interface AirTreatmentState {
    isLoading: boolean;
    items: IAirTreatmentView[];
    settings: IAirTreatmentSettingsView;
    thresholds: IAirTreatmentThresholdsView;
}