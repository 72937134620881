<div
  [class.input-disabled]="disabled"
  class="main-input-container position-relative">
  <div
    (click)="focusInput()"
    class="ls-input">
    <ng-content select="[leftIcon]"></ng-content>
    <div class="input-wrapper">
      <input
        #input
        [class]="inputCssClass"
        [class.no-max-width]="noMaxWidth"
        [class.rtl]="languageService.isRtl"
        [disabled]="disabled"
        [placeholder]="placeholder"
        [qaTags]="qaTags"
        [readonly]="readonly"
        type="text" />
      <label
        [class.input-label_filled]="!!value || value === 0"
        class="input-label">
        {{ label | translate }}
      </label>
      <span
        #content
        [ngClass]="{ icon__rtl: isRtl, icon: isContent }"
        [style.left.px]="languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
        [style.right.px]="!languageService.isRtl ? 0 : valueLengthPx + inputPadding + extraPadding"
        class="position-absolute w-mc pointer-events-none unit">
        <ng-content select="[suffix]"></ng-content>
      </span>

      <!--for value length calculation-->
      <span
        *ngIf="isContent && viewInitiated"
        #valueLengthSpan
        class="position-absolute value-raw">
        {{ inputRef.nativeElement.value || placeholder }}
      </span>
    </div>
    <ng-content select="[rightIcon]"></ng-content>
  </div>
  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>
