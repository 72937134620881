import { Component, ElementRef, EventEmitter, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AbstractControl,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  ButtonComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SvgIconComponent,
} from '@livestock/ui';
import {
  FlockConstants,
  IFlockBirdWeightView,
  IFlockDefaultWeightView,
  WeightMethodEnum,
} from '@livestock/flock';
import { distinctUntilChanged, Subscription } from 'rxjs';
import { ChickenBrandWeight, StorageItem, WeightUnitEnum } from '@livestock/shared/enums';
import { LanguageService, LocalStorageService, PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-flock-bird-weight-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    SvgIconComponent,
    ButtonComponent,
  ],
  templateUrl: './bird-weight-form.component.html',
  styleUrls: ['./bird-weight-form.component.scss'],
})
export class BirdWeightFormComponent implements OnDestroy {
  @ViewChild('table') table: ElementRef;
  @Output() changed = new EventEmitter();
  @Output() activeDayChanged = new EventEmitter();

  sub$ = new Subscription();

  form: FormGroup;
  activeIndex: number;
  maxWeight: number;
  isDirtyForm: boolean;
  originalBirdWeightItems: IFlockBirdWeightView[];
  prevValid = true;
  showPseudoLoading: boolean;

  ChickenBrandWeight = ChickenBrandWeight;
  WeightMethodEnum = WeightMethodEnum;
  WeightUnitEnum = WeightUnitEnum;
  FlockConstants = FlockConstants;

  private _birdWeightItems: IFlockBirdWeightView[];
  private _defaultWeightItems: IFlockBirdWeightView[];
  private _editMode: boolean;
  private _isLoading: boolean;
  private _weightUnit: WeightUnitEnum;
  private _weightMethod: WeightMethodEnum;
  private _separateMaleAndFemale: boolean;

  get weightUnit(): WeightUnitEnum {
    return this._weightUnit;
  }

  // drop all form changes after switching to view mode
  @Input() set editMode(editMode: boolean) {
    this._editMode = editMode;
    if (!this._editMode && this.form) {
      this.initForm(this.birdWeightItems);
    }

    this.setActiveIndex(null, null);
  }

  // make all fields inactive after loading
  @Input() set isLoading(isLoading: boolean) {
    this._isLoading = isLoading;
    this.setActiveIndex(null, null);
  }

  // change validators if unit was changed
  @Input() set weightUnit(weightUnit: WeightUnitEnum) {
    if (weightUnit != null) {
      this._weightUnit = weightUnit;
      this.maxWeight = FlockConstants.getMaxWeightByUnit(weightUnit);
      if (this.form) {
        this.setMaxWeightValidators(this.maxWeight);
      }
    }
  }

  @Input() set separateMaleAndFemale(separateMaleAndFemale: boolean) {
    if (separateMaleAndFemale != null) {
      this._separateMaleAndFemale = separateMaleAndFemale;
      if (this.form) {
        this.setCustomWeightValidators(separateMaleAndFemale);
      }
    }
  };

  // to avoid units jumping in the table
  @Input() set referenceTable(_referenceTable: ChickenBrandWeight) {
    this.showPseudoLoading = true;
    setTimeout(() => this.showPseudoLoading = false, 500);
  };

  // reinit form if default (predefined) items were set in store
  @Input() set defaultWeightItems(defaultWeightItems: IFlockDefaultWeightView[]) {
    if (defaultWeightItems.length === 0) return;

    this._defaultWeightItems = defaultWeightItems.map(item => {
      return {
        ...item,
        weight: item.weight,
        femaleWeight: item.femaleWeight,
        maleWeight: item.maleWeight,
        controllerID: +LocalStorageService.getStorageItem(StorageItem.ActiveControllerID),
      };
    });
    this.initForm(this._defaultWeightItems);
  }

  @Input() set weightMethod(weightMethod: WeightMethodEnum) {
    this.showPseudoLoading = true;
    setTimeout(() => this.showPseudoLoading = false, 500);

    if (this._weightMethod != null && weightMethod === WeightMethodEnum.OwnCurve) {
      this.initForm([]);
    }

    this.setActiveIndex(null, null);
    this._weightMethod = weightMethod;
  }

  get weightMethod(): WeightMethodEnum {
    return this._weightMethod;
  }

  get isLoading(): boolean {
    return this._isLoading;
  }

  get editMode(): boolean {
    return this._editMode;
  }

  get separateMaleAndFemale(): boolean {
    return this._separateMaleAndFemale;
  }

  get birdWeightItems(): IFlockBirdWeightView[] {
    return this._birdWeightItems;
  }

  get defaultWeightItems(): IFlockBirdWeightView[] {
    return this._defaultWeightItems;
  }

  @Input() set birdWeightItems(birdWeightItems: IFlockBirdWeightView[]) {
    if (birdWeightItems) {
      this._birdWeightItems = birdWeightItems;
      if (!this.originalBirdWeightItems) {
        this.originalBirdWeightItems = birdWeightItems;
      }

      if (!this.form) {
        this.initForm(birdWeightItems);
      }

      this.isDirtyForm = JSON.stringify(birdWeightItems) !== JSON.stringify(this.originalBirdWeightItems);
    }
  }

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
  ) {
  }

  initForm(birdWeightItems: IFlockBirdWeightView[]): void {
    if (birdWeightItems.length === 0) {
      this.changed.emit({
        formValues: [],
        isValid: this.weightMethod !== WeightMethodEnum.OwnCurve,
      });
    }

    this.form = this.formBuilder.group({
      birdWeightItems: this.formBuilder.array(birdWeightItems.map((item) => {
        return this.getBirdWeightFG(item, this.weightUnit, this.separateMaleAndFemale);
      })),
    });

    this.sub$.add(
      this.form.valueChanges.pipe(
        distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b) && this.prevValid === this.form.valid),
      ).subscribe((formValues) => {
        this.prevValid = this.form.valid;
        this.changed.emit({
          formValues: formValues.birdWeightItems,
          isValid: this.form.valid && formValues.birdWeightItems.length > 0,
        });
      }),
    );
  }

  addEmptyRow(): void {
    const itemsArr = this.getFormItems();

    itemsArr.push(this.getBirdWeightFG({
          controllerID: null,
          day: itemsArr.value?.length > 0
            ? Math.max(...itemsArr.value.map(x => x.day)) + 1
            : 0,
          weight: 0,
          femaleWeight: 0,
          maleWeight: 0,
        },
        this.weightUnit,
        this.separateMaleAndFemale),
    );

    this.changed.emit({
      formValues: this.form.value.birdWeightItems,
      isValid: false,
    });

    // scroll to the bottom
    setTimeout(() => {
      this.table.nativeElement.scrollTop = this.table.nativeElement.scrollHeight;
    });
  }

  removeRow(): void {
    if (this.activeIndex == null) {
      return;
    }

    const itemsArr = this.getFormItems();
    itemsArr.removeAt(this.activeIndex);
    this.activeIndex = null;

    this.checkDuplicacy();
  }

  setActiveIndex(index: number, day: number): void {
    if (this.activeIndex != index) {
      this.activeDayChanged.emit(day);
    }
    this.activeIndex = index;
  }

  private getBirdWeightFG(
    birdWeight: IFlockBirdWeightView,
    weightUnit: WeightUnitEnum,
    separateMaleAndFemale: boolean,
  ): FormGroup {
    let allValidators = [Validators.required];
    if (weightUnit != null && this.weightMethod === WeightMethodEnum.OwnCurve) {
      const maxWeight = FlockConstants.getMaxWeightByUnit(weightUnit);
      allValidators.push(...[
        Validators.min(0),
        Validators.max(maxWeight),
      ]);
    }

    // we don't need validation for predefined values, they are not editable
    if (this.weightMethod === WeightMethodEnum.Predefined) {
      allValidators = [];
    }

    const generateValidators = (property: string): any => {
      if (this.weightMethod === WeightMethodEnum.Predefined) {
        return [];
      }

      const validators = [...allValidators];
      switch (property) {
        case 'femaleWeight':
        case 'maleWeight':
          if (separateMaleAndFemale) {
            validators.push(this.validateWeight(property));
          }
          break;
        case 'weight':
          if (!separateMaleAndFemale) {
            validators.push(this.validateWeight(property));
          }
          break;
      }

      return validators;
    };

    return new FormGroup(
      {
        controllerID: new FormControl<number>(birdWeight.controllerID),
        day: new FormControl<number>(birdWeight.day, [
          Validators.required,
          Validators.min(FlockConstants.MinBirdWeightDay),
          Validators.max(FlockConstants.MaxBirdWeightDay),
          this.validateUniqDay(),
        ]),
        weight: new FormControl<number>(birdWeight.weight, generateValidators('weight')),
        femaleWeight: new FormControl<number>(birdWeight.femaleWeight, generateValidators('femaleWeight')),
        maleWeight: new FormControl<number>(birdWeight.maleWeight, generateValidators('maleWeight')),
      },
    );
  }

  setMaxWeightValidators(maxWeight: number): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = [
      'weight',
      'femaleWeight',
      'maleWeight',
    ];

    (this.getFormItems().controls as FormGroup[])
      .forEach((element) => {
        controls.forEach(controlName => {
          element.controls[controlName].addValidators(Validators.min(0));
          element.controls[controlName].addValidators(Validators.max(maxWeight));
          element.controls[controlName].updateValueAndValidity();
        });
      });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid && this.form.value.birdWeightItems.length > 0,
      });
    }
  }

  setCustomWeightValidators(separateMaleAndFemale: boolean): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const separateControls = [
      'femaleWeight',
      'maleWeight',
    ];
    const weightControlName = 'weight';

    (this.getFormItems().controls as FormGroup[])
      .forEach((element) => {
        if (separateMaleAndFemale) {
          separateControls.forEach(controlName => {
            element.controls[controlName].addValidators(this.validateWeight(controlName));
            element.controls[controlName].updateValueAndValidity();
          });
        } else {
          element.controls[weightControlName].addValidators(this.validateWeight(weightControlName));
          element.controls[weightControlName].updateValueAndValidity();
        }
      });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid && this.form.value.birdWeightItems.length > 0,
      });
    }
  }

  getFormItems(): FormArray<any> {
    return (this.form.controls['birdWeightItems'] as FormArray);
  }

  orderByDay(): void {
    const itemsArr = this.getFormItems();
    const sortedItems = JSON.parse(JSON.stringify(itemsArr.value)).sort((a, b) => a.day - b.day);
    this.form.patchValue({
      birdWeightItems: sortedItems,
    });

    this.originalBirdWeightItems = this.birdWeightItems;
    this.isDirtyForm = false;

    this.checkDuplicacy();
  }

  checkDuplicacy(): void {
    const itemsArr = this.getFormItems();
    itemsArr.controls.forEach((x) => {
      (x as FormGroup).get('day').updateValueAndValidity();
      (x as FormGroup).get('weight').updateValueAndValidity();
      (x as FormGroup).get('femaleWeight').updateValueAndValidity();
      (x as FormGroup).get('maleWeight').updateValueAndValidity();
    });
  }

  checkWeight(index: number, property: string): void {
    const itemsArr = this.getFormItems();
    itemsArr.controls.forEach((x, i) => {
      // We check current + prev + next + all invalid
      if (i == index || i == (index - 1) || (i == index + 1) || x.invalid) {
        (x as FormGroup).get(property).updateValueAndValidity();
      }
    });
  }

  validateUniqDay(): any {
    return (control: AbstractControl) => {
      if (control.value != null) {
        const formArray = control.parent
          ? (control.parent.parent as FormArray)
          : null;
        if (formArray) {
          const days = formArray.value.map((x) => x.day);
          return days.filter(x => x == control.value).length > 1
            ? { duplicatedDay: true }
            : null;
        }
      }
      return null;
    };
  }

  validateWeight(property: string): any {
    return (control: AbstractControl) => {
      if (control.value != null) {
        const formArray = control.parent
          ? (control.parent.parent as FormArray)
          : null;
        if (formArray) {
          const allFormValues = formArray.value;
          const currentFormValue = {
            ...control.parent.value,
            [property]: control.value,
          };
          const prevDay = Math.max(...allFormValues.filter(x => x.day < currentFormValue.day).map(x => x.day));
          const nextDay = Math.min(...allFormValues.filter(x => x.day > currentFormValue.day).map(x => x.day));
          const prevFormValue = allFormValues.find(x => x.day === prevDay);
          const nextFormValue = allFormValues.find(x => x.day === nextDay);

          // e.g.
          // 1) 4000
          // 2) 3000
          // 3) 6000
          if (
            (currentFormValue?.[property] < prevFormValue?.[property] || currentFormValue?.[property] > nextFormValue?.[property])
            && nextFormValue?.[property] > prevFormValue?.[property]
          ) {
            return {
              invalidWeight: {
                message: `${prevFormValue?.[property] ?? 0} - ${nextFormValue?.[property]}`,
              },
            };
          }

          // e.g.
          // 1) 4000
          // 2) 3000
          // 3) 2000
          // we shouldnt show message like "more than 4000 and less than 2000"
          if (currentFormValue?.[property] < prevFormValue?.[property] && nextFormValue?.[property] <= prevFormValue?.[property]) {
            return {
              weightShouldBeMoreThan: {
                message: prevFormValue?.[property] ?? 0,
              },
            };
          }

          // e.g.
          // 1) 4000
          // lastDay 3000
          if (currentFormValue?.[property] < prevFormValue?.[property] && !nextFormValue) {
            return {
              invalidWeight: {
                message: `${prevFormValue?.[property] ?? 0} - ${this.maxWeight}`,
              },
            };
          }
        }
      }
      return null;
    };
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
