import { Directive, OnDestroy, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { Store } from '@ngrx/store';
// import * as currentUserSelectors from '../../current-user/src/lib/+state/current-user.selectors';
import { Subscription } from 'rxjs';

// import { CommonUserRolesEnum } from '../enums/users/common-user-roles.enum';

@Directive({
  selector: '[lsRolesAccess]',
  standalone: true,
})
export class UserRolesAccessDirective implements OnInit, OnDestroy {
  // allowedRoles: CommonUserRolesEnum[];
  // userRole: CommonUserRolesEnum;
  sub$ = new Subscription();

  // @Input() set lsRolesAccess(allowedRoles: CommonUserRolesEnum[]) {
  //   if (!!allowedRoles && !!allowedRoles.length) {
  //     this.allowedRoles = allowedRoles;
  //     this.updateView(allowedRoles);
  //   }
  // }

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    // this.sub$.add(
    //   this.store.select(currentUserSelectors.selectCurrentUserRole).pipe(
    //     filter(x => x != null),
    //   ).subscribe((role: CommonUserRolesEnum) => {
    //     this.userRole = role;
    //     this.updateView(this.allowedRoles);
    //   }),
    // );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  // private updateView(roles: CommonUserRolesEnum[]): void {
  //   this.viewContainer.clear();
  //   if (roles.includes(this.userRole)) {
  //     this.viewContainer.createEmbeddedView(this.templateRef);
  //   } else {
  //     this.viewContainer.clear();
  //   }
  // }
}
