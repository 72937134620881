export const ControllerRoutes = {
  GeneralSettings: 'general-settings',
  DateTimeSettings: 'date-time-settings',
  NetworkSettings: 'network-settings',
  CloudSettings: 'cloud-settings',
  HouseSizesSettings: 'house-sizes-settings',
  Cooling: 'cooling',
  FlockSettings: 'flock-settings',
  FlockHouseMode: 'flock-house-mode',
  FlockBirdWeight: 'flock-bird-weight',
  FlockBirdWeightGraph: 'flock-bird-weight-graph',
  SprinklersFoggersProgram: 'sprinklers-foggers-program',
  StaticPressure: 'static-pressure',
  HeatingProgramZone: 'heating-program-zone',
  TemperatureCurve: 'temperature-curve',
  HeatingPrograms: 'heating-programs',
  LightingPrograms: 'lighting-programs',
  AirTreatment: 'air-treatment',
  AirTreatmentSettings: 'air-treatment-settings',
  TemperatureMapping: 'temperature-mapping',
  LightingMapping: 'lighting-mapping',
  BasicVentilationMinimum: 'basic-ventilation-minimum',
};
