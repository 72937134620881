import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormGroup, ReactiveFormsModule } from '@angular/forms';
import { DialogsService, LanguageService } from '@livestock/shared/services';
import { EnumPipe } from '@livestock/shared/pipes';
import { LoadingComponent, KeyboardComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'lv-controller-settings-stub-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    EnumPipe,
    QaTagsDirective,
    LoadingComponent,
    KeyboardComponent,
  ],
  templateUrl: './settings-stub-form.component.html',
  styleUrls: ['./settings-stub-form.component.scss'],
})
export class ControllerSettingsStubFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean = false;
  @Output() changed = new EventEmitter();

  form = new FormGroup({});

  constructor(
    public languageService: LanguageService,
    private dialogsService: DialogsService,
  ) {}

  ngOnInit(): void {
  }

  ngOnDestroy(): void {
  }

}
