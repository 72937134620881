import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MessageDialogComponent, SelectDialogComponent } from '@livestock/ui';
import { DialogButtonEnum, AlignmentEnum } from '@livestock/shared/enums';
import { ISelectDialogOptions, SelectDialogCssClass } from '@livestock/shared/interfaces';
import { lastValueFrom } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DialogsService {
  constructor(
    private dialog: MatDialog,
  ) {
  }

  async error(
    errorKey: string,
    errorTitle: string | null = null,
    iconPath: string = null,
    buttons: DialogButtonEnum[] = [DialogButtonEnum.OK],
    showCloseIcon = true,
  ): Promise<void> {
    await this.showDialog(
      errorKey,
      errorTitle,
      buttons,
      iconPath,
      true,
      true,
      true,
      AlignmentEnum.CENTER,
      AlignmentEnum.CENTER,
      showCloseIcon,
    );
  }

  async ok(
    messageKey: string,
    titleKey = null,
    iconPath: string = null,
    disableClose = true,
  ): Promise<DialogButtonEnum> {
    return await this.showDialog(messageKey, titleKey, [DialogButtonEnum.OK], iconPath, disableClose);
  }

  async question(
    messageKey: string,
    titleKey: string,
    buttons: DialogButtonEnum[],
    iconPath: string = null,
    isMessageShouldBeTranslated = true,
    isTitleShouldBeTranslated = true,
    alignText = AlignmentEnum.CENTER,
    alignButtons = AlignmentEnum.CENTER,
    showCloseIcon = true,
  ): Promise<DialogButtonEnum> {
    return await this.showDialog(messageKey,
      titleKey,
      buttons,
      iconPath,
      isMessageShouldBeTranslated,
      isTitleShouldBeTranslated,
      true,
      alignText,
      alignButtons,
      showCloseIcon,
    );
  }

  async select(options: ISelectDialogOptions, titleKey: string, buttons: DialogButtonEnum[], panelClass: string = 'popup-full__mobile', dialogStyle: SelectDialogCssClass = SelectDialogCssClass.DEFAULT): Promise<any> {
    return await this.showSelectDialog(options, titleKey, buttons, panelClass, dialogStyle);
  }

  private async showSelectDialog(options: ISelectDialogOptions, titleKey: string, buttons: DialogButtonEnum[], panelClass: string, dialogStyle: SelectDialogCssClass): Promise<any> {
    const dialogRef = this.dialog.open(SelectDialogComponent, {
      autoFocus: false,
      panelClass,
      disableClose: true,
      enterAnimationDuration: 0,
    });
    const dialog = dialogRef.componentInstance;
    dialog.setup(options, titleKey || 'Livestock', buttons, dialogStyle);
    return await lastValueFrom(dialogRef.afterClosed());
  }

  private async showDialog(
    messageKey: string,
    titleKey: string,
    buttons: DialogButtonEnum[],
    iconPath: string = null,
    isMessageShouldBeTranslated = true,
    isTitleShouldBeTranslated = true,
    disableClose = true,
    alignText = AlignmentEnum.CENTER,
    alignButtons = AlignmentEnum.CENTER,
    showCloseIcon = true,
  ): Promise<DialogButtonEnum> {
    const dialogRef = this.dialog.open(MessageDialogComponent, { disableClose });
    const dialog = <MessageDialogComponent>dialogRef.componentInstance;
    dialog.setup(
      titleKey || 'Livestock',
      messageKey,
      buttons,
      iconPath,
      isMessageShouldBeTranslated,
      isTitleShouldBeTranslated,
      alignText,
      alignButtons,
      showCloseIcon,
    );
    return await lastValueFrom(dialogRef.afterClosed());
  }

  async canContinueAction(): Promise<boolean> {
    const title = 'Livestock.UnsavedChanges';
    const message = 'Livestock.UnsavedChangesMessage';
    const result = await this.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE], 'flash/warning',
    );

    if (!result || result === DialogButtonEnum.CANCEL) {
      return false;
    }

    return true;
  }
}
