import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';

import {
  getElement, selectCurrentConnectionSetupIsDirty,
  selectCurrentElementType,
  selectElementIsLoading,
  setCurrentCard,
} from '@livestock/installation';
import { InstallationHelpersService } from '../../service/installation-helpers.service';
import { ElementWrapperHelper } from '../devices/helpers/element-wrapper.helper';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { InstallationRoutes } from '../../module/installation-routes';
import { PlatformService } from '@livestock/shared/services';
import { ActivatedRoute, Router } from '@angular/router';
import { CardType } from '../../enums/card-type.enum';
import { AppRoutes } from '@livestock/shared/routes';
import { ColorsEnum } from '@livestock/shared/enums';
import { ConnectionType } from '../../enums/connection-type.enum';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';

@Component({
  selector: 'ls-active-element-mobile',
  templateUrl: './active-element-mobile.component.html',
  styleUrls: ['./active-element-mobile.component.scss'],
})
export class ActiveElementMobileComponent implements OnInit, IComponentCanDeactivate {
  // sub
  currentElementType$: Observable<number> = this.store.select(selectCurrentElementType);
  isElementLoading$: Observable<boolean> = this.store.select(selectElementIsLoading);

  // vars
  cardNumber: number;
  cardType: CardType;
  connectionType: ConnectionType;
  connectionNumber: number;
  cardID: number;
  elementID: number;
  connectionID: number;

  // constants
  ElementTypesEnum = ElementTypesEnum;
  ColorsEnum = ColorsEnum;
  CardType = CardType;
  helper = ElementWrapperHelper;

  constructor(
    public platformService: PlatformService,
    public installationHelpersService: InstallationHelpersService,
    private store: Store,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.getElementForMobileApp();
  }

  getElementForMobileApp(): void {
    this.cardID = +this.activatedRoute.snapshot.params['cardID'];
    this.cardType = +this.activatedRoute.snapshot.params['cardType'];
    this.store.dispatch(setCurrentCard({ cardID: this.cardID }));

    const elementType = +this.activatedRoute.snapshot.params['elementType'];
    this.elementID = +this.activatedRoute.snapshot.params['elementID'];
    this.connectionID = +this.activatedRoute.snapshot.params['connectionID'];
    this.cardNumber = +this.activatedRoute.snapshot.queryParams['cardNumber'];
    this.connectionNumber = +this.activatedRoute.snapshot.queryParams['connectionNumber'];
    this.connectionType = +this.activatedRoute.snapshot.queryParams['connectionType'];

    this.store.dispatch(
      getElement({
        elementID: this.elementID,
        elementType,
        connectionID: this.connectionID,
      }),
    );
  }

  back(): void {
    if (this.installationHelpersService.isTableView$.value) {
      this.installationHelpersService.isTableView$.next(false);
      return;
    }

    this.router.navigate([AppRoutes.INSTALLATION], {
      queryParams: {
        cardNumber: this.cardNumber,
        cardType: this.cardType,
        connectionType: this.connectionType,
      },
    });
  }

  closeComponent(): void {
  }

  async canDeactivate(): Promise<boolean> {
    return !(await firstValueFrom(this.store.select(selectCurrentConnectionSetupIsDirty)));
  }

  openSettings(elementType: ElementTypesEnum): void {
    this.router.navigate(
      [
        InstallationRoutes.createSettingsLink(
          elementType,
        ),
      ],
    );
  }

  openTableView(): void {
    this.installationHelpersService.isTableView$.next(true);
  }
}
