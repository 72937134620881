<div
  *ngFor="let step of _steps; let idx = index"
  [class.rtl]="languageService.isRtl"
  class="step">
  <div
    [ngClass]="{ active: idx + 1 === currStep }"
    [qaTags]="'qa-img-stage' + (idx + 1 === currStep ? ' qa-img-check' : '')"
    class="circle">
    {{ idx + 1 }}
  </div>
  <div
    [ngStyle]="{ visibility: idx + 1 < currStep ? 'visible' : 'hidden' }"
    class="complete">
    <ls-svg-icon
      [hasMargin]="true"
      [mirrorRtl]="false"
      [path]="'tick'"
      class="ml-5"></ls-svg-icon>
  </div>
  <span
    *ngIf="labels[idx]"
    [ngClass]="{ active: idx + 1 === currStep }"
    class="label ml-10">
    {{ labels[idx] | translate }}
  </span>
</div>
