import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import { getNetworkSettings,
  selectActiveControllerID, selectNetworkSettings,
} from '@livestock/controllers';

export const networkSettingsResolver: ResolveFn<boolean> =
  async (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);

    const networkSettings = await firstValueFrom(store.select(selectNetworkSettings));
    if (networkSettings == null) {
      const controllerID = route.params['controllerID'] || await firstValueFrom(store.select(selectActiveControllerID));
      store.dispatch(getNetworkSettings({ controllerID }));

      await firstValueFrom(store.select(selectNetworkSettings).pipe(
        filter(info => info != null), // <- waiting until data is present
      ));
    }
    return true;
  };
