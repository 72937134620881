<div
  [class.rtl]="languageService.isRtl"
  [class.white]="selectedCard"
  class="wrapper">
  <ng-container *ngIf="!selectedCard">
    <div class="header-titles">
      <ls-header-title
        (cancel)="cancel()"
        [hasArrow]="false"
        [title]="'Installation.Main.InstallationMain'"
        titleClass="p-0"
        class="mb-5 d-block installation-header-title"></ls-header-title>
      <p class="header-subtitle gray">
        {{ "Installation.Main.SelectCardToContinue" | translate }}
      </p>
    </div>

    <ng-container *ngIf="!(isLoading$ | async); else loading">
      <div class="installation analog-digital">
        <div class="installation-col">
          <ng-container *ngFor="let aCard of analogCards$ | async; let i = index">
            <p
              [qaTags]="'qa-lbl-type'"
              class="title qa-lbl-analog-{{ i + 1 }}">
              {{ "Installation.Main.Analog" | translate }} {{ i + 1 }}
            </p>
            <div
              (click)="
                platformService.isMobileApp
                  ? selectCard(aCard)
                  : navigateToCard(aCard.cardID, i + 1, aCard.cardType, ConnectionType.AnalogInput)
              "
              [qaTags]="'qa-crd-type-ai'"
              class="installation-container installation-container--bordered installation-container--with-text pointer">
              <p
                [class.bottom-to-top]="!platformService.isMobileApp"
                [qaTags]="'qa-lbl-io-type'"
                class="text">
                {{ "Installation.Main.Input" | translate }}
              </p>

              <ng-container
                *ngTemplateOutlet="
                  cells;
                  context: {
                    $implicit:
                      aCard.connections | filterCollection : ConnectionType.AnalogInput : 'connectionType' : true
                  }
                "></ng-container>
            </div>
            <div
              (click)="
                platformService.isMobileApp
                  ? selectCard(aCard, true)
                  : navigateToCard(aCard.cardID, i + 1, aCard.cardType, ConnectionType.AnalogOutput)
              "
              [qaTags]="'qa-crd-type-ao'"
              class="installation-container installation-container--bordered installation-container--with-text pointer">
              <p
                [class.bottom-to-top]="!platformService.isMobileApp"
                [qaTags]="'qa-lbl-io-type'"
                class="text">
                {{ "Installation.Main.Output" | translate }}
              </p>
              <ng-container
                *ngTemplateOutlet="
                  cells;
                  context: {
                    $implicit:
                      aCard.connections | filterCollection : ConnectionType.AnalogOutput : 'connectionType' : true
                  }
                "></ng-container>
            </div>
          </ng-container>
          <p
            *ngIf="platformService.isMobileApp"
            class="title title--disabled">
            {{ "Installation.Main.Power" | translate }}
          </p>
          <div class="installation-container installation-container--disabled installation-container--bordered">
            <p *ngIf="!platformService.isMobileApp">{{ "Installation.Main.Power" | translate }}</p>
          </div>
        </div>
        <div class="installation-col installation-col--margined">
          <div>
            <p
              *ngIf="platformService.isMobileApp"
              class="title title--disabled">
              {{ "Installation.Main.SOM" | translate }}
            </p>
            <div class="installation-container installation-container--disabled installation-container--bordered">
              <p *ngIf="!platformService.isMobileApp">{{ "Installation.Main.SOM" | translate }}</p>
            </div>
          </div>

          <div
            *ngIf="digInpCard$ | async as digCard"
            (click)="
              platformService.isMobileApp ? selectCard(digCard) : navigateToCard(digCard.cardID, 1, digCard.cardType)
            "
            class="mt-19 mt-0-md">
            <p class="title">{{ "Installation.Main.DigitalInput" | translate }}</p>
            <div
              class="installation-container installation-container--bordered installation-container--with-text"
              qaTags="qa-crd-type-digital-input">
              <p
                [class.bottom-to-top]="!platformService.isMobileApp"
                [qaTags]="'qa-lbl-io-type'"
                class="text">
                {{ "Installation.Main.Input" | translate }}
              </p>
              <ng-container *ngTemplateOutlet="cells; context: { $implicit: digCard.connections }"></ng-container>
            </div>
          </div>

          <div
            *ngIf="scaleCard$ | async as scaleCard"
            (click)="platformService.isMobileApp && selectCard(scaleCard)">
            <p class="title">{{ "Installation.Main.Scale" | translate }}</p>
            <div
              class="installation-container installation-container--bordered installation-container--with-text"
              qaTags="qa-crd-type-scale">
              <p
                [class.bottom-to-top]="!platformService.isMobileApp"
                [qaTags]="'qa-lbl-io-type'"
                class="text">
                {{ "Installation.Main.Input" | translate }}
              </p>
              <ng-container *ngTemplateOutlet="cells; context: { $implicit: scaleCard.connections }"></ng-container>
            </div>
          </div>
        </div>
      </div>

      <div class="card">
        <p class="title">{{ "Installation.Main.Relay" | translate }}</p>
        <div
          *ngFor="let relCard of relayCards$ | async; index as index"
          (click)="
            platformService.isMobileApp
              ? selectCard(relCard)
              : navigateToCard(relCard.cardID, index + 1, relCard.cardType)
          "
          [ngClass]="{
            'installation-container--with-text pl-32': platformService.isMobileApp
          }"
          qaTags="qa-crd-type-relay"
          class="installation-container installation-container--high installation-container--bordered pointer">
          <p
            *ngIf="platformService.isMobileApp"
            [qaTags]="'qa-lbl-type qa-lbl-relay'"
            class="text">
            {{ "Installation.Main.Relay" | translate }} {{ index + 1 }}
          </p>

          <ng-container
            *ngTemplateOutlet="cells; context: { $implicit: relCard.connections, large: true }"></ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>

  <ng-container *ngIf="platformService.isMobileApp && selectedCard">
    <div class="header-titles">
      <ls-header-title
        (cancel)="resetSelectedCard()"
        [title]="selectedCardTitle"
        [translateNeeded]="false"
        class="mb-5 d-block ports-title"></ls-header-title>
    </div>
    <p class="fz-18 mb-12 mt-12 gray fw-500">
      {{ "Installation.Main.SelectPort" | translate }}
    </p>
    <div class="ports-list">
      <ng-container
        *ngTemplateOutlet="
          cells;
          context: { $implicit: selectedCard | memoizeFunc : getConnections.bind(this) }
        "></ng-container>
    </div>
  </ng-container>

  <ng-template
    #cells
    let-cellArr
    let-large="large">
    <ng-container *ngIf="!platformService.isMobileApp || selectedCard">
      <div
        *ngFor="let connection of cellArr; let i = index"
        (click)="openConnection(connection)"
        [class.installation-cell--disabled]="platformService.isMobileApp && !connection.element?.elementType"
        class="installation-cell d-flex justify-between pointer qa-lnk-con-box">
        <p class="installation-cell__number qa-lnk-con-num">
          {{ i + 1 }}
        </p>
        <div class="installation-cell__child d-flex flex-column justify-between align-center h-100-percent">
          <ng-container
            *ngTemplateOutlet="icon; context: { $implicit: connection.element, large: large }"></ng-container>
          <p
            *ngIf="connection.element"
            class="installation-cell__value">
            {{ connection.element.number | number : "2.0" }}
          </p>
        </div>
      </div>
    </ng-container>

    <ng-container *ngIf="platformService.isMobileApp && !selectedCard">
      <p class="highlighted">{{ (cellArr | memoizeFunc : this.getFilledConnections).length }}/{{ cellArr.length }}</p>
    </ng-container>
  </ng-template>

  <ng-template
    #icon
    let-element
    let-large="large">
    <ng-container *ngIf="element">
      <div class="position-relative">
        <ls-svg-icon
          [color]="ColorsEnum.BlueDefault"
          [fullHeight]="true"
          [hasMargin]="true"
          [overlayPath]="element.isWarning ? 'warning' : undefined"
          [path]="element.elementType | memoizeFunc : ElementTypesEnum.getSvgPath"
          [class.installation-cell__icon--large]="large"
          class="installation-cell__icon"></ls-svg-icon>

        <img
          *ngIf="GlobalConstants.relayIndicatorTypes.includes(element.elementType)"
          class="relay-indicator"
          src="assets/icons/relay-indicator.svg" />
      </div>
    </ng-container>
  </ng-template>

  <ng-template #loading>
    <ls-loading></ls-loading>
  </ng-template>
</div>
