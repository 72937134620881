<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'AirTreatment.AirTreatment'">
  <div
    *ngIf="!platformService.isMobileApp || !editMode"
    class="svg-content header__buttons d-flex align-center justify-between-md">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      class="icon pointer mr-16 mr-10-md qa-btn-edit-mode">
    </ls-svg-icon>
    <ls-svg-icon
      (click)="redirectToSettings()"
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'mobile/settings' : 'installation/settings'"
      class="icon pointer qa-btn-settings">
    </ls-svg-icon>
  </div>

  <div
    [class.md-edit-mode-height]="platformService.isMobileApp && editMode"
    class="main-content settings__form">
    <ls-air-treatment-thresholds-form
      (changed)="changedThresholds($event)"
      [airTreatmentThresholds]="airTreatmentThresholds"
      [editMode]="editMode"
      [isLoading]="isLoading$ | async">
    </ls-air-treatment-thresholds-form>
    <ls-air-treatment-form
      *ngIf="airTreatmentItems?.length || editMode; else empty"
      (changed)="changedItems($event)"
      (deleteDay)="deleteDay($event)"
      [airTreatmentItems]="airTreatmentItems"
      [controllerID]="controllerID"
      [editMode]="editMode"
      [isLoading]="isLoading$ | async">
      <div
        *ngIf="editMode"
        [class.align-center]="platformService.isMobileApp"
        class="buttons-content">
        <div class="d-flex align-center">
          <ls-button
            (click)="cancel()"
            [class.mr-28]="platformService.isMobileApp"
            [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
            [qaTags]="'qa-btn-cancel'"
            [type]="'secondary'"
            class="mr-20 h-button-36">
            {{ "Buttons.Cancel" | translate }}
          </ls-button>
          <ls-button
            (click)="update()"
            [class.disabled]="!isValid || !isValidThresholds || !isDirty"
            [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
            [qaTags]="'qa-btn-update'"
            [type]="'primary'"
            class="h-button-36">
            {{ "Buttons.Save" | translate }}
          </ls-button>
        </div>
      </div>
    </ls-air-treatment-form>
  </div>
  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20" />
</ls-page-wrapper>

<ng-template #empty>
  <div class="d-flex flex-column mt-20">
    <div
      *ngIf="!platformService.isMobileApp"
      class="table w-1-25 w-2-25 w-3-25 w-4-25">
      <div class="table-row">
        <div class="table-cell blue">
          {{ "AirTreatment.Day" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.Humidity" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.CO2" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "AirTreatment.Ammonia" | translate }}
        </div>
      </div>
      <div class="table-row">
        <div
          *ngFor="let i of [].constructor(4); first as first"
          [class.blue]="first"
          class="table-cell no-border-bottom"></div>
      </div>
    </div>
    <div class="empty-state d-flex flex-column justify-center align-center h-100-percent">
      <ls-svg-icon [path]="'heating-program/empty-state'"> </ls-svg-icon>
      <span class="empty-state-title mt-10">
        {{ "AirTreatment.AirTreatmentIsNotConfigured" | translate }}
      </span>
      <ls-button
        (click)="addDefaultDay()"
        [qaTags]="'qa-btn-empty'"
        [type]="'primary'">
        {{ "AirTreatment.ClickHereToConfigure" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>
