<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'Flock.Settings.FlockSetting'">
  <div class="svg-content header__buttons d-flex flex-row align-center justify-between-md">
    <ls-button
      (click)="newFlock()"
      [class.disabled]="editMode"
      [class.h-button-36]="platformService.isMobileApp"
      [class.w-button-110]="platformService.isMobileApp"
      [qaTags]="'qa-btn-new-flock'"
      type="secondary">
      {{ "Flock.Settings.NewFlock" | translate }}
    </ls-button>

    <div class="d-flex flex-row">
      <ls-svg-icon
        (click)="toggleEditMode()"
        [class.disabled]="editMode"
        [path]="'flock/edit-button'"
        class="icon pointer ml-20 qa-btn-edit-mode">
      </ls-svg-icon>
    </div>
  </div>

  <div class="main-content settings__form d-flex flex-row flex-column-md h-100-percent">
    <ls-flock-settings-form
      (changed)="changedSettings($event)"
      [editMode]="editMode"
      [isLoading]="isLoading$ | async"
      [settings]="settings"
      [originalSettings]="originalSettings"
      class="w-100-percent">
      <div
        *ngIf="editMode"
        [class.align-center]="platformService.isMobileApp"
        class="buttons-content">
        <ls-button
          (click)="cancel()"
          [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
          [qaTags]="'qa-btn-cancel'"
          [type]="'secondary'"
          class="page__footer__button mr-28 h-button-36">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>
        <ls-button
          (click)="update()"
          [class.disabled]="!isValid || !isDirty"
          [class.h-button-36]="platformService.isMobileApp"
          [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
          [qaTags]="'qa-btn-update'"
          [type]="'primary'"
          class="page__footer__button">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </ls-flock-settings-form>
  </div>

  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20" />
</ls-page-wrapper>
