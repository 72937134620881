import { createFeatureSelector, createSelector } from '@ngrx/store';
import { SprinklersFoggersProgramState } from './sprinklers-foggers-program.state';

export const SPRINKLERS_FOGGERS_FEATURE_KEY = 'sprinklersFoggersProgram';

export const getSprinklersFoggersProgramState = createFeatureSelector<SprinklersFoggersProgramState>(
  SPRINKLERS_FOGGERS_FEATURE_KEY,
);

export const selectSprinklersFoggersProgramIsLoading = createSelector(
  getSprinklersFoggersProgramState,
  ({ isLoading }) => isLoading,
);

export const selectSprinklersFoggersPeriods = createSelector(
  getSprinklersFoggersProgramState,
  ({ periods }) => periods,
);

export const selectCurrentSprinklersFoggersProgramPeriod = createSelector(
  getSprinklersFoggersProgramState,
  ({ currentPeriod }) => currentPeriod,
);

export const selectCurrentSprinklersFoggersProgram = createSelector(
  getSprinklersFoggersProgramState,
  ({ currentProgram }) => currentProgram,
);