<div
  [style.paddingTop.%]="paddingTop"
  class="d-flex flex-column align-center justify-center">
  <div class="d-flex align-center mb-10">
    <img
      alt="Loading"
      src="/assets/images/loading.gif" />
  </div>
  <span class="color-black">{{ "LoadingWithDots" | translate }}</span>
</div>
