import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-daily-consumption-widget',
  templateUrl: './daily-consumption-widget.component.html',
  styleUrls: ['./daily-consumption-widget.component.scss'],
})
export class DailyConsumptionWidgetComponent {
}
