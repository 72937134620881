<section class="dashboard d-flex justify-between">
  <div class="dashboard-left d-flex flex-column justify-between h-100-percent w-33-percent">
    <ls-ventilation-widget
      (click)="changeMainIndicator(DashboardMainIndicator.VENTILATION)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.VENTILATION"></ls-ventilation-widget>
    <ls-heat-widget
      (click)="changeMainIndicator(DashboardMainIndicator.HEAT)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.HEAT"></ls-heat-widget>
    <ls-inlet-widget
      (click)="changeMainIndicator(DashboardMainIndicator.INLET)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.INLET"></ls-inlet-widget>
    <ls-cooling-widget
      (click)="changeMainIndicator(DashboardMainIndicator.COOLING)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.COOLING"></ls-cooling-widget>
  </div>

  <div class="dashboard-middle d-flex flex-column justify-between h-100-percent w-33-percent">
    <ng-container [ngSwitch]="mainIndicator">
      <ls-main-ventilation-widget
        *ngSwitchCase="DashboardMainIndicator.VENTILATION"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-ventilation-widget>
      <ls-main-heat-widget
        *ngSwitchCase="DashboardMainIndicator.HEAT"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-heat-widget>
      <ls-main-inlet-widget
        *ngSwitchCase="DashboardMainIndicator.INLET"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-inlet-widget>
      <ls-main-cooling-widget
        *ngSwitchCase="DashboardMainIndicator.COOLING"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-cooling-widget>
      <ls-main-temperature-widget
        *ngSwitchCase="DashboardMainIndicator.TEMPERATURE"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-temperature-widget>
      <ls-main-pressure-widget
        *ngSwitchCase="DashboardMainIndicator.PRESSURE"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-pressure-widget>
      <ls-main-air-quality-widget
        *ngSwitchCase="DashboardMainIndicator.AIR_QUALITY"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-air-quality-widget>
      <ls-main-daily-consumption-widget
        *ngSwitchCase="DashboardMainIndicator.DAILY_CONSUMPTION"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-daily-consumption-widget>
      <ls-main-bird-widget
        *ngSwitchCase="DashboardMainIndicator.BIRD"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-bird-widget>
      <ls-main-light-widget
        *ngSwitchCase="DashboardMainIndicator.LIGHT"
        (closeWidget)="resetMainIndicator()"
        class="main-widget"></ls-main-light-widget>

      <ls-main-indicator-widget
        *ngSwitchDefault
        class="main-widget"></ls-main-indicator-widget>
    </ng-container>

    <ls-temperature-widget
      (click)="changeMainIndicator(DashboardMainIndicator.TEMPERATURE)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.TEMPERATURE"></ls-temperature-widget>
  </div>

  <div class="dashboard-right d-flex flex-column justify-between h-100-percent w-33-percent">
    <ls-pressure-widget
      (click)="changeMainIndicator(DashboardMainIndicator.PRESSURE)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.PRESSURE"
      class="left-widget"></ls-pressure-widget>
    <ls-air-quality-widget
      (click)="changeMainIndicator(DashboardMainIndicator.AIR_QUALITY)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.AIR_QUALITY"
      class="left-widget"></ls-air-quality-widget>
    <ls-daily-consumption-widget
      (click)="changeMainIndicator(DashboardMainIndicator.DAILY_CONSUMPTION)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.DAILY_CONSUMPTION"
      class="left-widget">
    </ls-daily-consumption-widget>
    <ls-bird-widget
      (click)="changeMainIndicator(DashboardMainIndicator.BIRD)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.BIRD"
      class="left-widget"></ls-bird-widget>
    <ls-light-widget
      (click)="changeMainIndicator(DashboardMainIndicator.LIGHT)"
      [class.widget-active]="mainIndicator === DashboardMainIndicator.LIGHT"
      class="left-widget"></ls-light-widget>
  </div>
</section>
