import { Injectable } from '@angular/core';
import { BehaviorSubject, firstValueFrom } from 'rxjs';
import { selectCurrentConnectionSetupIsDirty, selectElementIsLoading } from '@livestock/installation';
import { DialogButtonEnum } from '@livestock/shared/enums';
import { DialogsService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';

@Injectable({
  providedIn: 'root',
})
export class InstallationHelpersService {
  isTableView$ = new BehaviorSubject<boolean>(false);

  constructor(
    private store: Store,
    private dialogsService: DialogsService,
  ) {
  }

  async canLeaveDirtyElementSetup(): Promise<boolean> {
    const isElementLoading = await firstValueFrom(this.store.select(selectElementIsLoading));
    if (isElementLoading) {
      return false;
    }

    const isCurrentElementSetupDirty = await firstValueFrom(this.store.select(selectCurrentConnectionSetupIsDirty));
    if (isCurrentElementSetupDirty) {
      return await this.dialogsService.canContinueAction();
    }

    return true;
  }

  async deleteElementConfirmation(): Promise<boolean> {
    const title = 'Installation.Popups.UninstallElement';
    const message = 'Installation.Popups.UninstallElementMessage';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.NO, DialogButtonEnum.YES],
    );

    if (!result || result === DialogButtonEnum.NO) {
      return false;
    }

    return true;
  }
}
