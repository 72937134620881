export enum HouseModeEnum {
    Growing = 0,
    Empty = 1,
    PreHeat = 2,
    Cleaning = 3,
    Catching = 4,
}

export namespace HouseModeEnum {
    export function toTranslateKey(value: any): string {
        switch (value) {
            case HouseModeEnum.Growing:
                return 'Flock.Settings.HouseModeEnum.Growing';
            case HouseModeEnum.Empty:
                return 'Flock.Settings.HouseModeEnum.Empty';
            case HouseModeEnum.PreHeat:
                return 'Flock.Settings.HouseModeEnum.PreHeat';
            case HouseModeEnum.Cleaning:
                return 'Flock.Settings.HouseModeEnum.Cleaning';
            case HouseModeEnum.Catching:
                return 'Flock.Settings.HouseModeEnum.Catching';
            default:
                return value.toString();
        };
    }
}