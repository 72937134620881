import { Action, createReducer, on } from '@ngrx/store';
import * as ventBrandsActions from './ventilation-brands.actions';
import { VentilationBrandsState } from './ventilation-brands.state';

export const initialState: VentilationBrandsState = {
  isLoading: false,
  ventilationBrands: [],
  ventilationDOModels: [],
  ventilationAOModels: [],
};

const reducer = createReducer(
  initialState,
  on(
    ventBrandsActions.getVentilationBrands,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(ventBrandsActions.getVentilationModels, (state, { brandID, isDO }) => {
    const isLoading = isDO
      ? state.ventilationDOModels.some(model => model.brandID === brandID)
      : state.ventilationAOModels.some(model => model.brandID === brandID);

    return {
      ...state,
      isLoading,
    };
  }),
  on(
    ventBrandsActions.getVentilationBrandsError,
    ventBrandsActions.getVentilationModelsError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(ventBrandsActions.getVentilationBrandsSuccess, (state, { ventilationBrands }) => {
      return {
        ...state,
        ventilationBrands: ventilationBrands || state.ventilationBrands,
        isLoading: false,
      };
    },
  ),
  on(ventBrandsActions.getVentilationModelsAOSuccess, (state, { ventilationModels }) => {
      return {
        ...state,
        ventilationAOModels: [...state.ventilationAOModels, ...ventilationModels],
        isLoading: false,
      };
    },
  ),
  on(ventBrandsActions.getVentilationModelsDOSuccess, (state, { ventilationModels }) => {
      return {
        ...state,
        ventilationDOModels: [...state.ventilationDOModels, ...ventilationModels],
        isLoading: false,
      };
    },
  ),
);

export function ventilationBrandsReducer(state: VentilationBrandsState | undefined, action: Action): any {
  return reducer(state, action);
}
