import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormArray, FormBuilder, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  DualToggleComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LVInputIntegerWithLabelComponent,
  SlimButtonComponent,
  SvgIconComponent,
  ThemeEnum,
} from '@livestock/ui';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { FlockConstants, IFlockDefaultWeightView, IFlockSettingsView, StagingEnum } from '@livestock/flock';
import {
  ButtonIconPositionEnum,
  ButtonTypeEnum,
  ChickenBrandWeight,
  ColorsEnum,
  IconsEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { FlockSettingsConstants } from '../../../../../flock/src/lib/constants/flock-settings.constants';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import {
  VirtualKeyboardConstants,
} from '../../../../../ui/src/lib/controls/virtual-keyboard/virtual-keyboard.constants';
import { FlockDeleteDayComponent } from './flock-delete-day/flock-delete-day.component';
import { getFlockWeightReferenceTable } from '../../+state/upsert-controller/upsert-controller-device.actions';

@Component({
  selector: 'lv-flock-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    QaTagsDirective,
    LVInputIntegerWithLabelComponent,
    DualToggleComponent,
    EnumPipe,
    ErrorFieldDirective,
    MatCheckboxModule,
    InputDecimalComponent,
    SlimButtonComponent,
    EnumToArrayPipe,
    InputIntegerComponent,
    FlockDeleteDayComponent,
  ],
  templateUrl: './flock-settings-form.component.html',
  styleUrls: ['./flock-settings-form.component.scss'],
})
export class FlockSettingsFormComponent implements OnInit, OnDestroy {
  @ViewChild('referenceTable') referenceTable: ElementRef;
  @ViewChild('weightTableValues') weightTableValues: ElementRef;

  @Input() set defaultWeights(defaultWeights: IFlockDefaultWeightView[]) {
    if (!defaultWeights) return;

    this._defaultWeights = defaultWeights;
    this.initWeightForm();
  }

  @Input() set flockSettings(flockSettings: IFlockSettingsView) {
    if (flockSettings) {
      this.updateSettingsFormValues(flockSettings);
    }
  }

  @Input() deviceLogic: boolean;
  @Input() set weightUnit(weightUnit: WeightUnitEnum) {
    this._weightUnit = weightUnit;
    this.maxWeight = weightUnit === WeightUnitEnum.Pound
      ? FlockSettingsConstants.WeightMaxPound
      : FlockSettingsConstants.WeightMaxKg;
  }

  @Output() changedFlockSettings = new EventEmitter();
  @Output() changedFlockWeights = new EventEmitter();

  sub$ = new Subscription();

  settingsForm = new FormGroup({
    flockNumber: new FormControl<number>({
      value: 1,
      disabled: true,
    }, [
      Validators.min(FlockSettingsConstants.FlockNumberMin),
      Validators.max(FlockSettingsConstants.FlockNumberMax),
    ]),
    emptyHouse: new FormControl<boolean>(true),
    growthDay: new FormControl<number>(null, [
      Validators.min(FlockSettingsConstants.GrowthDayMin),
      Validators.max(FlockSettingsConstants.GrowthDayMax),
    ]),
    separateMaleAndFemale: new FormControl<boolean>(false),
    initialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockSettingsConstants.InitialNumberOfBirdsMin),
      Validators.max(FlockSettingsConstants.InitialNumberOfBirdsMax),
    ]),
    maleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockSettingsConstants.MaleInitialNumberOfBirdsMin),
      Validators.max(FlockSettingsConstants.MaleInitialNumberOfBirdsMax),
    ]),
    femaleInitialNumberOfBirds: new FormControl<number>(0, [
      Validators.min(FlockSettingsConstants.FemaleInitialNumberOfBirdsMin),
      Validators.max(FlockSettingsConstants.FemaleInitialNumberOfBirdsMax),
    ]),
    staging: new FormControl<StagingEnum>(StagingEnum.FullHouse),
    curveOffset: new FormControl<number>(0, [
      Validators.min(FlockSettingsConstants.CurveOffsetMin),
      Validators.max(FlockSettingsConstants.CurveOffsetMax),
    ]),
    defaultWeightBrandID: new FormControl<ChickenBrandWeight>(ChickenBrandWeight.COBB_500),
  });
  weightForm: FormGroup;
  curveOffset: number;
  maxWeight = FlockSettingsConstants.WeightMaxPound;

  // when input was focused we mark the row as selected
  selectedRow: number;

  // we also can mark multiple rows as selected by clicking checkmark in the table
  selectedDays: number[] = [];

  tableIsOpened: boolean;
  maxNumberPerGenderMessageIsShown: boolean;
  maxNumberOfBirdsMessageIsShown: boolean;
  deleteDaysPopupIsShowed: boolean;
  referenceTablePopupIsOpened: boolean;
  curveOffsetRangeIsShown: boolean;
  growthDayFocused: boolean;
  followingDayWeightError = {
    weight: false,
    femaleWeight: false,
    maleWeight: false,
  };

  // constants
  FlockSettingsConstants = FlockSettingsConstants;
  ChickenBrandWeight = ChickenBrandWeight;
  StagingEnum = StagingEnum;
  WeightUnitEnum = WeightUnitEnum;
  ThemeEnum = ThemeEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  ButtonTypeEnum = ButtonTypeEnum;
  ButtonIconPositionEnum = ButtonIconPositionEnum;
  VirtualKeyboardConstants = VirtualKeyboardConstants;
  weightProperties = ['weight', 'femaleWeight', 'maleWeight'];
  weightInputWidth = 70;
  minRowsForScroll = 9;

  private _weightUnit: WeightUnitEnum = WeightUnitEnum.Kilogram;
  private _defaultWeights: IFlockDefaultWeightView[] = [];

  get hasFollowingWeightError(): boolean {
    return this.followingDayWeightError.weight
      || this.followingDayWeightError.maleWeight
      || this.followingDayWeightError.femaleWeight;
  }

  get weightUnit(): WeightUnitEnum {
    return this._weightUnit;
  }

  get defaultWeights(): IFlockDefaultWeightView[] {
    return this._defaultWeights;
  }

  get incrementDecrementIconsColor(): ColorsEnum {
    if (this.settingsForm.controls.growthDay.invalid) return ColorsEnum.MainDarkRed;
    if (this.growthDayFocused) return ColorsEnum.MainBlue;
    return ColorsEnum.MonoGrey;
  }

  get curveOffsetArrowColor(): ColorsEnum {
    if (this.settingsForm.controls.curveOffset.invalid) return ColorsEnum.MainDarkRed;
    if (this.tableIsOpened) return ColorsEnum.MainBlue;
    return ColorsEnum.MonoGrey;
  }

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private formBuilder: FormBuilder,
    private store: Store,
    private renderer: Renderer2,
  ) {
    this.renderer.listen('window', 'click', (e: Event) => {
      if (e.composedPath().indexOf(this.referenceTable?.nativeElement) === -1) {
        this.referenceTablePopupIsOpened = false;
      }

      if (e.composedPath().indexOf(this.weightTableValues?.nativeElement) === -1) {
        this.selectedRow = null;
      }
    });
  }

  ngOnInit(): void {
    this.sub$.add(
      this.settingsForm.valueChanges.pipe(wasChanged()).subscribe((formValues) => {
        const flockNumber = this.settingsForm.getRawValue().flockNumber;
        this.changedFlockSettings.emit({
          formValues: {
            ...formValues,
            flockNumber,
          },
          isValid: this.settingsForm.valid,
        });
      }),
    );
  }

  updateSettingsFormValues(flockSettings: IFlockSettingsView): void {
    if (this.curveOffset == null) this.curveOffset = flockSettings.curveOffset;

    this.settingsForm.patchValue({
      ...flockSettings,
      growthDay: flockSettings.emptyHouse ? null : flockSettings.growthDay,
    });
  }

  showWeightTable(): void {
    this.tableIsOpened = true;
  }

  setReferenceTablePopupIsOpened(referenceTablePopupIsOpened: boolean): void {
    this.referenceTablePopupIsOpened = referenceTablePopupIsOpened;
  }

  showMaxNumberPerGenderMessage(isShowed: boolean): void {
    this.maxNumberPerGenderMessageIsShown = isShowed;
  }

  showMaxNumberOfBirdsMessage(isShowed: boolean): void {
    this.maxNumberOfBirdsMessageIsShown = isShowed;
  }

  selectRow(selectedRow: number | null): void {
    this.selectedRow = selectedRow;
  }

  checkDay(event: MatCheckboxChange, index: number, day: number): void {
    if (event.checked) {
      this.selectedDays = [...this.selectedDays, day];
      return;
    }

    this.selectedDays = this.selectedDays.filter(selectedDay => selectedDay !== day);
    if (this.selectedRow === index) {
      setTimeout(() => this.selectedRow = null);
    }
  }

  setReferenceTable(referenceTable: ChickenBrandWeight): void {
    if (this.settingsForm.controls.defaultWeightBrandID.value === referenceTable) return;

    this.settingsForm.controls.defaultWeightBrandID.setValue(referenceTable);
    this.settingsForm.controls.defaultWeightBrandID.updateValueAndValidity();
    this.referenceTablePopupIsOpened = false;
    this.store.dispatch(getFlockWeightReferenceTable({ brand: referenceTable, deviceLogic: this.deviceLogic }));
  }

  getWeightItemsArray(): FormArray {
    return this.weightForm.controls['items'] as FormArray;
  }

  addDay(rowIndex: number, emptyTable: boolean = false): void {
    const itemsArr = this.getWeightItemsArray();
    const currentRowIndexValue = emptyTable
      ? this.defaultWeights[0]
      : itemsArr.controls[rowIndex].value;

    itemsArr.insert(emptyTable ? 0 : rowIndex + 1, new FormGroup({
      controllerID: new FormControl<number>(null),
      day: new FormControl<number>(emptyTable ? 0 : currentRowIndexValue.day + 1, [
        Validators.required,
        Validators.min(FlockSettingsConstants.FirstDay),
        Validators.max(FlockSettingsConstants.LastDay),
      ]),
      weight: new FormControl<number>(currentRowIndexValue.weight, Validators.max(this.maxWeight)),
      femaleWeight: new FormControl<number>(currentRowIndexValue.femaleWeight, Validators.max(this.maxWeight)),
      maleWeight: new FormControl<number>(currentRowIndexValue.maleWeight, Validators.max(this.maxWeight)),
    }));

    setTimeout(() => this.selectedRow = emptyTable ? 0 : rowIndex + 1);
  }

  deleteDays(): void {
    const itemsArr = this.getWeightItemsArray();

    this.selectedDays.forEach(selectedDay => {
      const index = itemsArr.controls.findIndex(control => control.value.day === selectedDay);
      itemsArr.removeAt(index);
    });

    this.selectedDays = [];
    this.showDeleteDaysPopup(false);
  }

  showDeleteDaysPopup(showPopup: boolean): void {
    this.deleteDaysPopupIsShowed = showPopup;
  }

  initWeightForm(): void {
    // mockup
    this.weightForm = this.formBuilder.group({
      items: this.formBuilder.array(Array.from({ length: this.defaultWeights.length }).map((_item, index) => {
        return new FormGroup(
          {
            controllerID: new FormControl<number>(this.defaultWeights[index]?.controllerID),
            day: new FormControl<number>(this.defaultWeights[index].day, [
              Validators.required,
              Validators.min(FlockConstants.MinBirdWeightDay),
              Validators.max(FlockConstants.MaxBirdWeightDay),
            ]),
            weight: new FormControl<number>(this.defaultWeights[index].weight || 0, Validators.max(this.maxWeight)),
            femaleWeight: new FormControl<number>(this.defaultWeights[index].femaleWeight || 0, Validators.max(this.maxWeight)),
            maleWeight: new FormControl<number>(this.defaultWeights[index].maleWeight || 0, Validators.max(this.maxWeight)),
          },
        );
      })),
    });

    this.sub$.add(
      this.weightForm?.valueChanges.pipe(wasChanged()).subscribe((formValues) => {
        this.weightProperties.forEach(property => this.validateWeightProperty(property));
        this.weightForm.updateValueAndValidity();
        this.changedFlockWeights.emit({
          formValues: formValues['items'],
          isValid: this.weightForm.valid,
        });
      }),
    );
  }

  recalculateWeight(): void {
    this.showCurveOffsetRange(false);
    const currentCurveOffset = this.settingsForm.value.curveOffset;
    if (this.curveOffset === currentCurveOffset) return;
    if (currentCurveOffset < FlockSettingsConstants.CurveOffsetMin || currentCurveOffset > FlockSettingsConstants.CurveOffsetMax) return;

    const calculateWeight = (value: number): number => {
      return +(value + value * (currentCurveOffset / 100)).toFixed(3);
    };

    const items = this.weightForm.getRawValue().items.map(item => {
      return {
        ...item,
        weight: calculateWeight(item.weight),
        femaleWeight: calculateWeight(item.femaleWeight),
        maleWeight: calculateWeight(item.maleWeight),
      };
    });

    this.weightForm.patchValue({
      items,
    });
    this.weightForm.updateValueAndValidity();
    this.curveOffset = currentCurveOffset;
  }

  setGrowthDay(value: number = 0): void {
    this.settingsForm.controls.growthDay.setValue(this.settingsForm.value.emptyHouse ? null : value);
    this.settingsForm.updateValueAndValidity();
  }

  showCurveOffsetRange(showRange: boolean): void {
    this.curveOffsetRangeIsShown = showRange;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private validateWeightProperty(property: string): void {
    const weightsControls: FormGroup[] = this.getWeightItemsArray().controls as FormGroup[];
    let stopValidation = false;

    weightsControls.forEach((control, index) => {
      if (stopValidation) return;
      const currentControl = control.controls[property];
      const nextControl = weightsControls[index + 1]?.controls[property];
      const prevControl = weightsControls[index - 1]?.controls[property];
      const currentValue = currentControl.value;
      const nextValue = nextControl?.value;
      const prevValue = prevControl?.value;

      if (currentValue > nextValue) {
        this.followingDayWeightError[property] = true;
        currentControl.setValidators(Validators.max(nextValue));
        nextControl.setValidators(Validators.min(currentValue));
      }

      if (currentValue < prevValue) {
        this.followingDayWeightError[property] = true;
        currentControl.setValidators(Validators.min(prevValue));
        prevControl.setValidators(Validators.max(currentValue));
      }

      if (currentValue > nextValue || currentValue < prevValue) {
        currentControl.updateValueAndValidity();
        nextControl?.updateValueAndValidity();
        prevControl?.updateValueAndValidity();
        stopValidation = true;
        return;
      }

      this.followingDayWeightError[property] = false;
      currentControl.setValidators([Validators.min(0), Validators.max(this.maxWeight)]);
      nextControl?.setValidators([Validators.min(0), Validators.max(this.maxWeight)]);
      prevControl?.setValidators([Validators.min(0), Validators.max(this.maxWeight)]);
      currentControl.updateValueAndValidity();
      nextControl?.updateValueAndValidity();
      prevControl?.updateValueAndValidity();
    });
  }
}
