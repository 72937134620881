<div class="d-flex align-center justify-between mb-15">
  <ls-header-title
    (cancel)="returnBack()"
    [arrowColor]="platformService.isMobileApp ? ColorsEnum.Black : ColorsEnum.Default"
    [qaTagsForTitle]="'qa-lbl-install-type'"
    [title]="currentElementType | memoizeFunc : getSettingsTitle.bind(this)"
    [translateNeeded]="false"
    class="d-block"></ls-header-title>

  <ls-svg-icon
    (click)="toggleEditMode()"
    [class.disabled]="isEditMode"
    [color]="platformService.isMobileApp && isEditMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
    [path]="platformService.isMobileApp ? 'installation/edit-mobile' : 'installation/edit'"
    [qaTags]="'qa-btn-edit'"
    class="pointer"></ls-svg-icon>
</div>

<div
  [class.override-width]="currentElementType | memoizeFunc : helper.isElementFullWidthOnMobile"
  class="d-flex align-end justify-between h-100-percent">
  <div
    [class.settings-body__with-keyboard]="!platformService.isMobileApp && isEditMode"
    class="settings-body d-flex flex-column justify-between h-100-percent">
    <form
      *ngIf="parentForm && !(isElementLoading$ | async); else loading"
      [formGroup]="parentForm"
      class="settings-body__form">
      <!-- selected element settings form -->
      <ng-container [ngSwitch]="currentElementType">
        <ls-cooling-settings-form
          *ngSwitchCase="ElementTypesEnum.Cooling"
          (setIsDirty)="isDirty = $event"
          [formGroupName]="childGroupName"
          [isEditMode]="isEditMode"
          [isResetForm$]="isResetFormSubj$.asObservable()"
          [isSaveForm$]="isSaveFormSubj$.asObservable()"></ls-cooling-settings-form>

        <ls-heating-settings-form
          *ngSwitchCase="ElementTypesEnum.HeatingDO"
          (setIsDirty)="isDirty = $event"
          [formGroupName]="childGroupName"
          [isEditMode]="isEditMode"
          [isResetForm$]="isResetFormSubj$.asObservable()"
          [isSaveForm$]="isSaveFormSubj$.asObservable()">
        </ls-heating-settings-form>

        <ls-heating-settings-form
          *ngSwitchCase="ElementTypesEnum.HeatingAO"
          (setIsDirty)="isDirty = $event"
          [formGroupName]="childGroupName"
          [isEditMode]="isEditMode"
          [isResetForm$]="isResetFormSubj$.asObservable()"
          [isSaveForm$]="isSaveFormSubj$.asObservable()">
        </ls-heating-settings-form>

        <ls-sprinklers-and-foggers-settings-form
          *ngSwitchCase="ElementTypesEnum.SprinklersAndFoggers"
          (setIsDirty)="isDirty = $event"
          [formGroupName]="childGroupName"
          [isEditMode]="isEditMode"
          [isResetForm$]="isResetFormSubj$.asObservable()"
          [isSaveForm$]="isSaveFormSubj$.asObservable()"></ls-sprinklers-and-foggers-settings-form>

        <ls-static-pressure-settings-form
          *ngSwitchCase="ElementTypesEnum.StaticPressure"
          (setIsDirty)="isDirty = $event"
          [formGroupName]="childGroupName"
          [isEditMode]="isEditMode"
          [isResetForm$]="isResetFormSubj$.asObservable()"
          [isSaveForm$]="isSaveFormSubj$.asObservable()"></ls-static-pressure-settings-form>

        <ng-container
          *ngSwitchCase="ElementTypesEnum.LightDO"
          [ngTemplateOutlet]="lightingSettings"></ng-container>
        <ng-container
          *ngSwitchCase="ElementTypesEnum.LightingSensor"
          [ngTemplateOutlet]="lightingSettings"></ng-container>
        <ng-container
          *ngSwitchCase="ElementTypesEnum.LightAO"
          [ngTemplateOutlet]="lightingSettings"></ng-container>
        <ng-template #lightingSettings>
          <ls-lighting-settings-form
            (isTimePickerSelected)="isTimePickerSelected = $event"
            (setIsDirty)="isDirty = $event"
            [formGroupName]="childGroupName"
            [hoursFormat]="currentHoursFormat$ | async"
            [isEditMode]="isEditMode"
            [isResetForm$]="isResetFormSubj$.asObservable()"
            [isSaveForm$]="isSaveFormSubj$.asObservable()">
          </ls-lighting-settings-form>
        </ng-template>
      </ng-container>
    </form>

    <div
      *ngIf="isEditMode"
      class="settings-form__buttons d-flex justify-end gap-20">
      <ls-button
        (click)="toggleEditMode()"
        [qaTags]="'qa-btn-cancel'"
        class="w-120"
        type="secondary"
        >{{ "Buttons.Cancel" | translate }}</ls-button
      >
      <ls-button
        (click)="save()"
        [class.disabled]="!parentForm.dirty || parentForm.invalid"
        [qaTags]="'qa-btn-save'"
        class="w-120"
        type="primary"
        >{{ "Buttons.Save" | translate }}</ls-button
      >
    </div>
  </div>

  <ls-keyboard
    *ngIf="!platformService.isMobileApp && isEditMode"
    [hasTimePicker]="
      !platformService.isMobileApp && isTimePickerSelected && (currentHoursFormat$ | async) === HoursFormatTypeEnum.AmPm
    "></ls-keyboard>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
