<ng-container *ngIf="parentFormGroup.get(formGroupName)">
  <div [formGroupName]="formGroupName">
    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-cooling-days'">{{
        "Installation.Card.SprinklersAndFoggers.DayForSprinklersFoggers" | translate
      }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.sprinklersFoggersSettings.minDay.max"
          [min]="ElementsValidationConstants.sprinklersFoggersSettings.minDay.min"
          [qaTags]="'qa-txt-cooling-days'"
          errorField
          formControlName="minDay"></ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.SprinklersAndFoggers.Min" | translate }}</span>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-humidity-level'">{{
        "Installation.Card.SprinklersAndFoggers.HumidityLevelToRestartSprinklersFoggers" | translate
      }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.sprinklersFoggersSettings.humLevelToRestart.max"
          [min]="ElementsValidationConstants.sprinklersFoggersSettings.humLevelToRestart.min"
          [qaTags]="'qa-txt-humidity-level'"
          errorField
          formControlName="humLevelToRestart"></ls-input-integer>
        <span class="unit ml-10">{{ GlobalConstants.PercentageSymbol }}</span>
      </div>
    </div>

    <div class="settings-field form-field">
      <label [qaTags]="'qa-lbl-cooling-pad'">{{
        "Installation.Card.SprinklersAndFoggers.TimeToFillPipeLine" | translate
      }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.sprinklersFoggersSettings.timeToWetPad.max"
          [min]="ElementsValidationConstants.sprinklersFoggersSettings.timeToWetPad.min"
          [qaTags]="'qa-lbl-cooling-pad'"
          errorField
          formControlName="timeToWetPad"></ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.Cooling.Sec" | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
