export class FlockSettingsConstants {
  static GrowthDayMin = -4;
  static GrowthDayMax = 99;

  static FlockNumberMin = 1;
  static FlockNumberMax = 999;

  static InitialNumberOfBirdsMin = 0;
  static InitialNumberOfBirdsMax = 200000;

  static FemaleInitialNumberOfBirdsMin = 0;
  static FemaleInitialNumberOfBirdsMax = 100000;

  static MaleInitialNumberOfBirdsMin = 0;
  static MaleInitialNumberOfBirdsMax = 100000;

  static CurveOffsetMin = -15;
  static CurveOffsetMax = 15;

  static FirstDay = 0;
  static LastDay = 99;

  static WeightMin = 0.000;
  static WeightMaxKg = 10.000;
  static WeightMaxPound = 22.046;
}
