export enum UserTableColumnsEnum {
  No,
  FirstName,
  LastName,
  Email,
  Phone,
  Role,
  ID,
  Status,
  Actions,
}

export namespace UserTableColumnsEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case UserTableColumnsEnum.No:
        return 'Farms.Users.UserTableColumnsEnum.No';
      case UserTableColumnsEnum.FirstName:
        return 'Farms.Users.UserTableColumnsEnum.FirstName';
      case UserTableColumnsEnum.LastName:
        return 'Farms.Users.UserTableColumnsEnum.LastName';
      case UserTableColumnsEnum.Email:
        return 'Farms.Users.UserTableColumnsEnum.Email';
      case UserTableColumnsEnum.Phone:
        return 'Farms.Users.UserTableColumnsEnum.Phone';
      case UserTableColumnsEnum.Role:
        return 'Farms.Users.UserTableColumnsEnum.Role';
      case UserTableColumnsEnum.ID:
        return 'Farms.Users.UserTableColumnsEnum.ID';
      case UserTableColumnsEnum.Status:
        return 'Farms.Users.UserTableColumnsEnum.Status';
      case UserTableColumnsEnum.Actions:
        return 'Farms.Users.UserTableColumnsEnum.Actions';
      default:
        return value.toString();
    }
    ;
  }
}
