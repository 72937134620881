import { TempMappingStagesEnum } from '@livestock/temp-mapping/models';
import { ITempMappingSensorElement } from './temp-mapping-sensor-element.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElement } from '@livestock/installation/interfaces';

export interface ITempMappingItem {
  temperatureMappingID: number;
  controllerID: number;
  type: TempMappingStagesEnum;
  average: boolean;
  sensors: ITempMappingSensorElement[] | IElement[];
  elementID: number;
  elementNumber: number;
  elementType: ElementTypesEnum;
  elementName: string;
  zoneID: number;
}
