import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable, map } from 'rxjs';
import { ICard } from '../interfaces/card.interface';
import { IElementType } from '../interfaces/element/element-type.interface';
import { ICreateElement } from '../interfaces/element/create-element.interface';
import { IGetOrUpdateElement } from '../interfaces/element/get-or-update-element.interface';
import { IElementSettings } from '../interfaces/element/element-settings.interface';
import { IGetMultipleSetupsForElementType } from '../interfaces/element/get-multiple-setups-for-element-type.interface';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElementTestingData } from '../interfaces/element/element-testing-interface';
import { TimeUtils } from '@livestock/shared/utils';
import { IControllerElementsInfo } from '@livestock/installation/interfaces';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class InstallationApiService {
  constructor(private http: HttpClient) {
  }

  getControllerCards(controllerID: number): Observable<{ items: ICard[] }> {
    return this.http.get<{ items: ICard[] }>(`${baseUrl}/${controllerID}/cards`);
  }

  getCardElementTypes(controllerID: number, cardID: number): Observable<{ items: IElementType[] }> {
    return this.http.get<{ items: IElementType[] }>(`${baseUrl}/${controllerID}/cards/${cardID}/element-types`);
  }

  /* ELEMENTS CRUD */
  getElement(controllerID: number, cardID: number, connectionID: number, elementID: number, elName: string): Observable<IGetOrUpdateElement> {
    return this.http.get<IGetOrUpdateElement>(`${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/${elementID}/setup`);
  }

  createElement(controllerID: number, cardID: number, connectionID: number, view: ICreateElement, elName: string): Observable<ICreateElement> {
    return this.http.post<ICreateElement>(
      `${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}`,
      view,
    );
  }

  updateElement(controllerID: number, cardID: number, connectionID: number, elementID: number, view: IGetOrUpdateElement, elName: string): Observable<IGetOrUpdateElement> {
    return this.http.put<IGetOrUpdateElement>(
      `${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/${elementID}/setup`,
      view,
    );
  }

  deleteElement(controllerID: number, cardID: number, connectionID: number, elementID: number, elName: string): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/${elementID}`);
  }

  getMultipleSetupsForElementType(controllerID: number, cardID: number, connectionID: number, elName: string): Observable<IGetMultipleSetupsForElementType> {
    return this.http.get<IGetMultipleSetupsForElementType>(`${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/setup`);
  }

  /* ELEMENT SETTINGS*/
  getElementSettings(
    controllerID: number,
    elementType: ElementTypesEnum): Observable<IElementSettings> {
    switch (elementType) {
      case ElementTypesEnum.HeatingDO:
      case ElementTypesEnum.HeatingAO:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/heating-settings`);
      case ElementTypesEnum.Cooling:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/cooling-settings`);
      case ElementTypesEnum.LightAO:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.LightingSensor:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/lighting-settings`).pipe(
          map((value) => {
            if (value?.lightAOSettings) {
              return {
                ...value,
                lightAOSettings: {
                  ...value.lightAOSettings,
                  dimmingStartTime: TimeUtils.BackendValueToMoment(value.lightAOSettings.dimmingStartTime as number),
                },
              };
            }
            return value;
          }),
        );
      case ElementTypesEnum.SprinklersAndFoggers:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/sprinklers-foggers-settings`);
      case ElementTypesEnum.StaticPressure:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/static-pressure-settings`);
      default:
        return this.http.get<IElementSettings>(`${baseUrl}/${controllerID}/settings`);
    }
  }

  updateElementSettings(
    controllerID: number,
    view: IElementSettings,
    elementType: ElementTypesEnum,
  ): Observable<IElementSettings> {

    let parsedView: IElementSettings;

    switch (elementType) {
      case ElementTypesEnum.HeatingDO:
      case ElementTypesEnum.HeatingAO:
        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/heating-settings`, view);
      case ElementTypesEnum.Cooling:
        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/cooling-settings`, view);
      case ElementTypesEnum.LightAO:
      case ElementTypesEnum.LightDO:
      case ElementTypesEnum.LightingSensor:
        parsedView = {
          ...view,
          lightAOSettings: {
            ...view.lightAOSettings,
            dimmingStartTime: view.lightAOSettings && TimeUtils.MomentToBackendValue(view.lightAOSettings.dimmingStartTime),
          },
        };

        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/lighting-settings`, parsedView);
      case ElementTypesEnum.SprinklersAndFoggers:
        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/sprinklers-foggers-settings`, view);
      case ElementTypesEnum.StaticPressure:
        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/static-pressure-settings`, view);
      default:
        return this.http.put<IElementSettings>(`${baseUrl}/${controllerID}/settings`, view);
    }
  }

  getElementTestingData(
    controllerID: number,
    cardID: number,
    connectionID: number,
    elementID: number,
    elName: string,
  ): Observable<IElementTestingData> {
    return this.http.get<IElementTestingData>(`${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/${elementID}/test`);
  }

  updateElementTestingData(
    controllerID: number,
    cardID: number,
    connectionID: number,
    elementID: number,
    elName: string,
    testingData: IElementTestingData,
  ): Observable<IElementTestingData> {
    return this.http.put<IElementTestingData>(`${baseUrl}/${controllerID}/card/${cardID}/connection/${connectionID}/${elName}/${elementID}/test`, testingData);
  }

  //controller menu
  getControllerElementsInfo(controllerID: number): Observable<IControllerElementsInfo> {
    return this.http.get<IControllerElementsInfo>(`${baseUrl}/${controllerID}/elements/info`);
  }
}
