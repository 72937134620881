import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, HeaderTitleComponent, LoadingComponent, SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import {
  disconnectController, IControllerView, ICreateAddControllerTicketView, INetworkSettingsView, reconnectController,
  selectControllerInfo, selectCurrentControllerIsDisconnected, selectCurrentControllerIsLoading,
  selectNetworkSettings, selectTicketView,
} from '@livestock/controllers';
import { Store } from '@ngrx/store';
import { firstValueFrom, Observable } from 'rxjs';
import { AlignmentEnum, DialogButtonEnum } from '@livestock/shared/enums';
import { QRCodeModule } from 'angularx-qrcode';
import { QaTagsDirective } from '@livestock/shared/directives';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
  selector: 'ls-controller-cloud-settings-device',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    ButtonComponent,
    HeaderTitleComponent,
    LoadingComponent,
    QRCodeModule,
    QaTagsDirective,
  ],
  templateUrl: './cloud-settings-device.component.html',
  styleUrls: [
    '../general-settings/general-settings.component.scss',
    './cloud-settings-device.component.scss',
  ],
})
export class CloudSettingsDeviceComponent {
  currentController$: Observable<IControllerView> = this.store.select(selectControllerInfo);
  isDisconnected$: Observable<boolean> = this.store.select(selectCurrentControllerIsDisconnected);
  networkSettings$: Observable<INetworkSettingsView> = this.store.select(selectNetworkSettings);
  isLoading$: Observable<boolean> = this.store.select(selectCurrentControllerIsLoading);
  ticketView$: Observable<ICreateAddControllerTicketView> = this.store.select(selectTicketView);

  GlobalConstants = GlobalConstants;

  constructor(
    public platformService: PlatformService,
    private menuService: MenuService,
    private store: Store,
    private dialogsService: DialogsService,
  ) {
  }

  async cancel(): Promise<void> {
    const isDisconnected = await firstValueFrom(this.isDisconnected$);
    if (isDisconnected && !this.platformService.isDeviceApp) return;

    this.menuService.toggleDashboardMenu(true);
  }

  async disconnectController(): Promise<void> {
    const message = 'Controller.CloudSettings.AreYouSureYouWantToDisconnectController';
    const title = 'Controller.CloudSettings.DisconnectController';
    const result = await this.dialogsService.question(
      message,
      title,
      [DialogButtonEnum.CANCEL, DialogButtonEnum.DISCONNECT],
      null,
      true,
      true,
      AlignmentEnum.CENTER,
      AlignmentEnum.BETWEEN,
    );

    if (result === DialogButtonEnum.DISCONNECT) {
      this.store.dispatch(disconnectController());
    }
  }

  // available only via device
  reconnectController(): void {
    if (!this.platformService.isDeviceApp) return;

    this.store.dispatch(reconnectController());
  }
}
