import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import {
    IAirTreatmentSettingsView,
    IAirTreatmentThresholdsView,
    IAirTreatmentView,
    IAirTreatmentListView,
} from '@livestock/air-treatment';

const baseUrl = (controllerID: number): string => `${environment.apiUrl}/controller/${controllerID}/air-treatment`;

@Injectable({
    providedIn: 'root',
})
export class AirTreatmentService {

    constructor(
        private http: HttpClient,
    ) { }

    getAirTreatments(controllerID: number): Observable<IAirTreatmentListView> {
        return this.http.get<IAirTreatmentListView>(`${baseUrl(controllerID)}`);
    }

    updateAirTreatments(controllerID: number, items: IAirTreatmentView[]): Observable<IAirTreatmentView[]> {
        return this.http.put<IAirTreatmentView[]>(`${baseUrl(controllerID)}`, items);
    }

    deleteAirTreatment(controllerID: number, day: number): Observable<boolean> {
        return this.http.delete<boolean>(`${baseUrl(controllerID)}`, { body: { day } });
    }

    getAirTreatmentSettings(controllerID: number): Observable<IAirTreatmentSettingsView> {
        return this.http.get<IAirTreatmentSettingsView>(`${baseUrl(controllerID)}/settings`);
    }

    updateAirTreatmentSettings(controllerID: number, view: IAirTreatmentSettingsView): Observable<IAirTreatmentSettingsView> {
        return this.http.put<IAirTreatmentSettingsView>(`${baseUrl(controllerID)}/settings`, view);
    }

    getAirTreatmentThresholds(controllerID: number): Observable<IAirTreatmentThresholdsView> {
        return this.http.get<IAirTreatmentThresholdsView>(`${baseUrl(controllerID)}/thresholds`);
    }

    updateAirTreatmentThresholds(controllerID: number, view: IAirTreatmentThresholdsView): Observable<IAirTreatmentThresholdsView> {
        return this.http.put<IAirTreatmentThresholdsView>(`${baseUrl(controllerID)}/thresholds`, view);
    }
}