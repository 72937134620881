export enum TreatmentByEnum {
    Ventilation,
    VentilationHeating,
}

export namespace TreatmentByEnum {
  export function toTranslateKey(value: TreatmentByEnum| string | number): string {
    switch (value) {
      case TreatmentByEnum.Ventilation:
        return 'AirTreatment.Settings.Ventilation';
      case TreatmentByEnum.VentilationHeating:
        return 'AirTreatment.Settings.VentilationAndHeating';
      default:
        return value.toString();
    }
  }
}
