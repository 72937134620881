import {
  AfterViewInit,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { GlobalConstants } from '@livestock/shared/constants';
import { SleepUtils } from '@livestock/shared/utils';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Store } from '@ngrx/store';
import {
  selectActiveAmPmButton,
  selectCurrentKeyboardUUID,
  selectKeyboardValueAndUUID,
} from '../keyboard/+state/keyboard.selectors';
import { filter, first, firstValueFrom, Subscription } from 'rxjs';
import {
  clearFormControlInputInfo,
  clearKeyboardValue,
  setElementUuid,
  setKeyboardAMPMButtons,
  setKeyboardMode,
  setKeyboardRanges,
} from '../keyboard/+state/keyboard.actions';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { ColorsEnum, KeyboardEnum } from '@livestock/shared/enums';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { KeyboardModeEnum } from '../keyboard/keyboard-mode.enum';
import { HoursFormatTypeEnum, selectCurrentControllerTimeFormat } from '@livestock/controllers';
import { AmPmEnum } from '../../../../../controllers/src/lib/enums/am-pm.enum';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'ls-input-time',
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
    TranslateModule,
  ],
  templateUrl: './input-time.component.html',
  styleUrls: [
    '../input-integer/input-integer.component.scss',
    './input-time.component.scss',
  ],
})
export class InputTimeComponent implements ControlValueAccessor, OnInit, OnDestroy, AfterViewInit {
  @ViewChild('input') inputRef: ElementRef;
  @Input() min: number = GlobalConstants.TIME_MIN;
  @Input() max: number = GlobalConstants.TIME_MAX;
  @Input() isDisabled: boolean;
  @Input() placeholder = '00:00';
  @Input() qaTags: string;
  @Input() textColor: ColorsEnum = ColorsEnum.Default;
  @Input() fieldWithKeyboard = false;

  // 12 for AM, 23 for PM
  @Input() maxHour: 12 | 23 = 23;
  @Input() maxMinute: number = 59;

  sub$ = new Subscription();

  delimiter = ':';
  valueToShow: string = '00:00';
  defaultValueToShow: string = '00:00';
  maxSelectionStart = 6;
  clickedByUser: boolean;
  hoursFormat: HoursFormatTypeEnum;
  AMPMOption: AmPmEnum;

  HoursFormatTypeEnum = HoursFormatTypeEnum;
  AmPmEnum = AmPmEnum;

  constructor(
    public languageService: LanguageService,
    private store: Store,
    private platformService: PlatformService,
    @Optional()
    private control: NgControl,
  ) {
    control.valueAccessor = this;
    this.store.select(selectCurrentControllerTimeFormat).pipe(
      filter(res => res != null),
      first(),
    ).subscribe(hoursFormat => {
      this.hoursFormat = hoursFormat;
      this.maxHour = hoursFormat === HoursFormatTypeEnum.AmPm ? 12 : 23;
    });
  }

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    if (this.fieldWithKeyboard && !this.platformService.isMobileApp) {
      this.store.dispatch(setElementUuid({
        elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
      }));

      this.clickedByUser = true;

      this.store.dispatch(setKeyboardRanges(null));
      this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
      this.store.dispatch(setKeyboardAMPMButtons({ hasAMPM: this.hoursFormat === HoursFormatTypeEnum.AmPm }));
    }
  }

  // hide keyboard if clicked outside input
  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    await SleepUtils.sleep(100);
    const activeFormControl = await firstValueFrom(this.store.select(selectCurrentKeyboardUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.store.dispatch(clearFormControlInputInfo());
      this.store.dispatch(setKeyboardAMPMButtons({ hasAMPM: false }));
    }
  }

  @HostListener('keydown.backspace', ['$event'])
  onKeyDownBack(event): void {
    event.preventDefault();
    event.stopPropagation();

    const { selectionStart, selectionEnd, value } = this.inputRef.nativeElement;
    if (selectionStart !== selectionEnd) {
      // depending on amount of selected digits
      const hours = value.slice(0, 2);
      const minutes = value.slice(-2);
      const newValueRaw = `${hours}${minutes}`;
      const zeros = selectionStart >= 0 && selectionStart < 3 && selectionEnd >= 3
        ? '0'.repeat(selectionEnd - selectionStart - 1)
        : '0'.repeat(selectionEnd - selectionStart);

      const sliceHoursIndex = selectionStart >= 3 ? selectionStart - 1 : selectionStart;
      const sliceMinutesIndex = selectionEnd >= 3 ? selectionEnd - 1 : selectionEnd;
      const newValue = +`${newValueRaw.slice(0, sliceHoursIndex)}${zeros}${newValueRaw.slice(sliceMinutesIndex)}`;
      this.valueChange(newValue);
      this.updateInputValue();
      this.inputRef.nativeElement.selectionStart = selectionStart === 4 ? selectionStart - 2 : selectionStart - 1;
      this.inputRef.nativeElement.selectionEnd = selectionEnd === 4 ? selectionEnd - 2 : selectionEnd - 1;
      return;
    }

    let newValue;
    switch (selectionStart) {
      // 12:23 => 12:20
      case 5: {
        const hours = value.slice(0, 2);
        const minutes = value.slice(-2);
        newValue = `${hours}${minutes[0]}0`;
        this.valueChange(newValue);
        break;
      }
      // 12:23 => 12:03
      case 4: {
        const hours = value.slice(0, 2);
        const minutes = value.slice(-2);
        newValue = `${hours}0${minutes[1]}`;
        this.valueChange(newValue);
        break;
      }
      // 12:23 => 10:23
      case 2: {
        const hours = value.slice(0, 2);
        const minutes = value.slice(-2);
        newValue = `${hours[0]}0${minutes}`;
        this.valueChange(newValue);
        break;
      }
      // 12:23 => 02:23
      case 1: {
        const hours = value.slice(0, 2);
        const minutes = value.slice(-2);
        newValue = `0${hours[1]}${minutes}`;
        this.valueChange(newValue);
        break;
      }
      case 0:
      case 3:
      default:
        break;
    }

    this.updateInputValue();
    this.inputRef.nativeElement.selectionStart = selectionStart === 4 ? selectionStart - 2 : selectionStart - 1;
    this.inputRef.nativeElement.selectionEnd = selectionEnd === 4 ? selectionEnd - 2 : selectionEnd - 1;
  }

  @HostListener('keydown.delete', ['$event'])
  onKeyDown(): void {
  }

  @HostListener('input', ['$event'])
  onInput(event): void {
    event.preventDefault();
    event.stopPropagation();

    const { data: symbol } = event;
    const { selectionStart, selectionEnd, value } = event.target;

    if (/[0-9]/.test(symbol) === false && symbol !== this.delimiter) {
      this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
      return;
    }

    /*do nothing if trying to input more than 5 symbols*/
    if (selectionStart >= this.maxSelectionStart) {
      this.dropInputRefValue(selectionStart - 1, selectionStart - 1);
      return;
    }

    if (value.length > 5) {
      let newValue;
      switch (selectionStart) {
        case 1: {
          const hours = `${value.slice(0, 1)}${value.slice(2, 3)}`;
          const minutes = value.slice(-2);
          newValue = `${hours}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        case 2: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-2);
          newValue = `${hours}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        case 4: {
          const hours = value.slice(0, 2);
          const minutes = `${value.slice(-3, -2)}${value.slice(-1)}`;
          newValue = `${hours}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        case 5: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-3, -1);
          newValue = `${hours}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        // just step forward if trying to input smth before delimiter
        case 3:
        default:
          break;
      }

      this.updateInputValue();

      // jump the ":"
      setTimeout(() => {
        this.inputRef.nativeElement.selectionStart = selectionStart === 2 ? 3 : selectionStart;
        this.inputRef.nativeElement.selectionEnd = selectionEnd === 2 ? 3 : selectionEnd;
      });

      return;
    }

    // for cases when use selects 1 or more symbols
    if (value.length <= 5) {
      let newValue;
      if (selectionStart < 4) {
        newValue = value.replace(this.delimiter, '');
      } else {
        const hours = value.slice(0, 2);
        const minutes = value.split(this.delimiter)[1];
        const minutesWithZeros = `0${minutes}`.slice(-2);
        newValue = `${hours}${minutesWithZeros}`;
      }

      this.valueChange(newValue);
      this.updateInputValue();
      this.inputRef.nativeElement.selectionStart = this.maxSelectionStart;
      this.inputRef.nativeElement.selectionEnd = this.maxSelectionStart;
      return;
    }
  }

  dropInputRefValue(selectionStart: number, selectionEnd: number): void {
    this.inputRef.nativeElement.value = this.valueToShow;
    this.inputRef.nativeElement.selectionStart = selectionStart;
    this.inputRef.nativeElement.selectionEnd = selectionEnd;
  }

  onKeyPress(event): void {
    event.stopPropagation();
    event.preventDefault();
    const { value, selectionStart, selectionEnd } = this.inputRef.nativeElement;
    const symbol = event.key;

    if (/[0-9]/.test(symbol)) {
      let newValue;
      switch (selectionStart) {
        // 20:34 + 1 => 12:34
        case 0: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-2);
          newValue = `${symbol}${hours[0]}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        // 20:34 + 1 => 21:34
        case 1: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-2);
          newValue = `${hours[0]}${symbol}${minutes}`;
          this.valueChange(newValue);
          break;
        }
        // 20:34 + 1 => 20:13
        case 3: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-2);
          newValue = `${hours}${symbol}${minutes[0]}`;
          this.valueChange(newValue);
          break;
        }
        // 20:34 + 1 => 20:31
        case 4: {
          const hours = value.slice(0, 2);
          const minutes = value.slice(-2);
          newValue = `${hours}${minutes[0]}${symbol}`;
          this.valueChange(newValue);
          break;
        }
        // just step forward if trying to input smth before delimiter
        case 2:
        default:
          break;
      }

      this.updateInputValue();

      // jump the ":"
      this.inputRef.nativeElement.selectionStart = selectionStart === 1 ? 3 : selectionStart + 1;
      this.inputRef.nativeElement.selectionEnd = selectionEnd === 1 ? 3 : selectionEnd + 1;
      return;
    }
  }

  ngOnInit(): void {
    if (this.fieldWithKeyboard && !this.platformService.isMobileApp) {
      this.sub$.add(
        this.store.select(selectCurrentKeyboardUUID).pipe(
          wasChanged(),
          filter((uuid) => uuid === (this.control as any).uuid),
        ).subscribe(() => {
          setTimeout(() => {
            // do not set selection start/end and focus we user clicked input by himself
            if (!this.clickedByUser) {
              this.inputRef.nativeElement.focus();
              this.inputRef.nativeElement.selectionStart = 0;
              this.inputRef.nativeElement.selectionEnd = 0;
            }
            this.clickedByUser = false;
            this.store.dispatch(setKeyboardMode({ mode: KeyboardModeEnum.NumericOnly }));
          });
        }),
      );

      this.sub$.add(
        this.store.select(selectKeyboardValueAndUUID)
          .pipe(
            wasChanged(),
            filter(({ symbol, elementUuid }) =>
              symbol != null && !symbol.toString().includes(KeyboardEnum.Enter) && elementUuid === (this.control as any).uuid),
          ).subscribe(({ symbol }) => {
          if (symbol === '') {
            this.onKeyDownBack(new KeyboardEvent('keydown'));
            this.store.dispatch(clearKeyboardValue());
            return;
          }

          this.onKeyPress(new KeyboardEvent('keydown', { key: symbol as string }));
          this.store.dispatch(clearKeyboardValue());
        }),
      );
    }

    this.sub$.add(
      this.store.select(selectActiveAmPmButton).pipe(
        filter(res => res != null),
      ).subscribe(AMPM => {
        this.AMPMOption = AMPM;
        const valueToNumber = +this.valueToShow.replace(this.delimiter, '');
        this.valueChange(valueToNumber);
      }),
    );
  }

  ngAfterViewInit(): void {
    this.updateInputValue();
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  updateInputValue(): void {
    if (!this.inputRef) return;

    this.inputRef.nativeElement.value = this.valueToShow ?? this.defaultValueToShow;
  }

  valueChange(value: number): void {
    const hours = +value.toString().slice(0, 2);
    if (this.hoursFormat === HoursFormatTypeEnum.AmPm && hours > 12) {
      return;
    }

    this.valueToShow = this.transformValue(value);

    const formValue = this.hoursFormat === HoursFormatTypeEnum.AmPm
      ? this.transformToAmPmValue(value)
      : value;

    this.onChange(formValue);
  }

  transformToAmPmValue(value: number): number {
    const valueToString = value.toString().length === 4
      ? value.toString()
      : `${'0'.repeat(4 - value.toString().length)}${value}`;

    if (this.AMPMOption === AmPmEnum.PM) {
      const hours = valueToString.slice(0, 2);
      const minutes = +valueToString.slice(-2) > this.maxMinute
        ? this.maxMinute
        : valueToString.slice(-2);

      // 11:59PM = 23:59, 12:59PM = 12:59
      const realHours = +hours === 12
        ? '00'
        : +hours + 12;

      return +`${realHours}${minutes}`;
    }

    if (this.AMPMOption === AmPmEnum.AM) {
      const hours = valueToString.slice(0, 2);
      const minutes = valueToString.slice(-2);

      // 11:59AM = 11:59, 12:59AM = 00:59
      const realHours = hours === '00'
        ? '12'
        : hours;

      return +`${realHours}${minutes}`;
    }

    return +valueToString;
  }

  writeValue(value: number): void {
    if (this.hoursFormat === HoursFormatTypeEnum.AmPm && this.AMPMOption === AmPmEnum.PM) {
      const timeToNumber = +this.valueToShow.replace(this.delimiter, '');
      const timeToPM = this.transformToAmPmValue(timeToNumber);
      if (timeToPM === value) return;
    }

    if (this.valueToShow === this.transformValue(value)) return;
    this.valueToShow = this.transformValue(value);
    this.updateInputValue();
  }

  transformValue(value: number | string): string {
    const stringValue = value.toString();
    if (stringValue.includes(this.delimiter)) return stringValue;

    if (this.hoursFormat === HoursFormatTypeEnum.AmPm) {
      if (stringValue.length === 4) {
        let hours;

        hours = +stringValue.slice(0, 2) > this.maxHour
          ? this.maxHour
          : stringValue.slice(0, 2);

        hours = hours === '00' ? 12 : hours;
        const minutes = +stringValue.slice(-2) > this.maxMinute
          ? this.maxMinute
          : stringValue.slice(-2);

        return `${hours}${this.delimiter}${minutes}`;
      }

      const stringValueWithZeros = `${'0'.repeat(4 - stringValue.length)}${stringValue}`;
      let hours;
      hours = +stringValueWithZeros.slice(0, 2) > this.maxHour
        ? this.maxHour
        : stringValue.slice(0, 2);

      hours = +hours === 0 ? 12 : hours;

      const minutes = +stringValueWithZeros.slice(-2) > this.maxMinute
        ? this.maxMinute
        : stringValueWithZeros.slice(-2);

      return `${hours}${this.delimiter}${minutes}`;
    }

    if (stringValue.length === 4) {
      // 1244 => 12:44
      const hours = +stringValue.slice(0, 2) > this.maxHour
        ? this.maxHour
        : stringValue.slice(0, 2);
      const minutes = +stringValue.slice(-2) > this.maxMinute
        ? this.maxMinute
        : stringValue.slice(-2);

      return `${hours}${this.delimiter}${minutes}`;
    }

    // 2 => 00:02
    const stringValueWithZeros = `${'0'.repeat(4 - stringValue.length)}${stringValue}`;
    const hours = stringValueWithZeros.slice(0, 2);
    const minutes = +stringValueWithZeros.slice(-2) > this.maxMinute
      ? this.maxMinute
      : stringValueWithZeros.slice(-2);

    return `${hours}${this.delimiter}${minutes}`;
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private onTouched = (): void => {
  };

  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}
