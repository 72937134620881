export enum IconsNetworkEnum {
  NETWORK_CLOUD_CONNECTED = 'new-icons/network/cloud-connected',
  NETWORK_CLOUD_CONNECTED2 = 'new-icons/network/cloud-connected2',
  NETWORK_CLOUD_NO_CONNECTION = 'new-icons/network/cloud-no-connection',
  NETWORK_CLOUD_NO_CONNECTION2 = 'new-icons/network/cloud-no-connection2',
  NETWORK_CONNECTED = 'new-icons/network/connected',
  NETWORK_CONNECTED_GLOBE = 'new-icons/network/connected-globe',
  NETWORK_CONNECTED_GLOBE2 = 'new-icons/network/connected-globe2',
  NETWORK_DEVICE = 'new-icons/network/device',
  NETWORK_WIFI_CONNECTED_FULL = 'new-icons/network/wifi/wifi-connected-full',
  NETWORK_WIFI_CONNECTED_MEDIUM = 'new-icons/network/wifi/wifi-connected-medium',
  NETWORK_WIFI_CONNECTED_WEAK = 'new-icons/network/wifi/wifi-connected-weak',
  NETWORK_WIFI_NOT_CONNECTED = 'new-icons/network/wifi/wifi-not-connected',
  NETWORK_WIFI_SECURED = 'new-icons/network/wifi/wifi-secured',
  NETWORK_CELLULAR_1_BAR = 'new-icons/network/cellular/cellular-1-bar',
  NETWORK_CELLULAR_2_BARS = 'new-icons/network/cellular/cellular-2-bars',
  NETWORK_CELLULAR_3_BARS = 'new-icons/network/cellular/cellular-3-bars',
  NETWORK_CELLULAR_FULL = 'new-icons/network/cellular/cellular-full',
  NETWORK_CELLULAR_NO_CONNECTION = 'new-icons/network/cellular/cellular-no-connection',
  NETWORK_NO_CONNECTION = 'new-icons/network/no-connection',
  NETWORK_LAN_CONNECTED = 'new-icons/network/lan/lan-connected',
  NETWORK_LAN_NOT_CONNECTED = 'new-icons/network/lan/lan-not-connected',
  NETWORK_NO_CONNECTION_GLOBE = 'new-icons/network/no-connection-globe',
  NETWORK_NO_CONNECTION_GLOBE_WHITE = 'new-icons/network/no-connection-globe-white',
}
