<section
  [class.mobile-styles]="platformService.isMobileApp"
  class="page-content">
  <ls-header-title
    (cancel)="cancel()"
    [title]="'Controller.HouseSizesSettings.HouseSettings'" />

  <div class="container">
    <ls-house-sizes-settings-form
      (changed)="changedHouseSizesSettings($event)"
      [houseSizesSettings]="houseSizesSettings"
      [isLoading]="isLoading$ | async"
      [lengthUnit]="lengthUnit$ | async" />
    <div class="d-flex justify-end justify-center-md mt-30">
      <ls-button
        (click)="cancel()"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-20">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="update()"
        [class.disabled]="!isValid || !isDirtyForm"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'">
        {{ "Buttons.Update" | translate }}
      </ls-button>
    </div>
  </div>
</section>
