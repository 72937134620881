<div class="active-element__wrapper h-100-percent p-20">
  <ng-container *ngIf="!(isElementLoading$ | async); else loading">
    <ng-container *ngIf="currentElementType$ | async as currentElementType; else emptyDevice">
      <ls-element-wrapper [currentElementType]="currentElementType"></ls-element-wrapper>
    </ng-container>

    <ng-template #emptyDevice>
      <div class="active-element__empty h-100-percent w-100-percent d-flex align-center justify-center flex-column">
        <ls-svg-icon
          [hasMargin]="true"
          path="installation/card/empty-device-type"></ls-svg-icon>
        <h4
          [qaTags]="'qa-lbl-starting-mess'"
          class="mt-20 text-center active-element__empty-title">
          {{ "Installation.Card.ActiveElement.Select" | translate }}
          <span class="fw-500">{{ "Installation.Card.ActiveElement.Connection" | translate }}</span>
          {{ "Installation.Card.ActiveElement.Then" | translate | lowercase }}
          {{ "Installation.Card.ActiveElement.Select" | translate | lowercase }}
          <span class="fw-500">{{ "Installation.Card.ActiveElement.SensorType" | translate }}</span>
        </h4>
      </div>
    </ng-template>
  </ng-container>

  <ng-template #loading>
    <ls-loading></ls-loading>
  </ng-template>
</div>
