import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { ColorsEnum } from '@livestock/shared/enums';
import { Store } from '@ngrx/store';
import { IConnection } from '../../interfaces/connection.interface';
import {
  getElement,
  selectCardConnectionsByID,
  selectCurrentConnectionID,
  selectCurrentConnectionSetupData,
  selectCurrentConnectionSetupIsNew,
  selectCurrentElementType,
  setCurrentConnection,
} from '@livestock/installation';
import { filter, firstValueFrom, Observable, Subscription } from 'rxjs';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import { InstallationHelpersService } from '../../service/installation-helpers.service';
import { ConnectionType } from '../../enums/connection-type.enum';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IGetOrUpdateElement } from '../../interfaces/element/get-or-update-element.interface';
import { GlobalConstants } from '@livestock/shared/constants';

@Component({
  selector: 'ls-card-items',
  templateUrl: './card-items.component.html',
  styleUrls: ['./card-items.component.scss'],
})
export class CardItemsComponent implements OnInit, OnDestroy {
  @Input() cardID: number;
  @Input() connectionType: ConnectionType;
  @Input() elementType: ElementTypesEnum;

  /*sub$*/
  sub$ = new Subscription();
  currentConnectionID$: Observable<number> = this.store.select(selectCurrentConnectionID);
  currentConnectionElementType$: Observable<number> = this.store.select(selectCurrentElementType);
  currentConnectionSetupData$: Observable<IGetOrUpdateElement> = this.store.select(selectCurrentConnectionSetupData);
  currentConnectionSetupIsNew$: Observable<boolean> = this.store.select(selectCurrentConnectionSetupIsNew);

  /*vars*/
  connections: IConnection[] = [];

  /*constants*/
  GlobalConstants = GlobalConstants;
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private dialogsService: DialogsService,
    private installationHelpersService: InstallationHelpersService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectCardConnectionsByID(this.cardID)).pipe(
        filter(res => res != null),
      ).subscribe(connections => {
        this.connections = this.connectionType == null
          ? connections
          : connections.filter(connection => connection.connectionType === this.connectionType);
      }),
    );
  }

  async setActiveConnection(connection: IConnection): Promise<void> {
    /* if element is empty and this is not device app, do nothing - commented for now for presentation purposes */
    if (!this.platformService.isDeviceApp && !connection.element) {
      //return;
    }

    const connectionID = await firstValueFrom(this.currentConnectionID$);
    if (connection.connectionID === connectionID) {
      return;
    }

    const canChangeConnection = await this.installationHelpersService.canLeaveDirtyElementSetup();
    if (!canChangeConnection) {
      return;
    }

    this.store.dispatch(setCurrentConnection({ connectionID: connection.connectionID }));

    // get element setup if element exists
    if (connection.element) {
      this.store.dispatch(getElement({
        elementID: connection.element.elementID,
        elementType: connection.element.elementType,
        connectionID: connection.connectionID,
      }));
      return;
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
