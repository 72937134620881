import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-pressure-widget',
  templateUrl: './pressure-widget.component.html',
  styleUrls: ['./pressure-widget.component.scss'],
})
export class PressureWidgetComponent {
}
