<div class="widget-wrapper widget-wrapper__padding">
  <h6
    [qaTags]="'qa-lbl-panel-title'"
    class="widget-title d-flex align-center justify-between">
    <span>Main indicator</span>

    <img
      [qaTags]="'qa-lnk-edit'"
      alt="edit"
      class="pointer edit"
      src="/assets/icons/edit-pencil.svg" />
  </h6>

  <div class="widget-body">
    <div class="widget-content d-flex justify-center">
      <div
        [qaTags]="'qa-img-score'"
        class="main-indicator d-flex align-center justify-center flex-column">
        <h3
          [qaTags]="'qa-lbl-score-value'"
          class="fz-60 fz-44-mobile line-height-1">
          8.5
        </h3>
        <span
          [qaTags]="'qa-lbl-score-text'"
          class="fz-16-device fz-20-mobile"
          >Score</span
        >
      </div>
    </div>
  </div>
</div>
