import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { Store } from '@ngrx/store';
import { filter, firstValueFrom } from 'rxjs';
import {
  getGeneralSettings,
  selectActiveControllerID, selectGeneralSettings,
} from '@livestock/controllers';

export const generalSettingsResolver: ResolveFn<boolean> =
  async (route: ActivatedRouteSnapshot) => {
    const store = inject(Store);

    const generalSettings = await firstValueFrom(store.select(selectGeneralSettings));

    if (generalSettings?.controllerID == null) {
      const controllerID = route.params['controllerID'] || await firstValueFrom(store.select(selectActiveControllerID));
      store.dispatch(getGeneralSettings({ controllerID }));

      await firstValueFrom(store.select(selectGeneralSettings).pipe(
        filter(info => info != null), // <- waiting until data is present
      ));
    }

    return true;
  };
