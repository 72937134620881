import {
  AfterViewChecked,
  Component,
  HostListener,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import { CommonModule } from '@angular/common';
import { GlobalConstants } from '@livestock/shared/constants';
import { KeyboardEnum } from '@livestock/shared/enums';
import { KeyboardModeEnum } from './keyboard-mode.enum';
import { Store } from '@ngrx/store';
import {
  clearFormControlInputInfo,
  keyboardUpdateFieldsSuccess,
  removeSymbol,
  setKeyboardActiveAMPMButton,
  setKeyboardRanges,
  setKeyboardValue,
  switchElementUuid,
} from './+state/keyboard.actions';
import { distinctUntilChanged, filter, firstValueFrom, map, Observable, Subscription } from 'rxjs';
import {
  getKeyboardIconPath,
  isRefreshFieldsForKeyboard,
  selectActiveAmPmButton,
  selectCurrentKeyboardUUID,
  selectHasAmPmButtons,
  selectKeyboardMode,
  selectKeyboardRanges,
} from './+state/keyboard.selectors';
import { ProgramDeviceButtonsComponent } from '../../blocks/program-device-buttons/program-device-buttons.component';
import { SvgIconComponent } from '../../svg-icon/svg-icon.component';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { LanguageService } from '@livestock/shared/services';
import { SleepUtils } from '@livestock/shared/utils';
import { QaTagsDirective } from '@livestock/shared/directives';
import { AmPmEnum } from '../../../../../controllers/src/lib/enums/am-pm.enum';

@Component({
  selector: 'ls-keyboard',
  standalone: true,
  imports: [
    CommonModule,
    ProgramDeviceButtonsComponent,
    SvgIconComponent,
    TranslateModule,
    QaTagsDirective,
  ],
  templateUrl: './keyboard.component.html',
  styleUrls: ['./keyboard.component.scss'],
})
export class KeyboardComponent implements OnInit, OnDestroy, AfterViewChecked, OnChanges {
  @Input() ranges: { min: number | string, max: number | string };
  @Input() hasTimePicker: boolean;
  @Input() showDecimalDot: boolean = false;
  @Input() defaultMode = KeyboardModeEnum.Full;
  @Input() context: string;

  /*observables*/
  path$ = this.store.select(getKeyboardIconPath).pipe(
    distinctUntilChanged(),
    map(i => i === 'dashboard/sprinklers' ? 'Controls.Element.Sprinklers' : 'Controls.Element.Cooling'),
    map(i => this.translateService.instant(i)),
  );

  /*subs*/
  sub$ = new Subscription();
  activeAmPmButton$: Observable<AmPmEnum> = this.store.select(selectActiveAmPmButton);

  /*vars*/
  keyboard: (string | number | KeyboardEnum)[] = GlobalConstants.EnglishAlphabetKeyboard;
  toLowerCase = false;
  isDisabled = true;
  isMinusDisabled = true;
  fieldsForKeyboard: string[] = [];
  checkFieldsInterval: ReturnType<typeof setInterval>;
  longPressRemovalInProgress: boolean;
  hasAMPM: boolean;

  /*constants*/
  Number = Number;
  GlobalConstants = GlobalConstants;
  KeyboardModeEnum = KeyboardModeEnum;
  KeyboardEnum = KeyboardEnum;
  AmPmEnum = AmPmEnum;
  private _mode: KeyboardModeEnum;

  get mode(): KeyboardModeEnum {
    return this._mode;
  }

  set mode(mode: KeyboardModeEnum) {
    if (mode != null) {
      this._mode = mode;

      if (mode === KeyboardModeEnum.Full) {
        this.keyboard = GlobalConstants.EnglishAlphabetKeyboard;
        return;
      }

      this.keyboard = GlobalConstants.NumericKeyboard;
    }
  }

  @HostListener('mousedown', ['$event'])
  onClick(): boolean {
    return false;
  }

  constructor(
    private store: Store,
    private translateService: TranslateService,
    public languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectCurrentKeyboardUUID).subscribe((elementUUID) => {
        this.isDisabled = !elementUUID;
      }),
    );

    this.sub$.add(
      this.store.select(selectCurrentKeyboardUUID).subscribe((elementUUID) => {
        this.isDisabled = !elementUUID;
      }),
    );

    this.sub$.add(
      this.store.select(selectKeyboardMode).subscribe((mode) => {
        this.mode = mode ?? this.defaultMode;
      }),
    );

    this.sub$.add(
      this.store.select(selectHasAmPmButtons).subscribe((hasAMPM) => {
        this.hasAMPM = hasAMPM;
      }),
    );

    this.sub$.add(
      this.store.select(isRefreshFieldsForKeyboard)
        .pipe(
          filter((isRefreshFieldsForKeyboard) =>
            isRefreshFieldsForKeyboard),
        )
        .subscribe(() => {
          this.selectFormFields();
          this.store.dispatch(keyboardUpdateFieldsSuccess());
        }),
    );

    this.sub$.add(
      this.store.select(selectKeyboardRanges).subscribe((ranges) => {
        this.ranges = ranges;
        if (ranges != null) {
          const min = +(ranges['min']);
          if (min != null) {
            this.isMinusDisabled = min >= 0;
          }
        }
      }),
    );
  }

  ngAfterViewChecked(): void {
    this.selectFormFields();
  }

  ngOnChanges(changes: SimpleChanges): void {
    if ('context' in changes && changes['context'].currentValue !== changes['context'].previousValue) {
      this.selectFormFields();
    }
  }

  selectFormFields(): void {
    setTimeout(() => {
      const context: Element | Document = this.getContextElement(this.context);

      const controlsWithKeyboard = context.querySelectorAll('[uuid]');
      const fieldsForKeyboard = Array.from(controlsWithKeyboard).map(i => (i as HTMLElement).getAttribute('uuid'));
      if (JSON.stringify(this.fieldsForKeyboard) !== JSON.stringify(fieldsForKeyboard)) {
        this.fieldsForKeyboard = fieldsForKeyboard;
      }
    });
  }

  printSymbol(symbol: string | number): void {
    if (this.isDisabled) return;

    // disable for numeric keyboards if symbol is not a number (for non-decimal inputs)
    // or disable for numeric keyboards if symbol is not a number except minus symbol and not a dot (for decimal inputs)
    if (this.mode === KeyboardModeEnum.NumericOnly && (!Number.isInteger(symbol) && symbol !== '-' && (!this.showDecimalDot || symbol !== '.'))
      || (this.mode !== KeyboardModeEnum.Full && symbol === '-' && this.isMinusDisabled)) return;
    const modifiedSymbol = this.toLowerCase ? symbol.toString().toLowerCase() : symbol;
    this.store.dispatch(setKeyboardValue({ symbol: modifiedSymbol }));
  }

  async removeSymbol(): Promise<void> {
    this.longPressRemovalInProgress = true;
    await SleepUtils.sleep(1000);

    // recursively remove symbols if button is still pressed
    const longPressRemoval = async (): Promise<void> => {
      if (!this.longPressRemovalInProgress) {
        return;
      }

      await SleepUtils.sleep(100);
      this.store.dispatch(removeSymbol());
      longPressRemoval();
    };

    longPressRemoval();
  }

  async onButtonClicked(button: string | number | KeyboardEnum): Promise<void> {
    if (this.isDisabled) return;
    if (this.mode !== KeyboardModeEnum.Full && button === KeyboardEnum.ABC) return;
    if (this.fieldsForKeyboard.length <= 1 && button.toString().includes(KeyboardEnum.Enter)) return;

    const UUID = await firstValueFrom(this.store.select(selectCurrentKeyboardUUID));
    const uuidIndex = this.fieldsForKeyboard.indexOf(UUID);
    const nextUUID = this.fieldsForKeyboard.length === uuidIndex + 1
      ? this.fieldsForKeyboard[0]
      : this.fieldsForKeyboard[uuidIndex + 1];

    switch (button as KeyboardEnum) {
      case KeyboardEnum.Numeric:
        this.keyboard = GlobalConstants.NumericKeyboard;
        break;
      case KeyboardEnum.ABC:
        this.keyboard = GlobalConstants.EnglishAlphabetKeyboard;
        break;
      case KeyboardEnum.Up:
        this.toLowerCase = !this.toLowerCase;
        break;
      case KeyboardEnum.Backspace:
        // prevent extra removal after long press removal
        !this.longPressRemovalInProgress && this.store.dispatch(removeSymbol());
        this.longPressRemovalInProgress = false;
        break;
      case KeyboardEnum.Space:
        this.store.dispatch(setKeyboardValue({ symbol: ' ' }));
        break;
      case KeyboardEnum.AM:
      case KeyboardEnum.PM:
        this.store.dispatch(setKeyboardValue({ symbol: button }));
        this.store.dispatch(setKeyboardActiveAMPMButton({
          activeAMPM: button === KeyboardEnum.AM
            ? AmPmEnum.AM
            : AmPmEnum.PM,
        }));
        break;
      case KeyboardEnum.Enter:
      case KeyboardEnum.EnterLong:
        this.store.dispatch(setKeyboardValue({ symbol: button }));
        this.store.dispatch(setKeyboardRanges(null));
        if (!nextUUID) {
          break;
        }

        this.store.dispatch(switchElementUuid({ elementUuid: nextUUID }));
        break;
      default:
        break;
    }
  }

  private getContextElement(context: string): Element | Document {
    if (context?.length) {
      const contextElement = document.querySelector(this.context);

      if (contextElement instanceof Element) {
        return contextElement;
      }

      console.error(`${KeyboardComponent.name}: Provided context element ${this.context} not found or invalid, falling back to document.`);
    }

    return document;
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearFormControlInputInfo());
    clearInterval(this.checkFieldsInterval);
    this.sub$.unsubscribe();
  }
}
