import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import { ButtonComponent, ButtonWithIconComponent, InputIntegerComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { FormBuilder, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { PlatformService } from '@livestock/shared/services';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { NoDuplicateDaysProg, RequiredTrimValidator } from '@livestock/shared/validators';

@Component({
  selector: 'ls-add-day-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FormsModule,
    ReactiveFormsModule,
    ButtonComponent,
    InputIntegerComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    ErrorFieldDirective,
  ],
  templateUrl: './add-day-dialog.component.html',
  styleUrls: ['./add-day-dialog.component.scss'],
})
export class AddDayDialogComponent {
  form: FormGroup;

  constructor(
    public dialogRef: MatDialogRef<AddDayDialogComponent>,
    public platformService: PlatformService,
    public fb: FormBuilder,
  ) {
  }

  setup(days: number[]): void {
    this.form = this.fb.group({
      dayNumber: new FormControl(null, [Validators.max(99), RequiredTrimValidator, NoDuplicateDaysProg(days)]),
    });
  }

  close(): void {
    this.dialogRef.close();
  }

  add(): void {
    this.dialogRef.close(this.form.value);
  }
}
