import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit, ViewChild, ElementRef, HostListener } from '@angular/core';
import { Router, RouterModule, ActivatedRoute, ParamMap } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { ActivatedRouteService, BuildInfoService, LocalStorageService, LanguageService } from '@livestock/shared/services';
import { HttpClientModule } from '@angular/common/http';
import { MemoizeFuncPipe, EnumPipe } from '@livestock/shared/pipes';
import { ControllerLanguageEnum } from '@livestock/controllers';
import { StorageItem, IconsEnum } from '@livestock/shared/enums';
import { FormsModule } from '@angular/forms';
import { SvgIconComponent, RadioListComponent } from '@livestock/ui';
import { slideInRight } from './animation';
@Component({
  standalone: true,
  selector: 'ls-m-auth',
  imports: [
    CommonModule,
    FormsModule,
    RouterModule,
    TranslateModule,
    HttpClientModule,
    QaTagsDirective,
    MemoizeFuncPipe,
    EnumPipe,
    SvgIconComponent,
    RadioListComponent,
  ],
  templateUrl: './auth-m.component.html',
  styleUrls: ['./auth-m.component.scss'],
  animations: [slideInRight],
})
export class AuthMComponent implements OnInit, OnDestroy {
  @ViewChild('toggleMenuLanguages') toggleMenuLanguagesRef: ElementRef;
  @ViewChild('menuLanguages') menuLanguagesRef: ElementRef;
  showTopLogo = false;
  showLanguages = false;
  sub$: Subscription = new Subscription();
  language = this.languageService?.lang || +LocalStorageService.getStorageItem(StorageItem.ControllerLang) || ControllerLanguageEnum.EngUS;
  readonly languagesList = [
    { value: ControllerLanguageEnum.EngUS, title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.EngUS), cssClass: 'fw-600' },
    { value: ControllerLanguageEnum.Spanish, title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Spanish) },
    { value: ControllerLanguageEnum.Portuguese, title: ControllerLanguageEnum.toTranslateKey(ControllerLanguageEnum.Portuguese) },
  ];
  IconsEnum = IconsEnum;

  constructor(
    public router: Router,
    public activatedRouteService: ActivatedRouteService,
    public buildInfoService: BuildInfoService,
    private languageService: LanguageService,
    private activatedRoute: ActivatedRoute,
  ) { }

  @HostListener('document:click', ['$event'])
  clickout(event: Event): void {
    if (!this.menuLanguagesRef?.nativeElement.contains(event.target) &&
      !this.toggleMenuLanguagesRef?.nativeElement.contains(event.target)) {
      this.closeLanguagesMenu();
    }
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.queryParamMap.pipe(
        map((params: ParamMap) => params.get('step')),
      ).subscribe((step: any) => {
        this.showTopLogo = step > 1;
      }),
    );
  }

  openLanguagesMenu(): void {
    this.showLanguages = true;
  }

  closeLanguagesMenu(): void {
    this.showLanguages = false;
  }

  changeLanguage(language: ControllerLanguageEnum): void {
    this.language = language;
    this.languageService.changeLanguage(language);
    this.languagesList.forEach(languageOption => {
      if (languageOption.value === language) {
        languageOption['cssClass'] = 'fw-600';
      } else {
        languageOption['cssClass'] = undefined;
      }
    });
    this.closeLanguagesMenu();
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
