import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable, map } from 'rxjs';
import { IGetHeatingProgramView, IHeatingProgramView } from '@livestock/heating-program';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

const baseUrl = (controllerID: number): string => `${environment.apiUrl}/controller/${controllerID}/heating/program`;

@Injectable({
  providedIn: 'root',
})
export class HeatingProgramService {
  constructor(private http: HttpClient) {}

  getHeatingPrograms(controllerID: number, temperatureUnit: TemperatureUnitEnum): Observable<IGetHeatingProgramView> {
    return this.http.get<IGetHeatingProgramView>(`${baseUrl(controllerID)}`).pipe(
      map((programs) => {
        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          return {
            ...programs,
            items: this.parseHeatingPrograms(programs.items, TemperatureUnitEnum.Fahrenheit),
          };
        } else {
          return programs;
        }
      }),
    );
  }

  updateHeatingPrograms(
    controllerID: number,
    items: IHeatingProgramView[],
    temperatureUnit: TemperatureUnitEnum,
  ): Observable<IHeatingProgramView[]> {
    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
      items = this.parseHeatingPrograms(items, TemperatureUnitEnum.Celsius);
    }

    return this.http.put<IHeatingProgramView[]>(`${baseUrl(controllerID)}`, items).pipe(
      map((items) => {
        return temperatureUnit === TemperatureUnitEnum.Fahrenheit
          ? this.parseHeatingPrograms(items, TemperatureUnitEnum.Celsius)
          : items;
      }),
    );
  }

  deleteHeatingProgram(controllerID: number, programID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl(controllerID)}/${programID}`);
  }

  parseHeatingPrograms(programItems: IHeatingProgramView[], convertTo: TemperatureUnitEnum): IHeatingProgramView[] {
    const convertFunction =
      convertTo === TemperatureUnitEnum.Celsius ? ConvertHelper.fahrenheitToCelsius : ConvertHelper.celsiusToFahrenheit;

    return programItems.map((item) => ({
      ...item,
      onTempDiff: convertFunction(item.onTempDiff, 1),
      offTempDiff: convertFunction(item.offTempDiff, 1),
    }));
  }
}
