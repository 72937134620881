import { Component, OnDestroy, OnInit } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';

import {
  ButtonComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  PageWrapperComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { QaTagsDirective } from '@livestock/shared/directives';
import { CommonModule } from '@angular/common';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import {
  AirTreatmentSettingsFormComponent,
  IAirTreatmentSettingsView,
  getAirTreatmentSettings,
  selectAirTreatmentSettings,
  selectIsLoading,
  setAirTreatmentSettings,
  updateAirTreatmentSettings,
} from '@livestock/air-treatment';
import { Store } from '@ngrx/store';
import { ColorsEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import { ActivatedRoute, Router } from '@angular/router';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-air-treatment-settings',
  templateUrl: './air-treatment-settings.component.html',
  styleUrls: ['./air-treatment-settings.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    SvgIconComponent,
    ButtonComponent,
    KeyboardComponent,
    AirTreatmentSettingsFormComponent,
    PageWrapperComponent,
  ],
})
export class AirTreatmentSettingsComponent implements OnDestroy, OnInit, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);
  // variables
  editMode: boolean;
  controllerID: number;
  isValid = true;
  isDirty = false;

  originalAirTreatmentSettings: IAirTreatmentSettingsView;
  airTreatmentSettings: IAirTreatmentSettingsView;

  // enums
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private dialogsService: DialogsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
        this.store.dispatch(getAirTreatmentSettings({ controllerID: this.controllerID }));
      }),
    );

    this.sub$.add(
      this.store
        .select(selectAirTreatmentSettings)
        .pipe(wasChangedAndNotNull())
        .subscribe((airTreatmentSettings) => {
          if (!this.originalAirTreatmentSettings) {
            this.originalAirTreatmentSettings = airTreatmentSettings;
          }
          this.airTreatmentSettings = airTreatmentSettings;
          this.isDirty = this.areAnyChanges();
        }),
    );
  }

  updateSettings(): void {
    if (!this.isValid) return;
    this.originalAirTreatmentSettings = this.airTreatmentSettings;
    this.store.dispatch(updateAirTreatmentSettings({ controllerID: this.controllerID }));
    this.isDirty = false;
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  changeAirTreatmentSettings(event: { formValues: any; isValid: boolean }): void {
    this.isValid = event.isValid;
    this.airTreatmentSettings = event.formValues;
    if (event.isValid) {
      this.store.dispatch(setAirTreatmentSettings({
        view: this.airTreatmentSettings,
      }));
    }
  }

  areAnyChanges(): boolean {
    return JSON.stringify(this.originalAirTreatmentSettings) !== JSON.stringify(this.airTreatmentSettings);
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.airTreatmentSettings = this.originalAirTreatmentSettings;
    this.store.dispatch(setAirTreatmentSettings({ view: this.originalAirTreatmentSettings }));
    return true;
  }

  async cancelSettings(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.editMode = false;
    }
  }

  async goBack(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.router.navigate([AppRoutes.CONTROLLER, this.controllerID, ControllerRoutes.AirTreatment]);
    }
  }

  canDeactivate(): boolean {
    return !this.areAnyChanges();
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
