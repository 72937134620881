<div
  [class.no-padding-top]="mode === KeyboardModeEnum.Buttons"
  class="keyboard-wrapper">
  <div class="keyboard-letters__wrapper">
    <ng-container *ngIf="mode === KeyboardModeEnum.Buttons">
      <div [class.keyboard-wrapper__title-rtl]="languageService.isRtl">
        {{ "Controls.Element.Select" | translate }} {{ path$ | async }}
      </div>
      <ls-program-device-buttons
        [large]="true"
        [storeCurrentControl]="false"
        class="content">
      </ls-program-device-buttons>
      <div class="w-100-percent d-flex justify-between">
        <button
          class="clear-button"
          type="button">
          Clear
        </button>
        <ng-container *ngTemplateOutlet="icon; context: { symbol: KeyboardEnum.EnterLong }"> </ng-container>
      </div>
    </ng-container>
    <ng-container *ngIf="keyboard === GlobalConstants.NumericKeyboard && mode !== KeyboardModeEnum.Buttons">
      <h4
        *ngIf="hasTimePicker"
        class="fz-16 color-primary mb-10">
        {{ "Controller.DateTimeSettings.SelectTime" | translate }}
      </h4>
      <div
        *ngIf="hasTimePicker || hasAMPM"
        class="keyboard-letters d-flex align-center flex-wrap fz-24 mb-30">
        <div
          (click)="onButtonClicked(KeyboardEnum.AM)"
          [class.keyboard-letter__disabled]="isDisabled"
          [class.keyboard-letter__active]="(activeAmPmButton$ | async) === AmPmEnum.AM"
          [class.pointer]="!isDisabled"
          class="keyboard-letter keyboard-letter__width-square d-flex align-center justify-center color-tertiary">
          {{ "Controller.DateTimeSettings.HoursFormatTypeEnum.AM" | translate }}
        </div>

        <div
          (click)="onButtonClicked(KeyboardEnum.PM)"
          [class.keyboard-letter__disabled]="isDisabled"
          [class.keyboard-letter__active]="(activeAmPmButton$ | async) === AmPmEnum.PM"
          [class.pointer]="!isDisabled"
          class="keyboard-letter keyboard-letter__width-square d-flex align-center justify-center color-tertiary">
          {{ "Controller.DateTimeSettings.HoursFormatTypeEnum.PM" | translate }}
        </div>
      </div>

      <!--temporary-->
      <h4
        *ngIf="ranges && !hasTimePicker"
        class="fz-16 color-primary mb-10">
        Value from {{ ranges.min }} to {{ ranges.max }}
      </h4>
    </ng-container>

    <!-- for ABC 123 just show this symbol, in other cases - generate button
      for explanation of "disabled symbol" logic see printSymbol method -->
    <div
      *ngIf="mode !== KeyboardModeEnum.Buttons"
      class="keyboard-letters d-flex align-center flex-wrap fz-24">
      <div
        *ngFor="let symbol of keyboard"
        [class.keyboard-letter__transparent]="symbol.toString().length === 0"
        [class.keyboard-letter__width-auto]="symbol.toString().length > 1"
        [ngClass]="{
           'keyboard-letter__transparent': symbol.toString().length === 0,
           'keyboard-letter__disabled': isDisabled
           || (mode === KeyboardModeEnum.NumericOnly
              && !Number.isInteger(symbol)
              && symbol !== KeyboardEnum.Enter
              && symbol !== KeyboardEnum.EnterLong
              && symbol !== KeyboardEnum.Backspace
              && symbol !== '-'
              && (!this.showDecimalDot || symbol !== '.'))
              || (mode !== KeyboardModeEnum.Full && symbol === '-' && isMinusDisabled),
           'keyboard-letter__width-auto': symbol.toString().length > 1,
           'keyboard-letter__width-square': symbol.toString().length <= 1 && keyboard === GlobalConstants.NumericKeyboard,
           }"
        class="keyboard-letter d-flex align-center justify-center color-tertiary">
        <ng-container *ngIf="symbol.toString().length <= 1">
          <div
            (click)="printSymbol(symbol)"
            [class.pointer]="!isDisabled"
            class="d-flex justify-center align-center h-100-percent w-100-percent"
            qaTags="qa-tbl-keyboard">
            {{ toLowerCase ? symbol.toString().toLowerCase() : symbol }}
          </div>
        </ng-container>
        <ng-container *ngIf="symbol.toString().length > 1">
          <ng-container *ngTemplateOutlet="icon; context: { symbol: symbol }"> </ng-container>
        </ng-container>
      </div>
    </div>
  </div>
</div>

<ng-template
  #icon
  let-symbol="symbol">
  <ls-svg-icon
    (click)="onButtonClicked(symbol)"
    (mousedown)="symbol === KeyboardEnum.Backspace && removeSymbol()"
    (mouseup)="symbol === KeyboardEnum.Backspace && (longPressRemovalInProgress = false)"
    [class.pointer]="
      !isDisabled &&
      !(mode !== KeyboardModeEnum.Full && symbol === KeyboardEnum.ABC) &&
      !(fieldsForKeyboard.length <= 1 && symbol.toString().includes(KeyboardEnum.Enter))
    "
    [mirrorRtl]="false"
    [path]="
      'keyboard/' +
      symbol +
      (isDisabled ||
      (mode !== KeyboardModeEnum.Full && symbol === KeyboardEnum.ABC) ||
      (fieldsForKeyboard.length <= 1 && symbol.toString().includes(KeyboardEnum.Enter))
        ? '-disabled'
        : '')
    "></ls-svg-icon>
</ng-template>
