<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'LightingMapping.Title'">
  <div class="svg-content">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [class.disabled]="editMode"
      [path]="'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      class="pointer d-block"></ls-svg-icon>
  </div>

  <div
    *ngIf="!platformService.isMobileApp; else mobileTemplate"
    class="main-content h-100-percent">
    <div
      [class.content-edit]="editMode"
      class="content h-100-percent min-height-0 d-flex flex-column w-100-percent">
      <div class="table-wrapper h-100-percent overflow-hidden">
        <div class="table h-100-percent w-1-15 w-2-10 w-3-75 overflow-auto padding-top-10">
          <div class="table-row table-header">
            <div class="table-cell">{{ "LightingMapping.Unit" | translate }}</div>
            <div class="table-cell">{{ "LightingMapping.Average" | translate }}</div>
            <div class="table-cell">{{ "LightingMapping.Sensors" | translate }}</div>
          </div>

          <div
            *ngFor="let lightingMapping of lightingMappings$ | async; index as index; trackBy: trackBy"
            (click)="selectRow(lightingMapping)"
            [class.pointer]="editMode"
            [class.selected]="(activeMapping$ | async)?.lightElementID === lightingMapping.lightElementID"
            class="table-row">
            <div class="table-cell align-center blue">{{ lightingMapping.name }}</div>
            <div class="table-cell">
              <mat-checkbox
                (change)="changeIsAverage($event, lightingMapping.lightElementID)"
                [checked]="lightingMapping.average"
                [class.pointer-events-none]="!editMode"
                class="checkbox checkbox-active-color"></mat-checkbox>
            </div>
            <div
              [class.active-cell]="(activeMapping$ | async)?.lightElementID === lightingMapping.lightElementID"
              class="table-cell align-center justify-start flex-wrap">
              <ng-container *ngIf="!lightingMapping.average; else averageSensors">
                <div
                  *ngFor="let sensor of lightingMapping.sensorsList; trackBy: trackBy"
                  class="sensor">
                  <ls-svg-icon
                    [color]="ColorsEnum.BlueDefault"
                    [fullHeight]="true"
                    [path]="'dashboard/light-sensor'"
                    class="mb-5"></ls-svg-icon>
                  <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                </div>
              </ng-container>
            </div>
          </div>
        </div>
      </div>

      <div
        *ngIf="editMode"
        class="d-flex align-center align-self-end">
        <ls-button
          (click)="toggleEditMode()"
          [qaTags]="'qa-btn-cancel'"
          class="mr-20 w-120 h-button-36"
          type="secondary">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>

        <ls-button
          (click)="save()"
          [class.disabled]="!(isDirtyForm$ | async)"
          [qaTags]="'qa-btn-save'"
          class="w-120 h-button-36"
          type="primary">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </div>
  </div>

  <div
    *ngIf="editMode"
    class="keyboard-content ml-20">
    <ng-container [ngTemplateOutlet]="sensorsKeyboard"></ng-container>
  </div>
</ls-page-wrapper>

<ng-template #averageSensors>
  <div class="sensor sensor-all">
    <ls-svg-icon
      [color]="ColorsEnum.BlueDefault"
      [fullHeight]="true"
      [path]="'dashboard/light-sensor'"
      class="mb-5"></ls-svg-icon>
    <span class="fz-12 color-tertiary">{{ "LightingMapping.All" | translate }}</span>
  </div>
</ng-template>

<ng-template #mobileTemplate>
  <div class="position-relative d-flex justify-between flex-column h-100-percent">
    <div class="swiper-navigation swiper-arrows">
      <div
        (click)="slidePrev()"
        [class.disabled]="activeIndex === 0"
        class="swiper-navigation-prev"></div>
      <div
        (click)="slideNext()"
        [class.disabled]="activeIndex === (lightingMappings$ | async).length - 1"
        class="swiper-navigation-next"></div>
    </div>

    <div class="h-100-percent min-height-0">
      <swiper
        *ngIf="swiperService.swiperIsActive"
        (activeIndexChange)="moveToNextStage()"
        (realIndexChange)="changeActiveSlide($event)"
        #swiper
        [config]="swiperService.config"
        [dir]="languageService.direction"
        [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
        class="swiper overflow-y-auto pb-15"
        swiperDirective>
        <ng-container *ngFor="let lightingMapping of lightingMappings$ | async; trackBy: trackBy">
          <ng-template swiperSlide>
            <ng-container
              [ngTemplateOutletContext]="{ title: lightingMapping.name }"
              [ngTemplateOutlet]="slideTitle"></ng-container>

            <div
              (click)="selectRow(lightingMapping)"
              [class.table-active]="(activeMapping$ | async)?.lightElementID === lightingMapping.lightElementID"
              class="table w-100-percent w-1-40 w-2-60">
              <div class="table-row border-bottom">
                <div class="table-cell blue">{{ "LightingMapping.Average" | translate }}</div>
                <div class="table-cell">
                  <mat-checkbox
                    (change)="changeIsAverage($event, lightingMapping.lightElementID)"
                    [checked]="lightingMapping.average"
                    [class.pointer-events-none]="!editMode"
                    class="checkbox checkbox-active-color"></mat-checkbox>
                </div>
              </div>

              <div class="table-row">
                <div class="table-cell blue">{{ "LightingMapping.Sensors" | translate }}</div>
                <div class="table-cell align-center justify-start flex-wrap">
                  <ng-container *ngIf="!lightingMapping.average; else averageSensors">
                    <div
                      *ngFor="let sensor of lightingMapping.sensorsList; trackBy: trackBy"
                      class="sensor">
                      <ls-svg-icon
                        [color]="ColorsEnum.BlueDefault"
                        [fullHeight]="true"
                        [path]="'dashboard/light-sensor'"
                        class="mb-5"></ls-svg-icon>
                      <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                    </div>
                  </ng-container>
                </div>
              </div>
            </div>
          </ng-template>
        </ng-container>
      </swiper>

      <div
        *ngIf="editMode"
        class="ph-20 d-flex align-center justify-center">
        <ls-button
          (click)="toggleEditMode()"
          [qaTags]="'qa-btn-cancel'"
          class="mr-28 w-button-130 h-button-36"
          type="secondary">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>

        <ls-button
          (click)="save()"
          [class.disabled]="!(isDirtyForm$ | async)"
          [qaTags]="'qa-btn-save'"
          class="w-button-130 h-button-36"
          type="primary">
          {{ "Buttons.Save" | translate }}
        </ls-button>
      </div>
    </div>

    <ng-container
      *ngIf="(activeMapping$ | async)?.lightElementID"
      [ngTemplateOutlet]="sensorsKeyboard"></ng-container>
  </div>
</ng-template>

<ng-template
  #slideTitle
  let-title="title">
  <h6 class="fw-500 color-secondary mb-20 w-70-percent">
    {{ title }}
  </h6>
</ng-template>

<ng-template #sensorsKeyboard>
  <ls-sensors-keyboard
    (saveSensors)="saveSensors($event)"
    (unsetSensorMode)="unsetActiveMapping()"
    [activeZone]="activeMapping$ | async"
    [iconPath]="'dashboard/light-sensor'"
    [isAverageKey]="'average'"
    [isDisabled]="!(activeMapping$ | async) || (isLoading$ | async)"
    [sensorElements]="sensors$ | async"
    [sensorsKey]="'sensorsList'"
    [title]="'SensorKeyboard.SelectLightingSensors'"></ls-sensors-keyboard>
</ng-template>
