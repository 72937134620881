<div
  *ngIf="selectedDaysLength"
  class="container">
  <h2 class="fz-24 fw-600 mb-8 color-error">
    {{
      selectedDaysLength > 1
        ? ("Flock.DeleteDayPopup.DeleteRows" | translate)
        : ("Flock.DeleteDayPopup.DeleteRow" | translate)
    }}
  </h2>

  <h4 class="fz-18 fw-600 mb-18 line-height-12">
    {{
      selectedDaysLength > 1
        ? ("Flock.DeleteDayPopup.YouAreAboutToDeleteRowsWithValue" | translate : { value: selectedDaysLength })
        : ("Flock.DeleteDayPopup.YouAreAboutToDeleteOneRow" | translate)
    }}
  </h4>

  <div class="buttons d-flex align-center justify-between">
    <lv-button
      (click)="emitCancel()"
      [type]="ButtonTypeEnum.SECONDARY"
      class="w-114"
      >{{ "Buttons.Cancel" | translate }}</lv-button
    >
    <lv-button
      (click)="emitDeleteRows()"
      [type]="ButtonTypeEnum.ALERT"
      class="w-108"
      >{{ "Buttons.Delete" | translate }}</lv-button
    >
  </div>
</div>
