import { Injectable } from '@angular/core';
import { AppRoutes } from '../routes';

@Injectable({
  providedIn: 'root',
})
export class ActivatedRouteService {
  shouldShowTopMenu(url: string): boolean {
    return !(url.includes(AppRoutes.FARM) ||
      url.includes(AppRoutes.QUICK_START) ||
      url.includes(AppRoutes.LANDING)
    );
  }

  isFarmsHomepage(url: string): boolean {
    const farmsPageUrlPattern = new RegExp(`^\/${AppRoutes.FARM}\/\\d+$`);
    return farmsPageUrlPattern.test(url);
  }

  isFarmsEmptyHomepage(url: string): boolean {
    return url?.includes(AppRoutes.FARMS);
  }

  isQuickStart(url: string): boolean {
    return url.includes(AppRoutes.QUICK_START);
  }

  isAuthPage(url: string): boolean {
    return url.includes(AppRoutes.AUTH) ||
      url.includes(AppRoutes.VALIDATE) ||
      url.includes(AppRoutes.SCANNED_LOGIN);
  }

  isSignUpPage(url: string): boolean {
    return url.includes(`${AppRoutes.AUTH}/sign-up`);
  }

  isFarmCreationPage(url: string): boolean {
    return url.includes(AppRoutes.CREATE_FARM);
  }

  isForgotPasswordPage(url: string): boolean {
    return url.includes(AppRoutes.FORGOT_PASSWORD);
  }

  isDashboard(url: string): boolean {
    return url.includes(AppRoutes.DASHBOARD);
  }

  // do not show farms list in "create-farm" page
  canShowFarmsList(url: string): boolean {
    return !url.includes(AppRoutes.CREATE_FARM);
  }

  // do not show lobby in "create-farm" page
  canShowLobby(url: string): boolean {
    return !url.includes(AppRoutes.CREATE_FARM) && !url.includes(AppRoutes.FARMS);
  }
}
