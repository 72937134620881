<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-20">
    <label qaTags="qa-label-name">{{ "Installation.Main.SensorName" | translate }}</label>
    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-name'"
        errorField
        formControlName="name"></ls-input-text>
      <!-- copy btn -->
      <ng-content></ng-content>
    </div>
  </div>
  <div class="device-form__field mb-20">
    <label>{{ "Installation.Main.InputType" | translate }}</label>
    <ng-select
      [clearable]="false"
      [dropdownPosition]="'bottom'"
      [formControlName]="'inputType'"
      [qaTags]="'qa-btn-lighting-input-type'"
      [searchable]="false"
      placeholder="{{ 'Installation.Main.InputType' | translate }}"
      selectTitle="{{ 'Installation.Main.InputType' | translate }}">
      <ng-container>
        <ng-option
          *ngFor="let el of LightingInputType | enumToArray"
          [value]="el">
          {{ el | enum : "lighting-input-type" | translate }}
        </ng-option>
      </ng-container>
    </ng-select>
  </div>
  <div class="device-form__field device-form__field-margin">
    <label qaTags="qa-lbl-enabled">{{ "Enabled" | translate }}</label>
    <ls-toggle
      [offTitle]="'Buttons.No'"
      [onTitle]="'Buttons.Yes'"
      [qaTags]="'qa-tog-enabled'"
      [size]="'large'"
      formControlName="enabled" />
  </div>
</div>
