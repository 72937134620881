<section [class.rtl]="languageService.isRtl">
  <ls-flash-message></ls-flash-message>

  <ls-display-top-menu *ngIf="router.url === '/'"></ls-display-top-menu>
  <ls-top-menu
    *ngIf="router.url !== '/' && (router.url | memoizeFunc : activatedRouteService.shouldShowTopMenu)"></ls-top-menu>
  <router-outlet></router-outlet>

  <ng-container *ngIf="this.buildInfoService.json$ | async as data">
    <span class="version fz-12">
      v{{ data.version }}{{ this.buildInfoService.environmentPrefix }} {{ data.release }} {{ data.branch }}#{{
        data.buildNumber
      }}</span
    >
  </ng-container>

  <div class="touchdebug">{{ touchDebug }}</div>
  <ls-controller-menu *ngIf="menuService.dashBoardMenuOpened$ | async"></ls-controller-menu>

  <lv-virtual-keyboard></lv-virtual-keyboard>
</section>
