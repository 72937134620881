import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  InputComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  LoadingComponent, LVInputComponent, LVInputDecimalWithLabelComponent,
  LVInputIntegerWithLabelComponent,
  selectVirtualKeyboardIsActive,
  SvgIconComponent,
} from '@livestock/ui';
import { IHouseSizesView } from '../../../../../controllers/src/lib/interfaces/house-sizes-view.interface';
import { ColorsEnum, IconsEnum, LengthUnitEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { Observable, Subscription } from 'rxjs';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { greaterThanValidator, lessThanValidator } from '@livestock/shared/validators';
import { Store } from '@ngrx/store';

@Component({
  selector: 'ls-house-sizes-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    SvgIconComponent,
    InputComponent,
    QaTagsDirective,
    LoadingComponent,
    InputDecimalComponent,
    ErrorFieldDirective,
    KeyboardComponent,
    LVInputIntegerWithLabelComponent,
    InputIntegerComponent,
    LVInputComponent,
    LVInputDecimalWithLabelComponent,
  ],
  templateUrl: './house-sizes-settings-form.component.html',
  styleUrls: ['./house-sizes-settings-form.component.scss'],
})
export class ControllerHouseSizesSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() isDisabled: boolean = false;

  @Input() set houseSizesSettings(houseSizesSettings: IHouseSizesView) {
    if (houseSizesSettings) {
      this.updateFormValues(houseSizesSettings);
    }
  }

  @Output() changed = new EventEmitter();

  sub$ = new Subscription();
  isKeyboardActive$: Observable<boolean> = this.store.select(selectVirtualKeyboardIsActive);

  form = new FormGroup(
    {
      controllerID: new FormControl<number>(null),
      houseNumber: new FormControl<number>(0),
      houseWidth: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseLength: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseHeight: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
      houseRoofHeight: new FormControl(GlobalConstants.MinMeterWidthHeightLength),
    },
  );
  KeyboardModeEnum = KeyboardModeEnum;
  LengthUnitEnum = LengthUnitEnum;
  IconsEnum: typeof IconsEnum = IconsEnum;
  ColorsEnum = ColorsEnum;
  HouseNumberMin = 0;
  HouseNumberMax = 999;
  inputWidth = 70;
  inputWidthForHouseLength = 85;

  minValue: number;
  maxWidthHeightValue: number;
  maxLengthValue: number;
  accuracy: number = 1;

  private _lengthUnit: LengthUnitEnum;

  get lengthUnit(): LengthUnitEnum {
    return this._lengthUnit;
  }

  @Input() set lengthUnit(lengthUnit: LengthUnitEnum) {
    this._lengthUnit = lengthUnit;
    this.setMaxValidators(lengthUnit);
  }

  constructor(
    public languageService: LanguageService,
    public platformService: PlatformService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          this.setMaxValidators(this.lengthUnit);

          this.changed.emit({
            formValues,
            isValid: this.form.valid,
          });
        }),
    );
  }

  setMaxValidators(lengthUnit: LengthUnitEnum): void {
    const isValidBeforeSettingsValidators = this.form.valid;
    const controls = ['houseWidth', 'houseLength', 'houseHeight', 'houseRoofHeight'];
    this.maxLengthValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MaxFootLength
      : GlobalConstants.MaxMeterLength;
    this.maxWidthHeightValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MaxFootWidthAndHeight
      : GlobalConstants.MaxMeterWidthAndHeight;
    this.minValue = lengthUnit === LengthUnitEnum.Foot
      ? GlobalConstants.MinFootWidthHeightLength
      : GlobalConstants.MinMeterWidthHeightLength;

    controls.forEach(controlName => {
      this.form.controls[controlName].clearValidators();
    });

    //height
    this.form.controls['houseHeight'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        lessThanValidator('houseRoofHeight', null, true),
      ],
    );

    this.form.controls['houseRoofHeight'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        greaterThanValidator('houseHeight', null, true),
      ],
    );

    //width && length
    this.form.controls['houseWidth'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxWidthHeightValue),
        lessThanValidator('houseLength', null, true),
      ],
    );

    this.form.controls['houseLength'].addValidators([
        Validators.min(this.minValue),
        Validators.max(this.maxLengthValue),
        greaterThanValidator('houseWidth', null, true),
      ],
    );

    controls.forEach(controlName => {
      this.form.controls[controlName].updateValueAndValidity();
    });

    if (isValidBeforeSettingsValidators != this.form.valid) {
      this.changed.emit({
        formValues: this.form.value,
        isValid: this.form.valid,
      });
    }
  }

  updateFormValues(houseSizesSettings: IHouseSizesView): void {
    this.form.patchValue(houseSizesSettings);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
