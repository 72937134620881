import { Component, EventEmitter, Input, OnDestroy, Output } from '@angular/core';
import { FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';
import { NgSelectModule } from '@ng-select/ng-select';

import {
  ButtonComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  LoadingComponent,
  SelectDirective,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { EnumPipe, EnumToArrayPipe } from '@livestock/shared/pipes';
import { PlatformService } from '@livestock/shared/services';
import { AirTreatmentConstants, IAirTreatmentSettingsView, TreatmentByEnum } from '@livestock/air-treatment';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { GlobalConstants } from '@livestock/shared/constants';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-air-treatment-settings-form',
  templateUrl: './air-treatment-settings-form.component.html',
  styleUrls: ['./air-treatment-settings-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    InputDecimalComponent,
    SvgIconComponent,
    ButtonComponent,
    EnumPipe,
    ToggleComponent,
    NgSelectModule,
    SelectDirective,
    EnumToArrayPipe,
    QaTagsDirective,
  ],
})
export class AirTreatmentSettingsFormComponent implements OnDestroy {
  @Input() editMode: boolean;
  @Input() isLoading: boolean;

  @Input() set airTreatmentSettings(airTreatmentSettings: IAirTreatmentSettingsView) {
    if (airTreatmentSettings) {
      if (!this.form) {
        this.initForm(airTreatmentSettings);
      } else {
        this.updateFormValues(airTreatmentSettings);
      }
    }
  }

  @Input() set temperatureUnitType(unit: TemperatureUnitEnum) {
    this.temperatureUnitEnum = unit;
    const isCelsius = this.temperatureUnitEnum === TemperatureUnitEnum.Celsius;
    this.MIN_VALUE = isCelsius ? AirTreatmentConstants.MinIndoorTempToStop : AirTreatmentConstants.MinIndoorTempToStopF;
    this.MAX_VALUE = isCelsius ? AirTreatmentConstants.MaxIndoorTempToStop : AirTreatmentConstants.MaxIndoorTempToStopF;
    if (this.form) {
      this.form.controls['ventilationIndoorTempToStop'].addValidators([Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)]);
      this.form.controls['heatingIndoorTempToStop'].addValidators([Validators.min(this.MIN_VALUE), Validators.max(this.MAX_VALUE)]);
      this.form.controls['heatingIndoorTempToStop'].updateValueAndValidity();
      this.form.controls['ventilationIndoorTempToStop'].updateValueAndValidity();
    }
  }

  @Output() changed = new EventEmitter();

  // component vars
  form: FormGroup;
  MIN_VALUE: number;
  MAX_VALUE: number;
  temperatureUnitEnum: TemperatureUnitEnum;

  /* constants */
  TreatmentByEnum = TreatmentByEnum;
  TemperatureUnitEnum = TemperatureUnitEnum;
  GlobalConstants = GlobalConstants;

  // subs
  sub$: Subscription = new Subscription();

  constructor(public platformService: PlatformService) {
  }

  initForm(item: IAirTreatmentSettingsView): void {
    const validators = [Validators.required];
    if (this.MIN_VALUE != null && this.MAX_VALUE != null) {
      validators.push(Validators.min(this.MIN_VALUE));
      validators.push(Validators.max(this.MAX_VALUE));
    }
    this.form = new FormGroup({
      controllerID: new FormControl(item.controllerID),
      airTreatmentEnabled: new FormControl(item.airTreatmentEnabled, [Validators.required]),
      treatmentBy: new FormControl<TreatmentByEnum>(item.treatmentBy, [Validators.required]),
      ventilationMaxForTreatment: new FormControl<number>(item.ventilationMaxForTreatment, [Validators.min(AirTreatmentConstants.MinForTreatment), Validators.max(AirTreatmentConstants.MaxForTreatment), Validators.required]),
      ventilationIndoorTempToStop: new FormControl<number>(item.ventilationIndoorTempToStop, validators),
      heatingMaxForTreatment: new FormControl<number>(item.heatingMaxForTreatment, [Validators.min(AirTreatmentConstants.MinForTreatment), Validators.max(AirTreatmentConstants.MaxForTreatment), Validators.required]),
      heatingIndoorTempToStop: new FormControl<number>(item.heatingIndoorTempToStop, validators),
    });

    this.sub$.add(
      this.form.valueChanges.pipe(wasChanged())
        .subscribe((formValues) => {
          this.changed.emit({
            formValues: formValues,
            isValid: this.form.valid,
          });
        }),
    );
  }

  updateFormValues(settings: IAirTreatmentSettingsView): void {
    this.form.patchValue(settings);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
