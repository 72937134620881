import { inject } from '@angular/core';
import { ActivatedRouteSnapshot, ResolveFn } from '@angular/router';
import { ControllersService } from '../services/controllers.service';

export const elementsResolver: ResolveFn<any[]> =
  async (router: ActivatedRouteSnapshot) => {
    const controllersService = inject(ControllersService);

    return await Promise.all(router.data['elementType'].map(
      i => controllersService.getControllerElements(+router.paramMap.get('controllerID'), i).toPromise()),
    );
  };
