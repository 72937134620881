export enum StorageItem {
  Token = 'token',
  ActiveControllerID = 'activeControllerID',
  CheckNewFlockControllerID = 'checkNewFlockControllerID',
  ActiveFarmID = 'activeFarmID',
  ControllerMenuSlide = 'controllerMenuSlide',
  DashboardMenuSlide = 'dashboardMenuSlide',
  ProgramMenuSlide = 'programMenuSlide',
  ControllerLang = 'controllerLang',
  AppVersion = 'appVersion',
  NewControllerHouseNumber = 'houseNumber',
  NewControllerConnectionNumber = 'connectionNumber',
  IsBiometrySetup = 'isBiometryCredentialsSetup',
  FailedBiometricAttempts = 'failedBiometricAttempts',
}
