import { Moment } from 'moment';
import { FindElementIndexPipe } from '@livestock/shared/pipes';
import { Component, HostListener } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { MatDialogRef } from '@angular/material/dialog';
import { SwiperModule } from 'swiper/angular';
import { HourMinuteMeridiemEnum, MeridiemEnum } from '@livestock/shared/enums';
import * as moment from 'moment';

@Component({
  selector: 'ls-time-picker-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    FindElementIndexPipe,
  ],
  templateUrl: './time-picker-dialog.component.html',
  styleUrls: ['./time-picker-dialog.component.scss'],
})
export class TimePickerDialogComponent {
  hours: number[];
  minutes: number[] = Array.from({ length: 60 }, (_, i) => i);
  meridiem: string[] = ['AM', 'PM'];

  minuteValue: number;
  hourValue: number;
  meridiemValue: string;
  value: Moment;
  isInside: boolean = false;
  enableMeridian: boolean;

  HourMinuteMeridiemEnum = HourMinuteMeridiemEnum;
  MeridiemEnum = MeridiemEnum;

  constructor(
    public dialogRef: MatDialogRef<TimePickerDialogComponent>,
  ) {
  }

  setup(timeValue: Moment, enableMeridian: boolean): void {
    const timeFormat = enableMeridian ? 12 : 24;

    this.hours = Array.from({ length: timeFormat }, (_, i) => i = enableMeridian ? i + 1 : i);
    this.isInside = true;
    this.enableMeridian = enableMeridian;
    this.value = timeValue;
    this.minuteValue = timeValue.minute();
    this.hourValue = timeValue.hour() % timeFormat || timeFormat;
    this.meridiemValue = timeValue.format('A');
  }

  onIndexChange([swiper], value: HourMinuteMeridiemEnum): void {
    switch (value) {
      case HourMinuteMeridiemEnum.Hour:
        this.hourValue = this.hours[swiper.realIndex];
        break;
      case HourMinuteMeridiemEnum.Minute:
        this.minuteValue = this.minutes[swiper.realIndex];
        break;
      case HourMinuteMeridiemEnum.Meridiem:
        this.meridiemValue = this.meridiem[swiper.realIndex];
        break;
      default:
        this.value;
    }
  }

  @HostListener('document:click', ['$event'])
  close(): void {
    if (!this.isInside) {
      this.hourValue = this.hours.find(val => val === this.hourValue);

      if (this.enableMeridian) {
        this.hourValue = this.meridiemValue === 'AM' ? this.hourValue : this.hourValue + 12;
        this.hourValue = this.hourValue === 12 || this.hourValue === 24 ? this.hourValue - 12 : this.hourValue;
      }

      this.dialogRef.close(moment(this.value.set({
        hour: parseInt(this.hourValue.toString()),
        minute: parseInt(this.minuteValue.toString()),
      }).toDate()));
    }
    this.isInside = false;
  }
}