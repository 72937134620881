import { Component, HostListener, Inject, Renderer2 } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { AppDisplayClientSseService, DisplayTopMenuComponent } from '@livestock/appdisplay';
import { TranslateService } from '@ngx-translate/core';
import { FlashMessageComponent } from '@livestock/notifications';
import { delay, filter, mergeMap, of, retryWhen, Subscription, take, tap, throwError } from 'rxjs';
import { CommonModule, DOCUMENT } from '@angular/common';
import { ControllerMenuComponent } from '@livestock/dashboard';
import {
  ActivatedRouteService,
  BuildInfoService,
  LanguageService,
  LocalStorageService,
  MenuService,
} from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import {
  ControllerLanguageEnum,
  controllerLanguagesMapped,
  getFlockWeightReferenceTable,
  selectControllerLanguage,
  setActiveControllerID,
  setControllerLanguage,
} from '@livestock/controllers';
import { GlobalConstants } from '@livestock/shared/constants';
import { ChickenBrandWeight, StorageItem } from '@livestock/shared/enums';
import { TopMenuComponent } from '@livestock/menu';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ControllersService } from '../../../../libs/controllers/src/lib/services/controllers.service';
import { QuickStartStatusEnum } from '@livestock/controllers/enums';
import { AppRoutes } from '@livestock/shared/routes';
import { LoadingGalconComponent, VirtualKeyboardComponent } from '@livestock/ui';

@Component({
  standalone: true,
  imports: [RouterModule, DisplayTopMenuComponent, FlashMessageComponent, ControllerMenuComponent, CommonModule, TopMenuComponent, MemoizeFuncPipe, VirtualKeyboardComponent, LoadingGalconComponent],
  selector: 'ls-display-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  sub$ = new Subscription();
  title = 'display';
  touchDebug = 0;

  @HostListener('window:mousedown')
  @HostListener('window:mousemove')
  @HostListener('window:touchstart')
  @HostListener('window:keydown')
  onEvent(): void {
    this.touchDebug += 1;
  }

  constructor(
    public menuService: MenuService,
    public languageService: LanguageService,
    public activatedRouteService: ActivatedRouteService,
    public router: Router,
    public buildInfoService: BuildInfoService,
    private readonly translateService: TranslateService,
    private readonly AppDisplayClientSseService: AppDisplayClientSseService,
    private store: Store,
    private controllersService: ControllersService,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
  ) {
    this.translateService.addLangs([
      controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS),
      controllerLanguagesMapped.get(ControllerLanguageEnum.Hebrew),
    ]);
    this.translateService.setDefaultLang(controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS));
    const storedLanguage: ControllerLanguageEnum = +LocalStorageService.getStorageItem(StorageItem.ControllerLang);
    this.store.dispatch(setControllerLanguage({ controllerLanguage: storedLanguage ?? ControllerLanguageEnum.EngUS }));

    const lang = controllerLanguagesMapped.get(+storedLanguage) || controllerLanguagesMapped.get(ControllerLanguageEnum.EngUS);
    this.translateService.use(lang);
    document.dir = lang === controllerLanguagesMapped.get(ControllerLanguageEnum.Hebrew) ? 'rtl' : 'ltr';

    // TODO: use actual controllerID
    this.store.dispatch(setActiveControllerID({ controllerID: GlobalConstants.HardCodedControllerIDForDevice }));
  }

  ngOnInit(): void {
    this.sub$.add(this.AppDisplayClientSseService.sseEvents$.subscribe(ev => console.log('sse:', ev)));

    this.sub$.add(
      this.store.select(selectControllerLanguage).pipe(
        filter(res => res != null),
      ).subscribe(lang => {
        this.translateService.use(controllerLanguagesMapped.get(lang));
        document.dir = lang === ControllerLanguageEnum.Hebrew ? 'rtl' : 'ltr';
      }),
    );

    this.sub$.add(
      this.buildInfoService.json$.pipe(filter((res) => res != null)).subscribe((versionInfo) => {
        const storedVersion = LocalStorageService.getStorageItem(StorageItem.AppVersion);
        const currentVersion = versionInfo.version;

        if (storedVersion !== currentVersion) {
          LocalStorageService.removeAllExcept([StorageItem.Token, StorageItem.ControllerLang, StorageItem.ActiveControllerID]);
          LocalStorageService.setStorageItem(StorageItem.AppVersion, currentVersion);
        }
      }),
    );

    // Redirect to Dashboard if quick start process was completed
    this.controllersService.getQuickstartStatus().pipe(
      retryWhen(errors =>
        errors.pipe(
          // log error message
          tap(() => {
            console.log(`retrying`);
          }),
          mergeMap(error => {
            // TODO: REMOVE AFTER WEB ENV IS TERMINATED
            if (window.location.host.includes('galconsmart.com')) {
              return throwError(() => new Error('stopAnimation'));
            }
            return of(error).pipe(
              delay(2000),
              take(10),
            ); // Continue retrying
          }),
        ),
      ),
      // restart in 5 seconds
      delay(2000),
      take(10),
    ).subscribe((status: string) => {
      this.getFlockWeight();
      this.renderer.addClass(this.document.getElementById('preloader-holder'), 'animation-hide');
      window['stopAnimation'] = true;
      if (QuickStartStatusEnum.Done === Number(status)) {
        this.router.navigate([AppRoutes.DASHBOARD]);
        return;
      } else {
        this.router.navigate(['/']);
      }
    }, error => {
      if (error?.message === 'stopAnimation') {
        this.renderer.addClass(this.document.getElementById('preloader-holder'), 'animation-hide');
        window['stopAnimation'] = true;
      }
      this.getFlockWeight();
    });
  }

  getFlockWeight(): void {
    this.store.dispatch(getFlockWeightReferenceTable({
      brand: ChickenBrandWeight.COBB_500,
      deviceLogic: !window.location.host.includes('galconsmart.com'),
    }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
