import { IEditUnitModeObj } from '../../interfaces';

export enum AirSpeedUnitEnum {
  MeterPerSecond,
  FeetPerMinute,
}

export namespace AirSpeedUnitEnum {
  export function toTranslateKey(value: any): string {
    switch (value) {
      case AirSpeedUnitEnum.MeterPerSecond:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirSpeedEnum.ms';
      case AirSpeedUnitEnum.FeetPerMinute:
        return 'Controller.GeneralSettings.CustomUnitsDialog.AirSpeedEnum.fm';
      default:
        return value.toString();
    };
  }

  export function isMetricUnit(value: AirSpeedUnitEnum): boolean {
    return value === AirSpeedUnitEnum.MeterPerSecond;
  }

  export function toEditUnitModeObj(): IEditUnitModeObj {
    return {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.AirSpeed',
      controlName: 'airSpeed',
      enumName: AirSpeedUnitEnum,
      enumStr: 'air-speed-unit-enum',
    };
  }
}
