import { inject } from '@angular/core';
import { ActivatedRouteSnapshot } from '@angular/router';
import { ControllersService } from 'libs/controllers/src/lib/services/controllers.service';
import { firstValueFrom } from 'rxjs';
import { DialogsService } from '@livestock/shared/services';

export const ElementsGuard = async (route: ActivatedRouteSnapshot): Promise<boolean> => {
  const controllersService = inject(ControllersService);
  const dialogService = inject(DialogsService);

  if (!route.data['elementType']) return true;

  const elements = await Promise.all(route.data['elementType'].map(i => {
    return firstValueFrom(controllersService
      .getControllerElements(+route.paramMap.get('controllerID'), i));
  }));

  const elementsExist = elements.some(el => el.items.length);

  if (!elementsExist) {
    dialogService.error('Controller.Menu.PleaseCreateElementsBeforeEdit', 'Controller.Menu.NoAvailableElements');
    return false;
  }

  return true;
};
