import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Output } from '@angular/core';
import { PlatformService } from '@livestock/shared/services';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  standalone: true,
  imports: [CommonModule],
  selector: 'ls-main-widget-common',
  template: '',
})
export class MainWidgetCommonComponent {
  @Output() closeWidget = new EventEmitter();
  ColorsEnum = ColorsEnum;

  constructor(public platformService: PlatformService) {
  }

  emitCloseWidget(): void {
    this.closeWidget.emit();
  }
}
