import { Component, OnDestroy, OnInit } from '@angular/core';
import {
  ButtonComponent,
  clearKeyboardValue,
  InputTextComponent,
  KeyboardComponent,
  LoadingComponent,
  PageWrapperComponent,
  selectKeyboardValueAndUUID,
  SensorsKeyboardComponent,
  setElementUuid,
  SvgIconComponent,
} from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { HeatersKeyboardComponent } from './heaters-keyboard/heaters-keyboard.component';
import { HeatersZoneModeEnum } from '../enums/heaters-zone-mode.enum';
import {
  combineLatest,
  filter,
  firstValueFrom,
  lastValueFrom,
  Observable,
  Subscription,
} from 'rxjs';
import { IHeatingProgramZoneItem } from '../interfaces';
import { Store } from '@ngrx/store';
import {
  selectActiveZoneID,
  selectHeatingAOElements,
  selectHeatingDOElements,
  selectHeatingProgramActiveZone,
  selectHeatingProgramZones,
  selectIfSomeZoneNamesAreEmpty,
  selectIsLoading,
  selectOriginalZones,
  selectSelectedHeatersIDs,
  selectSensorElements,
} from '../+state/heating-program-zone.selectors';
import {
  addHeaterToMovingArray,
  addHeatingProgramZone,
  addSensorsElementsToZone,
  changeIsAverage,
  changeZoneName,
  clearHeatingZonesState,
  getHeatingProgramZones,
  moveHeatersToAnotherZone,
  removeHeaterFromMovingArray,
  removeHeatingProgramZone,
  setActiveHeatingProgramZone,
  setOriginalZones,
  updateHeatingProgramZones,
} from '../+state/heating-program-zone.actions';
import { ColorsEnum, DialogButtonEnum, KeyboardEnum } from '@livestock/shared/enums';
import { DialogsService, PlatformService } from '@livestock/shared/services';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { MoveHeatersPopupComponent } from './move-heaters-popup/move-heaters-popup.component';
import { IElement } from '@livestock/installation/interfaces';
import { HeatingProgramZoneMobileComponent } from './heating-program-zone-mobile/heating-program-zone-mobile.component';
import { ActivatedRoute, Router } from '@angular/router';
import { ControllerRoutes } from '@livestock/shared/routes';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-heating-program-zone',
  templateUrl: 'heating-program-zone.component.html',
  styleUrls: ['heating-program-zone.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    TranslateModule,
    HeatersKeyboardComponent,
    SensorsKeyboardComponent,
    ButtonComponent,
    LoadingComponent,
    InputTextComponent,
    MatCheckboxModule,
    KeyboardComponent,
    HeatingProgramZoneMobileComponent,
    MemoizeFuncPipe,
    PageWrapperComponent,
    QaTagsDirective,
  ],
})
export class HeatingProgramZoneComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  // Observables
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  someZoneNamesAreEmpty$: Observable<boolean> = this.store.select(selectIfSomeZoneNamesAreEmpty);
  zones$: Observable<IHeatingProgramZoneItem[]> = this.store.select(selectHeatingProgramZones);
  activeZoneID$: Observable<number> = this.store.select(selectActiveZoneID);
  activeZone$: Observable<IHeatingProgramZoneItem> = this.store.select(selectHeatingProgramActiveZone);
  selectedHeatersIDs$: Observable<number[]> = this.store.select(selectSelectedHeatersIDs);

  // vars
  editMode: boolean;
  heatingZoneMode: HeatersZoneModeEnum | null = HeatersZoneModeEnum.Heaters;
  heatingAOElements: IElement[] = [];
  heatingDOElements: IElement[] = [];
  sensorElements: IElement[] = [];
  originalZones: IHeatingProgramZoneItem[] = [];
  controllerID: number;
  MAX_ZONES = 15;

  // enums
  HeatersZoneModeEnum = HeatersZoneModeEnum;
  ColorsEnum = ColorsEnum;
  ElementType = ElementTypesEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private dialogsService: DialogsService,
    private dialog: MatDialog,
    private router: Router,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.controllerID = +this.activatedRoute.snapshot.params['controllerID'];
    this.store.dispatch(getHeatingProgramZones());

    this.sub$.add(
      combineLatest([
        this.store.select(selectHeatingAOElements),
        this.store.select(selectHeatingDOElements),
        this.store.select(selectSensorElements),
      ]).pipe(
        filter(([a, b, c]) => a != null && b != null && c != null),
        wasChanged(),
      ).subscribe(([heatingAOElements, heatingDOElements, sensorElements]) => {
        this.heatingAOElements = heatingAOElements;
        this.heatingDOElements = heatingDOElements;
        this.sensorElements = sensorElements;
      }),
    );

    this.sub$.add(
      this.store.select(selectOriginalZones).pipe(
        filter(res => res?.length > 0),
      ).subscribe((zones) => {
        this.originalZones = zones;
      }),
    );

    if (!this.platformService.isMobileApp) {
      this.sub$.add(
        this.store.select(selectKeyboardValueAndUUID)
          .pipe(
            filter(({ elementUuid, symbol }) => {
              return elementUuid && symbol != null && !symbol.toString().includes(KeyboardEnum.Enter);
            }),
          ).subscribe(({ elementUuid, symbol }) => {
          const input: HTMLInputElement = document.querySelector(`[data-zone-id=${elementUuid}]`);
          const { selectionStart, selectionEnd, value } = input;
          const isSingleDeletion = selectionStart === selectionEnd && symbol === '' && selectionStart > 0;
          const isMultipleDeletion = symbol === '' && selectionStart !== selectionEnd;

          // do nothing if cursor is in the beginning and we press BACKSPACE button
          if (symbol === '' && selectionStart === selectionEnd && selectionStart === 0) {
            this.store.dispatch(clearKeyboardValue());
            return;
          }

          const currentValue = value.split('');

          if (isSingleDeletion) {
            // remove the symbol before the cursor
            currentValue.splice(selectionStart - 1, 1);
          } else {
            // add symbol in the right place or remove multiple symbols
            currentValue.splice(selectionStart, selectionEnd - selectionStart, symbol as string);
          }

          this.store.dispatch(changeZoneName({ name: currentValue.join('') }));
          this.store.dispatch(clearKeyboardValue());

          // set cursor for prev place for single deletion
          if (isSingleDeletion) {
            setTimeout(() => {
              input.selectionStart = selectionStart - 1;
              input.selectionEnd = selectionStart - 1;
            });
            return;
          }

          // do not move cursor for multiple deletion
          if (isMultipleDeletion) {
            setTimeout(() => {
              input.selectionStart = selectionStart;
              input.selectionEnd = selectionStart;
            });
            return;
          }

          // set cursor on next place after adding new symbol
          setTimeout(() => {
            input.selectionStart = selectionStart + 1;
            input.selectionEnd = selectionStart + 1;
          });
        }),
      );
    }
  }

  async areAnyChanges(): Promise<boolean> {
    const zones = await firstValueFrom(this.zones$);
    return JSON.stringify(zones) !== JSON.stringify(this.originalZones);
  }

  async confirmChangesNotSaved(): Promise<boolean> {
    if (await this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    return true;
  }

  async toggleEditMode(): Promise<void> {
    const changesNotSavedConfirmation = await this.confirmChangesNotSaved();
    if (!changesNotSavedConfirmation) return;

    this.editMode = !this.editMode;
    this.heatingZoneMode = null;
    if (!this.editMode) {
      this.store.dispatch(setOriginalZones({ zones: this.originalZones }));
    }
  }

  async chooseZoneMode(heatingZoneMode: HeatersZoneModeEnum, zoneID: number): Promise<void> {
    if (!this.editMode) return;

    this.heatingZoneMode = heatingZoneMode;
    const activeZoneID = await firstValueFrom(this.activeZoneID$);
    if (activeZoneID === zoneID) return;

    this.store.dispatch(setActiveHeatingProgramZone({ zoneID }));
  }

  addZone(): void {
    this.store.dispatch(addHeatingProgramZone());
  }

  async removeZone(): Promise<void> {
    const activeZone = await firstValueFrom(this.activeZone$);

    if (activeZone.isUsedInProgram) {
      this.store.dispatch(setFlashMessage({
        flashMessage: {
          flashType: FlashMessageTypeEnum.Error,
          message: 'FlashMessages.ZoneHasProgram',
        },
      }));
      return;
    }

    if (activeZone.sensorElements?.length > 0 || activeZone.isAverage) {
      const message = 'HeatingProgramZone.DoYouWantToDeleteThisZone';
      const title = 'HeatingProgramZone.ThisZoneHasSensorElements';
      const result = await this.dialogsService.question(
        message, title, [DialogButtonEnum.NO, DialogButtonEnum.YES],
      );
      if (result === DialogButtonEnum.NO || !result) {
        return;
      }
    }

    this.store.dispatch(removeHeatingProgramZone());
  }

  changeIsAverage(isAverage: MatCheckboxChange, zoneID: number): void {
    this.heatingZoneMode = null;
    this.store.dispatch(setElementUuid({
      elementUuid: null,
    }));
    this.store.dispatch(setActiveHeatingProgramZone({ zoneID }));
    this.store.dispatch(changeIsAverage({ isAverage: isAverage.checked }));
  }

  enableKeyboard(zoneID: number): void {
    this.heatingZoneMode = null;
    const input = document.querySelector(`[data-zone-id=zoneName${zoneID}]`);
    this.store.dispatch(setElementUuid({
      elementUuid: input.getAttribute('uuid'),
    }));
  }

  changeZoneName(ev): void {
    this.store.dispatch(changeZoneName({ name: ev.target.value }));
  }

  async addOrRemoveHeaterToMovingArray(event: Event, elementID: number, zoneID: number): Promise<void> {
    event.stopPropagation();

    if (!this.editMode) return;

    this.heatingZoneMode = HeatersZoneModeEnum.Heaters;
    const activeZoneID = await firstValueFrom(this.activeZoneID$);
    if (activeZoneID !== zoneID) {
      this.store.dispatch(setActiveHeatingProgramZone({ zoneID }));
    }

    const selectedHeaters = await firstValueFrom(this.selectedHeatersIDs$);
    if (selectedHeaters.includes(elementID)) {
      this.store.dispatch(removeHeaterFromMovingArray({ elementID }));
      return;
    }

    this.store.dispatch(addHeaterToMovingArray({ elementID }));
  }

  async openMoveHeatersPopup(): Promise<void> {
    const allZones = await firstValueFrom(this.zones$);
    const activeZone = await firstValueFrom(this.activeZone$);

    // we can't copy AO elements to zone with DO elements, so we should check the current type of heaters of active
    // zone and disable zones with other type
    const heatingElementsTypeToCopy = this.heatingAOElements.some(el => {
      return activeZone.heatingElements.map(heatingEl => heatingEl.elementID).includes(el.elementID);
    })
      ? this.heatingAOElements
      : this.heatingDOElements;

    const zonesForPopup = allZones
      .filter(zone => zone.zoneID !== activeZone.zoneID)
      .map(zone => {
        const isDisabled = !heatingElementsTypeToCopy.some(el => {
          return zone.heatingElements.length === 0
            || zone.heatingElements.map(heatingEl => heatingEl.elementID).includes(el.elementID);
        });

        return {
          id: zone.zoneID,
          name: zone.name,
          isDisabled,
        };
      });

    const dialogRef = this.dialog.open(MoveHeatersPopupComponent, {
      width: '300px',
      disableClose: true,
      data: { zones: zonesForPopup },
    });

    const result = await lastValueFrom(dialogRef.afterClosed());
    if (result?.zoneID) {
      this.store.dispatch(moveHeatersToAnotherZone({ zoneID: result.zoneID }));
    }
  }

  save(): void {
    this.store.dispatch(updateHeatingProgramZones());
  }

  async goToProgramsPage(): Promise<void> {
    this.router.navigate([`/controller/${this.controllerID}/${ControllerRoutes.HeatingPrograms}`]);
  }

  saveSensors(sensors: IElement[]): void {
    this.store.dispatch(addSensorsElementsToZone({ sensors }));
  }

  trackBy(index: number): number {
    return index;
  }

  async canDeactivate(): Promise<boolean> {
    return !await this.areAnyChanges();
  }

  closeComponent(): void {
  }

  ngOnDestroy(): void {
    this.store.dispatch(clearHeatingZonesState());
    this.sub$.unsubscribe();
  }
}
