import { EditDayDialogComponent } from '@livestock/ui';
import { lastValueFrom } from 'rxjs';
import { Component, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformService } from '@livestock/shared/services';
import { MatDialog } from '@angular/material/dialog';
import { AddDeleteDayEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-days-manager-mobile',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
  ],
  templateUrl: './days-manager-mobile.component.html',
  styleUrls: ['./days-manager-mobile.component.scss'],
})
export class DaysManagerMobileComponent {
  @Output() addDay = new EventEmitter();
  @Output() deleteDay = new EventEmitter();

  constructor(
    public platformService: PlatformService,
    private dialog: MatDialog,
  ) {
  }

  async openEditDayDialog(): Promise<void> {
    const dialogRef = this.dialog.open(EditDayDialogComponent, {
      width: '90%',
    });

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (!res) return;

    if (res === AddDeleteDayEnum.DeleteDay) {
      this.deleteDay.emit();
    };

    if (res === AddDeleteDayEnum.AddDay) {
      this.addDay.emit();
    };
  }
}