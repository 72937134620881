import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef } from '@angular/material/dialog';
import {
  ButtonComponent,
  ButtonWithIconComponent,
  NoSearchResultsComponent,
  SearchComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { PlatformService } from '@livestock/shared/services';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Store } from '@ngrx/store';
import {
  getVentilationBrands, getVentilationModels,
  IVentBrandItem, IVentModelAOItem, IVentModelDOItem, selectVentilationAOModelsByBrandID,
  selectVentilationBrands, selectVentilationDOModelsByBrandID,
} from '@livestock/ventilation-brands';
import { Observable } from 'rxjs';
import { RouterLink } from '@angular/router';
import { FormsModule } from '@angular/forms';
import { FilterCollectionPipe } from '@livestock/shared/pipes';
import { ColorsEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-ventilation-brand-dialog',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    ButtonComponent,
    ButtonWithIconComponent,
    QaTagsDirective,
    RouterLink,
    SearchComponent,
    FormsModule,
    FilterCollectionPipe,
    SvgIconComponent,
    NoSearchResultsComponent,
  ],
  templateUrl: './ventilation-brand-dialog.component.html',
  styleUrls: ['./ventilation-brand-dialog.component.scss'],
})
export class VentilationBrandDialogComponent implements OnInit {
  brands$: Observable<IVentBrandItem[]> = this.store.select(selectVentilationBrands);
  models$: Observable<(brandID: number) => (IVentModelDOItem | IVentModelAOItem)[]>;
  brandID: number;
  modelID: number;
  isDO: boolean;
  brandSearchValue: string = '';
  modelSearchValue: string = '';
  ColorsEnum = ColorsEnum;

  constructor(
    public dialogRef: MatDialogRef<VentilationBrandDialogComponent>,
    public platformService: PlatformService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getVentilationBrands());
  }

  setup(brandID: number, modelID: number, isDO: boolean = true): void {
    this.brandID = brandID;
    this.modelID = modelID;
    this.isDO = isDO;
    if (this.brandID) {
      this.store.dispatch(getVentilationModels({ brandID, isDO }));
    }

    this.models$ = isDO
      ? this.store.select(selectVentilationDOModelsByBrandID)
      : this.store.select(selectVentilationAOModelsByBrandID);
  }

  saveValues(): void {
    if (!this.brandID || !this.modelID) return;

    this.dialogRef.close({
      brandID: this.brandID,
      modelID: this.modelID,
    });
  }

  selectBrand(brandID: number): void {
    if (this.brandID === brandID) return;
    this.brandID = brandID;
    this.modelID = null;
    this.store.dispatch(getVentilationModels({ brandID, isDO: this.isDO }));
  }

  selectModel(modelID: number): void {
    if (this.modelID === modelID) return;
    this.modelID = modelID;
  }

  close(): void {
    this.dialogRef.close();
  }

  trackBy(index: number): number {
    return index;
  }
}
