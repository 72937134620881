import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ButtonComponent, SvgIconComponent, HeaderTitleComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { IHouseSizesView } from '../../interfaces/house-sizes-view.interface';
import { Observable, Subscription, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute } from '@angular/router';

import {
  getGeneralSettings,
  getHouseSizesSettings,
  setHouseSizesSettings,
  updateHouseSizesSettings,
} from '../../+state/upsert-controller/upsert-controller.actions';
import { MenuService, PlatformService } from '@livestock/shared/services';
import {
  ControllerHouseSizesSettingsFormComponent,
} from '../../ui/house-sizes-settings-form/house-sizes-settings-form.component';
import { LengthUnitEnum } from '@livestock/shared/enums';
import {
  selectIsLoading,
  selectLengthUnit,
  selectHouseSizesSettings,
} from '../../+state/upsert-controller/upsert-controller.selectors';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-controller-house-sizes-settings',
  standalone: true,
  imports: [
    CommonModule,
    CommonModule,
    TranslateModule,
    ControllerHouseSizesSettingsFormComponent,
    ButtonComponent,
    SvgIconComponent,
    HeaderTitleComponent,
  ],
  templateUrl: './house-sizes-settings.component.html',
  styleUrls: ['../general-settings/general-settings.component.scss', './house-sizes-settings.component.scss'],
})
export class ControllerHouseSizesSettingsComponent implements OnDestroy, OnInit {
  houseSizesSettings: IHouseSizesView;
  originalHouseSizesSettings: IHouseSizesView;
  isValid = true;
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  lengthUnit$: Observable<LengthUnitEnum> = this.store.select(selectLengthUnit);
  isDirtyForm = false;

  private _controllerID: number;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    public platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.pipe(filter((params) => Boolean(+params['controllerID']))).subscribe((params) => {
        this._controllerID = +params['controllerID'];
        this.store.dispatch(getGeneralSettings({ controllerID: this._controllerID })); // To get Unit
        this.store.dispatch(getHouseSizesSettings({ controllerID: this._controllerID }));
      }),
    );

    this.sub$.add(
      this.store
        .select(selectHouseSizesSettings)
        .pipe(
          wasChanged(),
          filter((settings) => this._controllerID && settings?.controllerID === this._controllerID),
        )
        .subscribe((houseSizesSettings) => {
          if (!this.originalHouseSizesSettings && houseSizesSettings.controllerID) {
            this.originalHouseSizesSettings = houseSizesSettings;
          }

          this.houseSizesSettings = houseSizesSettings;
        }),
    );
  }

  changedHouseSizesSettings(event: { formValues: any; isValid: boolean }): void {
    this.isValid = event.isValid;
    this.isDirtyForm = JSON.stringify(this.originalHouseSizesSettings) !== JSON.stringify(event.formValues);
    if (event.isValid) {
      this.store.dispatch(setHouseSizesSettings({ houseSizesSettings: event.formValues }));
    }
  }

  cancel(): void {
    this.store.dispatch(setHouseSizesSettings({ houseSizesSettings: this.originalHouseSizesSettings }));
    this.menuService.toggleDashboardMenu(true);
  }

  update(): void {
    this.originalHouseSizesSettings = this.houseSizesSettings;
    this.store.dispatch(updateHouseSizesSettings());
    this.menuService.toggleDashboardMenu(true);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
