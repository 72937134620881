<section
  [class.rtl]="languageService.isRtl"
  class="page-content d-flex flex-column">
  <div class="header mb-20 mb-40-md d-flex align-center justify-between flex-column-md align-start-md">
    <ls-header-title
      (cancel)="goToMenu()"
      [qaTags]="'qa-lbl-temperature-mapping'"
      [title]="'TemperatureMapping.Title'"
      class="mb-10-md"></ls-header-title>

    <div
      *ngIf="!platformService.isMobileApp || !editMode"
      class="header-icons d-flex align-center">
      <ls-svg-icon
        (click)="toggleEditMode()"
        [class.pointer-events-none]="isLoading$ | async"
        [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
        [hasMargin]="true"
        [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
        [qaTags]="'qa-icon-edit'"
        class="pointer mr-10"></ls-svg-icon>
      <ls-svg-icon
        [class.disabled]="editMode"
        [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
        [hasMargin]="true"
        [path]="'installation/graph' + (platformService.isMobileApp ? '-mobile' : '')"
        class="pointer mr-10"></ls-svg-icon>
      <ls-svg-icon
        (click)="goToSettings()"
        [class.disabled]="editMode"
        [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
        [hasMargin]="true"
        [path]="'installation/settings' + (platformService.isMobileApp ? '-mobile' : '')"
        class="pointer"></ls-svg-icon>
    </div>
  </div>

  <ng-container *ngIf="!platformService.isMobileApp; else mobileTemplate">
    <div
      *ngIf="!editMode; else editModeTemplate"
      class="content h-100-percent min-height-0 d-flex flex-column">
      <ng-container *ngIf="!(isLoading$ | async); else loading">
        <div
          *ngIf="{ value: (temperatureUnit$ | async) } as temperatureUnit"
          class="current-temp text-right mb-10">
          {{ "TemperatureMapping.CurrentTemp" | translate }}
          <span class="fw-700">
            23{{ temperatureUnit.value | memoizeFunc : TemperatureUnitEnum.toShortTranslateKey | translate }}
          </span>
        </div>

        <div class="table-wrapper h-100-percent overflow-hidden">
          <div class="table h-100-percent w-1-20 w-2-20 w-3-10 w-4-25 w-5-25 overflow-auto">
            <div class="table-row table-header h-60">
              <div class="table-cell">{{ "TemperatureMapping.Stage" | translate }}</div>
              <div class="table-cell">{{ "TemperatureMapping.Device" | translate }}</div>
              <div class="table-cell">{{ "TemperatureMapping.Average" | translate }}</div>
              <div class="table-cell">{{ "TemperatureMapping.TemperatureSensors" | translate }}</div>
              <div class="table-cell">{{ "TemperatureMapping.Heaters" | translate }}</div>
            </div>

            <!--FULL HOUSE-->
            <div
              *ngIf="fullHouse$ | async as fullHouse"
              class="table-row">
              <div class="table-cell gray">{{ "TemperatureMapping.FullHouse" | translate }}</div>
              <div class="table-cell gray"></div>
              <div class="table-cell align-center">
                <mat-checkbox
                  *ngIf="fullHouse.average"
                  checked
                  class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
              </div>
              <div class="table-cell justify-start align-center padding-top-10 flex-wrap">
                <ng-container
                  *ngIf="fullHouse.average; else sensors"
                  [ngTemplateOutlet]="averageSensors"></ng-container>

                <ng-template #sensors>
                  <div
                    *ngFor="let sensor of fullHouse.sensors"
                    class="sensor">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/temp'"
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                  </div>
                </ng-template>
              </div>
              <div class="table-cell"></div>
            </div>

            <!--Brooding #1-->
            <ng-container
              [ngTemplateOutletContext]="{ title: 'TemperatureMapping.Brooding1' }"
              [ngTemplateOutlet]="(brooding1HasMappings$ | async) ? brooding1Mappings : emptyRow">
              <ng-template
                #brooding1Mappings
                let-title="title">
                <ng-container *ngIf="(brooding1HeatingZones$ | async).length > 0">
                  <div
                    *ngFor="let brooding1heatingZone of brooding1HeatingZones$ | async; first as first"
                    class="table-row">
                    <div
                      *ngIf="first"
                      class="table-cell gray">
                      {{ title | translate }}
                    </div>
                    <div
                      *ngIf="!first"
                      class="table-cell gray empty"></div>
                    <div class="table-cell gray">{{ brooding1heatingZone.name }}</div>
                    <div class="table-cell align-center padding-top-10">
                      <mat-checkbox
                        *ngIf="brooding1heatingZone.isAverage"
                        checked
                        class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                    </div>
                    <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                      <ng-container
                        *ngIf="brooding1heatingZone.isAverage; else sensors"
                        [ngTemplateOutlet]="averageSensors"></ng-container>

                      <ng-template #sensors>
                        <div
                          *ngFor="let sensor of brooding1heatingZone.sensorElements"
                          class="sensor">
                          <ls-svg-icon
                            [color]="ColorsEnum.BlueDefault"
                            [fullHeight]="true"
                            [path]="'heating/temp'"
                            class="mb-5"></ls-svg-icon>
                          <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                        </div>
                      </ng-template>
                    </div>
                    <div class="table-cell justify-start padding-top-10 flex-wrap">
                      <div
                        *ngFor="let heater of brooding1heatingZone.heatingElements"
                        class="heater">
                        <ls-svg-icon
                          [color]="ColorsEnum.BlueDefault"
                          [fullHeight]="true"
                          [path]="'heating/heat'"
                          [overlayPath]="heater.elementType === ElementTypesEnum.HeatingDO ? 'warning' : null"
                          class="mb-5"></ls-svg-icon>
                        <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                      </div>
                    </div>
                  </div>
                </ng-container>

                <ng-container *ngIf="(brooding1ElementMappings$ | async).length > 0">
                  <div
                    *ngFor="let brooding1ElementMapping of brooding1ElementMappings$ | async; first as first"
                    class="table-row">
                    <div
                      *ngIf="first && (brooding1HeatingZones$ | async).length === 0"
                      class="table-cell gray">
                      {{ "TemperatureMapping.Brooding1" | translate }}
                    </div>
                    <div
                      *ngIf="!first || (brooding1HeatingZones$ | async).length > 0"
                      class="table-cell empty gray"></div>
                    <div class="table-cell gray">
                      {{ brooding1ElementMapping.elementName }}
                    </div>
                    <div class="table-cell align-center">
                      <mat-checkbox
                        *ngIf="brooding1ElementMapping.average"
                        checked
                        class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                    </div>
                    <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                      <ng-container
                        *ngIf="brooding1ElementMapping.average; else sensors"
                        [ngTemplateOutlet]="averageSensors"></ng-container>

                      <ng-template #sensors>
                        <div
                          *ngFor="let sensor of brooding1ElementMapping.sensors"
                          class="sensor">
                          <ls-svg-icon
                            [color]="ColorsEnum.BlueDefault"
                            [fullHeight]="true"
                            [path]="'heating/temp'"
                            class="mb-5"></ls-svg-icon>
                          <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                        </div>
                      </ng-template>
                    </div>
                    <div class="table-cell justify-start padding-top-10"></div>
                  </div>
                </ng-container>
              </ng-template>
            </ng-container>

            <!--Brooding #2-->
            <ng-container *ngIf="brooding2IsShown$ | async">
              <ng-container
                [ngTemplateOutletContext]="{ title: 'TemperatureMapping.Brooding2' }"
                [ngTemplateOutlet]="(brooding2HasMappings$ | async) ? brooding2Mappings : emptyRow">
                <ng-template
                  #brooding2Mappings
                  let-title="title">
                  <div
                    *ngFor="let brooding2heatingZone of brooding2HeatingZones$ | async; first as first"
                    class="table-row">
                    <div
                      *ngIf="first"
                      class="table-cell gray">
                      {{ title | translate }}
                    </div>
                    <div
                      *ngIf="!first"
                      class="table-cell gray empty"></div>
                    <div class="table-cell gray">{{ brooding2heatingZone.name }}</div>
                    <div class="table-cell align-center padding-top-10">
                      <mat-checkbox
                        *ngIf="brooding2heatingZone.isAverage"
                        checked
                        class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                    </div>
                    <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                      <ng-container
                        *ngIf="brooding2heatingZone.isAverage; else sensors"
                        [ngTemplateOutlet]="averageSensors"></ng-container>

                      <ng-template #sensors>
                        <div
                          *ngFor="let sensor of brooding2heatingZone.sensorElements"
                          class="sensor">
                          <ls-svg-icon
                            [color]="ColorsEnum.BlueDefault"
                            [fullHeight]="true"
                            [path]="'heating/temp'"
                            class="mb-5"></ls-svg-icon>
                          <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                        </div>
                      </ng-template>
                    </div>
                    <div class="table-cell justify-start padding-top-10 flex-wrap">
                      <div
                        *ngFor="let heater of brooding2heatingZone.heatingElements"
                        class="heater">
                        <ls-svg-icon
                          [color]="ColorsEnum.BlueDefault"
                          [fullHeight]="true"
                          [path]="'heating/heat'"
                          [overlayPath]="heater.elementType === ElementTypesEnum.HeatingDO ? 'warning' : null"
                          class="mb-5"></ls-svg-icon>
                        <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                      </div>
                    </div>
                  </div>
                </ng-template>
              </ng-container>

              <ng-container *ngIf="(brooding2ElementMappings$ | async).length > 0">
                <div
                  *ngFor="let brooding2ElementMapping of brooding2ElementMappings$ | async; first as first"
                  class="table-row">
                  <div
                    *ngIf="first && (brooding2HeatingZones$ | async).length === 0"
                    class="table-cell gray">
                    {{ "TemperatureMapping.Brooding2" | translate }}
                  </div>
                  <div
                    *ngIf="!first || (brooding2HeatingZones$ | async).length > 0"
                    class="table-cell gray empty"></div>
                  <div class="table-cell gray">
                    {{ brooding2ElementMapping.elementName }}
                  </div>
                  <div class="table-cell align-center padding-top-10">
                    <mat-checkbox
                      *ngIf="brooding2ElementMapping.average"
                      checked
                      class="checkbox checkbox-active-color pointer-events-none"></mat-checkbox>
                  </div>
                  <div class="table-cell align-center justify-start padding-top-10 flex-wrap">
                    <ng-container
                      *ngIf="brooding2ElementMapping.average; else sensors"
                      [ngTemplateOutlet]="averageSensors"></ng-container>

                    <ng-template #sensors>
                      <div
                        *ngFor="let sensor of brooding2ElementMapping.sensors"
                        class="sensor">
                        <ls-svg-icon
                          [color]="ColorsEnum.BlueDefault"
                          [fullHeight]="true"
                          [path]="'heating/temp'"
                          class="mb-5"></ls-svg-icon>
                        <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                      </div>
                    </ng-template>
                  </div>
                  <div class="table-cell justify-start padding-top-10"></div>
                </div>
              </ng-container>
            </ng-container>

            <!--GENERAL-->
            <div class="table-row">
              <div class="table-cell gray">{{ "TemperatureMapping.General" | translate }}</div>
              <div class="table-cell gray">{{ "TemperatureMapping.Inlet" | translate }}</div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
            </div>

            <div class="table-row">
              <div class="table-cell gray empty"></div>
              <div class="table-cell gray">{{ "TemperatureMapping.StirFan" | translate }}</div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
              <div class="table-cell"></div>
            </div>
          </div>
        </div>
      </ng-container>
    </div>
  </ng-container>
</section>

<ng-template
  #emptyRow
  let-title="title">
  <div class="table-row">
    <div class="table-cell">{{ title | translate }}</div>
    <div class="table-cell"></div>
    <div class="table-cell"></div>
    <div class="table-cell"></div>
    <div class="table-cell"></div>
  </div>
</ng-template>

<ng-template #editModeTemplate>
  <ls-temp-mapping-form
    (closeEditMode)="toggleEditMode()"
    [brooding1ElementMappings]="brooding1ElementMappings$ | async"
    [brooding1HasMappings]="brooding1HasMappings$ | async"
    [brooding1HeatingZones]="brooding1HeatingZones$ | async"
    [brooding2ElementMappings]="brooding2ElementMappings$ | async"
    [brooding2HasMappings]="brooding2HasMappings$ | async"
    [brooding2HeatingZones]="brooding2HeatingZones$ | async"
    [brooding2IsShown]="brooding2IsShown$ | async"
    [fullHouse]="fullHouse$ | async"
    [isDirtyForm]="isDirtyForm$ | async"
    [isLoading]="isLoading$ | async"></ls-temp-mapping-form>
</ng-template>

<ng-template #averageSensors>
  <div class="sensor sensor-all">
    <ls-svg-icon
      [color]="ColorsEnum.BlueDefault"
      [fullHeight]="true"
      [path]="'heating/temp'"
      class="mb-5"></ls-svg-icon>
    <span class="fz-12 color-tertiary">{{ "TemperatureMapping.All" | translate }}</span>
  </div>
</ng-template>

<ng-template #mobileTemplate>
  <ls-temp-mapping-mobile
    (closeEditMode)="toggleEditMode()"
    [brooding1ElementMappings]="brooding1ElementMappings$ | async"
    [brooding1HasMappings]="brooding1HasMappings$ | async"
    [brooding1HeatingZones]="brooding1HeatingZones$ | async"
    [brooding2ElementMappings]="brooding2ElementMappings$ | async"
    [brooding2HasMappings]="brooding2HasMappings$ | async"
    [brooding2HeatingZones]="brooding2HeatingZones$ | async"
    [brooding2IsShown]="brooding2IsShown$ | async"
    [editMode]="editMode"
    [fullHouse]="fullHouse$ | async"
    [isDirtyForm]="isDirtyForm$ | async"
    [isLoading]="isLoading$ | async"></ls-temp-mapping-mobile>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
