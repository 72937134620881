import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import {
  KeyboardComponent,
  KeyboardModeEnum,
  ButtonComponent,
  SvgIconComponent,
  PageWrapperComponent,
} from '@livestock/ui';
import {
  FlockSettingsFormComponent,
  IFlockSettingsView,
  clearFlockHouseMode,
  clearFlockSettings,
  getFlockSettings,
  selectFlockSettings,
  selectIsLoading,
  setFlockSettings,
  updateFlockSettings,
} from '@livestock/flock';
import { Subscription, Observable, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { AppRoutes } from '@livestock/shared/routes';
import { AlignmentEnum, DialogButtonEnum } from '@livestock/shared/enums';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-flock-settings',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    FlockSettingsFormComponent,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    PageWrapperComponent,
  ],
  templateUrl: './flock-settings.component.html',
  styleUrls: ['./flock-settings.component.scss'],
})
export class FlockSettingsComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  settings: IFlockSettingsView;
  originalSettings: IFlockSettingsView;
  isValid = true;
  isDirty = false;
  navigatingToNewFlock: boolean = false;
  editMode: boolean;
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  KeyboardModeEnum = KeyboardModeEnum;

  constructor(
    public platformService: PlatformService,
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    private router: Router,
    private dialogsService: DialogsService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectFlockSettings)
        .pipe(
          wasChanged(),
          filter(settings => settings != null && !!settings.controllerID),
        ).subscribe((settings) => {
        if (!this.originalSettings) {
          this.originalSettings = settings;
        }

        this.settings = settings;
        this.isDirty = this.areAnyChanges();
      }),
    );

    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        const controllerID = +params['controllerID'];
        this.store.dispatch(getFlockSettings({ controllerID }));
      }),
    );
  }

  changedSettings(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    if (event.isValid) {
      this.store.dispatch(setFlockSettings({ settings: event.formValues }));
    }
  }

  areAnyChanges(): boolean {
    return JSON.stringify(this.originalSettings) !== JSON.stringify(this.settings);
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.areAnyChanges()) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.store.dispatch(setFlockSettings({ settings: this.originalSettings }));
    return true;
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) this.editMode = false;
  }

  update(): void {
    if (!this.isValid) {
      return;
    }

    this.originalSettings = this.settings;
    this.store.dispatch(updateFlockSettings());
    this.isDirty = false;
  }

  async goBack(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.menuService.toggleDashboardMenu(true);
    }
  }

  async newFlock(): Promise<void> {
    const message = 'Flock.NewFlock.CreateNewFlockMessage';
    const title = 'Flock.NewFlock.CreateNewFlock';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.CONTINUE],
      null, true, true, AlignmentEnum.START, AlignmentEnum.END,
    );

    if (result === DialogButtonEnum.CONTINUE) {
      this.originalSettings = this.settings;
      this.navigatingToNewFlock = true;
      this.router.navigateByUrl(AppRoutes.NEW_FLOCK);
    }
  }

  toggleEditMode(): void {
    this.editMode = !this.editMode;
  }

  canDeactivate(): boolean {
    return !this.areAnyChanges();
  }

  closeComponent(): void {
    if (!this.navigatingToNewFlock) {
      this.store.dispatch(clearFlockSettings());
      this.store.dispatch(clearFlockHouseMode());
    }
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
