import { IHeatingProgramZoneElementView } from '@livestock/heating-program/models';

export interface IHeatingProgramZoneView {
    zoneID: number;
    controllerID: number;
    name: string;
    isAverage: boolean;
    isDoElements: boolean;
    heatingElements: IHeatingProgramZoneElementView[];
    sensorElements: IHeatingProgramZoneElementView[];
}