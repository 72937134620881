<ls-button-with-icon
  *ngIf="!platformService.isMobileApp && !isLinkView"
  (click)="addDevice()"
  [buttonType]="buttonType"
  [iconPath]="'home/farms/devices/device-light'"
  [marginAuto]="marginAuto"
  [qaTags]="'qa-btn-add-device'"
  [textContent]="'Farms.FarmPage.AddDevice'"
  class="fz-20"></ls-button-with-icon>
<div
  *ngIf="platformService.isMobileApp && !isLinkView"
  class="add-device-mobile">
  <ls-svg-icon
    (click)="addDevice()"
    [path]="'home/farms/devices/add-device'"
    [qaTags]="'qa-btn-add-device'"
    class="icon pointer">
  </ls-svg-icon>
</div>
<a
  *ngIf="isLinkView"
  (click)="addDevice()"
  [qaTags]="'qa-btn-add-device'"
  class="add-device-link">
  {{ "Farms.FarmPage.AddDevice" | translate }}
</a>
