<section class="page-content">
  <ng-container *ngIf="!(isLoading$ | async); else loading">
    <ls-header-title
      (cancel)="cancel()"
      [title]="'Controller.CloudSettings.SettingCloud'"></ls-header-title>

    <!--
    STATE 1: CONTROLLER IS CONNECTED TO FARM + DISCONNECT BUTTON VISIBLE
    STATE 2: CONTROLLER IS DISCONNECTED + RECONNECT BUTTONS VISIBLE
    STATE 3: CONTROLLER IS IN RECONNECTION PROCESS + QR CODE VISIBLE + NO BUTTONS VISIBLE
    -->

    <div
      *ngIf="currentController$ | async as currentController"
      class="container">
      <div
        *ngIf="networkSettings$ | async as networkSettings"
        class="d-flex flex-column justify-center">
        <div
          class="connection-status d-flex justify-between align-start mx-auto mb-70 mb-15-md fz-20 fz-18-md color-secondary">
          <!--ID icon + ID-->
          <div class="connection-status__id d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ls-svg-icon [path]="'quick-start/network/device-id'"></ls-svg-icon>
            </div>
            <p class="word-break w-200 text-center">
              {{ "Controller.NetworkSettings.ID" | translate }}
              <span class="fw-500 color-primary">{{ currentController.sn }}</span>
            </p>
          </div>

          <!--Type of connection with bold/gray line to farm if connected/disconnected-->
          <div
            [class.connection-status__network-connected]="
              !(isDisconnected$ | async) && (networkSettings.modem || networkSettings.wifi || networkSettings.lan)
            "
            class="connection-status__network d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ng-container *ngIf="!(isDisconnected$ | async)">
                <ng-container *ngIf="networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/cellular-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>

                <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/wifi-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>

                <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
                  <ls-svg-icon
                    [path]="
                      'quick-start/network/cellular-' + ((isDisconnected$ | async) ? 'error' : 'success')
                    "></ls-svg-icon>
                </ng-container>
              </ng-container>

              <ls-svg-icon
                *ngIf="
                  (isDisconnected$ | async) || (!networkSettings.modem && !networkSettings.wifi && !networkSettings.lan)
                "
                [path]="'quick-start/network/no-network'"></ls-svg-icon>
            </div>

            <ng-container *ngIf="!(isDisconnected$ | async)">
              <ng-container *ngIf="networkSettings.lan">
                <span>{{ networkSettings.lanIp }}</span>
              </ng-container>

              <ng-container *ngIf="networkSettings.wifi && !networkSettings.lan">
                <span>{{ networkSettings.wifiName }}</span>
              </ng-container>

              <ng-container *ngIf="networkSettings.modem && !networkSettings.wifi && !networkSettings.lan">
                <span>{{ networkSettings.modemAPN }}</span>
              </ng-container>
            </ng-container>

            <ng-container
              *ngIf="
                (isDisconnected$ | async) || (!networkSettings.modem && !networkSettings.wifi && !networkSettings.lan)
              ">
              <span>{{ "Controller.CloudSettings.NoConnection" | translate }}</span>
            </ng-container>
          </div>

          <!--farm name or 'no farm' if not connected-->
          <div class="connection-status__farm d-flex flex-column justify-center align-center">
            <div class="connection-status-icon position-relative mb-10">
              <ls-svg-icon
                [path]="'quick-start/network/' + ((isDisconnected$ | async) ? 'no-farm' : 'farm')"></ls-svg-icon>
            </div>

            <span class="word-break w-200 text-center">
              {{ currentController.farmName || "test" }}
            </span>
          </div>
        </div>

        <div class="d-flex flex-column justify-center align-center w-350">
          <ng-container *ngIf="!(ticketView$ | async)">
            <!--STATE 1: CONTROLLER IS CONNECTED TO FARM + DISCONNECT BUTTON VISIBLE-->
            <ng-container *ngIf="!(isDisconnected$ | async); else reconnectBtn">
              <div class="fz-20 color-secondary connected-by-info mb-35 mx-auto text-center">
                {{ "Controller.CloudSettings.ThisDeviceWasConnectedBy" | translate }}
                <span class="fw-500 color-primary">{{ currentController.connectedByUserEmail || "test" }}</span>
                {{ "Controller.CloudSettings.To" | translate }}
                <span class="fw-500 color-primary">{{ currentController.farmName || "test" }}</span>
              </div>

              <ls-button
                (click)="disconnectController()"
                class="mr-20 ml-20"
                type="secondary">
                {{ "Controller.CloudSettings.Disconnect" | translate }}
              </ls-button>
            </ng-container>

            <!--STATE 2: CONTROLLER IS DISCONNECTED + RECONNECT BUTTONS VISIBLE-->
            <ng-template #reconnectBtn>
              <div class="fz-20 color-secondary connected-by-info mb-35 mx-auto text-center">
                {{ "Controller.CloudSettings.ThisDeviceWasDisconnected" | translate }}
              </div>

              <ls-button
                (click)="reconnectController()"
                class="mr-20 ml-20"
                type="secondary">
                {{ "Controller.CloudSettings.Reconnect" | translate }}
              </ls-button>
            </ng-template>
          </ng-container>

          <!--STATE 3: CONTROLLER IS IN RECONNECTION PROCESS + QR CODE VISIBLE + NO BUTTONS VISIBLE-->
          <ng-container *ngIf="ticketView$ | async as ticketView">
            <div class="fz-20 cloud-info">
              <span class="cloud-info__scan mt-30 text-center">{{
                "Controller.CloudSettings.ScanTheQRCodeMessage" | translate
              }}</span>
              <span class="cloud-info__device-number mt-25 mb-15 color-secondary">
                {{ "Controller.CloudSettings.DeviceConnectionNumber" | translate }}
                {{ ticketView.connectionNumber }}
              </span>

              <qrcode
                [colorLight]="'#ebeff5'"
                [errorCorrectionLevel]="'M'"
                [margin]="0"
                [qaTags]="'qa-img-QR'"
                [qrdata]="
                  GlobalConstants.getCreateControllerUrl(ticketView.ticketID, ticketView.connectionNumber, true)
                "
                [width]="160" />
            </div>
          </ng-container>
        </div>
      </div>

      <div class="d-flex justify-end mt-30">
        <ls-button
          (click)="cancel()"
          [qaTags]="'qa-btn-cancel'"
          [type]="'secondary'">
          {{ "Buttons.Cancel" | translate }}
        </ls-button>
      </div>
    </div>
  </ng-container>
</section>

<ng-template #loading>
  <ls-loading />
</ng-template>
