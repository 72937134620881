import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ControllerRoutes } from '@livestock/shared/routes';
import { HeaderTitleComponent } from '@livestock/ui';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Observable, Subscription, combineLatest, filter } from 'rxjs';
import { NgChartsModule } from 'ng2-charts';
import { ChartDataset, ChartOptions } from 'chart.js';
import { Store } from '@ngrx/store';
import { selectCurrentControllerWeightUnit } from '@livestock/controllers';
import { WeightUnitEnum } from '@livestock/shared/enums';
import {
  selectDefaultWeightItems,
  selectFlockBirdWeightItems,
  selectSeparateMaleAndFemale,
} from '../../+state/flock.selectors';
import { getDefaultWeight, getFlockBirdWeight } from '../../+state/flock.actions';
import { ConvertHelper } from '@livestock/shared/utils';

@Component({
  selector: 'ls-bird-weight-graph',
  templateUrl: './bird-weight-graph.component.html',
  styleUrls: ['./bird-weight-graph.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HeaderTitleComponent,
    NgChartsModule,
  ],
})
export class BirdWeightGraphComponent implements OnInit, OnDestroy {
  sub$: Subscription = new Subscription();
  controllerID: number;
  weightUnit$: Observable<WeightUnitEnum> = this.store.select(selectCurrentControllerWeightUnit);
  isSeparateMode: boolean;
  unit: WeightUnitEnum;

  barChartOptions: ChartOptions = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      tooltip: {
        enabled: true,
        callbacks: {
          title: (tooltipItems) => {
            return tooltipItems[0].label + ` ${this.translate.instant('Flock.BirdWeight.Day')}`;
          },
          label: (tooltipItems) => {
            return tooltipItems.formattedValue + ` ${this.translate.instant(`${WeightUnitEnum.toTranslateKey(this.unit)}`)}`;
          },
        },
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          font: {
            family: 'Roboto',
            size: 16,
          },
          display: true,
          text: `${this.translate.instant('Flock.BirdWeight.Days')}`,
          padding: {
            top: 15,
          },
        },
        ticks: {
          font: {
            family: 'Roboto',
            size: 16,
          },
        },
      },
      y: {
        display: true,
        title: {
          font: {
            family: 'Roboto',
            size: 16,
          },
          display: true,
          text: `${this.translate.instant('Flock.BirdWeight.Weight')}`,
          padding: {
            bottom: 15,
          },
        },
        ticks: {
          font: {
            family: 'Roboto',
            size: 16,
          },
        },
      },
    },
  };
  barChartLabels: number[];
  barChartLegend = false;
  barChartPlugins = [];
  barChartData: ChartDataset[] = [];

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private translate: TranslateService,
    private store: Store,
  ) {
  }

  ngOnInit(): void {
    this.store.dispatch(getDefaultWeight({}));

    this.sub$.add(
      this.activatedRoute.params.subscribe(params => {
        this.controllerID = +params['controllerID'];
        this.store.dispatch(getFlockBirdWeight({ controllerID: this.controllerID }));
      }),
    );

    this.sub$.add(
      combineLatest([
        this.weightUnit$,
        this.store.select(selectSeparateMaleAndFemale),
        this.store.select(selectDefaultWeightItems),
        this.store.select(selectFlockBirdWeightItems),
      ]).pipe(
        // eslint-disable-next-line
        filter(([unit, separateMode, defaultIndicators, currentIndicators]) => defaultIndicators != null && currentIndicators != null),
      ).subscribe(([unit, separateMode, defaultIndicators, currentIndicators]) => {
        this.barChartLabels = defaultIndicators.map(el => el.day);
        this.unit = unit;
        this.isSeparateMode = separateMode;
        const defaultLineColor = '#DA2700';
        const secondLineColor = '#6E2BFC';
        const thirdLineColor = '#2C73A6';
        let maleWeight;
        let femaleWeight;
        let commonWeight;
        if (separateMode) {
          maleWeight = currentIndicators.map(el => {
            return { y: el.maleWeight, x: el.day };
          });
          femaleWeight = currentIndicators.map(el => {
            return { y: el.femaleWeight, x: el.day };
          });
        } else {
          commonWeight = currentIndicators.map(el => {
            return { y: el.weight, x: el.day };
          });
        }
        const defaultWeight = defaultIndicators.map(el => {
          if (unit === WeightUnitEnum.Pound) {
            return { y: ConvertHelper.gramToPound(el.weight, 2), x: el.day };
          } else if (unit === WeightUnitEnum.Kilogram) {
            return { y: ConvertHelper.gramToKilogram(el.weight, 2), x: el.day };
          } else {
            return { y: el.weight, x: el.day };
          }
        });
        this.barChartData[0] = {
          data: defaultWeight,
          borderColor: defaultLineColor,
          pointBackgroundColor: defaultLineColor,
        }; // default weight line
        this.barChartData[1] = {
          data: separateMode ? maleWeight : commonWeight,
          borderColor: secondLineColor,
          pointBackgroundColor: secondLineColor,
        }; // common weight line or male
        this.barChartData[2] = {
          data: separateMode ? femaleWeight : null,
          borderColor: thirdLineColor,
          pointBackgroundColor: thirdLineColor,
        };
      }),
    );
  }

  back(): void {
    this.router.navigate([`/controller/${this.controllerID}/${ControllerRoutes.FlockBirdWeight}`]);
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
