import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Router } from '@angular/router';
import { AppRoutes } from '../routes';

@Injectable({
  providedIn: 'root',
})
export class MenuService {
  private _menuOpened$ = new BehaviorSubject(false);
  private _sideMenuOpened$ = new BehaviorSubject(null);
  private _dashBoardMenuOpened$ = new BehaviorSubject(false);
  menuOpened$ = this._menuOpened$.asObservable();
  sideMenuOpened$ = this._sideMenuOpened$.asObservable();
  dashBoardMenuOpened$ = this._dashBoardMenuOpened$.asObservable();

  constructor(
    private router: Router,
  ) {
  }

  toggleMenu(value?: boolean): void {
    this._menuOpened$.next(value ?? !this._menuOpened$.value);
  }

  toggleSideMenu(value?: boolean): void {
    this._sideMenuOpened$.next(value ?? !this._sideMenuOpened$.value);
  }

  async toggleDashboardMenu(value?: boolean): Promise<void> {
    const newValue = value ?? !this._dashBoardMenuOpened$.value;
    if (newValue) {
      const res = await this.router.navigate([AppRoutes.DASHBOARD]);
      if (res === false) {
        return;
      }
    }
    ;
    this._dashBoardMenuOpened$.next(newValue);
  }

  clear(): void {
    this._sideMenuOpened$.next(null);
    this._dashBoardMenuOpened$.next(false);
    this._menuOpened$.next(false);
  }
}
