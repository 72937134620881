import { Injectable } from '@angular/core';
import {
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse,
} from '@angular/common/http';
import { ErrorService } from '@livestock/shared/services';
import { Store } from '@ngrx/store';
import { clearAllIfUnAuthorised } from '@livestock/auth';
import { catchError, Observable, throwError } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';
import {
  FlashMessageTypeEnum,
  setFlashMessage,
} from '@livestock/notifications';
import { Router } from '@angular/router';
import { GlobalConstants } from '../constants';
import { PlatformService } from '../services';

@Injectable()
export class HttpErrorsInterceptor implements HttpInterceptor {
  constructor(
    private errorService: ErrorService,
    private store: Store,
    private router: Router,
    private platformService: PlatformService,
  ) {
  }

  intercept(
    req: HttpRequest<any>,
    next: HttpHandler,
  ): Observable<HttpEvent<any>> {
    req.headers.append('handleError', 'onService');
    const started = Date.now();
    let ok: string;
    let event1: any = undefined;

    return next.handle(req).pipe(
      tap(
        (event) => {
          ok = event instanceof HttpResponse ? 'succeeded' : '';
          event1 = event;
        },

        (error) => {
          ok = 'failed';
          event1 = error;
        },
      ),
      map((event: HttpEvent<any>) => event),
      catchError((error) => {
        const { error: errorMessage, status } = error;

        //handling 404 errors
        if (status === 404 && !errorMessage) {
          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Error,
                message: 'FlashMessages.404Error',
              },
            }),
          );
        }

        //Do not handle get svg errors
        if (
          !error.url.includes('.svg')
          && errorMessage
          && !GlobalConstants.nonHandlingHttpErrors.some(error => errorMessage?.toString()?.includes(error))
        ) {
          if (this.platformService.isDeviceApp && error.statusText === 'Unknown Error') {
            return null;
          }

          this.store.dispatch(
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Error,

                //TODO: hardcoded for now, should be refactored in future
                //handling no internet connection error
                message:
                  error.statusText === 'Unknown Error'
                    ? 'FlashMessages.NoInternetConnectionFoundCheckYourConnectionAndTryAgain'
                    : `FlashMessages.${errorMessage}`.trim(),
              },
            }),
          );
        }

        return throwError(() => new HttpErrorResponse({ error: error.error }));
      }),
      finalize(() => {
        const elapsed = Date.now() - started;
        const msg = `${req.method} "${req.urlWithParams}" ${ok} in ${elapsed} ms.`;
        this.errorService.errorSendMessage.next({
          msg,
          error: event1,
        });

        if (event1?.status === 401) {
          this.store.dispatch(clearAllIfUnAuthorised());
          this.router.navigate([GlobalConstants.authLoginPage]);
        }
      }),
    );
  }
}
