import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Inject,
  OnDestroy,
  Output,
  ViewChild,
  ViewContainerRef,
  ComponentRef,
} from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ElementCustomActionEvent } from '../../../interfaces/custom-action-event';
import { ElementWrapperHelper } from '../helpers/element-wrapper.helper';
import { ElementTypesEnum } from '@livestock/shared/enums';

@Component({
  selector: 'ls-element-testing-dialog',
  templateUrl: './element-testing-dialog.component.html',
  styleUrls: ['./element-testing-dialog.component.scss'],
})
export class ElementTestingDialogComponent implements AfterViewInit, OnDestroy {
  @ViewChild('injectionContainer', { read: ViewContainerRef }) injectionContainer: ViewContainerRef;
  @Output() toggleBtn: EventEmitter<boolean> = new EventEmitter();
  @Output() onCustomAction: EventEmitter<ElementCustomActionEvent> = new EventEmitter();

  injectedComponent: ComponentRef<any>;
  testHasStarted: boolean;
  currentElementType: ElementTypesEnum;

  ElementTypesEnum = ElementTypesEnum;
  Helper = ElementWrapperHelper;

  constructor(
    public dialogRef: MatDialogRef<ElementTestingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { component: any; currentElementType: ElementTypesEnum },
    private cdr: ChangeDetectorRef,
  ) {
    this.currentElementType = data.currentElementType;
  }

  ngAfterViewInit(): void {
    this.injectedComponent = this.injectionContainer.createComponent(this.data.component);
    this.injectedComponent.changeDetectorRef.detectChanges();
    this.cdr.detectChanges();
  }

  ngOnDestroy(): void {
    if (this.injectedComponent) {
      this.injectedComponent.changeDetectorRef.detach();
      this.injectedComponent.destroy();
    }
  }

  closePopup(): void {
    this.dialogRef.close();
  }

  toggleTesting(): void {
    this.testHasStarted = !this.testHasStarted;
    this.toggleBtn.emit(this.testHasStarted);
  }

  handleCustomAction(action: ElementCustomActionEvent): void {
    const form = this.injectedComponent.instance.form;
    this.onCustomAction.emit({form, action: action.action});
  }
}
