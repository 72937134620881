<div class="time-picker d-flex align-center justify-between">
  <swiper
    (activeIndexChange)="onIndexChange($event, HourMinuteMeridiemEnum.Hour)"
    [slidesPerView]="3"
    [initialSlide]="hours | findElementIndex : hourValue"
    [direction]="'vertical'"
    [watchSlidesProgress]="true"
    [loop]="true"
    [loopFillGroupWithBlank]="true"
    [centeredSlides]="true"
    [slideNextClass]="'time-picker__slide-next'"
    [slidePrevClass]="'time-picker__slide-prev'"
    class="time-picker__value-container d-flex align-center flex-column">
    <ng-template
      *ngFor="let hour of hours"
      swiperSlide>
      <div class="time-picker__value">{{ hour | number : "2.0" }}</div>
    </ng-template>
  </swiper>
  <span>:</span>
  <swiper
    (activeIndexChange)="onIndexChange($event, HourMinuteMeridiemEnum.Minute)"
    [slidesPerView]="3"
    [initialSlide]="minutes | findElementIndex : minuteValue"
    [direction]="'vertical'"
    [watchSlidesProgress]="true"
    [loopFillGroupWithBlank]="true"
    [loop]="true"
    [centeredSlides]="true"
    [slideNextClass]="'time-picker__slide-next'"
    [slidePrevClass]="'time-picker__slide-prev'"
    class="time-picker__value-container d-flex align-center flex-column">
    <ng-template
      *ngFor="let minute of minutes"
      swiperSlide>
      <div class="time-picker__value">{{ minute | number : "2.0" }}</div>
    </ng-template>
  </swiper>
  <swiper
    *ngIf="enableMeridian"
    (activeIndexChange)="onIndexChange($event, HourMinuteMeridiemEnum.Meridiem)"
    [slidesPerView]="3"
    [initialSlide]="meridiem | findElementIndex : meridiemValue"
    [direction]="'vertical'"
    [watchSlidesProgress]="true"
    [centeredSlides]="true"
    [slideNextClass]="'time-picker__slide-next'"
    [slidePrevClass]="'time-picker__slide-prev'"
    class="time-picker__value-container d-flex align-center flex-column">
    <ng-template
      *ngFor="let val of meridiem"
      swiperSlide>
      <div class="time-picker__value">{{ val }}</div>
    </ng-template>
  </swiper>
</div>
