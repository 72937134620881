import { NgOption, NgSelectComponent } from '@ng-select/ng-select';
import { Subscription, fromEvent } from 'rxjs';
import { AfterViewInit, Directive, ElementRef, Host, Input, OnDestroy, Optional, Renderer2, Self } from '@angular/core';

import { DialogsService, PlatformService } from '@livestock/shared/services';
import { ISelectDialogOptions, SelectDialogCssClass } from '@livestock/shared/interfaces';

@Directive({
  standalone: true,
  selector: 'ng-select',
})
export class SelectDirective implements AfterViewInit, OnDestroy {
  @Input() selectTitle?: string;

  private hostClick$: Subscription;

  constructor(
    @Host() @Self() @Optional() private hostSelectComponent: NgSelectComponent,
    private platformService: PlatformService,
    private renderer: Renderer2,
    private element: ElementRef,
    private dialogService: DialogsService,
  ) {}

  ngAfterViewInit(): void {
    if (this.platformService.isMobileApp) {
      // first child is the div container. Disabling its click events to catch them on the parent itself - <ng-select>.
      const selectContainer = this.element.nativeElement.querySelector('.ng-select-container');
      if (selectContainer) {
        this.renderer.setStyle(selectContainer, 'pointer-events', 'none');

          this.hostClick$ = fromEvent(this.element.nativeElement, 'click').subscribe((event) => {
            this.onSelectItemClick(event as MouseEvent, this.hostSelectComponent?.itemsList?.items);
          });

      } else {
        console.warn(
          `${SelectDirective.name}: Cannot initialize dialog selector on <ng-select/> component. \n Check that <ng-select/> is properly rendered & that it has a child with ".ng-select-container" class.`,
        );
      }
    }
  }

  private onSelectItemClick(event: MouseEvent, items: NgOption[]): void {
    event.stopImmediatePropagation();
    event.preventDefault();

    if (items && !this.hostSelectComponent?.disabled) {
      this.openSelectionDialog(items, this.selectTitle);
    }
  }

  private async openSelectionDialog(items: NgOption[], title: string): Promise<void> {
      const options: ISelectDialogOptions = {
        list: items.map(item => {
          return {
            id: item,
            name: item.label,
            disabled: item.disabled,
          };
        }),
        value: items.find(item => item.selected),
        translateFieldTitle: false,
      };
      const selectedOption = await this.dialogService.select(options, title, [], 'popup-regular__mobile', SelectDialogCssClass.MOBILE);
      if (selectedOption?.value != null) {
        this.hostSelectComponent.select(selectedOption.value);
      }
  }

  ngOnDestroy(): void {
    this.hostClick$?.unsubscribe();
  }
}
