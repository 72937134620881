<ng-container *ngIf="parentFormGroup.get(formGroupName)">
  <div [formGroupName]="formGroupName">
    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-cooling-days'">{{ "Installation.Card.Cooling.MinDayForCooling" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.coolingSettings.minDay.max"
          [min]="ElementsValidationConstants.coolingSettings.minDay.min"
          [qaTags]="'qa-txt-cooling-days'"
          errorField
          formControlName="minDay"></ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.Cooling.Days" | translate }}</span>
      </div>
    </div>

    <div class="settings-field form-field mb-20">
      <label [qaTags]="'qa-lbl-humidity-level'">{{
        "Installation.Card.Cooling.HumidityLevelToRestartCooling" | translate
      }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.coolingSettings.humLevelToRestart.max"
          [min]="ElementsValidationConstants.coolingSettings.humLevelToRestart.min"
          [qaTags]="'qa-txt-humidity-level'"
          errorField
          formControlName="humLevelToRestart"></ls-input-integer>
        <span class="unit ml-10">%</span>
      </div>
    </div>

    <div class="settings-field form-field">
      <label [qaTags]="'qa-lbl-cooling-pad'">{{ "Installation.Card.Cooling.TimeToWetCoolingPad" | translate }}</label>

      <div class="d-flex align-center flex-wrap">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [forceDisabled]="!isEditMode"
          [max]="ElementsValidationConstants.coolingSettings.timeToWetPad.max"
          [min]="ElementsValidationConstants.coolingSettings.timeToWetPad.min"
          [qaTags]="'qa-lbl-cooling-pad'"
          errorField
          formControlName="timeToWetPad"></ls-input-integer>
        <span class="unit ml-10">{{ "Installation.Card.Cooling.Sec" | translate }}</span>
      </div>
    </div>
  </div>
</ng-container>
