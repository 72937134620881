<div
  *ngIf="items?.length"
  [class.align-center]="editMode"
  [class.align-start]="!editMode"
  class="buttons-container d-flex justify-start">
  <div *ngFor="let item of items; index as index">
    <div
      *ngIf="item.get('elementID')?.value"
      (click)="setActiveIndex(index, item.get('elementType')?.value)"
      [class.buttons-container__content-active]="
        index === activeIndex && item.get('elementType')?.value === ElementTypesEnum.VentilationAO
      "
      [class.buttons-container__content-editable]="editMode"
      [class.buttons-container__content-max-size]="
        item.value.elementType === ElementTypesEnum.VentilationAO && editMode
      "
      [formGroup]="item">
      <div
        [class.border-none]="editMode"
        class="buttons-container__button d-flex align-center justify-center flex-column">
        <ls-svg-icon
          [class.icon__square]="item.value.elementType === ElementTypesEnum.VentilationDO"
          [color]="ColorsEnum.BlueDefault"
          [path]="path"
          [style.width]="editMode ? width + 'px' : 'auto'">
        </ls-svg-icon>
        <br />
        <p class="fz-12">{{ item.value.number }}</p>
      </div>
      <div
        *ngIf="item.value.elementType === ElementTypesEnum.VentilationAO"
        [class.border]="index === activeIndex"
        class="buttons-container__value d-flex align-center justify-center">
        <div
          *ngIf="!editMode"
          class="value">
          {{ item.value.percentage }} {{ unit }}
        </div>

        <ls-input-integer
          *ngIf="editMode"
          (change)="setPercentageValue(item.value)"
          (onFocusIn)="onFocusInEvent()"
          (onFocusOut)="onFocusOutEvent()"
          [class.border-none__no-editing-mode]="true"
          [extraPadding]="extraPadding"
          [fieldWithKeyboard]="true"
          [max]="max"
          [min]="min"
          [showTooltipOnHover]="!isMobileApp"
          class="align-center"
          errorField
          formControlName="percentage">
          <ng-container suffix>
            <div class="unit">{{ unit }}</div>
          </ng-container>
        </ls-input-integer>
      </div>
    </div>
  </div>
</div>
