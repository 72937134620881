<div
  *ngIf="!isLoading && form; else loading"
  [formGroup]="form"
  [class.show-mode]="!editMode"
  class="temperature-curve h-100-percent d-flex flex-column justify-between">
  <div class="temperature-curve__container">
    <div class="header d-flex flex-row">
      <div
        [ngClass]="{ 'm-unset': platformService.isMobileApp && editMode }"
        class="target-temperature d-flex justify-end mx-auto w-80-percent mb-20">
        {{ "TemperatureCurve.CurrentTargetTemp" | translate }}
        &nbsp;<span class="fw-700">23 {{ temperatureUnit | enum : "temperature-unit-short-enum" | translate }}</span>
      </div>
    </div>
    <div
      *ngIf="!platformService.isMobileApp; else mobileView"
      #table
      class="table equal-columns-4">
      <div class="table-row table-header h-60">
        <div class="table-cell blue">
          {{ "TemperatureCurve.Day" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "TemperatureCurve.Target" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "TemperatureCurve.LowTempForAlarm" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "TemperatureCurve.HighTempForAlarm" | translate }}
        </div>
      </div>

      <ng-container formArrayName="tempCurveItems">
        <ng-container
          *ngFor="let tempCurveForm of form.get('tempCurveItems')['controls']; let index = index; let isLast = last"
          [formGroup]="tempCurveForm">
          <div
            (click)="setActiveIndex(index)"
            [class.active]="activeIndex === index && editMode"
            [class.last-row]="isLast && activeIndex !== index"
            class="table-row h-60 padding-top-11">
            <div class="table-cell">
              <ls-input-integer
                (change)="checkDuplicacy()"
                [fieldWithKeyboard]="true"
                [max]="MAX_DAY"
                [min]="MIN_DAY"
                [qaTags]="'qa-txt-day'"
                [showTooltipOnHover]="true"
                class="align-center input-integer"
                errorField
                formControlName="day">
              </ls-input-integer>
            </div>
            <div class="table-cell">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ tempCurveForm.value?.target }}
                <ng-container *ngTemplateOutlet="unit"> </ng-container>
              </div>
              <ls-input-decimal
                *ngIf="editMode"
                [accuracy]="accuracy"
                [fieldWithKeyboard]="true"
                [max]="MAX_VALUE"
                [min]="MIN_VALUE"
                [qaTags]="'qa-txt-target'"
                [showTooltipOnHover]="true"
                [validateWithInitValue]="true"
                class="align-center input-decimal"
                errorField
                formControlName="target">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
            <div class="table-cell">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ tempCurveForm.value?.lowTemperatureAlarm }}
                <ng-container *ngTemplateOutlet="unit"> </ng-container>
              </div>
              <ls-input-decimal
                *ngIf="editMode"
                [accuracy]="accuracy"
                [fieldWithKeyboard]="true"
                [max]="MAX_VALUE"
                [min]="MIN_VALUE"
                [qaTags]="'qa-txt-low-temp-alarm'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="lowTemperatureAlarm">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
            <div class="table-cell">
              <div
                *ngIf="!editMode"
                class="d-flex flex-row align-center">
                {{ tempCurveForm.value?.highTemperatureAlarm }}
                <ng-container *ngTemplateOutlet="unit"> </ng-container>
              </div>
              <ls-input-decimal
                *ngIf="editMode"
                [accuracy]="accuracy"
                [fieldWithKeyboard]="true"
                [max]="MAX_VALUE"
                [min]="MIN_VALUE"
                [qaTags]="'qa-txt-high-temp-alarm'"
                [showTooltipOnHover]="true"
                class="align-center input-decimal"
                errorField
                formControlName="highTemperatureAlarm">
                <ng-container
                  *ngTemplateOutlet="unit"
                  suffix>
                </ng-container>
              </ls-input-decimal>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>

    <ng-template #mobileView>
      <ng-container *ngIf="form?.get('tempCurveItems')['controls'][activeIndex] as tempCurveForm">
        <ng-container [formGroup]="form?.get('tempCurveItems')['controls'][activeIndex]">
          <div
            *ngIf="platformService.isMobileApp && editMode"
            class="d-flex flex-column mt-18 position-relative">
            <div class="mb-8">{{ "TemperatureCurve.Day" | translate }}</div>
            <div class="d-flex flex-row justify-between">
              <ls-input-integer
                (change)="checkDuplicacy()"
                [fieldWithKeyboard]="true"
                [max]="MAX_DAY"
                [min]="MIN_DAY"
                [qaTags]="'qa-txt-day'"
                class="align-center input-integer"
                errorField
                formControlName="day">
              </ls-input-integer>

              <ls-days-manager-mobile
                (addDay)="addEmptyRow()"
                (deleteDay)="removeRow()">
              </ls-days-manager-mobile>
            </div>
          </div>

          <div class="temperature-curve__days position-relative d-flex flex-row">
            <div
              *ngIf="!editMode"
              class="program-days mr-20">
              <div
                #programDays
                class="program-days__scrollbar">
                <div
                  *ngFor="let day of form.get('tempCurveItems')['controls']; index as i"
                  (click)="setActiveIndex(i)"
                  [class.program-day__active]="i === activeIndex"
                  class="program-day">
                  {{ day.value.day }}
                </div>
              </div>
            </div>
            <div class="table w-1-40 w-2-60">
              <div class="table-row">
                <div class="title table-cell blue">{{ "TemperatureCurve.Target" | translate }}</div>
                <div class="value table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ tempCurveForm.value?.target }}
                    <ng-container *ngTemplateOutlet="unit"> </ng-container>
                  </div>
                  <ls-input-decimal
                    *ngIf="editMode"
                    [accuracy]="accuracy"
                    [errTooltipControl]="tempCurveForm.controls['target']"
                    [fieldWithKeyboard]="true"
                    [max]="MAX_VALUE"
                    [min]="MIN_VALUE"
                    [qaTags]="'qa-txt-target'"
                    [validateWithInitValue]="true"
                    class="align-center input-decimal"
                    errorField
                    formControlName="target">
                    <ng-container
                      *ngTemplateOutlet="unit"
                      suffix>
                    </ng-container>
                  </ls-input-decimal>
                </div>
              </div>
              <div class="table-row">
                <div class="title table-cell blue">{{ "TemperatureCurve.LowTempForAlarm" | translate }}</div>
                <div class="value table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ tempCurveForm.value?.lowTemperatureAlarm }}
                    <ng-container *ngTemplateOutlet="unit"> </ng-container>
                  </div>
                  <ls-input-decimal
                    *ngIf="editMode"
                    [accuracy]="accuracy"
                    [errTooltipControl]="tempCurveForm.controls['lowTemperatureAlarm']"
                    [fieldWithKeyboard]="true"
                    [max]="MAX_VALUE"
                    [min]="MIN_VALUE"
                    [qaTags]="'qa-txt-low-temp-alarm'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="lowTemperatureAlarm">
                    <ng-container
                      *ngTemplateOutlet="unit"
                      suffix>
                    </ng-container>
                  </ls-input-decimal>
                </div>
              </div>
              <div class="table-row">
                <div class="title table-cell blue">{{ "TemperatureCurve.HighTempForAlarm" | translate }}</div>
                <div class="value table-cell">
                  <div
                    *ngIf="!editMode"
                    class="d-flex flex-row align-center">
                    {{ tempCurveForm.value?.highTemperatureAlarm }}
                    <ng-container *ngTemplateOutlet="unit"> </ng-container>
                  </div>
                  <ls-input-decimal
                    *ngIf="editMode"
                    [accuracy]="accuracy"
                    [errTooltipControl]="tempCurveForm.controls['highTemperatureAlarm']"
                    [fieldWithKeyboard]="true"
                    [max]="MAX_VALUE"
                    [min]="MIN_VALUE"
                    [qaTags]="'qa-txt-high-temp-alarm'"
                    class="align-center input-decimal"
                    errorField
                    formControlName="highTemperatureAlarm">
                    <ng-container
                      *ngTemplateOutlet="unit"
                      suffix>
                    </ng-container>
                  </ls-input-decimal>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </ng-template>
  </div>

  <div class="buttons-container d-flex flex-row justify-between flex-column-md">
    <div
      *ngIf="editMode && !platformService.isMobileApp"
      class="buttons__add-delete d-flex flex-row">
      <ng-container *ngTemplateOutlet="buttons"></ng-container>
    </div>
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #buttons>
  <div class="d-flex flex-row inner-buttons">
    <ng-container *ngIf="platformService.isMobileApp">
      <ls-svg-icon
        (click)="addEmptyRow()"
        [class.disabled]="form.invalid"
        [path]="'temperature-curve/add'"
        [qaTags]="'qa-btn-add-row'"
        class="pointer">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="removeRow()"
        [class.disabled]="activeIndex == null"
        [path]="'temperature-curve/delete'"
        [qaTags]="'qa-btn-remove-row'"
        class="pointer">
      </ls-svg-icon>
    </ng-container>
    <ng-container *ngIf="!platformService.isMobileApp">
      <ls-button
        (click)="addEmptyRow()"
        [class.disabled]="form.invalid"
        [qaTags]="'qa-btn-add-row'"
        [type]="'secondary'"
        class="ml-20">
        {{ "TemperatureCurve.AddDay" | translate }}
      </ls-button>
      <ls-button
        (click)="removeRow()"
        [class.disabled]="activeIndex === null"
        [qaTags]="'qa-btn-remove-row'"
        [type]="'secondary'"
        class="ml-20">
        {{ "TemperatureCurve.DeleteDay" | translate }}
      </ls-button>
    </ng-container>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>

<ng-template #unit>
  <div class="unit">
    {{ temperatureUnit | enum : "temperature-unit-short-enum" | translate }}
  </div>
</ng-template>
