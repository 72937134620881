import { inject } from '@angular/core';
import { ResolveFn } from '@angular/router';
import {
  getDefaultWeight,
  prepareNewFlock,
  selectDefaultWeightItems,
  selectFlockSettings,
  getFlockSettings,
} from '@livestock/flock';
import { Store, select } from '@ngrx/store';
import { getControllerInfo } from 'libs/controllers/src/lib/+state/current-controller/current-controller.actions';
import {
  selectActiveControllerID,
  selectCurrentControllerWeightUnit,
  selectCurrentControllerUnits, selectFlockIsDefault,
} from 'libs/controllers/src/lib/+state/current-controller/current-controller.selectors';
import { filter, firstValueFrom } from 'rxjs';

export const newFlockResolver: ResolveFn<boolean> =
  async () => {
    const store = inject(Store);
    store.dispatch(getDefaultWeight({}));
    const controllerID = await firstValueFrom(store.select(selectActiveControllerID));
    let weightUnit = await firstValueFrom(store.select(selectCurrentControllerWeightUnit));
    const flockSettings = await firstValueFrom(store.select(selectFlockSettings));
    if (weightUnit == null) {
      store.dispatch(getControllerInfo({ controllerID }));
    }

    if (flockSettings == null) {
      store.dispatch(getFlockSettings({ controllerID }));
    }

    if (weightUnit == null || flockSettings == null) {
      weightUnit = await firstValueFrom(store.select(selectCurrentControllerWeightUnit).pipe(
        filter(unit => unit != null), // <- waiting until data is present
      ));
      await firstValueFrom(store.select(selectFlockSettings).pipe(
        filter(settings => settings != null), // <- waiting until data is present
      ));
    }

    const { units, unitsDetails } = await firstValueFrom(store.select(selectCurrentControllerUnits));
    const isDefaultFlock = await firstValueFrom(store.select(selectFlockIsDefault));
    await firstValueFrom(store.pipe(
      select(selectDefaultWeightItems),
      filter(defaultWeightItems => defaultWeightItems.length > 0)));
    store.dispatch(prepareNewFlock({ controllerID, isDefaultFlock, units, unitsDetails, weightUnit }));
    return true;
  };
