import { createFeatureSelector, createSelector } from '@ngrx/store';
import { StaticPressureState } from './static-pressure.state';

export const STATIC_PRESSURE_KEY = 'static-pressure';

export const getStaticPressureState =
  createFeatureSelector<StaticPressureState>(STATIC_PRESSURE_KEY);

export const selectIsLoading = createSelector(
  getStaticPressureState,
  ({ isLoading }) => isLoading,
);

export const selectCouldBeOpened = createSelector(
  getStaticPressureState,
  ({ couldBeOpened }) => couldBeOpened,
);

export const selectStaticPressureView = createSelector(
  getStaticPressureState,
  ({ view }) => view,
);
