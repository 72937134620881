import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SvgIconComponent } from '@livestock/ui';
import { ColorsEnum } from '@livestock/shared/enums';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { IElement } from '@livestock/installation/interfaces';

@Component({
  selector: 'ls-icons-keyboard',
  templateUrl: 'icons-keyboard.component.html',
  styleUrls: ['./icons-keyboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
  ],
})
export class IconsKeyboardComponent {
  @Input() elementsDO: IElement[];
  @Input() elementsAO: IElement[];
  // VentilationDO - relay, VentilationAO - analog
  @Input() keyboardMode: ElementTypesEnum = ElementTypesEnum.VentilationDO;
  @Input() elements: any;
  @Input() title: string = 'Controls.Element.SelectFans';
  @Input() path: string;
  @Input() selectedIndex: number;
  @Input() isMobileApp: boolean = false;
  @Output() change = new EventEmitter();

  // constants
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;

  switchKeyboardMode(keyboardMode: ElementTypesEnum): void {
    this.keyboardMode = keyboardMode;
  }

  isElementChosen(elementID: number): boolean {
    return this.elements?.some(c => c.elementID === elementID);
  }

  selectElement(element: IElement): void {
    this.setElements(element);
    this.selectedIndex = null;
  }

  setElements(element: IElement): void {
    const objectExist = this.elements?.some(obj => obj.elementID === element.elementID);

    this.elements = objectExist
      ? this.elements?.filter(el => el.elementID !== element?.elementID)
      : [...this.elements, element];
  }

  addElements(): void {
    this.change.emit(this.elements);
  }
}
