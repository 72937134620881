import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  standalone: true,
  imports: [CommonModule, QaTagsDirective],
  selector: 'ls-inlet-widget',
  templateUrl: './inlet-widget.component.html',
  styleUrls: ['./inlet-widget.component.scss'],
})
export class InletWidgetComponent {
  columns: number[] = [1, 2, 3];
}
