<ng-container *ngIf="currStep === 1">
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate>
    <h1>{{ "Auth.CreateAnAccount" | translate }}</h1>
    <lv-input
      (keydown.enter)="onSendVerificationCode()"
      (inputFocus)="email['focused'] = true"
      (inputBlur)="email['focused'] = false"
      #email
      [class.ng-invalid]="validateForm && form.get('email').invalid"
      [label]="'Auth.EmailAddress'"
      [qaTags]="'qa-txt-email'"
      [autocomplete]="'nope'"
      formControlName="email"
      type="email"
      class="mt-18 d-block">
      <ls-svg-icon
        [path]="IconsEnum.LETTER"
        [color]="
          validateForm && form.get('email').invalid
            ? ColorsEnum.MainRed
            : !!email['focused']
            ? ColorsEnum.MainBlue
            : ColorsEnum.MonoDark
        "
        leftIcon>
      </ls-svg-icon>
    </lv-input>
    <span
      *ngIf="validateForm && form.get('email').dirty && form.hasError('required', 'email')"
      [qaTags]="'qa-txt-err-email'"
      class="validation-error">
      {{ "Auth.PleaseEnterAnEmail" | translate }}
    </span>
    <span
      *ngIf="validateForm && form.get('email').dirty && form.hasError('email', 'email')"
      [qaTags]="'qa-txt-err-email'"
      class="validation-error">
      {{ "Auth.PleaseEnterAValidEmailAddress" | translate }}
    </span>
  </form>

  <div class="btn-group">
    <ls-button
      (click)="onSendVerificationCode()"
      [class.disabled-with-events]="form.get('email').invalid || isLoading"
      [qaTags]="'qa-btn-enter'"
      class="h-48"
      type="primary">
      {{ "Auth.SignUp.Register" | translate }}
    </ls-button>
  </div>
  <div class="login">
    <span class="login_question">
      {{ "Auth.SignUp.HaveAnAccount" | translate }}
    </span>
    <div
      (click)="navigateTo(AppRoutes.LOGIN)"
      [qaTags]="'qa-lnk-login'"
      class="login-button text-center pointer mt-8">
      {{ "Auth.SignUp.Login" | translate }}
    </div>
  </div>
</ng-container>

<ng-container *ngIf="currStep === 2">
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate>
    <div
      [class.mt-112]="platformService.isMobileApp"
      class="form-field">
      <h1 [qaTags]="'qa-lbl-ver-prompt'">
        {{ "Auth.VerificationCodeSentTo" | translate }}
        <span class="field-value">{{ form.value.email }}</span>
      </h1>
    </div>
    <div class="form-field mt-28">
      <h2
        *ngIf="
          !form.controls.verificationCode.getError('wrong') && !form.controls.verificationCode.getError('expired')
        ">
        {{ "Auth.EnterVerificationCode" | translate }}
      </h2>
      <h2
        *ngIf="form.controls.verificationCode.getError('wrong')"
        class="error">
        {{ "Auth.YouEnteredWrongCode" | translate }}
      </h2>
      <h2
        *ngIf="form.controls.verificationCode.getError('expired')"
        class="error">
        {{ "Auth.YouEnteredExpiredCode" | translate }}
      </h2>
      <lv-verification-code
        (submitForm)="onVerificationCode()"
        [class.ng-invalid]="
          form.get('verificationCode').dirty &&
          (form.controls.verificationCode.getError('wrong') || form.controls.verificationCode.getError('expired'))
        "
        [qaTags]="'qa-txt-verification-code'"
        class="mt-18"
        formControlName="verificationCode">
      </lv-verification-code>
    </div>
  </form>

  <div class="btn-group">
    <div
      (click)="onVerificationCode()"
      [qaTags]="'qa-btn-enter qa-btn-verify-code'"
      [class.disabled]="
        form.controls.verificationCode?.value?.length < GlobalConstants.VERIFICATION_CODE_LENGTH ||
        isLoading ||
        form.controls.verificationCode.getError('wrong') ||
        form.controls.verificationCode.getError('expired')
      "
      class="verify-button h-48 pointer">
      {{ "Auth.VerifyCode" | translate }}
    </div>

    <ls-button
      (click)="onSendNewCode()"
      [class.disabled]="isLoading || isDisabledResendCode"
      [qaTags]="'qa-btn-send-new-code'"
      class="h-48"
      type="primary">
      {{ "Auth.ResendCode" | translate }}
    </ls-button>
  </div>
</ng-container>

<ng-container *ngIf="currStep === 3">
  <ng-container *ngIf="inviteWasSuccessful; else formTemplate">
    <div class="invite-success mt-35">
      <ls-svg-icon
        [fullHeight]="true"
        [hasMargin]="true"
        [path]="'flash/success'"
        class="m-auto mb-40"></ls-svg-icon>
      <div class="text-center mb-60">
        <h3 class="fz-24 fz-20-md color-primary mb-15">
          {{ "Auth.SignUp.SuccessfullySignedUp" | translate }}
        </h3>
        <div class="fz-18 fz-14-md color-primary">
          <p class="mb-15">
            {{ "Auth.SignUp.ThankYou" | translate }}
            <strong class="fw-500">{{ form.value.fullName + ", " }}</strong>
          </p>
          <p class="mb-15">
            {{ "Auth.SignUp.YouHaveBeenSuccessfullySignedUpTo" | translate }}
            <strong class="fw-500">
              {{ farmNameForInvitedUser }}
            </strong>
          </p>
          <p>{{ "Auth.SignUp.ToContinuePleaseLogin" | translate }}</p>
        </div>
      </div>

      <ls-button
        [routerLink]="'/auth/login'"
        class="h-48"
        type="primary">
        {{ "Auth.Login.Login" | translate }}
      </ls-button>
    </div>
  </ng-container>

  <ng-template #formTemplate>
    <form
      [formGroup]="form"
      autocomplete="off"
      novalidate>
      <h1 [class.mt-56]="platformService.isMobileApp">
        {{ "Auth.SignUp.FinishYourRegistration" | translate }}
      </h1>
      <lv-input
        (keydown.enter)="focusPhone()"
        (inputFocus)="fullName['focused'] = true"
        (inputBlur)="fullName['focused'] = false"
        #fullName
        [label]="'Auth.SignUp.FullName'"
        [qaTags]="'qa-txt-fn'"
        [autocomplete]="'nope'"
        formControlName="fullName"
        type="email"
        class="mt-28 d-block">
        <ls-svg-icon
          [path]="IconsEnum.USER"
          [color]="
            form.get('fullName').dirty && form.get('fullName').invalid
              ? ColorsEnum.MainRed
              : !!fullName['focused']
              ? ColorsEnum.MainBlue
              : ColorsEnum.MonoDark
          "
          leftIcon>
        </ls-svg-icon>
      </lv-input>
      <lv-input
        (keydown.enter)="focusPassword()"
        (inputFocus)="phone['focused'] = true"
        (inputBlur)="phone['focused'] = false"
        #phone
        [label]="'Auth.SignUp.PhoneNumber'"
        [labelFieldType]="'Auth.SignUp.Optional'"
        [qaTags]="'qa-txt-mb'"
        [autocomplete]="'nope'"
        formControlName="mobile"
        type="email"
        class="mt-18 d-block">
        <ls-svg-icon
          [path]="IconsEnum.PHONE"
          [color]="
            form.get('mobile').dirty && form.hasError('invalidPhoneNumber', 'mobile')
              ? ColorsEnum.MainRed
              : !!phone['focused']
              ? ColorsEnum.MainBlue
              : ColorsEnum.MonoDark
          "
          leftIcon>
        </ls-svg-icon>
      </lv-input>
      <span
        *ngIf="form.get('mobile').dirty && form.hasError('invalidPhoneNumber', 'mobile')"
        [qaTags]="'qa-lbl-error-mb'"
        class="validation-error">
        {{ "Auth.SignUp.FormatInvalidUsePlusAndNumbers" | translate }}
      </span>
      <h2
        [qaTags]="'qa-lbl-title'"
        class="mt-56">
        {{ "Auth.SignUp.ChooseYourPasswordWisely" | translate }}
      </h2>
      <span class="password-info mt-8">
        {{ "Auth.GoodPasswordMessage" | translate }}
      </span>
      <div class="mt-18 position-relative">
        <lv-input
          (inputFocus)="password['focused'] = true"
          (inputBlur)="password['focused'] = false"
          #password
          [label]="'Auth.Login.Password'"
          [qaTags]="'qa-txt-new-passwd'"
          [type]="'text'"
          [autocomplete]="'new-password'"
          formControlName="password"
          class="d-block">
          <ls-svg-icon
            [path]="IconsEnum.PASSWORD"
            [color]="
              form.get('password').dirty && form.get('password').invalid
                ? ColorsEnum.MainRed
                : !!password['focused']
                ? ColorsEnum.MainBlue
                : ColorsEnum.MonoDark
            "
            leftIcon>
          </ls-svg-icon>
          <div
            *ngIf="form.get('password').valid"
            (click)="copyPassword($event)"
            class="copy-button pointer"
            rightIcon>
            <ls-svg-icon
              [path]="IconsEnum.COPY"
              class="mb-3">
            </ls-svg-icon>
            <span>{{ "Auth.Copy" | translate }}</span>
          </div>
          <div
            (click)="generatePassword($event)"
            class="generate-button"
            rightButton>
            <ls-svg-icon
              [path]="IconsEnum.GENERATE"
              class="pointer mb-4">
            </ls-svg-icon>
            <span>{{ "Auth.Generate" | translate }}</span>
          </div>
        </lv-input>
        <div
          *ngIf="
            form.get('password').valid && form.get('password').value.length >= GlobalConstants.STRONG_PASSWORD_LENGTH
          "
          class="password-description strong-password">
          <div>
            <span>{{ "Auth.Strong" | translate }} </span>
            <span>{{ "Auth.PasswordGoodJob" | translate | lowercase }}</span>
          </div>
          <div class="password-visualization">
            <div class="green"></div>
            <div class="green ml-4 mr-4"></div>
            <div class="green"></div>
          </div>
        </div>
        <div
          *ngIf="
            form.get('password').valid && form.get('password').value.length < GlobalConstants.STRONG_PASSWORD_LENGTH
          "
          class="password-description good-password">
          <div>
            <span>{{ "Auth.Good" | translate }} </span>
            <span>{{ "Auth.PasswordGoodEnough" | translate | lowercase }}</span>
          </div>
          <div class="password-visualization">
            <div class="orange"></div>
            <div class="orange ml-4 mr-4"></div>
            <div class="gray"></div>
          </div>
        </div>
        <div
          *ngIf="form.get('password').dirty && form.hasError('insecurePassword', 'password')"
          class="password-description week-password">
          <span>{{ "Auth.WeekPasswordNotGoodEnough" | translate }}</span>
          <div class="password-visualization">
            <div class="red"></div>
            <div class="gray ml-4 mr-4"></div>
            <div class="gray"></div>
          </div>
        </div>
      </div>
    </form>

    <div class="btn-group">
      <ls-button
        (click)="onCreate()"
        [class.disabled]="form.invalid || isLoading"
        [qaTags]="'qa-btn-enter'"
        class="h-48"
        type="primary">
        {{ "Auth.FinishAndLogin" | translate }}
      </ls-button>
    </div>
  </ng-template>
</ng-container>
