import { Action, createReducer, on } from '@ngrx/store';
import * as farmActions from './farms.actions';
import { IUser } from '@livestock/shared/interfaces';
import { TicketMessages } from '../../../../shared/constants/tickets/ticket-messages';
import * as upsertControllerActions
  from '../../../../controllers/src/lib/+state/upsert-controller/upsert-controller.actions';
import { LocalStorageService } from '@livestock/shared/services';
import { IFarm } from '../interfaces/farm.interface';
import { StorageItem } from '@livestock/shared/enums';
import { IController } from '../interfaces/controller.interface';
import { UserStatusesEnum } from '../../../../shared/enums/users/user-statuses.enum';

export const FARMS_FEATURE_KEY = 'farms';

export interface FarmsState {
  isLoading: boolean;
  areUsersLoading: boolean;
  farms: IFarm[];
  users: IUser[];
  controllers: any[];
  activeFarm: IFarm | null;
  ticketMessage: string | null;
  farmNameForInvitedUser: string | null;
}

export interface FarmsPartialState {
  readonly [FARMS_FEATURE_KEY]: FarmsState;
}

export const initialState: FarmsState = {
  isLoading: false,
  areUsersLoading: false,
  farms: [],
  activeFarm: null,
  users: [],
  controllers: [],
  ticketMessage: null,
  farmNameForInvitedUser: null,
};

const reducer = createReducer(
  initialState,
  on(
    farmActions.getFarmById,
    farmActions.createFarm,
    farmActions.updateFarmById,
    farmActions.deleteFarmById,
    farmActions.getFarmControllers,
    farmActions.deleteControllerFromFarm,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),
  on(
    farmActions.getFarmsError,
    farmActions.getFarmByIdError,
    farmActions.createFarmError,
    farmActions.updateFarmByIdError,
    farmActions.deleteFarmByIdError,
    farmActions.getFarmControllersError,
    farmActions.deleteControllerFromFarmError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),
  on(
    farmActions.getFarms, (state) => {
      return {
        ...state,
        isLoading: !state.farms?.length,
      };
    },
  ),
  on(farmActions.getFarmsSuccess,
    (state, { farms }) => {
      return {
        ...state,
        farms: farms.map(farm => {
          return {
            ...farm,
            existingHouseNumbers: Array.from(new Set(farm.existingHouseNumbers)).sort((a, b) => a - b),
          };
        }),
        isLoading: false,
      };
    }),
  on(farmActions.getFarmByIdSuccess, (state, { farm }) => {
    LocalStorageService.setStorageItem(StorageItem.ActiveFarmID, farm?.farmID);
    return {
      ...state,
      activeFarm: farm,
      isLoading: false,
    };
  }),
  on(farmActions.createFarmSuccess, (state, { farm }) => {
    return {
      ...state,
      farms: [...state.farms, farm],
      activeFarm: farm,
      isLoading: false,
    };
  }),
  on(farmActions.updateFarmByIdSuccess, (state, { farmID, farm }) => {
    const farms = [...state.farms];
    const index = farms.findIndex(farm => farm.farmID === farmID);
    let updatedFarm = { ...state.activeFarm };

    if (index !== -1) {
      updatedFarm = {
        ...updatedFarm,
        ...farm,
      };
      farms[index] = {
        ...farms[index],
        name: updatedFarm.name,
        address: updatedFarm.address,
      };
    }

    return {
      ...state,
      farms,
      activeFarm: updatedFarm,
      isLoading: false,
    };
  }),
  on(farmActions.deleteFarmByIdSuccess, (state, { farmID }) => {
    const farms = [...state.farms].filter(farm => farm.farmID !== farmID);
    LocalStorageService.setStorageItem(StorageItem.ActiveFarmID, farms?.[0]?.farmID);

    return {
      ...state,
      farms,
      isLoading: false,
    };
  }),
  on(farmActions.deleteControllerFromFarmSuccess, (state, { controllerID }) => {
    return {
      ...state,
      activeFarm: {
        ...state.activeFarm,
        controllersCount: state.activeFarm.controllersCount - 1,
      },
      controllers: state.controllers.filter(controller => controller.controllerID !== controllerID),
      isLoading: false,
    };
  }),

  /*USERS*/
  on(
    farmActions.getFarmUsers,
    farmActions.updateUser,
    farmActions.deleteFarmUser,
    farmActions.inviteFarmUser,
    farmActions.resendInviteFarmUser,
    (state) => {
      return {
        ...state,
        areUsersLoading: true,
      };
    },
  ),
  on(
    farmActions.getFarmUsersError,
    farmActions.updateUserError,
    farmActions.deleteFarmUserError,
    farmActions.inviteFarmUserError,
    farmActions.resendInviteFarmUserSuccess,
    (state) => {
      return {
        ...state,
        areUsersLoading: false,
      };
    },
  ),
  on(farmActions.getFarmUsersSuccess, (state, { farmID, users }) => {
    const filteredUsers = [...state.users].filter(user => user.farmID !== farmID);
    const modifiedUsers = users.map((user, index) => {
      return {
        ...user,
        farmID,
        tableID: index + 1,
      };
    });

    return {
      ...state,
      users: [...filteredUsers, ...modifiedUsers],
      areUsersLoading: false,
    };
  }),
  on(farmActions.updateUserSuccess, (state, { user }) => {
    const users = [...state.users];
    const index = users.findIndex(u => user.userID === u.userID);
    if (index !== -1) {
      users[index] = {
        ...users[index],
        ...user,
      };
    }

    return {
      ...state,
      users,
      areUsersLoading: false,
    };
  }),
  on(farmActions.setActiveFarm, (state, { farmID }) => {
    return {
      ...state,
      activeFarm: state.farms.find(farm => farm.farmID === farmID),
    };
  }),
  on(farmActions.deleteFarmUserSuccess, (state, { farmID, email }) => {
    const users = state.users.filter(user => {
      return user.email !== email && user.farmID === farmID;
    });

    const farms = [...state.farms];
    const index = farms.findIndex(f => f.farmID === farmID);

    if (index !== -1) {
      farms[index] = {
        ...farms[index],
      };
    }

    return {
      ...state,
      users: users.map((user, index) => {
        return {
          ...user,
          tableID: index + 1,
        };
      }),
      farms,
      activeFarm: {
        ...state.activeFarm,
        usersCount: state.activeFarm?.usersCount - 1,
      },
      areUsersLoading: false,
    };
  }),
  on(farmActions.inviteFarmUserSuccess, (state, { farmID, user }) => {
    const users = [...state.users];
    const userIndex = users.findIndex(u => u.email === user.email && u.farmID === farmID);

    if (userIndex !== -1) {
      users[userIndex] = {
        ...users[userIndex],
        ...user,
      };

      return {
        ...state,
        users,
        activeFarm: {
          ...state.activeFarm,
          usersCount: state.activeFarm?.usersCount + 1,
        },
        areUsersLoading: false,
      };
    }

    const modifiedUser: IUser = {
      ...user,
      farmID,
      tableID: users.length + 1,
    };

    return {
      ...state,
      users: [...users, modifiedUser],
      activeFarm: {
        ...state.activeFarm,
        usersCount: state.activeFarm?.usersCount + 1,
      },
      areUsersLoading: false,
    };
  }),
  on(farmActions.resendInviteFarmUserSuccess, (state, { farmID, email, resendDate }) => {
    const users = [...state.users];
    const index = users.findIndex(u => u.email === email && u.farmID === farmID);

    if (index !== -1) {
      users[index] = {
        ...users[index],
        resendDate,
        status: UserStatusesEnum.Pending,
      };
    }

    return {
      ...state,
      users,
      areUsersLoading: false,
    };
  }),

  //tickets
  on(farmActions.removeTicketMessage, (state) => {
    return {
      ...state,
      ticketMessage: null,
    };
  }),
  on(
    farmActions.acceptExistUserToFarmSuccess,
    farmActions.executeAddNewUserToFarmSuccess,
    (state) => {
      return {
        ...state,
        ticketMessage: TicketMessages.AcceptSuccess,
      };
    }),
  on(
    farmActions.addNewUserToFarmSuccess,
    (state, { farmName }) => {
      return {
        ...state,
        ticketMessage: TicketMessages.AcceptSuccess,
        farmNameForInvitedUser: farmName,
      };
    }),
  on(
    farmActions.acceptExistUserToFarmError,
    farmActions.addNewUserToFarmError,
    (state) => {
      return {
        ...state,
        ticketMessage: TicketMessages.AcceptError,
      };
    }),
  on(farmActions.declineExistUserToFarmSuccess, (state) => {
    return {
      ...state,
      ticketMessage: TicketMessages.DeclineSuccess,
    };
  }),
  on(farmActions.declineExistUserToFarmError, (state) => {
    return {
      ...state,
      ticketMessage: TicketMessages.DeclineError,
    };
  }),
  // controllers
  on(farmActions.getFarmControllersSuccess, (state, { controllers }) => {
    return {
      ...state,
      controllers,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.updateGeneralSettingsSuccess, (state, { generalSettings }) => {
    const { controllerID } = generalSettings;
    const controllers = [...state.controllers] as IController[];
    const index = controllers.findIndex(c => c.controllerID == controllerID);

    if (index !== -1) {
      controllers[index] = {
        ...controllers[index],
        controllerID,
      };
    }
    return {
      ...state,
      controllers,
      isLoading: false,
    };
  }),
  on(upsertControllerActions.createControllerByConnectionNumberSuccess, (state, { controller }) => {
    const farms: IFarm[] = JSON.parse(JSON.stringify(state.farms));
    const activeFarmIndex: number = farms.findIndex(farm => farm.farmID === state.activeFarm.farmID);

    if (activeFarmIndex !== -1) {
      farms[activeFarmIndex].existingHouseNumbers = farms[activeFarmIndex].existingHouseNumbers
        ? [...farms[activeFarmIndex].existingHouseNumbers, controller.houseNumber].sort((a, b) => a - b)
        : [controller.houseNumber];
    }

    return {
      ...state,
      farms,
      activeFarm: {
        ...state.activeFarm,
        controllersCount: state.activeFarm.controllersCount + 1,
      },
      controllers: [
        ...state.controllers,
        controller,
      ],
    };
  }),
);

export function farmsReducer(state: FarmsState | undefined, action: Action): any {
  return reducer(state, action);
}
