<ls-page-wrapper
  (back)="goBack()"
  [class.mobile-styles]="platformService.isMobileApp"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'HeatingProgram.HeatingProgram'">
  <div class="svg-content header__buttons d-flex align-center">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      qaTags="qa-btn-edit-program"
      class="icon pointer mr-16 mr-10-md qa-btn-edit-mode">
    </ls-svg-icon>
    <ls-svg-icon
      (click)="goToZones()"
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'mobile/zone' : 'heating-program/zone'"
      qaTags="qa-btn-heating-per-zone"
      class="icon pointer mr-16 mr-10-md qa-btn-zones">
    </ls-svg-icon>
    <ls-svg-icon
      (click)="goToSettings()"
      [class.disabled]="editMode"
      [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
      [path]="platformService.isMobileApp ? 'mobile/settings' : 'installation/settings'"
      class="icon pointer qa-btn-settings">
    </ls-svg-icon>
  </div>

  <ls-heating-programs-form
    *ngIf="editMode || heatingPrograms?.length; else empty"
    (changed)="changedPrograms($event)"
    (deleteProgram)="deleteProgram($event)"
    [editMode]="editMode"
    [heatingPrograms]="heatingPrograms"
    [isLoading]="isLoading$ | async"
    [programZones]="programZones$ | async"
    [temperatureUnit]="temperatureUnit$ | async"
    class="main-content settings__form">
    <div
      *ngIf="editMode"
      class="buttons-content">
      <ls-button
        (click)="cancel()"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-28 h-button-36">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="update()"
        [class.disabled]="!isValid || !isDirty"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'"
        class="h-button-36">
        {{ "Buttons.Save" | translate }}
      </ls-button>
    </div>
  </ls-heating-programs-form>

  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20" />
</ls-page-wrapper>

<ng-template #empty>
  <div class="settings__form d-flex flex-column">
    <div
      *ngIf="!platformService.isMobileApp"
      class="table w-1-8 w-2-12 w-3-12 w-4-12 w-5-12 w-6-12 w-7-16 w-8-16">
      <div class="table-row table-header h-60">
        <div class="table-cell blue">
          {{ "HeatingProgram.Program" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.Zone" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.OnTempDiff" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.OffTempDiff" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.MaxOutput" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.MinOutput" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.Heaters" | translate }}
        </div>
        <div class="table-cell blue">
          {{ "HeatingProgram.Sensors" | translate }}
        </div>
      </div>
      <div class="table-row">
        <div
          *ngFor="let i of [].constructor(8); first as first; last as last"
          [class.head]="first"
          [class.last-column]="last"
          class="table-cell"></div>
      </div>
    </div>
    <div class="empty-state d-flex flex-column justify-center align-center h-100-percent">
      <ls-svg-icon [path]="'heating-program/empty-state'"> </ls-svg-icon>
      <span class="empty-state-title mt-10">
        {{ "HeatingProgram.FirstCreationMessage" | translate }}
      </span>
      <ls-button
        (click)="addDefaultProgram()"
        [qaTags]="'qa-btn-empty'"
        [type]="'primary'">
        {{ "HeatingProgram.EditProgram" | translate }}
      </ls-button>
    </div>
  </div>
</ng-template>
