import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetHeatingProgramZoneView, IHeatingProgramZoneItem } from '../interfaces';
import { IElement } from '@livestock/installation/interfaces';

export const getHeatingProgramZones = createAction(
  '[HEATING PROGRAM ZONE] Get Heating Program Zones [...]',
);

export const getHeatingProgramZonesSuccess = createAction(
  '[HEATING PROGRAM ZONE] Get Heating Program Zones [SUCCESS]',
  props<{ view: IGetHeatingProgramZoneView }>(),
);

export const getHeatingProgramZonesError = createAction(
  '[HEATING PROGRAM ZONE] Get Heating Program Zones [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const updateHeatingProgramZones = createAction(
  '[HEATING PROGRAM ZONE] Update Heating Program Zones [...]',
);

export const updateHeatingProgramZonesSuccess = createAction(
  '[HEATING PROGRAM ZONE] Update Heating Program Zones [SUCCESS]',
  props<{ zones: IHeatingProgramZoneItem[] }>(),
);

export const updateHeatingProgramZonesError = createAction(
  '[HEATING PROGRAM ZONE] Update Heating Program Zones [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const setActiveHeatingProgramZone = createAction(
  '[HEATING PROGRAM ZONE] Set Active Heating Program Zone',
  props<{ zoneID: number }>(),
);

export const addHeatingElementToZone = createAction(
  '[HEATING PROGRAM ZONE] Add Heating Element To Zone',
  props<{ heatingElement: IElement }>(),
);

export const addSensorsElementsToZone = createAction(
  '[HEATING PROGRAM ZONE] Add Sensor Element To Zone',
  props<{ sensors: IElement[] }>(),
);

export const goToNextHeatingProgramZone = createAction(
  '[HEATING PROGRAM ZONE] Go To Next Zone',
);

export const addHeatingProgramZone = createAction(
  '[HEATING PROGRAM ZONE] Add Heating Program Zone',
);

export const removeHeatingProgramZone = createAction(
  '[HEATING PROGRAM ZONE] Remove Heating Program Zone',
);

export const changeZoneName = createAction(
  '[HEATING PROGRAM ZONE] Change Zone Name',
  props<{ name: string }>(),
);

export const changeIsAverage = createAction(
  '[HEATING PROGRAM ZONE] Change Is Average',
  props<{ isAverage: boolean }>(),
);

export const addHeaterToMovingArray = createAction(
  '[HEATING PROGRAM ZONE] Add Heater To Moving Array',
  props<{ elementID: number }>(),
);

export const removeHeaterFromMovingArray = createAction(
  '[HEATING PROGRAM ZONE] Remove Heater From Moving Array',
  props<{ elementID: number }>(),
);

export const moveHeatersToAnotherZone = createAction(
  '[HEATING PROGRAM ZONE] Move Heaters To Another Zone',
  props<{ zoneID: number }>(),
);

export const setOriginalZones = createAction(
  '[HEATING PROGRAM ZONE] Set Original Zones',
  props<{ zones: IHeatingProgramZoneItem[] }>(),
);

export const clearHeatingZonesState = createAction(
  '[HEATING PROGRAM ZONE] Clear Heating Zones State',
);
