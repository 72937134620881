<ng-container *ngIf="currStep === 1">
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate>
    <h1 [qaTags]="'qa-lbl-title'">
      {{ "Auth.ForgotPassword.ResetPassword" | translate }}
    </h1>
    <lv-input
      (keydown.enter)="onSendVerificationCode()"
      (inputFocus)="email['focused'] = true"
      (inputBlur)="email['focused'] = false"
      #email
      [class.ng-invalid]="validateForm && form.get('email').invalid"
      [label]="'Auth.EmailAddress'"
      [qaTags]="'qa-txt-email'"
      [autocomplete]="'nope'"
      formControlName="email"
      type="email"
      class="mt-18 d-block">
      <ls-svg-icon
        [path]="IconsEnum.LETTER"
        [color]="
          validateForm && form.get('email').invalid
            ? ColorsEnum.MainRed
            : !!email['focused']
            ? ColorsEnum.MainBlue
            : ColorsEnum.MonoDark
        "
        leftIcon>
      </ls-svg-icon>
    </lv-input>
    <span
      *ngIf="validateForm && form.get('email').dirty && form.get('email').invalid"
      [qaTags]="'qa-lbl-email-err-message'"
      class="validation-error">
      {{ "Auth.PleaseEnterAValidEmailAddress" | translate }}
    </span>
  </form>

  <div class="btn-group">
    <ls-button
      (click)="onSendVerificationCode()"
      [class.disabled-with-events]="form.get('email').invalid || isLoading"
      [qaTags]="'qa-btn-enter qa-btn-send-ver-code'"
      class="h-48"
      type="primary">
      {{ "Auth.ForgotPassword.Send" | translate }}
    </ls-button>
  </div>
  <div
    (click)="navigateTo(AppRoutes.LOGIN)"
    [qaTags]="'qa-lnk-login'"
    class="login-button text-center pointer mt-142">
    {{ "Auth.SignUp.Login" | translate }}
  </div>
</ng-container>

<ng-container *ngIf="currStep === 2">
  <form
    [formGroup]="form"
    autocomplete="off"
    novalidate>
    <div
      [class.mt-112]="platformService.isMobileApp"
      class="form-field">
      <h1 [qaTags]="'qa-lbl-ver-prompt'">
        {{ "Auth.VerificationCodeSentTo" | translate }}
        <span class="field-value">{{ form.value.email }}</span>
      </h1>
    </div>
    <div class="form-field mt-28">
      <h2
        *ngIf="
          !form.controls.verificationCode.getError('wrong') && !form.controls.verificationCode.getError('expired')
        ">
        {{ "Auth.EnterVerificationCode" | translate }}
      </h2>
      <h2
        *ngIf="form.controls.verificationCode.getError('wrong')"
        class="error">
        {{ "Auth.YouEnteredWrongCode" | translate }}
      </h2>
      <h2
        *ngIf="form.controls.verificationCode.getError('expired')"
        class="error">
        {{ "Auth.YouEnteredExpiredCode" | translate }}
      </h2>
      <lv-verification-code
        (submitForm)="onVerificationCode()"
        [class.ng-invalid]="
          form.get('verificationCode').dirty &&
          (form.controls.verificationCode.getError('wrong') || form.controls.verificationCode.getError('expired'))
        "
        [qaTags]="'qa-txt-verification-code'"
        class="mt-18"
        formControlName="verificationCode">
      </lv-verification-code>
    </div>
  </form>

  <div class="btn-group">
    <div
      (click)="onVerificationCode()"
      [qaTags]="'qa-btn-enter qa-btn-verify-code'"
      [class.disabled]="
        form.controls.verificationCode?.value?.length < GlobalConstants.VERIFICATION_CODE_LENGTH ||
        isLoading ||
        form.controls.verificationCode.getError('wrong') ||
        form.controls.verificationCode.getError('expired')
      "
      class="verify-button h-48 pointer">
      {{ "Auth.VerifyCode" | translate }}
    </div>

    <ls-button
      (click)="onSendNewCode()"
      [class.disabled]="isLoading || isDisabledResendCode"
      [qaTags]="'qa-btn-send-new-code'"
      class="h-48"
      type="primary">
      {{ "Auth.ResendCode" | translate }}
    </ls-button>
  </div>
</ng-container>

<ng-container *ngIf="currStep === 3">
  <form
    [formGroup]="form"
    autocomplete="off"
    class="passwords"
    novalidate>
    <h1
      [qaTags]="'qa-lbl-title'"
      [class.mt-145]="platformService.isMobileApp">
      {{ "Auth.ForgotPassword.ChooseNewPassword" | translate }}
    </h1>
    <span class="password-info mt-8">
      {{ "Auth.GoodPasswordMessage" | translate }}
    </span>
    <div class="mt-18 position-relative">
      <lv-input
        (inputFocus)="password['focused'] = true"
        (inputBlur)="password['focused'] = false"
        #password
        [label]="'Auth.Login.Password'"
        [qaTags]="'qa-txt-new-passwd'"
        [type]="'text'"
        [autocomplete]="'new-password'"
        formControlName="password"
        class="d-block">
        <ls-svg-icon
          [path]="IconsEnum.PASSWORD"
          [color]="
            form.get('password').dirty && form.get('password').invalid
              ? ColorsEnum.MainRed
              : !!password['focused']
              ? ColorsEnum.MainBlue
              : ColorsEnum.MonoDark
          "
          leftIcon>
        </ls-svg-icon>
        <div
          *ngIf="form.get('password').valid"
          (click)="copyPassword($event)"
          class="copy-button pointer"
          rightIcon>
          <ls-svg-icon
            [path]="IconsEnum.COPY"
            class="mb-3">
          </ls-svg-icon>
          <span>{{ "Auth.Copy" | translate }}</span>
        </div>
        <div
          (click)="generatePassword($event)"
          class="generate-button"
          rightButton>
          <ls-svg-icon
            [path]="IconsEnum.GENERATE"
            class="pointer mb-4">
          </ls-svg-icon>
          <span>{{ "Auth.Generate" | translate }}</span>
        </div>
      </lv-input>
      <div
        *ngIf="
          form.get('password').valid && form.get('password').value.length >= GlobalConstants.STRONG_PASSWORD_LENGTH
        "
        class="password-description strong-password">
        <div>
          <span>{{ "Auth.Strong" | translate }} </span>
          <span>{{ "Auth.PasswordGoodJob" | translate | lowercase }}</span>
        </div>
        <div class="password-visualization">
          <div class="green"></div>
          <div class="green ml-4 mr-4"></div>
          <div class="green"></div>
        </div>
      </div>
      <div
        *ngIf="form.get('password').valid && form.get('password').value.length < GlobalConstants.STRONG_PASSWORD_LENGTH"
        class="password-description good-password">
        <div>
          <span>{{ "Auth.Good" | translate }} </span>
          <span>{{ "Auth.PasswordGoodEnough" | translate | lowercase }}</span>
        </div>
        <div class="password-visualization">
          <div class="orange"></div>
          <div class="orange ml-4 mr-4"></div>
          <div class="gray"></div>
        </div>
      </div>
      <div
        *ngIf="form.get('password').dirty && form.hasError('insecurePassword', 'password')"
        class="password-description week-password">
        <span>{{ "Auth.WeekPasswordNotGoodEnough" | translate }}</span>
        <div class="password-visualization">
          <div class="red"></div>
          <div class="gray ml-4 mr-4"></div>
          <div class="gray"></div>
        </div>
      </div>
    </div>
  </form>

  <div class="btn-group">
    <ls-button
      (click)="onSave()"
      [class.disabled]="form.invalid || isLoading"
      [qaTags]="'qa-btn-save'"
      class="h-48"
      type="primary">
      {{ "Auth.FinishAndLogin" | translate }}
    </ls-button>
  </div>
</ng-container>
