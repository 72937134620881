<div
  *ngIf="controls || keyboardService.currentControl as controlsqq"
  class="device-buttons">
  <button
    *ngIf="hasElement1$ | async"
    (click)="controlsqq.controls['element1Connected'].setValue(!controlsqq.controls['element1Connected'].value)"
    (focus)="storeControl($event)"
    (focusout)="clearControl($event)"
    #button
    [class.device-button--large]="large"
    [class.device-button__active]="controlsqq.controls['element1Connected'].value"
    [class.device-button__disabled]="controls?.disabled"
    [class.ng-invalid]="controlsqq.hasError('elementReq')"
    class="device-button"
    tabindex="0">
    <ls-svg-icon
      [class.svg-icon__rtl]="languageService.isRtl"
      [color]="ColorsEnum.BlueDefault"
      [hasMargin]="true"
      [path]="path$ | async">
    </ls-svg-icon>
    <br />
    <p class="fz-12">1</p>
  </button>
  <button
    *ngIf="hasElement2$ | async"
    (click)="controlsqq.controls['element2Connected'].setValue(!controlsqq.controls['element2Connected'].value)"
    (focus)="storeControl($event)"
    (focusout)="clearControl($event)"
    #button
    [class.device-button--large]="large"
    [class.device-button__active]="controlsqq.controls['element2Connected'].value"
    [class.device-button__disabled]="controls?.disabled"
    [class.ng-invalid]="controlsqq.hasError('elementReq')"
    class="device-button"
    tabindex="0">
    <ls-svg-icon
      [class.svg-icon__rtl]="languageService.isRtl"
      [color]="ColorsEnum.BlueDefault"
      [hasMargin]="true"
      [path]="path$ | async">
    </ls-svg-icon>
    <br />
    <p class="fz-12">2</p>
  </button>
  <button
    *ngIf="hasElement3$ | async"
    (click)="controlsqq.controls['element3Connected'].setValue(!controlsqq.controls['element3Connected'].value)"
    (focus)="storeControl($event)"
    (focusout)="clearControl($event)"
    #button
    [class.device-button--large]="large"
    [class.device-button__active]="controlsqq.controls['element3Connected'].value"
    [class.device-button__disabled]="controls?.disabled"
    [class.ng-invalid]="controlsqq.hasError('elementReq')"
    class="device-button"
    tabindex="0">
    <ls-svg-icon
      [class.svg-icon__rtl]="languageService.isRtl"
      [color]="ColorsEnum.BlueDefault"
      [hasMargin]="true"
      [path]="path$ | async">
    </ls-svg-icon>
    <br />
    <p class="fz-12">3</p>
  </button>
</div>
