<ls-page-wrapper
  (back)="goBack()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'Flock.Settings.HouseMode'">
  <div
    *ngIf="!editMode || !platformService.isMobileApp"
    class="svg-content header__buttons d-flex align-center">
    <ls-svg-icon
      (click)="toggleEditMode()"
      [color]="editMode ? ColorsEnum.BlueDark : ColorsEnum.Default"
      [path]="editMode ? 'common/icon-table' : 'installation/edit' + (platformService.isMobileApp ? '-mobile' : '')"
      class="icon pointer ml-20 qa-btn-edit-mode">
    </ls-svg-icon>
  </div>
  <ls-flock-house-mode-form
    (changed)="changedHouseMode($event)"
    [editMode]="editMode"
    [houseMode]="houseMode"
    [isLoading]="isLoading$ | async"
    [temperatureUnit]="temperatureUnit$ | async"
    [ventilationWorkingMode]="ventilationMode$ | async"
    class="main-content">
    <div
      *ngIf="editMode"
      class="buttons-content">
      <ls-button
        (click)="cancel()"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-cancel'"
        [type]="'secondary'"
        class="mr-28 h-button-36">
        {{ "Buttons.Cancel" | translate }}
      </ls-button>
      <ls-button
        (click)="update()"
        [class.disabled]="!isValid || !isDirty"
        [class.h-button-36]="platformService.isMobileApp"
        [ngClass]="platformService.isMobileApp ? 'w-button-130' : 'w-120'"
        [qaTags]="'qa-btn-update'"
        [type]="'primary'">
        {{ "Buttons.Save" | translate }}
      </ls-button>
    </div>
  </ls-flock-house-mode-form>
  <ls-keyboard
    *ngIf="!platformService.isMobileApp && editMode"
    [defaultMode]="KeyboardModeEnum.NumericOnly"
    class="keyboard-content keyboard ml-20 d-none-md" />
</ls-page-wrapper>
