<div
  *ngIf="!isLoading; else loading"
  [class.rtl]="languageService.isRtl"
  [formGroup]="form"
  class="settings d-flex flex-column">
  <div class="settings__container d-flex flex-row flex-column-md overflow-y-auto">
    <div
      [class.disabled]="!editMode"
      class="settings__block d-flex flex-column">
      <label class="label">
        {{ "Flock.Settings.GrowthDay" | translate }}
      </label>
      <div class="d-flex flex-row align-center position-relative">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [max]="FlockSettingsConstants.GrowthDayMax"
          [min]="FlockSettingsConstants.GrowthDayMin"
          [qaTags]="'qa-txt-growth-day'"
          errorField
          formControlName="growthDay">
        </ls-input-integer>
        <span class="ml-5">{{ "Flock.Settings.Days" | translate }}</span>
      </div>

      <label class="label">{{ "Flock.Settings.FlockNumber" | translate }}</label>
      <div class="flock-number">
        {{ form.value.flockNumber + "_" + (flockCreationYear$ | async) }}
      </div>
    </div>

    <div
      [class.disabled]="!editMode"
      class="settings__block d-flex flex-column">
      <label class="label">{{ "Flock.Settings.SeparateMaleAndFemale" | translate }}</label>
      <ls-toggle
        [qaTags]="'qa-chk-separate-male-and-female'"
        [size]="'large'"
        formControlName="separateMaleAndFemale" />

      <ng-container *ngIf="!form.controls.separateMaleAndFemale.value">
        <label class="label">{{ "Flock.Settings.InitialNumberOfBirds" | translate }}</label>
        <div class="position-relative">
          <ls-input-integer
            [fieldWithKeyboard]="true"
            [max]="200000"
            [min]="0"
            [qaTags]="'qa-txt-initial-number-of-birds'"
            errorField
            formControlName="initialNumberOfBirds">
          </ls-input-integer>
        </div>
      </ng-container>

      <ng-container *ngIf="form.controls.separateMaleAndFemale.value">
        <label class="label">{{ "Flock.Settings.MaleInitialNumber" | translate }}</label>
        <div class="position-relative">
          <ls-input-integer
            [fieldWithKeyboard]="true"
            [max]="100000"
            [min]="0"
            [qaTags]="'qa-txt-male-initial-number-of-birds'"
            errorField
            formControlName="maleInitialNumberOfBirds">
          </ls-input-integer>
        </div>

        <label class="label">{{ "Flock.Settings.FemaleInitialNumber" | translate }}</label>
        <div class="position-relative">
          <ls-input-integer
            [fieldWithKeyboard]="true"
            [max]="100000"
            [min]="0"
            [qaTags]="'qa-txt-female-initial-number-of-birds'"
            errorField
            formControlName="femaleInitialNumberOfBirds">
          </ls-input-integer>
        </div>
      </ng-container>
    </div>

    <div
      [class.disabled]="!editMode"
      class="settings__block d-flex flex-column w-150">
      <label class="label">
        {{ "Flock.Settings.HouseMode" | translate }}
      </label>
      <ng-select
        [clearable]="false"
        [qaTags]="'qa-ddl-house-mode'"
        [searchable]="false"
        selectTitle="{{ 'Flock.Settings.HouseMode' | translate }}"
        formControlName="houseMode">
        <ng-option
          *ngFor="let el of HouseModeEnum | enumToArray"
          [disabled]="
            (originalSettings?.houseMode === HouseModeEnum.Empty &&
              [HouseModeEnum.Cleaning, HouseModeEnum.Catching].includes(el)) ||
            (originalSettings?.houseMode === HouseModeEnum.PreHeat &&
              ![HouseModeEnum.PreHeat, HouseModeEnum.Empty].includes(el)) ||
            (originalSettings?.houseMode === HouseModeEnum.Growing && el === HouseModeEnum.PreHeat) ||
            [HouseModeEnum.Cleaning, HouseModeEnum.Catching].includes(originalSettings?.houseMode) ||
            (isNewFlock && ![HouseModeEnum.Growing, HouseModeEnum.PreHeat].includes(el))
          "
          [value]="el">
          {{ el | enum : "house-mode-enum" | translate }}
        </ng-option>
      </ng-select>

      <ng-container *ngIf="form.value.houseMode === HouseModeEnum.PreHeat">
        <label class="label">{{ "Flock.Settings.Time" | translate }}</label>
        <div class="position-relative">
          <ls-input-time
            [fieldWithKeyboard]="true"
            formControlName="timeNextStage"></ls-input-time>

          <ls-svg-icon
            class="next-stage-arrow"
            path="flock/next-stage-arrow"></ls-svg-icon>
        </div>

        <!--TODO: next house mode logic (waiting for Luba)-->
        <label class="label">{{ "Flock.Settings.NextHouseMode" | translate }}</label>
        <div class="pseudo-input">
          {{ HouseModeEnum.Growing | enum : "house-mode-enum" | translate }}
        </div>
      </ng-container>

      <label class="label">{{ "Flock.Settings.Staging" | translate }}</label>
      <ng-select
        [clearable]="false"
        [dropdownPosition]="isWindowHeightSmall ? 'top' : 'auto'"
        [qaTags]="'qa-ddl-staging'"
        [searchable]="false"
        selectTitle="{{ 'Flock.Settings.Staging' | translate }}"
        formControlName="staging">
        <ng-option
          *ngFor="let el of StagingEnum | enumToArray"
          [value]="el">
          {{ el | enum : "staging-enum" | translate }}
        </ng-option>
      </ng-select>

      <label class="label">{{ "Flock.Settings.Unit" | translate }}</label>
      <!--could edit only if flock is new-->
      <div>
        <div
          (click)="openUnitsSelector($event)"
          class="pointer position-relative d-flex-md align-center-md">
          <ng-select
            [clearable]="false"
            [searchable]="false"
            selectTitle="{{ 'Flock.Settings.Unit' | translate }}"
            class="pointer-events-none w-100-percent"
            formControlName="units">
            <ng-option
              *ngFor="let el of ControllerUnitEnum | enumToArray"
              [value]="el">
              {{ el | enum : "controller-unit" | translate }}
            </ng-option>
          </ng-select>

          <ls-svg-icon
            [color]="ColorsEnum.Default"
            [path]="'common/edit'"
            class="unit-edit pointer ml-8-md">
          </ls-svg-icon>
        </div>
      </div>
    </div>

    <div class="w-20-percent d-none-device"></div>
  </div>
  <div class="buttons">
    <ng-content select=".buttons-content"></ng-content>
  </div>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
