import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({
  name: 'styleTextPart',
  standalone: true,
})
export class StyleTextPartPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(value: string, textPart: string, className: string): SafeHtml {
    if (value?.includes(textPart)) {
      const htmlContent = value.replace(new RegExp(textPart, 'g'), `<span class="${className}">${textPart}</span>`);
      return this.sanitizer.bypassSecurityTrustHtml(htmlContent);
    }
    return value;
  }
}
