import { ChangeDetectionStrategy, ChangeDetectorRef, Component, inject } from '@angular/core';
import { ControlContainer, FormControl, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { ElementOperationEnum } from 'libs/installation/src/lib/enums/element/element-operation.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatDialog } from '@angular/material/dialog';
import { keyboardUpdateFields } from '@livestock/ui';
import { BasicElementComponent } from '../basic-element/basic-element.component';
import { IGetOrUpdateElement } from '@livestock/installation/interfaces';
import {
  getVentilationBrands,
  getVentilationModels,
  IVentModelAOItem,
  selectVentilationAOModelByID,
  VentilationBrandDialogComponent,
} from '@livestock/ventilation-brands';
import { ColorsEnum } from '@livestock/shared/enums';
import { lastValueFrom, Observable } from 'rxjs';
import { PlatformService } from '@livestock/shared/services';
import { InstallationHelpersService } from '../../../../service/installation-helpers.service';
import { ElementsValidationConstants } from '@livestock/installation/constants';

@Component({
  selector: 'ls-ventilation-ao-form',
  templateUrl: './ventilation-ao-form.component.html',
  styleUrls: ['../ventilation-do/ventilation-do.component.scss'],
  viewProviders: [{
    provide: ControlContainer,
    useFactory: () => inject(ControlContainer, { skipSelf: true }),
  }],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class VentilationAoFormComponent extends BasicElementComponent {
  model$: Observable<(modelID: number, brandID: number) => IVentModelAOItem> = this.store.select(selectVentilationAOModelByID);
  airFlowSteps = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  ColorsEnum = ColorsEnum;
  valuesAirFlowValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(ElementsValidationConstants.ventilationAO.airFlow.min),
    Validators.max(ElementsValidationConstants.ventilationAO.airFlow.max),
  ];
  valuesMinEfficiencyValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(ElementsValidationConstants.ventilationAO.minEfficiency.min),
    Validators.max(ElementsValidationConstants.ventilationAO.minEfficiency.max),
  ];
  valuesMaxEfficiencyValidators: ValidatorFn[] = [
    Validators.required,
    Validators.min(ElementsValidationConstants.ventilationAO.maxEfficiency.min),
    Validators.max(ElementsValidationConstants.ventilationAO.maxEfficiency.max),
  ];
  valuesFormGroup = new FormGroup({
    airFlow10: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow20: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow30: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow40: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow50: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow60: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow70: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow80: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow90: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    airFlow100: new FormControl<number>(0, [...this.valuesAirFlowValidators]),
    maxEfficiency10: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency20: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency30: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency40: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency50: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency60: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency70: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency80: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency90: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    maxEfficiency100: new FormControl<number>(0, [...this.valuesMaxEfficiencyValidators]),
    minEfficiency10: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency20: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency30: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency40: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency50: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency60: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency70: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency80: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency90: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
    minEfficiency100: new FormControl<number>(0, [...this.valuesMinEfficiencyValidators]),
  });

  constructor(
    store: Store,
    translation: TranslateService,
    parentFormContainer: ControlContainer,
    public dialog: MatDialog,
    public platformService: PlatformService,
    public installationHelpersService: InstallationHelpersService,
    private cdr: ChangeDetectorRef,
  ) {
    super(store, translation, parentFormContainer);
  }

  override ngOnInit(): void {
    this.store.dispatch(getVentilationBrands());

    this.parentFormGroup.addControl(this.formGroupName,
      new FormGroup({
        name: this.name,
        maxVoltage: new FormControl<number>(0),
        minVoltage: new FormControl<number>(10),
        boostTime: new FormControl<number>(5, [
          Validators.required,
          Validators.min(ElementsValidationConstants.ventilationAO.boostTime.min),
          Validators.max(ElementsValidationConstants.ventilationAO.boostTime.max),
        ]),
        isMultipleFans: new FormControl<boolean>(false),
        fansNumber: new FormControl<number>(1, [
          Validators.required,
          Validators.min(ElementsValidationConstants.ventilationAO.fansNumber.min),
          Validators.max(ElementsValidationConstants.ventilationAO.fansNumber.max),
        ]),
        operation: new FormControl<ElementOperationEnum>(ElementOperationEnum.NormallyOpen, Validators.required),
        isCustomModel: new FormControl<boolean>(false),
        brandID: new FormControl<number>(null, Validators.required),
        modelID: new FormControl<number>(null, Validators.required),
        values: this.valuesFormGroup,
      }));
    super.ngOnInit();
  }

  override patchValue(setupData: IGetOrUpdateElement): void {
    this.parentFormGroup.get(this.formGroupName).patchValue({
      name: setupData.name,
      maxVoltage: setupData.maxVoltage,
      minVoltage: setupData.minVoltage,
      boostTime: setupData.boostTime,
      isMultipleFans: setupData.isMultipleFans,
      fansNumber: setupData.fansNumber,
      operation: setupData.operation,
      isCustomModel: setupData.isCustomModel,
      brandID: setupData.brandID,
      modelID: setupData.modelID,
      values: { ...setupData.values || this.parentFormGroup.get(this.formGroupName).value.values },
    });

    if (setupData.brandID) {
      this.store.dispatch(getVentilationModels({ brandID: setupData.brandID, isDO: false }));
    }

    this.validateNumberOfFans(setupData.isMultipleFans, setupData.fansNumber);
    this.validateVentBrandFields(setupData.isCustomModel);
  }

  validateNumberOfFans(isMultipleFans: boolean, numberOfFans?: number): void {
    const control = (this.parentFormGroup.get(this.formGroupName) as FormGroup).controls['fansNumber'];
    control.setValidators([Validators.required, Validators.min(isMultipleFans ? 2 : 1), Validators.max(40)]);
    control.setValue(numberOfFans || (isMultipleFans ? 2 : 1));
    this.parentFormGroup.get(this.formGroupName).updateValueAndValidity();
  }

  async openVentilationBrandsPopup(): Promise<void> {
    const dialogRef = this.dialog.open(VentilationBrandDialogComponent, {
      width: '540px',
      maxWidth: '100%',
      disableClose: true,
    });
    const form = this.parentFormGroup.get(this.formGroupName) as FormGroup;
    const brandIDControl = form.controls['brandID'];
    const modelIDControl = form.controls['modelID'];

    dialogRef.componentInstance.setup(brandIDControl.value, modelIDControl.value, false);

    const res = await lastValueFrom(dialogRef.afterClosed());
    if (res) {
      brandIDControl.setValue(res.brandID);
      modelIDControl.setValue(res.modelID);
      form.updateValueAndValidity();
      this.cdr.detectChanges();
    }
  }

  validateVentBrandFields(isCustomModel: boolean, unsetValues: boolean = false): void {
    const form = (this.parentFormGroup.get(this.formGroupName) as FormGroup);
    const brandIDControl = form.controls['brandID'];
    const modelIDControl = form.controls['modelID'];
    const valuesControl = form.controls['values'];
    const validator = !isCustomModel ? Validators.required : null;

    brandIDControl.setValidators(validator);
    modelIDControl.setValidators(validator);

    if (unsetValues) {
      brandIDControl.setValue(null);
      modelIDControl.setValue(null);
      valuesControl.patchValue({
        airFlow10: 0,
        airFlow20: 0,
        airFlow30: 0,
        airFlow40: 0,
        airFlow50: 0,
        airFlow60: 0,
        airFlow70: 0,
        airFlow80: 0,
        airFlow90: 0,
        airFlow100: 0,
        maxEfficiency10: 0,
        maxEfficiency20: 0,
        maxEfficiency30: 0,
        maxEfficiency40: 0,
        maxEfficiency50: 0,
        maxEfficiency60: 0,
        maxEfficiency70: 0,
        maxEfficiency80: 0,
        maxEfficiency90: 0,
        maxEfficiency100: 0,
        minEfficiency10: 0,
        minEfficiency20: 0,
        minEfficiency30: 0,
        minEfficiency40: 0,
        minEfficiency50: 0,
        minEfficiency60: 0,
        minEfficiency70: 0,
        minEfficiency80: 0,
        minEfficiency90: 0,
        minEfficiency100: 0,
      });
    }

    form.updateValueAndValidity();
    this.store.dispatch(keyboardUpdateFields());
  }
}
