import { Route } from '@angular/router';
import {
  BirdWeightComponent,
  FlockHouseModeComponent,
  FlockSettingsComponent,
  newFlockResolver,
} from '@livestock/flock';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import {
  ControllerDateTimeSettingsComponent,
  ControllerGeneralSettingsComponent, ControllerHouseSizesSettingsComponent, ControllerNetworkSettingsComponent,
  ElementsGuard,
  controllerResolver,
  elementsResolver, generalSettingsResolver,
} from '@livestock/controllers';
import { StaticPressureComponent, StaticPressureGuard } from '@livestock/static-pressure';
import { HeatingProgramZoneComponent } from '@livestock/heating-program-zone';
import { TempMappingComponent } from '@livestock/temp-mapping';
import { LightingMappingComponent } from '@livestock/lighting-mapping';
import { CanDeactivateGuard } from '@livestock/shared/guards';
import { ElementTypesEnum } from '@livestock/shared/enums';
import { LightingProgramComponent } from '@livestock/lighting-program';
import { TemperatureCurveComponent } from '@livestock/temperature-curve';
import { CoolingProgramComponent } from '@livestock/cooling-program';
import { HeatingElementsGuard, HeatingProgramsComponent } from '@livestock/heating-program';
import { AirTreatmentComponent, AirTreatmentSettingsComponent } from '@livestock/air-treatment';
import { SprinklersFoggersProgramComponent } from '@livestock/sprinklers-foggers-program';
import {
  BasicVentilationMinimumComponent,
  VentilationMinimumSettingsComponent,
} from '@livestock/basic-ventilation-minimum';
import {
  CloudSettingsDeviceComponent,
} from '../../../../libs/controllers/src/lib/components/cloud-settings-device/cloud-settings-device.component';

export const appRoutes: Route[] = [
  {
    path: '',
    loadComponent: () =>
      import('@livestock/controllers').then(
        com => com.QuickStartWebComponent,
      ),
  },
  {
    path: AppRoutes.INSTALLATION,
    loadChildren: () =>
      import('@livestock/installation').then(
        m => m.InstallationWrapperModule,
      ),
    resolve: { isLoaded: controllerResolver },
  },
  {
    path: AppRoutes.DASHBOARD,
    loadComponent: () =>
      import('@livestock/dashboard').then(
        (com) => com.DashboardComponent,
      ),
    resolve: { isLoaded: controllerResolver },
  },
  {
    path: AppRoutes.NEW_FLOCK,
    loadComponent: () =>
      import('@livestock/flock').then(
        com => com.FlockWizardComponent,
      ),
    resolve: { isLoaded: newFlockResolver },
  },
  {
    path: AppRoutes.LANDING,
    loadComponent: () =>
      import('@livestock/landing').then(
        com => com.LandingPageComponent,
      ),
  },
  {
    path: AppRoutes.CONTROLLER,
    children: [
      {
        path: ':controllerID',
        children: [
          {
            path: ControllerRoutes.StaticPressure,
            component: StaticPressureComponent,
            canActivate: [StaticPressureGuard],
            canDeactivate: [CanDeactivateGuard],
            resolve: { generalSettings: generalSettingsResolver },
          },
          {
            path: ControllerRoutes.HeatingProgramZone,
            component: HeatingProgramZoneComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ControllerRoutes.HeatingPrograms,
            component: HeatingProgramsComponent,
            data: {
              doElementType: ElementTypesEnum.HeatingDO,
              aoElementType: ElementTypesEnum.HeatingAO,
            },
            canActivate: [HeatingElementsGuard],
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: `${ControllerRoutes.TemperatureMapping}`,
            component: TempMappingComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: `${ControllerRoutes.TemperatureCurve}/:viewType`,
            component: TemperatureCurveComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: { generalSettings: generalSettingsResolver },
          },
          {
            path: ControllerRoutes.FlockSettings,
            component: FlockSettingsComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ControllerRoutes.FlockHouseMode,
            component: FlockHouseModeComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: { generalSettings: generalSettingsResolver },
          },
          {
            path: ControllerRoutes.FlockBirdWeight,
            component: BirdWeightComponent,
            canDeactivate: [CanDeactivateGuard],
            resolve: { generalSettings: generalSettingsResolver },
          },
          {
            path: ControllerRoutes.LightingPrograms,
            component: LightingProgramComponent,
            resolve: { elements: elementsResolver },
            canDeactivate: [CanDeactivateGuard],
            canActivate: [ElementsGuard],
            data: { elementType: [ElementTypesEnum.LightAO, ElementTypesEnum.LightDO] },
          },
          {
            path: `${ControllerRoutes.LightingMapping}`,
            component: LightingMappingComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [ElementsGuard],
            data: { elementType: [ElementTypesEnum.LightAO, ElementTypesEnum.LightDO] },
          },
          {
            path: ControllerRoutes.Cooling,
            component: CoolingProgramComponent,
            canActivate: [ElementsGuard],
            canDeactivate: [CanDeactivateGuard],
            data: { elementType: [ElementTypesEnum.Cooling] },
            resolve: { elements: elementsResolver, generalSettings: generalSettingsResolver },
          },
          {
            path: `${ControllerRoutes.AirTreatment}`,
            component: AirTreatmentComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: `${ControllerRoutes.AirTreatmentSettings}`,
            component: AirTreatmentSettingsComponent,
            canDeactivate: [CanDeactivateGuard],
          },
          {
            path: ControllerRoutes.GeneralSettings,
            component: ControllerGeneralSettingsComponent,
          },
          {
            path: ControllerRoutes.DateTimeSettings,
            component: ControllerDateTimeSettingsComponent,
          },
          {
            path: ControllerRoutes.NetworkSettings,
            component: ControllerNetworkSettingsComponent,
          },
          {
            path: ControllerRoutes.CloudSettings,
            component: CloudSettingsDeviceComponent,
          },
          {
            path: ControllerRoutes.HouseSizesSettings,
            component: ControllerHouseSizesSettingsComponent,
          },
          {
            path: ControllerRoutes.SprinklersFoggersProgram,
            component: SprinklersFoggersProgramComponent,
            canActivate: [ElementsGuard],
            canDeactivate: [CanDeactivateGuard],
            data: { elementType: [ElementTypesEnum.SprinklersAndFoggers] },
            resolve: { elements: elementsResolver, generalSettings: generalSettingsResolver },
          },
          {
            path: ControllerRoutes.BasicVentilationMinimum,
            component: BasicVentilationMinimumComponent,
            canDeactivate: [CanDeactivateGuard],
            canActivate: [ElementsGuard],
            data: { elementType: [ElementTypesEnum.VentilationDO, ElementTypesEnum.VentilationAO] },
          },
          {
            path: `${ControllerRoutes.BasicVentilationMinimum}/settings`,
            component: VentilationMinimumSettingsComponent,
            canDeactivate: [CanDeactivateGuard],
          },
        ],
        resolve: { isLoaded: controllerResolver },
      },
    ],
  },
  {
    path: '**',
    loadComponent: () => import('@livestock/ui').then(com => com.Page404Component),
  },
];
