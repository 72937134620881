import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { SvgIconComponent } from '../../../svg-icon/svg-icon.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatRadioModule } from '@angular/material/radio';
import { FormsModule } from '@angular/forms';
import { QaTagsDirective } from '@livestock/shared/directives';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { IGetOrUpdateElement } from 'libs/installation/src/lib/interfaces/element/get-or-update-element.interface';
import { LanguageService } from '@livestock/shared/services';

@Component({
  selector: 'ls-copy-dialog',
  templateUrl: './copy-dialog.component.html',
  styleUrls: ['./copy-dialog.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
    FormsModule,
    MatRadioModule,
    TranslateModule,
    SvgIconComponent,
    CommonModule,
  ],
})
export class CopyDialogComponent {

  constructor(
    public languageService: LanguageService,
    private dialogRef: MatDialogRef<CopyDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { elements: IGetOrUpdateElement[] },
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  copyElement(val: IGetOrUpdateElement): void {
    this.dialogRef.close(val);
  }
}
