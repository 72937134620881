<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-20">
    <label qaTags="qa-label-name">{{ "Installation.Main.SensorName" | translate }}</label>

    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-name'"
        errorField
        formControlName="name"></ls-input-text>
      <ng-content></ng-content>
    </div>
  </div>

  <div class="device-form__field device-form__field-margin">
    <label qaTags="qa-lbl-enabled">{{ "Enabled" | translate }}</label>
    <ls-toggle
      [offTitle]="'Buttons.No'"
      [onTitle]="'Buttons.Yes'"
      [qaTags]="'qa-tog-enabled'"
      [size]="'large'"
      formControlName="enabled" />
  </div>
</div>
