import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ControlValueAccessor, FormControl, FormGroup, NG_VALUE_ACCESSOR, ReactiveFormsModule, Validators } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { ButtonComponent, DualToggleComponent, RadioListComponent } from '@livestock/ui';
import {
  AirFlowUnitEnum,
  AirPressureUnitEnum,
  AirSpeedUnitEnum,
  IconsEnum,
  IlluminanceUnitEnum,
  LengthUnitEnum,
  TemperatureUnitEnum,
  VolumeUnitEnum,
  WaterLinePressureUnitEnum,
  WaterMainPressureUnitEnum,
  WeightUnitEnum,
} from '@livestock/shared/enums';
import { EnumPipe, EnumToArrayPipe, MemoizeFuncPipe, StyleTextPartPipe } from '@livestock/shared/pipes';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';
import { Subscription } from 'rxjs';
import { wasChanged } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-custom-units-list',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatRadioModule,
    TranslateModule,
    ButtonComponent,
    EnumToArrayPipe,
    EnumPipe,
    MemoizeFuncPipe,
    QaTagsDirective,
    DualToggleComponent,
    RadioListComponent,
    StyleTextPartPipe,
  ],
  templateUrl: './custom-units-list.component.html',
  styleUrls: ['./custom-units-list.component.scss'],
  providers: [{
    provide: NG_VALUE_ACCESSOR,
    multi: true,
    useExisting: forwardRef(() => CustomUnitsListComponent),
  }],
})
export class CustomUnitsListComponent implements ControlValueAccessor, OnInit, OnDestroy {
  @Output() change: EventEmitter<any> = new EventEmitter<any>();

  get value(): any {
    return this.form.value;
  }

  @Input('value')
  set value(value: any) {
    this.form.setValue(value);
  }

  sub$ = new Subscription();
  form = new FormGroup(
    {
      temperature: new FormControl(null, [Validators.required]),
      airFlow: new FormControl(null, [Validators.required]),
      airSpeed: new FormControl(null, [Validators.required]),
      airPressure: new FormControl(null, [Validators.required]),
      waterMainPressure: new FormControl(null, [Validators.required]),
      waterLinePressure: new FormControl(null, [Validators.required]),
      weight: new FormControl(null, [Validators.required]),
      volume: new FormControl(null, [Validators.required]),
      length: new FormControl(null, [Validators.required]),
      illuminance: new FormControl(null, [Validators.required]),
    },
  );
  readonly airPressureUnitList = [
    {
      title: 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.mm',
      subtitle: 'Controller.GeneralSettings.CustomUnitsDialog.AirPressureEnum.ofWater',
      value: AirPressureUnitEnum.MmOfWater,
    },
    {
      title: AirPressureUnitEnum.toTranslateKey(AirPressureUnitEnum.Pascal),
      value: AirPressureUnitEnum.Pascal,
    },
  ];
  readonly MIN_OPTION_WIDTH_CLASS: string = 'w-108';

  TemperatureUnitEnum = TemperatureUnitEnum;
  AirFlowUnitEnum = AirFlowUnitEnum;
  AirPressureUnitEnum = AirPressureUnitEnum;
  AirSpeedUnitEnum = AirSpeedUnitEnum;
  IlluminanceUnitEnum = IlluminanceUnitEnum;
  LengthUnitEnum = LengthUnitEnum;
  VolumeUnitEnum = VolumeUnitEnum;
  WaterLinePressureUnitEnum = WaterLinePressureUnitEnum;
  WaterMainPressureUnitEnum = WaterMainPressureUnitEnum;
  WeightUnitEnum = WeightUnitEnum;
  IconsEnum = IconsEnum;

  constructor(
    public platformService: PlatformService,
    public languageService: LanguageService,
  ) {
  }
  ngOnInit(): void {
    this.form.valueChanges.pipe(
      wasChanged(),
    ).subscribe(i => {
      this.propagateChange(i);
    });
  }

  _onTouched = (): void => {
  };

  propagateChange: (value: any) => void = () => {
  };

  registerOnChange(fn: (value: any) => void): void {
    this.propagateChange = fn;
  }

  registerOnTouched(fn: () => object): void {
    this._onTouched = fn;
  }

  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value);
    }
  }

  airPressureUnitChange(airPressureUnit: AirPressureUnitEnum): void {
    this.form.controls['airPressure'].setValue(airPressureUnit === null ? AirPressureUnitEnum.Pascal : airPressureUnit);
    this.airPressureUnitList.forEach(unit => {
      unit['cssClass'] = unit.value === this.form.controls['airPressure'].value ? 'highlighted' : 'mono-grey';
    });
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
