import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { map } from 'rxjs/operators';
import { catchError, firstValueFrom, lastValueFrom, Observable } from 'rxjs';
import { ILoginResponseModel } from '../interfaces/login-response.interface';
import { LoginData } from '../interfaces/login-request.interface';
import { ISignUpReq } from '../interfaces/sign-up.interface';
import { IResetPasswordReq } from '../interfaces/reset-password.interface';
import { IResponse } from '@livestock/shared/interfaces';
import { LocalStorageService } from '@livestock/shared/services';
import { GlobalConstants } from '@livestock/shared/constants';
import { QueryParamsHandling, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { getCurrentUser, ICurrentUserView, selectCurrentUser } from '@livestock/current-user';
import { StorageItem } from '@livestock/shared/enums';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private baseUrl = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private router: Router,
    private store: Store,
  ) {
    /* refresh token every minute */
    setInterval(async () => {
      try {
        if (!LocalStorageService.getStorageItem(StorageItem.Token)) {
          return;
        }

        if (this.router.url.includes('/auth')) {
          return;
        }

        const refreshTokenRequest = await this.refreshToken();
        const token = await lastValueFrom(refreshTokenRequest);
        LocalStorageService.setStorageItem(StorageItem.Token, token);
      } catch (e) {
        console.log(e);
      }
    }, 1000 * 5);
  }

  goToLogin(queryParamsHandling?: QueryParamsHandling): void {
    LocalStorageService.removeStorageItem(StorageItem.Token);
    LocalStorageService.removeStorageItem(StorageItem.ActiveControllerID);
    LocalStorageService.removeStorageItem(StorageItem.ActiveFarmID);

    setTimeout(() => {
      this.router.navigate([GlobalConstants.authLoginPage], {
        queryParamsHandling,
      });
    });
  }

  login(data: LoginData): Observable<ILoginResponseModel> {
    return this.http
      .post<ILoginResponseModel>(
        `${this.baseUrl}${GlobalConstants.authLoginPage}`,
        data,
      )
      .pipe(
        map((res) => {
          if (!res || !res.token) {
            throw new Error('Token flash-message');
          }
          return res;
        }),
      );
  }

  signUp({ ticketID, ...data }: ISignUpReq): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/sign-up/execute`,
      data,
    );
  }

  resetPassword({ ticketID, ...data }: IResetPasswordReq): Observable<any> {
    return this.http.post<any>(
      `${this.baseUrl}/ticket/${ticketID}/reset-password/execute`,
      data,
    );
  }

  logout(): Observable<any> {
    return this.http.get<IResponse<any>>(`${this.baseUrl}/auth/logout`);
  }

  async refreshToken(): Promise<Observable<string>> {
    return this.http
      .get<{ token: string }>(`${this.baseUrl}/auth/token/refresh`)
      .pipe(
        map((res: { token: string }) => res?.token),
        catchError((e) => {
          throw new Error(e);
        }),
      );
  }

  async canActivate(): Promise<boolean> {
    const token = LocalStorageService.getStorageItem(StorageItem.Token);
    const currentUser = await this.getCurrentUser();
    if (token) {
      if (!currentUser) {
        this.store.dispatch(getCurrentUser());
      }
      return true;
    }

    return false;
  }

  async getCurrentUser(): Promise<ICurrentUserView> {
    return await firstValueFrom(this.store.select(selectCurrentUser));
  }
}
