<form
  [formGroup]="form"
  class="d-flex flex-column gap-18 house-modes-form">
  <div class="d-flex flex-row">
    <div class="container d-flex flex-row gap-10 w-100-percent">
      <div class="form-field disabled justify-unset">
        <span class="form-field__title">{{ "Flock.HouseMode" | translate }}</span>
        <span class="form-field__value">
          <span class="highlight">{{ "Flock.Preheat" | translate }}</span>
        </span>
      </div>
      <div class="d-flex flex-row justify-between w-100-percent">
        <lv-input-time
          [AMPM]="hoursFormat === HoursFormatTypeEnum.AmPm"
          [AMPMOption]="AMPMOption$ | async"
          [placeholder]="
            hoursFormat === HoursFormatTypeEnum.AmPm
              ? GlobalConstants.Time_hhmmAMPM_placeholder
              : GlobalConstants.Time_hhmm_placeholder
          "
          label="Flock.GrowingStart"
          textPosition="center"
          formControlName="timeChangeNextMode"
          class="w-input-63 bg-white validate-untouched">
          <ls-svg-icon
            [path]="IconsEnum.TIME_ICON"
            leftIcon
            class="mr-8 w-18">
          </ls-svg-icon>
        </lv-input-time>

        <div class="override-input">
          <lv-input-decimal-with-label
            (onFocusIn)="inputFocus('preheat', true)"
            (onFocusOut)="inputFocus('preheat', false)"
            [accuracy]="DECIMAL_INPUT_ACCURACY"
            label="{{
              'Flock.TargetTempWithValue'
                | translate : { temp: TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }
            }}"
            [min]="FlockConstants.MinTargetTemperature"
            [max]="maxTargetTemperature"
            inputCssClass="w-100-percent-forced"
            formControlName="targetTemperaturePreheat" />
          <div
            *ngIf="focusedInput['preheat'] || form.controls['targetTemperaturePreheat']?.errors"
            [ngClass]="{ error: form.controls['targetTemperaturePreheat']?.errors }"
            class="hint absolute">
            {{ "Flock.PossibleRange" | translate }} {{ FlockConstants.MinTargetTemperature
            }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }} +{{ maxTargetTemperature
            }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }}
          </div>
        </div>
        <div class="form-field">
          <span class="form-field__title">{{ "Flock.TempAlert" | translate }}</span>
          <span class="form-field__value">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Buttons.Yes' | translate,
                value: true,
              }"
              [secondOption]="{
                title: 'Buttons.No' | translate,
                value: false,
              }"
              formControlName="enableTemperatureAlarmPreheat"></lv-dual-toggle>
          </span>
        </div>
        <div class="form-field range">
          <span class="form-field__title">{{ "Flock.Ventilation" | translate }}</span>
          <span class="form-field__value">
            <ls-range
              [min]="MIN_VENTILATION"
              [max]="maxVentilation"
              formControlName="ventilationPreheat" />
          </span>
        </div>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row justify-between">
    <div class="container d-flex flex-column gap-18">
      <div class="form-field w-100-percent-forced disabled justify-unset">
        <span class="form-field__title">{{ "Flock.HouseMode" | translate }}</span>
        <span class="form-field__value">
          <span class="highlight">{{ "Flock.EmptyHouse" | translate }}</span>
        </span>
      </div>
      <div class="d-flex flex-row gap-18 mb-5">
        <div class="override-input">
          <lv-input-decimal-with-label
            (onFocusIn)="inputFocus('empty', true)"
            (onFocusOut)="inputFocus('empty', false)"
            [accuracy]="DECIMAL_INPUT_ACCURACY"
            label="{{
              'Flock.TargetTempWithValue'
                | translate : { temp: TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }
            }}"
            [min]="FlockConstants.MinTargetTemperature"
            [max]="maxTargetTemperature"
            inputCssClass="w-100-percent-forced"
            formControlName="targetTemperatureEmpty" />
        </div>
        <div class="form-field w-100-percent-forced">
          <span class="form-field__title">{{ "Flock.TempAlert" | translate }}</span>
          <span class="form-field__value">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Buttons.Yes' | translate,
                value: true,
              }"
              [secondOption]="{
                title: 'Buttons.No' | translate,
                value: false,
              }"
              formControlName="enableTemperatureAlarmEmpty"></lv-dual-toggle>
          </span>
        </div>
      </div>
      <div
        *ngIf="focusedInput['empty'] || form.controls['targetTemperatureEmpty']?.errors"
        [ngClass]="{ error: form.controls['targetTemperatureEmpty']?.errors }"
        class="hint">
        {{ "Flock.PossibleRange" | translate }} {{ FlockConstants.MinTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }} +{{ maxTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }}
      </div>
      <div class="form-field range">
        <span class="form-field__title">{{ "Flock.Ventilation" | translate }}</span>
        <span class="form-field__value">
          <ls-range
            [min]="MIN_VENTILATION"
            [max]="maxVentilation"
            formControlName="ventilationEmpty" />
        </span>
      </div>
    </div>
    <div class="container d-flex flex-column gap-18">
      <div class="form-field w-100-percent-forced disabled justify-unset">
        <span class="form-field__title">{{ "Flock.HouseMode" | translate }}</span>
        <span class="form-field__value">
          <span class="highlight">{{ "Flock.Catching" | translate }}</span>
        </span>
      </div>
      <div class="d-flex flex-row gap-18 mb-5">
        <div class="override-input">
          <lv-input-decimal-with-label
            (onFocusIn)="inputFocus('catching', true)"
            (onFocusOut)="inputFocus('catching', false)"
            [accuracy]="DECIMAL_INPUT_ACCURACY"
            label="{{
              'Flock.TargetTempWithValue'
                | translate : { temp: TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }
            }}"
            [min]="FlockConstants.MinTargetTemperature"
            [max]="maxTargetTemperature"
            inputCssClass="w-100-percent-forced"
            formControlName="targetTemperatureCatching" />
        </div>
        <div class="form-field w-100-percent-forced">
          <span class="form-field__title">{{ "Flock.TempAlert" | translate }}</span>
          <span class="form-field__value">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Buttons.Yes' | translate,
                value: true,
              }"
              [secondOption]="{
                title: 'Buttons.No' | translate,
                value: false,
              }"
              formControlName="enableTemperatureAlarmCatching"></lv-dual-toggle>
          </span>
        </div>
      </div>
      <div
        *ngIf="focusedInput['catching'] || form.controls['targetTemperatureCatching']?.errors"
        [ngClass]="{ error: form.controls['targetTemperatureCatching']?.errors }"
        class="hint">
        {{ "Flock.PossibleRange" | translate }} {{ FlockConstants.MinTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }} +{{ maxTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }}
      </div>
      <div class="form-field range">
        <span class="form-field__title">{{ "Flock.Ventilation" | translate }}</span>
        <span class="form-field__value">
          <ls-range
            [min]="MIN_VENTILATION"
            [max]="maxVentilation"
            formControlName="ventilationCatching" />
        </span>
      </div>
    </div>
    <div class="container d-flex flex-column gap-18">
      <div class="form-field w-100-percent-forced disabled justify-unset">
        <span class="form-field__title">{{ "Flock.HouseMode" | translate }}</span>
        <span class="form-field__value">
          <span class="highlight">{{ "Flock.Cleaning" | translate }}</span>
        </span>
      </div>
      <div class="d-flex flex-row gap-18 mb-5">
        <div class="override-input">
          <lv-input-decimal-with-label
            (onFocusIn)="inputFocus('cleaning', true)"
            (onFocusOut)="inputFocus('cleaning', false)"
            [accuracy]="DECIMAL_INPUT_ACCURACY"
            label="{{
              'Flock.TargetTempWithValue'
                | translate : { temp: TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }
            }}"
            [min]="FlockConstants.MinTargetTemperature"
            [max]="maxTargetTemperature"
            inputCssClass="w-100-percent-forced"
            formControlName="targetTemperatureCleaning" />
        </div>
        <div class="form-field w-100-percent-forced">
          <span class="form-field__title">{{ "Flock.TempAlert" | translate }}</span>
          <span class="form-field__value">
            <lv-dual-toggle
              [firstOption]="{
                title: 'Buttons.Yes' | translate,
                value: true,
              }"
              [secondOption]="{
                title: 'Buttons.No' | translate,
                value: false,
              }"
              formControlName="enableTemperatureAlarmCleaning"></lv-dual-toggle>
          </span>
        </div>
      </div>
      <div
        *ngIf="focusedInput['cleaning'] || form.controls['targetTemperatureCleaning']?.errors"
        [ngClass]="{ error: form.controls['targetTemperatureCleaning']?.errors }"
        class="hint">
        {{ "Flock.PossibleRange" | translate }} {{ FlockConstants.MinTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }} +{{ maxTargetTemperature
        }}{{ TemperatureUnitEnum.toShortTranslateKey(temperatureUnit) | translate }}
      </div>
      <div class="form-field range">
        <span class="form-field__title">{{ "Flock.Ventilation" | translate }}</span>
        <span class="form-field__value">
          <ls-range
            [min]="MIN_VENTILATION"
            [max]="maxVentilation"
            formControlName="ventilationCleaning" />
        </span>
      </div>
    </div>
  </div>
  <div class="d-flex flex-row">
    <div class="form-field bg-mono-bg">
      <span class="form-field__title">{{ "Flock.Units" | translate }}</span>
      <span class="form-field__value">
        <lv-dual-toggle
          [disabled]="true"
          [firstOption]="{
              title: TemperatureUnitEnum.toTranslateKey(TemperatureUnitEnum.Celsius),
              value: TemperatureUnitEnum.Celsius,
            }"
          [secondOption]="{
              title: TemperatureUnitEnum.toTranslateKey(TemperatureUnitEnum.Fahrenheit),
              value: TemperatureUnitEnum.Fahrenheit,
            }"
          [notFormValue]="temperatureUnit">
        </lv-dual-toggle>
      </span>
    </div>
  </div>
</form>
