import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NgControl } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { QaTagsDirective } from '@livestock/shared/directives';
import { GlobalConstants } from '@livestock/shared/constants';
import { filter, firstValueFrom, Subscription } from 'rxjs';
import { Store } from '@ngrx/store';
import { LanguageService, PlatformService } from '@livestock/shared/services';
import { SleepUtils } from '@livestock/shared/utils';
import { ColorsEnum } from '@livestock/shared/enums';
import { NativeElementInjectorDirective } from '../native-element.directive';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import {
  setVirtualKeyboardElementUuid,
  setVirtualKeyboardMode,
  setVirtualKeyboardRanges,
  selectVirtualKeyboardElementUUID,
  selectVirtualKeyboardSymbolAndUUID,
  VirtualKeyboardModesEnum,
  VirtualKeyboardButtonsEnum, setVirtualKeyboardSymbol, clearVirtualKeyboardElementUuid,
} from '@livestock/ui';
import { VirtualKeyboardConstants } from '../virtual-keyboard/virtual-keyboard.constants';

const invalidFormat = RegExp(/(?!^[-])\D/gm);

@Component({
  standalone: true,
  imports: [
    CommonModule,
    QaTagsDirective,
    NativeElementInjectorDirective,
  ],
  selector: 'ls-input-integer',
  templateUrl: 'input-integer.component.html',
  styleUrls: ['input-integer.component.scss'],
})

export class InputIntegerComponent implements ControlValueAccessor, AfterViewInit, OnInit, AfterViewChecked, OnDestroy {
  @ViewChild('input') inputRef: ElementRef;
  @ViewChild('valueLengthSpan') valueLengthSpan: ElementRef;
  @ViewChild('content', { static: false }) content: ElementRef = new ElementRef(null);
  @Input() placeholder = '';
  @Input() noBorder = false;
  @Input() noPaddings = false;
  @Input() noMaxWidth = false;
  @Input() min: number = 1;
  @Input() max: number = GlobalConstants.SMALLINT_MAX;
  @Input() default: number;
  @Input() readonly: boolean;
  @Input() forceDisabled: boolean;
  @Input() normalizeValues: boolean;
  @Input() step: number = 1;
  @Input() formControlName: string;
  @Input() textColor: ColorsEnum = ColorsEnum.Default;
  @Input() extraPadding = 5; // extra padding 5px so unit will not stick with value
  @Input() enterKeyHint: string;
  @Input() showRangesOnKeyboard: string;
  @Input() labelForRanges: string = VirtualKeyboardConstants.DefaultRangeLabel;
  @Input() blueBorder: boolean;

  //TODO: remove in future
  @Input() fieldWithKeyboard: boolean;

  @Output() change = new EventEmitter<number>();
  @Output() onFocusOut = new EventEmitter();
  @Output() onFocusIn = new EventEmitter();

  value: number;
  isDisabled: boolean;
  isContent = false;
  sub$ = new Subscription();

  get defaultValue(): number {
    return this.default || (this.min > 0 ? this.min : 0);
  }

  /*for units positioning*/
  valueLengthPx: number; //length of current value in input in px
  inputPadding: number; //padding left/right of padding
  viewInitiated: boolean; // flag when all element refs will be initiated
  textPositioning: string; // input text positioning

  @HostListener('focusin', ['$event'])
  onInputClick(): void {
    if (this.platformService.isDeviceApp) {
      this.store.dispatch(setVirtualKeyboardElementUuid({
        elementUuid: this.inputRef.nativeElement.getAttribute('uuid'),
      }));

      const mode = this.min < 0
        ? VirtualKeyboardModesEnum.NumericNegative
        : VirtualKeyboardModesEnum.Numeric;
      this.store.dispatch(setVirtualKeyboardMode({ mode }));

      if (this.showRangesOnKeyboard) {
        this.store.dispatch(setVirtualKeyboardRanges({
          labelForRanges: this.labelForRanges,
          ranges: {
            min: this.min,
            max: this.max,
          },
        }));
      }

    }

    this.onFocusIn.emit();
  }

  // hide keyboard if clicked outside input
  @HostListener('focusout')
  async unsetFormControl(): Promise<void> {
    if (!this.platformService.isDeviceApp) return;

    await SleepUtils.sleep(100);
    const activeFormControl = await firstValueFrom(this.store.select(selectVirtualKeyboardElementUUID));
    if (activeFormControl === this.inputRef.nativeElement.getAttribute('uuid')) {
      this.store.dispatch(clearVirtualKeyboardElementUuid());
    }
  }

  @HostListener('paste', ['$event'])
  onPaste(e: ClipboardEvent): void {
    if (this.isDisabled) return;
    if (this.value === 0) {
      const target = e.target as HTMLInputElement;
      e.preventDefault();
      e.stopPropagation();
      target.value = e.clipboardData.getData('text');
      const parsedValue = isNaN(parseInt(target.value)) ? 0 : parseInt(target.value);
      this.value = this.min >= 0 && parsedValue < 0 ? 0 : parsedValue;
      this.updateInputValue();
      this.valueChange();
    }
  }

  @HostListener('input', ['$event'])
  onInput(e: InputEvent): void {
    if (this.isDisabled) return;
    const target: HTMLInputElement = e.target as HTMLInputElement;
    let { selectionEnd } = target;

    if (target.value == VirtualKeyboardButtonsEnum.MINUS) {
      if (this.min < 0 && this.value == null) {
        this.inputRef.nativeElement.value = VirtualKeyboardButtonsEnum.MINUS;
        return;
      }
    }

    if (this.min >= 0 && target.value.indexOf(VirtualKeyboardButtonsEnum.MINUS) !== -1) {
      target.value = String(this.toPositive(target.valueAsNumber));
    }
    if (this.value === 0 && e.inputType === 'insertText') {
      target.value = this.min < 0 ? target.value : e.data;
    }
    if (invalidFormat.test(target.value) === true) {
      target.value = target.value.replace(invalidFormat, '');
    }
    if (isNaN(parseInt(target.value))) {
      this.value = null;
      this.updateInputValue();
      return;
    }

    const result = parseInt(target.value);
    this.value = this.normalizeValues
      ? this.valueNormalize(result)
      : result;
    this.updateInputValue();
    this.valueChange();
    setTimeout(() => {
      // e.data == null - we delete symbol
      if (e.data != null) {
        const valueLength = this.value?.toString().length;
        const countOfCommas = Math.trunc((valueLength - 1) / 3);
        selectionEnd = selectionEnd + countOfCommas;
      }

      this.inputRef.nativeElement.setSelectionRange(selectionEnd, selectionEnd);
    }, 1);
  }

  printSymbolFromVirtualKeyboard(symbol: string): void {
    const { selectionStart, selectionEnd, value: inputValue } = this.inputRef.nativeElement;
    const isSingleDeletion = selectionStart === selectionEnd
      && symbol === VirtualKeyboardButtonsEnum.DEL
      && selectionStart > 0;
    const isMultipleDeletion = symbol === VirtualKeyboardButtonsEnum.DEL && selectionStart !== selectionEnd;

    // change DEL to empty string to avoid collisions
    // TODO: think about changing 'DEL' to empty string in enum
    const updatedSymbol = symbol === VirtualKeyboardButtonsEnum.DEL ? '' : symbol;

    if (Number.isInteger(updatedSymbol)) {
      if (this.value?.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').length
        >= this.max.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').length) return;
    }

    let currentValue;
    if (this.value === 0 && updatedSymbol === VirtualKeyboardButtonsEnum.PLUS) return;

    if (this.value) {
      if (updatedSymbol === VirtualKeyboardButtonsEnum.PLUS) {
        if (String(this.value) === VirtualKeyboardButtonsEnum.MINUS) this.value = 0;
        this.value = Math.abs(+this.value);
        this.valueChange();
        this.updateInputValue();
        return;
      }

      if (updatedSymbol === VirtualKeyboardButtonsEnum.MINUS) {
        if (this.value === 0) return;
        if (String(this.value) === VirtualKeyboardButtonsEnum.MINUS) return;
        this.value = this.value * -1;
        this.valueChange();
        this.updateInputValue();
        return;
      }

      currentValue = inputValue.toString().split('');

      if (isSingleDeletion) {
        // remove the symbol before the cursor
        if (currentValue.length === 2 && currentValue.includes(VirtualKeyboardButtonsEnum.MINUS)) {
          currentValue.splice(0, currentValue.length);
        }

        currentValue.splice(selectionStart - 1, 1);
      } else {
        // add symbol in the right place or remove multiple symbols
        currentValue.splice(selectionStart, selectionEnd - selectionStart, updatedSymbol as string);
      }
    } else {
      currentValue = `${updatedSymbol}`.split('');
    }

    const joinedValue = currentValue.filter(symbol => symbol !== ',').join('');
    const parsedValue = !isNaN(+joinedValue) ? +joinedValue : joinedValue;

    if (this.isLengthValid(parsedValue)) {
      this.value = parsedValue;
      this.updateInputValue();

      if (!isNaN(+parsedValue)) {
        this.valueChange();
      }
    }

    // TODO: refactor for big numbers (>= 1,000,000) and negative numbers (<= -1,000)
    const currentInputValue = this.inputRef.nativeElement.value;
    const commaWasAddedOrDeleted = Math.abs(currentInputValue.length - inputValue.length) > 1;
    const commaIndex = currentInputValue.indexOf(',');

    // set cursor for prev place for single deletion
    if (isSingleDeletion) {
      // jump 2 indexes if commas was deleted
      if (commaWasAddedOrDeleted && commaIndex === -1 && selectionStart > commaIndex) {
        this.inputRef.nativeElement.selectionStart = selectionStart - 2;
        this.inputRef.nativeElement.selectionEnd = selectionStart - 2;
        return;
      }

      this.inputRef.nativeElement.selectionStart = selectionStart - 1;
      this.inputRef.nativeElement.selectionEnd = selectionStart - 1;
      return;
    }

    // do not move cursor for multiple deletion
    if (isMultipleDeletion) {
      this.inputRef.nativeElement.selectionStart = selectionStart;
      this.inputRef.nativeElement.selectionEnd = selectionStart;
      return;
    }

    // set cursor on next place after adding new symbol (don't forget about commas)
    // jump 2 indexes if commas was added
    if (commaWasAddedOrDeleted && commaIndex > 0 && selectionStart > commaIndex) {
      this.inputRef.nativeElement.selectionStart = selectionStart + 2;
      this.inputRef.nativeElement.selectionEnd = selectionStart + 2;
      return;
    }

    this.inputRef.nativeElement.selectionStart = selectionStart + 1;
    this.inputRef.nativeElement.selectionEnd = selectionStart + 1;
  }

  constructor(
    public languageService: LanguageService,
    protected store: Store,
    protected platformService: PlatformService,
    protected control: NgControl,
  ) {
    control.valueAccessor = this;
  }

  ngOnInit(): void {
    if (this.platformService.isDeviceApp) {
      this.sub$.add(
        this.store.select(selectVirtualKeyboardElementUUID).pipe(
          wasChanged(),
          filter(res => res == null),
        ).subscribe(() => {
          this.inputRef?.nativeElement.blur();
        }),
      );

      this.sub$.add(
        this.store.select(selectVirtualKeyboardElementUUID).pipe(
          wasChanged(),
          filter((uuid) => uuid === this.inputRef?.nativeElement.getAttribute('uuid')),
        ).subscribe(() => {
          this.inputRef.nativeElement.focus();
          this.store.dispatch(setVirtualKeyboardMode({ mode: VirtualKeyboardModesEnum.Numeric }));

          if (this.showRangesOnKeyboard) {
            this.store.dispatch(setVirtualKeyboardRanges({
              labelForRanges: this.labelForRanges,
              ranges: {
                min: this.min,
                max: this.max,
              },
            }));
          }
        }),
      );

      this.sub$.add(
        this.store.select(selectVirtualKeyboardSymbolAndUUID).pipe(
          wasChanged(),
          filter(({ symbol, elementUuid }) => {
            return symbol != null && elementUuid === this.inputRef?.nativeElement.getAttribute('uuid');
          }),
        ).subscribe(({ symbol }) => {
          this.printSymbolFromVirtualKeyboard(symbol);
          this.store.dispatch(setVirtualKeyboardSymbol({ symbol: null }));
        }),
      );
    }
  }

  get isRtl(): boolean {
    return this.languageService.isRtl;
  }

  onBlur(): void {
    if (this.value as unknown as string === VirtualKeyboardButtonsEnum.MINUS) {
      this.value = 0;
      this.updateInputValue();
      this.valueChange();
      return;
    }

    if (['', VirtualKeyboardButtonsEnum.MINUS].includes(this.inputRef.nativeElement.value) === false) return;
    this.value = this.valueNormalize(this.value) || this.defaultValue;
    this.updateInputValue();
    this.valueChange(this.value);
  }

  onFocusOutEvent(): void {
    this.onFocusOut.emit();
  }

  valueNormalize(num: number): number {
    if (num < this.min) return this.min;
    if (num > this.max) return this.max;
    return num;
  }

  toNegative(n: number): number {
    return Math.sign(n) === 0 ? -1 : -Math.abs(n);
  }

  toPositive(n: number): number {
    return Math.sign(n) === 0 ? 1 : Math.abs(n);
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.viewInitiated = true;
      this.isContent = this.content.nativeElement?.children?.length > 0;
    });

    this.setInputPadding();

    //done to place suffix correctly on resize
    const observer = new ResizeObserver(() => {
      this.setInputPadding();
    });

    observer.observe(this.inputRef.nativeElement);
  }

  async setInputPadding(): Promise<void> {
    // calculating input padding
    const computedStyle = window.getComputedStyle(this.inputRef.nativeElement, null);
    this.textPositioning = computedStyle.getPropertyValue('text-align');

    // for text-align center we calculate HALF of input width + HALF of value width,
    // for left: input padding + value width
    this.inputPadding = this.textPositioning === 'center'
      ? +computedStyle.getPropertyValue('width').replace('px', '') / 2
      : +computedStyle.getPropertyValue('padding-left').replace('px', '');

    this.updateInputValue();
  }

  ngAfterViewChecked(): void {
    if (this.normalizeValues) return;
    const maxLength = this.max?.toString().length;
    const countOfCommas = Math.trunc((maxLength - 1) / 3);
    (this.inputRef.nativeElement as HTMLInputElement).maxLength = this.min < 0
      ? maxLength + countOfCommas + 1
      : (maxLength + countOfCommas) || 7;
  }

  updateInputValue(value?: string | number): void {
    if (!this.inputRef) return;

    setTimeout(() => {
      this.valueLengthPx = this.textPositioning === 'center'
        ? this.valueLengthSpan?.nativeElement.offsetWidth / 2
        : this.valueLengthSpan?.nativeElement.offsetWidth;
    });

    this.inputRef.nativeElement.value = value ?? this.numberWithCommas(this.value) ?? '';
  }

  numberWithCommas(x: number): string {
    return x?.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '';
  }

  setInputValueToNull(): void {
    this.inputRef.nativeElement.value = null;
  }

  setDisabledState?(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

  valueChange(value?): void {
    const newValue = value || this.value;
    this.onChange(newValue);
    this.change.emit(newValue);
  }

  writeValue(value: number): void {
    if (this.value === value) return;

    if (value === null) {
      this.value = null;
      if (!this.inputRef) return;
      this.setInputValueToNull();
      return;
    }

    this.value = value;
    this.updateInputValue();
  }

  registerOnChange(
    onChange: (value: number | string) => void,
  ): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }

  private isLengthValid(value: number): boolean {
    const valueLength = value.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').length;
    if (value.toString().includes(VirtualKeyboardButtonsEnum.MINUS)) {
      return valueLength <= this.min.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').length;
    }

    return valueLength <= this.max.toString().replace(VirtualKeyboardButtonsEnum.MINUS, '').length;
  }

  private onTouched = (): void => {
  };
  private onChange: (
    value: number | string,
  ) => void = () => {
  };
}

