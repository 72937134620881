import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  catchError,
  exhaustMap,
  filter,
  map,
  of,
  switchMap,
  withLatestFrom,
} from 'rxjs';
import * as farmActions from './farms.actions';
import * as farmSelectors from './farms.selectors';
import { selectActiveFarmID } from './farms.selectors';
import { Store } from '@ngrx/store';
import { ICreateFarm } from '../interfaces/create-farm.interface';
import { IFarmInfo } from '../interfaces/farm-info.interface';
import { IUpdateFarm } from '../interfaces/update-farm.interface';
import { FarmsService } from '../services/farms.service';
import { IInvitedUser, IUser } from '@livestock/shared/interfaces';
import {
  FlashMessageTypeEnum,
  setFlashMessage,
} from '@livestock/notifications';
import { IUserFromInvite } from '../../../../shared/interfaces/users/user-from-invite.interface';
import { Router } from '@angular/router';
import { AppRoutes } from '@livestock/shared/routes';
import { ControllersService } from 'libs/controllers/src/lib/services/controllers.service';
import { LocalStorageService } from '@livestock/shared/services';
import { StorageItem } from '@livestock/shared/enums';

@Injectable()
export class FarmsEffects {
  getFarms$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarms),
      withLatestFrom(this.store.select(farmSelectors.selectFarms)),
      filter(([_, farms]) => farms == null || farms?.length == 0),
      exhaustMap(() => {
        return this.farmsService.getFarms().pipe(
          map(({ farms }) =>
            farmActions.getFarmsSuccess({ farms }),
          ),
          catchError((error) =>
            of(farmActions.getFarmsError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmById),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmById(farmID).pipe(
          map((farm) => farmActions.getFarmByIdSuccess({ farm })),
          catchError((error) =>
            of(farmActions.getFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  createFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.createFarm),
      map((action) => action.farm),
      switchMap((farm: ICreateFarm) => {
        return this.farmsService.createFarm(farm).pipe(
          switchMap((farm: IFarmInfo) => {
            return [
              farmActions.createFarmSuccess({ farm }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FarmWasSuccessfullyCreated',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.createFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  updateFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.updateFarmById),
      map((action): [number, IUpdateFarm] => [action.farmID, action.farm]),
      switchMap(([farmID, farm]: [number, IUpdateFarm]) => {
        return this.farmsService.updateFarmById(farmID, farm).pipe(
          switchMap(_ => {
            return [
              farmActions.updateFarmByIdSuccess({ farmID, farm }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FarmWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.updateFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  deleteFarmById$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteFarmById),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.deleteFarmById(farmID).pipe(
          switchMap(() => {
            return [
              farmActions.deleteFarmByIdSuccess({ farmID }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.FarmWasSuccessfullyDeleted',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmByIdError({ payload: error })),
          ),
        );
      }),
    ),
  );

  getFarmControllers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmControllers),
      map(action => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmControllers(farmID).pipe(
          map(({ controllers }) =>
            farmActions.getFarmControllersSuccess({ farmID, controllers }),
          ),
          catchError((error) => {
            this.router.navigate([AppRoutes.FARMS]);
            return of(farmActions.getFarmControllersError({ payload: error }));
          }),
        );
      }),
    ),
  );

  deleteFarmController$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteControllerFromFarm),
      map(deleteControllerFromFarm => deleteControllerFromFarm.controllerID),
      switchMap(controllerID => {
        return this.controllersService.deleteController(controllerID).pipe(
          switchMap(() => [
            farmActions.deleteControllerFromFarmSuccess({ controllerID }),
            setFlashMessage({
              flashMessage: {
                flashType: FlashMessageTypeEnum.Success,
                message: 'FlashMessages.ControllerDeleteSuccess',
              },
            }),
          ]),
          catchError(error => {
            return of(farmActions.deleteControllerFromFarmError({ payload: error }));
          }),
        );
      }),
    ),
  );

  getFarmUsers$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.getFarmUsers),
      map((action) => action.farmID),
      switchMap((farmID: number) => {
        return this.farmsService.getFarmUsers(farmID).pipe(
          map(({ users }) =>
            farmActions.getFarmUsersSuccess({ farmID, users }),
          ),
          catchError((error) => {
            this.router.navigate([AppRoutes.FARMS]);
            return of(farmActions.getFarmUsersError({ payload: error }));
          }),
        );
      }),
    ),
  );

  inviteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.inviteFarmUser),
      map((action) => action.invitedUser),
      withLatestFrom(this.store.select(selectActiveFarmID)),
      switchMap(([invitedUser, farmID]: [IInvitedUser, number]) => {
        return this.farmsService.inviteFarmUser(farmID, invitedUser).pipe(
          switchMap((user) => {
            return [
              farmActions.inviteFarmUserSuccess({ farmID, user }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyInvited',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.inviteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  resendInviteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.resendInviteFarmUser),
      map((action) => action.email),
      withLatestFrom(this.store.select(selectActiveFarmID)),
      switchMap(([email, farmID]: [string, number]) => {
        return this.farmsService.resendInviteFarmUser(farmID || +LocalStorageService.getStorageItem(StorageItem.ActiveFarmID), email).pipe(
          switchMap(({ resendDate }) => {
            return [
              farmActions.resendInviteFarmUserSuccess({
                farmID,
                email,
                resendDate,
              }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.TheInvitationWasSuccessfullyResent',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.inviteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  deleteFarmUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.deleteFarmUser),
      map((action): [number, string] => [action.farmID, action.email]),
      switchMap(([farmID, email]: [number, string]) => {
        return this.farmsService.deleteFarmUser(farmID, email).pipe(
          switchMap(() => {
            return [
              farmActions.deleteFarmUserSuccess({ farmID, email }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyDeleted',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  updateUser$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.updateUser),
      map((action) => action.user),
      switchMap((user: IUser) => {
        return this.farmsService.updateUser(user).pipe(
          switchMap(() => {
            return [
              farmActions.updateUserSuccess({ user }),
              setFlashMessage({
                flashMessage: {
                  flashType: FlashMessageTypeEnum.Success,
                  message: 'FlashMessages.UserWasSuccessfullyUpdated',
                },
              }),
            ];
          }),
          catchError((error) =>
            of(farmActions.deleteFarmUserError({ payload: error })),
          ),
        );
      }),
    ),
  );

  /*Tickets*/
  acceptExistUserToFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.acceptExistUserToFarm),
      map((action) => action.ticketID),
      switchMap((ticketID: string) => {
        return this.farmsService.acceptExistUserToFarm(ticketID).pipe(
          map(() => farmActions.acceptExistUserToFarmSuccess()),
          catchError((error) =>
            of(farmActions.acceptExistUserToFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  declineExistUserToFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.declineExistUserToFarm),
      map((action) => action.ticketID),
      switchMap((ticketID: string) => {
        return this.farmsService.declineExistUserToFarm(ticketID).pipe(
          map(() => farmActions.declineExistUserToFarmSuccess()),
          catchError((error) =>
            of(farmActions.declineExistUserToFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  addNewUserToFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.addNewUserToFarm),
      map((action) => action.ticketID),
      switchMap((ticketID: string) => {
        return this.farmsService.addNewUserToFarm(ticketID).pipe(
          map(({ farmName }) =>
            farmActions.addNewUserToFarmSuccess({ farmName }),
          ),
          catchError((error) =>
            of(farmActions.addNewUserToFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  executeAddNewUserToFarm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(farmActions.executeAddNewUserToFarm),
      map((action) => action.userFromInvite),
      switchMap((userFromInvite: IUserFromInvite) => {
        return this.farmsService.executeAddNewUserToFarm(userFromInvite).pipe(
          map(() => farmActions.executeAddNewUserToFarmSuccess()),
          catchError((error) =>
            of(farmActions.executeAddNewUserToFarmError({ payload: error })),
          ),
        );
      }),
    ),
  );

  constructor(
    private store: Store,
    private router: Router,
    private actions$: Actions,
    private farmsService: FarmsService,
    private controllersService: ControllersService,
  ) {
  }
}
