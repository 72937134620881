<div
  [formGroupName]="formGroupName"
  class="device-form">
  <div class="device-form__field mb-20">
    <label qaTags="qa-label-name">{{ "Installation.Main.DeviceName" | translate }}</label>

    <div class="d-flex align-center form-field">
      <ls-input-text
        [fieldWithKeyboard]="true"
        [maxLength]="ElementConstants.MaxNameLength"
        [qaTags]="'qa-txt-name'"
        errorField
        formControlName="name"></ls-input-text>
    </div>
  </div>

  <div class="device-form__field mb-20">
    <label [qaTags]="'qa-lbl-offset'"> {{ "Installation.Card.OutdoorHumidity.Offset" | translate }}</label>
    <div class="d-flex align-center">
      <ls-input-integer
        [fieldWithKeyboard]="true"
        [max]="ElementsValidationConstants.outdoorHumidity.offset.max"
        [min]="ElementsValidationConstants.outdoorHumidity.offset.min"
        [qaTags]="'qa-inp-offset'"
        [validateWithInitValue]="true"
        errorField
        formControlName="offset">
      </ls-input-integer>
      <span class="unit ml-5">{{ GlobalConstants.PercentageSymbol }}</span>
    </div>
  </div>
  <div class="device-form__field device-form__field-margin">
    <label [qaTags]="'qa-lbl-enabled'"> {{ "Enabled" | translate }}</label>
    <ls-toggle
      [offTitle]="'Buttons.No'"
      [onTitle]="'Buttons.Yes'"
      [qaTags]="'qa-tgl-enabled'"
      [size]="'large'"
      formControlName="enabled" />
  </div>
</div>
