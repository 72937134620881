import { Component } from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { TempMappingCommonComponent } from '../common/temp-mapping-common.component';
import { DialogsService } from '@livestock/shared/services';
import { CommonModule } from '@angular/common';
import {
  ButtonComponent,
  HeaderTitleComponent,
  LoadingComponent,
  SensorsKeyboardComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { TempMappingHelpersService } from '../../services/temp-mapping-helpers.service';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-temp-mapping-form',
  templateUrl: 'temp-mapping-form.component.html',
  styleUrls: ['temp-mapping-form.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    HeaderTitleComponent,
    TranslateModule,
    SvgIconComponent,
    MemoizeFuncPipe,
    SensorsKeyboardComponent,
    ButtonComponent,
    MatCheckboxModule,
    LoadingComponent,
    QaTagsDirective,
  ],
})
export class TempMappingFormComponent extends TempMappingCommonComponent {
  // eslint-disable-next-line @typescript-eslint/no-useless-constructor
  constructor(
    store: Store,
    translateService: TranslateService,
    dialogsService: DialogsService,
    tempMappingHelpersService: TempMappingHelpersService,
  ) {
    super(store, translateService, dialogsService, tempMappingHelpersService);
  }
}
