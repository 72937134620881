import { AfterViewInit, Directive, ElementRef, Optional } from '@angular/core';
import { NgControl, NgModel } from '@angular/forms';
import { v4 } from 'uuid';

@Directive({
  standalone: true,
  //selector doesnt penetrate lib tags so ngx-mat-timepicker is set with native js
  // selector: 'input, ngx-mat-timepicker, ls-program-device-buttons',
  selector: 'input',
})
export class NativeElementInjectorDirective implements AfterViewInit {
  constructor(
    private el: ElementRef,
    @Optional() private control: NgControl,
    @Optional() private model: NgModel,
  ) {
  }

  ngAfterViewInit(): void {
    const id = this.getId();

    (this.el.nativeElement as HTMLElement)?.setAttribute('uuid', id);

    if (this.model) {
      (<any>this.model.control).uuid = id;
      return;
    }

    if (this.control) {
      (<any>this.control).uuid = id;
    }
  }

  // ngAfterViewInit(): void {
  //     const id = this.getId();
  //     if (this.el.nativeElement.tagName === 'NGX-MAT-TIMEPICKER') {
  //         Array.from(this.el.nativeElement.querySelectorAll('input')).forEach(input => {
  //             const id = this.getId();
  //             (input as HTMLElement).setAttribute('uuid', id);
  //         });
  //     } else if (this.el.nativeElement.tagName === 'LS-PROGRAM-DEVICE-BUTTONS') {
  //         Array.from(this.el.nativeElement.querySelectorAll('button.device-button')).forEach(input => {
  //             const id = this.getId();
  //             (input as HTMLElement).setAttribute('uuid', id);
  //         });
  //     } else {
  //         (this.el.nativeElement as HTMLElement)?.setAttribute('uuid', id);
  //     }
  //
  //     if (this.model) {
  //         (<any>this.model.control).uuid = id;
  //     }
  //     else if (this.control) {
  //         (<any>this.control).uuid = id;
  //     }
  // }

  getId(): string {
    // Q added, because if UD starts with number JS will not find if
    return `Q${v4()}`;
  }
}
