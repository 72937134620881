import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, map, of, switchMap, withLatestFrom } from 'rxjs';
import { Store } from '@ngrx/store';
import * as heatingProgramActions from './heating-program.actions';
import * as heatingProgramSelectors from './heating-program.selectors';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { HeatingProgramService, IGetHeatingProgramView, IHeatingProgramView } from '@livestock/heating-program';
import { selectCurrentControllerTemperatureUnit } from '@livestock/controllers';
import { TemperatureUnitEnum } from '@livestock/shared/enums';

@Injectable()
export class HeatingProgramEffects {

    getHeatingPrograms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(heatingProgramActions.getHeatingPrograms),
            map((action) => action.controllerID),
            withLatestFrom(this.store.select(selectCurrentControllerTemperatureUnit)),
            switchMap(([controllerID, temperatureUnit]: [number, TemperatureUnitEnum]) => {
                return this.heatingProgramService.getHeatingPrograms(controllerID, temperatureUnit).pipe(
                    map((view: IGetHeatingProgramView) =>
                        heatingProgramActions.getHeatingProgramsSuccess({ view })),
                    catchError((error) => of(heatingProgramActions.getHeatingProgramsError({ payload: error }))),
                );
            }),
        ),
    );

    updateHeatingPrograms$ = createEffect(() =>
        this.actions$.pipe(
            ofType(heatingProgramActions.updateHeatingPrograms),
            map((action) => action.controllerID),
            withLatestFrom(
                this.store.select(heatingProgramSelectors.selectHeatingPrograms),
                this.store.select(selectCurrentControllerTemperatureUnit),
            ),
            switchMap(([controllerID, birdWeightItems, temperatureUnit]: [number, IHeatingProgramView[], TemperatureUnitEnum]) => {
                return this.heatingProgramService.updateHeatingPrograms(controllerID, birdWeightItems, temperatureUnit).pipe(
                    switchMap((updatedPrograms: IHeatingProgramView[]) => {
                        console.error(updatedPrograms);
                        return [
                            heatingProgramActions.updateHeatingProgramsSuccess({ updatedPrograms }),
                            setFlashMessage({
                                flashMessage: {
                                    flashType: FlashMessageTypeEnum.Success,
                                    message: 'FlashMessages.HeatingProgramsWereSuccessfullyUpdated',
                                },
                            }),
                        ];
                    }),
                    catchError((error) => of(heatingProgramActions.updateHeatingProgramsError({ payload: error }))),
                );
            }),
        ),
    );

    deleteHeatingProgram$ = createEffect(() =>
        this.actions$.pipe(
            ofType(heatingProgramActions.deleteHeatingProgram),
            switchMap(({ controllerID, programID }) => {
                return this.heatingProgramService.deleteHeatingProgram(controllerID, programID).pipe(
                    switchMap(() => {
                        return [
                            heatingProgramActions.deleteHeatingProgramSuccess({ programID }),
                            setFlashMessage({
                                flashMessage: {
                                    flashType: FlashMessageTypeEnum.Success,
                                    message: 'HeatingProgram.DeletedSuccessfully',
                                },
                            }),
                        ];
                    }),
                    catchError((error) => of(heatingProgramActions.deleteHeatingProgramError({ payload: error }))),
                );
            }),
        ),
    );

    constructor(
        private store: Store,
        private actions$: Actions,
        private heatingProgramService: HeatingProgramService,
    ) {
    }
}