import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ICard } from '../interfaces/card.interface';
import { IElementType } from '../interfaces/element/element-type.interface';

export * from './element-settings.actions';
export * from './element-crud.actions';
export * from './element-testing.actions';

export const getControllerCards = createAction(
  '[INSTALLATION] Get Controller Cards [...]',
);

export const getControllerCardsSuccess = createAction(
  '[INSTALLATION] Get Controller Cards [SUCCESS]',
  props<{ cards: ICard[] }>(),
);

export const getControllerCardsError = createAction(
  '[INSTALLATION] Get Controller Cards [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const setCurrentCard = createAction(
  '[INSTALLATION] Set Current Card',
  props<{ cardID: number }>(),
);

export const setCurrentConnection = createAction(
  '[INSTALLATION] Set Current Connection',
  props<{ connectionID: number }>(),
);

// ELEMENT TYPES
export const getCardElementTypes = createAction(
  '[INSTALLATION] Get Card Element Types [...]',
  props<{ cardID: number }>(),
);

export const getCardElementTypesSuccess = createAction(
  '[INSTALLATION] Get Card Element Types [SUCCESS]',
  props<{ elementTypes: IElementType[] }>(),
);

export const getCardElementTypesError = createAction(
  '[INSTALLATION] Get Card Element Types [Error]',
  props<{ error: HttpErrorResponse }>(),
);