import { ColorsEnum, CommonUserRolesEnum, ElementTypesEnum } from '@livestock/shared/enums';

export interface IControllerMenuIcon {
  title: string;
  path: string;
  qaTag: string;
  elementTypes?: ElementTypesEnum[];
  navigateTo?: string;
  allowedUserRoles?: CommonUserRolesEnum[];
  color?: ColorsEnum;
  disabled?: boolean;
  directLink?: boolean;
  showErrorMessage?: boolean;
  isLoadingInfo?: boolean;
  hasWarning?: boolean;
}
