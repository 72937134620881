import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ActivatedRoute } from '@angular/router';
import { Store } from '@ngrx/store';
import { setActiveFarm } from '../../+state/farms.actions';
import { filter, first, Subscription } from 'rxjs';
import { selectFarms } from '../../+state/farms.selectors';

@Component({
  standalone: true,
  imports: [
    CommonModule,
  ],
  selector: 'lv-farm-info',
  templateUrl: './farm-info.component.html',
  styleUrls: ['./farm-info.component.scss'],
})
export class FarmInfoComponent implements OnInit {
  sub$ = new Subscription();
  farmID: number;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
  ) {
  }

  ngOnInit(): void {
    this.activatedRoute.params.subscribe(({ farmID }) => {
      this.farmID = farmID;
      this.store.dispatch(setActiveFarm({ farmID: +farmID }));
    });

    this.store.select(selectFarms).pipe(
      filter(res => res?.length > 0),
      first(),
    ).subscribe(() => {
      this.store.dispatch(setActiveFarm({ farmID: +this.farmID }));
    });
  }
}
