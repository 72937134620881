import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { IGetTempMapping } from '../interfaces/get-temp-mapping.interface';
import { IElement } from '@livestock/installation/interfaces';
import { TempMappingStagesEnum } from '../enums/temp-mapping-stages.enum';

export const getTemperatureMappings = createAction(
  '[TEMPERATURE MAPPING] Get Temperature Mappings [...]',
);

export const getTemperatureMappingsSuccess = createAction(
  '[TEMPERATURE MAPPING] Get Temperature Mappings [SUCCESS]',
  props<{ view: IGetTempMapping }>(),
);

export const getTemperatureMappingsError = createAction(
  '[TEMPERATURE MAPPING] Get Temperature Mappings [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const updateTemperatureMappings = createAction(
  '[TEMPERATURE MAPPING] Update Temperature Mappings [...]',
);

export const updateTemperatureMappingsSuccess = createAction(
  '[TEMPERATURE MAPPING] Update Temperature Mappings [SUCCESS]',
);

export const updateTemperatureMappingsError = createAction(
  '[TEMPERATURE MAPPING] Update Temperature Mappings [ERROR]',
  props<{ error: HttpErrorResponse }>(),
);

export const setActiveElement = createAction(
  '[TEMPERATURE MAPPING] Set Active Element',
  props<{ activeStage: TempMappingStagesEnum, elementID?: number }>(),
);

export const changeActiveElementIsAverage = createAction(
  '[TEMPERATURE MAPPING] Change Active Element Is Average',
  props<{ activeStage: TempMappingStagesEnum, isAverage: boolean, elementID?: number }>(),
);

export const addSensorsToTempMappingElement = createAction(
  '[TEMPERATURE MAPPING] Add Sensor To Temp Mapping Element',
  props<{ sensors: IElement[], activeStage: TempMappingStagesEnum, elementID?: number }>(),
);

export const removeLastSensorFromTempMappingElement = createAction(
  '[TEMPERATURE MAPPING] Remove Last Sensor From Temp Mapping Element',
  props<{ activeStage: TempMappingStagesEnum, elementID?: number }>(),
);

export const addBrooding2Stage = createAction(
  '[TEMPERATURE MAPPING] Add Brooding 2 Stage',
);

export const removeBrooding2Stage = createAction(
  '[TEMPERATURE MAPPING] Remove Brooding 2 Stage',
);

export const moveElement = createAction(
  '[TEMPERATURE MAPPING] Move Element',
  props<{
    activeStage: TempMappingStagesEnum,
    destinationStage: TempMappingStagesEnum,
    elementID?: number,
    zoneID?: number
  }>(),
);

export const resetTemperatureMappings = createAction(
  '[TEMPERATURE MAPPING] Reset Temperature Mappings',
);
