import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { KeyboardModeEnum } from '@livestock/ui';

@Component({
  selector: 'ls-heating-do-testing-form',
  templateUrl: './heating-do-testing-form.component.html',
  styleUrls: [
    '../../cooling-device/cooling-testing-form/cooling-testing-form.component.scss',
    './heating-do-testing-form.component.scss',
  ],
})
export class HeatingDOTestingFormComponent implements OnInit {
  form: FormGroup;
  KeyboardModeEnum = KeyboardModeEnum;

  ngOnInit(): void {
    this.form = new FormGroup({
      time: new FormControl(''),
    });
  }
}

