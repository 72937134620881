<div class="widget-wrapper position-relative">
  <div class="d-flex align-center justify-between temperature-top align-start-desktop">
    <h6
      [qaTags]="'qa-lbl-widget-title qa-lbl-temp-temp'"
      class="widget-title">
      Temperature
    </h6>
    <div class="temperature-offset">
      <div class="d-flex flex-column">
        <div class="widget-content__value fz-24">-2<span class="fz-16">°C</span></div>
        <span class="widget-content__subtitle">Offset</span>
      </div>
    </div>
  </div>

  <div class="widget-body d-flex">
    <div class="widget-icon mr-25">
      <ls-svg-icon
        [color]="ColorsEnum.BlueDefault"
        [hasMargin]="true"
        [qaTags]="'qa-img-widget qa-img-temp'"
        path="dashboard/temperature"></ls-svg-icon>
    </div>

    <div class="widget-content pt-20 pb-10 position-absolute left-50-percent">
      <div class="temperature-ark position-relative">
        <div class="temperature-arrow position-absolute"></div>
        <div
          [qaTags]="'qa-lbl-temp-units'"
          class="temperature-value left-50-percent bottom-0 fz-40 fz-32-desktop fz-24-mobile position-absolute">
          23.2<span class="fz-24 fz-16-desktop fz-20-mobile">°C</span>
        </div>

        <div class="temperature-icons">
          <div class="temperature-icon temperature-icon__alarm-low">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              22.2<span>°C</span>
            </div>
            <img
              [qaTags]="'qa-img-temp-alarm'"
              alt="alarm"
              src="/assets/icons/alarms/alarm-blue.svg" />
          </div>
          <div class="temperature-icon temperature-icon__settings">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              25.1<span>°C</span>
            </div>
            <img
              alt="settings"
              src="/assets/icons/dashboard/temperature/settings.svg" />
          </div>
          <div class="temperature-icon temperature-icon__s-icon">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              26.2<span>°C</span>
            </div>
            <img
              [qaTags]="'qa-img-temp-vent'"
              alt="s-icon"
              src="/assets/icons/dashboard/temperature/s-icon.svg" />
          </div>
          <div class="temperature-icon temperature-icon__t-icon row-reverse">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              28.1<span>°C</span>
            </div>
            <img
              [qaTags]="'qa-img-temp-heat'"
              alt="t-icon"
              src="/assets/icons/dashboard/temperature/t-icon.svg" />
          </div>
          <div class="temperature-icon temperature-icon__snowflake row-reverse">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              29.1<span>°C</span>
            </div>
            <img
              [qaTags]="'qa-img-temp-status'"
              alt="snowflake"
              src="/assets/icons/dashboard/temperature/snowflake.svg" />
          </div>
          <div class="temperature-icon temperature-icon__alarm-high row-reverse">
            <div
              [qaTags]="'qa-lbl-temp-units'"
              class="temperature-icon__value">
              32.0<span>°C</span>
            </div>
            <img
              alt="alarm"
              src="/assets/icons/alarms/alarm-blue.svg" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
