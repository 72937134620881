import { AbstractControl, ValidationErrors } from '@angular/forms';
import { IFlockHouseModeView } from '@livestock/flock';

export const MaxMustBeGreaterThanMin = (control: AbstractControl<IFlockHouseModeView>): ValidationErrors => {
    const { value } = control;
    const maxMustBeGreaterThanMinEmpty = value.maximumVentilationEmpty < value.minimumVentilationEmpty;
    const maxMustBeGreaterThanMinPreheat = value.maximumVentilationPreheat < value.minimumVentilationPreheat;
    const maxMustBeGreaterThanMinCleaning = value.maximumVentilationCleaning < value.minimumVentilationCleaning;
    const maxMustBeGreaterThanMinCatching = value.maximumVentilationCatching < value.minimumVentilationCatching;

    if (maxMustBeGreaterThanMinEmpty || maxMustBeGreaterThanMinPreheat ||
        maxMustBeGreaterThanMinCleaning || maxMustBeGreaterThanMinCatching) {
        return {
            maxMustBeGreaterThanMinEmpty,
            maxMustBeGreaterThanMinPreheat,
            maxMustBeGreaterThanMinCleaning,
            maxMustBeGreaterThanMinCatching,
        };
    }

    return null;
};

export const RangesMaxMustBeGreaterThanMin = (control: AbstractControl): ValidationErrors => {
    const { value } = control;
    const maxMustBeGreaterThanMinEmpty = value.ventilationEmpty.max <= value.ventilationEmpty.min;
    const maxMustBeGreaterThanMinPreheat = value.ventilationPreheat.max <= value.ventilationPreheat.min;
    const maxMustBeGreaterThanMinCleaning = value.ventilationCleaning.max <= value.ventilationCleaning.min;
    const maxMustBeGreaterThanMinCatching = value.ventilationCatching.max <= value.ventilationCatching.min;

    if (maxMustBeGreaterThanMinEmpty || maxMustBeGreaterThanMinPreheat ||
        maxMustBeGreaterThanMinCleaning || maxMustBeGreaterThanMinCatching) {
        return {
            maxMustBeGreaterThanMinEmpty,
            maxMustBeGreaterThanMinPreheat,
            maxMustBeGreaterThanMinCleaning,
            maxMustBeGreaterThanMinCatching,
        };
    }

    return null;
};