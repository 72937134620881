<div
  *ngIf="!isLoading && form; else loading"
  [formGroup]="form"
  [class.disabled]="!editMode"
  class="settings d-flex flex-column">
  <div class="settings__container">
    <div class="d-flex flex-column mb-20">
      <label>{{ "AirTreatment.AirTreatment" | translate }}</label>
      <ls-toggle
        [qaTags]="'qa-tgl-enabled'"
        [size]="'large'"
        formControlName="airTreatmentEnabled" />
    </div>

    <div *ngIf="form.get('airTreatmentEnabled').value == true">
      <label class="label mb-20">{{ "AirTreatment.Settings.TreatmentBy" | translate }}</label>
      <ng-select
        [qaTags]="'qa-ddl-treatment-by'"
        [clearable]="false"
        [dropdownPosition]="'bottom'"
        [searchable]="false"
        formControlName="treatmentBy">
        <ng-container>
          <ng-option
            *ngFor="let el of TreatmentByEnum | enumToArray"
            [value]="el">
            {{ el | enum : "treatment-enum" | translate }}
          </ng-option>
        </ng-container>
      </ng-select>

      <div class="d-flex flex-row flex-column-md">
        <div class="d-flex flex-column">
          <div class="mr-20 d-flex flex-column">
            <label class="label label__bold mt-30">{{ "AirTreatment.Settings.ExtraVentilation" | translate }}</label>
            <div class="settings-field form-field mb-20">
              <label>{{ "AirTreatment.Settings.MaxForTreatmentDiff" | translate }}</label>
              <div class="d-flex align-center flex-wrap">
                <ls-input-integer
                  [qaTags]="'qa-txt-ventilation-max-for-treatment'"
                  [fieldWithKeyboard]="true"
                  [forceDisabled]="!editMode"
                  [max]="100"
                  [min]="1"
                  errorField
                  formControlName="ventilationMaxForTreatment"></ls-input-integer>
                <span class="unit ml-10">{{ GlobalConstants.PercentageSymbol }}</span>
              </div>
            </div>

            <div>
              <label class="label">{{ "AirTreatment.Settings.IndoorTempToStopDiffFromTheTarget" | translate }}</label>
              <div class="d-flex align-center">
                <ls-input-decimal
                  [qaTags]="'qa-txt-ventilation-indoor-temp-to-stop'"
                  [accuracy]="1"
                  [disabled]="!editMode"
                  [fieldWithKeyboard]="true"
                  [max]="this.MAX_VALUE"
                  [min]="this.MIN_VALUE"
                  [class.disabled]="!editMode"
                  errorField
                  formControlName="ventilationIndoorTempToStop">
                </ls-input-decimal>
                <span class="unit ml-10">
                  {{ (temperatureUnitEnum === TemperatureUnitEnum.Celsius ? "Celsius" : "Fahrenheit") | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
        <div
          *ngIf="form.get('treatmentBy').value == TreatmentByEnum.VentilationHeating"
          class="d-flex flex-column">
          <div class="d-flex flex-column">
            <label class="label label__bold mt-30">{{ "AirTreatment.Settings.ExtraHeating" | translate }}</label>
            <div class="settings-field form-field mb-20">
              <label class="label">{{ "AirTreatment.Settings.MaxForTreatmentDiff" | translate }}</label>
              <div class="d-flex align-center">
                <ls-input-integer
                  [qaTags]="'qa-txt-heating-max-for-treatment'"
                  [fieldWithKeyboard]="true"
                  [forceDisabled]="!editMode"
                  [max]="100"
                  [min]="1"
                  errorField
                  formControlName="heatingMaxForTreatment"></ls-input-integer>
                <span class="unit ml-10">{{ GlobalConstants.PercentageSymbol }}</span>
              </div>
            </div>

            <div>
              <label class="label">{{ "AirTreatment.Settings.IndoorTempToStopDiffFromTheTarget" | translate }}</label>
              <div class="d-flex align-center">
                <ls-input-decimal
                  [qaTags]="'qa-txt-heating-indoor-temp-to-stop'"
                  [accuracy]="1"
                  [disabled]="!editMode"
                  [fieldWithKeyboard]="true"
                  [max]="this.MAX_VALUE"
                  [min]="this.MIN_VALUE"
                  [class.disabled]="!editMode"
                  errorField
                  formControlName="heatingIndoorTempToStop">
                </ls-input-decimal>
                <span class="unit ml-10">
                  {{ (temperatureUnitEnum === TemperatureUnitEnum.Celsius ? "Celsius" : "Fahrenheit") | translate }}
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-content select=".buttons-content"></ng-content>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
