import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { InstallationWrapperRoutingModule } from './installation-wrapper.routing.module';
import { CardComponent } from '../components/card/card.component';
import { InstallationComponent } from '../components/installation/installation.component';
import { RouterOutlet } from '@angular/router';
import { InstallationWrapperComponent } from './installation-wrapper.component';
import {
  ButtonComponent,
  CopyButtonComponent,
  HeaderTitleComponent,
  InputComponent,
  InputDecimalComponent,
  InputIntegerComponent,
  InputTextComponent,
  KeyboardComponent,
  LoadingComponent,
  SelectDirective,
  SvgIconComponent,
  TimePickerDialogControlComponent,
  ToggleComponent,
} from '@livestock/ui';
import { CardItemsComponent } from '../components/card-items/card-items.component';
import { DeviceTypesComponent } from '../components/device-types/device-types.component';
import { ActiveElementComponent } from '../components/active-element/active-element.component';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatRadioModule } from '@angular/material/radio';
import { NgSelectModule } from '@ng-select/ng-select';
import {
  EnumPipe,
  EnumToArrayPipe,
  FilterCollectionPipe,
  MemoizeFuncPipe,
} from '@livestock/shared/pipes';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import { MatTooltipModule } from '@angular/material/tooltip';
import { ActiveElementMobileComponent } from '../components/active-element-mobile/active-element-mobile.component';
import {
  ElementSettingsWrapperComponent,
} from '../components/devices/element-settings-wrapper/element-settings-wrapper.component';
import { ElementWrapperComponent } from '../components/devices/element-wrapper/element-wrapper.component';
import {
  CoolingSettingsFormComponent,
} from '../components/devices/elements/cooling-device/cooling-settings-form/cooling-settings-form.component';
import {
  CoolingElementFormComponent,
} from '../components/devices/elements/cooling-device/cooling-element-form.component';
import {
  CoolingTestingFormComponent,
} from '../components/devices/elements/cooling-device/cooling-testing-form/cooling-testing-form.component';
import {
  SprinklersAndFoggersFormComponent,
} from '../components/devices/elements/sprinklers-and-foggers/sprinklers-and-foggers.component';
import {
  SprinklersAndFoggersTestingFormComponent,
} from '../components/devices/elements/sprinklers-and-foggers/sprinklers-and-foggers-testing-form/sprinklers-and-foggers-testing-form.component';
import {
  SprinklersAndFoggersSettingsFormComponent,
} from '../components/devices/elements/sprinklers-and-foggers/sprinklers-and-foggers-settings-form/sprinklers-and-foggers-settings-form.component';
import {
  ElementTestingDialogComponent,
} from '../components/devices/element-testing-dialog/element-testing-dialog.component';
import {
  OutdoorHumidityComponent,
} from '../components/devices/elements/outdoor-humidity/outdoor-humidity-form.component';
import { OutdoorTempComponent } from '../components/devices/elements/outdoor-temp/outdoor-temp-element-form.component';
import { IndoorHumidityComponent } from '../components/devices/elements/indoor-humidity/indoor-humidity-form.component';
import { IndoorTempComponent } from '../components/devices/elements/indoor-temp/indoor-temp-element-form.component';
import { Co2SensorComponent } from '../components/devices/elements/co2-device/co2-sensor-form.component';
import {
  StaticPressureComponent,
} from '../components/devices/elements/static-pressure/static-pressure-element-form.component';
import {
  StaticPressureSettingsFormComponent,
} from '../components/devices/elements/static-pressure/static-pressure-settings-form/static-pressure-settings-form.component';
import { HeatingDOFormComponent } from '../components/devices/elements/heating-do/heating-do-form.component';
import {
  HeatingSettingsFormComponent,
} from '../components/devices/elements/heating/heating-settings-form/heating-settings-form.component';
import {
  HeatingDOTestingFormComponent,
} from '../components/devices/elements/heating-do/heating-do-testing-form/heating-do-testing-form.component';
import { HeatingAOFormComponent } from '../components/devices/elements/heating-ao/heating-ao-form.component';
import {
  HeatingAOTestingFormComponent,
} from '../components/devices/elements/heating-ao/heating-ao-testing-form/heating-ao-testing-form.component';
import { LightingSensorComponent } from '../components/devices/elements/lighting-sensor/lighting-sensor-form.component';
import {
  LightingTestingFormComponent,
} from '../components/devices/elements/lighting-sensor/testing-form/lighting-sensor-testing-form.component';
import { LightingDoComponent } from '../components/devices/elements/lighting-do/lighting-do.component';
import {
  LightingDOTestingFormComponent,
} from '../components/devices/elements/lighting-do/testing-form/lighting-do-testing-form.component';
import { LightingAOFormComponent } from '../components/devices/elements/lighting-ao/lighting-ao-form.component';
import {
  LightingAOTestingFormComponent,
} from '../components/devices/elements/lighting-ao/lighting-ao-testing-form/lighting-ao-testing-form.component';
import { AirSpeedComponent } from '../components/devices/elements/air-speed/air-speed-element-form.component';
import {
  LightingSettingsFormBaseComponent,
} from '../components/devices/elements/lighting/lighting-settings-form-base/lighting-settings-form-base.component';
import { NgxMatTimepickerModule } from '@angular-material-components/datetime-picker';
import { NgxMatMomentModule } from '@angular-material-components/moment-adapter';
import { NativeElementInjectorDirective } from 'libs/ui/src/lib/controls/native-element.directive';
import { MatNativeDateModule } from '@angular/material/core';
import {
  VentilationDoFormComponent,
} from '../components/devices/elements/ventilation-do/ventilation-do-form.component';
import {
  VentilationDoTestingFormComponent,
} from '../components/devices/elements/ventilation-do/ventilation-do-testing-form/ventilation-do-testing-form.component';
import { MatExpansionModule } from '@angular/material/expansion';
import {
  LightingSettingsFormMobileComponent,
} from '../components/devices/elements/lighting/lighting-settings-form-mobile/lighting-settings-form-mobile.component';
import {
  LightingSettingsFormComponent,
} from '../components/devices/elements/lighting/lighting-settings-form/lighting-settings-form.component';
import { BasicElementComponent } from '../components/devices/elements/basic-element/basic-element.component';
import { MatCheckboxModule } from '@angular/material/checkbox';
import {
  VentilationAoFormComponent,
} from '../components/devices/elements/ventilation-ao/ventilation-ao-form.component';
import {
  VentilationAoTestingFormComponent,
} from '../components/devices/elements/ventilation-ao/ventilation-ao-testing-form/ventilation-ao-testing-form.component';

@NgModule({
  imports: [
    CommonModule,
    InstallationWrapperRoutingModule,
    RouterOutlet,
    KeyboardComponent,
    HeaderTitleComponent,
    SvgIconComponent,
    TranslateModule,
    InputComponent,
    ButtonComponent,
    CopyButtonComponent,
    ReactiveFormsModule,
    MatRadioModule,
    FormsModule,
    InputIntegerComponent,
    NgSelectModule,
    EnumToArrayPipe,
    EnumPipe,
    ErrorFieldDirective,
    FilterCollectionPipe,
    MemoizeFuncPipe,
    InputTextComponent,
    LoadingComponent,
    MatTooltipModule,
    QaTagsDirective,
    ToggleComponent,
    InputDecimalComponent,
    SelectDirective,
    NgxMatTimepickerModule,
    NgxMatMomentModule,
    MatNativeDateModule,
    NativeElementInjectorDirective,
    MatExpansionModule,
    TimePickerDialogControlComponent,
    MatCheckboxModule,
  ],
  providers: [FilterCollectionPipe],
  declarations: [
    InstallationWrapperComponent,
    InstallationComponent,
    CardComponent,
    CardItemsComponent,
    DeviceTypesComponent,
    ActiveElementComponent,
    ActiveElementMobileComponent,
    ElementSettingsWrapperComponent,
    ElementWrapperComponent,
    CoolingSettingsFormComponent,
    CoolingElementFormComponent,
    CoolingTestingFormComponent,
    SprinklersAndFoggersFormComponent,
    SprinklersAndFoggersTestingFormComponent,
    SprinklersAndFoggersSettingsFormComponent,
    ElementTestingDialogComponent,
    OutdoorTempComponent,
    IndoorHumidityComponent,
    OutdoorHumidityComponent,
    IndoorTempComponent,
    Co2SensorComponent,
    StaticPressureComponent,
    StaticPressureSettingsFormComponent,
    HeatingDOFormComponent,
    HeatingSettingsFormComponent,
    HeatingDOTestingFormComponent,
    HeatingAOFormComponent,
    HeatingAOTestingFormComponent,
    LightingSensorComponent,
    LightingTestingFormComponent,
    LightingAOFormComponent,
    LightingAOTestingFormComponent,
    LightingDoComponent,
    LightingDOTestingFormComponent,
    AirSpeedComponent,
    LightingSettingsFormBaseComponent,
    LightingSettingsFormComponent,
    VentilationDoFormComponent,
    VentilationDoTestingFormComponent,
    LightingSettingsFormMobileComponent,
    BasicElementComponent,
    VentilationAoFormComponent,
    VentilationAoTestingFormComponent,
  ],
})
export class InstallationWrapperModule {
}
