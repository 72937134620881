import { AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import {
  LanguageService,
  LocalStorageService,
  MenuService,
  PlatformService,
  SwiperService,
} from '@livestock/shared/services';
import { CommonModule } from '@angular/common';
import { Position, SvgIconComponent } from '@livestock/ui';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective, SwiperDirective } from '@livestock/shared/directives';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { ColorsEnum, ElementTypesEnum, StorageItem } from '@livestock/shared/enums';
import { TemperatureCurveRoute } from '@livestock/temperature-curve';
import { firstValueFrom, forkJoin } from 'rxjs';
import { selectCurrentUserRole } from '@livestock/current-user';
import { selectActiveControllerID } from '@livestock/controllers';
import { IControllerMenu } from '../interfaces/controller-menu.interface';
import { IControllerMenuIcon } from '../interfaces/controller-menu-icon.interface';
import { InstallationApiService } from '../../../../installation/src/lib/service/installation-api.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    RouterModule,
    TranslateModule,
    SvgIconComponent,
    TranslateModule,
    QaTagsDirective,
    SwiperModule,
    SwiperDirective,
  ],
  selector: 'ls-controller-menu',
  templateUrl: './controller-menu.component.html',
  styleUrls: ['./controller-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ControllerMenuComponent implements OnInit, AfterViewInit {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  items: IControllerMenu[] = [
    {
      title: 'Control',
      qaTag: 'qa-lbl-control',
      icons: [
        {
          title: 'Temperature',
          path: 'temperature',
          qaTag: 'qa-btn-temp',
          navigateTo: ControllerRoutes.TemperatureMapping,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'TemperatureCurve',
          path: 'temperature-curve',
          qaTag: 'qa-btn-temp-curve',
          navigateTo: `${ControllerRoutes.TemperatureCurve}/${TemperatureCurveRoute.Table}`,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'Ventilation',
          path: 'ventilation',
          qaTag: 'qa-btn-ventilation',
          navigateTo: ControllerRoutes.BasicVentilationMinimum,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.VentilationDO, ElementTypesEnum.VentilationAO],
          isLoadingInfo: true,
          disabled: true,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'Cooling',
          path: 'cooling',
          qaTag: 'qa-btn-cooling',
          navigateTo: ControllerRoutes.Cooling,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.Cooling],
          isLoadingInfo: true,
          disabled: true,
        },
        {
          title: 'Heat',
          path: 'heat',
          qaTag: 'qa-btn-heat',
          navigateTo: ControllerRoutes.HeatingPrograms,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.HeatingDO, ElementTypesEnum.HeatingAO],
          isLoadingInfo: true,
          disabled: true,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'StaticPressure',
          path: 'static-pressure',
          qaTag: 'qa-btn-static-pressure',
          navigateTo: ControllerRoutes.StaticPressure,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.StaticPressure],
          isLoadingInfo: true,
          disabled: true,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'Light',
          path: 'light',
          qaTag: 'qa-btn-light',
          navigateTo: ControllerRoutes.LightingPrograms,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.LightDO, ElementTypesEnum.LightAO],
          isLoadingInfo: true,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'AirTreatment',
          path: 'air-treatment',
          qaTag: 'qa-btn-air-treatment',
          navigateTo: ControllerRoutes.AirTreatment,
          // allowedUserRoles: [CommonUserRolesEnum.God],
        },
        {
          title: 'SprinklersAndFoggers',
          path: 'sprinklers-foggers',
          qaTag: 'qa-btn-sprinklers-foggers',
          navigateTo: ControllerRoutes.SprinklersFoggersProgram,
          color: ColorsEnum.GrayDisabled,
          elementTypes: [ElementTypesEnum.SprinklersAndFoggers],
          isLoadingInfo: true,
        },
      ],
    },
    {
      title: 'Management',
      qaTag: 'qa-lbl-management',
      icons: [
        {
          title: 'FlockSetting',
          path: 'flock-setting',
          qaTag: 'qa-btn-flock',
          navigateTo: ControllerRoutes.FlockSettings,
        },
        {
          title: 'HouseMode',
          path: 'house-mode',
          qaTag: 'qa-btn-house-mode',
          navigateTo: ControllerRoutes.FlockHouseMode,
        },
        {
          title: 'BirdWeight',
          path: 'bird-weight',
          qaTag: 'qa-btn-bird-weight',
          navigateTo: ControllerRoutes.FlockBirdWeight,
        },
        {
          title: 'BirdInventory',
          path: 'bird-inventory',
          qaTag: 'qa-btn-bird-inventory',
        },
        {
          title: 'WaterConsumption',
          path: 'water-drinking',
          qaTag: 'qa-btn-drinking',
        },
        {
          title: 'FeedInventory',
          path: 'feed-inventory',
          qaTag: 'qa-btn-feed',
        },
        {
          title: 'HouseSettings',
          path: 'house-settings',
          qaTag: 'qa-btn-house',
          navigateTo: ControllerRoutes.HouseSizesSettings,
        },
      ],
    },
    {
      title: 'System',
      qaTag: 'qa-lbl-general',
      icons: [
        {
          title: 'Alarms',
          path: 'alarms',
          qaTag: 'qa-btn-alarms',
        },
        {
          title: 'Reports',
          path: 'reports',
          qaTag: 'qa-btn-reports',
        },
        {
          title: 'Logs',
          path: 'logs',
          qaTag: 'qa-btn-logs',
        },
        {
          title: 'DeviceAndSensors',
          path: 'device-sensors',
          qaTag: 'qa-btn-device-sensors',
          navigateTo: AppRoutes.INSTALLATION,
          directLink: true,
          isLoadingInfo: true,
        },
        {
          title: 'General',
          path: 'general',
          qaTag: 'qa-btn-general',
          navigateTo: ControllerRoutes.GeneralSettings,
        },
        {
          title: 'DateAndTime',
          path: 'date-time',
          qaTag: 'qa-btn-date-time',
          navigateTo: ControllerRoutes.DateTimeSettings,
        },
        {
          title: 'Network',
          path: 'network',
          qaTag: 'qa-btn-network',
          navigateTo: ControllerRoutes.NetworkSettings,
        },
        {
          title: 'Cloud',
          path: 'cloud',
          qaTag: 'qa-btn-cloud',
          navigateTo: ControllerRoutes.CloudSettings,
        },
        {
          title: 'User',
          path: 'user',
          qaTag: 'qa-btn-user',
        },
      ],
    },
  ];

  activeSlide = Number(LocalStorageService.getStorageItem(StorageItem.ControllerMenuSlide)) || 0;
  navigationInProgress: boolean;
  showErrorMessageTimeOut: ReturnType<typeof setTimeout>[] = [];
  ColorsEnum = ColorsEnum;
  StorageItem = StorageItem;
  Position = Position;

  constructor(
    public menuService: MenuService,
    public router: Router,
    public store: Store,
    public platformService: PlatformService,
    public languageService: LanguageService,
    public swiperService: SwiperService,
    public cdr: ChangeDetectorRef,
    private installationApiService: InstallationApiService,
  ) {
    this.swiperService.config = {
      autoHeight: true,
      spaceBetween: 20,
      direction: 'horizontal',
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ControllerMenuSlide)) || 0,
      centeredSlides: true,
    };
  }

  ngOnInit(): void {
    this.cdr.detectChanges();
    this.swiper?.swiperRef.slideTo(this.activeSlide, 0);
    this.updateIconsStatus();
  }

  async updateIconsStatus(): Promise<void> {
    const controllerID = await firstValueFrom(this.store.select(selectActiveControllerID));
    const controlIcons = this.items[0].icons;
    const generalIcons = this.items[2].icons;

    forkJoin(
      [this.installationApiService.getControllerElementsInfo(controllerID),
        this.installationApiService.getControllerCards(controllerID)],
    ).subscribe({
      next: ([elementsInfo, cards]) => {
        const sprinklersIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.SprinklersAndFoggers));
        sprinklersIcon.color = elementsInfo.hasSprinklersAndFoggersElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        sprinklersIcon.disabled = !elementsInfo.hasSprinklersAndFoggersElements;
        sprinklersIcon.isLoadingInfo = false;

        const coolingIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.Cooling));
        coolingIcon.color = elementsInfo.hasCoolingElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        coolingIcon.disabled = !elementsInfo.hasCoolingElements;
        coolingIcon.isLoadingInfo = false;

        const lightIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.LightAO));
        lightIcon.color = elementsInfo.hasLightElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        lightIcon.disabled = !elementsInfo.hasLightElements;
        lightIcon.isLoadingInfo = false;

        const heatIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.HeatingAO));
        heatIcon.color = elementsInfo.hasHeatElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        heatIcon.disabled = !elementsInfo.hasHeatElements;
        heatIcon.isLoadingInfo = false;

        const ventIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.VentilationAO));
        ventIcon.color = elementsInfo.hasVentilationElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        ventIcon.disabled = !elementsInfo.hasVentilationElements;
        ventIcon.isLoadingInfo = false;

        const staticPressureIcon = controlIcons
          .find(icon => icon.elementTypes?.includes(ElementTypesEnum.StaticPressure));
        staticPressureIcon.color = elementsInfo.hasStaticPressureElements ? ColorsEnum.BlueDefault : ColorsEnum.GrayDisabled;
        staticPressureIcon.disabled = !elementsInfo.hasStaticPressureElements;
        staticPressureIcon.isLoadingInfo = false;

        const hasConnectedDevice = cards.items?.some(item => item.connections.some(connection => connection?.element != null));

        const devicesAndSensorsIcon = generalIcons.find(icon => icon.navigateTo === AppRoutes.INSTALLATION);

        devicesAndSensorsIcon.hasWarning = !hasConnectedDevice;
        devicesAndSensorsIcon.isLoadingInfo = false;

        this.cdr.detectChanges();
      },
    });
  }

  ngAfterViewInit(): void {
    setTimeout(() => {
      this.cdr.detectChanges();
    }, 100);
  }

  changeActiveSlide(ev): void {
    this.activeSlide = ev[0].realIndex;
    LocalStorageService.setStorageItem(
      StorageItem.ControllerMenuSlide,
      this.activeSlide,
    );
    this.cdr.detectChanges();
  }

  async navigateToThePage(icon: IControllerMenuIcon): Promise<void> {
    const controllerID = localStorage.getItem('activeControllerID');

    if (icon.isLoadingInfo) return;
    if (icon.disabled && this.platformService.isDesktopApp) return;

    // Temporary for QA
    if (icon.allowedUserRoles) {
      const currentUserRole = await firstValueFrom(this.store.select(selectCurrentUserRole));
      if (!icon.allowedUserRoles.includes(currentUserRole)) {
        return;
      }
    }

    // show error message in desktop by simple hover, in device and mobile - by clicking it and 3 sec only
    if (icon.disabled && !this.platformService.isDesktopApp) {
      clearTimeout(this.showErrorMessageTimeOut?.[icon.elementTypes[0]]);
      const clickedMenuIcon = this.items[0].icons
        .find(menuIcon => menuIcon.elementTypes?.includes(icon.elementTypes[0]));
      clickedMenuIcon.showErrorMessage = true;

      this.showErrorMessageTimeOut[icon.elementTypes[0]] = setTimeout(() => {
        clickedMenuIcon.showErrorMessage = false;
        this.cdr.detectChanges();
      }, 3000);

      return;
    }

    if (icon.navigateTo && !this.navigationInProgress) {
      this.navigationInProgress = true;
      setTimeout(() => this.navigationInProgress = false, 1000);
      const link = icon.directLink
        ? icon.navigateTo
        : `controller/${controllerID}/${icon.navigateTo}`;

      this.router.navigate([link]);
    }
  }

  trackByFn(index: number): number {
    return index;
  }
}
