import {
  IVentilationDOValues,
} from '../../../../installation/src/lib/interfaces/element/ventilation/ventilation-do-values.interface';

export interface IVentModelDOItem extends IVentilationDOValues {
  modelID: number;
  brandID: number;
  brandName: string;
  name: string;
}
