<input
  #input
  [placeholder]="placeholder"
  [disabled]="isDisabled"
  [qaTags]="qaTags"
  [style.color]="textColor"
  type="text" />

<span
  *ngIf="hoursFormat === HoursFormatTypeEnum.AmPm"
  class="ml-5">
  {{
    (AMPMOption === AmPmEnum.AM
      ? "Controller.DateTimeSettings.HoursFormatTypeEnum.AM"
      : "Controller.DateTimeSettings.HoursFormatTypeEnum.PM"
    ) | translate
  }}
</span>
