<ls-page-wrapper
  (back)="cancel()"
  [hasArrow]="true"
  [isLoading]="isLoading$ | async"
  [title]="'Controls.Program.SprinklersAndFoggers'">
  <div class="svg-content header__buttons buttons d-flex align-center">
    <ls-svg-icon
      *ngIf="!editMode"
      (click)="enableEdit()"
      [path]="platformService.isMobileApp ? 'mobile/edit' : 'installation/edit'"
      [qaTags]="'qa-icon-edit'"
      class="pointer">
    </ls-svg-icon>
    <ng-container *ngIf="!platformService.isMobileApp || !editMode">
      <ls-svg-icon
        *ngIf="editMode"
        (click)="cancelEdit()"
        [color]="ColorsEnum.BlueDark"
        class="icon pointer"
        path="installation/table">
      </ls-svg-icon>
      <ls-svg-icon
        [class.pointer-events-none]="editMode"
        [color]="ColorsEnum.DefaultDisabled"
        [path]="platformService.isMobileApp ? 'installation/graph-mobile' : 'installation/graph'"
        class="icon pointer">
      </ls-svg-icon>
      <ls-svg-icon
        (click)="goToSettings()"
        [class.pointer-events-none]="editMode"
        [color]="editMode ? ColorsEnum.DefaultDisabled : ColorsEnum.Default"
        [path]="platformService.isMobileApp ? 'mobile/settings' : 'installation/settings'"
        class="icon pointer">
      </ls-svg-icon>
    </ng-container>
  </div>
  <div class="main-content program">
    <ls-add-program-day-mobile-dialog
      *ngIf="editMode && platformService.isMobileApp"
      (isDisabledChange)="setDisabledState($event)"
      (clearBeforeDayAdding)="clearBeforeDayAdding($event)"
      (deleteDay)="deleteDay()"
      (onFocusOut)="addNewDayMobile($event)"
      [currentPeriod]="currentPeriod$ | async"
      [periods]="periods$ | async">
    </ls-add-program-day-mobile-dialog>
    <div
      [class.position-relative]="platformService.isMobileApp"
      class="overflow-hidden">
      <div class="program-header color-secondary d-flex align-center mb-20">
        <h6 [class.d-none]="editMode && platformService.isMobileApp">
          {{ "Controls.Program.Days" | translate }}
        </h6>
        <p *ngIf="!platformService.isMobileApp; else programsCount">
          {{ "Controls.Program.TargetTemp" | translate }} <span class="color-primary fw-500">20°C</span>
        </p>
        <ng-template #programsCount>
          <p>{{ "Controls.Program.Program" | translate }} {{ currentProgramIndex }} / {{ getRows()?.length }}</p>
          <img
            *ngIf="editMode && form?.invalid"
            src="/assets/icons/common/warning-icon.svg" />
        </ng-template>
      </div>
      <div
        [class.gap-unset]="platformService.isMobileApp && editMode"
        [class.mb-50]="!platformService.isMobileApp || (platformService.isMobileApp && !editMode)"
        [ngClass]="platformService.isMobileApp ? 'h-auto' : 'h-100-percent'"
        class="program-body d-flex justify-between">
        <div
          [class.d-none]="editMode && platformService.isMobileApp"
          class="program-days">
          <div
            #periodsScrollbar
            class="program-days__scrollbar">
            <div
              *ngFor="let period of periods$ | async; index as index"
              (click)="setPeriod(period, true, index)"
              [class.program-day__active]="period.periodID === (currentPeriod$ | async)?.periodID"
              class="program-day">
              {{ period.dayNumber }}
            </div>
          </div>
        </div>
        <div
          *ngIf="form"
          [class.h-85-percent]="!platformService.isMobileApp"
          [class.overflow-auto]="!platformService.isMobileApp"
          class="flex-grow-1">
          <ls-program-table-mobile
            *ngIf="platformService.isMobileApp; else desktopTable"
            (onChange)="onTableChange($event)"
            (setFocusedTimepicker)="setFocusedTimepicker($event)"
            #mobileTable
            [editMode]="editMode"
            [humLevelToRestart]="humLevelToRestart || CoolingProgramConstants.humStopLevelMin"
            [isDisabled]="isDisabled"
            [isflockModeAdvance]="isflockModeAdvance"
            [items]="getRows()"
            [noFilledRows]="areNoFilledRows()"
            [path]="'dashboard/sprinklers'">
          </ls-program-table-mobile>
          <ng-template #desktopTable>
            <div
              [formGroup]="form"
              class="item__container">
              <div
                [class.table__rtl]="languageService.isRtl"
                class="table w-1-5 w-2-40 w-3-20 w-4-10 w-5-10 w-6-15">
                <div class="table-row table-row__header">
                  <div class="table-cell blue align-center">
                    {{ "Controls.Element.Prog" | translate }}
                  </div>
                  <div class="table-cell blue">
                    <div class="table-cell__child w-100-percent">
                      {{ "Controls.Element.Time" | translate }}
                    </div>
                    <div class="table-cell__child">
                      {{ "Controls.Element.Start" | translate }}
                    </div>
                    <div class="table-cell__child">
                      {{ "Controls.Element.End" | translate }}
                    </div>
                    <div class="table-cell__child">
                      {{ "Controls.Element.OnTime" | translate }}
                    </div>
                    <div class="table-cell__child">
                      {{ "Controls.Element.OffTime" | translate }}
                    </div>
                  </div>
                  <div class="table-cell blue">
                    <div class="table-cell__child w-100-percent">
                      {{ "Controls.Element.Tempdiff" | translate }}
                    </div>
                    <div class="table-cell__child flex-1">
                      {{ "Controls.Element.Start" | translate }}
                    </div>
                    <div class="table-cell__child flex-1">
                      {{ "Controls.Element.Stop" | translate }}
                    </div>
                  </div>
                  <div class="table-cell blue align-center">
                    {{ "Controls.Element.VentStartLevel" | translate }}
                  </div>
                  <div class="table-cell blue align-center">
                    {{ "Controls.Element.HumidityLevelToStop" | translate }}
                  </div>
                  <div class="table-cell blue align-center">
                    {{ "Controls.Element.Device" | translate }}
                  </div>
                </div>
                <ng-container formArrayName="items">
                  <div
                    *ngFor="let controls of getRows(); let i = index; let isLast = last"
                    (click)="selectRow(controls, i)"
                    [class.active]="editMode && i === selectedProgramIndex"
                    [class.last-row]="isLast"
                    [formGroup]="controls"
                    class="table-row">
                    <div class="table-cell no-border-bottom blue">
                      <div
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [qaTags]="'qa-txt-program-number'"
                        class="d-flex flex-row align-center">
                        {{ i + 1 }}
                      </div>
                    </div>
                    <div class="table-cell d-flex">
                      <div class="no-border-bottom table-cell__child">
                        <ngx-mat-timepicker
                          (click)="setFocusedTimepicker($event)"
                          #timepicker
                          [class.border-none__no-editing-mode]="!editMode"
                          [class.focused]="isFocused([timepicker, currentTimeUUID])"
                          [class.hidden]="!editMode && areNoFilledRows()"
                          [class.ng-invalid]="controls.hasError(ErrorFieldEnum.OuterOverlap)"
                          [disabled]="controls?.disabled"
                          [enableMeridian]="selectHourFormatForTimePicker$ | async"
                          [errTooltipControl]="controls"
                          [forcedErrorKey]="ErrorFieldEnum.OuterOverlap"
                          [showSeconds]="false"
                          [showSpinners]="false"
                          [showTooltipOnHover]="true"
                          class="program__time-picker w-100-percent"
                          errorField
                          formControlName="startTime">
                        </ngx-mat-timepicker>
                      </div>
                      <div class="no-border-bottom table-cell__child">
                        <ngx-mat-timepicker
                          (click)="setFocusedTimepicker($event)"
                          #q
                          #timepicker
                          [class.border-none__no-editing-mode]="!editMode"
                          [class.focused]="isFocused([q, currentTimeUUID])"
                          [class.hidden]="!editMode && areNoFilledRows()"
                          [class.ng-invalid]="controls.hasError(ErrorFieldEnum.InnerOverlap)"
                          [disabled]="controls?.disabled"
                          [enableMeridian]="selectHourFormatForTimePicker$ | async"
                          [errTooltipControl]="controls"
                          [forcedErrorKey]="ErrorFieldEnum.InnerOverlap"
                          [showSeconds]="false"
                          [showSpinners]="false"
                          [showTooltipOnHover]="true"
                          class="program__time-picker w-100-percent"
                          errorField
                          formControlName="endTime">
                        </ngx-mat-timepicker>
                      </div>
                      <div class="no-border-bottom table-cell__child">
                        <div
                          *ngIf="!editMode && controls.value.onTime != null"
                          class="d-flex flex-row align-center">
                          {{ controls.value.onTime }} {{ "Sec" | translate | lowercase }}
                        </div>
                        <ls-input-integer
                          *ngIf="editMode"
                          [fieldWithKeyboard]="true"
                          [max]="CoolingProgramConstants.onTimeMax"
                          [min]="CoolingProgramConstants.onTimeMin"
                          [noMaxWidth]="true"
                          [noPaddings]="true"
                          [qaTags]="'qa-txt-on-time'"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="onTime"
                          placeholder="0">
                        </ls-input-integer>
                      </div>
                      <div class="no-border-bottom table-cell__child">
                        <div
                          *ngIf="!editMode && controls.value.offTime != null"
                          class="d-flex flex-row align-center">
                          {{ controls.value.offTime }} {{ "Sec" | translate | lowercase }}
                        </div>
                        <ls-input-integer
                          *ngIf="editMode"
                          [fieldWithKeyboard]="true"
                          [max]="CoolingProgramConstants.offTimeMax"
                          [min]="CoolingProgramConstants.offTimeMin"
                          [noMaxWidth]="true"
                          [noPaddings]="true"
                          [qaTags]="'qa-txt-off-time'"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="offTime"
                          placeholder="0">
                        </ls-input-integer>
                      </div>
                    </div>
                    <div class="table-cell d-flex">
                      <div class="no-border-bottom table-cell__child flex-1">
                        <ls-input-decimal
                          [accuracy]="1"
                          [class.border-none__no-editing-mode]="!editMode"
                          [class.hidden]="!editMode && areNoFilledRows()"
                          [fieldWithKeyboard]="true"
                          [max]="CoolingProgramConstants.startTempMax"
                          [min]="CoolingProgramConstants.startTempMin"
                          [noMaxWidth]="true"
                          [qaTags]="'qa-txt-start-temp'"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="startTemp"
                          placeholder="0.0">
                        </ls-input-decimal>
                      </div>
                      <div class="no-border-bottom table-cell__child flex-1">
                        <ls-input-decimal
                          [accuracy]="1"
                          [class.border-none__no-editing-mode]="!editMode"
                          [class.hidden]="!editMode && areNoFilledRows()"
                          [fieldWithKeyboard]="true"
                          [max]="CoolingProgramConstants.stopTempMax"
                          [min]="CoolingProgramConstants.stopTempMin"
                          [noMaxWidth]="true"
                          [qaTags]="'qa-txt-stop-temp'"
                          [showTooltipOnHover]="true"
                          class="align-center"
                          errorField
                          formControlName="stopTemp"
                          placeholder="0.0">
                        </ls-input-decimal>
                      </div>
                    </div>
                    <div class="table-cell no-border-bottom">
                      <ls-input-integer
                        [class.border-none__no-editing-mode]="!editMode"
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [fieldWithKeyboard]="true"
                        [max]="
                          isflockModeAdvance
                            ? CoolingProgramConstants.ventStartLevelAdvancedMax
                            : CoolingProgramConstants.ventStartLevelMax
                        "
                        [min]="
                          isflockModeAdvance
                            ? CoolingProgramConstants.ventStartLevelAdvancedMin
                            : CoolingProgramConstants.ventStartLevelMin
                        "
                        [noMaxWidth]="true"
                        [noPaddings]="true"
                        [qaTags]="'qa-txt-vent-start-level'"
                        [showTooltipOnHover]="true"
                        class="align-center"
                        errorField
                        formControlName="ventStartLevel"
                        placeholder="0">
                        <ng-container
                          *ngIf="!isflockModeAdvance"
                          suffix>
                          <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                        </ng-container>
                      </ls-input-integer>
                    </div>
                    <div class="table-cell no-border-bottom">
                      <ls-input-integer
                        [max]="CoolingProgramConstants.humStopLevelMax"
                        [min]="humLevelToRestart || CoolingProgramConstants.humStopLevelMin"
                        [class.border-none__no-editing-mode]="!editMode"
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [fieldWithKeyboard]="true"
                        [noMaxWidth]="true"
                        [noPaddings]="true"
                        [qaTags]="'qa-txt-hum-stop-level'"
                        [showTooltipOnHover]="true"
                        class="align-center"
                        errorField
                        formControlName="humStopLevel"
                        placeholder="00">
                        <ng-container suffix>
                          <div class="unit">{{ GlobalConstants.PercentageSymbol }}</div>
                        </ng-container>
                      </ls-input-integer>
                    </div>
                    <div class="table-cell no-border-bottom padding-top-10">
                      <ls-program-device-buttons
                        [class.hidden]="!editMode && areNoFilledRows()"
                        [controls]="controls">
                      </ls-program-device-buttons>
                    </div>
                  </div>
                </ng-container>
              </div>
            </div>
          </ng-template>
          <div
            *ngIf="!editMode && !platformService.isMobileApp"
            class="program-table__block d-flex align-center">
            <img src="/assets/icons/common/note-icon.svg" />
            <span class="program-table__block__text">
              {{
                "Controls.Program.TheseValuesAreForDaysFromTo"
                  | translate
                    : {
                        from: (currentPeriod$ | async).dayNumber,
                        to: periods$ | async | findNextTo : (currentPeriod$ | async)
                      }
              }}
            </span>
          </div>
        </div>
      </div>
    </div>
    <div
      *ngIf="editMode"
      [class.align-center]="platformService.isMobileApp"
      class="program__footer">
      <div class="program__footer__buttons d-flex align-center">
        <ls-button
          *ngIf="!platformService.isMobileApp"
          (click)="addDay()"
          [qaTags]="'qa-btn-add-day'"
          [type]="'secondary'"
          class="program__footer-button program__footer-button--icon mr-16">
          <ls-svg-icon
            [color]="ColorsEnum.Primary"
            path="table/plus">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="!platformService.isMobileApp"
          (click)="deleteDay()"
          [class.disabled]="(periods$ | async).length === 1"
          [qaTags]="'qa-btn-delete-day'"
          [type]="'secondary'"
          class="program__footer-button program__footer-button--icon mr-35">
          <ls-svg-icon
            [color]="(periods$ | async).length === 1 ? ColorsEnum.DefaultDisabled : ColorsEnum.Primary"
            path="table/delete">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="getRows() as rows"
          (click)="addEmptyRow()"
          [class.disabled]="rows.length >= 10"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [qaTags]="'qa-btn-add-program'"
          [type]="'secondary'"
          class="program__footer-button mr-16">
          <span *ngIf="!platformService.isMobileApp">{{ "Controls.Program.AddProgram" | translate }}</span>
          <ls-svg-icon
            *ngIf="platformService.isMobileApp"
            [color]="ColorsEnum.Primary"
            path="table/plus">
          </ls-svg-icon>
        </ls-button>
        <ls-button
          *ngIf="currentPeriod$ | async as period"
          (click)="deleteProgram(period)"
          [class.disabled]="selectedProgramIndex === null"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [qaTags]="'qa-btn-delete-program'"
          [type]="'secondary'"
          class="program__footer-button">
          <span *ngIf="!platformService.isMobileApp">{{ "Controls.Program.DeleteProgram" | translate }}</span>
          <ls-svg-icon
            *ngIf="platformService.isMobileApp"
            [color]="selectedProgramIndex === null ? ColorsEnum.DefaultDisabled : ColorsEnum.Primary"
            path="table/delete">
          </ls-svg-icon>
        </ls-button>
      </div>
      <div class="program__footer__buttons d-flex align-center">
        <ls-button
          (click)="cancelEdit()"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [qaTags]="'qa-btn-cancel'"
          [type]="'secondary'"
          class="program__footer-button mr-16">
          {{ "Controls.Program.Cancel" | translate }}
        </ls-button>
        <ls-button
          (click)="save()"
          [class.disabled]="form.invalid || isDisabled || (formUnchanged && !areNoFilledRows())"
          [class.program__footer-button__mobile]="platformService.isMobileApp"
          [qaTags]="'qa-btn-save'"
          class="program__footer-button">
          {{ "Controls.Program.Save" | translate }}
        </ls-button>
      </div>
    </div>
    <div
      *ngIf="!editMode && !(currentProgram$ | async)?.items?.length && !platformService.isMobileApp"
      class="program-empty">
      <ls-svg-icon
        [hasMargin]="true"
        path="controls/no-programs"></ls-svg-icon>
      <p class="color-secondary">
        {{ "Controls.Program.Creation" | translate }}
      </p>
      <ls-button (click)="enableEdit()">
        {{ "Controls.Program.EditProgram" | translate }}
      </ls-button>
    </div>
  </div>
  <ls-keyboard
    *ngIf="editMode && !platformService.isMobileApp"
    [context]="dialogRef ? 'mat-dialog-container' : null"
    [hasTimePicker]="(isAMPM$ | async) && (selectHourFormatForTimePicker$ | async)"
    class="keyboard-content keyboard ml-15" />
</ls-page-wrapper>
