import { IBasicVentilationMinimumItem, IBasicVentilationSettings } from '@livestock/basic-ventilation-minimum';
import { IElement } from 'libs/installation/src/lib/interfaces/element/element.interface';

export interface BasicVentilationMinimumState {
  isLoading: boolean;
  canDeactivate: boolean;
  items: IBasicVentilationMinimumItem[];
  ventilationDOElements: IElement[];
  ventilationAOElements: IElement[];
  // settings
  isLoadingSettings: boolean;
  settings: IBasicVentilationSettings;
}
