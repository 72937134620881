import { ControllerUnitEnum } from '@livestock/controllers';
import { IFlockBirdWeightView, IFlockDefaultWeightView, IFlockSettingsView } from '@livestock/flock';
import { WeightUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

export class FlockHelper {
  static getWeight(defaultWeight: IFlockDefaultWeightView, weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Gram:
        return defaultWeight.weight;
      case WeightUnitEnum.Kilogram:
        return ConvertHelper.gramToKilogram(defaultWeight.weight, 2);
      case WeightUnitEnum.Pound:
        return ConvertHelper.gramToPound(defaultWeight.weight, 2);
    }
  }

  static convertWeight(weight: number, weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Gram:
        return weight;
      case WeightUnitEnum.Kilogram:
        return ConvertHelper.gramToKilogram(weight, 2);
      case WeightUnitEnum.Pound:
        return ConvertHelper.gramToPound(weight, 2);
    }
  }

  static getFemaleWeight(defaultWeight: IFlockDefaultWeightView, weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Gram:
        return defaultWeight.femaleWeight;
      case WeightUnitEnum.Kilogram:
        return ConvertHelper.gramToKilogram(defaultWeight.femaleWeight, 2);
      case WeightUnitEnum.Pound:
        return ConvertHelper.gramToPound(defaultWeight.femaleWeight, 2);
    }
  }

  static getMaleWeight(defaultWeight: IFlockDefaultWeightView, weightUnit: WeightUnitEnum): number {
    switch (weightUnit) {
      case WeightUnitEnum.Gram:
        return defaultWeight.maleWeight;
      case WeightUnitEnum.Kilogram:
        return ConvertHelper.gramToKilogram(defaultWeight.maleWeight, 2);
      case WeightUnitEnum.Pound:
        return ConvertHelper.gramToPound(defaultWeight.maleWeight, 2);
    }
  }

  static prepareBirdWeightFromDefault(
    controllerID: number,
    weightUnit: WeightUnitEnum,
    defaultWeightItems: IFlockDefaultWeightView[],
  ): IFlockBirdWeightView[] {
    return defaultWeightItems.map((defaultWeight) => {
      return {
        controllerID,
        day: defaultWeight.day,
        weight: weightUnit === null ? defaultWeight.weight : FlockHelper.getWeight(defaultWeight, weightUnit),
        femaleWeight:
          weightUnit === null ? defaultWeight.femaleWeight : FlockHelper.getFemaleWeight(defaultWeight, weightUnit),
        maleWeight: weightUnit === null ? defaultWeight.maleWeight : FlockHelper.getMaleWeight(defaultWeight, weightUnit),
      };
    });
  }

  static getWeightUnit(settings: IFlockSettingsView): WeightUnitEnum {
    switch (settings?.units) {
      case ControllerUnitEnum.Metric:
        return WeightUnitEnum.Gram;
      case ControllerUnitEnum.Imperial:
        return WeightUnitEnum.Pound;
      default:
        return settings?.unitsDetails?.weight;
    }
  }
}
