<div class="widget-wrapper">
  <div class="widget-top mb-30 mb-0-xl mb-40-mobile">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="widget-title__name"
          >Heat by zone</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content pr-10-xl pr-0-mobile">
      <section class="d-none-mobile">
        <table class="ml-20 ml-0-xl text-center fz-16 with-border with-border-radius td-padding-10 mb-20 mb-15-xl">
          <thead class="thead-fw-500">
            <tr>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-label'"
                class="cell-double-active color-tertiary">
                Zone
              </th>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-num'"
                class="cell-active color-secondary">
                1
              </th>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-num'"
                class="cell-active color-secondary">
                2
              </th>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-num'"
                class="cell-active color-secondary">
                3
              </th>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-num'"
                class="color-disabled">
                4
              </th>
              <th
                [qaTags]="'qa-lbl-heat-p-zone-num'"
                class="color-disabled">
                5
              </th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td
                [qaTags]="'qa-lbl-heat-p-temp-label'"
                class="cell-secondary color-tertiary">
                Temp
              </td>
              <td [qaTags]="'qa-lbl-heat-p-temp-value'">23.7<span class="fz-12">°C</span></td>
              <td [qaTags]="'qa-lbl-heat-p-temp-value'">23.7<span class="fz-12">°C</span></td>
              <td [qaTags]="'qa-lbl-heat-p-temp-value'">23.7<span class="fz-12">°C</span></td>
              <td
                [qaTags]="'qa-lbl-heat-p-temp-value'"
                class="color-disabled">
                -
              </td>
              <td
                [qaTags]="'qa-lbl-heat-p-temp-value'"
                class="color-disabled">
                -
              </td>
            </tr>

            <tr>
              <td
                [qaTags]="'qa-lbl-heat-p-offset-label'"
                class="cell-secondary color-tertiary">
                Offset
              </td>
              <td [qaTags]="'qa-lbl-heat-p-offset-value'">24<span class="fz-12">°C</span></td>
              <td [qaTags]="'qa-lbl-heat-p-offset-value'">23.9<span class="fz-12">°C</span></td>
              <td [qaTags]="'qa-lbl-heat-p-offset-value'">23.7<span class="fz-12">°C</span></td>
              <td
                [qaTags]="'qa-lbl-heat-p-offset-value'"
                class="color-disabled">
                -
              </td>
              <td
                [qaTags]="'qa-lbl-heat-p-offset-value'"
                class="color-disabled">
                -
              </td>
            </tr>
          </tbody>
        </table>

        <table class="ml-20 ml-0-xl text-center fz-16 with-border with-border-radius td-padding-10 mb-20 mb-15-xl">
          <thead class="thead-fw-500">
            <tr>
              <th class="cell-double-active color-tertiary">Zone</th>
              <th class="color-tertiary">6</th>
              <th class="cell-active color-secondary">7</th>
              <th class="color-secondary">8</th>
              <th class="color-disabled">9</th>
              <th class="color-disabled">10</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="cell-secondary color-tertiary">Temp</td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td>23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
            </tr>

            <tr>
              <td class="cell-secondary color-tertiary">Offset</td>
              <td class="color-disabled">24<span class="fz-12">°C</span></td>
              <td>23.9<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
            </tr>
          </tbody>
        </table>

        <table class="ml-20 ml-0-xl text-center fz-16 with-border with-border-radius td-padding-10 mb-20 mb-15-xl">
          <thead class="thead-fw-500">
            <tr>
              <th class="cell-double-active color-tertiary">Zone</th>
              <th class="color-tertiary">11</th>
              <th class="cell-active color-secondary">12</th>
              <th class="color-secondary">13</th>
              <th class="color-disabled">14</th>
              <th class="color-disabled">15</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td class="cell-secondary color-tertiary">Temp</td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td>23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
            </tr>

            <tr>
              <td class="cell-secondary color-tertiary">Offset</td>
              <td class="color-disabled">24<span class="fz-12">°C</span></td>
              <td>23.9<span class="fz-12">°C</span></td>
              <td class="color-disabled">23.7<span class="fz-12">°C</span></td>
              <td class="color-disabled">-</td>
              <td class="color-disabled">-</td>
            </tr>
          </tbody>
        </table>
      </section>

      <section class="d-none d-block-mobile table-mobile">
        <table class="ml-20 ml-0-xl text-center fz-16 with-border-radius-6 td-padding-10 mb-20 mb-15-xl">
          <thead class="fz-18-mobile">
            <tr>
              <th class="cell-double-active color-tertiary">Zone</th>
              <th class="cell-secondary color-tertiary">Temp</th>
              <th class="cell-secondary color-tertiary">Offset</th>
            </tr>
          </thead>

          <tbody class="fz-22-mobile">
            <tr *ngFor="let i of [].constructor(15); index as index">
              <td
                [ngClass]="index % 2 === 0 ? 'cell-active color-secondary' : 'color-disabled'"
                [qaTags]="index % 2 === 0 ? 'qa-bgc-heat-p-zone-cell' : ''"
                class="fz-18-mobile">
                {{ index + 1 }}
              </td>
              <td>23.7<span class="fz-16-mobile">°C</span></td>
              <td>23.7<span class="fz-16">°C</span></td>
            </tr>
          </tbody>
        </table>
      </section>
    </div>
  </div>
</div>
