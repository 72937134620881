<div
  *ngIf="isLoading"
  class="loading">
  <div class="loading__background"></div>
  <ls-loading-galcon />
  <span class="mt-18 mono-dark fz-24">
    {{ "Controller.NetworkSettings.ConnectingToNetwork" | translate }}
  </span>
</div>

<div class="d-flex flex-row">
  <div
    [formGroup]="form"
    class="menu d-flex flex-row gap-20">
    <section class="d-flex flex-column gap-20 titles">
      <div
        (click)="menuClicked('ethernet')"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'ethernet', disabled: isDisabled }"
        class="form-field justify-unset">
        <div
          *ngIf="menuItem === 'ethernet' || !!form.controls['lanIp'].value"
          class="form-field__title">
          {{ "Controller.NetworkSettings.Ethernet" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <div class="d-flex flex-row align-center">
            <ng-container *ngIf="!form.controls['lanIp'].value">
              {{ "Controller.NetworkSettings.Ethernet" | translate }}
            </ng-container>
            <ng-container *ngIf="form.controls['lanIp'].value">
              <ls-svg-icon
                [path]="IconsEnum.NETWORK_IP"
                class="mr-8" />
              {{ form.controls["lanIp"].value }}
            </ng-container>
          </div>
          <lv-toggle-new formControlName="lan"> </lv-toggle-new>
        </div>
      </div>
      <div
        (click)="menuClicked('wifi')"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'wifi', disabled: isDisabled }"
        class="form-field justify-unset">
        <div
          *ngIf="menuItem === 'wifi'"
          class="form-field__title">
          {{ "Controller.NetworkSettings.Wifi" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <span>
            <ng-container *ngIf="menuItem !== 'wifi'">
              {{ "Controller.NetworkSettings.Wifi" | translate }}
            </ng-container>
          </span>
          <lv-toggle-new></lv-toggle-new>
        </div>
      </div>
      <div
        (click)="menuClicked('cellular')"
        [ngClass]="{ 'form-field__active connect connect-separator': menuItem === 'cellular', disabled: isDisabled }"
        class="form-field justify-unset">
        <div
          *ngIf="menuItem === 'cellular'"
          class="form-field__title">
          {{ "Controller.NetworkSettings.Cellular" | translate }}
        </div>
        <div class="form-field__value d-flex flex-row justify-between">
          <span>
            <ng-container *ngIf="menuItem !== 'cellular'">
              {{ "Controller.NetworkSettings.Cellular" | translate }}
            </ng-container>
          </span>
          <lv-toggle-new></lv-toggle-new>
        </div>
      </div>
    </section>
    <section
      *ngIf="menuItem"
      class="d-flex flex-column gap-20 p-15 settings {{ menuItem }}">
      <div
        *ngIf="menuItem === 'ethernet'"
        [@fadeInOut]
        class="d-flex flex-column pt-28 pb-28">
        <div class="ethernet-block">
          <div class="ethernet-block__title">
            {{ "Controller.NetworkSettings.IPAddress" | translate }}
          </div>
          <div class="ethernet-block__value">
            {{ form.controls["lanIp"].value }}
          </div>
        </div>
        <div class="ethernet-block mt-18">
          <div class="ethernet-block__title">
            {{ "Controller.NetworkSettings.MAC" | translate }}
          </div>
          <div class="ethernet-block__value">
            {{ form.controls["lanMac"].value }}
          </div>
        </div>
      </div>
      <div
        *ngIf="menuItem === 'wifi'"
        [@fadeInOut]
        class="d-flex flex-column gap-28 mono-dark">
        <div>
          <h3 class="fw-600 fz-18 mb-8">
            {{ "Controller.NetworkSettings.Wifi" | translate }}
          </h3>
          <p>Wifi will be implemented in future</p>
        </div>
      </div>

      <div
        *ngIf="menuItem === 'cellular'"
        [@fadeInOut]
        class="d-flex flex-column gap-28 mono-dark">
        <div>
          <h3 class="fw-600 fz-18 mb-8">
            {{ "Controller.NetworkSettings.Cellular" | translate }}
          </h3>
          <p>Cellular will be implemented in future</p>
        </div>
      </div>
    </section>
  </div>
</div>
