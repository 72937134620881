<div
  [class.keyboard-mobile]="platformService.isMobileApp"
  [class.is-disabled]="isDisabled || !activeZone"
  class="keyboard">
  <div class="d-flex align-center justify-between">
    <h6 class="mb-10">{{ title | translate }}</h6>
    <ls-svg-icon
      *ngIf="platformService.isMobileApp"
      (click)="unsetSensorModeEmit()"
      [hasMargin]="true"
      path="common/cross"></ls-svg-icon>
  </div>

  <div
    [class.pointer-events-none]="platformService.isMobileApp && activeZone?.[isAverageKey]"
    class="icons gap-6">
    <ng-container *ngIf="sensorElements.length > 0; else noSensors">
      <div
        *ngFor="let element of sensorElements"
        (click)="toggleSensorElement(element.elementID)"
        [class.icon-selected]="selectedSensorsIDs.includes(element.elementID) && !activeZone?.[isAverageKey]"
        class="icon pointer">
        <ls-svg-icon
          [color]="
          selectedSensorsIDs.includes(element.elementID) || activeZone?.[isAverageKey]
            ? ColorsEnum.DefaultDisabled
            : ColorsEnum.BlueDefault
        "
          [path]="iconPath"
          class="mb-5"></ls-svg-icon>
        <span>{{ element.number }}</span>
      </div>
    </ng-container>
  </div>

  <div class="buttons d-flex justify-between">
    <ls-svg-icon
      [color]="ColorsEnum.DefaultDisabled"
      [hasMargin]="true"
      [path]="'keyboard/123-disabled'"></ls-svg-icon>

    <div class="d-flex">
      <ls-svg-icon
        [path]="'keyboard/backspace-disabled'"
        class="mr-11"></ls-svg-icon>
      <ls-svg-icon
        (click)="saveSensorsEmit()"
        [path]="'keyboard/enter-long'"
        [class.disabled]="isDisabled || !activeZone"
        class="pointer"></ls-svg-icon>
    </div>
  </div>
</div>

<ng-template #noSensors>
  <h6>{{ "SensorKeyboard.NoSensorsAvailable" | translate }}</h6>
</ng-template>
