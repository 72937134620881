<div
  *ngIf="!isLoading; else loading"
  [class.flex-column]="editMode"
  [class.rtl]="languageService.isRtl"
  class="d-flex justify-between">
  <div
    *ngIf="!editMode"
    class="stages">
    <h6 class="stages-title mb-15 fw-500 color-secondary fz-18">
      {{ "TemperatureMapping.Stage" | translate }}
    </h6>

    <ng-container *ngFor="let stage of TempMappingStagesEnum | enumToArray">
      <div
        *ngIf="stage !== TempMappingStagesEnum.Brooding2 || brooding2IsShown"
        (click)="changeActiveStage(stage)"
        [class.stage-active]="stage === activeStage"
        class="stage">
        {{ stage | memoizeFunc : tempMappingStagesToTitle | translate }}
      </div>
    </ng-container>
  </div>

  <div
    *ngIf="editMode"
    class="current-stage d-flex align-center justify-between mb-40">
    <div class="">
      <h6 class="stages-title mb-5 color-secondary">
        {{ "TemperatureMapping.Stage" | translate }}
      </h6>

      <h6 class="fw-500 color-secondary fz-18">
        {{ activeStage | memoizeFunc : tempMappingStagesToTitle | translate }}
      </h6>
    </div>

    <ls-svg-icon
      (click)="openBrooding2Popup()"
      [path]="'common/dots'"></ls-svg-icon>
  </div>

  <div
    [class.devices-full]="editMode"
    class="devices position-relative">
    <h6 class="devices-title position-absolute color-secondary d-flex align-center line-height-1">
      {{ "TemperatureMapping.Device" | translate }}

      <ng-container
        *ngIf="[activeStage, activeElementIndex] | memoizeFunc : getHeatingElementType : this as heatingType">
        <span class="device-heating__type ml-8">
          {{ heatingType === ElementTypesEnum.HeatingDO ? "D" : "A" }}
        </span>
      </ng-container>
    </h6>

    <div class="devices-slider position-relative">
      <div class="swiper-navigation swiper-arrows">
        <div class="swiper-navigation-prev"></div>
        <div class="swiper-navigation-next"></div>
      </div>

      <ng-container *ngIf="!isLoading; else loading">
        <swiper
          *ngIf="swiperService.swiperIsActive"
          (activeIndexChange)="changeActiveElementIndex($event)"
          #swiper
          [config]="swiperService.config"
          [dir]="languageService.direction"
          [navigation]="{ prevEl: '.swiper-navigation-prev', nextEl: '.swiper-navigation-next' }"
          [slideIndexKeyFromStorage]="StorageItem.ProgramMenuSlide"
          class="swiper overflow-y-auto pb-15"
          swiperDirective>
          <ng-container [ngSwitch]="activeStage">
            <ng-container
              *ngSwitchCase="TempMappingStagesEnum.FullHouse"
              [ngTemplateOutlet]="dash"></ng-container>

            <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding1">
              <ng-container *ngIf="brooding1HeatingZones.length > 0 || brooding1ElementMappings.length > 0; else dash">
                <ng-container *ngFor="let brooding1Zone of brooding1HeatingZones; index as index">
                  <ng-template swiperSlide>
                    <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                      <span class="element-title d-block mr-5">{{ brooding1Zone.name }}</span>
                      {{ index + 1 }}/{{ brooding1HeatingZones.length + brooding1ElementMappings.length }}
                    </h6>
                  </ng-template>
                </ng-container>

                <ng-container *ngFor="let brooding1Element of brooding1ElementMappings; index as index">
                  <ng-template swiperSlide>
                    <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                      <span class="element-title d-block mr-5">{{ brooding1Element.elementName }}</span>
                      {{ index + 1 + brooding1HeatingZones.length }}/{{
                        brooding1HeatingZones.length + brooding1ElementMappings.length
                      }}
                    </h6>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding2">
              <ng-container *ngIf="brooding2HeatingZones.length > 0 || brooding2ElementMappings.length > 0; else dash">
                <ng-container *ngFor="let brooding2Zone of brooding2HeatingZones; index as index">
                  <ng-template swiperSlide>
                    <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                      <span class="element-title d-block mr-5">{{ brooding2Zone.name }}</span>
                      {{ index + 1 }}/{{ brooding2HeatingZones.length + brooding2ElementMappings.length }}
                    </h6>
                  </ng-template>
                </ng-container>

                <ng-container *ngFor="let brooding2Element of brooding2ElementMappings; index as index">
                  <ng-template swiperSlide>
                    <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                      <span class="element-title d-block mr-5">{{ brooding2Element.elementName }}</span>
                      {{ index + 1 + brooding2HeatingZones.length }}/{{
                        brooding2HeatingZones.length + brooding2ElementMappings.length
                      }}
                    </h6>
                  </ng-template>
                </ng-container>
              </ng-container>
            </ng-container>

            <ng-container *ngSwitchCase="TempMappingStagesEnum.Generals">
              <ng-template swiperSlide>
                <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                  <span class="element-title d-block mr-5">{{ "TemperatureMapping.Inlet" | translate }}</span>
                  1/2
                </h6>
              </ng-template>

              <ng-template swiperSlide>
                <h6 class="fw-500 fz-18 color-secondary d-flex align-center swiper-slide__title">
                  <span class="element-title d-block mr-5">{{ "TemperatureMapping.StirFan" | translate }}</span>
                  2/2
                </h6>
              </ng-template>
            </ng-container>

            <ng-template #dash>
              <ng-template swiperSlide>
                <h6 class="swiper-slide__title">-</h6>
              </ng-template>
            </ng-template>
          </ng-container>
        </swiper>
      </ng-container>

      <div class="devices-table">
        <div class="table w-1-33 w-2-67">
          <div class="table-row">
            <div class="table-cell light-blue">{{ "TemperatureMapping.Avg" | translate }}</div>
            <div
              [class.gray]="
                (activeStage === TempMappingStagesEnum.Brooding1 &&
                  activeElementIndex < brooding1HeatingZones.length) ||
                (activeStage === TempMappingStagesEnum.Brooding2 && activeElementIndex < brooding2HeatingZones.length)
              "
              class="table-cell">
              <ng-container [ngSwitch]="activeStage">
                <ng-container *ngSwitchCase="TempMappingStagesEnum.FullHouse">
                  <mat-checkbox
                    (change)="changeIsAverage($event, 0)"
                    [checked]="fullHouse.average"
                    [class.pointer-events-none]="!editMode"
                    class="checkbox checkbox-active-color">
                  </mat-checkbox>
                </ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding1">
                  <ng-container
                    *ngIf="
                      brooding1HeatingZones.length === 0 ||
                      (brooding1HeatingZones.length > 0 && activeElementIndex >= brooding1HeatingZones.length)
                    ">
                    <mat-checkbox
                      (change)="
                        changeIsAverage(
                          $event,
                          activeElementIndex - brooding1HeatingZones.length,
                          brooding1ElementMappings[activeElementIndex - brooding1HeatingZones.length]?.elementID
                        )
                      "
                      [checked]="brooding1ElementMappings[activeElementIndex - brooding1HeatingZones.length]?.average"
                      [class.pointer-events-none]="!editMode"
                      class="checkbox checkbox-active-color">
                    </mat-checkbox>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding2">
                  <ng-container
                    *ngIf="
                      (brooding2HeatingZones.length === 0 && brooding2ElementMappings.length > 0) ||
                      (brooding2HeatingZones.length > 0 &&
                        brooding2ElementMappings.length > 0 &&
                        activeElementIndex >= brooding2HeatingZones.length)
                    ">
                    <mat-checkbox
                      [checked]="brooding2ElementMappings[activeElementIndex - brooding2HeatingZones.length]?.average"
                      class="checkbox checkbox-active-color pointer-events-none">
                    </mat-checkbox>
                  </ng-container>
                </ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Generals"></ng-container>
              </ng-container>
            </div>
          </div>

          <div
            (click)="activateSensorKeyboard()"
            [class.selected]="editMode && sensorKeyboardIsActive"
            class="table-row pointer">
            <div class="table-cell light-blue">{{ "TemperatureMapping.Temp" | translate }}</div>
            <div
              [class.active-cell]="editMode && sensorKeyboardIsActive"
              class="table-cell">
              <ng-container [ngSwitch]="activeStage">
                <!--FULL HOUSE-->
                <ng-container *ngSwitchCase="TempMappingStagesEnum.FullHouse">
                  <ng-container
                    [ngTemplateOutletContext]="{ sensorList: fullHouse.sensors }"
                    [ngTemplateOutlet]="fullHouse.average ? averageSensors : sensors"></ng-container>
                </ng-container>

                <!--BROODING 1-->
                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding1">
                  <ng-container
                    *ngIf="brooding1HeatingZones.length > 0 && activeElementIndex < brooding1HeatingZones.length">
                    <ng-container
                      [ngTemplateOutletContext]="{
                        sensorList: brooding1HeatingZones[activeElementIndex]?.sensorElements
                      }"
                      [ngTemplateOutlet]="
                        brooding1HeatingZones[activeElementIndex]?.isAverage ? averageSensors : sensors
                      ">
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      brooding1HeatingZones.length === 0 ||
                      (brooding1HeatingZones.length > 0 && activeElementIndex >= brooding1HeatingZones.length)
                    ">
                    <ng-container
                      [ngTemplateOutletContext]="{
                        sensorList: brooding1ElementMappings[activeElementIndex - brooding1HeatingZones.length]?.sensors
                      }"
                      [ngTemplateOutlet]="
                        brooding1ElementMappings[activeElementIndex - brooding1HeatingZones.length]?.average
                          ? averageSensors
                          : sensors
                      ">
                    </ng-container>
                  </ng-container>
                </ng-container>

                <!--BROODING 2-->
                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding2">
                  <ng-container
                    *ngIf="brooding2HeatingZones.length > 0 && activeElementIndex < brooding2HeatingZones.length">
                    <ng-container
                      [ngTemplateOutletContext]="{
                        sensorList: brooding2HeatingZones[activeElementIndex]?.sensorElements
                      }"
                      [ngTemplateOutlet]="
                        brooding2HeatingZones[activeElementIndex]?.isAverage ? averageSensors : sensors
                      ">
                    </ng-container>
                  </ng-container>

                  <ng-container
                    *ngIf="
                      brooding2HeatingZones.length === 0 ||
                      (brooding2HeatingZones.length > 0 && activeElementIndex >= brooding2HeatingZones.length)
                    ">
                    <ng-container
                      [ngTemplateOutletContext]="{ sensorList: brooding2ElementMappings[activeElementIndex]?.sensors }"
                      [ngTemplateOutlet]="
                        brooding2ElementMappings[activeElementIndex]?.average ? averageSensors : sensors
                      ">
                    </ng-container>
                  </ng-container>
                </ng-container>

                <!--GENERALS-->
                <ng-container *ngSwitchCase="TempMappingStagesEnum.Generals"></ng-container>

                <ng-template
                  #sensors
                  let-sensorList="sensorList">
                  <div
                    *ngFor="let sensor of sensorList; index as index; trackBy: trackBy"
                    class="sensor">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/temp'"
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ sensor.number }}</span>
                  </div>
                </ng-template>
              </ng-container>
            </div>
          </div>

          <div class="table-row">
            <div class="table-cell light-blue">{{ "TemperatureMapping.Heaters" | translate }}</div>
            <div
              [class.gray]="
                activeStage === TempMappingStagesEnum.FullHouse ||
                (activeStage === TempMappingStagesEnum.Brooding1 &&
                  activeElementIndex >= brooding1HeatingZones.length) ||
                (activeStage === TempMappingStagesEnum.Brooding2 && activeElementIndex >= brooding2HeatingZones.length)
              "
              class="table-cell">
              <ng-container [ngSwitch]="activeStage">
                <ng-container *ngSwitchCase="TempMappingStagesEnum.FullHouse"></ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding1">
                  <div
                    *ngFor="let heater of brooding1HeatingZones[activeElementIndex]?.heatingElements; trackBy: trackBy"
                    class="heater">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/heat'"
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Brooding2">
                  <div
                    *ngFor="let heater of brooding2HeatingZones[activeElementIndex]?.heatingElements; trackBy: trackBy"
                    class="heater">
                    <ls-svg-icon
                      [color]="ColorsEnum.BlueDefault"
                      [fullHeight]="true"
                      [path]="'heating/heat'"
                      class="mb-5"></ls-svg-icon>
                    <span class="fz-12 color-tertiary">{{ heater.number }}</span>
                  </div>
                </ng-container>

                <ng-container *ngSwitchCase="TempMappingStagesEnum.Generals"></ng-container>
              </ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div
  *ngIf="editMode"
  class="buttons mt-20">
  <div class="mb-20 d-flex align-center">
    <ls-button
      (click)="moveElement()"
      [class.disabled]="
        (activeStage === TempMappingStagesEnum.Brooding1 &&
          brooding1HeatingZones.length === 0 &&
          brooding1ElementMappings.length === 0) ||
        (activeStage === TempMappingStagesEnum.Brooding2 &&
          brooding2HeatingZones.length === 0 &&
          brooding2ElementMappings.length === 0) ||
        !brooding2IsShown ||
        isLoading ||
        ![TempMappingStagesEnum.Brooding1, TempMappingStagesEnum.Brooding2].includes(activeStage)
      "
      class="w-70 d-block mr-10"
      type="secondary">
      {{ "Buttons.Move" | translate }}
    </ls-button>

    <div class="d-flex action-icons">
      <ls-svg-icon
        (click)="addBrooding2Stage()"
        [class.disabled]="brooding2IsShown || isLoading"
        [class]="brooding2IsShown || isLoading ? 'color-disabled' : 'color-green'"
        [fullHeight]="true"
        [path]="'common/add'"
        class="mr-10">
      </ls-svg-icon>

      <ls-svg-icon
        (click)="removeBrooding2Stage()"
        [class.disabled]="activeStage !== TempMappingStagesEnum.Brooding2 || isLoading"
        [class]="activeStage !== TempMappingStagesEnum.Brooding2 || isLoading ? 'color-disabled' : 'color-green'"
        [fullHeight]="true"
        [path]="'common/delete'">
      </ls-svg-icon>
    </div>
  </div>

  <div class="ph-20 d-flex align-center justify-between">
    <ls-button
      (click)="closeEditModeEmit()"
      [class.disabled]="isLoading"
      [qaTags]="'qa-btn-cancel'"
      class="mr-20 w-120"
      type="secondary">
      {{ "Buttons.Cancel" | translate }}
    </ls-button>

    <ls-button
      (click)="save()"
      [class.disabled]="isLoading || !isDirtyForm"
      [qaTags]="'qa-btn-save'"
      class="w-120"
      type="primary">
      {{ "Buttons.Save" | translate }}
    </ls-button>
  </div>
</div>

<ls-sensors-keyboard
  *ngIf="editMode && sensorKeyboardIsActive"
  (saveSensors)="saveSensors($event)"
  (unsetSensorMode)="hideSensorKeyboard()"
  [activeZone]="activeElement$ | async"
  [sensorElements]="sensors$ | async"
  [sensorsKey]="'sensors'"></ls-sensors-keyboard>

<ng-template #averageSensors>
  <div class="sensor sensor-all">
    <ls-svg-icon
      [color]="ColorsEnum.BlueDefault"
      [fullHeight]="true"
      [path]="'heating/temp'"
      class="mb-5"></ls-svg-icon>
    <span class="fz-12 color-tertiary">{{ "TemperatureMapping.All" | translate }}</span>
  </div>
</ng-template>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
