import { Component, Input, forwardRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { SwiperModule } from 'swiper/angular';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MomentDateFormatPipe } from '@livestock/shared/pipes';
import { TimePickerDialogComponent } from '@livestock/ui';
import { MatDialog } from '@angular/material/dialog';
import { lastValueFrom } from 'rxjs';
import { Moment } from 'moment';

@Component({
  selector: 'ls-time-picker-dialog-control',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    SwiperModule,
    MomentDateFormatPipe,
  ],
  templateUrl: './time-picker-dialog-control.component.html',
  styleUrls: ['./time-picker-dialog-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerDialogControlComponent),
      multi: true,
    },
  ],
})
export class TimePickerDialogControlComponent implements ControlValueAccessor {
  @Input() editMode: boolean = false;
  @Input() isDisabled: boolean = false;
  @Input() isFieldInvalid: boolean = false;
  @Input() enableMeridian: boolean = false;

  // vars
  value: Moment;
  isOpen: boolean = false;

  constructor(
    private dialog: MatDialog,
  ) {
  }

  async openTimePickerDialog(value): Promise<void> {
    const dialogRef = this.dialog.open(TimePickerDialogComponent, {
      width: '100%',
      height: '30%',
      position: { top:'auto' },
    });

    this.isOpen = true;
    dialogRef.componentInstance.setup(value, this.enableMeridian);
    const res = await lastValueFrom(dialogRef.afterClosed());

    if (res != null) {
      this.writeValue(res);
      this.isOpen = false;
      this.onChange(res);
    }
  }

  writeValue(value: Moment): void {
    if (value == null) return;
    this.value = value;
  }

  registerOnChange(onChange: (value: Moment) => void): void {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: () => void): void {
    this.onTouched = onTouched;
  }

  private onTouched = (): void => {};
  private onChange: (value: Moment) => void = () => {};
}