import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'findNextTo',
  standalone: true,
})
export class FindNextToPipe implements PipeTransform {
  transform(array, current): number {
    const nextDayNumber = array[array.findIndex(period => period.dayNumber === current.dayNumber) + 1]?.dayNumber;

    return nextDayNumber || current.dayNumber;
  }
}
