<div class="popup text-left">
  <h3 class="popup-title fz-20 mb-10 ml-12 mr-12 d-flex align-center justify-between">
    <span>{{ "Controller.GeneralSettings.UnitsDialog.SelectUnits" | translate }}</span>
    <img
      (click)="close(null)"
      [qaTags]="'qa-btn-close'"
      alt="cross"
      class="pointer"
      src="/assets/icons/common/cross.svg" />
  </h3>
  <div class="ls-radio-container mt-10">
    <mat-radio-group
      (change)="changeValue($event)"
      [qaTags]="'qa-radio-group'"
      [(ngModel)]="selectedValue"
      aria-labelledby="ls-radio-group-label"
      class="ls-radio-group">
      <mat-radio-button
        [qaTags]="'qa-radio-group-button-0'"
        [value]="ControllerUnitEnum.Metric"
        class="ls-radio-group__button overrided-mat-radio-button">
        {{ ControllerUnitEnum.Metric | enum : "controller-unit" | translate }}
      </mat-radio-button>
      <mat-radio-button
        [qaTags]="'qa-radio-group-button-1'"
        [value]="ControllerUnitEnum.Imperial"
        class="ls-radio-group__button overrided-mat-radio-button">
        {{ ControllerUnitEnum.Imperial | enum : "controller-unit" | translate }}
      </mat-radio-button>

      <div
        (click)="close(ControllerUnitEnum.Custom)"
        class="d-flex flex-row ls-radio-group__custom mt-15 pt-15 pointer">
        <mat-radio-button
          [qaTags]="'qa-radio-group-button-2'"
          [value]="ControllerUnitEnum.Custom"
          class="ls-radio-group__button overrided-mat-radio-button">
          {{ ControllerUnitEnum.Custom | enum : "controller-unit" | translate }}
        </mat-radio-button>
        <div class="ls-radio-group__custom_img-container">
          <img
            class="ls-radio-group__custom_chevron"
            src="/assets/icons/arrows/chevron-right-grey.svg" />
        </div>
      </div>
    </mat-radio-group>
  </div>
</div>
