import { Action, createReducer, on } from '@ngrx/store';
import * as coolingActions from './cooling-program.actions';
import { CoolingProgramState } from './cooling-program.state';
import { CoolingPeriod } from '../interfaces/cooling.interfaces';

export const COOLING_FEATURE_KEY = 'coolingProgram';

export const initialState: CoolingProgramState = {
  isLoading: false,
  periods: null,
  currentProgram: null,
  currentPeriod: null,
};

const reducer = createReducer(
  initialState,
  on(
    coolingActions.getCoolingProgram,
    coolingActions.getCoolingPeriod,
    coolingActions.saveCoolingProgram,
    coolingActions.deleteCoolingDay,
    coolingActions.deleteCoolingProgram,
    (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
  ),

  on(
    coolingActions.addCoolingDayError,
    coolingActions.getCoolingProgramError,
    coolingActions.deleteCoolingProgramError,
    coolingActions.getCoolingPeriodError,
    (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
  ),

  on(
    coolingActions.getCoolingProgramSuccess,
    (state, { item }) => {
      return {
        ...state,
        isLoading: false,
        currentProgram: item,
      };
    },
  ),
  on(
    coolingActions.deleteCoolingProgramSuccess,
    (state, { periodID }) => {
      if (!periodID) return { ...state };
      return {
        ...state,
        isLoading: false,
        currentProgram: {
          ...state.currentProgram,
          items: state.currentProgram.items.filter(i => i.periodID !== periodID),
        },
      };
    },
  ),
  on(
    coolingActions.deleteCoolingDaySuccess,
    (state, { periodID, preventSelecting }) => {
      const periods = state.periods.filter(per => per.periodID !== periodID);
      return {
        ...state,
        isLoading: false,
        periods,
        currentPeriod: preventSelecting ? null : periods[0],
      };
    },
  ),

  on(
    coolingActions.addCoolingDaySuccess,
    (state, { dayNumber, controllerID }) => {
      const hasDay = state.periods.some(period => period.dayNumber === dayNumber);
      if (hasDay) {
        return state;
      }

      const periods: CoolingPeriod[] = [...state.periods || [], { dayNumber, controllerID }];

      return {
        ...state,
        isLoading: false,
        periods: periods.sort((a, b) => a.dayNumber - b.dayNumber),
        currentProgram: { hasElement1: true, hasElement2: true, hasElement3: true, items: [] },
        currentPeriod: {
          dayNumber,
          controllerID,
        },
      };
    },
  ),

  on(
    coolingActions.getCoolingPeriodSuccess,
    (state, { items, controllerID }) => {
      return {
        ...state,
        isLoading: false,
        periods: items.length ? items : [{
          controllerID,
          dayNumber: 0,
        }],
      };
    },
  ),
  on(
    coolingActions.clearProgram,
    (state) => {
      return {
        ...state,
        currentProgram: { hasElement1: true, hasElement2: true, hasElement3: true, items: [] },
      };
    },
  ),
  on(
    coolingActions.filterPeriods,
    (state, { currentPeriod }) => {
      return {
        ...state,
        periods: state.periods?.length > 1 ? filterUnusedPeriods(currentPeriod, [...state.periods]) : [...state.periods],
        currentPeriod: currentPeriod ?? state.periods[0],
      };
    },
  ),
  on(
    coolingActions.saveCoolingProgramSuccess,
    (state, { period, items }) => {

      // If we've added first real day which is not 0,
      // remove the first default day 0 which is not saved.
      const skipFirstDefaultDay =
        state.periods.length === 2 &&
        state.periods[0].dayNumber === 0 &&
        state.periods[0]?.periodID == null &&
        state.periods[1]?.periodID == null;

      return {
        ...state,
        isLoading: false,
        periods: ([...state.periods].splice(skipFirstDefaultDay ? 1 : 0)).map(i => i.periodID ? ({ ...i }) : ({
          ...i,
          periodID: period.periodID,
        })),
        currentPeriod: period,
        currentProgram: {
          ...state.currentProgram,
          items,
        },
      };
    },
  ),

  on(
    coolingActions.setCurrentCoolingProgram,
    (state, { currentProgram }) => {
      return {
        ...state,
        currentProgram,
      };
    },
  ),

  on(
    coolingActions.setCurrentCoolingPeriod,
    (state, { currentPeriod }) => {
      return {
        ...state,
        currentPeriod,
      };
    },
  ),

  on(
    coolingActions.resetCoolingProgram,
    () => {
      return { ...initialState };
    },
  ),
);

export function coolingReducer(
  state: CoolingProgramState | undefined,
  action: Action,
): CoolingProgramState {
  return reducer(state, action);
}

export function filterUnusedPeriods(currentPeriod: CoolingPeriod, periods: CoolingPeriod[], key = 'periodID'): CoolingPeriod[] {
  if (!currentPeriod?.periodID) return periods.filter(period => !!period.periodID);

  // remove duplicate periods with same periodID's and replace with saved period (currentPeriod)
  return [...new Map(periods.map(item => [item[key], item])).values()]
    .map(period => period.periodID === currentPeriod?.periodID ? currentPeriod : period);
}
