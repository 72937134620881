import { Component, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { fadeOut } from './fade.animation';

@Component({
  selector: 'ls-preloader',
  templateUrl: './preloader.component.html',
  styleUrls: ['./preloader.component.scss'],
  standalone: true,
  animations: [fadeOut],
  imports: [
    CommonModule,
  ],
})
export class PreloaderComponent implements OnInit {
  showLoader = true;

  ngOnInit(): void {
    setTimeout(() => {
      this.showLoader = false;
    }, 300);
  }
}
