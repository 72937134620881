import { Component, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { ColorsEnum } from '@livestock/shared/enums';
import { IElement } from '@livestock/installation/interfaces';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { PlatformService } from '@livestock/shared/services';

@Component({
  selector: 'ls-sensors-keyboard',
  templateUrl: 'sensors-keyboard.component.html',
  styleUrls: ['./sensors-keyboard.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    TranslateModule,
    MemoizeFuncPipe,
  ],
})
export class SensorsKeyboardComponent {
  @Input() sensorElements: IElement[] = [];
  @Input() isDisabled: boolean;
  @Input() title = 'SensorKeyboard.SelectTempSensors';
  @Input() iconPath: string = 'heating/temp';
  @Input() sensorsKey = 'sensorElements';
  @Input() isAverageKey = 'isAverage';
  @Output() unsetSensorMode = new EventEmitter();
  @Output() saveSensors = new EventEmitter<IElement[]>();
  selectedSensorsIDs: number[] = [];
  ColorsEnum = ColorsEnum;
  private _activeZone: any;

  get activeZone(): any {
    return this._activeZone;
  }

  @Input() set activeZone(activeZone: any) {
    this._activeZone = activeZone;
    setTimeout(() => {
      this.selectedSensorsIDs = activeZone?.[this.sensorsKey].map(sensor => sensor.elementID) || [];
    });
  }

  constructor(public platformService: PlatformService) {
  }

  toggleSensorElement(elementID: number): void {
    if (this.selectedSensorsIDs.includes(elementID)) {
      this.selectedSensorsIDs = this.selectedSensorsIDs.filter(id => id !== elementID);
      return;
    }

    this.selectedSensorsIDs = [...this.selectedSensorsIDs, elementID];
  }

  saveSensorsEmit(): void {
    const sensors: IElement[] = this.sensorElements
      .filter(sensor => this.selectedSensorsIDs.includes(sensor.elementID));
    this.saveSensors.emit(sensors);
  }

  unsetSensorModeEmit(): void {
    this.unsetSensorMode.emit();
  }
}
