import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { IGetLightingMapping } from '../interfaces/get-lighting-mapping.interface';
import { ILightingMappingItemUpdate } from '../interfaces/lighting-mapping-item-update.interface';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class LightingMappingApiService {

  constructor(
    private http: HttpClient,
  ) {
  }

  getLightingMappings(controllerID: number): Observable<IGetLightingMapping> {
    return this.http.get<IGetLightingMapping>(`${baseUrl}/${controllerID}/lighting-mapping`);
  }

  updateLightingMappings(controllerID: number, view: ILightingMappingItemUpdate[]): Observable<void> {
    return this.http.put<void>(`${baseUrl}/${controllerID}/lighting-mapping`, view);
  }
}
