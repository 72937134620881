import { createFeatureSelector, createSelector } from '@ngrx/store';
import { VentilationBrandsState } from './ventilation-brands.state';

export const VENTILATION_BRANDS_KEY = 'ventilationBrands';

export const getVentilationBrandsState =
  createFeatureSelector<VentilationBrandsState>(VENTILATION_BRANDS_KEY);

export const selectIsLoading = createSelector(
  getVentilationBrandsState,
  ({ isLoading }) => isLoading,
);

export const selectVentilationBrands = createSelector(
  getVentilationBrandsState,
  ({ ventilationBrands }) => ventilationBrands,
);

export const selectVentilationDOModels = createSelector(
  getVentilationBrandsState,
  ({ ventilationDOModels }) => ventilationDOModels,
);

export const selectVentilationDOModelsByBrandID = createSelector(
  getVentilationBrandsState,
  ({ ventilationDOModels }) => (brandID: number) => {
    return ventilationDOModels.filter(model => model.brandID === brandID);
  },
);

export const selectVentilationDOModelByID = createSelector(
  selectVentilationDOModels,
  (ventilationDOModels) => (modelID: number, brandID: number) => {
    return ventilationDOModels.find(model => model.modelID === modelID && model.brandID === brandID);
  },
);

export const selectVentilationAOModels = createSelector(
  getVentilationBrandsState,
  ({ ventilationAOModels }) => ventilationAOModels,
);

export const selectVentilationAOModelsByBrandID = createSelector(
  getVentilationBrandsState,
  ({ ventilationAOModels }) => (brandID: number) => {
    return ventilationAOModels.filter(model => model.brandID === brandID);
  },
);

export const selectVentilationAOModelByID = createSelector(
  selectVentilationAOModels,
  (ventilationAOModels) => (modelID: number, brandID: number) => {
    return ventilationAOModels.find(model => model.modelID === modelID && model.brandID === brandID);
  },
);
