import { ChangeDetectorRef, Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ButtonComponent, SensorsKeyboardComponent, SvgIconComponent } from '@livestock/ui';
import { CommonModule } from '@angular/common';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { Store } from '@ngrx/store';
import { ColorsEnum, ElementTypesEnum, StorageItem } from '@livestock/shared/enums';
import { SwiperComponent, SwiperModule } from 'swiper/angular';
import { IHeatingProgramZoneItem } from '../../interfaces';
import {
  addHeaterToMovingArray,
  addHeatingProgramZone,
  addSensorsElementsToZone,
  changeIsAverage,
  HeatersZoneModeEnum,
  moveHeatersToAnotherZone,
  removeHeaterFromMovingArray,
  selectActiveZoneID,
  selectActiveZoneIndex,
  selectHeatingProgramActiveZone,
  selectHeatingProgramZones,
  selectSelectedHeatersIDs,
  setActiveHeatingProgramZone,
} from '@livestock/heating-program-zone';
import { MatCheckboxChange, MatCheckboxModule } from '@angular/material/checkbox';
import { MemoizeFuncPipe } from '@livestock/shared/pipes';
import { filter, firstValueFrom, lastValueFrom, Observable, Subscription } from 'rxjs';
import { IElement } from '@livestock/installation/interfaces';
import { LanguageService, LocalStorageService, SwiperService } from '@livestock/shared/services';
import { SwiperDirective } from '@livestock/shared/directives';
import { MoveHeatersPopupComponent } from '../move-heaters-popup/move-heaters-popup.component';
import { MatDialog } from '@angular/material/dialog';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';

@Component({
  selector: 'ls-heating-program-zone-mobile',
  templateUrl: 'heating-program-zone-mobile.component.html',
  styleUrls: ['heating-program-zone-mobile.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    SvgIconComponent,
    TranslateModule,
    ButtonComponent,
    SwiperModule,
    MatCheckboxModule,
    MemoizeFuncPipe,
    SensorsKeyboardComponent,
    SwiperDirective,
  ],
})
export class HeatingProgramZoneMobileComponent implements OnInit, OnDestroy {
  @ViewChild('swiper', { static: false }) swiper?: SwiperComponent;
  @Input() zones: IHeatingProgramZoneItem[] = [];
  @Input() someZonesAreEmpty: boolean;
  @Input() heatingAOElements: IElement[] = [];
  @Input() heatingDOElements: IElement[] = [];
  @Input() sensorElements: IElement[] = [];
  @Input() noChangesMade: boolean = false;
  @Output() changeZoneName = new EventEmitter<Event>();
  @Output() toggleEditMode = new EventEmitter();
  @Output() setSensorMode = new EventEmitter();
  @Output() removeZone = new EventEmitter();
  @Output() save = new EventEmitter();

  // sub
  sub$ = new Subscription();
  zones$: Observable<IHeatingProgramZoneItem[]> = this.store.select(selectHeatingProgramZones);
  activeZoneID$: Observable<number> = this.store.select(selectActiveZoneID);
  activeZone$: Observable<IHeatingProgramZoneItem> = this.store.select(selectHeatingProgramActiveZone);
  selectedHeatersIDs$: Observable<number[]> = this.store.select(selectSelectedHeatersIDs);

  // vars
  activeZoneIndex = 0;
  MAX_ZONES = 15;

  // enums
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;
  StorageItem = StorageItem;

  // private
  private _editMode: boolean;

  get editMode(): boolean {
    return this._editMode;
  }

  @Input() set editMode(editMode: boolean) {
    this._editMode = editMode;
    this.store.dispatch(setActiveHeatingProgramZone({ zoneID: editMode ? this.zones[this.activeZoneIndex]?.zoneID : null }));
  };

  private _sensorMode: boolean;

  get sensorMode(): boolean {
    return this._sensorMode;
  }

  @Input() set sensorMode(mode: HeatersZoneModeEnum | null) {
    this._sensorMode = mode === HeatersZoneModeEnum.Sensors;
  };

  constructor(
    public languageService: LanguageService,
    public cdr: ChangeDetectorRef,
    public swiperService: SwiperService,
    private store: Store,
    private dialog: MatDialog,
    private translateService: TranslateService,
  ) {
    this.swiperService.config = {
      spaceBetween: 20,
      navigation: false,
      slidesPerView: 1,
      initialSlide: Number(LocalStorageService.getStorageItem(StorageItem.ProgramMenuSlide)) || 0,
      centeredSlides: true,
    };
  }

  ngOnInit(): void {
    this.sub$.add(
      this.store.select(selectActiveZoneIndex).pipe(
        filter(zoneIndex => zoneIndex && zoneIndex !== -1),
      ).subscribe(zoneIndex => {
        this.activeZoneIndex = zoneIndex;
        LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, this.activeZoneIndex);
        setTimeout(() => this.swiper.swiperRef.slideTo(zoneIndex, 0));
      }),
    );
  }

  changeZoneNameEmit(ev: Event): void {
    this.changeZoneName.emit(ev);
  }

  disableSensorMode(): void {
    this._sensorMode = false;
  }

  changeActiveZone([swiper]): void {
    this.activeZoneIndex = swiper.realIndex;
    LocalStorageService.setStorageItem(StorageItem.ProgramMenuSlide, this.activeZoneIndex);

    if (this._editMode) {
      this.store.dispatch(setActiveHeatingProgramZone({ zoneID: this.zones[this.activeZoneIndex]?.zoneID }));
    }
  }

  changeIsAverage(isAverage: MatCheckboxChange): void {
    this.store.dispatch(changeIsAverage({ isAverage: isAverage.checked }));
  }

  toggleEditModeEmit(): void {
    this._sensorMode = false;
    this.toggleEditMode.emit(false);
  }

  enableSensorMode(): void {
    if (!this._editMode) return;
    this.setSensorMode.emit();
    this._sensorMode = true;
  }

  addZone(): void {
    this.store.dispatch(addHeatingProgramZone());
  }

  removeZoneEmit(): void {
    this.removeZone.emit();
  }

  emitSave(): void {
    this.save.emit();
  }

  saveSensors(sensors: IElement[]): void {
    this.store.dispatch(addSensorsElementsToZone({ sensors }));
  }

  unsetSensorMode(): void {
    this._sensorMode = false;
  }

  trackBy(index: number): number {
    return index;
  }

  goToPrevSlide(): void {
    this.swiper.swiperRef.slidePrev(100);
  }

  goToNextSlide(): void {
    this.swiper.swiperRef.slideNext(100);
  }

  async addOrRemoveHeaterToMovingArray(event: Event, elementID: number, zoneID: number): Promise<void> {
    event.stopPropagation();

    if (!this.editMode) return;

    const activeZoneID = await firstValueFrom(this.activeZoneID$);
    if (activeZoneID !== zoneID) {
      this.store.dispatch(setActiveHeatingProgramZone({ zoneID }));
    }

    const selectedHeaters = await firstValueFrom(this.selectedHeatersIDs$);
    if (selectedHeaters.includes(elementID)) {
      this.store.dispatch(removeHeaterFromMovingArray({ elementID }));
      return;
    }

    this.store.dispatch(addHeaterToMovingArray({ elementID }));
  }

  async openMoveHeatersPopup(): Promise<void> {
    const allZones = await firstValueFrom(this.zones$);
    const activeZone = await firstValueFrom(this.activeZone$);

    // we can't copy AO elements to zone with DO elements, so we should check the current type of heaters of active
    // zone and disable zones with other type
    const heatingElementsTypeToCopy = this.heatingAOElements.some(el => {
      return activeZone.heatingElements.map(heatingEl => heatingEl.elementID).includes(el.elementID);
    })
      ? this.heatingAOElements
      : this.heatingDOElements;

    const zonesForPopup = allZones
      .filter(zone => zone.zoneID !== activeZone.zoneID)
      .map(zone => {
        const isDisabled = !heatingElementsTypeToCopy.some(el => {
          return zone.heatingElements.length === 0
            || zone.heatingElements.map(heatingEl => heatingEl.elementID).includes(el.elementID);
        });

        return {
          id: zone.zoneID,
          name: zone.name,
          isDisabled,
        };
      });

    const dialogRef = this.dialog.open(MoveHeatersPopupComponent, {
      width: '300px',
      disableClose: true,
      data: { zones: zonesForPopup },
    });

    const result = await lastValueFrom(dialogRef.afterClosed());
    if (result?.zoneID) {
      this.store.dispatch(moveHeatersToAnotherZone({ zoneID: result.zoneID }));

      const flashMessage = this.translateService.instant('FlashMessages.MoveToZoneSuccessfullyWithValue', {
        value: result.zoneName,
      });
      this.store.dispatch(setFlashMessage({
        flashMessage: {
          message: flashMessage,
          flashType: FlashMessageTypeEnum.Success,
          doNotTranslate: true,
        },
      }));
    }
  }

  ngOnDestroy(): void {
    LocalStorageService.removeStorageItem(StorageItem.ProgramMenuSlide);
    this.sub$.unsubscribe();
  }
}
