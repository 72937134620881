import { ChangeDetectorRef, Directive, Input, OnDestroy, OnInit } from '@angular/core';
import { filter, pairwise, Subscription } from 'rxjs';
import { ControllerLanguageEnum } from '../../controllers/src';
import { LanguageService, LocalStorageService, SwiperService } from '../services';
import { StorageItem } from '../enums';

@Directive({
  selector: '[swiperDirective]',
  standalone: true,
})
export class SwiperDirective implements OnInit, OnDestroy {
  @Input('cdr') cdr: ChangeDetectorRef;
  @Input('slideIndexKeyFromStorage') slideIndexKeyFromStorage: StorageItem;
  @Input('initialSlideIndexDefault') initialSlideIndexDefault = 0;
  sub$ = new Subscription();

  constructor(
    private swiperService: SwiperService,
    private languageService: LanguageService,
  ) {
  }

  ngOnInit(): void {
    /* rebuild swiper if lang dir was changed */
    this.sub$.add(
      this.languageService.lang$.pipe(
        pairwise(),
        filter(([prevLang, newLang]: [ControllerLanguageEnum, ControllerLanguageEnum]) => {
          return prevLang === ControllerLanguageEnum.Hebrew || newLang === ControllerLanguageEnum.Hebrew;
        }),
      ).subscribe(() => {
        this.swiperService.swiperIsActive = false;
        this.cdr?.detectChanges();

        setTimeout(() => {
          this.swiperService.config = {
            ...this.swiperService.config,
            initialSlide: Number(LocalStorageService.getStorageItem(this.slideIndexKeyFromStorage)) ?? this.initialSlideIndexDefault,
          };

          this.swiperService.swiperIsActive = true;
          this.cdr?.detectChanges();
        }, 100);
      }),
    );
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
