<div
  [class.keyboard-disabled]="activeZone == null"
  class="keyboard">
  <h6 class="mb-10">{{ "HeatingProgramZone.SelectHeaters" | translate }}</h6>

  <div class="keyboard-modes d-flex mb-20">
    <div
      (click)="switchKeyboardMode(HeatersKeyboardModeEnum.Relay)"
      [class.disabled]="[HeatersKeyboardModeEnum.Relay, activeZone] | memoizeFunc : shouldOptionBeDisabled : this"
      [class.keyboard-mode__active]="keyboardMode === HeatersKeyboardModeEnum.Relay"
      class="keyboard-mode">
      {{ "HeatingProgramZone.Relay" | translate }}
    </div>

    <div
      (click)="switchKeyboardMode(HeatersKeyboardModeEnum.Analog)"
      [class.disabled]="[HeatersKeyboardModeEnum.Analog, activeZone] | memoizeFunc : shouldOptionBeDisabled : this"
      [class.keyboard-mode__active]="keyboardMode === HeatersKeyboardModeEnum.Analog"
      class="keyboard-mode">
      {{ "HeatingProgramZone.Analog" | translate }}
    </div>
  </div>

  <div class="icons gap-6">
    <ng-container *ngIf="keyboardMode === HeatersKeyboardModeEnum.Relay">
      <div
        *ngFor="let element of heatingDOElements$ | async"
        (click)="selectHeatingElement(element)"
        [ngClass]="
          !([element.elementID, zones] | memoizeFunc : isHeatingElementChosen) ? 'pointer' : 'pointer-events-none'
        "
        class="icon">
        <ls-svg-icon
          [color]="
            ([element.elementID, zones] | memoizeFunc : isHeatingElementChosen)
              ? ColorsEnum.DefaultDisabled
              : ColorsEnum.BlueDefault
          "
          [path]="'heating/heat'"
          class="mb-5"></ls-svg-icon>
        <span>{{ element.number }}</span>
      </div>
    </ng-container>

    <ng-container *ngIf="keyboardMode === HeatersKeyboardModeEnum.Analog">
      <div
        *ngFor="let element of heatingAOElements$ | async"
        (click)="selectHeatingElement(element)"
        [ngClass]="
          !([element.elementID, zones] | memoizeFunc : isHeatingElementChosen) ? 'pointer' : 'pointer-events-none'
        "
        class="icon">
        <ls-svg-icon
          [color]="
            ([element.elementID, zones] | memoizeFunc : isHeatingElementChosen)
              ? ColorsEnum.DefaultDisabled
              : ColorsEnum.BlueDefault
          "
          [path]="'heating/heat'"
          class="mb-5"></ls-svg-icon>
        <span>{{ element.number }}</span>
      </div>
    </ng-container>
  </div>

  <div class="buttons d-flex justify-between">
    <ls-svg-icon
      [color]="ColorsEnum.DefaultDisabled"
      [hasMargin]="true"
      [path]="'keyboard/123-disabled'"></ls-svg-icon>
    <ls-svg-icon
      [hasMargin]="true"
      [path]="'keyboard/backspace'"
      class="pointer"></ls-svg-icon>
    <ls-svg-icon
      (click)="goToNextZone()"
      [hasMargin]="true"
      [path]="'keyboard/enter-long'"
      class="pointer"></ls-svg-icon>
  </div>
</div>
