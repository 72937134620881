export enum CalibrationModeEnum {
  Custom,
  Hours,
}

export namespace CalibrationModeEnum {
  export function toTranslateKey(value: CalibrationModeEnum | number): string {
    switch (value) {
      case CalibrationModeEnum.Custom:
        return 'Controller.VentilationBasicSettings.Custom';
      case CalibrationModeEnum.Hours:
        return 'Controller.VentilationBasicSettings.Hours';
      default:
        return value.toString();
    }
  }
}
