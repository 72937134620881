import { Component, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { EnumPipe, EnumToArrayPipe, MemoizeFuncPipe } from '@livestock/shared/pipes';
import { ErrorFieldDirective, QaTagsDirective } from '@livestock/shared/directives';
import {
  InputDecimalComponent,
  InputIntegerComponent,
  InputTimeComponent,
  LoadingComponent,
  SelectDirective,
  setElementUuid,
  SvgIconComponent,
  ToggleComponent,
} from '@livestock/ui';
import {
  IFlockSettingsView,
  HouseModeEnum,
  StagingEnum, selectFlockCreationYear,
} from '@livestock/flock';
import { NgSelectModule } from '@ng-select/ng-select';
import { MatRadioModule } from '@angular/material/radio';
import { lastValueFrom, Subscription } from 'rxjs';
import { FlockSettingsConstants } from '../../constants/flock-settings.constants';
import {
  ControllerUnitEnum,
  UnitsDetailsDialogComponent, HoursFormatTypeEnum, selectHourFormatForTimePicker,
  UnitsDialogComponent,
} from '@livestock/controllers';
import { MatDialog } from '@angular/material/dialog';
import { GlobalConstants } from '@livestock/shared/constants';
import { ColorsEnum } from '@livestock/shared/enums';
import { ActivatedRouteService, LanguageService, PlatformService } from '@livestock/shared/services';
import { Router } from '@angular/router';
import { MatTooltipModule } from '@angular/material/tooltip';
import { wasChanged } from '@livestock/shared/rxjs-operators';
import { Store } from '@ngrx/store';
import { NativeElementInjectorDirective } from '../../../../../ui/src/lib/controls/native-element.directive';

@Component({
  selector: 'ls-flock-settings-form',
  standalone: true,
  imports: [
    CommonModule,
    ReactiveFormsModule,
    TranslateModule,
    NgSelectModule,
    MatRadioModule,
    EnumPipe,
    EnumToArrayPipe,
    QaTagsDirective,
    ErrorFieldDirective,
    LoadingComponent,
    InputIntegerComponent,
    ToggleComponent,
    SelectDirective,
    SvgIconComponent,
    MemoizeFuncPipe,
    MatTooltipModule,
    InputDecimalComponent,
    NativeElementInjectorDirective,
    InputTimeComponent,
  ],
  templateUrl: './settings-form.component.html',
  styleUrls: ['./settings-form.component.scss'],
})
export class FlockSettingsFormComponent implements OnInit, OnDestroy {
  @Input() isLoading: boolean;
  @Input() editMode: boolean;
  @Input() isNewFlock: boolean;
  @Input() originalSettings: IFlockSettingsView;

  @Input() set settings(settings: IFlockSettingsView) {
    if (settings) {
      this.updateFormValues(settings);
    }
  }

  @Output() changed = new EventEmitter();

  //subs
  sub$ = new Subscription();
  flockCreationYear$ = this.store.select(selectFlockCreationYear);
  selectHourFormatForTimePicker$ = this.store.select(selectHourFormatForTimePicker);

  //vars
  minWindowHeight: number = 600;
  currentTimeUUID: string;
  form = new FormGroup(
    {
      controllerID: new FormControl(),
      growthDay: new FormControl(0, [
        Validators.required,
        Validators.min(FlockSettingsConstants.GrowthDayMin),
        Validators.max(FlockSettingsConstants.GrowthDayMax),
      ]),
      flockNumber: new FormControl<number | string>(1, [
        Validators.required,
        Validators.min(FlockSettingsConstants.FlockNumberMin),
        Validators.max(FlockSettingsConstants.FlockNumberMax),
      ]),
      separateMaleAndFemale: new FormControl(false),
      initialNumberOfBirds: new FormControl(0, [
        Validators.required,
        Validators.min(FlockSettingsConstants.InitialNumberOfBirdsMin),
        Validators.max(FlockSettingsConstants.InitialNumberOfBirdsMax),
      ]),
      femaleInitialNumberOfBirds: new FormControl(0, [
        Validators.required,
        Validators.min(FlockSettingsConstants.FemaleInitialNumberOfBirdsMin),
        Validators.max(FlockSettingsConstants.FemaleInitialNumberOfBirdsMax),
      ]),
      maleInitialNumberOfBirds: new FormControl(0, [
        Validators.required,
        Validators.min(FlockSettingsConstants.MaleInitialNumberOfBirdsMin),
        Validators.max(FlockSettingsConstants.MaleInitialNumberOfBirdsMax),
      ]),
      houseMode: new FormControl(HouseModeEnum.Growing, [Validators.required]),
      staging: new FormControl(StagingEnum.FullHouse, [Validators.required]),
      units: new FormControl(ControllerUnitEnum.Metric, [Validators.required]),
      unitsDetails: new FormControl(GlobalConstants.DefaultUnitsDetails),
      timeNextStage: new FormControl<number>(null),
    },
  );

  //constants
  HouseModeEnum = HouseModeEnum;
  StagingEnum = StagingEnum;
  ControllerUnitEnum = ControllerUnitEnum;
  ColorsEnum = ColorsEnum;
  FlockSettingsConstants = FlockSettingsConstants;
  HoursFormatTypeEnum = HoursFormatTypeEnum;

  get isWindowHeightSmall(): boolean {
    return window.innerHeight < this.minWindowHeight;
  }

  constructor(
    public router: Router,
    public store: Store,
    public languageService: LanguageService,
    public activatedRouteService: ActivatedRouteService,
    private dialog: MatDialog,
    private platformService: PlatformService,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.form.valueChanges.pipe(
        wasChanged(),
      ).subscribe((formValues) => {
        this.changed.emit({
          formValues,
          isValid: this.form.valid,
        });
      }),
    );
  }

  updateFormValues(settings: IFlockSettingsView): void {
    this.form.patchValue(settings);
  }

  async openUnitsSelector(event: Event): Promise<void> {
    event.preventDefault();
    event.stopPropagation();
    const dialogRef = this.dialog.open(UnitsDialogComponent, {
      width: '440px',
      panelClass: 'popup-full__mobile',
      disableClose: true,
    });
    dialogRef.componentInstance.setup(this.form.controls.units.value);
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (result?.value != null) {
      this.form.controls.units.setValue(result.value);

      if (result.value !== ControllerUnitEnum.Custom) {
        this.form.controls.unitsDetails.setValue(null);
      }

      if (result.value === ControllerUnitEnum.Custom) {
        const dialogRef = this.dialog.open(UnitsDetailsDialogComponent, {
          width: '1280px',
          panelClass: 'popup-full__mobile',
          disableClose: true,
          autoFocus: false,
        });
        dialogRef.componentInstance.setup(this.form.controls.unitsDetails.value);
        const unitsDetailsResult = await lastValueFrom(dialogRef.afterClosed());

        if (unitsDetailsResult) {
          this.form.controls.unitsDetails.setValue(unitsDetailsResult);
        }
      }
    }
  }

  setFocusedTimepicker(e): void {
    if (this.platformService.isMobileApp) return;

    const uuid = e.target.getAttribute('uuid');
    this.currentTimeUUID = uuid;
    if (!uuid) return;
    this.store.dispatch(setElementUuid({
      elementUuid: uuid,
    }));
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
