<div
  *ngIf="form"
  [formGroup]="form"
  class="add-day__container d-flex justify-between align-end justify-center">
  <div class="add-day__container__days position-relative">
    <p class="title">{{ "Controls.Program.Days" | translate }}</p>
    <ls-input-integer
      (onFocusOut)="onInputFocusOut()"
      [class.pointer-events-none]="!addNewDay"
      [fieldWithKeyboard]="true"
      [noMaxWidth]="true"
      [min]="0"
      [max]="99"
      [class.invalid]="form.value.dayNumber == null"
      class="align-center"
      formControlName="dayNumber"
      errorField>
    </ls-input-integer>
  </div>
  <div
    (click)="openEditDayDialog()"
    class="add-day__container__day-managment position-relative">
    <div class="dots"></div>
  </div>
</div>
