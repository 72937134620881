<div
  *ngIf="form"
  [formGroup]="form"
  class="popup d-flex flex-column justify-between add-day">
  <div class="popup-top">
    <div class="d-flex align-center justify-between mb-30">
      <h3
        [qaTags]="'qa-lbl-popup-title'"
        class="popup-title fw-500 fz-20">
        {{ "Controls.Element.AddDay" | translate }}
      </h3>
      <img
        (click)="close()"
        [qaTags]="'qa-btn-close'"
        alt="cross"
        class="close-icon pointer"
        src="/assets/icons/common/cross.svg" />
    </div>

    <div class="popup-body">
      <div
        [qaTags]="'qa-lbl-popup-message'"
        class="fz-20 color-secondary mb-10">
        {{ "Controls.Element.DayNumber" | translate }}
      </div>
      <div class="form-group mb-30">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [noMaxWidth]="true"
          [min]="0"
          [max]="99"
          [qaTags]="'qa-txt-device-box'"
          [showTooltipOnHover]="true"
          class="align-center"
          errorField
          formControlName="dayNumber">
        </ls-input-integer>
      </div>
    </div>
  </div>
  <div class="popup-footer">
    <div class="d-flex align-center justify-end buttons">
      <ls-button
        (click)="add()"
        [class.disabled]="form.invalid"
        [qaTags]="'qa-btn-add'">
        {{ "Buttons.Add" | translate }}
      </ls-button>
    </div>
  </div>
</div>
