<form
  *ngIf="!(isLoading$ | async); else loading"
  [formGroup]="form">
  <div class="container">
    <div class="row">
      <div class="cell d-flex justify-center align-center d-flex justify-center align-center">
        {{ "Installation.Card.Lighting.Voltage" | translate }}
      </div>
      <div class="cell d-flex justify-center align-center">
        <div>{{ "Installation.Card.Lighting.Measured" | translate }}</div>
      </div>
      <div class="cell d-flex justify-center align-center">
        <div>{{ "Installation.Card.Lighting.Lux" | translate }}</div>
      </div>
    </div>
    <div class="row">
      <div class="cell d-flex justify-center align-center">
        <div>{{ "Installation.Card.Lighting.Min1V" | translate }}</div>
      </div>
      <div class="cell d-flex justify-center align-center">
        <ls-button
          [qaTags]="'qa-btn-test'"
          type="secondary">
          {{ "Buttons.Test" | translate }}
        </ls-button>
      </div>
      <div class="cell d-flex justify-center align-center">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [max]="MAX"
          [min]="MIN"
          [qaTags]="'qa-txt-min-test'"
          errorField
          formControlName="min1V">
        </ls-input-integer>
      </div>
    </div>
    <div class="row">
      <div class="cell d-flex justify-center align-center">
        <div>{{ "Installation.Card.Lighting.Max10V" | translate }}</div>
      </div>
      <div class="cell d-flex justify-center align-center">
        <ls-button
          [qaTags]="'qa-btn-test'"
          type="secondary">
          {{ "Buttons.Test" | translate }}
        </ls-button>
      </div>
      <div class="cell d-flex justify-center align-center">
        <ls-input-integer
          [fieldWithKeyboard]="true"
          [max]="MAX"
          [min]="MIN"
          [qaTags]="'qa-txt-max-test'"
          errorField
          formControlName="max10V">
        </ls-input-integer>
      </div>
    </div>
  </div>
</form>

<div
  *ngIf="!platformService.isMobileApp"
  class="keyboard-wrapper">
  <ls-keyboard [defaultMode]="KeyboardModeEnum.NumericOnly"> </ls-keyboard>
</div>

<ng-template #loading>
  <ls-loading></ls-loading>
</ng-template>
