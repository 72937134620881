import { IFans } from './fans.interface';
import { IInlets } from './inlets.interface';

export interface IBasicVentilationMinimumItem {
  basicVentilationID: number,
  dayNumber: number,
  onTime: number,
  offTime: number,
  fans: IFans[],
  inlets: IInlets[],
}