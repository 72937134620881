<div
  [class.input-disabled]="isDisabled"
  class="main-input-container position-relative w-100-percent">
  <div
    (click)="focusInput()"
    [class.right-button-exist]="isRightButtonExists"
    class="ls-input">
    <ng-content select="[leftIcon]"></ng-content>
    <div class="input-wrapper position-relative">
      <input
        #input
        [class.no-value]="!value"
        [disabled]="isDisabled"
        [ngModel]="value"
        [qaTags]="qaTags"
        class="text-{{ textPosition }}" />

      <div class="placeholder position-absolute">
        <span>{{ placeholder }}</span>
      </div>

      <div class="ml-8 ampm">
        <span class="d-none mr-5">{{ value }}</span>
        <span *ngIf="AMPM">{{ AMPMOption | enum : "am-pm" | translate }}</span>
      </div>

      <label class="input-label input-label_filled">
        {{ label | translate }}
      </label>
    </div>
    <ng-content select="[rightIcon]"></ng-content>
    <div
      #rightButton
      [class.right-button]="isRightButtonExists">
      <ng-content select="[rightButton]"></ng-content>
    </div>
  </div>
  <div class="description">
    <ng-content select="[description]"></ng-content>
  </div>
</div>
