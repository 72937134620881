import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, map } from 'rxjs';

import { environment } from '@livestock/shared/environments';
import { ITemperatureCurve, ITemperatureCurveItem, ITemperatureCurveSettings } from '@livestock/temperature-curve';
import { TemperatureUnitEnum } from '@livestock/shared/enums';
import { ConvertHelper } from '@livestock/shared/utils';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({ providedIn: 'root' })
export class TemperatureCurveService {
  constructor(private http: HttpClient) {
  }

  getTemperatureCurve(controllerID: number, temperatureUnit: TemperatureUnitEnum): Observable<ITemperatureCurve> {
    return this.http.get<ITemperatureCurve>(`${baseUrl}/${controllerID}/temperature-curve`).pipe(
      map((temperatureCurve) => {
        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          return {
            items: this.parseTemperatureCurveItems(temperatureCurve.items, TemperatureUnitEnum.Fahrenheit),
          };
        }

        return temperatureCurve;
      }),
    );
  }

  updateTemperatureCurve(
    controllerID: number,
    view: ITemperatureCurveItem[],
    temperatureUnit: TemperatureUnitEnum,
  ): Observable<ITemperatureCurve> {
    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit)
      view = this.parseTemperatureCurveItems(view, TemperatureUnitEnum.Celsius);

    return this.http.put<ITemperatureCurve>(`${baseUrl}/${controllerID}/temperature-curve`, view).pipe(
      map((temperatureCurve) => {
        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          return {
            items: this.parseTemperatureCurveItems(temperatureCurve.items, TemperatureUnitEnum.Fahrenheit),
          };
        } else {
          return temperatureCurve;
        }
      }),
    );
  }

  getTemperatureCurveSettings(
    controllerID: number,
    temperatureUnit: TemperatureUnitEnum,
  ): Observable<ITemperatureCurveSettings> {
    return this.http.get<ITemperatureCurveSettings>(`${baseUrl}/${controllerID}/temperature-curve/settings`).pipe(
      map((settings) => {
        if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
          return this.parseTemperatureCurveSettings(settings, TemperatureUnitEnum.Fahrenheit);
        } else {
          return settings;
        }
      }),
    );
  }

  updateTemperatureCurveSettings(
    controllerID: number,
    temperatureCurveSettings: ITemperatureCurveSettings,
    temperatureUnit: TemperatureUnitEnum,
  ): Observable<ITemperatureCurveSettings> {
    if (temperatureUnit === TemperatureUnitEnum.Fahrenheit) {
      temperatureCurveSettings = this.parseTemperatureCurveSettings(
        temperatureCurveSettings,
        TemperatureUnitEnum.Celsius,
      );
    }

    return this.http
      .put<ITemperatureCurveSettings>(`${baseUrl}/${controllerID}/temperature-curve/settings`, temperatureCurveSettings)
      .pipe(
        map((settings) => {
          return temperatureUnit === TemperatureUnitEnum.Fahrenheit
            ? this.parseTemperatureCurveSettings(settings, TemperatureUnitEnum.Fahrenheit)
            : settings;
        }),
      );
  }

  parseTemperatureCurveSettings(settings: ITemperatureCurveSettings, convertTo: TemperatureUnitEnum): ITemperatureCurveSettings {
    const convertFunction = convertTo === TemperatureUnitEnum.Celsius ? ConvertHelper.fahrenheitToCelsius : ConvertHelper.celsiusToFahrenheit;
    return {
      ...settings,
      comfortZone: convertFunction(settings.comfortZone, 2),
      curveOffset: convertFunction(settings.curveOffset, 2),
    };
  };

  parseTemperatureCurveItems(items: ITemperatureCurveItem[], convertTo: TemperatureUnitEnum): ITemperatureCurveItem[] {
    const convertFunction = convertTo === TemperatureUnitEnum.Celsius ? ConvertHelper.fahrenheitToCelsius : ConvertHelper.celsiusToFahrenheit;
    return items.map((item) => ({
      ...item,
      lowTemperatureAlarm: convertFunction(item.lowTemperatureAlarm, 1),
      target: convertFunction(item.target, 1),
      highTemperatureAlarm: convertFunction(item.highTemperatureAlarm, 1),
    }));
  };
}
