import { Component, EventEmitter, Input, Output } from '@angular/core';
import {
  addBrooding2Stage,
  addSensorsToTempMappingElement,
  changeActiveElementIsAverage,
  IActiveMappingElement,
  ITempMappingItem,
  moveElement,
  removeBrooding2Stage,
  selectBrooding2IsEmpty,
  selectTempMappingsActiveElement,
  selectTempMappingsSensorElements,
  setActiveElement,
  TempMappingHelpersService,
  TempMappingStagesEnum,
  updateTemperatureMappings,
} from '@livestock/temp-mapping';
import { IHeatingProgramZoneItem } from '@livestock/heating-program-zone';
import { firstValueFrom, Observable } from 'rxjs';
import { IElement } from '@livestock/installation/interfaces';
import { Store } from '@ngrx/store';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { ColorsEnum, DialogButtonEnum, ElementTypesEnum } from '@livestock/shared/enums';
import { FlashMessageTypeEnum, setFlashMessage } from '@livestock/notifications';
import { TranslateService } from '@ngx-translate/core';
import { DialogsService } from '@livestock/shared/services';

@Component({
  selector: 'ls-temp-mapping-common',
  template: '',
  standalone: true,
})
export class TempMappingCommonComponent {
  @Input() fullHouse: ITempMappingItem;
  @Input() isLoading: boolean;
  @Input() isDirtyForm: boolean;
  //brooding 1
  @Input() brooding1HasMappings: boolean;
  @Input() brooding1HeatingZones: IHeatingProgramZoneItem[];
  @Input() brooding1ElementMappings: ITempMappingItem[];
  //brooding 2
  @Input() brooding2IsShown: boolean;
  @Input() brooding2HasMappings: boolean;
  @Input() brooding2HeatingZones: IHeatingProgramZoneItem[];
  @Input() brooding2ElementMappings: ITempMappingItem[];
  @Output() closeEditMode = new EventEmitter();

  //subs
  sensors$: Observable<IElement[]> = this.store.select(selectTempMappingsSensorElements);
  activeElement$: Observable<IActiveMappingElement> = this.store.select(selectTempMappingsActiveElement);

  //vars
  activeStage: TempMappingStagesEnum = TempMappingStagesEnum.FullHouse;
  activeElementID: number;
  activeZoneID: number;
  selectedRowIndex: number;

  //enums
  TempMappingStagesEnum = TempMappingStagesEnum;
  ColorsEnum = ColorsEnum;
  ElementTypesEnum = ElementTypesEnum;

  constructor(
    protected store: Store,
    protected translateService: TranslateService,
    protected dialogsService: DialogsService,
    protected tempMappingHelpersService: TempMappingHelpersService,
  ) {
  }

  changeActiveStage(activeStage: TempMappingStagesEnum): void {
    this.unsetActiveElements();
    this.store.dispatch(setActiveElement({ activeStage: null }));
    this.activeStage = activeStage;
  }

  selectRow(elementID = null, zoneID = null, index = 0): void {
    this.selectedRowIndex = index;
    this.activeElementID = elementID;
    this.activeZoneID = zoneID;
    this.store.dispatch(setActiveElement({ activeStage: this.activeStage, elementID }));
  }

  changeIsAverage(event: MatCheckboxChange, index: number, elementID: number = null): void {
    this.selectedRowIndex = index;
    this.activeElementID = elementID;
    this.store.dispatch(changeActiveElementIsAverage({
      activeStage: this.activeStage,
      isAverage: event.checked,
      elementID,
    }));
  }

  addBrooding2Stage(): void {
    this.store.dispatch(addBrooding2Stage());
    this.unsetActiveElements();
    this.activeStage = TempMappingStagesEnum.Brooding2;
  }

  async removeBrooding2Stage(): Promise<void> {
    const message = 'TemperatureMapping.DeleteBrooding2ConfirmationMessage';
    const title = 'TemperatureMapping.DeleteBrooding2';
    const result = await this.dialogsService.question(
      message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
    );

    if (!result || result === DialogButtonEnum.CANCEL) {
      return;
    }

    this.store.dispatch(removeBrooding2Stage());
    this.unsetActiveElements();
    if (this.activeStage === TempMappingStagesEnum.Brooding2) {
      this.activeStage = TempMappingStagesEnum.Brooding1;
    }
  }

  async moveElement(): Promise<void> {
    if (!this.activeElementID && !this.activeZoneID) return;

    const mappings = this.activeStage === TempMappingStagesEnum.Brooding1
      ? [...this.brooding1ElementMappings, ...this.brooding1HeatingZones]
      : [...this.brooding2ElementMappings, ...this.brooding2HeatingZones];
    const currentMapping = mappings.find(el => {
      return !!this.activeZoneID && el.zoneID === this.activeZoneID
        || !!this.activeElementID && (el as ITempMappingItem).elementID === this.activeElementID;
    });

    const movingElementOrZone = this.activeZoneID
      ? (currentMapping as IHeatingProgramZoneItem).name
      : (currentMapping as ITempMappingItem).elementName;

    const popupMessage = this.activeElementID
      ? this.translateService.instant('TemperatureMapping.ThisActionWillMoveThisElementWouldYouLikeToContinue')
      : this.translateService.instant('TemperatureMapping.ThisActionWillMoveThisZoneWouldYouLikeToContinue');
    const title = this.activeElementID
      ? this.translateService.instant('TemperatureMapping.MoveElementWithValue', { value: movingElementOrZone })
      : this.translateService.instant('TemperatureMapping.MoveZoneWithValue', { value: movingElementOrZone });
    const result = await this.dialogsService.question(
      popupMessage, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.MOVE], 'flash/warning', false, false,
    );

    if (!result || result === DialogButtonEnum.CANCEL) {
      return;
    }

    const destinationStage = this.activeStage === TempMappingStagesEnum.Brooding1
      ? TempMappingStagesEnum.Brooding2
      : TempMappingStagesEnum.Brooding1;

    this.store.dispatch(moveElement({
      activeStage: this.activeStage,
      destinationStage,
      elementID: this.activeElementID,
      zoneID: this.activeZoneID,
    }));

    const successMessage = this.translateService.instant('TemperatureMapping.SuccessfullyMovedTo');

    const destinationTitle = this.activeStage === TempMappingStagesEnum.Brooding1
      ? this.translateService.instant('TemperatureMapping.Brooding2')
      : this.translateService.instant('TemperatureMapping.Brooding1');

    // Cooling 2 successfully moved to Brooding 2
    const message = `${movingElementOrZone} ${successMessage} ${destinationTitle}`;
    this.store.dispatch(setFlashMessage({
      flashMessage: {
        flashType: FlashMessageTypeEnum.Success,
        message,
        doNotTranslate: true,
      },
    }));
    this.unsetActiveElements();
  }

  async closeEditModeEmit(): Promise<void> {
    const confirmationResult = await this.tempMappingHelpersService.confirmUnsavedChanges();
    if (!confirmationResult) {
      return;
    }

    this.unsetActiveElements();
    this.closeEditMode.emit();
  }

  async save(): Promise<void> {
    const brooding2IsEmpty = await firstValueFrom(this.store.select(selectBrooding2IsEmpty));
    if (brooding2IsEmpty) {
      const message = 'TemperatureMapping.DeleteEmptyBrooding2ConfirmationMessage';
      const title = 'TemperatureMapping.DeleteBrooding2';
      const result = await this.dialogsService.question(
        message, title, [DialogButtonEnum.CANCEL, DialogButtonEnum.DELETE],
      );

      if (!result || result === DialogButtonEnum.CANCEL) {
        return;
      }

      if (this.activeStage === TempMappingStagesEnum.Brooding2) {
        this.activeStage = TempMappingStagesEnum.Brooding1;
      }
    }
    this.store.dispatch(updateTemperatureMappings());
  }

  saveSensors(sensors: IElement[]): void {
    this.store.dispatch(addSensorsToTempMappingElement({
      sensors,
      activeStage: this.activeStage,
      elementID: this.activeElementID,
    }));
  }

  unsetActiveElements(): void {
    this.activeZoneID = null;
    this.activeElementID = null;
    this.selectedRowIndex = null;
    this.store.dispatch(setActiveElement({ activeStage: null }));
  }

  trackBy(index: number): number {
    return index;
  }
}
