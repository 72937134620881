<section
  *ngIf="!isLoading; else loading"
  [class.mobile-styles]="platformService.isMobileApp"
  [class.white-bg]="platformService.isMobileApp && isWhiteBackground"
  class="page-content">
  <div class="d-flex flex-column w-100-percent">
    <div class="content-wrapper__header-container d-flex align-center justify-between mb-15 mb-5-md flex-wrap">
      <ls-header-title
        (cancel)="cancel()"
        [hasArrow]="hasArrow"
        [title]="title">
      </ls-header-title>
      <ng-content select=".svg-content"></ng-content>
    </div>

    <div class="d-flex justify-between h-100-percent min-height-0">
      <div class="content-wrapper">
        <div class="content-wrapper__data-container">
          <ng-content select=".main-content"></ng-content>
        </div>
      </div>
      <div class="keyboard-content">
        <ng-content select=".keyboard-content"></ng-content>
      </div>
    </div>
  </div>
</section>

<ng-template #loading>
  <ls-loading [paddingTop]="15"></ls-loading>
</ng-template>
