import { ElementTypesEnum } from '@livestock/shared/enums';

export enum IElementEndpointByTypeEnum {
  COOLING = 'cooling',
  SPRINKLERS_AND_FOGGERS = 'sprinklers-foggers',
  INDOOR_TEMPERATURE = 'indoor-temperature',
  OUTDOOR_TEMPERATURE = 'outdoor-temperature',
  OUTDOOR_HUMIDITY = 'outdoor-humidity',
  INDOOR_HUMIDITY = 'indoor-humidity',
  CO2 = 'co2',
  STATIC_PRESSURE = 'static-pressure',
  HEATING_DO = 'heating-do',
  HEATING_AO = 'heating-ao',
  LIGHTING_SENSOR = 'lighting-sensor',
  LIGHTING_DO = 'light-do',
  LIGHTING_AO = 'light-ao',
  AIR_SPEED = 'air-speed',
  VENTILATION_DO = 'ventilation-do',
  VENTILATION_AO = 'ventilation-ao',
}

export function getElementEndpointByType(type: ElementTypesEnum): string {
  switch (type) {
    case ElementTypesEnum.Cooling:
      return IElementEndpointByTypeEnum.COOLING;
    case ElementTypesEnum.SprinklersAndFoggers:
      return IElementEndpointByTypeEnum.SPRINKLERS_AND_FOGGERS;
    case ElementTypesEnum.IndoorTemperature:
      return IElementEndpointByTypeEnum.INDOOR_TEMPERATURE;
    case ElementTypesEnum.OutdoorTemperature:
      return IElementEndpointByTypeEnum.OUTDOOR_TEMPERATURE;
    case ElementTypesEnum.OutdoorHumidity:
      return IElementEndpointByTypeEnum.OUTDOOR_HUMIDITY;
    case ElementTypesEnum.IndoorHumidity:
      return IElementEndpointByTypeEnum.INDOOR_HUMIDITY;
    case ElementTypesEnum.CO2:
      return IElementEndpointByTypeEnum.CO2;
    case ElementTypesEnum.StaticPressure:
      return IElementEndpointByTypeEnum.STATIC_PRESSURE;
    case ElementTypesEnum.HeatingDO:
      return IElementEndpointByTypeEnum.HEATING_DO;
    case ElementTypesEnum.HeatingAO:
      return IElementEndpointByTypeEnum.HEATING_AO;
    case ElementTypesEnum.LightingSensor:
      return IElementEndpointByTypeEnum.LIGHTING_SENSOR;
    case ElementTypesEnum.LightDO:
      return IElementEndpointByTypeEnum.LIGHTING_DO;
    case ElementTypesEnum.LightAO:
      return IElementEndpointByTypeEnum.LIGHTING_AO;
    case ElementTypesEnum.AirSpeed:
      return IElementEndpointByTypeEnum.AIR_SPEED;
    case ElementTypesEnum.VentilationDO:
      return IElementEndpointByTypeEnum.VENTILATION_DO;
    case ElementTypesEnum.VentilationAO:
      return IElementEndpointByTypeEnum.VENTILATION_AO;
    default:
      return '';
  }
}

export function updateView(type: ElementTypesEnum, view: any): any {
  switch (type) {
    case ElementTypesEnum.VentilationDO:
    case ElementTypesEnum.VentilationAO: {
      if (!view.isCustomModel) {
        return {
          ...view,
          values: null,
        };
      }

      return view;
    }
    default:
      return view;
  }
}
