import { createAction, props } from '@ngrx/store';
import { HttpErrorResponse } from '@angular/common/http';
import { ISprinklersFoggersProgram } from './../interfaces/sprinklers-foggers-program.interface';
import { ISprinklersFoggersSingleProgram } from './../interfaces/sprinklers-foggers-single-program.interface';
import { ISprinklersFoggersProgramPeriod } from './../interfaces/sprinklers-foggers-program-period.interface';

export const setCurrentSprinklersFoggersProgram = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Set sprinklers and foggers program',
  props<{ currentProgram: ISprinklersFoggersSingleProgram }>(),
);

export const setCurrentSprinklersFoggersProgramPeriod = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Set sprinklers and foggers period',
  props<{ currentPeriod: ISprinklersFoggersProgramPeriod }>(),
);

export const getSprinklersFoggersProgram = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers program [...]',
  props<{ periodID: number }>(),
);

export const getSprinklersFoggersProgramSuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers program [SUCCESS]',
  props<{ item: ISprinklersFoggersSingleProgram }>(),
);

export const getSprinklersFoggersProgramError = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers program [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const deleteSprinklersFoggersProgram = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Delete sprinklers and foggers program [...]',
  props<{ periodID: number, programID: number }>(),
);

export const deleteSprinklersFoggersProgramSuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Delete sprinklers and foggers program [SUCCESS]',
  props<{ periodID: number, programID: number }>(),
);

export const deleteSprinklersFoggersProgramError = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Delete sprinklers and foggers program [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const getSprinklersFoggersProgramPeriod = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers period [...]',
);

export const getSprinklersFoggersProgramPeriodSuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers period [SUCCESS]',
  props<{ items: ISprinklersFoggersProgramPeriod[], controllerID: number }>(),
);

export const getSprinklersFoggersProgramPeriodError = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Get sprinklers and foggers period [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const addSprinklersFoggersProgramDay = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Add sprinklers and foggers day [...]',
  props<{ dayNumber: number }>(),
);

// mobile --> clear data when add new day
export const clearProgram = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Clear program [...]',
);

// mobile --> remove unsaved day from list days when cancel editing
export const filterPeriods = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Filter periods [...]',
  props<{ currentPeriod: ISprinklersFoggersProgramPeriod }>(),
);

export const deleteSprinklersFoggersProgramDay = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Delete sprinklers and foggers day [...]',
  props<{ periodID: number, preventSelecting?: boolean }>(),
);

export const deleteSprinklersFoggersProgramDaySuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Delete sprinklers and foggers day [SUCCESS]',
  props<{ periodID: number, preventSelecting?: boolean }>(),
);

export const addSprinklersFoggersProgramDaySuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Add sprinklers and foggers day [SUCCESS]',
  props<{ dayNumber: number, controllerID: number }>(),
);

export const addSprinklersFoggersProgramDayError = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Add sprinklers and foggers day [Error]',
  props<{ error: HttpErrorResponse }>(),
);

export const saveSprinklersFoggersProgram = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Save sprinklers and foggers program [...]',
  props<{
    period: ISprinklersFoggersProgramPeriod, items: ISprinklersFoggersProgram[], itemsToDelete: {
      programID: number,
      programIndex: number,
      periodID: number
    }[]
  }>(),
);

export const saveSprinklersFoggersProgramSuccess = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Save sprinklers and foggers program [SUCCESS]',
  props<{ period: ISprinklersFoggersProgramPeriod, items: ISprinklersFoggersProgram[] }>(),
);

export const resetSprinklersFoggers = createAction(
  '[SPRINKLERS AND FOGGERS PROGRAM] Reset sprinklers and foggers program [RESET]',
);
