<div class="widget-wrapper">
  <div class="widget-top">
    <h6 class="widget-title d-flex align-center justify-between">
      <div class="d-flex align-center">
        <img
          (click)="emitCloseWidget()"
          [qaTags]="'qa-btn-return'"
          alt="back"
          class="pointer"
          src="/assets/icons/arrows/chevron-left-grey.svg" />

        <span
          [qaTags]="'qa-lbl-panel-title'"
          class="ml-20"
          >Air pressure</span
        >
      </div>

      <img
        [qaTags]="'qa-lnk-edit'"
        alt="edit"
        class="pointer edit"
        src="/assets/icons/edit-pencil.svg" />
    </h6>
  </div>

  <div class="widget-body">
    <div class="widget-content ph-10 ph-0-xl">
      <h2
        [qaTags]="'qa-lbl-air-pressure-p-status'"
        class="fz-32 fz-24-xl ph-20 mb-10 mb-0-xl ph-0-xl">
        Minimal
      </h2>

      <div class="pressure-block pv-20 pv-10-xl pv-20-mobile">
        <div class="pressure-block__item pressure-block__item-long pl-20 pl-0-xl">
          <div class="pressure-block__title">
            <img
              [qaTags]="'qa-img-air-pressure-p-band qa-img-air-pressure-p-data'"
              alt="waves"
              class="mb-5"
              src="/assets/icons/dashboard/pressure/waves.svg" />
            <span
              [qaTags]="'qa-lbl-air-pressure-p-data qa-lbl-air-pressure-p-band'"
              class="fz-16 color-tertiary"
              >Band</span
            >
          </div>
        </div>

        <div
          [qaTags]="'qa-lbl-air-pressure-p-band-value'"
          class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          5<span
            [qaTags]="'qa-lbl-air-pressure-p-band-units'"
            class="fz-16"
            >PA</span
          >
        </div>
      </div>

      <div class="pressure-block pv-20 pv-10-xl pv-20-mobile">
        <div class="pressure-block__item pressure-block__item-long pl-20 pl-0-xl">
          <div class="pressure-block__title">
            <img
              [qaTags]="'qa-img-air-pressure-p-data qa-img-air-pressure-p-set'"
              alt="settings"
              class="mb-5"
              src="/assets/icons/dashboard/pressure/settings.svg" />
            <span
              [qaTags]="'qa-lbl-air-pressure-p-set'"
              class="fz-16 fz-18-mobile color-tertiary"
              >Set</span
            >
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            25<span
              [qaTags]="'qa-lbl-air-pressure-p-set-units'"
              class="fz-16"
              >PA</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-set-cold'"
            class="fz-16 fz-18-mobile color-tertiary">
            Cold
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            10<span
              [qaTags]="'qa-lbl-air-pressure-p-set-units'"
              class="fz-16"
              >PA</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-set-warm'"
            class="fz-16 color-tertiary">
            Warm
          </div>
        </div>
      </div>

      <div class="pressure-block pv-20 pv-10-xl pv-20-mobile">
        <div class="pressure-block__item pressure-block__item-long pl-20 pl-0-xl">
          <div class="pressure-block__title pressure-block__title-full">
            <img
              [qaTags]="'qa-img-air-pressure-p-data qa-img-air-pressure-p-out'"
              alt="sun-clouds"
              class="mb-5"
              src="/assets/icons/dashboard/pressure/sun-clouds.svg" />
            <span
              [qaTags]="'qa-lbl-air-pressure-p-out'"
              class="fz-16 fz-18-mobile color-tertiary"
              >Outside temp</span
            >
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            5<span
              [qaTags]="'qa-lbl-air-pressure-p-out-units'"
              class="fz-16"
              >°C</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-out-cold'"
            class="fz-16 fz-18-mobile color-tertiary">
            Cold
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            15<span
              [qaTags]="'qa-lbl-air-pressure-p-out-units'"
              class="fz-16"
              >°C</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-out-warm'"
            class="fz-16 fz-18-mobile color-tertiary">
            Warm
          </div>
        </div>
      </div>

      <div class="pressure-block pv-20 pv-10-xl pv-20-mobile pressure-block__no-border">
        <div class="pressure-block__item pressure-block__item-long pl-20 pl-0-xl">
          <div class="pressure-block__title">
            <img
              [qaTags]="'qa-img-air-pressure-p-data qa-img-air-pressure-p-alerts'"
              alt="alarm"
              class="mb-5"
              src="/assets/icons/dashboard/pressure/alarm.svg" />
            <span
              [qaTags]="'qa-lbl-air-pressure-p-alerts'"
              class="fz-16 fz-18-mobile color-tertiary"
              >Alerts</span
            >
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            2<span
              [qaTags]="'qa-lbl-air-pressure-p-alerts-units'"
              class="fz-16"
              >PA</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-alerts-low'"
            class="fz-16 fz-18-mobile color-tertiary">
            Low
          </div>
        </div>

        <div class="pressure-block__item pressure-block__value fz-32 fz-24-xl fz-22-mobile">
          <div>
            40<span
              [qaTags]="'qa-lbl-air-pressure-p-alerts-units'"
              class="fz-16"
              >PA</span
            >
          </div>
          <div
            [qaTags]="'qa-lbl-air-pressure-p-alerts-high'"
            class="fz-16 fz-18-mobile color-tertiary">
            High
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
