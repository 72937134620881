import { Component, Input } from '@angular/core';
import { QaTagsDirective } from '@livestock/shared/directives';

@Component({
  selector: 'ls-button',
  templateUrl: './button.component.html',
  styleUrls: ['./button.component.scss'],
  standalone: true,
  imports: [
    QaTagsDirective,
  ],
})

export class ButtonComponent {
  @Input() type: 'default' | 'primary' | 'secondary' | 'alert' | 'success' | 'danger' = 'default';
  @Input() buttonType: 'submit' | 'button' = 'button';
  @Input() isValidForm: boolean = true;
  @Input() title: string = 'Confirm';
  @Input() qaTags: string;
  @Input() paddingClass: string = 'ph-20';
}
