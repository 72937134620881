import { Component, Inject } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { MatRadioModule } from '@angular/material/radio';
import { SvgIconComponent } from '@livestock/ui';
import { TranslateModule } from '@ngx-translate/core';
import { QaTagsDirective } from '@livestock/shared/directives';

import { IZonesForCopyHeatersPopup } from '../../interfaces/zones-for-copy-heaters-popup.interface';

@Component({
  selector: 'ls-move-heaters-popup',
  templateUrl: 'move-heaters-popup.component.html',
  styleUrls: ['move-heaters-popup.component.scss'],
  standalone: true,
  imports: [
    CommonModule,
    MatRadioModule,
    SvgIconComponent,
    TranslateModule,
    QaTagsDirective,
  ],
})
export class MoveHeatersPopupComponent {
  constructor(
    private dialogRef: MatDialogRef<MoveHeatersPopupComponent>,
    @Inject(MAT_DIALOG_DATA) public data: { zones: IZonesForCopyHeatersPopup[] },
  ) {
  }

  close(): void {
    this.dialogRef.close();
  }

  copyElement(zone: IZonesForCopyHeatersPopup): void {
    if (zone.isDisabled) return;

    this.dialogRef.close({ zoneID: zone.id, zoneName: zone.name });
  }

}
