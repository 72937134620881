import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@livestock/shared/environments';
import { Observable } from 'rxjs';
import { CoolingPeriod, CoolingProgram, SingleCoolingProgram } from '../interfaces/cooling.interfaces';

const baseUrl = `${environment.apiUrl}/controller`;

@Injectable({
  providedIn: 'root',
})
export class CoolingProgramService {
  constructor(private http: HttpClient) {
  }

  getCoolingPeriod(controllerID: number): Observable<{ items: CoolingPeriod[] }> {
    return this.http.get<{ items: CoolingPeriod[] }>(`${baseUrl}/${controllerID}/cooling/period`);
  }

  addCoolingDay(controllerID: number, body: { dayNumber: number }): Observable<CoolingPeriod> {
    return this.http.post<CoolingPeriod>(`${baseUrl}/${controllerID}/cooling/period`, body);
  }

  deleteCoolingDay(controllerID: number, periodID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/cooling/period/${periodID}`);
  }

  deleteProgram(controllerID: number, periodID: number, programID: number): Observable<void> {
    return this.http.delete<void>(`${baseUrl}/${controllerID}/cooling/period/${periodID}/program/${programID}`);
  }

  getCoolingProgram(controllerID: number, periodID: number): Observable<SingleCoolingProgram> {
    return this.http.get<SingleCoolingProgram>(`${baseUrl}/${controllerID}/cooling/period/${periodID}/program`);
  }

  addCoolingProgram(controllerID: number, periodID: number, body: CoolingProgram): Observable<CoolingProgram> {
    return this.http.post<CoolingProgram>(`${baseUrl}/${controllerID}/cooling/period/${periodID}/program`, body);
  }

  editCoolingProgram(controllerID: number, periodID: number, body: CoolingProgram): Observable<CoolingProgram> {
    return this.http.put<CoolingProgram>(`${baseUrl}/${controllerID}/cooling/period/${periodID}/program/${body.programID}`, body);
  }
}
