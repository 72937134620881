import { Component, OnDestroy, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  HeatingProgramsFormComponent,
  IHeatingProgramView,
  IHeatingProgramZoneView,
  deleteHeatingProgram,
  getHeatingPrograms,
  selectHeatingProgramZones,
  selectHeatingPrograms,
  selectIsDirty,
  selectIsLoading,
  selectOriginalHeatingPrograms,
  setHeatingPrograms,
  updateHeatingPrograms,
} from '@livestock/heating-program';
import { TranslateModule } from '@ngx-translate/core';
import {
  ButtonComponent,
  KeyboardComponent,
  KeyboardModeEnum,
  PageWrapperComponent,
  SvgIconComponent,
} from '@livestock/ui';
import { Observable, Subscription, filter } from 'rxjs';
import { Store } from '@ngrx/store';
import { ActivatedRoute, Router } from '@angular/router';
import { DialogsService, MenuService, PlatformService } from '@livestock/shared/services';
import { ColorsEnum, ElementTypesEnum, TemperatureUnitEnum } from '@livestock/shared/enums';
import {
  selectCurrentControllerTemperatureUnit,
} from '../../../../../controllers/src/lib/+state/current-controller/current-controller.selectors';
import { AppRoutes, ControllerRoutes } from '@livestock/shared/routes';
import { IComponentCanDeactivate } from '@livestock/shared/interfaces';
import { clearHeatingProgramsState } from '../../+state/heating-program.actions';
import { wasChangedAndNotNull } from '@livestock/shared/rxjs-operators';

@Component({
  selector: 'ls-heating-programs',
  standalone: true,
  imports: [
    CommonModule,
    TranslateModule,
    HeatingProgramsFormComponent,
    KeyboardComponent,
    ButtonComponent,
    SvgIconComponent,
    PageWrapperComponent,
  ],
  templateUrl: './heating-programs.component.html',
  styleUrls: ['./heating-programs.component.scss'],
})
export class HeatingProgramsComponent implements OnInit, OnDestroy, IComponentCanDeactivate {
  sub$ = new Subscription();
  isLoading$: Observable<boolean> = this.store.select(selectIsLoading);
  temperatureUnit$: Observable<TemperatureUnitEnum> = this.store.select(selectCurrentControllerTemperatureUnit);
  programZones$: Observable<IHeatingProgramZoneView[]> = this.store.select(selectHeatingProgramZones);
  // variables
  editMode: boolean;
  controllerID: number;
  heatingPrograms: IHeatingProgramView[];
  originalHeatingPrograms: IHeatingProgramView[];
  isValid = true;
  isDirty = false;
  // enums
  KeyboardModeEnum = KeyboardModeEnum;
  ColorsEnum = ColorsEnum;

  constructor(
    private store: Store,
    private activatedRoute: ActivatedRoute,
    private menuService: MenuService,
    public platformService: PlatformService,
    private dialogsService: DialogsService,
    private router: Router,
  ) {
  }

  ngOnInit(): void {
    this.sub$.add(
      this.activatedRoute.params.subscribe((params) => {
        this.controllerID = +params['controllerID'];
        this.store.dispatch(getHeatingPrograms({ controllerID: this.controllerID }));
      }),
    );

    this.sub$.add(
      this.store.select(selectHeatingPrograms)
        .pipe(wasChangedAndNotNull())
        .subscribe((heatingPrograms) => {
          this.heatingPrograms = heatingPrograms;
        }),
    );

    this.sub$.add(
      this.store.select(selectOriginalHeatingPrograms)
        .pipe(wasChangedAndNotNull())
        .subscribe((originalHeatingPrograms) => {
          this.originalHeatingPrograms = originalHeatingPrograms;
        }),
    );

    this.sub$.add(
      this.store.select(selectIsDirty)
        .pipe(
          filter(selectIsDirty => selectIsDirty != null),
        ).subscribe((isDirty) => {
        this.isDirty = isDirty;
      }),
    );
  }

  goToSettings(): void {
    this.router.navigate([AppRoutes.INSTALLATION, 'elementType', ElementTypesEnum.HeatingDO, 'settings']);
  }

  changedPrograms(event: { formValues: any, isValid: boolean }): void {
    this.isValid = event.isValid;
    this.store.dispatch(setHeatingPrograms({ programs: event.formValues }));
  }

  update(): void {
    if (!this.isValid) {
      return;
    }
    this.store.dispatch(updateHeatingPrograms({ controllerID: this.controllerID }));
  }

  addDefaultProgram(): void {
    this.store.dispatch(setHeatingPrograms({
      programs: [{
        programID: null,
        number: 1,
        zoneID: null,
        onTempDiff: 0,
        offTempDiff: 0,
        minOutput: 0,
        maxOutput: 0,
      }],
    }));
    this.editMode = true;
  }

  toggleEditMode(): void {
    if (!this.editMode && this.heatingPrograms.length === 0) {
      this.addDefaultProgram();
      return;
    }

    if (this.editMode) {
      this.cancel();
      return;
    }

    this.editMode = !this.editMode;
  }

  async checkUnsavedChanges(): Promise<boolean> {
    if (this.isDirty) {
      const result = await this.dialogsService.canContinueAction();

      if (!result) {
        return false;
      }
    }

    this.store.dispatch(setHeatingPrograms({ programs: this.originalHeatingPrograms }));
    return true;
  }

  async cancel(): Promise<void> {
    const cancelConfirmation = await this.checkUnsavedChanges();
    if (cancelConfirmation) {
      this.editMode = false;
      this.store.dispatch(setHeatingPrograms({ programs: this.originalHeatingPrograms }));
    }
  }

  async goBack(): Promise<void> {
    this.menuService.toggleDashboardMenu(true);
  }

  goToZones(): void {
    this.router.navigate([`/controller/${this.controllerID}/${ControllerRoutes.HeatingProgramZone}`]);
  }

  deleteProgram(programID: number): void {
    this.store.dispatch(deleteHeatingProgram({ controllerID: this.controllerID, programID }));
  }

  canDeactivate(): boolean {
    return !this.isDirty;
  }

  closeComponent(): void {
    this.store.dispatch(clearHeatingProgramsState());
  }

  ngOnDestroy(): void {
    this.sub$.unsubscribe();
  }
}
