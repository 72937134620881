export enum EDashboardMainIndicator {
  VENTILATION,
  HEAT,
  INLET,
  COOLING,
  TEMPERATURE,
  PRESSURE,
  AIR_QUALITY,
  DAILY_CONSUMPTION,
  BIRD,
  LIGHT,
}
